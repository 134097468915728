import { Selection } from 'victory';
import { colors } from '../const/theme';

export const chartMouseEvents = (isBar = false, callback, clickCallback) => ({
  target: 'data',
  eventHandlers: {
    onMouseOver: (evt) => {
      const { x, y } = Selection.getSVGEventCoordinates(evt);
      return [
        {
          mutation: (props) => {
            if (typeof callback === 'function') {
              const explanation = props.datum.explanation || '';
              const options = {
                showExplanation: true,
                explanation,
              };
              callback(options);
            }
            return {
              style: Object.assign({}, props.style, {
                fill: colors.HOVER_CHART_FILL_COLOR,
                strokeWidth: isBar ? 0 : 1.5,
              }),
            };
          },
        },
        {
          target: 'labels',
          mutation: () => ({ x, y, active: true }),
        },
      ];
    },
    ...(isBar && {
      onMouseMove: (evt) => {
        if (isBar) {
          const { x, y } = Selection.getSVGEventCoordinates(evt);
          return {
            target: 'labels',
            mutation: () => ({
              x,
              y,
              active: true,
            }),
          };
        }
      },
    }),
    onMouseOut: () => {
      return [
        {
          mutation: () => {
            if (typeof callback === 'function') {
              const options = {
                showExplanation: false,
                explanation: null,
              };
              callback(options);
            }
            return null;
          },
        },
        {
          target: 'labels',
          mutation: () => ({ active: false }),
        },
      ];
    },
    onClick: () => {
      return [
        {
          target: 'data',
          mutation: (props) => {
            if (typeof clickCallback === 'function') {
              clickCallback(props.datum);
            }
            const fill = props.style.fill;
            return fill === colors.PRIMARY_CHART_FILL_COLOR
              ? null
              : {
                  style: {
                    fill: colors.SELECTED_CHART_FILL_COLOR,
                  },
                };
          },
        },
      ];
    },
  },
});
