import React from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { withRouter } from 'react-router-dom';

import { AuthUserContext } from '../components/auth-context';
import { auth } from '../firebase';
import { LANDING } from '../const/routes';

import { getUser, createNewUser, updateUser, docToObj } from '../lib/admin-api';
import Tracking from 'lib/tracking/tracking';
import { isIfster } from 'lib/utils';

const withAuthorization = (authCondition) => (Component) => {
  class WithAuthorization extends React.Component {
    state = {
      currentUser: null,
    };
    componentDidMount() {
      onAuthStateChanged(auth, async (authUser) => {
        if (!authUser) {
          this.props.history.push(LANDING);
          return;
        }

        if (!authCondition(authUser)) {
          this.props.history.push(LANDING);
        }
        const tokenResult = await authUser.getIdTokenResult();
        const claims = tokenResult.claims;
        this.getUserDetails(authUser, claims);
      });
    }
    saveCurrentUser(authUser, data, claims) {
      const currentUser = {
        id: authUser.uid,
        ...data,
        ...((claims && claims) || {}),
      };
      Tracking.identifyUser({
        userId: authUser.uid,
        userAttributes: {
          $email: authUser.email,
          $name: authUser.displayName,
          isIfster: isIfster({ email: authUser.email }),
        },
      });
      this.setState({ currentUser });
    }
    updateCurrentUser = (user) => {
      updateUser(user).then((currentUser) => this.setState({ currentUser }));
    };
    getUserDetails(authUser, claims) {
      if (!authUser) return;
      //authUser.sendEmailVerification();
      const userDetails = {
        ...authUser,
        ...((claims && claims) || {}),
      };
      return getUser(authUser).then(
        (doc) => {
          if (doc.exists)
            return this.saveCurrentUser(authUser, docToObj(doc), claims);
          //FYI: These details are also created using a function
          //But we use merge:true on set so that there is
          //no data overriding.
          return createNewUser(userDetails).then((res) => {
            this.saveCurrentUser(authUser, res, claims);
          });
        },
        () => {
          //FYI: These details are also created using a function
          //But we use merge:true on set so that there is
          //no data overriding.
          return createNewUser(userDetails).then((res) => {
            this.saveCurrentUser(authUser, res, claims);
          });
        }
      );
    }
    render() {
      return (
        <AuthUserContext.Consumer>
          {(authUser) =>
            authUser ? (
              <Component
                {...this.props}
                currentUser={this.state.currentUser}
                updateCurrentUser={this.updateCurrentUser}
              />
            ) : null
          }
        </AuthUserContext.Consumer>
      );
    }
  }

  return withRouter(WithAuthorization);
};

export default withAuthorization;
