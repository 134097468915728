import { formatISO } from 'date-fns';

import {
  capitalize,
  getUserName,
  sortWordCloudDesc,
  getAllowedWordGroup,
  unwantedWordCloudWords,
  addStylingVariantToWordGroupElements,
} from '../Helper';

function getParticipants(acc, name, responseId, value) {
  return [
    ...acc.participants,
    {
      name,
      label: responseId,
      responseId,
    },
  ];
}

function getParticipantsData(acc, responseId, name, value, timestamp) {
  return {
    ...acc.participantsData,
    [responseId]: {
      name,
      value,
      responseId,
      timestamp,
    },
  };
}

function getInitialValue() {
  return {
    participantsData: {},
    participants: [],
  };
}

/**
 * Reduce callback function to format message bubble responses
 * @param acc accumulator
 * @param response current Value
 * @param currentIndex
 * @param moduleId
 * @param moduleList
 * @returns {(*&{participantsData, participants: *})|*}
 */
const formatMessageBubbleResponses = ({
  acc,
  response,
  currentIndex,
  moduleId,
  moduleList,
}) => {
  const responseId = response.responseId;
  const timestamp = response.timestamp;
  const answerData = response.answerData;
  const moduleResponse = answerData[moduleId];
  if (!moduleResponse) return acc;
  const value = moduleResponse.value || '';
  const index = currentIndex + 1;
  const name = getUserName(response.userName, answerData, moduleList, index);
  return {
    ...acc,
    participants: getParticipants(acc, name, responseId, value),
    participantsData: getParticipantsData(
      acc,
      responseId,
      name,
      value,
      timestamp
    ),
  };
};

const formatOpenQuestionDataResponses = ({
  userResponses,
  moduleList,
  moduleId,
}) => {
  const responses = Object.values(userResponses);
  const responsesPerRespondent = responses.reduce(
    (acc, response, currentIndex) =>
      formatMessageBubbleResponses({
        acc,
        response,
        currentIndex,
        moduleId,
        moduleList,
      }),
    getInitialValue()
  );

  const wordCloudGroup = getWordCloudData({
    userResponses,
    moduleId,
  });

  return {
    participantsData: responsesPerRespondent.participantsData,
    participants: responsesPerRespondent.participants,
    wordCloudGroup,
  };
};

/**
 * Concatenate the user responses into one sentence/string
 * @param moduleId
 * @returns {function(*, *): (*)}
 */
function concatResponses(moduleId) {
  return (acc, response) => {
    const answerData = response.answerData;
    const moduleResponse = answerData[moduleId];
    if (!moduleResponse) return acc;
    const value = moduleResponse.value || '';
    acc = `${acc} ${value}`;
    return acc;
  };
}

const getWordCloudData = ({ userResponses, moduleId }) => {
  const responses = Object.values(userResponses);

  const sentence = responses.reduce(concatResponses(moduleId), ``);

  const sentenceArray = sentence
    .split(' ')
    .map((word) => {
      let newWord = word.trim();
      return newWord.toLowerCase();
    })
    .map((word) => word.replace(/[&\/\\#,+_`()-/$~%.'":*?<>{}]/g, ''))
    .filter((word) => !unwantedWordCloudWords.includes(word));

  const wordGroup = sentenceArray.reduce((acc, currentValue) => {
    currentValue = currentValue.trim();
    acc[currentValue] = (acc[currentValue] || 0) + 1;
    return acc;
  }, {});

  const wordGroupArray = Object.entries(wordGroup).map(([key, value]) => ({
    text: capitalize(key),
    value,
  }));

  const sortedWordGroup = sortWordCloudDesc({ wordGroup: wordGroupArray });

  const allowedWordGroup = getAllowedWordGroup({
    wordGroup: sortedWordGroup,
    maxLength: 70,
  });

  return addStylingVariantToWordGroupElements({ wordGroup: allowedWordGroup });
};

const getOpenQuestionData = ({ userResponses, moduleList, moduleId }) => {
  const {
    participants,
    wordCloudGroup,
    participantsData,
  } = formatOpenQuestionDataResponses({
    userResponses,
    moduleList,
    moduleId,
  });
  const participantsDataArray = Object.values(participantsData)
    .map((resp) => {
      resp.timestamp = formatISO(new Date(resp.timestamp.seconds * 1000));
      return resp;
    })
    .sort((a, b) => b.timestamp.localeCompare(a.timestamp));

  return {
    participants,
    wordCloudData: wordCloudGroup,
    participantsData,
    allResponses: participantsDataArray,
  };
};

export { getOpenQuestionData };
