import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Tooltip from '@reach/tooltip';
import '@reach/tooltip/styles.css';

import { Button } from '../layout';
import SurveyCardMenu from '../SurveyCardMenu';
import {
  getIsClosed,
  getSurveyTitle,
  getEditDisabled,
  getIsBeforeExpireDate,
} from 'lib/builder-api';
import PlayButton from 'img/play_button.png';
import { ReactComponent as PresentIcon } from 'img/present_icon.svg';
import { canCurrentUserEditSurvey } from 'lib/utils';
import { ButtonBase } from '../buttons';
import { WORKSHOP_ROOT } from 'const/routes';
import { HILOS } from 'const/index';

const GearImg = () => (
  <svg
    width="20"
    height="22"
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1L16 5L5 16H1V12L12 1V1Z"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 21H19"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const BarChartImg = () => (
  <svg
    width="28"
    height="23"
    viewBox="0 0 28 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.9419 5.95735C13.9419 5.92549 13.9677 5.89966 13.9996 5.89966C14.0314 5.89966 14.0573 5.92549 14.0573 5.95735V17.0997H13.9419V5.95735Z"
      stroke="#262525"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.1729 9.25789C18.1729 9.22602 18.1987 9.2002 18.2305 9.2002C18.2624 9.2002 18.2882 9.22603 18.2882 9.25789V17.1002H18.1729V9.25789Z"
      stroke="#262525"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.71143 8.1573C9.71143 8.12544 9.73726 8.09961 9.76912 8.09961C9.80098 8.09961 9.82681 8.12544 9.82681 8.1573V17.0996H9.71143V8.1573Z"
      stroke="#262525"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.48096 13.6573C5.48096 13.6254 5.50679 13.5996 5.53865 13.5996C5.57051 13.5996 5.59634 13.6254 5.59634 13.6573V17.0996H5.48096V13.6573Z"
      stroke="#262525"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.4043 15.857C22.4043 15.8251 22.4301 15.7993 22.462 15.7993C22.4939 15.7993 22.5197 15.8251 22.5197 15.857V17.0993H22.4043V15.857Z"
      stroke="#262525"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="1.25"
      y="1.5"
      width="25.5"
      height="20"
      rx="3.125"
      stroke="#262525"
      strokeWidth="2"
    />
  </svg>
);

const SettingsImg = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.4 15C19.1277 15.6171 19.2583 16.3378 19.73 16.82L19.79 16.88C20.1656 17.2551 20.3766 17.7642 20.3766 18.295C20.3766 18.8258 20.1656 19.3349 19.79 19.71C19.4149 20.0856 18.9058 20.2966 18.375 20.2966C17.8442 20.2966 17.3351 20.0856 16.96 19.71L16.9 19.65C16.4178 19.1783 15.6971 19.0477 15.08 19.32C14.4755 19.5791 14.0826 20.1724 14.08 20.83V21C14.08 22.1046 13.1846 23 12.08 23C10.9754 23 10.08 22.1046 10.08 21V20.91C10.0642 20.2327 9.63587 19.6339 9 19.4C8.38291 19.1277 7.66219 19.2583 7.18 19.73L7.12 19.79C6.74486 20.1656 6.23582 20.3766 5.705 20.3766C5.17418 20.3766 4.66514 20.1656 4.29 19.79C3.91445 19.4149 3.70343 18.9058 3.70343 18.375C3.70343 17.8442 3.91445 17.3351 4.29 16.96L4.35 16.9C4.82167 16.4178 4.95235 15.6971 4.68 15.08C4.42093 14.4755 3.82764 14.0826 3.17 14.08H3C1.89543 14.08 1 13.1846 1 12.08C1 10.9754 1.89543 10.08 3 10.08H3.09C3.76733 10.0642 4.36613 9.63587 4.6 9C4.87235 8.38291 4.74167 7.66219 4.27 7.18L4.21 7.12C3.83445 6.74486 3.62343 6.23582 3.62343 5.705C3.62343 5.17418 3.83445 4.66514 4.21 4.29C4.58514 3.91445 5.09418 3.70343 5.625 3.70343C6.15582 3.70343 6.66486 3.91445 7.04 4.29L7.1 4.35C7.58219 4.82167 8.30291 4.95235 8.92 4.68H9C9.60447 4.42093 9.99738 3.82764 10 3.17V3C10 1.89543 10.8954 1 12 1C13.1046 1 14 1.89543 14 3V3.09C14.0026 3.74764 14.3955 4.34093 15 4.6C15.6171 4.87235 16.3378 4.74167 16.82 4.27L16.88 4.21C17.2551 3.83445 17.7642 3.62343 18.295 3.62343C18.8258 3.62343 19.3349 3.83445 19.71 4.21C20.0856 4.58514 20.2966 5.09418 20.2966 5.625C20.2966 6.15582 20.0856 6.66486 19.71 7.04L19.65 7.1C19.1783 7.58219 19.0477 8.30291 19.32 8.92V9C19.5791 9.60447 20.1724 9.99738 20.83 10H21C22.1046 10 23 10.8954 23 12C23 13.1046 22.1046 14 21 14H20.91C20.2524 14.0026 19.6591 14.3955 19.4 15Z"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const TemplateImg = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.8"
      y="0.8"
      width="24.4"
      height="24.4"
      rx="0.8"
      stroke="black"
      strokeWidth="1.6"
    />
    <path
      d="M8.01568 4.13661C8.11147 3.84179 8.52856 3.84179 8.62436 4.13661L9.32584 6.29555C9.36868 6.4274 9.49155 6.51667 9.63018 6.51667H11.9002C12.2102 6.51667 12.3391 6.91335 12.0883 7.09555L10.2518 8.42986C10.1397 8.51134 10.0927 8.65578 10.1356 8.78763L10.837 10.9466C10.9328 11.2414 10.5954 11.4866 10.3446 11.3043L8.50811 9.97004C8.39595 9.88856 8.24408 9.88856 8.13193 9.97004L6.29542 11.3043C6.04463 11.4866 5.7072 11.2414 5.80299 10.9466L6.50447 8.78763C6.54731 8.65578 6.50038 8.51134 6.38823 8.42986L4.55172 7.09555C4.30093 6.91335 4.42982 6.51667 4.73981 6.51667H7.00986C7.14849 6.51667 7.27136 6.4274 7.3142 6.29555L8.01568 4.13661Z"
      fill="black"
    />
    <line
      x1="5.8"
      y1="15.2"
      x2="22.2"
      y2="15.2"
      stroke="black"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 11L22.2 11"
      stroke="black"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 6.2L22.2 6.19995"
      stroke="black"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="5.8"
      y1="20.2"
      x2="22.2"
      y2="20.2"
      stroke="black"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const PlayImg = () => (
  <svg
    width="16"
    height="20"
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 1L15 10L1 19V1V1Z"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const StyledLink = styled(Link)`
  color: inherit;
  transition: color 50ms;
  &:hover {
    text-decoration: none;
  }
`;

export const SurveyCardTitle = styled('div')`
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;
`;

const Title = styled('div')`
  font-family: 'Roboto', sans-serif;
  font-size: 30px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: -0.06rem;
  padding-right: 0.625rem;
  transition: color 50ms;
  cursor: ${(props) => (props.active ? 'pointer' : 'default')};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  color: ${(props) =>
    props.hiloType === HILOS.SURVEY ? '#1CA4FC' : '#FF7B3C'};
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  &:hover {
    color: ${(props) =>
      props.active
        ? '#000'
        : props.hiloType === HILOS.SURVEY
        ? '#1CA4FC'
        : '#FF7B3C'};
  }
`;

export const Responses = styled('div')`
  font-family: 'Neue Haas Grotesk Display Std', sans-serif;
  font-weight: normal;
  font-size: 1.125rem;
`;

export const SurveyCardHover = styled('div')`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background-color: rgba(196, 196, 196, 0.41);
  background-image: url('${PlayButton}');
  background-size: 3.125rem 3.125rem;
  background-position: center center;
  background-repeat: no-repeat;
  transition: opacity 0.1s;
  &:hover {
    opacity: 1;
  }
`;

export const Separator = styled('div')`
  background-color: #dadada;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0.75rem;
  right: 0.75rem;
`;

export const ResponsesAndConfig = styled('div')`
  padding: 0.75rem;
  transition: background-color 0.1s;
`;

export const TemplateBottom = styled(ResponsesAndConfig)`
  display: flex;
  align-items: center;
`;

const ResponsesContainer = styled('div')`
  display: inline-block;
  border-bottom: 0.5px dashed #000000;
  width: 200px;
`;
const ResponsesInner = styled('div')`
  display: flex;
`;

const TemplateImgContainer = styled('div')``;

export const Config = styled('div')`
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 1.875rem;
  height: 1.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 1rem;
  padding: 0.25rem;
  box-sizing: border-box;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  path {
    transition: stroke 0.1s;
  }
  &:hover {
    path,
    rect,
    line {
      stroke: #1ca4fc;
    }
    ${TemplateImgContainer} {
      path {
        fill: #1ca4fc;
      }
    }
  }
`;

export const ConfigAndCodeName = styled('div')`
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: relative;
  align-items: flex-end;
`;

export const CodeName = styled('div')`
  font-family: Neue Haas Grotesk Display Std;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height */

  color: #333333;
`;

export const DraftDot = styled('div')`
  background: ${(props) => (props.draft ? '#F2C94C' : '#00E08A')};
  border-radius: 50%;
  width: 0.8125rem;
  height: 0.8125rem;
  margin-right: 0.3125rem;
`;

export const SurveyCodeContainer = styled('div')`
  width: 200px;
`;

const CodeRow = styled('div')`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

export const Draft = styled(CodeName)`
  color: #c4c4c4;
  text-transform: uppercase;
`;

const CodeRowTitle = styled('div')`
  font-family: Neue Haas Grotesk Display Std;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height */

  color: ${(props) => props.color || '#00e08a'};
  min-width: 85px;
`;

export const SurveyCode = ({
  surveyCode,
  formattedTimestamp,
  isLocked,
  survey,
  lockedBy,
}) => {
  const { unpublished } = survey || {};
  let code = <CodeRow />;
  const isClosed = getIsClosed(survey);
  if (!unpublished) {
    code = (
      <CodeRow>
        <CodeRowTitle color={isClosed ? '#FF7B7B' : null}>
          {isClosed ? 'Closed' : 'Launched'}
        </CodeRowTitle>
        <CodeName>{surveyCode || 'CODENAME'}</CodeName>
      </CodeRow>
    );
  }
  let code2 = (
    <CodeRow>
      <CodeRowTitle>Last Modified</CodeRowTitle>
      <CodeName>{formattedTimestamp}</CodeName>
    </CodeRow>
  );
  if (isLocked) {
    code2 = (
      <CodeRow>
        {/*<CodeRowTitle color="#FF9B3A">Locked</CodeRowTitle>*/}
        {/*<CodeName>{lockedBy}</CodeName>*/}
      </CodeRow>
    );
  }
  return (
    <SurveyCodeContainer>
      {code}
      {code2}
    </SurveyCodeContainer>
  );
};

const TemplateHover = styled(SurveyCardHover)`
  background: #1ca4fc;
  padding: 0.625rem;
  color: white;
  font-family: 'Neue Haas Grotesk Display Std', sans-serif;
  font-size: 1.125rem;
  font-weight: normal;
  letter-spacing: normal;
`;

const UseTemplateButton = styled(Button)`
  transition: background-color 0.1s;
  flex: 1 0 auto;
`;

export const SurveyCardContainer = styled('div')`
  background-color: white;
  height: 16.875rem;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  margin: ${(props) => (props.hideMargin ? '0' : '0 1.25rem 1.25rem 0')};
  flex: 0 0 13.9375rem;
  display: flex;
  flex-direction: column;
  border: 2px solid
    ${(props) => (props.hiloType === HILOS.SURVEY ? '#1CA4FC' : '#FF7B3C')};
  &:hover ${TemplateHover} {
    opacity: 1;
  }
  &:hover ${UseTemplateButton} {
    background-color: white;
    color: #1ca4fc;
  }
  &:hover ${ResponsesAndConfig} {
    background-color: ${(props) =>
      props.isTemplateSurvey ? '#1CA4FC' : 'white'};
  }
  transition: background-color 0.1s, border-color 0.1s;
  position: relative;
  overflow: visible;
  cursor: ${(props) => props.cursor || 'auto'};
  &:before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: opacity 0.1s;
    box-shadow: 0px 0px 8px rgba(0, 175, 215, 0.25);
    opacity: 0;
    pointer-events: none;
  }
  &:hover {
    text-decoration: none;
    &:before {
      opacity: 1;
    }
  }
`;

const ConfigRow = styled('div')`
  display: flex;
`;

const BarChartContainer = styled('div')`
  margin-right: 0.5rem;
`;

const BarChart = () => (
  <BarChartContainer>
    <BarChartImg />
  </BarChartContainer>
);

const SurveyCardTitleContainer = styled('div')`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.75rem 0.75rem 0 0.75rem;
  justify-content: space-between;
  ${Responses} {
    opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  }
`;

const PresentBtn = styled(ButtonBase)`
  stroke: #000;
  text-align: left;
  padding: 0;
  width: fit-content;
  &:hover {
    stroke: #ff7b3c;
  }
`;

const Template = () => (
  <TemplateImgContainer>
    <TemplateImg />
  </TemplateImgContainer>
);

const ResponseCount = ({
  responseCount,
  disabled,
  onClickTitle,
  cannotClick,
  active,
}) => {
  const responses = responseCount === 1 ? 'Response' : 'Responses';
  return (
    <Responses>
      <ResponsesContainer
        cannotClick={cannotClick}
        onClick={disabled ? null : onClickTitle}
      >
        <ResponsesInner>
          <BarChart />
          <div>
            {active && <span>Present </span>}
            <span>{` ${responseCount} ${responses}`}</span>
          </div>
        </ResponsesInner>
      </ResponsesContainer>
    </Responses>
  );
};

export default class SurveyCard extends Component {
  state = {
    isSettingsVisible: false,
    presentActive: false,
  };
  onClickTitle = () => {
    const { isAllSurveys, currentUser, survey } = this.props;
    if (isAllSurveys && !canCurrentUserEditSurvey(survey, currentUser.id)) {
      return;
    }
    const { titleLink, history, draft } = this.props;
    if (draft) return;
    const { location, state } = titleLink || {};
    if (location && state) return history.push(location, state);
    if (titleLink) return history.push(titleLink);
  };
  onClickConfig = () => {
    const {
      configLink,
      onClickConfig,
      surveyId,
      projectId,
      history,
    } = this.props;
    const { location, state } = configLink || {};
    if (onClickConfig)
      return onClickConfig({ surveyId, projectId, location, state });
    if (location && state) return history.push(location, state);
    if (configLink) return history.push(configLink);
  };
  onDeleteClick = (e) => {
    e.stopPropagation();
    const { surveyId, projectId, survey } = this.props;
    if (this.props.onDeleteClick)
      this.props.onDeleteClick({ surveyId, projectId, survey });
  };
  showSettings = () => this.setState({ isSettingsVisible: true });
  hideSettings = () => {
    this.setState({ isSettingsVisible: false });
  };

  render() {
    const {
      showSettings,
      hideSettings,
      onDeleteClick,
      onClickConfig,
      onClickTitle,
      props,
      state,
    } = this;
    const {
      responseCount = 0,
      isTemplateSurvey,
      hideMargin,
      formattedTimestamp,
      survey,
      onClickTemplate,
      onClickPlay,
      currentUser,
      fetchProjectSurveys,
      launchSurveyClick,
      fetchOrganizationSurveys,
      surveyLocks,
      history,
      isAllSurveys,
    } = props;
    const { surveyCode, surveyId, hilo } = survey;
    const { isSettingsVisible } = state;
    const disabled = responseCount === 0;
    const editDisabled = getEditDisabled({
      surveyDetails: survey,
      currentUser,
      surveyLocks,
      isAllSurveys,
    });
    const cannotClick =
      isAllSurveys && !canCurrentUserEditSurvey(survey, currentUser.id);
    const lock = (surveyLocks || {})[surveyId];
    const { id: userId } = currentUser;
    const { userId: lockedById, userName: lockedBy } = lock || {};
    const lockedByMe = userId === lockedById;
    const isBeforeExpireDate = lock ? getIsBeforeExpireDate(lock) : false;
    const isLocked = lockedByMe && isBeforeExpireDate ? true : editDisabled;
    const bottom = (
      <ResponsesAndConfig>
        <ConfigAndCodeName>
          <SurveyCode
            surveyCode={surveyCode}
            formattedTimestamp={formattedTimestamp}
            survey={survey}
            {...{
              isLocked,
              lockedBy,
            }}
          />
          <ConfigRow>
            {!editDisabled && (
              <Tooltip label="Edit">
                <Config onClick={onClickConfig} disabled={editDisabled}>
                  <GearImg />
                </Config>
              </Tooltip>
            )}
            <Tooltip label="Save as Template">
              <Config onClick={onClickTemplate}>
                <Template />
              </Config>
            </Tooltip>
            <Tooltip label="Preview">
              <Config onClick={onClickPlay}>
                <PlayImg />
              </Config>
            </Tooltip>
          </ConfigRow>
        </ConfigAndCodeName>
      </ResponsesAndConfig>
    );
    const surveyTitle = getSurveyTitle(survey);
    return (
      <>
        <SurveyCardContainer
          hideMargin={hideMargin}
          isTemplateSurvey={isTemplateSurvey}
          hiloType={hilo}
        >
          <>
            <SurveyCardTitleContainer {...{ disabled }}>
              <SurveyCardTitle cannotClick={cannotClick}>
                <Title
                  hiloType={hilo}
                  onMouseEnter={() => {
                    this.setState({ presentActive: true });
                  }}
                  onMouseLeave={() => {
                    this.setState({ presentActive: false });
                  }}
                  cannotClick={cannotClick}
                  active={this.state.presentActive}
                  onClick={onClickTitle}
                >
                  {surveyTitle}
                </Title>
                {!editDisabled && (
                  <Tooltip label="Manage">
                    <Config onClick={showSettings} disabled={editDisabled}>
                      <SettingsImg />
                    </Config>
                  </Tooltip>
                )}
              </SurveyCardTitle>
              {hilo === HILOS.WORKSHOP && (
                <Tooltip label="Enter facilitation mode">
                  <PresentBtn
                    onClick={() =>
                      window.open(`${WORKSHOP_ROOT}/${surveyCode}/1`)
                    }
                  >
                    <PresentIcon />
                  </PresentBtn>
                </Tooltip>
              )}
              <ResponseCount
                cannotClick={cannotClick}
                responseCount={responseCount}
                active={this.state.presentActive}
                disabled={disabled}
              />
            </SurveyCardTitleContainer>
            {bottom}
          </>
          <SurveyCardMenu
            {...{
              isSettingsVisible,
              hideSettings,
              onDeleteClick,
              survey,
              currentUser,
              reloadData: isAllSurveys
                ? fetchOrganizationSurveys
                : fetchProjectSurveys,
              launchSurveyClick,
              history,
            }}
          />
        </SurveyCardContainer>
      </>
    );
  }
}
