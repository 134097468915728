import React, { useEffect } from 'react';
import styled from 'styled-components';

import { useSelector } from 'react-redux';

import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { TrashMenu } from 'pages/Admin/admin-page-2';
import ModuleCard, { InsertModule } from './ModuleCard2';
import {
  MODULE_TYPES_DISPLAY,
  MODULE_TYPES_OVERLAY,
  MODULE_TYPES_DESCRIPTION,
  ADD_MODULE,
  EDIT_SURVEY,
  MANAGE_RESPONSES,
} from '../../const/module-types';
import SurveyCardMenu from '../SurveyCardMenu';
import AnimLoader from '../loader';

import EditImg from '../../img/edit3.svg';
import PlusImg from '../../img/add.svg';
import SettingsImg from '../../img/manage.svg';
import EditBlueImg from '../../img/edit-blue.svg';
import PlusBlueImg from '../../img/add-blue.svg';
import SettingsBlueImg from '../../img/manage-blue.svg';

// needs current meeting passed to it. or it can consume it from route context (DONE)
// Presentation Mode
// we will have entry modules and display modules
// let them select how they want to display
// load meeting on page landing
// use child component to pass meeting code to child (DONE)

export const Loading = styled('div')`
  display: ${(props) => (props.loading ? 'block' : 'none')};
`;

export const NoSurveyFound = styled(Loading)`
  display: ${(props) =>
    props.loading ? 'none' : props.surveyLoadError ? 'block' : 'none'};
`;

const ModulesContainer = styled('div')`
  margin: 0 80px;
  flex: 0;
`;

export const SurveyTitle = styled('input')`
  flex: 1 0 auto;
  height: 2.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Neue Haas Grotesk Display Std', sans-serif;
  font-size: 1.125rem;
  font-weight: bold;
  color: #333333;
  box-shadow: inset -1px 1px 0.1875rem rgba(0, 0, 0, 0.15);
  -webkit-appearance: none;
  background: white;
  padding: 0 0.625rem;
  border: none;
  pointer-events: auto;
`;

const TabsContainer = styled('div')`
  display: flex;
  justify-content: center;
  padding: 26px 0 22px 0;
  margin: 0 auto;
  border-bottom: 1px dashed black;
`;
const TabText = styled('div')`
  font-family: Neue Haas Grotesk Display Std;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  position: relative;
  margin-bottom: 8px;
`;
const TabIcon = styled('div')`
  width: 20px;
  height: 20px;
  background: url('${({ icon }) => icon || ''}') center/auto no-repeat;
`;
const TabHoverIcon = styled(TabIcon)`
  display: none;
`;
const TabContainer = styled('div')`
  cursor: pointer;
  opacity: ${(props) => (props.enabled ? 1 : 0.5)};
  pointer-events: ${(props) => (props.enabled ? 'auto' : 'none')};
  margin: 0 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  color: #262525;
  &:hover {
    ${TabText} {
      color: #1ca4fc;
    }
    ${TabIcon} {
      display: none;
    }
    ${TabHoverIcon} {
      display: block;
    }
  }
  ${(props) =>
    props.active
      ? `
    &:hover {
      color: #262525;
    }
    ${TabText} {
      &:before {
        content: ' ';
        position: absolute;
        bottom: -4px;
        left: 2px;
        right: 2px;
        height: 2px;
        background: #1ca4fc;
      }
      font-weight: bold;
    }
    
  `
      : ``}
`;
const Tab = ({ text, icon, hoverIcon, active, onClick, enabled }) => (
  <TabContainer active={active} onClick={onClick} enabled={enabled}>
    <TabText>{text}</TabText>
    <TabIcon icon={icon} />
    <TabHoverIcon icon={hoverIcon} />
  </TabContainer>
);

const ModulesHeader = styled('div')`
  font-family: Neue Haas Grotesk Display Std;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 94.86%;
  /* or 28px */
  letter-spacing: -0.01em;
  padding: 21px 0 46px 0;
  margin: 0 auto;
`;

const ModulesHeader2 = styled(ModulesHeader)`
  font-family: Neue Haas Grotesk Display Std;
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 34px;
  letter-spacing: -0.01em;
  padding-right: 0;
  display: ${(props) => (props.inEditMode ? 'none' : 'block')};
`;

const ModulesAndHeader = styled('div')`
  position: relative;
`;
const LeftOverlayContainer = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 3;
  background: #1ca4fc;
  padding: 28px 35px;
  color: #ffffff;
`;

const OverlayHeader = styled('div')`
  font-family: Neue Haas Grotesk Display Std;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 37px;
  letter-spacing: -0.01em;
  margin-bottom: 16px;
`;
const OverlayDescription = styled('div')`
  font-family: Neue Haas Grotesk Display Std;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 23px;
  letter-spacing: -0.01em;
  margin-bottom: 60px;
`;
const OverlayImage = styled('img')`
  width: 100%;
  display: none;
`;
const LeftOverlay = ({ leftOverlayId }) => {
  if (!leftOverlayId) return null;
  const header = MODULE_TYPES_DISPLAY[leftOverlayId];
  const image = MODULE_TYPES_OVERLAY[leftOverlayId];
  const desc = MODULE_TYPES_DESCRIPTION[leftOverlayId];
  return (
    <LeftOverlayContainer>
      <OverlayHeader>{header}</OverlayHeader>
      <OverlayDescription dangerouslySetInnerHTML={{ __html: desc }} />
      <OverlayImage src={image} />
    </LeftOverlayContainer>
  );
};

const ManageContainer = styled('div')``;
const ManageCell = styled('div')`
  flex: 0 0 65px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OuterContainer = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const InnerContainer = styled('div')`
  position: relative;
  overflow: auto;
  flex: 1;
`;

const LoaderContainer = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
  z-index: 5000;
`;

const Loader = () => (
  <LoaderContainer>
    <AnimLoader
      styles={{
        width: 311,
        height: 200,
        background: '#FFF',
        border: '3px solid #1CA4FC',
        borderRadius: '8px',
        position: 'absolute',
        top: '30%',
        left: '18%',
      }}
    />
  </LoaderContainer>
);

const SurveyModules = (props) => {
  const { type } = useSelector((state) => state.hilo);
  const {
    loading,
    surveyLoadError,
    survey,
    saving,
    saveDraft,
    saveSurvey,
    moveCard,
    onPreviewClick,
    onPresentationClick,
    onDupeClick,
    onMoveClick,
    onInsertClick,
    activeInsertIndex,
    setActiveTab,
    activeTab,
    removeModule,
    onTrashClick,
    loadSurvey,
    formikProps,
    leftOverlayId,
    inAddMode,
    inEditMode,
    inManageMode,
    modulesArray,
    isClosed,
    toggleClosed,
    emptyModules,
    currentUser,
    history,
    showSurveyLaunched,
    setEditingModule,
    clearEditingModule,
    editingModule,
    setActiveModule,
    clearActiveModule,
    activeModule,
    isTemplate,
    onModuleDrag,
    ...other
  } = props;
  const restoreFromPreview = () => {
    const { location, formikProps } = props;
    const { values, setValues } = formikProps;
    const { surveyDetails, moduleList, projectDetails } =
      (location && location.state) || {};
    // try to restore temporary location data (preview)
    if (surveyDetails && moduleList && projectDetails) {
      console.log('restoring temporary preview data');
      setValues({
        ...values,
        surveyDetails,
        moduleList,
        projectDetails,
      });
    }
  };
  const { initialLoadComplete } = props;
  useEffect(
    (prevProps) => {
      if (initialLoadComplete) restoreFromPreview();
    },
    [initialLoadComplete]
  );
  const getIsSaveDraftEnabled = () => {
    return props.formikProps.values.surveyDetails.name;
  };
  const getIsSaveEnabled = () => {
    const { modulesArray } = props;
    return getIsSaveDraftEnabled() && modulesArray.length > 0;
  };
  const { values } = formikProps;
  const { surveyDetails } = values;
  const error = (
    <NoSurveyFound loading={loading} surveyLoadError={surveyLoadError}>
      No {type} found with id {survey}
    </NoSurveyFound>
  );
  const isSaveDraftEnabled = getIsSaveDraftEnabled();
  const isSaveEnabled = getIsSaveEnabled();
  const modules = modulesArray.map((module) => {
    // render module input by type (XY, 22, etc)
    const { order, moduleId, moduleType } = module;
    const key = `moduleCard::${moduleId}`;
    return (
      <ModuleCard
        {...{
          ...other,
          moduleId,
          order,
          moduleType,
          key,
          moveCard,
          onPreviewClick,
          onPresentationClick,
          onDupeClick,
          onMoveClick,
          activeInsertIndex,
          onInsertClick,
          inEditMode,
          removeModule,
          saveDraft,
          saveSurvey,
          isSaveDraftEnabled,
          isSaveEnabled,
          loadSurvey,
          formikProps,
          setEditingModule,
          clearEditingModule,
          editingModule,
          setActiveModule,
          clearActiveModule,
          activeModule,
          onModuleDrag,
          modulesArray,
        }}
      />
    );
  });
  const tabs = (
    <TabsContainer>
      <Tab
        text="Add module"
        icon={PlusImg}
        hoverIcon={PlusBlueImg}
        active={inAddMode}
        enabled={!editingModule}
        onClick={() => setActiveTab(ADD_MODULE)}
      />
      <Tab
        text={`Edit ${type}`}
        icon={EditImg}
        hoverIcon={EditBlueImg}
        active={inEditMode}
        enabled={!emptyModules}
        onClick={() => setActiveTab(EDIT_SURVEY)}
      />
      {!isTemplate && (
        <Tab
          text="Manage responses"
          icon={SettingsImg}
          hoverIcon={SettingsBlueImg}
          active={inManageMode}
          enabled={!emptyModules && !editingModule}
          onClick={() => setActiveTab(MANAGE_RESPONSES)}
        />
      )}
    </TabsContainer>
  );
  const modulesHeader = !emptyModules ? (
    <ModulesHeader2 inEditMode={inEditMode}>
      Select a location below, then pick a module from the right.
    </ModulesHeader2>
  ) : (
    <ModulesHeader2>
      <strong>It’s a blank {type}!</strong>
      <div>Pick some modules from the right to get started.</div>
    </ModulesHeader2>
  );
  return (
    <>
      {saving && <Loader />}
      <OuterContainer>
        <LeftOverlay
          {...{
            leftOverlayId,
          }}
        />
        <ModulesContainer>
          {tabs}
          {error}
        </ModulesContainer>
        <InnerContainer>
          {inManageMode ? null : (
            <ModulesContainer>{modulesHeader}</ModulesContainer>
          )}
          {inAddMode || inEditMode ? (
            <ModulesAndHeader>
              <DndProvider backend={HTML5Backend}>
                <InsertModule
                  insertIndex={0}
                  activeInsertIndex={activeInsertIndex}
                  onInsertClick={onInsertClick}
                  inEditMode={inEditMode}
                />
                {modules}
              </DndProvider>
            </ModulesAndHeader>
          ) : null}
          {inManageMode && !loading ? (
            <ManageContainer>
              <SurveyCardMenu
                {...{
                  survey: surveyDetails,
                  currentUser,
                  inManageMode,
                  reloadData: loadSurvey,
                  history,
                  showSurveyLaunched,
                  toggleClosedExternal: toggleClosed,
                }}
              />
            </ManageContainer>
          ) : null}
        </InnerContainer>
        {inEditMode && !loading && activeModule ? (
          <TrashMenu onTrashClick={onTrashClick} inEditMode={inEditMode} />
        ) : null}
      </OuterContainer>
    </>
  );
};

export default SurveyModules;
