import { getUserName } from '../Helper';
import {
  PLOTTER22_QUADRANT_FOUR,
  PLOTTER22_QUADRANT_ONE,
  PLOTTER22_QUADRANT_THREE,
  PLOTTER22_QUADRANT_TWO,
} from '../../../const/module-types';

const formatPlotterDataFromResponse = (userResponses, moduleList, moduleId) => {
  const responses = Object.values(userResponses);
  return responses.reduce(
    (acc, response, currentIndex) => {
      const answerData = response.answerData;
      const moduleResponse = answerData[moduleId];
      if (!moduleResponse) return acc;
      const index = currentIndex + 1;
      const name = getUserName(
        response.userName,
        answerData,
        moduleList,
        index
      );
      const nameLabel = `${name}-${currentIndex}`;
      const explanation = moduleResponse.explanation || '';
      const plotterValues = moduleResponse.plotterValues || [];
      if (plotterValues.length === 0)
        return {
          ...acc,
          participants: [
            ...acc.participants,
            {
              name,
              label: nameLabel,
            },
          ],
          participantsData: {
            ...acc.participantsData,
            [nameLabel]: {
              data: [],
              explanation,
            },
          },
        };
      return {
        ...acc,
        participants: [
          ...acc.participants,
          {
            name,
            label: nameLabel,
          },
        ],
        participantsData: {
          ...acc.participantsData,
          [nameLabel]: {
            data: [
              {
                name,
                ...plotterValues[0],
              },
            ],
            explanation,
            id: response.responseCode,
          },
        },
      };
    },
    {
      participantsData: {},
      participants: [],
    }
  );
};

const getTotalNumberOfParticipantsWithResponse = (labelValues) => {
  const values = Object.values(labelValues);
  return values.reduce((acc, value) => {
    return acc + value;
  }, 0);
};

const isPositiveXY = (x, y) => x > 0 && y > 0;
const isPositiveYNegativeX = (x, y) => y > 0 && x < 0;
const isNegativeXNegativeY = (x, y) => x < 0 && y < 0;
const isPositiveXNegativeY = (x, y) => x > 0 && y < 0;

const getPlotter22LabelsNumbers = (data) => {
  const initial = {
    [PLOTTER22_QUADRANT_ONE]: 0,
    [PLOTTER22_QUADRANT_TWO]: 0,
    [PLOTTER22_QUADRANT_THREE]: 0,
    [PLOTTER22_QUADRANT_FOUR]: 0,
  };
  return Object.values(data).reduce((acc, currentValue) => {
    const { data } = currentValue;
    if (data.length === 0) return acc;
    const { x, y } = data[0];
    return {
      ...acc,
      [PLOTTER22_QUADRANT_ONE]: isPositiveXY(x, y)
        ? acc[PLOTTER22_QUADRANT_ONE] + 1
        : acc[PLOTTER22_QUADRANT_ONE],
      [PLOTTER22_QUADRANT_TWO]: isPositiveYNegativeX(x, y)
        ? acc[PLOTTER22_QUADRANT_TWO] + 1
        : acc[PLOTTER22_QUADRANT_TWO],
      [PLOTTER22_QUADRANT_THREE]: isNegativeXNegativeY(x, y)
        ? acc[PLOTTER22_QUADRANT_THREE] + 1
        : acc[PLOTTER22_QUADRANT_THREE],
      [PLOTTER22_QUADRANT_FOUR]: isPositiveXNegativeY(x, y)
        ? acc[PLOTTER22_QUADRANT_FOUR] + 1
        : acc[PLOTTER22_QUADRANT_FOUR],
    };
  }, initial);
};

const getStructuredPlotter22Data = (data) => {
  const reducedData = Object.values(data).reduce((acc, currentValue) => {
    const { data } = currentValue;
    if (data.length === 0) return acc;
    const { name, x, y } = data[0];
    const key = `${x}${y}`;
    acc[key] = {
      ...(acc[key] || {}),
      frequency: (acc[key] && acc[key].frequency + 1) || 1,
      x,
      y,
      members: [...((acc[key] && acc[key].members) || []), name],
      users: [...(acc[key]?.users || []), currentValue.id],
    };
    return acc;
  }, {});
  return Object.values(reducedData);
};

/**
 * Get average for the Plotter22 chart.
 * @param plotterValues
 * @param responseCount
 * @returns {{x: number, y: number}[]}
 */
function getAveragePoint(plotterValues, responseCount) {
  const plotterTotalsX = Object.values(plotterValues).reduce(
    (xcollect, pointValue) => {
      const { data } = pointValue;
      if (data.length === 0) return xcollect;
      return xcollect + data[0].x;
    },
    0
  );
  const plotterTotalsY = Object.values(plotterValues).reduce(
    (ycollect, pointValue) => {
      const { data } = pointValue;
      if (data.length === 0) return ycollect;
      return ycollect + data[0].y;
    },
    0
  );
  const averageX = plotterTotalsX / responseCount;
  const averageY = plotterTotalsY / responseCount;
  return [
    {
      x: averageX,
      y: averageY,
    },
  ];
}

const getAxisLabels = ({
  positiveXLabel,
  negativeXLabel,
  positiveYLabel,
  negativeYLabel,
}) => ({
  positiveXLabel,
  negativeXLabel,
  positiveYLabel,
  negativeYLabel,
});

const getPlotterValuesOnXAxis = (data) =>
  data.map(({ name, x }) => ({ name, x, y: 0 }));

const getPlotterValuesOnYAxis = (data) =>
  data.map(({ name, y }) => ({ name, y, x: 0 }));

const getPlotter22Data = (
  userResponses,
  questionData,
  moduleList,
  moduleId
) => {
  const { participantsData, participants } = formatPlotterDataFromResponse(
    userResponses,
    moduleList,
    moduleId
  );
  const chartData = getStructuredPlotter22Data(participantsData);
  const labelValues = getPlotter22LabelsNumbers(participantsData);
  const axisLabels = getAxisLabels(questionData);
  const numberOfParticipantsWithResponses = getTotalNumberOfParticipantsWithResponse(
    labelValues
  );
  const average = getAveragePoint(
    participantsData,
    numberOfParticipantsWithResponses
  );
  return {
    average,
    chartData,
    axisLabels,
    labelValues,
    participants,
    participantsData,
    chartDataCopy: chartData,
    numberOfParticipantsWithResponses,
  };
};

export { getPlotter22Data, getPlotterValuesOnXAxis, getPlotterValuesOnYAxis };
