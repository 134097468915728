import React from 'react';
import styled from 'styled-components';

const Description = styled('div')`
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
  font-weight: 500;
  color: #3faffa;
  font-size: 1.25rem;
`;

const Question = styled('h1')`
  font-size: 40px;
  font-weight: bold;
  font-family: Roboto;
  color: #000;
  margin-bottom: 30px;
  margin-top: 0px;
`;

const TextSlide = ({ text, question }) => (
  <>
    <Question>{question}</Question>
    {/* <Description>{text}tit</Description> */}
  </>
);

export default TextSlide;
