import styled from 'styled-components';
import React from 'react';
import PlusImg from 'img/plus.svg';
import CrossWhite from 'img/cross-white.svg';
import Trash2Img from 'img/trash2.svg';

const activeIconStyle = `
  background: white;
  color: #FF9B3A;
  font-weight: bold;
  padding-top: 3px;
`;

export const TeamBarContainer = styled('div')`
  background: #ff9b3a;
  padding: 0 10px;
  display: flex;
  align-items: center;
  position: relative;
  box-shadow: 0.125rem 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
`;

export const TeamTitle = styled('div')`
  font-family: Neue Haas Grotesk Display Std;
  font-size: 18px;
  line-height: 22px;
  font-weight: bold;
  flex: 1;
`;

export const TeamIconContainer = styled('div')`
  border-radius: 50%;
  width: 25px;
  height: 25px;
  border: 1.5px solid #ffffff;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: Neue Haas Grotesk Display Std;
  font-size: 10px;
  line-height: 11px;
  cursor: pointer;
  margin: 0 2px;
  ${(props) => (props.isActive ? activeIconStyle : ``)}
  &:hover {
    ${activeIconStyle}
  }
`;

export const TeamIconInitials = styled('div')``;

export const TeamIconsContainer = styled('div')`
  display: flex;
  height: 40px;
  align-items: center;
  cursor: pointer;
`;

export const TeamModalContainer = styled('div')`
  position: absolute;
  width: 100%;
  max-height: 17.1875rem;
  z-index: 4;
  overflow: auto;
  top: 40px;
  left: 0;
  padding-top: 20px;
  cursor: pointer;
`;

export const TeamModalBox = styled('div')`
  background: #ff9b3a;
  box-shadow: 0.125rem 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
`;

export const TeamAdd = styled('div')`
  width: 40px;
  height: 40px;
  background: ${(props) =>
      props.open ? `url('${CrossWhite}')` : `url('${PlusImg}')`}
    center/auto no-repeat;
  margin-right: -10px;
  cursor: pointer;
`;

const Hover = styled('div')`
  width: 36px;
  height: 36px;
  float: right;
  opacity: 0;
  transition: opacity 0.1s;
`;

export const HoverAdd = styled(Hover)`
  background: url('${PlusImg}') center/auto no-repeat;
`;

export const HoverRemove = styled(Hover)`
  background: url('${Trash2Img}') center/auto no-repeat;
`;

export const TeamModalMember = styled('div')`
  color: white;
  font-family: 'Neue Haas Grotesk Display Std', sans-serif;
  font-size: 1.5rem;
  font-weight: normal;
  line-height: 1.5;
  transition: background 0.1s;
  padding: 0 0.625rem;
  cursor: ${(props) => (props.onClick ? 'pointer' : 'auto')};
  &:hover {
    background: #d47f34;
    ${HoverAdd}, ${HoverRemove} {
      opacity: 1;
    }
  }
`;
