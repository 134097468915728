import React from 'react';
import ChartControls from './ChartControls';
import {
  Madlib,
  PointDistribution,
  StackRank,
  Plotter22,
  SpectrumDistribution,
  MultiSelect,
  OpenQuestion,
  TextSlide,
  AddOptions,
} from '../PresentationModules';
import {
  ADD_OPTION,
  MADLIB,
  MULTI_SELECT,
  OPEN_ENDED,
  PLOTTER_22,
  POINT_DISTRIBUTION,
  SPECTRUM_DISTRIBUTION,
  STACK_RANK,
  TEXT_SLIDE,
} from '../../const/module-types';
import {
  getQuestionDescription,
  getQuestionText,
} from '../../lib/Presentation';
import { useSelector } from 'react-redux';

const Chart = ({
  mode,
  currentUser,
  comparisonSection,
  onClickSortAsc,
  onClickSortDesc,
  onClickPlotterAverage,
  onCollapseXAxisPlotter22,
  onCollapseYAxisPlotter22,
  className,
  activeQuestion,
  showTextSlideQuestion,
  onClickOpenQuestionResponse,
  onClickOpenQuestionWordCloud,
  openQuestionBubblesAnimationControls,
}) => {
  const showChartControls = useSelector(
    (state) => state.presentation[activeQuestion.moduleId]?.showChartControls
  );

  return (
    <div
      css={[
        {
          display: 'flex',
          flexDirection: 'column',
          height: 'auto',
          background: 'white',
          paddingBottom: '7%',
          marginBottom: '-30%',
          paddingLeft:
            (activeQuestion.moduleType === PLOTTER_22 ||
              activeQuestion.moduleType === OPEN_ENDED) &&
            '10%',
          paddingRight:
            (activeQuestion.moduleType === PLOTTER_22 ||
              activeQuestion.moduleType === OPEN_ENDED) &&
            '10%',
        },
        className,
      ]}
    >
      {showChartControls && (
        <ChartControls
          moduleId={activeQuestion.moduleId}
          onClickSortAsc={onClickSortAsc}
          onClickSortDesc={onClickSortDesc}
          onClickPlotter22Average={onClickPlotterAverage}
          isPlotter22={activeQuestion.moduleType === PLOTTER_22}
          isOpenQuestion={activeQuestion.moduleType === OPEN_ENDED}
          isStackRank={activeQuestion.moduleType === STACK_RANK}
          onClickOpenQuestionResponse={onClickOpenQuestionResponse}
          onClickOpenQuestionWordCloud={onClickOpenQuestionWordCloud}
        />
      )}

      {activeQuestion.moduleType === POINT_DISTRIBUTION && (
        <PointDistribution
          comparisonSection={comparisonSection}
          moduleId={activeQuestion.moduleId}
          mode={mode}
        />
      )}
      {activeQuestion.moduleType === STACK_RANK && (
        <StackRank
          comparisonSection={comparisonSection}
          moduleId={activeQuestion.moduleId}
          mode={mode}
        />
      )}
      {activeQuestion.moduleType === PLOTTER_22 && (
        <Plotter22
          mode={mode}
          moduleId={activeQuestion.moduleId}
          comparisonSection={comparisonSection}
          onCollapseXAxisPlotter22={onCollapseXAxisPlotter22}
          onCollapseYAxisPlotter22={onCollapseYAxisPlotter22}
        />
      )}
      {activeQuestion.moduleType === SPECTRUM_DISTRIBUTION && (
        <SpectrumDistribution
          mode={mode}
          moduleId={activeQuestion.moduleId}
          comparisonSection={comparisonSection}
        />
      )}
      {activeQuestion.moduleType === TEXT_SLIDE && (
        <TextSlide
          question={
            showTextSlideQuestion ? getQuestionText(activeQuestion) : ''
          }
          text={getQuestionDescription(activeQuestion)}
        />
      )}
      {activeQuestion.moduleType === MADLIB && (
        <Madlib
          mode={mode}
          moduleId={activeQuestion.moduleId}
          comparisonSection={comparisonSection}
        />
      )}
      {activeQuestion.moduleType === OPEN_ENDED && (
        <OpenQuestion
          mode={mode}
          moduleId={activeQuestion.moduleId}
          comparisonSection={comparisonSection}
          bubblesAnimationsControls={openQuestionBubblesAnimationControls}
        />
      )}
      {activeQuestion.moduleType === MULTI_SELECT && (
        <MultiSelect
          mode={mode}
          moduleId={activeQuestion.moduleId}
          comparisonSection={comparisonSection}
        />
      )}
      {activeQuestion.moduleType === ADD_OPTION && (
        <AddOptions
          mode={mode}
          moduleId={activeQuestion.moduleId}
          comparisonSection={comparisonSection}
          currentUser={currentUser}
        />
      )}
    </div>
  );
};

export default Chart;
