import React from 'react';
import { colors } from 'const/theme';
import { isNull } from 'lodash';

const bubbleFill = (
  datum,
  initialRender,
  optionIndex,
  selectedOption,
  selectAll,
  selectedResponseId
) => {
  if (selectedResponseId && selectedResponseId === datum.responseId) {
    return colors.SELECTED_CHART_FILL_COLOR;
  }
  return isNull(selectedOption) && selectAll
    ? datum.color
    : (initialRender && optionIndex !== 0) || selectedOption !== optionIndex
    ? '#c4c4c4'
    : datum.color;
};

const ScatterPoint = ({
  x,
  y,
  size,
  datum,
  initialRender,
  optionIndex,
  selectedOption,
  selectAll,
  onClickBubble,
  selectedParticipantResponseId,
}) => {
  const onClick = () => {
    onClickBubble(datum);
  };

  return (
    <circle
      cx={x}
      cy={y}
      r={size}
      stroke={
        selectedParticipantResponseId === datum.responseId
          ? colors.STROKE_CHART_COLOR
          : null
      }
      strokeWidth={1}
      fill={bubbleFill(
        datum,
        initialRender,
        optionIndex,
        selectedOption,
        selectAll,
        selectedParticipantResponseId
      )}
      onClick={onClick}
    />
  );
};

export default ScatterPoint;
