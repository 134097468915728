import { configureStore } from '@reduxjs/toolkit';
import hiloReducer from '../pages/store/hiloSlice';
import presentationReducer from '../pages/presentationSlice';
import surveyBuilderReducer from 'pages/SurveyBuilder/slice';
import workshopReducer from 'pages/workshopSlice';

export default configureStore({
  reducer: {
    hilo: hiloReducer,
    presentation: presentationReducer,
    surveyBuilder: surveyBuilderReducer,
    workshop: workshopReducer,
  },
});
