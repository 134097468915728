import React, { useEffect, useRef, useState } from 'react';
import {
  TeamModalContainer,
  TeamIconsContainer,
  TeamIconContainer,
  TeamBarContainer,
  TeamIconInitials,
  TeamModalMember,
  TeamModalBox,
  HoverRemove,
  TeamTitle,
  HoverAdd,
  TeamAdd,
} from './styles';

const getInitials = (name) => {
  if (!name) return 'No Name';
  const nameSplit = name.split(' ');
  const first = nameSplit[0] ? nameSplit[0][0] : '';
  const last = nameSplit[1] ? nameSplit[1][0] : '';
  return `${first.toUpperCase()}${last.toUpperCase()}`;
};

const TeamModal = ({ children, ...other }) => (
  <TeamModalContainer {...other}>
    <TeamModalBox>{children}</TeamModalBox>
  </TeamModalContainer>
);

const TeamIcon = ({ userId, projectId, name, onRemoveClick }) => {
  const onClick = () => {
    return (
      onRemoveClick &&
      onRemoveClick({
        userId: userId,
        projectId: projectId,
      })
    );
  };
  const initials = getInitials(name);
  return (
    <TeamIconContainer title={name} /*onClick={onClick}*/>
      <TeamIconInitials>{initials}</TeamIconInitials>
    </TeamIconContainer>
  );
};

const TeamIcons = ({
  selectedProject,
  currentProjectUsers = [],
  onRemoveClick,
  onClickIcon,
}) => {
  const icons = currentProjectUsers.map(([id, user]) => (
    <TeamIcon
      key={id}
      userId={id}
      projectId={selectedProject}
      name={user.name}
      onRemoveClick={onRemoveClick}
    />
  ));
  return <TeamIconsContainer onClick={onClickIcon}>{icons}</TeamIconsContainer>;
};

const TeamModalMembersAdd = ({ members = [], addNewTeamMember }) => {
  const teamMembers = members
    .filter(({ alreadyInTeam }) => !alreadyInTeam)
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(({ userId, name, alreadyInTeam }) => {
      return (
        <TeamModalMember
          key={userId}
          data-id={userId}
          data-name={name}
          onClick={addNewTeamMember}
          alreadyInTeam={alreadyInTeam}
        >
          {name}
          <HoverAdd />
        </TeamModalMember>
      );
    });
  return (
    <TeamModal>
      {teamMembers.length > 0 ? (
        teamMembers
      ) : (
        <TeamModalMember>No users to add</TeamModalMember>
      )}
    </TeamModal>
  );
};

const TeamModalRemove = ({ members = [], onRemoveClick }) => {
  const teamMembers = members
    .filter(({ alreadyInTeam }) => alreadyInTeam)
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(({ userId, name, alreadyInTeam }) => {
      return (
        <TeamModalMember
          key={userId}
          data-id={userId}
          data-name={name}
          onClick={onRemoveClick}
          alreadyInTeam={alreadyInTeam}
        >
          {name}
          <HoverRemove />
        </TeamModalMember>
      );
    });
  return (
    <TeamModal>
      {teamMembers.length > 0 ? (
        teamMembers
      ) : (
        <TeamModalMember>No users to remove</TeamModalMember>
      )}
    </TeamModal>
  );
};

export const Team = ({
  onRemoveClick,
  members,
  addNewTeamMember,
  selectedProject,
  currentProjectUsers,
}) => {
  const ref = useRef(null);

  const [
    addTeamMembersModalVisibility,
    setAddTeamMembersModalVisibility,
  ] = useState(false);
  const [
    removeTeamMembersModalVisibility,
    setRemoveAddTeamMembersModalVisibility,
  ] = useState(false);

  useEffect(() => {
    // Close any team dialog if the user clicks away from it.
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setAddTeamMembersModalVisibility(false);
        setRemoveAddTeamMembersModalVisibility(false);
      }
    };

    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  /**
   * Close the already added modal if we are opening the add
   * members modal or if we are closing the already
   * added members modal since we use the same close icon.
   */
  const onClickTeamModalAddCloseIcon = () => {
    if (removeTeamMembersModalVisibility) {
      setAddTeamMembersModalVisibility(false);
    } else {
      setAddTeamMembersModalVisibility(!addTeamMembersModalVisibility);
    }
    setRemoveAddTeamMembersModalVisibility(false);
  };

  const onClickMemberIcon = () => {
    setAddTeamMembersModalVisibility(false);
    setRemoveAddTeamMembersModalVisibility(true);
  };

  return (
    <TeamBarContainer ref={ref}>
      <TeamTitle>Team</TeamTitle>
      <TeamIcons
        selectedProject={selectedProject}
        currentProjectUsers={currentProjectUsers}
        onRemoveClick={onRemoveClick}
        onClickIcon={onClickMemberIcon}
      />
      <TeamAdd
        onClick={onClickTeamModalAddCloseIcon}
        open={addTeamMembersModalVisibility || removeTeamMembersModalVisibility}
      />
      {addTeamMembersModalVisibility && (
        <TeamModalMembersAdd
          members={members}
          addNewTeamMember={addNewTeamMember}
        />
      )}
      {removeTeamMembersModalVisibility && (
        <TeamModalRemove members={members} onRemoveClick={onRemoveClick} />
      )}
    </TeamBarContainer>
  );
};
