import React, { useState, useContext } from "react";
import { DialogContent } from "@reach/dialog";
import "@reach/dialog/styles.css";
import { AuthUserContext } from "../../auth-context";
import { saveTemplate } from "../../../lib/admin-api";
import {
  Container,
  Error,
  SuccessMessage,
  SuccessHeader,
  SuccessContainer,
  Right,
  ConfirmButton,
  Text,
  Description,
  TemplateName,
  TemplateDialog,
  ContentContainer,
  HorizontalRule
} from "./styles";
import {TemplateGalleryLink} from "../index";
import {HILOS} from "../../../const";

const Cross = () => (
  <svg
    width="24"
    height="30"
    viewBox="0 0 24 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      y1="-1"
      x2="25.0805"
      y2="-1"
      transform="matrix(0.606927 -0.606928 0.606927 0.606928 4.677 29.2734)"
      stroke="#1CA4FC"
      strokeWidth="2"
    />
    <line
      y1="-1"
      x2="25.0805"
      y2="-1"
      transform="matrix(-0.606928 -0.606927 0.606928 -0.606927 19.8992 28.0052)"
      stroke="#1CA4FC"
      strokeWidth="2"
    />
  </svg>
);

const TEMPLATE_NAME_ERROR = "Template name is required";
const TEMPLATE_DESCRIPTION_ERROR = "Template description is required";
const TEMPLATE_SAVE_FAILED_ERROR = "Failed to create the template";

const TemplateCreationDialog = ({
  showDialog = false,
  onDismiss,
  surveyId
}) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const context = useContext(AuthUserContext);

  /**
   * Check if there is an error for either the name or description
   * @param value
   * @param errorMessage
   * @returns {unknown}
   */
  function isValueAvailable(value, errorMessage) {
    return error && error === errorMessage && value;
  }

  function isInputError(input) {
    if (input === "name") {
      return error && error === TEMPLATE_NAME_ERROR;
    }

    if (input === "description") {
      return error && error === TEMPLATE_DESCRIPTION_ERROR;
    }
  }

  const onChange = ({ target }) => {
    const { name, value } = target;
    if (name === "name") {
      setName(value);
      if (isValueAvailable(value, TEMPLATE_NAME_ERROR)) {
        setError(null);
      }
    }
    if (name === "description") {
      setDescription(value);
      if (isValueAvailable(value, TEMPLATE_DESCRIPTION_ERROR)) {
        setError(null);
      }
    }
  };

  const onClickConfirm = async () => {
    if (!name) {
      setError(TEMPLATE_NAME_ERROR);
      return;
    }
    if (!description) {
      setError(TEMPLATE_DESCRIPTION_ERROR);
      return;
    }
    setError(null);
    setLoading(true);
    const organizationId = context.claims.organizationId;
    const details = {
      name,
      description,
      surveyId: surveyId,
      published: false,
      organizationId,
      creatorName: context.authUser.displayName,
      creatorId: context.authUser.uid,
      organizationName: context.claims.organizationName,
      template: true,
      hilo: HILOS.SURVEY
    };

    try {
      await saveTemplate(organizationId, details);
      setLoading(false);
      setSuccess(true);
    } catch (e) {
      setError(TEMPLATE_SAVE_FAILED_ERROR);
      setSuccess(false);
      console.log('error', e)
    }
  };

  /**
   * Do not close the Dialog when au ser has typed a name or description
   */
  const onCloseDialogFromClickingAway = () => {
    if (name || description) {
      return;
    }
    setName("");
    setDescription("");
    setError(null);
    onDismiss({});
  };

  const onClose = () => {
    setName("");
    setDescription("");
    setError(null);
    setSuccess(false);
    onDismiss({});
  };

  function renderTemplateForm() {
    if (isSuccess) return null;
    return (
      <>
        <TemplateName
          type="text"
          placeholder="Template name"
          name="name"
          onChange={onChange}
        />
        <HorizontalRule isError={isInputError("name")} />
        <Description
          name="description"
          onChange={onChange}
          id="description"
          cols="30"
          rows="3"
          placeholder="Description"
        />
        <HorizontalRule isError={isInputError("description")} />
        <Text>{`Creator: ${context.authUser && context.authUser.displayName || ""}`}</Text>
        <HorizontalRule />
        <Text>{`Organization: ${context.claims &&context.claims.organizationName}`}</Text>
        <HorizontalRule />
        <Right isError={error}>
          {error && <Error>{error}</Error>}
          <ConfirmButton onClick={onClickConfirm} isLoading={isLoading}>
            Confirm
          </ConfirmButton>
        </Right>
      </>
    );
  }

  function renderSuccessMessage() {
    if (!isSuccess) return null;
    return (
      <SuccessContainer>
        <SuccessHeader>
          You have successfully created the template!
        </SuccessHeader>
        <SuccessMessage>
          Congrats! You can now go to template gallery to check out your template
        </SuccessMessage>
        <TemplateGalleryLink paddingTop={40}/>
      </SuccessContainer>
    );
  }

  return (
    <TemplateDialog
      isOpen={showDialog}
      onDismiss={onCloseDialogFromClickingAway}
    >
      <DialogContent aria-label="Creating a template">
        <ContentContainer>
          <Right>
            <Container onClick={onClose}>
              <Cross />
            </Container>
          </Right>
          {renderTemplateForm()}
          {renderSuccessMessage()}
        </ContentContainer>
      </DialogContent>
    </TemplateDialog>
  );
};

export default TemplateCreationDialog;
