import React, { Component } from 'react';
import { Prompt } from 'react-router';
import styled from 'styled-components';
import { Button, Explanation } from '../../components/layout';
import {
  EXPLANATION,
  PLOTTER_XY,
  PLOTTER_22,
  TOTAL_POINTS,
  POINT_DISTRIBUTION,
  REQUIRED,
  SPECTRUM,
  PLOTTER_VALUES,
  STACK_RANK,
  TEXT_HIGHLIGHT,
  SPECTRUM_DISTRIBUTION,
  HIGHLIGHT_VALUES,
  MATRIX,
  PERSONAL_INFO,
  MADLIB,
  TEXT_SLIDE,
  SPECTRUM_VALUE,
  typeToValueKey,
  OPEN_ENDED,
  MULTI_SELECT,
  ADD_OPTION,
} from '../../const/module-types';
import {
  SURVEYROOT,
  RESULTROOT,
  COMPLETE,
  PRESENTATIONROOT,
  PREVIEWROOT,
  FACILITATIONROOT,
} from '../../const/routes';
import {
  surveyStateToAnswerData,
  getCurrentPoints,
  saveSurveyResponse,
  saveResponse,
} from '../../lib/survey-api';
import { getNestedData } from '../../const/nested-data';
import SpectrumDistribution from '../../components/survey-components/SpectrumDistribution';
import queryString from 'query-string';
import { getSurveyResponseWithResponseId } from '../../lib/survey-api';
import Tracking from 'lib/tracking/tracking';
import { surveyTakerEvents } from 'lib/tracking/events';

export const PREV = 'PREV';
export const NEXT = 'NEXT';
export const SAVE = 'SAVE';

const SurveyBottomContainer = styled('div')`
  bottom: 6px;
  left: 0;
  width: 100%;
  padding: 0 1rem;
  z-index: 2;
  position: absolute;
  ${({ inMobileView, isMultiSelect }) =>
    inMobileView
      ? ``
      : `
  @media (min-width: 769px) {
    position: ${isMultiSelect ? 'relative' : 'absolute'};
  }
  `}
  ${({ inMobileView }) =>
    inMobileView
      ? ``
      : `
  @media (min-width: 1200px) {
    position: relative;
    bottom: auto;
    padding: 0;
    width: auto;
    min-width: 100%;
  }
  `}
`;

const ButtonContainer = styled('div')`
  width: 100%;
  max-width: 21rem;
  display: flex;
  justify-content: space-between;
  min-height: 2.5rem;
  margin: 0 auto;
  ${({ inMobileView }) =>
    inMobileView
      ? ``
      : `
  @media (min-width: 1200px) {
    margin: 1.25rem auto 0.75rem auto;
    max-width: 100%;
  }
  `}
  pointer-events: ${(props) => (props.inEditMode ? 'none' : 'auto')};
`;

const ButtonsLeft = styled('div')`
  display: flex;
  flex: 0 0 auto;
  justify-content: flex-start;
`;

const ButtonsCenter = styled('div')`
  display: flex;
  flex: 1 0 auto;
  justify-content: center;
  align-items: center;
  margin: 0 1.25rem;
`;

const ButtonsRight = styled('div')`
  display: flex;
  flex: 0 0 auto;
  justify-content: flex-end;
`;

const HideAndShowButton = styled(Button)`
  opacity: ${(props) => (props.enabled ? (props.active ? 1 : 0.5) : 0)};
  display: ${(props) => (props.enabled ? 'flex' : 'none')};
  pointer-events: ${(props) =>
    props.enabled && props.active ? 'auto' : 'none'};
`;

const DisabledButton = styled(HideAndShowButton)`
  color: #828282;
  background: #e0e0e0;
`;

const ActiveButton = styled(HideAndShowButton)`
  color: white;
  background: #ff9b3a;
`;

const EnabledButton = styled(HideAndShowButton)`
  color: white;
  background: #1ca4fc;
`;

const ExplanationContainer = styled('div')`
  width: 100%;
  max-width: 21rem;
  display: ${(props) => (props.enabled ? 'block' : 'none')};
  margin: 0 auto 9px auto;
  ${({ inMobileView }) =>
    inMobileView
      ? ``
      : `
  @media (min-width: 1200px) {
    max-width: 100%;
  }
  `}
`;

const ProgressContainer = styled('div')`
  height: 0.625rem;
  border-radius: 0.75rem;
  background-color: #ffffff;
  width: 100%;
`;

const ProgressBar = styled('div')`
  height: 0.625rem;
  border-radius: 0.75rem;
  width: ${(props) => props.width || '0'};
  background-color: #1ca4fc;
`;

const Progress = ({ percentage }) => (
  <ProgressContainer>
    <ProgressBar width={percentage} />
  </ProgressContainer>
);

export default class SurveyBottom extends Component {
  isDirty = () => {
    const answerData = this.getAnswerData();
    const dirty = Object.keys(answerData).length > 0;
    const {
      currentSlide,
      inPresentationMode,
      inPreviewMode,
      inResultMode,
      inEditMode,
      inFacilitationMode,
    } = this.props;
    const isComplete = currentSlide === COMPLETE;
    const isDirty =
      dirty &&
      !isComplete &&
      !inPreviewMode &&
      !inFacilitationMode &&
      !inPresentationMode &&
      !inResultMode &&
      !inEditMode;
    return isDirty;
  };
  beforeUnload = (e) => {
    if (this.isDirty()) {
      e.preventDefault();
      e.returnValue = 'o/';
    }
  };
  async componentDidMount() {
    const { currentSlide } = this.props;
    await this.onSetSurveyResponse(currentSlide);
    window.addEventListener('beforeunload', this.beforeUnload);
  }
  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.beforeUnload);
  }
  getAnswerData(options = {}) {
    const { values } = options;
    const {
      plotterData,
      stackRank,
      pointDistribution,
      textHighlight,
      spectrumDistribution,
      explanation,
      matrix,
      personalInfo,
      openEnded,
      multiSelect,
      addOption,
      madlib,
    } = values || this.props.values;
    const answerData = surveyStateToAnswerData({
      plotterData,
      stackRank,
      pointDistribution,
      textHighlight,
      spectrumDistribution,
      explanation,
      matrix,
      personalInfo,
      madlib,
      openEnded,
      multiSelect,
      addOption,
    });
    return answerData;
  }
  isRequiredOk() {
    const {
      questionData,
      moduleType,
      moduleId,
      inResultMode,
      inPresentationMode,
    } = this.props;
    if (!questionData) return false;
    if (inResultMode) return true;
    if (inPresentationMode) return true;
    const { [REQUIRED]: required, ...restOfQuestionData } = questionData;
    const moduleResponse = this.getAnswerData()[moduleId] || {};
    // if question isn't required, then the required state is ok
    if (!required) return true;
    // if the question is required... (return false for all failed test cases)
    if ([PLOTTER_XY, PLOTTER_22].includes(moduleType)) {
      const { [PLOTTER_VALUES]: plotterValues } = moduleResponse;
      if (plotterValues && plotterValues.length > 0) return true;
      return false;
    }
    if ([POINT_DISTRIBUTION, MATRIX].includes(moduleType)) {
      const { [TOTAL_POINTS]: totalPointsText = 0 } = questionData;
      const totalPoints = parseInt(totalPointsText, 10);
      const currentPoints = getCurrentPoints(moduleResponse);
      if (totalPoints === 0) return true;
      if (currentPoints === totalPoints) return true;
      return false;
    }
    if (
      [
        STACK_RANK,
        TEXT_SLIDE,
        PERSONAL_INFO,
        OPEN_ENDED,
        MULTI_SELECT,
        ADD_OPTION,
      ].includes(moduleType)
    ) {
      return true;
    }
    if ([TEXT_HIGHLIGHT].includes(moduleType)) {
      const { [HIGHLIGHT_VALUES]: highlightValues } = moduleResponse;
      if (highlightValues && highlightValues.length > 0) return true;
      return false;
    }
    if ([SPECTRUM_DISTRIBUTION].includes(moduleType)) {
      const { [SPECTRUM_VALUE]: spectrumValue } = moduleResponse;
      if (spectrumValue && spectrumValue.length > 0) return true;
      return false;
    }
    if ([MADLIB].includes(moduleType)) {
      // todo with new format
      return true;
    }
    return true;
  }
  isPrevEnabled() {
    const { currentSlide } = this.props;
    return parseInt(currentSlide, 10) > 1;
  }
  isSaveEnabled() {
    const { resultSurveyCode, currentSlide, lastSlide } = this.props;
    if (resultSurveyCode) return false;
    return this.isRequiredOk() && currentSlide === lastSlide;
  }
  isNextEnabled() {
    const { currentSlide, lastSlide } = this.props;
    return this.isRequiredOk() && currentSlide < lastSlide;
  }
  getResponseId() {
    const parsedQuery = queryString.parse(
      this.props.location && this.props.location.search
    );
    const { responseId } = parsedQuery;
    return responseId;
  }
  onClickPrev = async () => {
    const {
      surveyCode,
      history,
      currentSlide,
      inEditMode,
      inPreviewMode,
      inResultMode,
      resultSurveyCode,
      inFacilitationMode,
      setSubmitting,
      moduleType,
    } = this.props;
    if (!this.isPrevEnabled()) return;
    if (inEditMode) return;
    const newSlide = parseInt(currentSlide, 10) - 1;
    let rootPath = SURVEYROOT;
    if (inPreviewMode) rootPath = PREVIEWROOT;
    if (inResultMode) rootPath = RESULTROOT;
    if (inPreviewMode || inResultMode) {
      const nextPath = resultSurveyCode
        ? `${RESULTROOT}/${surveyCode}/${newSlide}/${resultSurveyCode}`
        : `${rootPath}/${surveyCode}/${newSlide}`;
      history.push(nextPath, this.props.location.state);
      return;
    }

    if (inFacilitationMode) {
      history.push(`${FACILITATIONROOT}/${surveyCode}/${newSlide}`);
      return;
    }
    Tracking.event({
      name: surveyTakerEvents.MODULE_PREV_BUTTON,
      properties: {
        moduleFrom: moduleType,
        modulePathCurrent: `${SURVEYROOT}/${surveyCode}/${newSlide}`,
        modulePathNext: `${SURVEYROOT}/${surveyCode}/${newSlide + 1}`,
      },
    });

    const responseId = this.getResponseId();
    //Redirect to first slide if no response Id
    if (!responseId) {
      history.push(`${SURVEYROOT}/${surveyCode}/1`);
      return;
    }

    this.onSaveResponseWithResponseId(responseId)
      .then(async () => {
        setSubmitting(false);
        history.push(
          `${SURVEYROOT}/${surveyCode}/${newSlide}?responseId=${responseId}`
        );
      })
      .catch((e) => console.log(e));

    await this.onSetSurveyResponse(newSlide);
  };

  onClickNext = async () => {
    const {
      currentSlide,
      surveyCode,
      resultSurveyCode,
      history,
      inEditMode,
      inPreviewMode,
      inResultMode,
      setSubmitting,
      inFacilitationMode,
      moduleType,
    } = this.props;
    if (!this.isNextEnabled()) return;
    if (inEditMode) return;
    const newSlide = currentSlide + 1;
    let rootPath = SURVEYROOT;
    if (inPreviewMode) rootPath = PREVIEWROOT;
    if (inResultMode) rootPath = RESULTROOT;
    if (inPreviewMode || inResultMode) {
      const nextPath = resultSurveyCode
        ? `${RESULTROOT}/${surveyCode}/${newSlide}/${resultSurveyCode}`
        : `${rootPath}/${surveyCode}/${newSlide}`;
      history.push(nextPath, this.props.location.state);
      return;
    }

    if (inFacilitationMode) {
      history.push(`${FACILITATIONROOT}/${surveyCode}/${newSlide}`);
      return;
    }

    this.setModuleTimestamps();
    Tracking.event({
      name: surveyTakerEvents.MODULE_NEXT_BUTTON,
      properties: {
        moduleFrom: moduleType,
        modulePathCurrent: `${SURVEYROOT}/${surveyCode}/${newSlide}`,
        modulePathNext: `${SURVEYROOT}/${surveyCode}/${newSlide + 1}`,
      },
    });
    if (currentSlide === 1 && !this.getResponseId()) {
      this.onSave()
        .then(async ({ responseId }) => {
          setSubmitting(false);
          history.push(
            `${SURVEYROOT}/${surveyCode}/${newSlide}?responseId=${responseId}`
          );
        })
        .catch((e) => console.log(e));
    } else {
      const responseId = this.getResponseId();
      //Redirect to first slide if no response Id
      if (!responseId) {
        history.push(`${SURVEYROOT}/${surveyCode}/1`);
        return;
      }
      this.onSaveResponseWithResponseId(responseId)
        .then(async () => {
          setSubmitting(false);
          history.push(
            `${SURVEYROOT}/${surveyCode}/${newSlide}?responseId=${responseId}`
          );
        })
        .catch((e) => console.log(e));
    }

    await this.onSetSurveyResponse(newSlide);
  };

  /**
   * Save survey response for the first time to get a responseId
   * @returns {Promise<{responseId: *}>}
   */
  onSave = async () => {
    const {
      surveyDetails,
      currentUser,
      setSubmitting,
      startSurveyTimestamp,
    } = this.props;
    const values = this.setModuleTimestamps();
    const { projectId, surveyId } = surveyDetails;
    const { savedResponseId, selectedReviewCode, surveyEmail } = values;
    const { id, name, email } = currentUser || {};
    const userId = id || 'Guest';
    const userName = name || 'Guest';
    const userEmail = email || surveyEmail;
    const answerData = this.getAnswerData({ values });
    // if (isSubmitting) return;
    setSubmitting(true);
    return saveSurveyResponse({
      projectId,
      surveyId,
      userId,
      userName,
      userEmail,
      answerData,
      savedResponseId,
      selectedReviewCode,
      startSurveyTimestamp,
    });
  };

  /**
   * List the values of the Stack rank if the user does not rearrange the ranks
   * @param questions
   * @returns {option1...optionN}
   */
  getStackRankQuestionData(questions) {
    const result = Object.entries(questions)
      .filter(([stackId, stackText]) => {
        return stackId.indexOf('option') === 0;
      })
      .sort(([aId, aText], [bId, bText]) => {
        const aInt = parseInt(aId.replace('option', ''), 10);
        const bInt = parseInt(bId.replace('option', ''), 10);
        return aInt - bInt;
      })
      .reduce((collector, [stackId, stackText], i) => {
        const order = i + 1;
        return {
          ...collector,
          [stackId]: {
            stackText,
            stackId,
            order,
          },
        };
      }, {});
    return result;
  }

  /**
   * Save a response provided the responseId
   * @param responseId
   * @param isDone Boolean: Indicate where the user has finished filling in the survey
   * @returns {Promise<void>}
   */
  onSaveResponseWithResponseId = async (responseId, isDone = false) => {
    const {
      surveyDetails,
      currentUser,
      setSubmitting,
      isSubmitting,
      moduleType,
      startSurveyTimestamp,
      inEditMode,
      inPresentationMode,
      inPreviewMode,
      inResultMode,
      inFacilitationMode,
      moduleId,
      questionData,
    } = this.props;
    if (inPreviewMode || inFacilitationMode || inResultMode || inEditMode)
      return;
    const values = this.setModuleTimestamps();
    const { projectId, surveyId } = surveyDetails;
    const { surveyEmail } = values;
    const { id, name, email } = currentUser || {};
    const userId = id || 'Guest';
    const userName = name || 'Guest';
    const userEmail = email || surveyEmail;
    if (moduleType === STACK_RANK && !values.stackRank[moduleId].option1) {
      const stackRankOptions = await this.getStackRankQuestionData(
        questionData
      );
      const stacks = Object.assign(
        values.stackRank[moduleId],
        stackRankOptions
      );
    }
    const answerData = this.getAnswerData({ values });
    if (!answerData || Object.keys(answerData).length === 0) return;

    if (isSubmitting) return;
    setSubmitting(true);
    return saveResponse({
      responseId,
      projectId,
      surveyId,
      userId,
      isDone,
      userName,
      userEmail,
      answerData,
      startSurveyTimestamp,
    });
  };

  onClickFinish = () => {
    const {
      surveyCode,
      history,
      setSubmitting,
      inEditMode,
      inPresentationMode,
      inPreviewMode,
      inResultMode,
      currentSlide,
      inFacilitationMode,
    } = this.props;
    if (inPreviewMode) return;
    if (inPresentationMode) return;
    if (inResultMode) return;
    if (!this.isSaveEnabled()) return;
    if (inEditMode) return;
    if (inFacilitationMode) return;

    if (currentSlide === 1) {
      this.onSave()
        .then(({ responseId }) => {
          setSubmitting(false);
          history.push(
            `${SURVEYROOT}/${surveyCode}/${COMPLETE}?responseId=${responseId}`
          );
        })
        .catch((e) => console.log(e));
      return;
    }

    const responseId = this.getResponseId();
    //Redirect to first slide if no response Id
    if (!responseId) {
      history.push(`${SURVEYROOT}/${surveyCode}/1`);
      return;
    }

    const isDone = true;

    this.onSaveResponseWithResponseId(responseId, isDone)
      .then(() => {
        Tracking.event({ name: surveyTakerEvents.FINISH_SURVEY });
        setSubmitting(false);
        history.push(
          `${SURVEYROOT}/${surveyCode}/${COMPLETE}?responseId=${responseId}`
        );
      })
      .catch((e) => console.log(e));
  };

  getSlideModule = (slide) => {
    const { moduleArray } = this.props;
    const module = moduleArray.find((module) => module.order === slide);
    return module || {};
  };

  /**
   * Set the survey response provided the surveyId and responseId.
   * We do this when a user comes back to the survey to continue
   * filling it in after a while.
   * @returns {Promise<void>}
   */
  onSetSurveyResponse = async (slide) => {
    if (!slide) return;

    const responseId = this.getResponseId();
    const { surveyCode, setValues, values, history } = this.props;
    if (!responseId || !surveyCode) return;
    try {
      const {
        answerData,
        isDone = false,
      } = await getSurveyResponseWithResponseId(surveyCode, responseId);
      if (isDone) {
        history.push(`${SURVEYROOT}/${surveyCode}`);
        return;
      }

      if (answerData) {
        const { moduleId, moduleType } = this.getSlideModule(slide);
        const valueKey = typeToValueKey[moduleType];
        if (valueKey && moduleId) {
          const newValues = {
            ...values,
            [valueKey]: {
              ...(values[valueKey] || {}),
              [moduleId]: {
                ...(values[valueKey][moduleId] || {}),
                ...(answerData[moduleId] || {}),
              },
            },
          };
          setValues(newValues);
        }
      }
    } catch (e) {
      //Error picking values
    }
  };

  setModuleTimestamps = () => {
    const {
      startModuleTimestamp,
      moduleId,
      moduleType,
      values,
      setValues,
    } = this.props;
    const endModuleTimestamp = new Date();
    const valueKey = typeToValueKey[moduleType];
    if (valueKey && moduleId) {
      const newValues = {
        ...values,
        [valueKey]: {
          ...(values[valueKey] || {}),
          [moduleId]: {
            ...(values[valueKey][moduleId] || {}),
            startModuleTimestamp,
            endModuleTimestamp,
          },
        },
      };
      setValues(newValues);
      return newValues;
    }
    return values;
  };
  isSpectrumEnabled() {
    const { [SPECTRUM]: spectrum } = this.props.questionData;
    return !!spectrum;
  }
  isExplanationEnabled() {
    const { inPresentationMode, moduleType } = this.props;
    const { [EXPLANATION]: explanation } = this.props.questionData;
    return !!explanation && !inPresentationMode && moduleType !== MULTI_SELECT;
  }
  promptMessage = (location) => {
    return location.pathname.startsWith('/survey')
      ? true
      : 'Survey progress will not be saved, Continue?';
  };
  render() {
    const { props } = this;
    const {
      handleChange,
      moduleId,
      values,
      inPresentationMode,
      isSubmitting,
      onClickShowExplanations,
      showExplanationsActive,
      currentSlide,
      lastSlide,
      inEditMode,
      inMobileView,
      className,
      moduleType,
    } = props;
    const { loading } = values;
    if (loading) return;
    const isMultiSelect = moduleType === MULTI_SELECT;
    const key = `explanation.${moduleId}.${EXPLANATION}`;
    const value = getNestedData(values, key);
    const percentage = `${(currentSlide / lastSlide) * 100}%`;
    const explanation = (
      <ExplanationContainer
        {...{ inMobileView }}
        enabled={this.isExplanationEnabled()}
      >
        <Explanation
          id={key}
          placeholder="Please explain your answer..."
          onChange={handleChange}
          value={value || ''}
          rows="1"
          moduleType={moduleType}
        />
      </ExplanationContainer>
    );
    const prevButton = (
      <DisabledButton
        key="prev"
        onClick={this.onClickPrev}
        enabled={true}
        active={!loading && !isSubmitting}
      >
        Prev
      </DisabledButton>
    );
    const saveButton = (
      <ActiveButton
        key="save"
        onClick={this.onClickFinish}
        active={!loading && !isSubmitting}
        enabled={this.isSaveEnabled()}
      >
        Finish
      </ActiveButton>
    );
    const nextButton = (
      <EnabledButton
        key="next"
        onClick={this.onClickNext}
        active={!loading && !isSubmitting}
        enabled={this.isNextEnabled()}
      >
        Next
      </EnabledButton>
    );
    const spectrum = this.isSpectrumEnabled() ? (
      <SpectrumDistribution {...props} {...{ inMobileView }} />
    ) : null;
    const explanationsButton = inPresentationMode ? (
      <DisabledButton
        key="explanations"
        onClick={onClickShowExplanations}
        enabled={inPresentationMode}
        active={!loading && showExplanationsActive}
      >
        Explanations
      </DisabledButton>
    ) : (
      <Progress percentage={percentage} />
    );
    return (
      <SurveyBottomContainer
        css={className}
        {...{ inEditMode, inMobileView, isMultiSelect }}
      >
        {spectrum}
        {explanation}
        <ButtonContainer {...{ inEditMode, inMobileView }}>
          <ButtonsLeft>{prevButton}</ButtonsLeft>
          <ButtonsCenter>{explanationsButton}</ButtonsCenter>
          <ButtonsRight>
            {saveButton}
            {nextButton}
          </ButtonsRight>
        </ButtonContainer>
        <Prompt when={this.isDirty()} message={this.promptMessage} />
      </SurveyBottomContainer>
    );
  }
}
