import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import styled from 'styled-components';
import Tooltip from '@reach/tooltip';
import { useDispatch } from 'react-redux';
import { setUserResponses } from 'pages/presentationSlice';

import Layout from '../components/layout';
import { fetchSurveyModulesAndResponses } from 'lib/survey-api';
import { ADMIN, PROJECT_ID_QUERY_KEY } from '../const/routes';
import { authCondition } from '../lib/auth-api';
import withAuthorization from '../components/with-authorization';
import Presentation from 'components/Presentation';
import Switcher from '../components/Switcher';
import { HILOS } from 'const/index';
import { useAsyncResource } from '../utils/async';
import Tracking from 'lib/tracking/tracking';
import { presentationEvents } from 'lib/tracking/events';
import useUserResponses from '../hooks/useUserResponses';

const PageContainer = styled('div')`
  display: flex;
  height: 100%;
`;

const Loading = styled('div')`
  display: flex;
  width: 100%;
  justify-content: center;
  height: 100%;
  align-items: center;
  font-size: 2em;
`;

const PresentationPage = ({ props, builderFormikProps }) => {
  const [loading, setLoading] = useState(true);
  const [surveyLoadingError, setSurveyLoadingError] = useState(null);

  const [surveyDetails, setSurveyDetails] = useState({});
  const [moduleList, setModuleList] = useState(null);

  const history = useHistory();

  let { presentationSurveyCode } = useParams();

  const responses = useUserResponses({
    surveyId: presentationSurveyCode,
  });

  const dispatch = useDispatch();

  const loadSurvey = async () => {
    try {
      const {
        surveyDetails,
        moduleList,
        userResponses,
      } = await fetchSurveyModulesAndResponses({
        surveyCode: presentationSurveyCode,
        showAllUsers: true,
      });
      setSurveyDetails(surveyDetails);
      setModuleList(moduleList);
      setLoading(false);
      setSurveyLoadingError(null);
      dispatch(setUserResponses(userResponses));
    } catch (e) {
      setSurveyLoadingError(
        `Survey with Id ${presentationSurveyCode} cannot be found`
      );
    }
  };

  useEffect(() => {
    if (!presentationSurveyCode) return props.history.replace('/');
    loadSurvey();
  }, []);

  useEffect(() => {
    dispatch(setUserResponses(responses));
  }, [responses]);

  const { inPresentationMode = true } = props;

  const onClose = () =>
    props.history.push(
      `${ADMIN}?${PROJECT_ID_QUERY_KEY}=${surveyDetails.projectId}`
    );

  const title = surveyDetails.name || '';

  const layoutProps = { inPresentationMode, title, onClose };

  const getDataAsync = useAsyncResource(async () => {
    const data = await fetchSurveyModulesAndResponses({
      surveyCode: presentationSurveyCode,
      showAllUsers: true,
    });
    builderFormikProps.setValues(data);
    dispatch(setUserResponses(data.userResponses));
    return data;
  });
  const { userResponses = {} } = builderFormikProps.values || {};

  const onLogoClick = () => {
    Tracking.event({ name: presentationEvents.IF_ICON_CLICKED });
    history.push(ADMIN);
  };

  useEffect(() => {
    getDataAsync.execute();
  }, []);

  return (
    <Layout {...layoutProps} onLogoClick={onLogoClick}>
      {loading && <Loading>{surveyLoadingError || 'Loading...'}</Loading>}
      {!loading && !surveyLoadingError && (
        <PageContainer>
          <Presentation
            currentUser={props.currentUser}
            getDataAsync={getDataAsync}
            moduleList={moduleList}
            userResponses={userResponses}
            mode={HILOS.PRESENTATION}
            renderControls={({
              onQuestionSelected,
              questionsData,
              activeQuestion,
            }) => {
              const items = Object.values(questionsData.questionsMetaData);
              const indexActiveQuestion = items.findIndex(
                (item) => item.moduleId === activeQuestion.moduleId
              );

              const leftSwitcherBtnIsDisabled = indexActiveQuestion === 0;
              const rightSwitcherBtnIsDisabled =
                indexActiveQuestion === items.length - 1;

              return (
                <Switcher
                  leftSwitchIsDisabled={leftSwitcherBtnIsDisabled}
                  rightSwitchIsDisabled={rightSwitcherBtnIsDisabled}
                  selectedItem={(activeQuestion || {}).metaData}
                  items={items}
                  onSelectItem={(item) => {
                    Tracking.event({
                      name: presentationEvents.QN_SWITCHER_DROPDOWN,
                    });
                    onQuestionSelected(item.moduleId);
                  }}
                  itemRender={({ item = {} }) => (
                    <Tooltip
                      style={{ zIndex: 100 }}
                      label={item.questionText || ''}
                    >
                      <span>{item.questionText}</span>
                    </Tooltip>
                  )}
                  onClickLeftSwitch={() => {
                    Tracking.event({
                      name: presentationEvents.QN_SWITCHER_LEFT,
                    });
                    onQuestionSelected(
                      items[indexActiveQuestion - 1]?.moduleId
                    );
                  }}
                  onClickRightSwitch={() => {
                    Tracking.event({
                      name: presentationEvents.QN_SWITCHER_RIGHT,
                    });
                    onQuestionSelected(
                      items[indexActiveQuestion + 1]?.moduleId
                    );
                  }}
                />
              );
            }}
          />
        </PageContainer>
      )}
    </Layout>
  );
};

function FormikWrappedWorkshop(props) {
  return (
    <Formik
      enableReinitialize
      initialValues={{}}
      onSubmit={() => {}}
      render={(formikProps) => {
        return (
          <PresentationPage props={props} builderFormikProps={formikProps} />
        );
      }}
    />
  );
}

export default FormikWrappedWorkshop;
