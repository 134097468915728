import React from 'react';

export const ManageCopySvg = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.6001 8.2001C6.6001 7.31644 7.31644 6.6001 8.2001 6.6001H15.4001C16.2838 6.6001 17.0001 7.31644 17.0001 8.2001V15.4001C17.0001 16.2838 16.2838 17.0001 15.4001 17.0001H8.2001C7.31644 17.0001 6.6001 16.2838 6.6001 15.4001V8.2001Z"
      stroke="#262525"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.4 11.4H2.6C1.71634 11.4 1 10.6837 1 9.8V2.6C1 1.71634 1.71634 1 2.6 1H9.8C10.6837 1 11.4 1.71634 11.4 2.6V3.4"
      stroke="#262525"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ManageDuplicateSvg = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.6001 8.2001C6.6001 7.31644 7.31644 6.6001 8.2001 6.6001H15.4001C16.2838 6.6001 17.0001 7.31644 17.0001 8.2001V15.4001C17.0001 16.2838 16.2838 17.0001 15.4001 17.0001H8.2001C7.31644 17.0001 6.6001 16.2838 6.6001 15.4001V8.2001Z"
      stroke="#262525"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.4 11.4H2.6C1.71634 11.4 1 10.6837 1 9.8V2.6C1 1.71634 1.71634 1 2.6 1H9.8C10.6837 1 11.4 1.71634 11.4 2.6V3.4"
      stroke="#262525"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.7998 9.7998V13.7998"
      stroke="#262525"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.7998 11.7998H13.7998"
      stroke="#262525"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ManageEditSvg = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.8 1L13 4.2L4.2 13H1V9.8L9.8 1Z"
      stroke="#262525"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 16H15"
      stroke="#262525"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ManageTrashSvg = () => (
  <svg
    width="17"
    height="20"
    viewBox="0 0 17 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 5.25H2.66667H16"
      stroke="#262525"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5625 5.25C14.5625 4.83579 14.2267 4.5 13.8125 4.5C13.3983 4.5 13.0625 4.83579 13.0625 5.25H14.5625ZM3.93752 5.25C3.93752 4.83579 3.60173 4.5 3.18752 4.5C2.7733 4.5 2.43752 4.83579 2.43752 5.25H3.93752ZM4.62502 5.25C4.62502 5.66421 4.9608 6 5.37502 6C5.78923 6 6.12502 5.66421 6.12502 5.25H4.62502ZM10.875 5.25C10.875 5.66421 11.2108 6 11.625 6C12.0392 6 12.375 5.66421 12.375 5.25H10.875ZM13.0625 5.25V17.2812H14.5625V5.25H13.0625ZM13.0625 17.2812C13.0625 17.9066 12.6338 18.25 12.2947 18.25V19.75C13.6321 19.75 14.5625 18.5544 14.5625 17.2812H13.0625ZM12.2947 18.25H4.70537V19.75H12.2947V18.25ZM4.70537 18.25C4.36619 18.25 3.93752 17.9066 3.93752 17.2812H2.43752C2.43752 18.5544 3.36798 19.75 4.70537 19.75V18.25ZM3.93752 17.2812V5.25H2.43752V17.2812H3.93752ZM6.12502 5.25V3.375H4.62502V5.25H6.12502ZM6.12502 3.375C6.12502 2.61983 6.61101 2.25 6.93752 2.25V0.75C5.53813 0.75 4.62502 2.0591 4.62502 3.375H6.12502ZM6.93752 2.25H10.0625V0.75H6.93752V2.25ZM10.0625 2.25C10.389 2.25 10.875 2.61983 10.875 3.375H12.375C12.375 2.0591 11.4619 0.75 10.0625 0.75V2.25ZM10.875 3.375V5.25H12.375V3.375H10.875Z"
      fill="#262525"
      className="fill"
    />
  </svg>
);
