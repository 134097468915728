import React, { Component } from 'react';
import format from 'date-fns/format';
import get from 'lodash/get';
import Helmet from 'react-helmet';
import queryString from 'query-string';

import withAuthorization from 'components/with-authorization';
import { AuthUserContext } from 'components/auth-context';
import { getNestedData } from 'const/nested-data';
import {
  addTeamMember,
  createProject,
  createHilo,
  fetchLocks,
  fetchOrganizationSurveys,
  fetchProjects,
  fetchProjectsAUserIsSharedWith,
  fetchProjectSurveys,
  fetchTeamMembers,
  markProjectAsDeleted,
  removeTeamMember,
  updateProject,
  watchLocks,
} from 'lib/admin-api';
import { getIsClosed } from 'lib/builder-api';
import { fetchSurveyResponses } from 'lib/survey-api';
import {
  ADD,
  EDIT,
  LANDING,
  MANAGE,
  NEW_PRESENTATION_ROOT,
  PREVIEWROOT,
  PROJECT_ALL_QUERY_KEY,
  PROJECT_ID_QUERY_KEY,
} from 'const/routes';
import Layout, { PageContainerBasic, RightColumn } from 'components/layout';
import { authCondition } from 'lib/auth-api';
import { TemplateCreationDialog } from 'components/Templates';
import { AdminLeftColumn } from 'components/Admin';
import OrganizationSurveyList from 'components/Admin/SurveyOrganization';
import { sortByLastSaved, sortByRecentlyCreated, sortByName } from 'lib/utils';
import { CreateHilo, CreateHiloDialog } from 'components/Admin/CreateHilo';
import { DIALOG, HILOS } from 'const/index';
import Tracking from 'lib/tracking/tracking';
import { generalEvents, projectEvents } from 'lib/tracking/events';
import { Team } from 'components/Admin/Team';
import SurveyCard from 'components/Admin/SurveyCard';
import {
  Trash,
  TopRow,
  MarginBottom,
  TrashPadding,
  SurveysContainer,
  ProjectNameEditIcon,
  ProjectNameEditInput,
  ProjectNameContainer,
} from 'pages/Admin/styles';

// See existing meetings ones I own and ones I am apart of
// See user information
// Create user after login if one does not exist

const getSelectedProject = ({ projects = {}, selectedProject = '' }) => {
  return projects[selectedProject] || {};
};

const getProjectSurveys = (state) => {
  const { surveys, selectedProject } = state;
  const currentProjectSurveys2 = surveys[selectedProject];
  const surveyList = (currentProjectSurveys2 || []).sort((a, b) => {
    const aSec =
      get(a, `createTimestamp.seconds`) || get(a, `timestamp.seconds`);
    const bSec =
      get(b, `createTimestamp.seconds`) || get(b, `timestamp.seconds`);
    return aSec - bSec;
  });
  return surveyList;
};

const ProjectNameEdit = (props) => (
  <ProjectNameContainer>
    <ProjectNameEditInput {...props} />
    <ProjectNameEditIcon />
  </ProjectNameContainer>
);

// users as [id, userHash]
const getProjectUsers = (state) => {
  const currentProject = getSelectedProject(state);
  if (!currentProject) return [];
  const currentProjectUsers = currentProject.team || [];
  return Object.entries(currentProjectUsers).filter(([id, user]) => {
    return !!user;
  });
};

const getAlreadyInTeam = (state, user) => {
  const { userId } = user;
  const { selectedProject, projects } = state;
  const projectUserIds = Object.entries(
    getNestedData(projects, `${selectedProject}.team`) || {}
  )
    .filter(([key, value]) => value)
    .map(([key, value]) => key);
  const inTeam = projectUserIds.includes(userId);
  return inTeam;
};

const getAllUsers = (state) => {
  const teamMembers = Object.values(state.users).map((user) => {
    const alreadyInTeam = getAlreadyInTeam(state, user);
    return {
      ...user,
      alreadyInTeam,
    };
  });
  return teamMembers;
};

export const TrashMenu = ({ onTrashClick, inEditMode }) => (
  <TrashPadding onClick={onTrashClick} inEditMode={inEditMode}>
    <Trash />
  </TrashPadding>
);

const sortProjects = ({ projects = [], currentUser = {} }) => {
  const { projectOrder = [] } = currentUser;
  const projectArray = Object.entries(projects);
  const sortedProjectObj = projectArray.reduce(
    (collector, project) => {
      const projectId = project[0];
      const projectOrderIndex = projectOrder.indexOf(projectId);
      if (projectOrderIndex > -1) {
        let sortedClone = [...collector.sorted];
        sortedClone[projectOrderIndex] = project;
        return {
          ...collector,
          sorted: sortedClone,
        };
      }
      return {
        ...collector,
        unsorted: [...collector.unsorted, project],
      };
    },
    { sorted: [], unsorted: [] }
  );
  return [...sortedProjectObj.sorted, ...sortedProjectObj.unsorted].filter(
    (x) => x
  );
};

const getCurrentProjectId = ({
  projectsArray = [],
  location = {},
  selectedProject = '',
  focusFirstProject,
}) => {
  const showAllProjects = queryString.parse(location.search || '')[
    PROJECT_ALL_QUERY_KEY
  ];
  if (showAllProjects) return null;

  const firstProject = projectsArray[0] && projectsArray[0][0];
  const locationProject = queryString.parse(location.search || '')[
    PROJECT_ID_QUERY_KEY
  ];
  return focusFirstProject
    ? locationProject || firstProject
    : locationProject || selectedProject;
};

const getEvent = (currentProject) => ({
  currentTarget: {
    dataset: {
      id: currentProject,
    },
  },
});

class AdminPage extends Component {
  state = {
    loading: true,
    projectsLoaded: false,
    projects: {},
    projectsArray: [],
    sharedProjectsMap: {},
    sharedProjects: [],
    selectedProject: null,
    isAllSurveyActive: false,
    saving: false,
    editingProject: null,
    responses: {},
    showTeamModal: false,
    users: {},
    showTemplates: false,
    templateSurveys: {},
    isTemplateDialogOpen: false,
    isCreateHiloDialogOpen: false,
    hilo: null,
    showTrashModal: false,
    surveys: {},
    organizationSurveys: [],
    surveyLocks: {},
    templateSurveyId: null,
    surveyFilter: null,
  };

  static contextType = AuthUserContext;

  onOpenDialog = ({
    dialog = DIALOG.CREATE_TEMPLATE,
    surveyId,
    surveyCode,
    hilo = HILOS.SURVEY,
  }) => {
    let stateUpdate = {};

    if (dialog === DIALOG.CREATE_TEMPLATE) {
      stateUpdate = {
        isTemplateDialogOpen: true,
        templateSurveyId: surveyId || surveyCode,
      };
    } else {
      stateUpdate = {
        isCreateHiloDialogOpen: true,
        hilo,
      };
    }

    this.setState(stateUpdate);
  };

  onDismissDialog = ({ dialog = DIALOG.CREATE_TEMPLATE, hilo = null }) => {
    if (dialog === DIALOG.CREATE_TEMPLATE) {
      Tracking.event({ name: generalEvents.CANCEL_TEMPLATE_CREATION });
      this.setState({ isTemplateDialogOpen: false, templateSurveyId: null });
    } else {
      this.setState({ isCreateHiloDialogOpen: false, hilo: null });
      Tracking.hiloCancellation({ hilo });
    }
  };

  onClickCreateHiloDialogButton = ({ hilo = HILOS.SURVEY }) => {
    if (hilo === HILOS.SURVEY) {
      this.addNewHilo({ hilo: HILOS.SURVEY });
      return;
    }

    if (hilo === HILOS.WORKSHOP) {
      this.addNewHilo({ hilo: HILOS.WORKSHOP });
      return;
    }
  };

  fetchResponses = (surveys) => {
    const currentProjectSurveys =
      surveys || getProjectSurveys(this.state, this.props);
    if (!currentProjectSurveys.length) return;
    this.setState({ loading: true });
    const fetchResponses = currentProjectSurveys.map((surveyData) => {
      const surveyId = surveyData.id;
      return fetchSurveyResponses({ surveyId, parse: true }).then(
        (responseData) => {
          this.setState((state) => ({
            responses: {
              ...state.responses,
              [surveyId]: responseData,
            },
          }));
        }
      );
    });
    return Promise.all(fetchResponses).then(() => {
      this.setState({ loading: false });
    });
  };

  fetchProjects = (focusFirstProject) => {
    const { currentUser, location } = this.props;
    const { selectedProject } = this.state;
    this.setState({ loading: true });
    const {
      claims: { organizationId },
    } = this.context;
    const userId = currentUser && currentUser.id;
    return fetchProjects(organizationId, userId).then((allProjects) => {
      const projects = allProjects;
      const projectsArray = sortProjects({ projects, currentUser });
      const currentProject = getCurrentProjectId({
        projectsArray,
        location,
        selectedProject,
        focusFirstProject,
      });

      let isAllSurveyActive = false;

      if (currentProject) {
        const event = getEvent(currentProject);
        isAllSurveyActive = false;
        this.selectProject(event);
      } else {
        //The user has refreshed and the showAll=true query param
        isAllSurveyActive = true;
      }

      this.setState(
        (state) => {
          return {
            projects: { ...projects, ...state.sharedProjectsMap },
            selectedProject: currentProject,
            projectsArray,
            loading: false,
            projectsLoaded: true,
            isAllSurveyActive,
          };
        },
        () => {
          this.fetchTeamMembers(organizationId);
        }
      );
    });
  };

  fetchProjectsSharedByUser = () => {
    const { currentUser } = this.props;
    this.setState({ loading: true });
    const {
      claims: { organizationId },
    } = this.context;
    const userId = currentUser && currentUser.id;
    return fetchProjectsAUserIsSharedWith(organizationId, userId).then(
      (userSharedProjects) => {
        this.setState((state) => {
          return {
            sharedProjectsMap: userSharedProjects,
            projects: { ...userSharedProjects, ...state.projects },
            sharedProjects: [...Object.values(userSharedProjects)],
            loading: false,
            projectsLoaded: true,
          };
        });
      }
    );
  };

  fetchProjectSurveys = (options) => {
    const selectedProject = options
      ? options.selectedProject
      : this.state.selectedProject;
    if (!selectedProject) return;
    this.setState({ loading: true });
    const {
      claims: { organizationId },
    } = this.context;
    return Promise.all([
      fetchProjectSurveys({ projectId: selectedProject, organizationId }),
      fetchLocks(),
    ])
      .then(([surveys, surveyLocks]) => {
        this.setState((state) => ({
          loading: false,
          surveys: {
            ...state.surveys,
            [selectedProject]: surveys,
          },
          surveyLocks,
        }));
        return surveys;
      })
      .then((surveys) => this.fetchResponses(surveys));
  };

  fetchOrganizationSurveys = (options = {}) => {
    if (this.state.selectedProject) return;
    this.setState({ loading: true });
    const {
      claims: { organizationId },
    } = this.context;
    return Promise.all([
      fetchOrganizationSurveys({ organizationId, ...options }),
      fetchLocks(),
    ]).then(([surveys, surveyLocks]) => {
      this.setState({
        loading: false,
        organizationSurveys: surveys,
        surveyLocks,
      });
    });
  };

  startLockWatch = () => {
    console.log('start lock watch...');
    watchLocks((surveyLocks) => {
      this.setState({ surveyLocks });
    });
  };

  componentDidMount() {
    console.log('did mount ::: this.props.currentUser', this.props.currentUser);
    if (this.props.currentUser) {
      console.log(
        'Admin page running::: this.props.currentUser',
        this.props.currentUser
      );
      this.fetchProjects(true);
      this.fetchProjectsSharedByUser();
      this.fetchOrganizationSurveys();
      this.startLockWatch();
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.currentUser !== this.props.currentUser) {
      console.log(
        'componentDidUpdate ::: prevProps.currentUser',
        prevProps.currentUser
      );
      this.fetchProjects(true);
      this.fetchProjectsSharedByUser();
      this.fetchOrganizationSurveys();
      this.startLockWatch();
      const {
        claims: { organizationId },
      } = this.context;
      this.fetchTeamMembers(organizationId);
    }
    this.updateSelectedProject();
  }

  updateSelectedProject = () => {
    const newSearch = this.props.location.search;
    const searchParams = new URLSearchParams(newSearch);
    const newProjectId = searchParams.get(PROJECT_ID_QUERY_KEY);
    const showAllProjects = searchParams.get(PROJECT_ALL_QUERY_KEY);

    if (this.state.selectedProject === newProjectId) return;
    if (!this.props.currentUser) return;

    if (showAllProjects) {
      this.setState({ isAllSurveyActive: true, selectedProject: null }, () => {
        this.fetchOrganizationSurveys();
      });
    } else {
      this.setState({ selectedProject: newProjectId }, () => {
        this.fetchProjectSurveys({ selectedProject: newProjectId });
      });
    }
  };

  selectProject = (e) => {
    this.setState({ isAllSurveyActive: false });
    const location = this.props.location;
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete(PROJECT_ALL_QUERY_KEY);

    if (!e) {
      searchParams.delete(PROJECT_ID_QUERY_KEY);
    } else {
      const selectedProject = e.currentTarget.dataset.id;
      searchParams.set(PROJECT_ID_QUERY_KEY, selectedProject);
    }
    this.props.history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  onClickAllSurvey = () => {
    const location = this.props.location;

    Tracking.event({
      name: generalEvents.ALL_HILOS_ACCESS,
    });

    const searchParams = new URLSearchParams(location.search);
    searchParams.delete(PROJECT_ID_QUERY_KEY);
    searchParams.set(PROJECT_ALL_QUERY_KEY, 'true');

    this.props.history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    });

    this.setState({ isAllSurveyActive: true, selectedProject: null });
  };

  onDeleteProject = async (project) => {
    const confirmed = window.confirm(`        ${project.name}
        Are you sure you want to delete this project? By clicking OK, 
        you will remove the project and all the surveys within from 
        the entire project team.`);
    if (!confirmed) return;
    try {
      await markProjectAsDeleted(project.id);
    } catch (e) {
      console.log(e);
    }
    this.onClickAllSurvey();
    this.fetchProjects();
    this.fetchProjectsSharedByUser();
    window.confirm(
      `Your project ${project.name} has been successfully deleted for the entire project team.`
    );
  };

  onRemoveClick = (e) => {
    const projectId = this.state.selectedProject;
    let { userId } = e;
    if (e.currentTarget.dataset) {
      userId = e.currentTarget.dataset.id;
    }
    this.setState({ saving: false });
    removeTeamMember({ userId, projectId }).then(() => {
      this.setState({ saving: false });
      Tracking.event({
        name: projectEvents.REMOVE_TEAM_MEMBER,
        properties: {
          userId,
          projectId,
        },
      });
      this.fetchProjects();
      this.fetchProjectsSharedByUser();
    });
  };
  selectSettingsTab = ({ projectId, surveyId }) => {
    this.props.history.push(
      `${MANAGE}?project=${projectId}&survey=${surveyId}`
    );
  };
  renderSurveyList() {
    const {
      state,
      props,
      launchSurveyClick,
      fetchProjects,
      fetchProjectSurveys,
      onOpenDialog,
    } = this;
    const { responses, selectedProject, surveyLocks } = state;
    const { history, currentUser } = props;
    const currentProjectSurveys = getProjectSurveys(state, props);
    return currentProjectSurveys.map((survey, i) => {
      const { surveyCode, surveyId, hilo } = survey;
      const isClosed = getIsClosed(survey);
      const responseCount = Object.keys(responses[surveyId] || {}).length;
      const projectId = selectedProject;
      const formattedTimestamp = survey.timestamp
        ? format(new Date(survey.timestamp.seconds * 1000), 'Pp')
        : '';
      const members = getAllUsers(state);
      const titleLocation = `${NEW_PRESENTATION_ROOT}/${surveyCode}`;
      const titleLink = {
        location: titleLocation,
        state: { projectId },
      };
      const configLink = {
        location: `${EDIT}?project=${selectedProject}&${
          hilo === HILOS.SURVEY ? 'survey' : 'workshop'
        }=${surveyId}`,
        state: { projectId },
      };
      const onClickPlay = () => {
        window.open(`${PREVIEWROOT}/${surveyCode}/1`);
      };
      return (
        <SurveyCard
          hideMargin
          key={`${surveyId}::${i}`}
          onClickSettings={() =>
            this.selectSettingsTab({ projectId, surveyId })
          }
          onClickPlay={() => onClickPlay({ surveyCode, isClosed })}
          onClickTemplate={() => onOpenDialog({ surveyId, surveyCode })}
          {...{
            titleLink,
            configLink,
            launchSurveyClick,
            history,
            responseCount,
            formattedTimestamp,
            members,
            survey,
            currentUser,
            fetchProjects,
            fetchProjectSurveys,
            surveyLocks,
          }}
        />
      );
    });
  }
  addNewProject = () => {
    if (!this.props.currentUser) {
      this.props.history.push(LANDING);
      return;
    }
    this.setState({ saving: true });
    const {
      claims: { organizationId, organizationName },
    } = this.context;
    const projectName = 'Enter a project name';
    const {
      id: userId,
      name: userName,
      email: userEmail,
    } = this.props.currentUser;
    createProject({
      projectName,
      userId,
      userName,
      userEmail,
      organizationId,
      organizationName,
    })
      .then((project) => {
        const projectId = project.id;
        this.setState({ saving: false, editingProject: projectId }, () => {
          this.fetchProjects();
          this.fetchProjectsSharedByUser();
        });
      })
      .catch((e) => {
        // TODO: Check for the error code and message and display it to the user
      });
  };
  addNewHilo = ({ hilo = HILOS.SURVEY }) => {
    const name = `New ${hilo}, enter name`;
    const projectId = this.state.selectedProject;
    const {
      claims: { organizationId, organizationName },
    } = this.context;
    const {
      id: userId,
      name: userName,
      email: userEmail,
    } = this.props.currentUser;
    this.setState({ saving: true }, () => {
      createHilo({
        hilo,
        name,
        projectId,
        userId,
        userName,
        userEmail,
        organizationId,
        organizationName,
      }).then((hiloId) => {
        this.setState({ saving: false });
        // todo: smaller data fetch
        //this.fetchProjects();
        this.props.history.push(
          `${ADD}?project=${projectId}&${hilo}=${hiloId}`,
          { projectId }
        );
      });
    });
    return false;
  };
  addNewTeamMember = (e) => {
    e.stopPropagation();
    const { id: userId, name: userName } =
      e.currentTarget.dataset || this.props.currentUser;
    const projectId = this.state.selectedProject;
    const projectName = this.state.projects[projectId].name;
    this.setState({ saving: true }, () => {
      addTeamMember({
        userId,
        userName,
        projectId,
        projectName,
      }).then(() => {
        this.setState({ saving: false });
        Tracking.event({
          name: projectEvents.ADD_NEW_TEAM_MEMBER,
          properties: {
            userId,
            projectId,
            projectName,
          },
        });
        this.fetchProjects();
        this.fetchProjectsSharedByUser();
      });
    });
  };
  setProjectName = (e) => {
    const name = e.currentTarget.value;
    const projectId = this.state.selectedProject;
    if ([13, 27].includes(e.keyCode)) {
      this.setState({ editingProject: null, saving: true });
      if (e.keyCode === 13) {
        updateProject({ projectId, name }).then(() => {
          this.setState({ saving: false });
          Tracking.event({
            name: projectEvents.SET_PROJECT_NAME,
            properties: {
              projectId,
              projectName: name,
            },
          });
          this.fetchProjects();
          this.fetchProjectsSharedByUser();
        });
      }
    }
  };
  setProjectNameOnBlur = (e) => {
    const { selectedProject, projects } = this.state;
    const name = e.currentTarget.value;
    const existingProject = projects[selectedProject];
    const existingName = existingProject && existingProject.name;
    if (name !== existingName) {
      this.setState({ editingProject: null, saving: true });
      updateProject({ projectId: selectedProject, name }).then(() => {
        this.setState({ saving: false });
        this.fetchProjects();
        this.fetchProjectsSharedByUser();
      });
    }
  };
  renameProject = (e) => {
    e.preventDefault();
    const id = e.currentTarget.dataset.id;
    this.setState({ selectedProject: id, editingProject: id }, () => {
      document.execCommand('selectAll', false, null);
    });
  };
  fetchTeamMembers(organizationId) {
    fetchTeamMembers({ organizationId }).then((users) => {
      this.setState({ users });
    });
  }

  handleOnSelectedSurveySortOrder = (selectedItem) => {
    const { currentUser } = this.props;
    if (selectedItem.id === 1) {
      this.setState({
        surveyFilter: {
          type: 'sort',
          filter: sortByLastSaved,
        },
      });
    }

    if (selectedItem.id === 2) {
      const filter = (survey) => survey.creator === currentUser.id;
      this.setState({
        surveyFilter: {
          type: 'filter',
          filter: filter,
        },
      });
    }

    if (selectedItem.id === 3) {
      this.setState({
        surveyFilter: {
          type: 'sort',
          filter: sortByRecentlyCreated,
        },
      });
    }

    if (selectedItem.id === 4) {
      this.setState({
        surveyFilter: {
          type: 'sort',
          filter: sortByName,
        },
      });
    }
  };

  /**
   * We are sorting before filtering so that we preserve the order on
   * the frontend. So that when a user clicks the launch/close button
   * the card does not jump.
   * @returns {any[]|this}
   */
  getFilteredSurveys = () => {
    const { surveyFilter, organizationSurveys } = this.state;
    if (surveyFilter) {
      if (surveyFilter.type === 'filter') {
        return organizationSurveys
          .sort(sortByLastSaved)
          .filter(surveyFilter.filter);
      }
      if (surveyFilter.type === 'sort') {
        return organizationSurveys.sort(surveyFilter.filter);
      }
    }
    return organizationSurveys.sort(sortByLastSaved);
  };

  renderBody() {
    const {
      hilo,
      isTemplateDialogOpen,
      isCreateHiloDialogOpen,
      templateSurveyId,
      surveyLocks,
    } = this.state;
    const { currentUser, history } = this.props;
    if (!this.state.selectedProject)
      return (
        <OrganizationSurveyList
          isTemplateDialogOpen={isTemplateDialogOpen}
          onDismissTemplate={this.onDismissDialog}
          templateSurveyId={templateSurveyId}
          surveys={this.getFilteredSurveys()}
          state={this.state}
          getAllUsers={getAllUsers}
          onClickTemplate={this.onOpenDialog}
          currentUser={currentUser}
          fetchProjects={this.fetchProjects}
          fetchProjectSurveys={this.fetchProjectSurveys}
          fetchOrganizationSurveys={this.fetchOrganizationSurveys}
          surveyLocks={surveyLocks}
          history={history}
          onSelectedSortOrder={this.handleOnSelectedSurveySortOrder}
        />
      );
    const currentProject = getSelectedProject(this.state);
    const { name, projectId } = currentProject;
    if (!currentProject) return <RightColumn />;
    return (
      <RightColumn>
        <TopRow>
          <ProjectNameEdit
            defaultValue={name}
            key={`projectName::${projectId}`}
            onKeyDown={this.setProjectName}
            onBlur={this.setProjectNameOnBlur}
          />
          <Team
            members={getAllUsers(this.state)}
            onRemoveClick={this.onRemoveClick}
            addNewTeamMember={this.addNewTeamMember}
            selectedProject={this.state.selectedProject}
            currentProjectUsers={getProjectUsers(this.state)}
          />
        </TopRow>
        <TemplateCreationDialog
          showDialog={isTemplateDialogOpen}
          onDismiss={this.onDismissDialog}
          surveyId={templateSurveyId}
        />
        <CreateHiloDialog
          hilo={hilo}
          isOpen={isCreateHiloDialogOpen}
          onClickCreate={this.onClickCreateHiloDialogButton}
          onDismiss={() =>
            this.onDismissDialog({ dialog: DIALOG.CREATE_HILO, hilo })
          }
        />
        <CreateHilo
          onClick={({ hilo }) =>
            this.onOpenDialog({ dialog: DIALOG.CREATE_HILO, hilo })
          }
        />
        <MarginBottom />
        <SurveysContainer>{this.renderSurveyList()}</SurveysContainer>
      </RightColumn>
    );
  }

  onTrashClose = () => this.setState({ showTrashModal: false });

  render() {
    const {
      isLoading,
      saving,
      projectsArray,
      selectedProject,
      isAllSurveyActive,
      sharedProjects,
    } = this.state;
    return (
      <>
        <Layout
          loading={isLoading}
          saving={saving}
          title="Projects"
          background="linear-gradient(to right, white 0%, white 50%, #F0F0F0 51%, #F0F0F0 100%)"
          hideBodyOverflow
        >
          <Helmet>
            <title>Projects</title>
          </Helmet>
          <PageContainerBasic collapseMobile={true}>
            <AdminLeftColumn
              projectsArray={projectsArray}
              addNewProject={this.addNewProject}
              selectProject={this.selectProject}
              selectedProject={selectedProject}
              sharedProjects={sharedProjects}
              onClickAllSurvey={this.onClickAllSurvey}
              isAllSurveyActive={isAllSurveyActive}
              onDeleteProject={this.onDeleteProject}
            />
            {this.renderBody()}
          </PageContainerBasic>
        </Layout>
      </>
    );
  }
}

export default withAuthorization(authCondition)(AdminPage);
