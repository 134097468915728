import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, isNull } from 'lodash';
import styled from 'styled-components';
import {
  VictoryChart,
  VictoryScatter,
  VictoryVoronoiContainer,
  VictoryGroup,
  VictoryAxis,
  VictoryTheme,
  VictoryLegend,
} from 'victory';
import { DefaultTooltip } from '../common/Tooltips';
import {
  setCommentsPanelVisibility,
  setPanelComments,
  setSelectedParticipantResponseId,
} from 'pages/presentationSlice';
import { HILOS } from 'const/index';
import { STACK_RANK_COLOR_PALETTE } from 'const/module-types';
import ScatterPoint from 'components/PresentationModules/StackRank/ScatterPoint';
import { colors } from 'const/theme';
import AxisTickLabel from 'components/PresentationModules/StackRank/AxisTickLabel';

const ChartWrapper = styled.div`
  width: 50vw;
  margin: auto;
  position: relative;
  svg {
    overflow: visible;
  }
`;

export default function StackRankChartDetail({
  userData,
  moduleId,
  mode,
  comparisonSection,
}) {
  const dispatch = useDispatch();

  const [initialRender, setInitialRender] = useState(false);
  const [selectedOption, setSelectedOption] = useState(0);
  const [selectAll, setSelectAll] = useState(false);

  const xLabels = userData[0].data;
  const yLabels = userData.map((option, index) => index + 1);
  const comments = useSelector((state) => state.presentation.comments);

  const participantsData = useSelector(
    (state) => state.presentation[moduleId]?.participantsData
  );

  const selectedParticipantResponseId = useSelector((state) => {
    if (comparisonSection && mode === HILOS.WORKSHOP) {
      return state.workshop.comparison[comparisonSection]
        .selectedParticipantResponseId;
    } else {
      return state.presentation.selectedParticipantResponseId;
    }
  });

  useEffect(() => {
    setInitialRender(true);
  }, [userData]);

  const onClickBubble = (datum) => {
    dispatch(setCommentsPanelVisibility(true));
    dispatch(setPanelComments(comments));
    dispatch(setSelectedParticipantResponseId(datum.responseId));
  };

  /**
   * Called when s auser clicks the item on the axis
   * @param responseId
   */
  const onClickLabel = (responseId) => {
    onClickBubble({ responseId });
  };

  const legendLabel = userData.map((option, index) => {
    return {
      name: option.optionName,
      labels: {
        fill:
          !xLabels || (selectedOption !== index && !selectAll)
            ? '#c4c4c4'
            : null,
      },
      symbol: {
        fill:
          isNull(selectedOption) && selectAll
            ? STACK_RANK_COLOR_PALETTE[index]
            : (initialRender && index !== 0) ||
              selectedOption !== index ||
              !xLabels
            ? '#c4c4c4'
            : STACK_RANK_COLOR_PALETTE[index],
        size: '8',
      },
    };
  });

  return (
    <ChartWrapper>
      <span style={{ position: 'absolute', left: '-25%', top: '4%' }}>
        <VictoryLegend
          x={0}
          y={40}
          title={'Select All'}
          gutter={20}
          style={{
            title: {
              fontSize: 15,
              fontWeight: '600',
              fill: selectAll && xLabels ? '#ff9736' : null,
            },
          }}
          data={legendLabel}
          events={[
            {
              target: 'data',
              eventHandlers: {
                onClick: () => {
                  return [
                    {
                      target: 'data',
                      mutation: (props) => {
                        setInitialRender(false);
                        return setSelectedOption(props.index);
                      },
                    },
                  ];
                },
              },
            },
            {
              target: 'data',
              eventHandlers: {
                onMouseOver: () => {
                  return [
                    {
                      target: 'data',
                      mutation: (props) => {
                        return {
                          style: {
                            fill: STACK_RANK_COLOR_PALETTE[props.index],
                          },
                        };
                      },
                    },
                    {
                      target: 'labels',
                      mutation: (props) => {
                        return { style: { textDecoration: 'underline' } };
                      },
                    },
                  ];
                },
                onMouseOut: () => {
                  return [
                    {
                      target: 'data',
                      mutation: () => {
                        return null;
                      },
                    },
                    {
                      target: 'labels',
                      mutation: () => {
                        return { style: { textDecoration: 'none' } };
                      },
                    },
                  ];
                },
              },
            },
            {
              target: 'title',
              eventHandlers: {
                onClick: () => {
                  return [
                    {
                      target: 'title',
                      mutation: (props) => {
                        setInitialRender(false);
                        setSelectedOption(null);
                        return setSelectAll(!selectAll);
                      },
                    },
                  ];
                },
              },
            },
          ]}
        />
      </span>
      <VictoryChart
        height={350}
        width={550}
        theme={xLabels ? VictoryTheme.material : VictoryTheme.grayscale}
        containerComponent={<VictoryVoronoiContainer />}
        domain={{ x: !xLabels && [] }}
        domainPadding={{
          x:
            xLabels && (xLabels.length === 2 || xLabels.length === 3)
              ? [250, 250]
              : [30, 0],
        }}
      >
        <VictoryAxis
          dependentAxis
          tickFormat={yLabels}
          style={{ tickLabels: { fontWeight: '600' } }}
          invertAxis
        />
        <VictoryAxis
          independentAxis
          style={{ tickLabels: { angle: -60, fontWeight: '600', padding: 12 } }}
          tickLabelComponent={
            <AxisTickLabel
              onClickLabel={onClickLabel}
              participantsData={participantsData}
              selectedParticipantResponseId={selectedParticipantResponseId}
            />
          }
        />
        {xLabels &&
          userData.map((result, index) => (
            <VictoryGroup
              key={index}
              color="#1CA4FC"
              labels={({ datum }) => `${result.optionName}`}
              labelComponent={<DefaultTooltip fontSize={10} />}
              data={result.data}
            >
              <VictoryScatter
                dataComponent={
                  <ScatterPoint
                    moduleId={moduleId}
                    size={5}
                    selectedParticipantResponseId={
                      selectedParticipantResponseId
                    }
                    onClickBubble={onClickBubble}
                    initialRender={initialRender}
                    optionIndex={index}
                    selectedOption={selectedOption}
                    selectAll={selectAll}
                  />
                }
              />
            </VictoryGroup>
          ))}
      </VictoryChart>
    </ChartWrapper>
  );
}
