import styled from 'styled-components';
import {
  LabeledTextarea,
  Button2,
  DetailShortInputContainer,
} from '../../layout';

export const DetailTextarea = styled(LabeledTextarea)`
  width: 100%;
`;

export const Question = styled(DetailTextarea)`
  margin-bottom: 20px;
`;

export const Description = styled(DetailTextarea)`
  margin-bottom: 20px;
`;

export const AllowExplanationContainer = styled('div')`
  cursor: pointer;
  flex: ${(props) => (props.isPersonInfoModule ? '' : '0 1 2 68.5%')};
  font-family: 'Neue Haas Grotesk Display Std', sans-serif;
  font-size: 10px;
  line-height: 11px;
  font-weight: normal;
  display: flex;
  align-items: center;
  margin: 0 0 12px 0;
`;

export const AllowExplanationCheckbox = styled('input')`
  &[type='checkbox'] {
    margin: 0 0 0 0.5rem;
  }
  cursor: pointer;
`;

export const HorizontalFieldContainer = styled('div')`
  flex: 0 1 48.5%;
  display: block;
`;

export const SpacerContainer = styled(DetailShortInputContainer)``;

export const QuestionDetailsContainer = styled('div')`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
`;

export const Type = styled('div')`
  font-family: 'Neue Haas Grotesk Display Std', sans-serif;
  font-size: 0.75rem;
  font-weight: bold;
  margin-bottom: 12px;
`;

export const QuestionDisplay = styled('div')`
  font-family: Neue Haas Grotesk Display Std;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: ${(props) => (props.noRequired ? '0px' : '21px')};
  text-overflow: ellipsis;
  min-height: ${(props) => (props.noRequired ? '0px' : '21px')};
  overflow: hidden;
`;

export const Details = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: ${(props) => (props.noRequired ? '0px' : '12px')};
  gap: 3px;
`;

export const CancelSave = styled('div')`
  display: flex;
  justify-content: flex-end;
`;

export const CancelButton = styled(Button2)``;
export const SaveButton = styled(Button2)`
  border-color: #1ca4fc;
  color: #1ca4fc;
  margin-left: 15px;
`;
