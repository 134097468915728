import styled from 'styled-components';
import { device } from '../../../utils/deviceBreakpoints';

export const RightColumnContainer = styled('div')`
  width: ${(props) => (props.isOpenEndedModule ? '100%' : '70%')};
  background: #ffffff;
  padding-right: ${(props) => (props.isAddModule ? '6.5em' : '0')};
  padding-left: ${(props) => (props.isAddModule ? '6.5em' : '10%')};
  margin-left: ${(props) => props.isPointDistroModule && '-5%'};
  padding-right: ${(props) => props.isPointDistroModule && '20%'};
  padding-right: ${(props) => props.is2By2Module && '17%'};
  padding-right: ${(props) => props.isOpenEndedModule && '20%'};
  padding-left: ${(props) => props.isOpenEndedModule && '20%'};
  padding-bottom: ${(props) => (props.isOpenEndedModule ? '2em' : '1%')};
  display: flex;
  flex-direction: column;
  height: 80%;
  margin: -1rem auto 0;
  margin-left: ${(props) => props.isPointDistroModule && '10%'};
  @media ${device.laptopL} {
    width: ${(props) => (props.isPointDistroModule ? '70%' : '110%')};
    margin-left: ${(props) => props.isPointDistroModule && '10%'};
    padding-right: ${(props) => (props.isAddModule ? '6.5em' : '15%')};
    padding-right: ${(props) => (props.isPointDistroModule ? '40%' : '15%')};
    padding-right: ${(props) => (props.is2By2Module ? '40%' : '15%')};
    padding-left: ${(props) => (props.is2By2Module ? '10%' : '15%')};
    padding-left: ${(props) => (props.isAddModule ? '6.5em' : '10%')};
  },
`;

export const ModuleType = styled('div')`
  display: flex;
  justify-content: flex-end;
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
  font-style: normal;
  font-weight: bold;
  text-transform: capitalize;
  flex-flow: column wrap;
  align-items: flex-end;
`;

export const ChartContainer = styled('div')`
  width: 750px;
`;

export const PointDistributionLineChartContainer = styled('div')`
  padding-top: 1em;
  width: 150%;
`;
