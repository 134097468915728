import React from 'react';
import { TEMPLATES_GALLERY } from '../../../const/routes';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import TemplateImg from '../../../img/template-icon.svg';
import TemplateImgWhite from '../../../img/template-icon-white.svg';
import TemplateImgHover from '../../../img/template-icon-hover.svg';
import Tracking from 'lib/tracking/tracking';
import { generalEvents } from 'lib/tracking/events';

const TemplateIcon = styled('div')`
  background-image: url('${TemplateImg}');
  background-repeat: no-repeat;
  width: 26px;
  height: 26px;
  margin-right: 20px;
`;

const TemplateGallery = styled('h2')`
  border-top: ${(props) => (props.isInMenu ? '2px solid #1ca4fc' : 'none')};
  padding-left: 30px;
  display: flex;
  padding-top: ${(props) =>
    props.paddingTop ? `${props.paddingTop}px` : '27px'};
  padding-bottom: ${(props) =>
    props.paddingBottom ? `${props.paddingBottom}px` : '27px'};
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #6a36ff;
  cursor: pointer;
  margin-top: 0;
  margin-bottom: 0;
  &:hover {
    color: ${(props) => (props.isInMenu ? '#fff' : '#B59BFF')};
    background-color: ${(props) => (props.isInMenu ? '#6a36ff' : 'none')};
    ${TemplateIcon} {
      background-image: url(${(props) =>
        props.isInMenu ? TemplateImgWhite : TemplateImgHover});
    }
  }
`;

const TemplateGalleryLink = (props) => {
  return (
    <TemplateGallery
      onClick={() => {
        Tracking.event({
          name: generalEvents.TEMPLATE_GALLERY_ACCESS,
        });
        props.history.push(TEMPLATES_GALLERY);
      }}
      {...props}
    >
      <TemplateIcon /> Template Gallery
    </TemplateGallery>
  );
};

export default withRouter(TemplateGalleryLink);
