import React, { Component } from 'react';
import { RightTitle, Button, Center } from '../../layout';
import styled from 'styled-components';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { sortModulesToArray } from '../../../lib/builder-api';
import ModuleCard from "../ModuleCard";

// needs current meeting passed to it. or it can consume it from route context (DONE)
// Presentation Mode
// we will have entry modules and display modules
// let them select how they want to display
// load meeting on page landing
// use child component to pass meeting code to child (DONE)

export const Loading = styled(Center)`
  opacity: ${props => (props.loading ? 1 : 0)};
`;

export const NoSurveyFound = styled(Center)`
  opacity: ${props => (props.loading ? 0 : props.surveyLoadError ? 1 : 0)};
`;

const ModulesContainer = styled('div')`
  width: 100%;
  margin: 0 auto;
`;

const AddAModule = styled('div')`
  background-color: #f2f2f2;
  border: 1px solid #dadada;
  box-shadow: 0.125rem 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
  font-family: 'Neue Haas Grotesk Display Std';
  font-weight: bold;
  font-size: 2.25rem;
  color: #dadada;
  min-height: 16.875rem;
  padding: 1.25rem;
  line-height: 1;
  cursor: pointer;
`;

const SaveButton = styled(Button)`
  pointer-events: ${props => (props.saveEnabled ? 'auto' : 'none')};
  background: ${props => (props.saveEnabled ? '#FF9B3A' : '#C4C4C4')};
`;

const SaveDraftButton = styled(SaveButton)`
  background: ${props => (props.saveEnabled ? '#1CA4FC' : '#C4C4C4')};
  margin: 0 1.25rem 0 0;
  text-align: center;
  position: relative;
`;

const SurveyTitle = styled('input')`
  flex: 1 0 auto;
  height: 2.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Neue Haas Grotesk Display Std', sans-serif;
  font-size: 1.125rem;
  font-weight: bold;
  color: #333333;
  box-shadow: inset -1px 1px 0.1875rem rgba(0, 0, 0, 0.15);
  -webkit-appearance: none;
  background: white;
  padding: 0 0.625rem;
  border: none;
  pointer-events: auto;
`;

const UnpublishedChanges = styled('div')`
  color: #ff9b3a;
  font-family: 'Neue Haas Grotesk Display Std', sans-serif;
  font-size: 0.875rem;
  font-weight: normal;
  position: absolute;
  top: -1.5rem;
  left: 50%;
  margin-left: -60px;
  width: 120px;
  text-align: center;
`;

const Buttons = styled('div')`
  margin: 1.5rem 1.25rem;
  display: flex;
`;
const ModuleTitle = styled(RightTitle)`
  margin: 1rem 0 0 0;
  height: 6rem;
`;

export class SurveyModules extends Component {
  restoreFromPreview = () => {
    const { values, setValues, location } = this.props;
    const { surveyDetails, moduleList, projectDetails } =
      (location && location.state) || {};
    // try to restore temporary location data (preview)
    if (surveyDetails && moduleList && projectDetails) {
      setValues({
        ...values,
        surveyDetails,
        moduleList,
        projectDetails
      });
    }
  };
  componentDidUpdate(prevProps) {
    const previousLoadComplete = prevProps.values.initialLoadComplete;
    const initialLoadComplete = this.props.values.initialLoadComplete;
    if (previousLoadComplete !== initialLoadComplete && initialLoadComplete)
      this.restoreFromPreview();
  }
  state = {
    saving: false
  };
  renderModules() {
    const {
      loading,
      surveyLoadError,
      moduleList,
      onRemoveModule,
      moveCard,
      handleChange,
      values,
      setValues,
      setFieldValue,
      onPreviewClick,
      onPresentationClick,
      onDupeClick,
      onMoveClick
    } = this.props;
    if (loading || surveyLoadError) return;
    if (!sortModulesToArray(moduleList).length)
      return <AddAModule>Add new modules from the menu</AddAModule>;
    return sortModulesToArray(moduleList).map((module, i) => {
      // render module input by type (XY, 22, etc)
      const { order, moduleId, moduleType } = module;
      return (
        <ModuleCard
          moduleId={moduleId}
          order={order}
          moduleType={moduleType}
          key={moduleId}
          onRemoveModule={onRemoveModule}
          moveCard={moveCard}
          handleChange={handleChange}
          values={values}
          setValues={setValues}
          setFieldValue={setFieldValue}
          onPreviewClick={onPreviewClick}
          onPresentationClick={onPresentationClick}
          onDupeClick={onDupeClick}
          onMoveClick={onMoveClick}
        />
      );
    });
  }
  isSaveDraftEnabled() {
    return this.props.values.surveyDetails.name;
  }
  isSaveEnabled() {
    return (
      this.isSaveDraftEnabled() &&
      Object.entries(this.props.moduleList).length > 0
    );
  }
  render() {
    const {
      loading,
      surveyLoadError,
      survey,
      saving,
      dirty,
      handleChange,
      values,
      saveAsTemplate,
      saveDraft,
      saveSurvey,
      draft
    } = this.props;
    const load = <Loading loading={loading}>Loading...</Loading>;
    const error = (
      <NoSurveyFound loading={loading} surveyLoadError={surveyLoadError}>
        No survey found with id {survey}
      </NoSurveyFound>
    );
    const saveOpacity = saving ? 0.5 : 1;
    const titleKey = `surveyDetails.name`;
    //console.log(JSON.stringify({ dirty, draft }));
    const unpublishedChanges =
      dirty || draft ? (
        <UnpublishedChanges>Unpublished changes</UnpublishedChanges>
      ) : null;
    const save = !loading && !surveyLoadError && (
      <Center opacity={saveOpacity}>
        <SurveyTitle
          key={titleKey}
          id={titleKey}
          name={titleKey}
          onChange={handleChange}
          value={values.surveyDetails.name}
        />
        <Buttons>
          <SaveDraftButton
            onClick={saveAsTemplate}
            saveEnabled={this.isSaveEnabled()}
          >
            Create template
          </SaveDraftButton>
          <SaveDraftButton
            onClick={saveDraft}
            saveEnabled={this.isSaveDraftEnabled()}
          >
            {unpublishedChanges}
            Save
          </SaveDraftButton>
          <SaveButton onClick={saveSurvey} saveEnabled={this.isSaveEnabled()}>
            Publish
          </SaveButton>
        </Buttons>
      </Center>
    );
    return (
      <ModulesContainer>
        <ModuleTitle>
          {load}
          {error}
          {save}
        </ModuleTitle>
        {this.renderModules()}
      </ModulesContainer>
    );
  }
}

export default DragDropContext(HTML5Backend)(SurveyModules);
