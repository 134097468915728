import React, { useState } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
} from "@reach/accordion";
import styled from "styled-components";
import CaretDown from "../../../img/caret-down-dark.svg";
import CaretUp from "../../../img/caret-up-dark.svg";

const AccordingHeader = styled("h3")`
  margin-top: ${(props) => (props.lowerHeader ? "20px" : 0)};
  margin-bottom: 0;
  [data-reach-accordion-button] {
    background: none;
    outline: none;
    border: none;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #6a36ff;
    padding-right: 0;
    padding-left: 0;
    display: flex;
  }
`;

const AccordionContent = styled("div")`
  [data-reach-accordion-panel] {
    border: none;
    outline: none;
  }
`;

const styles = {
  projectLink: {
    fontFamily: "Roboto",
    fontSize: "15px",
    color: "#000",
    fontWeight: 400,
    cursor: "pointer",
    "&:hover": {
      color: "#6a36ff ",
    },
  },
};

export default function SelectProject({ projects, onSelectProject }) {
  const _projects = projects || { userProjects: [], sharedProjects: [] };

  const [indices, setIndices] = useState([0, 1]);

  function toggleItem(toggledIndex) {
    if (indices.includes(toggledIndex)) {
      setIndices(
        indices.filter((currentIndex) => currentIndex !== toggledIndex)
      );
    } else {
      setIndices([...indices, toggledIndex].sort());
    }
  }

  function isCollapsed(headerIndex) {
    return !indices.includes(headerIndex);
  }

  return (
    <Accordion index={indices} onChange={toggleItem}>
      <AccordionItem>
        <AccordingHeader>
          <AccordionButton css={{ display: "flex" }}>
            <img
              css={{ alignSelf: "normal" }}
              src={isCollapsed(0) ? CaretDown : CaretUp}
            />
            <span css={{ marginLeft: "10px" }}>My Projects</span>
          </AccordionButton>
        </AccordingHeader>
        <AccordionContent>
          <AccordionPanel>
            <div>
              {_projects.userProjects.map((project) => {
                return (
                  <div css={{ marginTop: "20px" }} key={project.id}>
                    <a css={styles.projectLink} onClick={() => onSelectProject(project)}>
                      {project.name}
                    </a>
                  </div>
                );
              })}
            </div>
          </AccordionPanel>
        </AccordionContent>
      </AccordionItem>
      <AccordionItem>
        <AccordingHeader lowerHeader>
          <AccordionButton css={{ display: "flex" }}>
            <img
              css={{ alignSelf: "normal" }}
              src={isCollapsed(1) ? CaretDown : CaretUp}
            />
            <span css={{ marginLeft: "10px" }}>Projects I'm shared to</span>
          </AccordionButton>
        </AccordingHeader>
        <AccordionContent>
          <AccordionPanel>
            <div>
              {_projects.sharedProjects.map((project) => {
                return (
                  <div css={{ marginTop: "20px" }} key={project.id}>
                    <a css={styles.projectLink} onClick={() => onSelectProject(project)}>
                      {project.name}
                    </a>
                  </div>
                );
              })}
            </div>
          </AccordionPanel>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}
