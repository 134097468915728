import React from "react";

/**
 * Show zero(0) in the tooltip.
 * 0.1 is only used to show a
 * small bar on the chart
 * @param points
 * @returns {*}
 */
const getPoint = points => (points === 0.2 ? 0 : points);

const FlyOut = ({
  x,
  y,
  datum,
  dx,
  dy,
  chartRightPadding,
  isSelected,
  isBar,
  fontSize
}) => {
  const defaultChartWidth = 400;
  const extraPadding = 10;
  let offset =
    x > defaultChartWidth
      ? x - defaultChartWidth + (chartRightPadding + extraPadding)
      : 0;
  const textX = x + 7;
  // TODO: Adjust the width of the tooltip depending on the name length using datum.
  return (
    <g>
      <rect
        x={x - offset}
        y={y - (isBar ? 30 : 45)}
        width={isBar ? 75 : 90}
        dx={dx}
        dy={dy}
        height={isBar ? 30 : 40}
        rx="4.5"
        fill="white"
        stroke="#868C97"
      />
      <text
        x={textX - offset}
        y={y - (isBar ? 18 : 28)}
        fontFamily="Roboto"
        fontSize={fontSize || 8}
        fontWeight="500"
        fill="#868C97"
      >
        {isSelected ? `Name: ${datum.name}` : `Points: ${datum.points}`}
      </text>
      <text
        x={textX - offset}
        y={y - (isBar ? 5 : 15)}
        fontFamily="Roboto"
        fontWeight="500"
        fontSize={fontSize || 8}
        fill="#868C97"
      >
        {isSelected
          ? `Points: ${getPoint(datum.points)}`
          : `Number of people: ${datum.members.length}`}
      </text>
    </g>
  );
};

export default FlyOut;
