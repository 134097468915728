import React, { useEffect, useRef, useState, useMemo, useContext } from "react";
import "@reach/dialog/styles.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import AnimLoader from "../components/loader";
import {
  TemplateCard,
  PlaceTemplateDialog,
  SelectProject,
} from "../components/Templates";
import SurveySort from "../components/SurveySort";
import Layout from "../components/layout";
import { ButtonBase } from "../components/buttons";
import ExitIcon from "../img/exit-blue.svg";
import withAuthorization from "../components/with-authorization";
import { authCondition } from "../lib/auth-api";
import {
  fetchTemplates,
  fetchProjects,
  fetchProjectsAUserIsSharedWith,
  createHilo,
  deleteTemplate,
} from "../lib/admin-api";
import { useAsyncResource } from "../utils/async";
import {
  sortByLastSaved,
  sortByRecentlyCreated,
  sortByUsageCount,
  sortByName,
} from "../lib/utils";
import { AuthUserContext } from "../components/auth-context";
import { PROJECT_ID_QUERY_KEY, PREVIEWROOT } from "../const/routes";
import {HILOS} from "../const";

const styles = {
  templatesContainer: {
    padding: "1.5rem",
    flexWrap: "wrap",
    display: "grid",
    width: "100%",
    "grid-template-rows": "minmax(270px, auto)",
    "grid-gap": "22px",
    margin: "0 0 22px 0",
    "grid-template-columns": "100%",
    "@media (min-width: 400px)": {
      "grid-template-columns": "repeat(auto-fill, minmax(345px, 1fr))",
    },
  },
  exitBtn: {
    display: "flex",
    marginLeft: "50px",
    background: "transparent",
    border: 0,
    padding: 0,
    alignItems: "center",
    width: "fit-content",
    fontSize: "1rem",
    marginTop: "10px",
    fontFamily: "Roboto",
    fontWeight: 500,
    color: "#6A36FF",
  },
  exitBtnIcon: {
    marginRight: "14px",
  },
  sortBtn: {
    marginLeft: "auto",
    background: "#6A36FF",
    border: 0,
    padding: "0.625rem 1rem",
    marginRight: "20px",
    color: "white",
    fontSize: "18px",
    fontFamily: "Roboto",
    fontWeight: 500,
  },
  sortBtnValue: {
    marginLeft: "20px",
  },
  sortBtnIcon: {
    verticalAlign: "unset",
    marginLeft: "30px",
  },
  headerContainer: {
    display: "flex",
    marginTop: "20px",
  },
  sort: {
    marginLeft: "auto",
    marginRight: "20px",
    background: "#6A36FF",
    height: "fit-content",
  },
};

const successStyles = {
  container: {
    fontFamily: "Roboto",
    fontWeight: 700,
    fontSize: "30px",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    color: "#6A36FF",
  },
  msg: {
    marginTop: "auto",
    marginLeft: "auto",
    marginRight: "auto",
    width: "75%",
  },
  navBtn: {
    margin: "0 auto",
    border: "3px solid",
    padding: "4px 24px",
    borderRadius: "45px",
  },
  navBtnContainer: { marginTop: "auto", marginBottom: "auto", display: "flex" },
};

const createSurveyFromTemplate = ({ template, projectId, currentUser }) => {
  return createHilo({
    name: template.name,
    projectId,
    userId: currentUser.id,
    userName: currentUser.name,
    userEmail: currentUser.email,
    organizationId: currentUser.organizationId,
    organizationName: currentUser.organizationName,
    templateId: template.id,
    template: false,
    hilo: HILOS.SURVEY
  });
};

const CreateSurveySuccess = ({ projectName, onClickNavBtn }) => {
  return (
    <div css={successStyles.container}>
      <p css={successStyles.msg}>Success! Template is added to {projectName}</p>
      <div css={successStyles.navBtnContainer}>
        <ButtonBase css={successStyles.navBtn} onClick={onClickNavBtn}>
          Go to the project
        </ButtonBase>
      </div>
    </div>
  );
};

const sortParams = [
  {
    id: 1,
    label: "Name",
    fn: sortByName,
  },
  {
    id: 2,
    label: "Last Modified",
    fn: sortByLastSaved,
  },
  {
    id: 3,
    label: "Create Date",
    fn: sortByRecentlyCreated,
  },
  {
    id: 4,
    label: "Most Popular",
    fn: sortByUsageCount,
  },
];

function TemplatesGallery({ history, currentUser }) {
  const context = useContext(AuthUserContext);
  const organizationId =
    context && context.claims && context.claims.organizationId;
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const selectedTemplate = useRef(null);
  const selectedProject = useRef(null);
  const [currentSortParamId, setCurrentSortParamId] = useState(
    sortParams[0].id
  );

  const getProjectsAsync = useAsyncResource(async () => {
    const [userProjects, sharedProjects] = await Promise.all([
      fetchProjects(organizationId, currentUser.id),
      fetchProjectsAUserIsSharedWith(organizationId, currentUser.id),
    ]);
    return {
      userProjects: Object.values(userProjects),
      sharedProjects: Object.values(sharedProjects),
    };
  }, null);
  const getTemplatesAsync = useAsyncResource(() => fetchTemplates(organizationId), []); // prettier-ignore
  const createSurveyAsync = useAsyncResource();

  useEffect(() => {
    const getUserTemplates = async () => {
      if (!organizationId) return;
      getTemplatesAsync.execute();
    };
    getUserTemplates();
  }, [organizationId]);

  const handlePlaceTemplateDialogDismiss = () => {
    setDialogIsOpen(false);
    createSurveyAsync.reset();
  };

  const handleTurnIntoSurveyClick = (template) => {
    selectedTemplate.current = template;
    setDialogIsOpen(true);
    if (getProjectsAsync.data == null) getProjectsAsync.execute();
  };

  const onClickEditBtn = (template) => {
    const { id } = template;
    if (!id) return;
    return history.push(`/edit?templateId=${id}`);
  };

  const handleTemplatePreviewBtnClick = (template) => {
    const { id } = template;
    if (!id) return;
    window.open(`${PREVIEWROOT}/${id}/1`);
  };

  const handleProjectSelection = async (project) => {
    selectedProject.current = project;
    await createSurveyAsync.execute(() =>
      createSurveyFromTemplate({
        template: selectedTemplate.current,
        projectId: project.id,
        currentUser: currentUser,
      })
    );
    if (createSurveyAsync.state.isSuccess) {
      await getTemplatesAsync.execute(() =>
        getTemplatesAsync.data.map((template) => {
          if (template.id === selectedTemplate.current.id) {
            const updatedTemplate = {
              ...template,
              usageCount: (template.usageCount || 0) + 1,
            };
            return updatedTemplate;
          }
          return template;
        })
      );
    }
  };

  const handleDeleteTemplate = async (template) => {
    const confirmed = window.confirm(
      `${template.name}\nAre you sure you want to delete this template?`
    );
    if (!confirmed) return;
    try {
      await deleteTemplate(template.id);
    } catch (error) {}
    await getTemplatesAsync.execute();
  };

  const sortedTemplates = useMemo(() => {
    const sortParam = sortParams.find(
      (param) => param.id === currentSortParamId
    );
    return getTemplatesAsync.data.sort(sortParam.fn);
  }, [currentSortParamId, getTemplatesAsync.data]);

  return (
    <>
      <Layout title="Templates">
        <div css={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <div css={styles.headerContainer}>
            <button
              css={styles.exitBtn}
              onClick={() => history.replace("/admin")}
            >
              <img
                css={styles.exitBtnIcon}
                src={ExitIcon}
                alt="back to surveys"
              />
              Back to surveys
            </button>
            <SurveySort
              css={styles.sort}
              items={sortParams}
              onSelectedItem={(item) => setCurrentSortParamId(item.id)}
            />
          </div>
          {getTemplatesAsync.state.isSuccess() && (
            <div css={[styles.templatesContainer]}>
              {sortedTemplates.map((template) => (
                <TemplateCard
                  key={template.id}
                  template={template}
                  isOwned={(currentUser || {}).id === template.creatorId}
                  handleTurnIntoSurveyClick={() =>
                    handleTurnIntoSurveyClick(template)
                  }
                  onClickEditBtn={() => onClickEditBtn(template)}
                  onClickPreviewBtn={() =>
                    handleTemplatePreviewBtnClick(template)
                  }
                  onClickDeleteBtn={() => handleDeleteTemplate(template)}
                  sortedByUsageCount={currentSortParamId === 4}
                />
              ))}
            </div>
          )}
          {getTemplatesAsync.state.isLoading() &&
            (getTemplatesAsync.data || []).length == 0 && (
              <AnimLoader styles={{ width: 400, height: 300 }} />
            )}
        </div>
      </Layout>
      <PlaceTemplateDialog
        onDismiss={handlePlaceTemplateDialogDismiss}
        isOpen={dialogIsOpen}
      >
        {(getProjectsAsync.state.isLoading() ||
          createSurveyAsync.state.isLoading()) && (
          <div css={{ margin: "auto" }}>
            <AnimLoader styles={{ width: 200, height: 200 }} />
          </div>
        )}
        {getProjectsAsync.state.isSuccess() &&
          !createSurveyAsync.state.isLoading() &&
          !createSurveyAsync.data && (
            <SelectProject
              projects={getProjectsAsync.state.data}
              onSelectProject={handleProjectSelection}
            />
          )}
        {createSurveyAsync.state.isSuccess() && (
          <CreateSurveySuccess
            projectName={(selectedProject.current || {}).name}
            onClickNavBtn={() => {
              history.push(
                `/admin?${PROJECT_ID_QUERY_KEY}=${selectedProject.current.id}`
              );
            }}
          />
        )}
      </PlaceTemplateDialog>
    </>
  );
}

export default withAuthorization(authCondition)(TemplatesGallery);
