import React, { useState, useRef } from "react";
import { useDropzone } from "react-dropzone";
import { ButtonBase } from "../buttons";
import { ReactComponent as IconClose } from "../../img/ic_close.svg";
import { ReactComponent as IconAdd } from "../../img/plus.svg";

const styles = {
  imageUploadBoxContainer: {
    position: "absolute",
    top: 20,
    left: 0,
    right: 0,
    width: "100%",
    borderRadius: "10px",
    background: "#FFF",
    height: "190px",
    padding: "12px",
    border: "1px dashed #1CA4FC",
    display: "flex",
    flexDirection: "column"
  },
  imageDragZone: {
    display: "flex",
    flexDirection: "column"
  },
  imageUploadBoxCloseBtn: {
    stroke: "#1CA4FC",
    width: "fit-content",
    marginLeft: "auto"
  },
  imageUploadBoxAddBtn: {
    background: "#1CA4FC",
    padding: "8px",
    width: "fit-content",
    margin: "18px auto 24px"
  },
  imageUploadBoxText: {
    margin: "0 auto",
    fontSize: "1rem",
    color: "#1CA4FC",
    fontWeight: 500
  },
  imageUploadBoxSubText: {
    margin: "5px auto 0",
    fontSize: "10px",
    color: "#BDBDBD",
    fontWeight: "normal"
  }
};

const FileUpload = ({ onClickClose, onImageAdded, maxFiles = 1, acceptedMimeTypes = "image/jpeg, image/png, image/jpg" }) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptedMimeTypes,
    maxFiles,
    onDrop: acceptedFiles => {
      if (acceptedFiles.length === 1) {
        const file = acceptedFiles[0];
        onImageAdded({ file });
      }
    }
  });

  return (
    <div css={styles.imageUploadBoxContainer}>
      <ButtonBase css={styles.imageUploadBoxCloseBtn} onClick={onClickClose}>
        <IconClose />
      </ButtonBase>
      <div css={styles.imageDragZone} {...getRootProps()}>
        <input {...getInputProps()} />
        <ButtonBase css={styles.imageUploadBoxAddBtn}>
          <IconAdd />
        </ButtonBase>
        <p css={styles.imageUploadBoxText}>Select file to upload</p>
        <p css={styles.imageUploadBoxSubText}>
          or drag and drop, copy and paste files
        </p>
        <em css={styles.imageUploadBoxSubText}>Only *.jpeg and .png images are accepted</em>
      </div>
    </div>
  );
};

export default FileUpload;
