// # setNestedData
export function setNestedData(root, path, value) {
  var paths = path.split('.');
  var last_index = paths.length - 1;
  paths.forEach(function(key, index) {
    if (!(key in root)) root[key] = {};
    if (index === last_index) root[key] = value;
    root = root[key];
  });
  return root;
}

// # getNestedData
export function getNestedData(obj, path) {
  var index = function(obj, i) { return obj && obj[i]; };
  return path.split('.').reduce(index, obj);
}

// # flattenNestedData
export function flattenNestedData(obj) {
  var returnObj = {};
  for (var i in obj) {
    if (!obj.hasOwnProperty(i)) continue;
    if ((typeof obj[i]) === 'object') {
      var flatObject = flattenNestedData(obj[i]);
      for (var x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;
        returnObj[i + '.' + x] = flatObject[x];
      }
      continue;
    }
    returnObj[i] = obj[i];
  }
  return returnObj;
};
