import React, { Component, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import * as routes from './const/routes';
import LandingPage from 'pages/Landing/landing-page';
import AdminPage2 from 'pages/Admin/admin-page-2';
import Layout from './components/layout';
import SurveyBuilder2 from 'pages/SurveyBuilder/survey-builder-2';
import Survey from './pages/Survey/survey';
import Export from './pages/export';
import FullPresentation from './pages/full-presentation';
import { doSignOut } from './lib/auth-api';
import Presentation from './pages/presentation';
import { initGoogleAnalytics } from './lib/GoogleAnalytics';
import GoogleAnalyticsTracker from './components/GoogleAnalytics ';
import TemplatesGallery from './pages/templates-gallery';
import Workshop from './pages/workshop';

export const RouterContext = React.createContext({
  navigate: () => {},
  survey: null,
  title: null,
  subtitle: null,
  loading: null,
  saving: null,
});

const Logout = ({ history }) => {
  useEffect(() => {
    doSignOut().then(() => history.push('/'));
  }, []);
  return <div>Logging out...</div>;
};

export default class Navigation extends Component {
  setTitle = ({ title }) => {
    this.setState({ title });
  };
  setSubtitle = ({ subtitle }) => {
    this.setState({ subtitle });
  };
  setLoading = ({ loading }) => {
    this.setState({ loading });
  };
  setSaving = ({ saving }) => {
    this.setState({ saving });
  };
  render() {
    return (
      <Router>
        {initGoogleAnalytics() && <Route component={GoogleAnalyticsTracker} />}
        <Switch>
          <Route exact path={routes.LANDING} component={LandingPage} />
          <Route exact path={'/survey'} component={LandingPage} />
          <Route
            exact
            path={routes.TEMPLATES_GALLERY}
            component={TemplatesGallery}
          />
          <Route path={routes.ADMIN} component={AdminPage2} />
          <Route path="/logout" component={Logout} />
          <Route path={routes.LAYOUT} component={Layout} />
          <Route path={routes.BUILDER} component={SurveyBuilder2} />
          <Route path={routes.ADD} component={SurveyBuilder2} />
          <Route path={routes.EDIT} component={SurveyBuilder2} />
          <Route path={routes.MANAGE} component={SurveyBuilder2} />
          <Route path={routes.SURVEY} component={Survey} />
          <Route exact path={routes.RESULT} component={Survey} />
          <Route exact path={routes.PREVIEW} component={Survey} />
          <Route exact path={routes.FACILITATION} component={Survey} />
          <Route path={routes.EXPORT} component={Export} />
          <Route
            exact
            path={routes.NEW_PRESENTATION}
            component={Presentation}
          />
          <Route exact path={routes.PRESENTATION} component={Survey} />
          <Route path={routes.FULLPRESENTATION} component={FullPresentation} />
          <Route path={routes.WORKSHOP} component={Workshop} />
          <Route>
            <Redirect to="/" />
          </Route>
        </Switch>
      </Router>
    );
  }
}
