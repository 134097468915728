import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ChartHeader,
  ChartSortAscImg,
  ChartSortContainer,
  ChartSortDescImg,
  PresenationBtnStyle,
} from './styles';
import PresentationButton from './PresentationButton';
import { setDistributionToggle } from 'pages/presentationSlice';

const ChartControls = ({
  onClickSortAsc,
  onClickSortDesc,
  isPlotter22,
  isOpenQuestion,
  isStackRank,
  moduleId,
  onClickPlotter22Average,
  onClickOpenQuestionResponse,
  onClickOpenQuestionWordCloud,
}) => {
  const dispatch = useDispatch();

  const presentationData = useSelector((state) => state.presentation);

  const isCommentPanelVisible = useSelector(
    (state) => state.presentation.isCommentsPanelVisible
  );

  const sortChartControls = useSelector(
    (state) => state.presentation[moduleId]?.sortChartControls
  );

  const shouldShowDistributionButton = useSelector(
    (state) => state.presentation[moduleId]?.shouldShowDistributionButton
  );

  const distributionToggle = useSelector(
    (state) => state.presentation[moduleId]?.distributionToggle
  );

  const onClickDistribution = () =>
    dispatch(
      setDistributionToggle({
        moduleId,
        distributionToggle: !distributionToggle,
      })
    );

  const onClickAsc = () => {
    onClickSortAsc({ asc: true, desc: false });
  };

  const onClickDesc = () => {
    onClickSortDesc({ asc: false, desc: true });
  };

  const renderControls = () => {
    if (isPlotter22) {
      return (
        <PresentationButton
          onClick={() => onClickPlotter22Average(moduleId)}
          toggle={presentationData[moduleId].plotter22AvgToggle}
        >
          Avg
        </PresentationButton>
      );
    }

    if (isOpenQuestion) {
      return (
        <>
          <PresentationButton
            onClick={() => onClickOpenQuestionResponse(moduleId)}
            toggle={presentationData[moduleId].openQuestionResponseToggle}
            marginRight="10px"
          >
            Response
          </PresentationButton>
          <PresentationButton
            onClick={() => onClickOpenQuestionWordCloud(moduleId)}
            toggle={presentationData[moduleId].isWordCloudSelected}
          >
            Word Cloud
          </PresentationButton>
        </>
      );
    }

    return (
      <>
        <ChartSortContainer>
          <ChartSortAscImg
            onClick={onClickAsc}
            selected={sortChartControls?.sortAscSelected}
          />
          <ChartSortDescImg
            onClick={onClickDesc}
            selected={sortChartControls?.sortDescSelected}
          />
        </ChartSortContainer>
        {shouldShowDistributionButton && (
          <PresenationBtnStyle>
            <PresentationButton
              onClick={onClickDistribution}
              toggle={distributionToggle}
            >
              Distribution
            </PresentationButton>
          </PresenationBtnStyle>
        )}
      </>
    );
  };

  return (
    <ChartHeader
      flexEnd={isPlotter22 || isOpenQuestion}
      MarginLeft={isStackRank}
      MarginBottom={isPlotter22}
      ControlPosition={isStackRank && isCommentPanelVisible}
      openEnded={isOpenQuestion}
    >
      {renderControls()}
    </ChartHeader>
  );
};

export default ChartControls;
