import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  DetailTextarea,
  Question,
  Description,
  AllowExplanationContainer,
  AllowExplanationCheckbox,
  HorizontalFieldContainer,
  SpacerContainer,
  QuestionDetailsContainer,
  QuestionDisplay,
  Type,
  Details,
  CancelButton,
  CancelSave,
  SaveButton,
} from './styles';
import { LabeledInput, DetailShortInputContainer } from '../../layout';
import {
  MODULE_TYPES_DISPLAY,
  MODULE_TYPES_FIELDS,
  TEXT_SLIDE,
  STACK_RANK,
  POINT_DISTRIBUTION,
  EXPLANATION,
  OPTION,
  SENTENCE,
  TEXT_HIGHLIGHT,
  MATRIX,
  SPECTRUM,
  SPECTRUM_DISTRIBUTION,
  PERSONAL_INFO,
  SPECTRUM_DESCRIPTION,
  FIRST_COLUMN_LABEL,
  POINT_DISTRIBUTION_EXPLANATION,
  MADLIB,
  REQUIRED,
  MADLIB_SENTENCE,
  OPTION_PREFIX,
  DESCRIPTION,
  QUESTION,
  CATEGORY_DESCRIPTION,
  DISABLE_EMAIL_INPUT,
  STACK_FOOTER,
  STACK_HEADER,
  COMPARISON,
  WAITING_ROOM,
  OPEN_ENDED,
  MULTI_SELECT,
  POPULATE_OPTIONS,
  ADD_OPTION,
} from 'const/module-types';
import { getNestedData, setNestedData } from 'const/nested-data';
import ComparisonModuleFields from './Comparison';
import MultiSelectFields, {
  TypeSelection as MultiSelectTypeSelection,
} from './MultiSelect';
import { PopulateToggle } from './components/PopulateToggle';
import { PopulateDropdown } from './components/PopulateDropdown';
import { HILOS } from 'const/index';
import { AddOptions } from './components/ModulesInputs/AddOptions';
import {
  doesModuleHaveOptions,
  removeModuleOptions,
} from 'lib/Presentation/Helper';
import { handlePopulateToggle } from 'components/Builder/SurveyModulesEdit/Helper';

const isWaitingRoomModule = (moduleType) => moduleType === WAITING_ROOM;

export const filterAndSortOptions = (hash, prefix = OPTION_PREFIX) => {
  return Object.entries(hash)
    .filter(([key]) => {
      return key.indexOf(prefix) === 0;
    })
    .sort(([aKey], [bKey]) => {
      const aOrder = parseInt(aKey.replace(prefix, ''), 10);
      const bOrder = parseInt(bKey.replace(prefix, ''), 10);
      return aOrder - bOrder;
    });
};

const DetailShortInput = (props) => {
  return (
    <DetailShortInputContainer className={props.className}>
      <LabeledInput {...props} />
    </DetailShortInputContainer>
  );
};

const DetailShort = (props = {}) => {
  const { name, formikProps } = props;
  const { values, handleChange } = formikProps;
  const value = getNestedData(values, name);
  const extendProps = {
    value: value || '',
    onChange: handleChange,
    ...props,
  };
  return <DetailShortInput {...extendProps} />;
};

class AllowExplanation extends Component {
  toggleBoolean = () => {
    const { moduleId, field = EXPLANATION, formikProps } = this.props;
    const { values, setFieldValue } = formikProps;
    const key = `moduleList.${moduleId}.questionData.${field}`;
    const value = getNestedData(values, key) || '';
    setFieldValue(key, !value);
  };
  render() {
    const { toggleBoolean, props } = this;
    const {
      moduleId,
      formikProps,
      placeholder,
      field = EXPLANATION,
      isPersonInfoModule,
    } = props;
    const { values } = formikProps;
    const key = `moduleList.${moduleId}.questionData.${field}`;
    const value = getNestedData(values, key) || '';
    return (
      <AllowExplanationContainer
        onClick={toggleBoolean}
        isPersonInfoModule={isPersonInfoModule}
      >
        {placeholder}
        <AllowExplanationCheckbox
          id={key}
          name={key}
          key={key}
          type="checkbox"
          checked={value}
          readOnly
        />
      </AllowExplanationContainer>
    );
  }
}

class SurveyModulesEdit extends Component {
  state = {
    moduleType: '',
  };
  renderDetailsByType(optionsModuleType) {
    const { moduleType, moduleId, formikProps } = this.props;
    const selectedModuleType = optionsModuleType || moduleType;
    const fields = MODULE_TYPES_FIELDS[selectedModuleType] || {};
    return Object.entries(fields).map(([fieldKey, placeholder]) => {
      const key = `moduleList.${moduleId}.questionData.${fieldKey}`;
      return (
        <DetailShort
          id={key}
          name={key}
          key={key}
          data-field-key={fieldKey}
          placeholder={placeholder}
          formikProps={formikProps}
        />
      );
    });
  }

  getModuleQuestionPlaceholder(moduleType) {
    if (moduleType === TEXT_SLIDE) {
      return 'Header';
    } else if (moduleType === WAITING_ROOM) {
      return 'Title';
    } else {
      return 'Question';
    }
  }

  renderQuestion() {
    const { moduleType, moduleId, formikProps } = this.props;
    const { values, handleChange } = formikProps;
    const key = `moduleList.${moduleId}.${QUESTION}`;
    const value = getNestedData(values, key);
    const placeholder = this.getModuleQuestionPlaceholder(moduleType);
    return (
      <Question
        placeholder={placeholder}
        value={value || ''}
        id={key}
        name={key}
        key={key}
        onChange={handleChange}
      />
    );
  }

  getModuleDescriptionPlaceholder(moduleType) {
    if (moduleType === WAITING_ROOM) {
      return 'Direction';
    } else {
      return 'Description';
    }
  }

  renderDescription() {
    const { moduleId, moduleType, formikProps } = this.props;
    const { values, handleChange } = formikProps;
    const key = `moduleList.${moduleId}.${DESCRIPTION}`;
    const value = getNestedData(values, key);
    const placeholder = this.getModuleDescriptionPlaceholder(moduleType);
    return (
      <Description
        placeholder={placeholder}
        value={value || ''}
        id={key}
        name={key}
        key={key}
        onChange={handleChange}
      />
    );
  }
  getQuestionData() {
    const { moduleId, formikProps } = this.props;
    const { values } = formikProps;
    const key = `moduleList.${moduleId}.questionData`;
    const questionData = getNestedData(values, key);
    return questionData;
  }

  onToggle = () => {
    this.setState(({ toggleChecked }) => ({ toggleChecked: !toggleChecked }));
  };

  onPopulateToggle = ({ populateOptionsKey, value, moduleId }) => {
    const {
      formikProps: { setFieldValue, values },
    } = this.props;
    handlePopulateToggle(
      values,
      moduleId,
      value,
      setFieldValue,
      populateOptionsKey
    );
  };

  renderStackRankInputs() {
    const {
      order,
      moduleType,
      moduleId,
      modulesArray,
      formikProps,
      hilo,
    } = this.props;
    const { handleChange, values } = formikProps;
    if (moduleType !== STACK_RANK) return;
    const keyHeader = `moduleList.${moduleId}.questionData.${STACK_HEADER}`;
    const isPopulateOptionsKey = `moduleList.${moduleId}.questionData.${POPULATE_OPTIONS}`;
    const isPopulateOptions =
      getNestedData(values, isPopulateOptionsKey) || false;
    const headerValue = getNestedData(formikProps.values, keyHeader);
    const mostImportant = (
      <DetailShort
        css={{ flex: '0 1 100%' }}
        id={keyHeader}
        name={keyHeader}
        key={keyHeader}
        value={headerValue || (headerValue === '' ? '' : 'Most Important')}
        onChange={handleChange}
        formikProps={formikProps}
        placeholder="Header"
        maxLength="35"
      />
    );
    const keyFooter = `moduleList.${moduleId}.questionData.${STACK_FOOTER}`;
    const footerValue = getNestedData(formikProps.values, keyFooter);
    const leastImportant = (
      <DetailShort
        css={{ flex: '0 1 100%' }}
        id={keyFooter}
        name={keyFooter}
        key={keyFooter}
        value={footerValue || (footerValue === '' ? '' : 'Least Important')}
        onChange={handleChange}
        formikProps={formikProps}
        placeholder="Footer"
        maxLength="35"
      />
    );

    let lastIndex = 0;
    const currentItems = filterAndSortOptions(this.getQuestionData()).map(
      ([key]) => {
        const key2 = `moduleList.${moduleId}.questionData.${key}`;
        lastIndex = parseInt(key.replace('option', ''), 10);
        return (
          <DetailShort
            id={key2}
            name={key2}
            key={key2}
            placeholder={`Change Item ${lastIndex}`}
            formikProps={formikProps}
          />
        );
      }
    );
    const key3 = `moduleList.${moduleId}.questionData.option${lastIndex + 1}`;
    const additionalInput = (
      <DetailShort
        id={key3}
        name={key3}
        key={key3}
        placeholder={`Add Item ${lastIndex + 1}`}
        formikProps={formikProps}
        value={''}
      />
    );

    const isWorkshopMode = hilo.type === HILOS.WORKSHOP;

    const components = [mostImportant, leastImportant];

    const populateOption = (
      <PopulateToggle
        key={order}
        displayOrder={order}
        checked={isPopulateOptions}
        onChange={(value) =>
          this.onPopulateToggle({
            populateOptionsKey: isPopulateOptionsKey,
            value,
            moduleId,
          })
        }
      />
    );

    if (isWorkshopMode) {
      components.push(populateOption);
    }

    const populateDropdown = (
      <PopulateDropdown
        key={`dropdown_key${order}`}
        displayOrder={order}
        options={modulesArray}
        moduleId={moduleId}
        formikProps={formikProps}
      />
    );

    const componentToShow =
      isPopulateOptions && isWorkshopMode
        ? [populateDropdown]
        : [...currentItems, additionalInput];

    return [...components, ...componentToShow];
  }
  renderMultiSelectInputs() {
    const {
      order,
      moduleType,
      moduleId,
      modulesArray,
      formikProps,
    } = this.props;
    if (moduleType !== MULTI_SELECT) return null;
    const questionData = this.getQuestionData();
    const options = filterAndSortOptions(questionData);
    return (
      <>
        <MultiSelectFields
          options={options}
          formikProps={formikProps}
          moduleId={moduleId}
          modulesArray={modulesArray}
          displayOrder={order}
        />
      </>
    );
  }
  renderAddOptionInputs() {
    const {
      order,
      moduleType,
      moduleId,
      modulesArray,
      formikProps,
    } = this.props;
    const { values } = formikProps;
    const optionNumberKey = `moduleList.${moduleId}.questionData.${OPTION}`;
    const isOpenOptionChecked = getNestedData(values, optionNumberKey);
    if (moduleType !== ADD_OPTION) return null;
    const questionData = this.getQuestionData();
    return (
      <>
        <AddOptions
          moduleType={moduleType}
          moduleId={moduleId}
          formikProps={formikProps}
          modulesArray={modulesArray}
          isOpenOptionChecked={isOpenOptionChecked}
          order={order}
        />
      </>
    );
  }
  renderRequired() {
    const {
      moduleId,
      moduleType,
      formikProps,
      isPersonInfoModule,
    } = this.props;
    if (moduleType === WAITING_ROOM) return null;

    const required = (
      <AllowExplanation
        placeholder="Required"
        moduleId={moduleId}
        formikProps={formikProps}
        field={REQUIRED}
        isPersonInfoModule={isPersonInfoModule}
      />
    );
    return required;
  }
  renderAllowExplanation() {
    const {
      moduleId,
      moduleType,
      formikProps,
      isPersonInfoModule,
      field = EXPLANATION,
    } = this.props;
    if ([WAITING_ROOM, OPEN_ENDED, TEXT_SLIDE].includes(moduleType))
      return null;

    const { initialValues } = formikProps;
    const key = `moduleList.${moduleId}.questionData.${field}`;
    const initialVal = setNestedData(initialValues, key, true);
    const initialFormikProps = { ...formikProps, initialValues: initialVal };

    const explanation = (
      <AllowExplanation
        placeholder="Allow comments"
        formikProps={initialFormikProps}
        moduleId={moduleId}
        field={EXPLANATION}
        isPersonInfoModule={isPersonInfoModule}
      />
    );
    return explanation;
  }
  renderOptionNumber() {
    const {
      moduleId,
      moduleType,
      formikProps,
      isPersonInfoModule,
    } = this.props;
    if ([WAITING_ROOM, OPEN_ENDED, TEXT_SLIDE].includes(moduleType))
      return null;

    const optionCheck = (
      <AllowExplanation
        placeholder="Set option number"
        formikProps={formikProps}
        moduleId={moduleId}
        field={OPTION}
        isPersonInfoModule={isPersonInfoModule}
      />
    );
    return optionCheck;
  }
  renderCategoryDescription() {
    const {
      moduleType,
      moduleId,
      formikProps,
      isPersonInfoModule,
    } = this.props;
    if (moduleType !== PERSONAL_INFO) return null;
    const explanation = (
      <AllowExplanation
        placeholder="Disable Category Description"
        formikProps={formikProps}
        moduleId={moduleId}
        field={CATEGORY_DESCRIPTION}
        isPersonInfoModule={isPersonInfoModule}
      />
    );
    return explanation;
  }
  renderDisableEmailInput() {
    const {
      moduleType,
      moduleId,
      formikProps,
      isPersonInfoModule,
    } = this.props;
    if (moduleType !== PERSONAL_INFO) return null;
    const explanation = (
      <AllowExplanation
        placeholder="Disable Email"
        formikProps={formikProps}
        moduleId={moduleId}
        field={DISABLE_EMAIL_INPUT}
        isPersonInfoModule={isPersonInfoModule}
      />
    );
    return explanation;
  }
  renderAllowPointDistributionExplanation() {
    const { moduleType, moduleId, formikProps } = this.props;
    if (moduleType !== POINT_DISTRIBUTION) return;
    const explanation = (
      <AllowExplanation
        placeholder="Allow comment per item"
        formikProps={formikProps}
        moduleId={moduleId}
        field={POINT_DISTRIBUTION_EXPLANATION}
      />
    );
    return explanation;
  }
  renderPointDistributionInputs() {
    const {
      order,
      moduleType,
      moduleId,
      modulesArray,
      formikProps,
      hilo,
    } = this.props;
    const { values } = formikProps;
    if (moduleType !== POINT_DISTRIBUTION) return;
    const key = `moduleList.${moduleId}.questionData`;
    const value = getNestedData(values, key);
    const isPopulateOptionsKey = `moduleList.${moduleId}.questionData.${POPULATE_OPTIONS}`;
    const isPopulateOptions =
      getNestedData(values, isPopulateOptionsKey) || false;
    let lastIndex = 0;
    const currentItems = filterAndSortOptions(value).map(([key]) => {
      const key2 = `moduleList.${moduleId}.questionData.${key}`;
      lastIndex = parseInt(key.replace('option', ''), 10);
      return (
        <DetailShort
          id={key2}
          name={key2}
          key={key2}
          placeholder={`Change Item ${lastIndex}`}
          formikProps={formikProps}
        />
      );
    });
    const key3 = `moduleList.${moduleId}.questionData.option${lastIndex + 1}`;
    const additionalInput = (
      <DetailShort
        id={key3}
        name={key3}
        key={key3}
        placeholder={`Add Item ${lastIndex + 1}`}
        formikProps={formikProps}
        value={''}
      />
    );

    const isWorkshopMode = hilo.type === HILOS.WORKSHOP;

    const components = [];

    const populateOption = (
      <PopulateToggle
        key={order}
        displayOrder={order}
        checked={isPopulateOptions}
        onChange={(value) =>
          this.onPopulateToggle({
            populateOptionsKey: isPopulateOptionsKey,
            value,
            moduleId,
          })
        }
      />
    );

    if (isWorkshopMode) {
      components.push(populateOption);
    }

    const populateDropdown = (
      <PopulateDropdown
        key={`dropdown_key${order}`}
        displayOrder={order}
        options={modulesArray}
        moduleId={moduleId}
        formikProps={formikProps}
      />
    );
    const componentToShow =
      isPopulateOptions && isWorkshopMode
        ? [populateDropdown]
        : [...currentItems, additionalInput];

    return [...components, ...componentToShow];
  }
  renderTextHighlightInputs() {
    const { moduleType, moduleId, formikProps } = this.props;
    const { values, handleChange } = formikProps;
    if (moduleType !== TEXT_HIGHLIGHT) return;
    const key = `moduleList.${moduleId}.questionData.${SENTENCE}`;
    const value = getNestedData(values, key);
    return (
      <DetailTextarea
        id={key}
        name={key}
        key={key}
        placeholder="Sentence"
        onChange={handleChange}
        value={value || ''}
        rows={3}
      />
    );
  }
  renderMatrixRowInputs() {
    const { moduleId, formikProps } = this.props;
    let lastRowIndex = 0;
    const questionData = this.getQuestionData();
    const rowItems = Object.entries(questionData)
      .filter(([key]) => {
        return key.indexOf('row') === 0;
      })
      .map(([key, matrixText]) => {
        const key2 = `moduleList.${moduleId}.questionData.${key}`;
        const intRow = parseInt(key.replace('row', ''), 10);
        lastRowIndex = intRow;
        return (
          <DetailShort
            id={key2}
            name={key2}
            key={key2}
            placeholder={`Row ${intRow}`}
            formikProps={formikProps}
            value={matrixText || ''}
          />
        );
      });
    const addRowKey = `moduleList.${moduleId}.questionData.row${lastRowIndex +
      1}`;
    const addRowInput = (
      <DetailShort
        id={addRowKey}
        name={addRowKey}
        key={addRowKey}
        placeholder={`Row ${lastRowIndex + 1}`}
        formikProps={formikProps}
        value={''}
      />
    );
    return [...rowItems, addRowInput];
  }
  renderMatrixColumnInputs() {
    const { moduleId, formikProps } = this.props;
    let lastColumnIndex = 0;
    const questionData = this.getQuestionData();
    const columnItems = Object.entries(questionData)
      .filter(([key]) => {
        return key.indexOf('column') === 0;
      })
      .map(([key, matrixText]) => {
        const key2 = `moduleList.${moduleId}.questionData.${key}`;
        const intColumn = parseInt(key.replace('column', ''), 10);
        lastColumnIndex = intColumn;
        return (
          <DetailShort
            id={key2}
            name={key2}
            key={key2}
            placeholder={`Column ${intColumn}`}
            formikProps={formikProps}
            value={matrixText || ''}
          />
        );
      });
    const addColumnKey = `moduleList.${moduleId}.questionData.column${lastColumnIndex +
      1}`;
    const addColumnInput = (
      <DetailShort
        id={addColumnKey}
        name={addColumnKey}
        key={addColumnKey}
        placeholder={`Column ${lastColumnIndex + 1}`}
        formikProps={formikProps}
        value={''}
      />
    );
    return [...columnItems, addColumnInput];
  }
  renderMatrixInputs() {
    const { moduleType, moduleId, formikProps } = this.props;
    const { values } = formikProps;
    if (moduleType !== MATRIX) return;
    const firstColumnLabelKey = `moduleList.${moduleId}.questionData.${FIRST_COLUMN_LABEL}`;
    const value = getNestedData(values, firstColumnLabelKey);
    const firstColumnLabelInput = (
      <DetailShort
        id={firstColumnLabelKey}
        name={firstColumnLabelKey}
        key={firstColumnLabelKey}
        placeholder="# Column Label"
        formikProps={formikProps}
        value={value || ''}
      />
    );
    return [
      firstColumnLabelInput,
      <HorizontalFieldContainer key="matrixRows">
        {this.renderMatrixRowInputs()}
      </HorizontalFieldContainer>,
      <HorizontalFieldContainer key="matrixColumns">
        {this.renderMatrixColumnInputs()}
      </HorizontalFieldContainer>,
    ];
  }
  renderAllowSpectrum() {
    return null;
    /*
    const { moduleType, moduleId, formikProps } = this.props;
    const { values, handleChange, setFieldValue } = formikProps;
    if (![STACK_RANK, POINT_DISTRIBUTION, MATRIX].includes(moduleType)) return;
    const spectrum = (
      <AllowExplanation
        placeholder="Allow spectrum"
        formikProps={formikProps}
        moduleId={moduleId}
        field={SPECTRUM}
      />
    );
    return spectrum;
    */
  }
  renderPersonalInfoInputs() {
    const { moduleType, moduleId, formikProps } = this.props;
    const { values } = formikProps;
    if (moduleType !== PERSONAL_INFO) return;
    const disableTeamKey = `moduleList.${moduleId}.questionData.disableCategoryDescription`;
    const disableTeam = getNestedData(values, disableTeamKey) || false;
    const key = `moduleList.${moduleId}.questionData`;
    const value = getNestedData(values, key);
    let lastIndex = 0;
    const currentItems = filterAndSortOptions(value).map(
      ([key, pointDistributionText]) => {
        const key2 = `moduleList.${moduleId}.questionData.${key}`;
        lastIndex = parseInt(key.replace('option', ''), 10);
        return (
          <DetailShort
            id={key2}
            name={key2}
            key={key2}
            placeholder={`Option ${lastIndex}`}
            inputPlaceholder={`Change option ${lastIndex}`}
            formikProps={formikProps}
            value={pointDistributionText || ''}
            disable={disableTeam}
          />
        );
      }
    );
    const key3 = `moduleList.${moduleId}.questionData.option${lastIndex + 1}`;
    const additionalInput = (
      <DetailShort
        id={key3}
        name={key3}
        key={key3}
        placeholder={`Option ${lastIndex + 1}`}
        inputPlaceholder={`Add option ${lastIndex + 1}`}
        formikProps={formikProps}
        value={''}
        disable={disableTeam}
      />
    );
    const key4 = `moduleList.${moduleId}.questionData.categoryDescription`;
    const value4 = getNestedData(values, key4);

    const isCatDescKey = `moduleList.${moduleId}.questionData.${CATEGORY_DESCRIPTION}`;
    const isCatDesc = getNestedData(values, isCatDescKey);

    const categoryDescription = (
      <DetailShort
        id={key4}
        name={key4}
        key={key4}
        placeholder={`Category Description`}
        inputPlaceholder="Team"
        formikProps={formikProps}
        value={value4 || ''}
        disable={disableTeam}
      />
    );
    const spacerContainer = <SpacerContainer key="placeholder" />;
    return !isCatDesc
      ? [categoryDescription, spacerContainer, ...currentItems, additionalInput]
      : [];
  }
  renderAdditionalSpectrum() {
    const { moduleType, moduleId, formikProps } = this.props;
    const { values } = formikProps;
    if (![STACK_RANK, POINT_DISTRIBUTION, MATRIX].includes(moduleType)) return;
    const keyPrefix = `moduleList.${moduleId}.questionData`;
    const spectrumKey = `${keyPrefix}.${SPECTRUM}`;
    const spectrumEnabled = getNestedData(values, spectrumKey);
    if (!spectrumEnabled) return;
    const specDescriptionKey = `${keyPrefix}.${SPECTRUM_DESCRIPTION}`;
    const specDescription = (
      <DetailShort
        name={specDescriptionKey}
        key={specDescriptionKey}
        placeholder="Spectrum Description"
        formikProps={formikProps}
      />
    );
    const spectrumDistributionFields = this.renderDetailsByType(
      SPECTRUM_DISTRIBUTION
    );
    // as per @eric only spectrum description for now
    return [specDescription, ...spectrumDistributionFields];
    //return [ specQuestion, specDescription, ...spectrumDistributionFields ];
  }
  renderMadlibInput() {
    const { moduleType, moduleId, formikProps } = this.props;
    const { values, handleChange } = formikProps;
    if (moduleType !== MADLIB) return;
    const key = `moduleList.${moduleId}.questionData.${MADLIB_SENTENCE}`;
    const value = getNestedData(values, key);
    return (
      <DetailTextarea
        id={key}
        name={key}
        key={key}
        placeholder="Madlib Sentence"
        inputPlaceholder="Enter madlib options [initial text, option 1, option 2] here"
        onChange={handleChange}
        value={value || ''}
        rows={3}
      />
    );
  }
  onCancelClick = (e) => {
    e.stopPropagation();
    const { clearEditingModule, formikProps } = this.props;
    const { resetForm } = formikProps;
    resetForm();
    clearEditingModule();
  };
  render() {
    const { onCancelClick } = this;
    const {
      onMouseEnter,
      onMouseLeave,
      moduleType,
      inEditMode,
      isEditing,
      saveDraft,
      isSaveDraftEnabled,
      formikProps,
      moduleId,
      order,
      modulesArray,
      hilo,
    } = this.props;
    const { values } = formikProps;
    const key = `moduleList.${moduleId}.${QUESTION}`;
    const optionNumberKey = `moduleList.${moduleId}.questionData.${OPTION}`;
    const value = getNestedData(values, key);
    const isOpenOptionChecked = getNestedData(values, optionNumberKey);
    const onPopulateToggle = {};
    const saveDisabled = isSaveDraftEnabled === false;
    if (inEditMode && isEditing) {
      return (
        <QuestionDetailsContainer
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {moduleType === MULTI_SELECT && (
            <MultiSelectTypeSelection
              moduleId={moduleId}
              formikProps={formikProps}
            />
          )}
          <Type>{MODULE_TYPES_DISPLAY[moduleType]}</Type>
          {moduleType !== COMPARISON && (
            <>
              <QuestionDisplay noRequired={isWaitingRoomModule(moduleType)}>
                {value}
              </QuestionDisplay>
              <Details noRequired={isWaitingRoomModule(moduleType)}>
                {this.renderRequired()}
                {this.renderAllowExplanation()}
                {moduleType === ADD_OPTION && this.renderOptionNumber()}
                {this.renderCategoryDescription()}
                {this.renderDisableEmailInput()}
              </Details>
              <Details>
                {this.renderQuestion()}
                {this.renderAllowPointDistributionExplanation()}
                {this.renderAllowSpectrum()}
                {this.renderAdditionalSpectrum()}
                {this.renderDescription()}
                {this.renderDetailsByType()}
                {this.renderStackRankInputs()}
                {this.renderPointDistributionInputs()}
                {this.renderTextHighlightInputs()}
                {this.renderMatrixInputs()}
                {this.renderPersonalInfoInputs()}
                {this.renderMadlibInput()}
                {this.renderMultiSelectInputs()}
                {this.renderAddOptionInputs()}
              </Details>
            </>
          )}
          {moduleType === COMPARISON && (
            <ComparisonModuleFields {...this.props} />
          )}
          <CancelSave>
            <CancelButton onClick={onCancelClick} hoverBold>
              Cancel
            </CancelButton>
            <SaveButton disabled={saveDisabled} onClick={saveDraft} hoverBold>
              Save
            </SaveButton>
          </CancelSave>
        </QuestionDetailsContainer>
      );
    }
    return (
      <QuestionDetailsContainer
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <Type>{MODULE_TYPES_DISPLAY[moduleType]}</Type>
        <QuestionDisplay>{value}</QuestionDisplay>
      </QuestionDetailsContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  hilo: state.hilo,
});

export default connect(mapStateToProps)(SurveyModulesEdit);
