import styled from 'styled-components';
import MobilePhoneSkinImg from 'img/mobile-phone-skin.svg';
import { LargeButton2, RightColumn } from 'components/layout';

export const ManageTitle = styled('div')`
  font-family: Neue Haas Grotesk Display Std;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 43px;
`;
export const ManageSubtitle = styled('span')`
  font-family: Neue Haas Grotesk Display Std;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 27px;
  color: #262525;
`;
export const ManageSelected = styled('span')`
  font-weight: bold;
`;
export const ManageTotal = styled('span')`
  font-weight: 500;
`;

export const CompletedUserContainer = styled('tr')`
  margin-bottom: 0.5rem;
  opacity: ${({ responseHidden }) => (responseHidden ? '0.5' : '1')};
  font-family: Neue Haas Grotesk Display Std;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
`;
export const CompletedByUser = styled('td')``;

export const CompletedCell = styled('td')`
  padding: 14px;
  width: ${({ width }) => width || 'auto'};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')};
`;

export const EditContainer = styled('div')`
  height: 100%;
`;
export const DesktopView = styled('div')`
  height: 100%;
`;
export const MobileView = styled('div')`
  display: flex;
  height: 100%;
`;
export const MobilePhoneSkin = styled('div')`
  flex: 0 0 397px;
  width: 397px;
  height: 672px;
  background: url('${MobilePhoneSkinImg}') center / auto no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
`;
export const MobilePhone = styled('div')`
  width: 375px;
  height: 575px;
`;

export const ManageContainer = styled('div')`
  height: 100%;
  padding: 22px 0;
`;

export const ManageRow = styled('div')`
  display: flex;
  width: 100%;
  padding: 0 11px;
`;

export const ManageLeft = styled('div')`
  flex: 1;
`;
export const ManageRight = styled('div')``;

export const ResponsesHeader = styled('tr')`
  height: 68px;
`;

export const ResponsesBorder = styled('div')`
  border-top: 1px dashed black;
  width: 100%;
`;

export const ResponsesTable = styled('table')`
  width: 100%;
  margin-top: 38px;
  border-collapse: collapse;
  padding: 0;
  position: relative;
`;

export const ResponsesButtons = styled('div')`
  display: flex;
  align-items: center;
`;

export const ResponsesCount = styled('div')`
  min-width: 43px;
`;

export const ResponsesButton = styled('div')`
  width: 30px;
  height: 30px;
  background: url('${({ img }) => img}') center / auto no-repeat;
  cursor: pointer;
  margin: 0 4px;
`;

export const ResponsesLargeButton = styled(LargeButton2)`
  justify-content: space-between;
  padding: 0 15px;
  width: 209px;
  margin-bottom: 15px;
`;

export const BuilderOverlay = styled('div')`
  background: rgba(242, 242, 242, 0.8);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding-top: 71px;
`;

export const LaunchedTitle = styled('div')`
  font-family: Neue Haas Grotesk Display Std;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 37px;
  letter-spacing: -0.01em;
  color: #ffffff;
`;

export const LaunchedSubtitle = styled('div')`
  font-family: Neue Haas Grotesk Display Std;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 101.83%;
  /* or 37px */
  letter-spacing: -0.01em;
  color: #ffffff;
  margin-bottom: 60px;
`;

export const LaunchedButton = styled('div')`
  font-family: Neue Haas Grotesk Display Std;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.01em;

  color: #000000;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 275px;
  background: #ffffff;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
  &:hover {
    color: #1ca4fc;
  }
`;

export const LaunchedPadding = styled('div')`
  padding: 62px 42px;
`;

export const BuilderRightColumn = styled(RightColumn)`
  ${(props) => (props.inEditMode ? `padding: 0` : ``)};
`;
