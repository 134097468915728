import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';

import Header, { Button, fadeIn, fadeOut } from '../components/header';

import PlusImg from '../img/plus4.svg';
import CloseImg from '../img/close.svg';
import DragHandleImg from '../img/drag-handle.svg';
import { Button2 } from '../components/buttons';

import './layout.css';
import '../fonts/stylesheet.css';
import Tracking from 'lib/tracking/tracking';
import { surveyTakerEvents } from 'lib/tracking/events';

const Background = styled('div')`
  background: ${(props) => (props.background ? props.background : '#FFF')};
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
`;

const Body = styled('div')`
  display: block;
  flex: 1;
  overflow: ${(props) => (props.hideBodyOverflow ? 'hidden' : 'auto')};
  position: relative;
`;

// import database & auth
// I need to support logging in grab auth solution for if-internal
// I need to pass down who the user is to AdminPage
// login component
// meeting code entry component (should be shared)

export const PageContainerBasic = styled('div')`
  display: flex;
  flex: 1 0 auto;
  width: 100%;
  background: ${(props) =>
    props.background ? props.background : 'transparent'};
  margin: auto;
  height: ${(props) => (props.height ? props.height : 'auto')};
  position: relative;
  justify-content: ${(props) => props.justifyContent || 'center'};
  transition: background 0.4s ease-out;
  flex-direction: ${(props) =>
    props.collapseMobile
      ? 'column'
      : props.flexDirection
      ? props.flexDirection
      : 'row'};
  //padding-top: 3rem;
  height: 100%;
  @media (min-width: 1024px) {
    padding-top: 0;
    flex-direction: ${(props) =>
      props.flexDirection ? props.flexDirection : 'row'};
    //justify-content: center;
  }
`;

export const PageContainer = styled(PageContainerBasic)`
  ${({ inEditMode, inMobileMode }) =>
    inEditMode
      ? ``
      : `
  padding-top: 0;
  @media (min-width: 1024px) {
    padding-top: 0;
  }
  `}
`;

export const Row = styled('div')`
  display: flex;
  flex: 1 0 auto;
  width: 100%;
  flex-direction: row;
`;

export const LeftColumn = styled('div')`
  background: ${(props) => (props.background ? props.background : 'white')};
  box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.1);
  z-index: 2;
  flex: 0 0 auto;
  overflow: auto;
  @media (min-width: 1024px) {
    flex: 0 0 345px;
  }
`;

export const LeftColumn2 = styled(LeftColumn)`
  position: relative;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  max-height: 50%;
  overflow: hidden;
  @media (min-width: 1024px) {
    max-height: none;
    flex: 0 0 490px;
  }
`;

export const RightColumn = styled('div')`
  background: ${(props) => (props.background ? props.background : '#F0F0F0')};
  padding: 0 1.25rem;
  flex: 1 1 auto;
  overflow: auto;
`;

export const LeftTitle = styled('h2')`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-family: 'Neue Haas Grotesk Display Std', sans-serif;
  font-size: 1.125rem;
  height: 4.375rem;
  padding: 0 3.125rem;
  margin: 0;
  color: #333333;
  position: relative;
  flex: 0 0 4.375rem;
`;

export const RightTitle = styled('h2')`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-family: 'Neue Haas Grotesk Display Std', sans-serif;
  font-size: 1.125rem;
  height: 4.375rem;
  margin: ${(props) => (props.margin ? props.margin : 0)};
  color: #333333;
  position: relative;
`;

export const DragHandle = styled('div')`
  width: 25px;
  height: 15px;
  background: url('${DragHandleImg}') center/auto no-repeat;
  opacity: 0;
`;

const LeftOptionOrig = styled(Link)`
  text-decoration: none;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: ${(props) => (props.active ? 'white' : 'black')};
  }
  cursor: pointer;
  background-color: ${(props) =>
    props.active
      ? '#1CA4FC'
      : props.backgroundColor
      ? props.backgroundColor
      : 'white'};
  color: ${(props) => (props.active ? 'white' : 'black')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.25rem 0 3.125rem;
  font-family: 'Neue Haas Grotesk Display Std', sans-serif;
  font-weight: normal;
  transition: color 0.1s, background-color 0.1s;
  &:hover {
    background-color: ${(props) => (props.active ? '#1CA4FC' : '#F2F2F2')};
    ${DragHandle} {
      opacity: 1;
    }
  }
  font-size: 0.875rem;
  height: 2.5rem;
  @media (min-width: 1024px) {
    font-size: 1.125rem;
    height: 4.375rem;
  }
`;

export const LeftOption = ({ children, showHandle, ...other }) => (
  <LeftOptionOrig {...other}>
    {children}
    {showHandle ? <DragHandle /> : null}
  </LeftOptionOrig>
);

export const Plus = styled('div')`
  background-image: url('${PlusImg}');
  background-position: center center;
  background-repeat: no-repeat;
  width: 1rem;
  height: 1rem;
  background-size: auto;
  margin-left: auto;
  right: 1.75rem;
  top: 1.75rem;
`;

export const Delete = styled(Plus)`
  flex: 0 0 auto;
  transform: rotate(45deg);
`;

export const Input = styled('input')`
  font-family: 'Neue Haas Grotesk Display Std', sans-serif;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '1rem')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 'lighter')};
  width: 100%;
  padding: 0.4375rem 0.625rem;
  box-shadow: inset -1px 1px 0.1875rem rgba(0, 0, 0, 0.15);
  -webkit-appearance: none;
  border: none;
  color: ${(props) =>
    props.disabled ? '#dadada' : props.color ? props.color : '#1ca4fc'};
  background-color: white;
  line-height: 1.125em;
  &::placeholder {
    color: #dadada;
  }
`;

const LabeledInputContainer = styled('div')`
  width: 100%;
`;

export const InputLabel = styled('div')`
  font-family: Neue Haas Grotesk Display Std;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #bdbdbd;
  margin-bottom: 3px;
`;

export const DetailShortInputContainer = styled('div')`
  flex: 0 1 48.5%;
  margin-bottom: 20px;
`;

export const LabeledInput = (props) => {
  const {
    placeholder: label,
    inputPlaceholder,
    disable,
    ...otherProps
  } = props;
  const inputProps = {
    placeholder: inputPlaceholder,
    disabled: disable,
    ...otherProps,
  };
  return (
    <LabeledInputContainer>
      <InputLabel>{label}</InputLabel>
      <Input color="initial" {...inputProps} />
    </LabeledInputContainer>
  );
};

export const Textarea = styled('textarea')`
  font-family: 'Neue Haas Grotesk Display Std', sans-serif;
  font-size: 1rem;
  font-weight: lighter;
  width: 100%;
  padding: 0.4375rem 0.625rem;
  box-shadow: inset -1px 1px 0.1875rem rgba(0, 0, 0, 0.15);
  -webkit-appearance: none;
  border: none;
  color: ${(props) => props.color || '#1ca4fc'};
  &::placeholder {
    color: #c4c4c4;
  }
  background: white;
  height: 3rem;
`;

const ExplanationTextarea = styled(Textarea)``;
export class Explanation extends React.Component {
  onInput = (e) => {
    const textarea = e.currentTarget;
    textarea.style.height = '';
    textarea.style.height = `${textarea.scrollHeight}px`;

    Tracking.event({
      name: surveyTakerEvents.MODULE_EXPLANATION_TEXT,
      properties: {
        moduleType: this.props.moduleType,
        moduleExplanation: textarea.value,
      },
    });
  };
  onBlur = (e) => {
    const textarea = e.currentTarget;
    textarea.style.height = '';
  };
  render() {
    return (
      <ExplanationTextarea
        {...this.props}
        onFocus={this.onInput}
        onInput={this.onInput}
        onBlur={this.onBlur}
      />
    );
  }
}

export const LabeledTextarea = (props) => {
  const { placeholder: label, inputPlaceholder, ...otherProps } = props;
  const inputProps = { placeholder: inputPlaceholder, ...otherProps };
  return (
    <LabeledInputContainer>
      <InputLabel>{label}</InputLabel>
      <Explanation color="initial" {...inputProps} />
    </LabeledInputContainer>
  );
};

export const QuestionDataContainer = styled('div')`
  max-width: 44.375rem;
  margin: ${(props) => (props.margin ? props.margin : '0 auto')};
  padding: 1rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

export const PresentationTableContainer = styled('div')`
  width: 100%;
  max-width: 44.375rem;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
`;

export const PresentationTableContainerTwoColumn = styled(
  PresentationTableContainer
)`
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
`;

export const PresentationHalfWidth = styled('div')`
  flex: 0 0 49%;
  margin: 0.5rem auto;
  width: 49%;
`;

export const PresentationTable = styled('table')`
  width: 100%;
  max-width: 44.375rem;
  margin-bottom: 1rem;
  table-layout: fixed;
`;

export const PresentationRow = styled('tr')``;

export const PresentationTitleRow = styled('tr')`
  font-weight: bold;
`;

export const PresentationCell = styled('td')`
  font-family: 'Neue Haas Grotesk Text Std', 'Neue Haas Grotesk Display Std',
    sans-serif;
  font-size: 0.75rem;
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};
  background-color: ${(props) =>
    props.background ? `${props.background}!important` : 'white'};
  padding: 0.5rem 0.625rem;
  background-color: white;
  color: ${(props) => (props.color ? props.color : '#333333')};
  width: ${(props) => (props.width ? props.width : 'auto')};
  overflow: auto;
  &:nth-child(even) {
    background-color: #f8f8f8;
  }
`;

export const PresentationBody = styled('tbody')`
  border-top: 1px solid black;
  border-bottom: 1px solid black;
`;

export const ChartContainer = styled('div')`
  background: white;
`;

export const Orange = '#FF9B3A';
export const Blue = '#1CA4FC';

export const SettingsOverlay = styled('div')`
  position: absolute;
  background: ${(props) =>
    props.background ? props.background : 'rgba(15, 43, 61, 0.63)'};
  width: 100%;
  min-height: 100%;
  pointer-events: ${(props) => (props.active ? 'auto' : 'none')};
  opacity: ${(props) => (props.active ? 1 : 0)};
  z-index: ${(props) => (props.active ? 3 : -1)};
  top: 3.125rem;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  @media (min-width: 1024px) {
    top: 0;
    background: linear-gradient(
      to right,
      rgba(15, 43, 61, 0.63) 0%,
      rgba(15, 43, 61, 0.63) 49%,
      ${(props) => (props.rightColor ? props.rightColor : 'white')} 51%,
      ${(props) => (props.rightColor ? props.rightColor : 'white')} 100%
    );
  }
`;

export const ModalOverlay = styled(SettingsOverlay)`
  background: ${(props) =>
    props.background ? props.background : 'rgba(15, 43, 61, 0.63)'};
  @media (min-width: 1200px) {
    background: ${(props) =>
      props.background ? props.background : 'rgba(15, 43, 61, 0.63)'};
  }
`;

export const ModalBody = styled('div')`
  background: #333333;
  color: #ffffff;
  padding: 1rem;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: calc(100vh - 3rem);
  @media (min-width: 1024px) {
    margin: 0 auto;
    padding: 4rem 6.25rem;
    max-width: 56.25rem;
    min-height: calc(100vh - 4rem);
  }
`;

export const ModalClose = styled('div')`
  background-image: url('${CloseImg}');
  background-position: center center;
  background-repeat: no-repeat;
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  cursor: pointer;
`;

export const ModalTextarea = styled(Textarea)`
  font-family: 'Neue Haas Grotesk Display Std', sans-serif;
  font-weight: normal;
  font-size: 1.5rem;
  color: #1ca4fc;
  padding: 1.25rem;
  box-shadow: inset -1px 1px 0.1875rem rgba(0, 0, 0, 0.15);
  -webkit-appearance: none;
  line-height: 1.1;
  margin-top: auto;
  min-height: 7rem;
  margin-top: 2rem;
`;

export const SettingsTitle = styled('h1')`
  margin: 2rem 0;
  font-family: 'Neue Haas Grotesk Display Std', sans-serif;
  font-size: 3.75rem;
  font-weight: bold;
`;

export const Center = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  pointer-events: none;
  position: absolute;
  opacity: ${(props) => (props.opacity ? props.opacity : 0)};
  transition: opacity 0.1s;
  flex-wrap: wrap;
`;

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: '',
      currentTab: '',
    };
  }
  updateCode = (e) => {
    this.setState({
      code: e.target.value || '',
    });
  };
  render() {
    const {
      background,
      children,
      hideBodyOverflow,
      inEditMode,
      ...other
    } = this.props;
    return (
      <Background background={background}>
        <Helmet titleTemplate="Survey Tool - %s" defaultTitle="Survey Tool" />
        {inEditMode ? null : <Header {...other} />}
        <Body {...{ hideBodyOverflow }}>{children}</Body>
      </Background>
    );
  }
}

export default withRouter(Layout);

export { Button, Button2, fadeIn, fadeOut };

export const isEmailValid = (emailValue) => {
  return emailValue && emailValue.match(/^\S+@\S+$/);
};

export const LargeButton2 = styled(Button2)`
  font-family: Neue Haas Grotesk Display Std;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  height: 40px;
`;

export const A = styled('a')`
  text-decoration: none;
  color: #333;
  &:hover,
  &:visited {
    text-decoration: none;
    color: #333;
  }
`;
