import React from 'react';
import { Formik } from 'formik';
import styled from 'styled-components';
import {
  COMPARISON,
  EXPLANATION,
  MADLIB,
  MATRIX,
  MULTI_SELECT,
  ADD_OPTION,
  OPEN_ENDED,
  PERSONAL_INFO,
  PLOTTER_22,
  PLOTTER_XY,
  POINT_DISTRIBUTION,
  SPECTRUM_DISTRIBUTION,
  STACK_RANK,
  TEXT_HIGHLIGHT,
} from '../../const/module-types';
import SurveyTitle from '../SurveyTitle';
import Plotter from './Plotter';
import StackRank from './StackRank';
import PointDistribution from './PointDistribution';
import TextHighlightSurvey from './TextHighlight';
import SpectrumDistribution from './SpectrumDistribution';
import Matrix from './Matrix';
import PersonalInfo from './PersonalInfo';
import Madlib from './Madlib';
import Comparison from './Comparison';
import OpenEnded from './OpenEnded';
import MultiSelect from './MultiSelect';
import AddOptions from './AddOptions/AddOptions';
import SurveyExplanationInput from './SurveyExplanationInput';

const PlotterContainer = styled('div')`
  width: 100%;
  margin: auto;
  max-width: 44.375rem;
  cursor: pointer;
  height: 100%;
`;

const getModuleComponentsMap = ({ formikProps, inMobileView, module }) => {
  const { moduleType, moduleId, questionData } = module;
  const commonProps = {
    moduleType,
    moduleId,
    questionData,
    inMobileView,
    ...formikProps,
  };
  return {
    [PLOTTER_XY]: () => (
      <PlotterContainer>
        <Plotter {...commonProps} />
      </PlotterContainer>
    ),
    [PLOTTER_22]: () => (
      <PlotterContainer>
        <Plotter {...commonProps} />
      </PlotterContainer>
    ),
    [STACK_RANK]: () => <StackRank {...commonProps} />,
    [POINT_DISTRIBUTION]: () => <PointDistribution {...commonProps} />,
    [TEXT_HIGHLIGHT]: () => <TextHighlightSurvey {...commonProps} />,
    [SPECTRUM_DISTRIBUTION]: () => <SpectrumDistribution {...commonProps} />,
    [MATRIX]: () => <Matrix {...commonProps} />,
    [PERSONAL_INFO]: () => <PersonalInfo {...commonProps} />,
    [MADLIB]: () => <Madlib {...commonProps} />,
    [OPEN_ENDED]: () => (
      <OpenEnded
        module={module}
        inMobileView={inMobileView}
        controls={() => null}
        {...formikProps}
      />
    ),
    [MULTI_SELECT]: () => (
      <MultiSelect
        questionData={questionData}
        module={module}
        controls={() => null}
        {...formikProps}
      />
    ),
    [ADD_OPTION]: () => (
      <AddOptions
        moduleId={moduleId}
        questionData={questionData}
        inPresentationMode={false}
        module={module}
        formikProps={formikProps}
        inMobileView={inMobileView}
        controls={() => null}
        {...formikProps}
      />
    ),
  };
};

// TODO: adapt this to normal survey. currently only adapted to workshop survey.
export default function SurveyComponents({
  inMobileView,
  module = {},
  initialSurveyData = {},
  children,
}) {
  const showExplanation = !!module?.questionData?.[EXPLANATION] 
    && module.moduleType !== MULTI_SELECT; // prettier-ignore

  return (
    <Formik
      initialValues={initialSurveyData}
      onSubmit={() => {}}
      render={(formikProps) => {
        return children({
          renderExplanationInput: () =>
            showExplanation ? (
              <SurveyExplanationInput
                moduleId={module.moduleId}
                inMobileView={inMobileView}
                {...formikProps}
              />
            ) : null,
          renderCurrentModule:
            getModuleComponentsMap({
              formikProps,
              inMobileView,
              module,
            })[module?.moduleType] || (() => null),
          renderTitle: ({ renderWaitingRoomInfoIcon } = {}) => (
            <SurveyTitle
              moduleType={module.moduleType}
              orderWithOutTextSlides={module.displayOrder}
              question={module.question}
              description={module.description}
              inMobileView={inMobileView}
              toggleTitleCollapsed={() => null}
              titleCollapsed={false}
              renderWaitingRoomInfoIcon={renderWaitingRoomInfoIcon}
            />
          ),
          formikProps,
        });
      }}
    />
  );
}
