import React, { Component } from 'react';
import styled from 'styled-components';
import { TEXT_SLIDE, WAITING_ROOM } from '../../const/module-types';

import ArrowCircle1 from './arrow_down_circle.svg';
import ArrowCircle2 from './arrow_down_circle2.svg';

const QuestionXofY = styled('div')`
  font-family: 'Neue Haas Grotesk Display Std', sans-serif;
  font-size: 0.75rem;
  font-weight: bold;
  margin: 0 0 0.25rem 0;
  display: inline-block;
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
`;

export const Question = styled('h2')`
  font-family: 'Neue Haas Grotesk Display Std', sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1;
  margin: 0 0 0.25em 0;
  overflow-x: auto;
  overflow-y: hidden;
  ${({ inMobileView, disableMinHeight }) =>
    inMobileView
      ? ``
      : `
  @media (min-width: 1199px) {
    font-size: ${disableMinHeight ? '2rem' : '4rem'};
    min-height: 4rem;
  }
  `}
`;

export const Description = styled('div')`
  color: #1ca4fc;
  font-family: 'Neue Haas Grotesk Display Std', sans-serif;
  font-size: 1.5rem;
  font-weight: normal;
  margin: ${({ margin }) => margin || `0 0 1.75rem 0`};
  ${({ disableMinHeight, inMobileView }) =>
    disableMinHeight
      ? `
  margin-bottom: 0.5rem;
  font-size: 1rem;
  `
      : inMobileView
      ? ``
      : `
  @media (min-width: 1199px) {
    min-height: 8.5rem;
  }
  `}
`;

const ResponseCount = styled(QuestionXofY)`
  color: #1ca4fc;
  margin: 0 0 0.25rem 0.75rem;
`;

export const QuestionContainer = styled('div')`
  width: 100%;
  color: ${(props) => (props.color ? props.color : '#333333')};
  transition: color 0.5s;
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
  &[data-module-type="${TEXT_SLIDE}"] {
    padding: 0 1rem;
    overflow: auto;
    height: 85vh;
  }
  &:not([data-module-type="${TEXT_SLIDE}"]) {
    margin-top: 0;
    top: 0;
    left: 0;
    height: ${({ titleCollapsed }) => (titleCollapsed ? '2.5rem' : 'auto')};
    box-shadow: 0 5px 6px rgba(0,0,0,0.2);
    background-color: #f2f2f2;
    overflow: hidden;
    z-index: 100;
    ${QuestionXofY} {
      padding: 0.75rem;
    }
    ${Question}, ${Description} {
      padding: 0 0.75rem;
    }
  }
  ${({ inMobileView, titleCollapsed }) =>
    inMobileView
      ? ``
      : `
  &[data-module-type="${TEXT_SLIDE}"] {
    @media (min-width: 1200px) {
      padding: 0;
      overflow: revert;
      height: auto;
    }
  }
  &:not([data-module-type="${TEXT_SLIDE}"]) {
    @media (min-width: 1200px) {
      height: auto;
      box-shadow: none;
      background-color: transparent;
      overflow: initial;
      ${QuestionXofY} {
        padding: 0;
      }
      ${Question}, ${Description} {
        padding: 0;
      }
    }
  }
  @media (min-width: 1200px) {
    margin-top: 0;
    padding: 0;
    position: static;
  }
  `}
`;

const MobileExpand = styled('div')`
  display: block;
  height: 2.5rem;
  background-image: url('${ArrowCircle1}');
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  ${({ inMobileView }) =>
    inMobileView
      ? ``
      : `
  @media (min-width: 1200px) {
    display: none;
  }
  `}
`;
const MobileCollapse = styled('div')`
  display: block;
  height: 2.5rem;
  background-image: url('${ArrowCircle2}');
  background-repeat: no-repeat;
  background-position: center;
  ${({ inMobileView }) =>
    inMobileView
      ? ``
      : `
  @media (min-width: 1200px) {
    display: none;
  }
  `}
`;

const InfoIcon = ({ onClick, isOpen }) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
    style={{ cursor: 'pointer' }}
  >
    <circle cx="20" cy="20" r="20" fill={isOpen ? '#FF7B3C' : '#1CA4FC'} />
    <path
      d="M22.2779 29.7145H17.5625V14.6196H22.2779V29.7145ZM17.2835 10.7273C17.2835 10.0577 17.5253 9.50897 18.0089 9.08114C18.4926 8.65331 19.1203 8.4394 19.8923 8.4394C20.6642 8.4394 21.292 8.65331 21.7757 9.08114C22.2593 9.50897 22.5011 10.0577 22.5011 10.7273C22.5011 11.397 22.2593 11.9457 21.7757 12.3736C21.292 12.8014 20.6642 13.0153 19.8923 13.0153C19.1203 13.0153 18.4926 12.8014 18.0089 12.3736C17.5253 11.9457 17.2835 11.397 17.2835 10.7273Z"
      fill="white"
    />
  </svg>
);

const QuestionDetails = styled('div')`
  display: flex;
  justify-content: space-between;
`;

const MobileExpandCollapse = ({ titleCollapsed, inMobileView }) =>
  titleCollapsed ? (
    <MobileExpand {...{ inMobileView }} />
  ) : (
    <MobileCollapse {...{ inMobileView }} />
  );

export default class SurveyTitle extends Component {
  render() {
    const {
      moduleType,
      orderWithOutTextSlides,
      question,
      description,
      responseCount,
      inPresentationMode,
      titleCollapsed,
      toggleTitleCollapsed,
      notFixed,
      disableMinHeight,
      inEditMode,
      inMobileView,
      onClickInfoIcon,
      inFacilitationMode = false,
      waitingRoomResponsesPanelToggle = false,
      className,
      renderWaitingRoomInfoIcon,
    } = this.props;
    const color = moduleType === TEXT_SLIDE ? 'white' : '#333333';
    const questionOrder = (
      <QuestionDetails>
        <QuestionXofY visible={moduleType !== TEXT_SLIDE} {...{ inMobileView }}>
          Question {orderWithOutTextSlides}
        </QuestionXofY>
        {/* TODO: rework this when adapting new survey */}
        {renderWaitingRoomInfoIcon
          ? renderWaitingRoomInfoIcon()
          : moduleType === WAITING_ROOM &&
            inFacilitationMode && (
              <InfoIcon
                onClick={onClickInfoIcon}
                isOpen={waitingRoomResponsesPanelToggle}
              />
            )}
      </QuestionDetails>
    );
    const respCountString = `${responseCount} Response${
      responseCount !== 1 ? 's' : ''
    }`;
    const respCount =
      inPresentationMode && moduleType !== TEXT_SLIDE ? (
        <ResponseCount>{respCountString}</ResponseCount>
      ) : null;
    const desc =
      description &&
      description
        .split('')
        .map((char, i) => (char === '\n' ? <br key={`desc${i}`} /> : char));
    return (
      <QuestionContainer
        color={color}
        data-module-type={moduleType}
        onClick={toggleTitleCollapsed}
        titleCollapsed={titleCollapsed}
        css={className}
        {...{ notFixed, inEditMode, inMobileView }}
      >
        {questionOrder}
        {respCount}
        <Question {...{ disableMinHeight, inMobileView }}>{question}</Question>
        <Description {...{ disableMinHeight, inMobileView }}>
          {desc}
        </Description>
        <MobileExpandCollapse {...{ titleCollapsed, inMobileView }} />
      </QuestionContainer>
    );
  }
}
