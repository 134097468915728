import React from 'react';
import styled from 'styled-components';

const Container = styled('div')`
  color: #868c97;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-size: ${(props) => props.fontSize || '10'}px;
  font-weight: 500;
  height: 100%;
  width: 100%;
`;

const DataContainer = styled('div')`
  border: 1px solid #868c97;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 8px 5px 10px 10px;
`;

const getHeight = (numberOfMembers) => {
  const defaultHeight = 65;
  const arbitraryNumber = 20;
  if (!numberOfMembers) return defaultHeight;
  return numberOfMembers * arbitraryNumber + defaultHeight;
};

const getXOffset = (
  x,
  defaultChartWidth,
  chartRightPadding,
  extraPadding,
  isSpectrum = false,
  isPlotter22 = false,
  isStackRank = false
) => {
  let offset =
    x > defaultChartWidth
      ? x - defaultChartWidth + (chartRightPadding + extraPadding)
      : -2;
  if (isSpectrum) {
    const possibleLastXChartValue = 400;
    offset = x < possibleLastXChartValue ? 0 : 112;
  }
  if (isPlotter22) return 3;
  if (isStackRank) return 3;
  return offset;
};

const getYOffset = (isSpectrum, datum, isPlotter22) => {
  const DEFAULT_OFFSET = 30;
  const PLOTTER22_OFFSET = 60;
  if (isSpectrum) {
    if (datum.frequency >= 2) return 30;
    return 20;
  }
  if (isPlotter22) {
    return PLOTTER22_OFFSET;
  }
  return DEFAULT_OFFSET;
};

const getWidth = (isPlotter22) => (isPlotter22 ? '150' : '112');

const sortMembersByNameLength = (a, b) => b.length - a.length;

const HTMLFlyOut = ({
  x,
  y,
  datum,
  chartRightPadding,
  dx,
  dy,
  flyOutLabelType = 'Point',
  render,
  chartWidth,
  isSpectrum = false,
  fontSize,
  isPlotter22,
  isStackRank,
}) => {
  const defaultChartWidth = chartWidth || 400;
  const extraPadding = 65;
  const xOffset = getXOffset(
    x,
    defaultChartWidth,
    chartRightPadding,
    extraPadding,
    isSpectrum,
    isPlotter22,
    isStackRank
  );

  const yOffset = getYOffset(isSpectrum, datum, isPlotter22, isStackRank);
  const numberOfMembers = datum.members && datum.members.length;
  const members = (datum.members ? datum.members : []).join(', ');
  return (
    <g style={{ pointerEvents: 'none' }}>
      <foreignObject
        x={x - xOffset}
        y={y - yOffset}
        dx={dx}
        dy={dy}
        width={getWidth(isPlotter22)}
        height={getHeight(numberOfMembers)}
      >
        <Container xmlns="http://www.w3.org/1999/xhtml" fontSize={fontSize}>
          <DataContainer>
            {render(datum, flyOutLabelType, numberOfMembers, members)}
          </DataContainer>
        </Container>
      </foreignObject>
    </g>
  );
};

export default HTMLFlyOut;
