import React, { useMemo } from 'react';
import styled from 'styled-components';
import Chart from '@nhuson/react-d3-cloud';
import { motion } from 'framer-motion';
import { NoResponse } from '../common';
import isPropsEqual from 'src/utils/isPropsEqual';

const Container = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const fontSizeMapper = (word) => {
  const value = word.value * 10;
  return Math.log2(value) * 5;
};

const shouldRender = (prevProps, nextProps) => {
  return isPropsEqual(prevProps.data, nextProps.data);
};

const CloudChart = React.memo(({ data }) => {
  return (
    <Chart
      data={JSON.parse(JSON.stringify(data))}
      fontSizeMapper={fontSizeMapper}
      padding={10}
      defaultColor="#1CA4FC"
      spiral="archimedean"
      font="Roboto"
    />
  );
}, shouldRender);

const WordCloud = React.memo(({ data, moduleId }) => {
  const memoizedData = useMemo(() => data, [data]);

  return (
    <Container
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      reanimate={false}
      transition={{
        type: 'spring',
        stiffness: 260,
        damping: 20,
      }}
    >
      <CloudChart data={memoizedData} />
      <NoResponse data={memoizedData} />
    </Container>
  );
});

export default WordCloud;
