import React from "react";

const AveragePoint = ({ x, y }) => (
  <svg
    width="35"
    height="35"
    x={x}
    y={y}
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="17.5" cy="17.5" r="16.5" stroke="#3FAFFA" strokeWidth="2" />
    <path
      d="M20.8774 21.3613H13.4312L11.7588 26H9.3418L16.1289 8.22656H18.1797L24.979 26H22.5742L20.8774 21.3613ZM14.1392 19.4326H20.1816L17.1543 11.1196L14.1392 19.4326Z"
      fill="#3FAFFA"
    />
  </svg>
);

export default AveragePoint;
