import { ButtonBase } from "components/buttons";
import React, { useState } from "react";
import styled from "styled-components";
import {
  COMPARISON,
  MULTI_SELECT,
  OPEN_ENDED,
  WAITING_ROOM,
} from "../../const/module-types";
import SurveyComponents from "../survey-components";
import Comparison from "../survey-components/Comparison";
import { ReactComponent as InfoIcon } from "img/ic_info.svg";
import WaitingRoom from "components/survey-components/WaitingRoom";

const SurveyQuestionDataContainer = styled("div")`
  margin: 0;
  height: 100%;
  ${({ inMobileView }) =>
    inMobileView
      ? ``
      : `
  @media (min-width: 1200px) {
    margin: auto;
    width: 72.6875rem;
    display: flex;
    justify-content: center;
    height: 100%;
    display: table;
    table-layout: fixed;
  }
  `}
`;

const SurveyRow = styled("div")`
  height: 100%;
  ${({ inMobileView }) =>
    inMobileView
      ? ``
      : `
  @media (min-width: 1200px) {
    display: table-row;
  }
  `}
`;

const SurveyLeftColumn = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto 0;
  flex: 1 0 30.5rem;
  width: 30.5rem;
`;

const SurveyRightColumn = styled("div")`
  display: flex;
  overflow: auto;
  height: 100%;
  margin: 0 auto;
  width: 100%;
  padding: 2.5rem 0 3.25rem 0;
  ${({ inMobileView }) =>
    inMobileView
      ? ``
      : `
  @media (min-width: 1200px) {
    display: table-cell;
    vertical-align: middle;
    width: 612px;
    padding: 0;
  }
  `}
`;

const ComparisonContainer = styled("div")`
  background: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 0 20px;
  padding-top: 0px;
  width: 100%;
`;

function getWorkshopModulesMap({ moduleList, userResponses, module }) {
  return {
    [COMPARISON]: () => (
      <ComparisonContainer>
        <Comparison
          moduleList={moduleList}
          userResponses={userResponses}
          questionData={module.questionData}
        />
      </ComparisonContainer>
    ),
  };
}

export default function WorkshopSurvey({
  inMobileView,
  module = {},
  moduleList = [],
  userResponses = {},
  onClickWaitingRoomRefresh,
  renderControls = () => null,
}) {
  const [showWaitingRoom, setShowWaitingRoom] = useState(false);

  return (
    <SurveyComponents
      inMobileView={false}
      module={module}
      initialSurveyData={{}}
    >
      {({
        renderCurrentModule,
        renderExplanationInput,
        renderTitle,
        formikProps,
      }) => {
        const { moduleType } = module;

        if ([COMPARISON].includes(moduleType)) {
          const renderCurrentWorkshopModule = getWorkshopModulesMap({
            moduleList,
            module,
            userResponses,
          })[moduleType];
          return (
            <>
              <div css={{ background: "#FFF", padding: "3em 0 .125em 6em" }}>
                {renderControls()}
              </div>
              {renderCurrentWorkshopModule?.()}
            </>
          );
        }
        return [MULTI_SELECT, OPEN_ENDED].includes(moduleType) ? (
          <>
            <div css={{ background: "#FFF", padding: "3em 0 .125em 6em" }}>
              {renderControls()}
            </div>
            {renderCurrentModule()}
          </>
        ) : (
          <SurveyQuestionDataContainer
            data-module-type={moduleType}
            inMobileView={inMobileView}
          >
            <SurveyRow inMobileView={inMobileView}>
              <SurveyLeftColumn>
                <div css={{ margin: "3em 0" }}>{renderControls()}</div>
                {renderTitle({
                  renderWaitingRoomInfoIcon: () => {
                    return moduleType === WAITING_ROOM ? (
                      <ButtonBase
                        css={{ fill: showWaitingRoom ? "#FF7B3C" : "#1CA4FC" }}
                        onClick={() => setShowWaitingRoom(!showWaitingRoom)}
                      >
                        <InfoIcon />
                      </ButtonBase>
                    ) : null;
                  },
                })}
                {renderExplanationInput()}
              </SurveyLeftColumn>
              <SurveyRightColumn
                data-module-type={moduleType}
                inMobileView={inMobileView}
              >
                {showWaitingRoom && moduleType === WAITING_ROOM ? (
                  <WaitingRoom
                    moduleList={moduleList}
                    userResponses={userResponses}
                    onClickRefreshIcon={onClickWaitingRoomRefresh}
                    onClickCloseIcon={() => setShowWaitingRoom(false)}
                  />
                ) : (
                  renderCurrentModule()
                )}
              </SurveyRightColumn>
            </SurveyRow>
          </SurveyQuestionDataContainer>
        );
      }}
    </SurveyComponents>
  );
}
