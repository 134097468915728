import React from "react";
import styled from "styled-components";
import { Explanation } from "../layout";
import { EXPLANATION } from "../../const/module-types";

const ExplanationContainer = styled("div")`
  width: 100%;
  max-width: 21rem;
  margin: 0 auto 9px auto;
  ${({ inMobileView }) =>
    inMobileView
      ? ``
      : `
  @media (min-width: 1200px) {
    max-width: 100%;
  }
  `}
`;

export default function SurveyExplanationInput({
  inMobileView,
  handleChange,
  value,
  moduleId,
}) {
  const key = `explanation.${moduleId}.${EXPLANATION}`;
  return (
    <ExplanationContainer {...{ inMobileView }}>
      <Explanation
        id={key}
        placeholder="Please explain your answer..."
        onChange={handleChange}
        value={value || ""}
        rows="1"
      />
    </ExplanationContainer>
  );
}
