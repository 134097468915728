export const styles = {
  containerOverlay: {
    display: "flex",
    zIndex: 100,
    "> [data-reach-dialog-content]": {
      border: "5px solid #1CA4FC",
      borderRadius: "8px",
      margin: "auto",
      padding: "40px 50px",
      width: "max-content",
      display: "flex",
      flexDirection: "column",
      maxWidth: "550px",
    },
  },
  instructionText: {
    fontSize: "20px",
    color: "#1CA4FC",
    fontFamily: "Roboto",
    marginBottom: "40px",
  },
  optionsTitlesText: {
    color: "#FF7B3C",
    fontSize: "20px",
    fontFamily: "Roboto",
    fontWeight: 500,
    marginBottom: "60px",
    maxHeight: "400px",
    overflow: "auto",
  },
  cancelBtn: {
    background: "#FFF",
    boxShadow: "0px 1.24444px 4.97778px rgba(28, 164, 252, 0.3)",
    borderRadius: "62px",
    padding: "8px 40px",
    fontSize: "12px",
    color: "#1CA4FC",
    fontWeight: 500,
    fontFamily: "Roboto",
  },
  saveBtn: {
    background: "#FFF",
    boxShadow: "0px 1.24444px 4.97778px rgba(28, 164, 252, 0.3)",
    borderRadius: "62px",
    padding: "8px 40px",
    fontSize: "12px",
    color: "#1CA4FC",
    fontWeight: 500,
    fontFamily: "Roboto",
    marginLeft: "16px",
  },
  actionBtnsContainer: {
    margin: "0 auto",
  },
};
