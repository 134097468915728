import { Plus } from 'components/layout';
import styled from 'styled-components';
import Highlight from '../../../img/highlight.svg';
import HighlightWhite from '../../../img/highlightWhite.svg';

export const styles = {
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    position: 'relative',
  },
  topGrpBtn: {
    display: 'flex',
    alignContent: 'center',
    gap: '20px',
  },
  actionBtn: {
    marginLeft: '0',
    marginRight: 'auto',
    background: '#FFF',
    boxShadow: '0px 1.24444px 4.97778px rgba(28, 164, 252, 0.3)',
    borderRadius: '62px',
    padding: '6px 15px 6px 35px',
    fontSize: '14px',
    color: '#1CA4FC',
    fontWeight: 600,
    fontFamily: 'Roboto',
    '&:hover': {
      color: '#FFF',
      background: '#1CA4FC',
    },
  },
  sortBtn: {
    '&:hover': {
      color: '#1ca4fc',
      background: '#fff',
    },
  },
  sortIcon: {
    position: 'absolute',
    right: '80px',
    height: '20px',
  },
  options: {
    marginTop: '40px',
    justifyContent: 'center',
  },
  highOpt: {
    position: 'relative',
  },
  arrIcon: {
    position: 'absolute',
    top: '5px',
    left: '80px',
  },
  optionCheckboxChecked: {
    backgroundColor: '#1CA4FC',
    display: 'flex',
    flex: 1,
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export const LightIcon = styled(Plus)`
	position: absolute;
	left: 15px;
	margin-left: 0;
	top: 8px;
	height: 15px;
	width: 15px;
	background-image: url('${Highlight}');
`;

export const HighlightBtn = styled.button`
	position: relative;
	min-width: 120px;
	outline: 0;
	border: 0;
	background: transparent;
	padding: 0;
	&:hover {
		${LightIcon} {
			background-image: url('${HighlightWhite}');
		}
	}
`;

export const Masons = styled.div`
  background: #f1f2f5;
  padding: 10px 20px;
  font-weight: 500;
  color: ${(props) => (props.selected ? '#FFFFFF' : '#3faffa')};
  font-size: 1.25rem;
  border-radius: 12px;
  cursor: pointer;
  background: ${(props) => (props.selected ? '#FD9E46' : '#f1f2f5')};
`;

export const HighlightOptions = styled.div`
  position: absolute;
  right: 128px;
  top: 40px;
  display: flex;
  flex-direction: column;
  background: #f1f2f5;
  padding: 10px 57px 10px 15px;
  border-radius: 10px;
  z-index: 100;
`;

export const SubOptions = styled.div`
  position: absolute;
  top: 40px;
  display: flex;
  flex-direction: column;
  background: #f1f2f5;
  padding: 10px 20px 0px 15px;
  border-radius: 10px;
  z-index: 100;
`;
