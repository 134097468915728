import React, { Component } from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import { connect } from 'react-redux';
import {
  VictoryChart,
  VictoryStack,
  VictoryBar,
  VictoryContainer,
  VictoryAxis,
  VictoryLabel,
  Bar,
  Box,
  VictoryBoxPlot,
  LineSegment,
} from 'victory';
import {
  TOTAL_POINTS,
  POINT_DISTRIBUTION_EXPLANATION,
  POINTS,
  OPTION_PREFIX,
} from '../../../const/module-types';
import { getNestedData } from '../../../const/nested-data';
import { filterAndSortOptions } from '../../Builder/ModuleCard';
import { getCurrentPoints } from '../../../lib/survey-api';
import { CHART_WIDTH, CHART_HEIGHT, axisStyleXY } from '../Plotter';
import {
  PresentationTableContainer,
  PresentationTable,
  PresentationRow,
  PresentationCell,
  PresentationBody,
  ChartContainer,
  PresentationTitleRow,
  Explanation,
} from '../../layout';
import { isPopulatedOptions } from 'components/utils/helpers';

const PointDistributionContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem 4rem 1rem; // 4rem extra padding for explain
  overflow: auto;
  ${({ inMobileView }) =>
    inMobileView
      ? ``
      : `
  @media (min-width: 1200px) {
    padding: 0;
    margin: auto 0;
  }
  `}
`;

export const RemainingPointsContainer = styled('div')`
  text-align: left;
  order: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const RemainingPointsText = styled('div')`
  font-family: 'Neue Haas Grotesk Text Std', sans-serif;
  font-weight: bold;
  font-size: 0.75rem;
  color: #333333;
  text-align: left;
  order: 1;
`;

export const RemainingPoints = styled('div')`
  font-family: 'Neue Haas Grotesk Text Std', sans-serif;
  font-weight: normal;
  font-size: 1.5rem;
  color: ${(props) =>
    props.remainingPointsEqualsTotal ? '#000000' : '#FF9B3A'};
  line-height: 1;
  order: 0;
`;

const PointsAndExplanationContainer = styled('div')``;

const PointsAndExplanation = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const Points = styled('input')`
  font-family: 'Neue Haas Grotesk Display Std', sans-serif;
  font-weight: lighter;
  color: #1ca4fc;
  text-align: center;
  background: white;
  margin: 0 1.25rem;
  border: none;
  box-shadow: inset -1px 1px 0.1875rem rgba(0, 0, 0, 0.15);
  -webkit-appearance: none;
  &::placeholder {
    color: #dadada;
  }
  font-size: 1rem;
  height: 2.25rem;
  flex: 0 0 2.5rem;
  width: 2.5rem;
`;

const AnswerAndExplanation = styled('div')`
  margin: 0 0 1rem -1.25rem;
  display: flex;
  flex-direction: row;
  /* flex: 1 0 auto; */
`;

const Answer = styled('div')`
  font-family: 'Neue Haas Grotesk Display Std', sans-serif;
  font-weight: normal;
  color: #000000;
  width: 6.375rem;
  margin: 0 0 0.4375rem 0;
  /* background: white; */
  /* padding: 0 0.875rem; */
  display: block;
  width: 100%;
  /* box-shadow: inset -1px 1px 0.1875rem rgba(0,0,0,0.15); */
  font-size: 1.5rem;
  line-height: 1;
`;

export const getMedian = (array) => {
  if (array.length === 0) return 0;
  const arr = array.slice(0);
  const middle = (arr.length + 1) / 2;
  const sorted = arr.sort((a, b) => a - b);
  return sorted.length % 2
    ? sorted[middle - 1]
    : (sorted[middle - 1.5] + sorted[middle - 0.5]) / 2;
};

class PointDistribution extends Component {
  getRemainingEqualsTotal() {
    const { questionData, moduleId, values } = this.props;
    const { pointDistribution = {} } = values;
    const totalPoints = parseInt(questionData[TOTAL_POINTS], 10) || 0;
    const moduleResponse = pointDistribution[moduleId] || {};
    const currentPoints = getCurrentPoints(moduleResponse);
    return currentPoints === 0;
  }
  getRemainingPoints = (options = {}) => {
    const { skipKey } = options;
    const { questionData, moduleId, values } = this.props;
    const { pointDistribution = {} } = values;
    const totalPoints = parseInt(questionData[TOTAL_POINTS], 10) || 0;
    const moduleResponse = pointDistribution[moduleId] || {};
    const currentPoints = getCurrentPoints(moduleResponse, skipKey);
    return totalPoints - currentPoints;
  };
  handlePointChange = (e) => {
    const { props, getRemainingPoints } = this;
    const {
      inPresentationMode,
      values,
      setValues,
      setFieldValue,
      moduleId,
    } = props;
    if (inPresentationMode) return;
    const id = e.currentTarget.id;
    const value = e.currentTarget.value || '';
    const { key1, key2, skipKey } = e.currentTarget.dataset;
    const cleanedValue = value.replace(/[^\d]/g, '');
    const ivalue = cleanedValue ? parseInt(cleanedValue, 10) : 0;
    const toppedValue = Math.min(ivalue, getRemainingPoints({ skipKey }));
    /*
    const newValues = {
      ...values,
      pointDistribution: {
        ...values.pointDistribution,
        [key1]: {
          ...(get(values, `pointDistribtion.${key1}`) || {}),
          [key2]: {
            ...(get(values, `pointDistribtion.${key2}.${key2}`) || {}),
            [POINTS]: toppedValue
          }
        }
      }
    };
    setValues(newValues);
    console.log({ values, id, toppedValue, newValues });
    */
    setFieldValue(id, toppedValue);
  };
  renderPointDistributionAnswers() {
    const { props, handlePointChange } = this;
    const {
      questionData,
      moduleId,
      values,
      handleBlur,
      hilo: { isParticipantMode },
    } = props;
    const populatePlaceHolder = {
      option1: 'To be populated',
      option2: 'To be populated',
      option3: 'To be populated',
    };
    const items = filterAndSortOptions(
      questionData?.populateOptions &&
        !isParticipantMode &&
        isPopulatedOptions(Object.keys(questionData)) === false
        ? populatePlaceHolder
        : questionData
    );
    return items.map(([key, pointDistributionText]) => {
      const key2 = `pointDistribution.${moduleId}.${key}`;
      const pointsKey = `${key2}.${POINTS}`;
      const explanationKey = `${key2}.${POINT_DISTRIBUTION_EXPLANATION}`;
      const points = getNestedData(values, pointsKey);
      const explanation = getNestedData(values, explanationKey);
      // todo: allow no explanation for point distribution (percentage bars)
      const showExplanation = questionData[POINT_DISTRIBUTION_EXPLANATION];
      return (
        <PointsAndExplanation key={key}>
          <Answer>{pointDistributionText}</Answer>
          <AnswerAndExplanation>
            <Points
              key={pointsKey}
              id={pointsKey}
              name={pointsKey}
              data-skip-key={key}
              data-key1={moduleId}
              data-key2={key}
              onChange={handlePointChange}
              onBlur={handleBlur}
              value={points !== void 0 ? points : ''}
              type="tel"
              placeholder="#"
            />
            {showExplanation ? (
              <Explanation
                key={explanationKey}
                id={explanationKey}
                name={explanationKey}
                onChange={this.props.handleChange}
                onBlur={this.props.handleBlur}
                value={explanation || ''}
                placeholder="Please explain your answer..."
                rows={1}
                color="#1CA4FC"
              />
            ) : null}
          </AnswerAndExplanation>
        </PointsAndExplanation>
      );
    });
  }
  renderSurvey() {
    const { questionData, inMobileView } = this.props;
    if (!questionData) return;
    return (
      <PointDistributionContainer {...{ inMobileView }}>
        <RemainingPointsContainer>
          <RemainingPointsText>Remaining Points</RemainingPointsText>
          <RemainingPoints
            remainingPointsEqualsTotal={this.getRemainingEqualsTotal()}
          >
            {this.getRemainingPoints()}
          </RemainingPoints>
        </RemainingPointsContainer>
        <PointsAndExplanationContainer>
          {this.renderPointDistributionAnswers()}
        </PointsAndExplanationContainer>
      </PointDistributionContainer>
    );
  }
  getPresentationData() {
    const { moduleId, questionData } = this.props;
    const userResponses =
      getNestedData(this.props, 'values.userResponses') || {};
    const responsesPerOption = filterAndSortOptions(questionData).reduce(
      (collector, [key, pointDistributionText]) => {
        const userResponsesForOption = Object.entries(userResponses).reduce(
          (collector2, [userId, responseData]) => {
            const key2 = `answerData.${moduleId}.${key}`;
            const pointsKey = `${key2}.${POINTS}`;
            const explanationKey = `${key2}.${POINT_DISTRIBUTION_EXPLANATION}`;
            const points = getNestedData(responseData, pointsKey);
            const explanation = getNestedData(responseData, explanationKey);
            const infoHash = {
              [POINTS]: points,
              [POINT_DISTRIBUTION_EXPLANATION]: explanation,
            };
            return {
              ...collector2,
              [userId]: infoHash,
            };
          },
          {}
        );
        return {
          ...collector,
          [key]: {
            ...collector[key],
            ...userResponsesForOption,
          },
        };
      },
      {}
    );
    const presentationData = Object.entries(responsesPerOption).reduce(
      (collector, [optionKey, userResponsesHash]) => {
        const userResponseArray = Object.values(userResponsesHash).map(
          (infoHash) => infoHash[POINTS] || 0
        );
        const average =
          userResponseArray.reduce((av, points) => {
            return av + points;
          }, 0) / (userResponseArray.length || 1);
        const median = getMedian(userResponseArray);
        const minimum =
          userResponseArray.reduce((min, points) => {
            return min === void 0 || points < min ? points : min;
          }, void 0) || 0;
        const maximum =
          userResponseArray.reduce((min, points) => {
            return min === void 0 || points > min ? points : min;
          }, void 0) || 0;
        const range = maximum - minimum;
        // https://www.mathsisfun.com/data/standard-deviation-formulas.html
        const sdStep2 = userResponseArray.map((points) => {
          const step2 = Math.pow(points - average, 2);
          return step2;
        });
        const sdStep3 = sdStep2.reduce((sd3, step2) => {
          return sd3 + step2;
        }, 0);
        const sdStep4 = sdStep3 / (sdStep2.length || 1);
        const sd = Math.pow(sdStep4, 0.5);
        const optionText = questionData[optionKey];
        const presentationHash = {
          average,
          median,
          minimum,
          maximum,
          range,
          sd,
          optionText,
          userResponseArray,
        };
        return {
          ...collector,
          [optionKey]: presentationHash,
        };
      },
      {}
    );
    const presentationWithRank = Object.entries(presentationData)
      .sort(([aKey, a], [bKey, b]) => {
        return b.average - a.average;
      })
      .map(([optionKey, presentationHash], i) => {
        const optionInt = parseInt(optionKey.replace(OPTION_PREFIX, ''), 10);
        return {
          ...presentationHash,
          optionKey,
          optionInt,
          rank: i + 1,
        };
      });
    return presentationWithRank;
  }
  getBarWidth({ boxPlotData }) {
    const width = 350 / boxPlotData.length;
    return width;
  }
  renderRankCharts() {
    const presentationData = this.getPresentationData();
    const smallestMinimum = presentationData.reduce((min, pHash) => {
      return min === void 0 || pHash.minimum < min ? pHash.minimum : min;
    }, void 0);
    const biggestMaximum = presentationData.reduce((max, pHash) => {
      return max === void 0 || pHash.maximum > max ? pHash.maximum : max;
    }, void 0);
    const domain = {
      x: [0, presentationData.length],
      y: [smallestMinimum - 1, biggestMaximum + 1],
    };
    const ticksBetween = new Array(biggestMaximum - smallestMinimum + 1)
      .fill()
      .map((_, i) => smallestMinimum + i)
      .filter((x) => x % 5 === 0);
    const tickValues = [smallestMinimum, ...ticksBetween, biggestMaximum];
    const boxPlotData = presentationData.map((pHash) => {
      return { x: pHash.optionText, y: pHash.userResponseArray, ...pHash };
    });
    const optionTickValues = presentationData.map((pHash) => {
      return pHash.optionText;
    });
    const boxWidth = this.getBarWidth({ boxPlotData });
    return (
      <ChartContainer>
        <VictoryChart domain={domain} width={CHART_WIDTH} height={CHART_HEIGHT}>
          <VictoryAxis
            dependentAxis
            label="Points"
            axisLabelComponent={
              <VictoryLabel key="yLabel" x={10} y={30} angle={0} />
            }
            style={axisStyleXY}
            tickValues={tickValues}
          />
          <VictoryAxis
            label="Option"
            axisLabelComponent={<VictoryLabel key="xLabel" x={10} y={405} />}
            style={axisStyleXY}
            tickValues={optionTickValues}
          />
          <VictoryBoxPlot
            boxWidth={boxWidth}
            data={boxPlotData}
            sortKey="rank"
            q1Component={<Box style={{ fill: '#1CA4FC' }} />}
            medianComponent={
              <LineSegment style={{ stroke: '#FF9B3A', strokeWidth: 3 }} />
            }
            q3Component={<Box style={{ fill: '#1CA4FC' }} />}
          />
        </VictoryChart>
      </ChartContainer>
    );
  }
  renderPresentation() {
    const { inPresentationMode } = this.props;
    if (!inPresentationMode) return null;
    const presentationData = this.getPresentationData();
    const body = presentationData.map((pHash) => {
      return (
        <PresentationRow key={`row${pHash.optionKey}`}>
          <PresentationCell>{pHash.optionText}</PresentationCell>
          <PresentationCell textAlign="center">
            {pHash.average.toFixed(2)}
          </PresentationCell>
          <PresentationCell textAlign="center">
            {pHash.median.toFixed(2)}
          </PresentationCell>
          <PresentationCell textAlign="center">
            {pHash.minimum}
          </PresentationCell>
          <PresentationCell textAlign="center">
            {pHash.maximum}
          </PresentationCell>
          <PresentationCell textAlign="center">{pHash.range}</PresentationCell>
          <PresentationCell textAlign="center">
            {pHash.sd.toFixed(2)}
          </PresentationCell>
        </PresentationRow>
      );
    });
    return (
      <PresentationTableContainer>
        <PresentationTable>
          <thead>
            <PresentationTitleRow>
              <PresentationCell background="transparent" width="5.9375rem" />
              <PresentationCell background="transparent" textAlign="center">
                Mean
              </PresentationCell>
              <PresentationCell background="transparent" textAlign="center">
                Median
              </PresentationCell>
              <PresentationCell background="transparent" textAlign="center">
                Minimum
              </PresentationCell>
              <PresentationCell background="transparent" textAlign="center">
                Maximum
              </PresentationCell>
              <PresentationCell background="transparent" textAlign="center">
                Range
              </PresentationCell>
              <PresentationCell background="transparent" textAlign="center">
                Std. Dev
              </PresentationCell>
            </PresentationTitleRow>
          </thead>
          <PresentationBody>{body}</PresentationBody>
        </PresentationTable>
        {this.renderRankCharts()}
      </PresentationTableContainer>
    );
  }
  render() {
    return this.props.inPresentationMode
      ? this.renderPresentation()
      : this.renderSurvey();
  }
}

const mapStateToProps = (state) => ({
  hilo: state.hilo,
});

const ConnectedPointDistribution = connect(mapStateToProps)(PointDistribution);

export default ConnectedPointDistribution;
