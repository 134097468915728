import React, { useState } from 'react';
import {
  VictoryChart,
  VictoryAxis,
  VictoryScatter,
  VictoryLabel,
  VictoryTooltip,
  LineSegment,
} from 'victory';
import HTMLFlyOut from '../common/Tooltips/HTMLFlyOut';
import { InnerText } from '../common/Tooltips';
import { chartMouseEvents } from '../../../lib/Events';
import { colors } from '../../../const/theme';
import { isThereData } from '../../../lib/Presentation';
import NoData from '../common/NoData';
import { useDispatch, useSelector } from 'react-redux';
import {
  setClickedChartElementOptionKey,
  setClickedChartElementResponseIds,
  setCommentsPanelVisibility,
  setPanelComments,
  setSelectedParticipantResponseId,
  setSpectrumSelectedPoint,
} from 'pages/presentationSlice';
import { HILOS } from 'const/index';

import { Circle } from './Circle';
import { splitString } from 'components/utils/helpers';

const CHART_WIDTH = 450;
const CHART_HEIGHT = 200;
const DEFAULT_PADDING = 50;

const axisLabel = {
  fontSize: 8,
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 400,
};

const ScatterPoint = ({
  x,
  y,
  size,
  datum,
  moduleId,
  onClickBubble,
  selectedParticipantResponseId,
}) => {
  const [selected, setSelected] = useState(false);
  const [hovered, setHovered] = useState(false);

  const selectedPoint = useSelector(
    (state) => state.presentation[moduleId].selectedPoint
  );

  const onClick = () => {
    onClickBubble(datum);
    setSelected(!selected);
  };

  return (
    <>
      <Circle
        cx={x}
        cy={y}
        r={size}
        stroke={hovered ? colors.STROKE_CHART_COLOR : colors.STROKE_CHART_COLOR}
        strokeWidth={1}
        fill={
          datum.x === selectedPoint ||
          datum.users.includes(selectedParticipantResponseId)
            ? colors.SELECTED_CHART_FILL_COLOR
            : colors.PRIMARY_CHART_FILL_COLOR
        }
        onClick={() => {
          onClick();
        }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      />
    </>
  );
};

const SpectrumDistribution = ({ moduleId, mode, comparisonSection }) => {
  const dispatch = useDispatch();

  const data = useSelector(
    (state) => state.presentation[moduleId].chartData.data
  );

  const domain = useSelector(
    (state) => state.presentation[moduleId].chartData.domain
  );

  const selectedParticipantResponseId = useSelector((state) => {
    if (comparisonSection && mode === HILOS.WORKSHOP) {
      return state.workshop.comparison[comparisonSection]
        .selectedParticipantResponseId;
    } else {
      return state.presentation.selectedParticipantResponseId;
    }
  });

  const labels = useSelector(
    (state) => state.presentation[moduleId].chartData.labels
  );

  const comments = useSelector((state) => state.presentation.comments);

  const numberOfDivisions = Number(labels.numberOfDivisions);

  const onClickedBubble = (datum) => {
    const responseIds = datum.users;
    // Set this to null so that the highlight on View selection
    // In the comment section does not conflict with the bubble click.
    dispatch(setSelectedParticipantResponseId(null));
    dispatch(
      setSpectrumSelectedPoint({
        moduleId,
        selectedPoint: datum.x,
      })
    );
    dispatch(setClickedChartElementOptionKey(datum.x));
    dispatch(setClickedChartElementResponseIds(responseIds));
    dispatch(setCommentsPanelVisibility(true));
    dispatch(setPanelComments(comments));
  };

  return (
    <>
      <h2 style={{ marginLeft: '70px', marginBottom: '-90px' }}>
        {labels.spectrumText}
      </h2>
      {isThereData(data) && (
        <VictoryChart
          domain={{ x: [domain.min, numberOfDivisions] }}
          domainPadding={{ y: [0, 10] }}
          height={CHART_HEIGHT}
          width={CHART_WIDTH}
        >
          <VictoryAxis
            crossAxis={false}
            tickFormat={() => ''}
            style={{
              ticks: {
                stroke: colors.STROKE_CHART_COLOR,
                size: -5,
                angle: 90,
              },
            }}
          />
          <LineSegment
            x1={50}
            x2={400}
            y1={105}
            y2={105}
            style={{
              strokeDasharray: '2 4',
            }}
          />
          <VictoryScatter
            data={data}
            events={[chartMouseEvents(false, null, onClickedBubble)]}
            alignment="middle"
            bubbleProperty="frequency"
            maxBubbleSize={20}
            minBubbleSize={5}
            style={{
              data: {
                fill: colors.PRIMARY_CHART_FILL_COLOR,
                stroke: colors.STROKE_CHART_COLOR,
                strokeWidth: 1,
              },
            }}
            dataComponent={
              <ScatterPoint
                moduleId={moduleId}
                onClickBubble={onClickedBubble}
                selectedParticipantResponseId={selectedParticipantResponseId}
              />
            }
            labels={() => ''}
            labelComponent={
              <VictoryTooltip
                flyoutComponent={
                  <HTMLFlyOut
                    chartRightPadding={0}
                    flyOutLabelType=""
                    isSpectrum
                    chartWidth={CHART_WIDTH}
                    render={(datum, flyOutLabel, numberOfMembers, members) => (
                      <InnerText
                        datum={datum}
                        flyOutLabelType={flyOutLabel}
                        numberOfMembers={numberOfMembers}
                        members={members}
                      />
                    )}
                  />
                }
              />
            }
          />
          <VictoryLabel
            text={splitString(labels.leftEndpoint, 15)}
            x={DEFAULT_PADDING - 10}
            y={CHART_HEIGHT - 35}
            style={{ ...axisLabel }}
            renderInPortal
          />
          <VictoryLabel
            text={splitString(labels.midpoint, 15)}
            textAnchor="middle"
            x={CHART_WIDTH / 2 - 10}
            y={CHART_HEIGHT - 35}
            style={{ ...axisLabel }}
            renderInPortal
          />
          <VictoryLabel
            text={splitString(labels.rightEndpoint, 15)}
            textAnchor="middle"
            x={CHART_WIDTH - (DEFAULT_PADDING + 10)}
            y={CHART_HEIGHT - 35}
            style={{ ...axisLabel }}
            renderInPortal
          />
        </VictoryChart>
      )}

      {!isThereData(data) && <NoData />}
    </>
  );
};

export default SpectrumDistribution;
