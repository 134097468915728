import mixpanel from 'mixpanel-browser';
import { HILOS } from 'const/index';
import { generalEvents, surveyBuilderEvents } from 'lib/tracking/events';
import { MODULE_TYPES } from 'const/module-types';
import { isEmpty } from 'lodash';

class Tracking {
  static identifyUser({ userId, userAttributes = {} }) {
    mixpanel.identify(userId);
    if (userAttributes && Object.values(userAttributes).length > 0) {
      if (isEmpty(userAttributes.$email)) {
        const email = `${userId}@if.com`;
        mixpanel.people.set({ ...userAttributes, $email: email });
      } else {
        mixpanel.people.set(userAttributes);
      }
    }
  }

  static getDistinctId() {
    return mixpanel.get_distinct_id();
  }

  static event({ name, properties = {} }) {
    mixpanel.track(name, properties);
  }

  static hiloCreation({ hilo, properties }) {
    const validHilos = [HILOS.SURVEY, HILOS.WORKSHOP, HILOS.TEMPLATE];
    if (!validHilos.includes(hilo)) {
      console.log('[!] Hilo is not valid');
      return;
    }
    if (hilo === HILOS.SURVEY) {
      Tracking.event({ name: generalEvents.CREATE_SURVEY, properties });
    }
    if (hilo === HILOS.WORKSHOP) {
      Tracking.event({ name: generalEvents.CREATE_WORKSHOP, properties });
    }
    if (hilo === HILOS.TEMPLATE) {
      Tracking.event({ name: generalEvents.CREATE_TEMPLATE, properties });
    }
  }

  static hiloCancellation({ hilo }) {
    if (hilo === HILOS.SURVEY) {
      Tracking.event({ name: generalEvents.CANCEL_SURVEY_CREATION });
    }
    if (hilo === HILOS.WORKSHOP) {
      Tracking.event({ name: generalEvents.CANCEL_WORKSHOP_CREATION });
    }
  }

  static builderModuleAddition({ moduleType, properties }) {
    if (!MODULE_TYPES.includes(moduleType)) {
      console.log('[!] Module type is not supported');
      return;
    }
    Tracking.event({
      name: surveyBuilderEvents[moduleType],
      properties,
    });
  }

  static launchOrCloseSurvey({ hilo, properties }) {
    if (hilo.type === HILOS.SURVEY) {
      if (properties.closed) {
        Tracking.event({ name: surveyBuilderEvents.CLOSED_SURVEY, properties });
      } else {
        Tracking.event({
          name: surveyBuilderEvents.LAUNCHED_SURVEY,
          properties,
        });
      }
    }

    if (hilo.type === HILOS.WORKSHOP) {
      if (properties.closed) {
        Tracking.event({
          name: surveyBuilderEvents.CLOSED_WORKSHOP,
          properties,
        });
      } else {
        Tracking.event({
          name: surveyBuilderEvents.LAUNCHED_WORKSHOP,
          properties,
        });
      }
    }
  }
}

export default Tracking;
