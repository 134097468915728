import React from "react";
import styled from "styled-components";
import { ReactComponent as ArrowLeftFilled } from "img/arrow_filled_left.svg";
import { ReactComponent as ArrowRightFilled } from "img/arrow_filled_right.svg";
import SelectMenu from "./SelectMenu";
import { ButtonBase } from "./buttons";

const Container = styled.div`
  display: flex;
  align-items: flex-end;
`;

const styles = {
  leftQuestionSwitcherBtn: (disabled) => ({
    fill: disabled ? "#C4C4C4" : "#1CA4FC",
    marginRight: "10px",
    "&:hover": {
      fill: disabled ? "#C4C4C4" : "#FF9736",
    },
  }),
  rightQuestionSwitcherBtn: (disabled) => ({
    fill: disabled ? "#C4C4C4" : "#1CA4FC",
    marginLeft: "10px",
    "&:hover": {
      fill: disabled ? "#C4C4C4" : "#FF9736",
    },
  }),
};

export default function Switcher({
  leftSwitchIsDisabled,
  onClickLeftSwitch,
  items,
  selectedItem,
  onSelectItem,
  itemSelectionDisabled,
  itemRender,
  rightSwitchIsDisabled,
  onClickRightSwitch,
  className,
  renderLeftSwitchBtn = null,
  renderRightSwitchBtn = null,
}) {
  return (
    <Container css={className}>
      {renderLeftSwitchBtn ? (
        renderLeftSwitchBtn({
          defaultIconRender: () => <ArrowLeftFilled />,
          defaultStyle: styles.leftQuestionSwitcherBtn(leftSwitchIsDisabled),
        })
      ) : (
        <ButtonBase
          disabled={leftSwitchIsDisabled}
          css={styles.leftQuestionSwitcherBtn(leftSwitchIsDisabled)}
          onClick={() => {
            if (leftSwitchIsDisabled) return;
            onClickLeftSwitch();
          }}
        >
          <ArrowLeftFilled />
        </ButtonBase>
      )}
      <SelectMenu
        items={items}
        selectedItem={selectedItem}
        onSelectItem={onSelectItem}
        itemRender={itemRender}
        itemSelectionDisabled={itemSelectionDisabled}
      />
      {renderRightSwitchBtn ? (
        renderRightSwitchBtn({
          defaultIconRender: () => <ArrowRightFilled />,
          defaultStyle: styles.rightQuestionSwitcherBtn(rightSwitchIsDisabled),
        })
      ) : (
        <ButtonBase
          disabled={rightSwitchIsDisabled}
          css={styles.rightQuestionSwitcherBtn(rightSwitchIsDisabled)}
          onClick={() => {
            if (rightSwitchIsDisabled) return;
            onClickRightSwitch();
          }}
        >
          <ArrowRightFilled />
        </ButtonBase>
      )}
    </Container>
  );
}
