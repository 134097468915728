import { createSlice } from '@reduxjs/toolkit';

// All the chart data/presentation data is stored in the presentationSlice under
// a moduleId.
export const workshopSlice = createSlice({
  name: 'workshop',
  initialState: {
    comparison: {
      sectionOne: {
        activeQuestion: {
          question: '',
          metaData: {},
          moduleType: '',
          moduleId: '',
        },
        comments: [],
        selectedParticipantResponseId: null,
      },
      sectionTwo: {
        activeQuestion: {
          question: '',
          metaData: {},
          moduleType: '',
          moduleId: '',
        },
        comments: [],
        selectedParticipantResponseId: null,
      },
    },
  },
  reducers: {
    setComparisonSectionOneActiveQuestion: (state, action) => {
      state.comparison.sectionOne.activeQuestion = action.payload;
    },
    setComparisonSectionTwoActiveQuestion: (state, action) => {
      state.comparison.sectionTwo.activeQuestion = action.payload;
    },
    setComparisonSectionOneComments: (state, action) => {
      state.comparison.sectionOne.comments = action.payload;
    },
    setComparisonSectionTwoComments: (state, action) => {
      state.comparison.sectionTwo.comments = action.payload;
    },
    setComparisonSectionOneSelectedParticipantResponseId: (state, action) => {
      state.comparison.sectionOne.selectedParticipantResponseId =
        action.payload;
    },
    setComparisonSectionTwoSelectedParticipantResponseId: (state, action) => {
      state.comparison.sectionTwo.selectedParticipantResponseId =
        action.payload;
    },
  },
});

export const {
  setComparisonSectionOneSelectedParticipantResponseId,
  setComparisonSectionTwoSelectedParticipantResponseId,
  setComparisonSectionOneActiveQuestion,
  setComparisonSectionTwoActiveQuestion,
  setComparisonSectionOneComments,
  setComparisonSectionTwoComments,
} = workshopSlice.actions;

export default workshopSlice.reducer;
