import {
  ADD_OPTION,
  MULTI_SELECT,
  POINT_DISTRIBUTION,
  STACK_RANK,
} from 'const/module-types';
import {
  getQuestionOptions,
  getQuestionOptionsForModuleFormatting,
} from 'lib/Presentation/Helper';
import { sortModules } from 'lib/builder-api';

function getMultiSelectFormattedOptions(questionData) {
  const options = getQuestionOptionsForModuleFormatting(questionData);

  return options.reduce((acc, currentValue, index) => {
    return {
      ...acc,
      [`option${index}`]: {
        key: `option${index}`,
        title: currentValue,
      },
    };
  }, {});
}

/**
 * Get stack rank options formatted from multi-select module
 * e.g
 * {
      option0: value,
      option1: value,
      option2: 'value,
      ...
    }
 * @param multiSelectModule
 * @returns {*[]|*}
 */
const getStackRankOptionsFromMultiSelect = ({ multiSelectModule }) => {
  if (!multiSelectModule || multiSelectModule.moduleType !== MULTI_SELECT) {
    throw new Error('Multi select module is missing');
  }

  const questionData = multiSelectModule.questionData;

  if (!questionData || Object.keys(questionData).length === 0) {
    return {};
  }

  const options = getQuestionOptionsForModuleFormatting(questionData);

  const nonDeletedOptions = options.filter((option) => !option.removed);

  return nonDeletedOptions.reduce((acc, currentValue, index) => {
    return {
      ...acc,
      [`option${index}`]: currentValue.title,
    };
  }, {});
};

/**
 * {
      option0: value,
      option1: value,
      option2: 'value,
      ...
    }
 * @param pointDistributionModule
 * @returns {{}|*}
 */
const getStackRankOptionsFromPointDistribution = ({
  pointDistributionModule,
}) => {
  if (
    !pointDistributionModule ||
    pointDistributionModule.moduleType !== POINT_DISTRIBUTION
  ) {
    throw new Error('Point distribution module is missing');
  }

  const questionData = pointDistributionModule.questionData;

  if (!questionData || Object.keys(questionData).length === 0) {
    return {};
  }

  const options = getQuestionOptionsForModuleFormatting(questionData);

  return options.reduce((acc, currentValue, index) => {
    return {
      ...acc,
      [`option${index}`]: currentValue,
    };
  }, {});
};

/**
 * Sample
 * {
      option0: { key: 'option0', title: value },
      option1: { key: 'option1', title: value },
      option2: { key: 'option2', title: value },
      option3: { key: 'option3', title: value },
      ...
    }
 * @param stackRankModule
 * @returns {{}|*}
 */
const getMultiSelectOptionsFromStackRank = ({ stackRankModule }) => {
  if (!stackRankModule || stackRankModule.moduleType !== STACK_RANK) {
    throw new Error('Stack rank module is missing');
  }

  const questionData = stackRankModule.questionData;

  if (!questionData || Object.keys(questionData).length === 0) {
    return {};
  }

  const options = getQuestionOptionsForModuleFormatting(questionData);
  return getMultiSelectFormattedOptions(options);
};

/**
 * Sample
 * {
      option0: { key: 'option0', title: value },
      option1: { key: 'option1', title: value },
      option2: { key: 'option2', title: value },
      option3: { key: 'option3', title: value },
      ...
    }
 * @param pointDistributionModule
 * @returns {{}|*}
 */
const getMultiSelectOptionsFromPointDistribution = ({
  pointDistributionModule,
}) => {
  if (
    !pointDistributionModule ||
    pointDistributionModule.moduleType !== POINT_DISTRIBUTION
  ) {
    throw new Error('Point distributionModule module is missing');
  }

  const questionData = pointDistributionModule.questionData;

  if (!questionData || Object.keys(questionData).length === 0) {
    return {};
  }

  return getMultiSelectFormattedOptions(questionData);
};

/**
 * Sample
 * {
      option0: value,
      option1: value,
      option2: 'value,
      ...
    }
 * @param stackRankModule
 * @returns {{}|*}
 */
const getPointDistributionOptionsFromStackRank = ({ stackRankModule }) => {
  if (!stackRankModule || stackRankModule.moduleType !== STACK_RANK) {
    throw new Error('Stack rank Module module is missing');
  }

  const questionData = stackRankModule.questionData;

  if (!questionData || Object.keys(questionData).length === 0) {
    return {};
  }

  const options = getQuestionOptionsForModuleFormatting(questionData);

  return options.reduce((acc, currentValue, index) => {
    return {
      ...acc,
      [`option${index}`]: currentValue,
    };
  }, {});
};

const defaultGetOptionsFromAnotherModule = (optionsMap) => ({
  [POINT_DISTRIBUTION]: optionsMap,
  [STACK_RANK]: optionsMap,
  [ADD_OPTION]: Object.entries(optionsMap).reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key]: value.title,
    };
  }, {}),
  [MULTI_SELECT]: Object.entries(optionsMap)
    .reduce((acc, [key, value]) => value.removed
      ? acc
      : { ...acc, [key]: value.title, },
      {}) // prettier-ignore
});

const defaultGetMultiSelectFromAnotherModule = (optionsMap) =>
  Object.entries(optionsMap)
    .reduce((acc, [key, value]) => ({
      ...acc,
      [key]: { key: key, title: value,},
    }), {}); // prettier-ignore

/**
 *
 * @param module The module that is populate-able e.g Stack rank, Multi-select
 * and point distribution.
 * @param masterModule The module from which we are getting the options to add
 * to the populate-able module.
 * module and master module can be the same module e.g stack rank -> stack rank
 * @returns {*}
 */
export const getModuleOptionsFromAnotherModule = (module, masterModule) => {
  const optionsMap = getQuestionOptions(masterModule.questionData ?? {})
    .reduce((acc, option) => {
      const [key, value] = Object.entries(option)[0] ?? [];
      return { ...acc, [key]: value };
    }, {}); // prettier-ignore
  const map = {
    // module map
    [MULTI_SELECT]: () => ({
      [POINT_DISTRIBUTION]: defaultGetMultiSelectFromAnotherModule(optionsMap),
      [STACK_RANK]: defaultGetMultiSelectFromAnotherModule(optionsMap),
      [MULTI_SELECT]: Object.entries(optionsMap)
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value, }), {}), // prettier-ignore
      [ADD_OPTION]: Object.entries(optionsMap).reduce((acc, [key, value]) => {
        return {
          ...acc,
          [key]: { key, title: value.title },
        };
      }, {}),
    }),
    [STACK_RANK]: () => ({
      [POINT_DISTRIBUTION]: optionsMap,
      [STACK_RANK]: optionsMap,
      [ADD_OPTION]: Object.entries(optionsMap).reduce((acc, [key, value]) => {
        return {
          ...acc,
          [key]: value.title,
        };
      }, {}),
      [MULTI_SELECT]: Object.entries(optionsMap)
        .reduce((acc, [key, value]) => value.removed
            ? acc
            : { ...acc, [key]: value.title, },
          {}) // prettier-ignore
    }),
    [POINT_DISTRIBUTION]: () => defaultGetOptionsFromAnotherModule(optionsMap),
  };
  return map[module.moduleType]?.()?.[masterModule.moduleType] ?? {};
};

export const filterModuleOptionsByKeys = (options = {}, optionKeys = []) => {
  return Object.entries(options)
    .filter(([key]) => optionKeys.includes(key))
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}); // prettier-ignore
};

const isHoveredOverModulePopulated = ({ hoveredModule }) => {
  return (
    hoveredModule.questionData?.populateOptions &&
    hoveredModule.questionData?.populateModule
  );
};

const isHoveredOverModulePopulatedByDraggedModule = ({
  draggedModule,
  hoveredModule,
}) => {
  const draggedModuleId = draggedModule.moduleId;
  const hoveredOverPopulatedModuleId =
    hoveredModule.questionData?.populateModule?.moduleId;
  return draggedModuleId === hoveredOverPopulatedModuleId;
};

/**
 * Get all other modules that reference the module being dragged.
 * Remove the hovered over module since that case is taken care
 * of by case 4 below in @showBreakingAlertDialogOnModuleDrag
 * @param draggedModule
 * @param hoveredModule
 * @param moduleList
 * @returns {unknown[]}
 */
const getModulesPopulatedByDraggedModule = ({
  draggedModule,
  hoveredModule,
  moduleList,
}) => {
  return Object.values(moduleList)
    .filter(
      (module) =>
        module.questionData?.populateModule?.moduleId === draggedModule.moduleId
    )
    .filter((module) => module.moduleId !== hoveredModule.moduleId);
};

const getModulesPopulatedByDeletedModule = ({ moduleId, moduleList }) => {
  return Object.values(moduleList).filter(
    (module) => module.questionData?.populateModule?.moduleId === moduleId
  );
};

/**
 * Find the modules which will break when a dragged module is dragged.
 * We get this by checking the order value on the modules.
 * @param draggedModule
 * @param hoveredModule
 * @param moduleList
 * @returns {boolean|*}
 */
const modulesBrokenByDraggedModule = ({
  draggedModule,
  hoveredModule,
  moduleList,
}) => {
  const modulesPopulatedByDraggedModule = getModulesPopulatedByDraggedModule({
    draggedModule,
    moduleList,
    hoveredModule,
  });
  if (
    !modulesPopulatedByDraggedModule ||
    modulesPopulatedByDraggedModule.length === 0
  )
    return [];
  const draggedModuleOrder = draggedModule.order;
  const hoveredModuleOrder = hoveredModule.order;
  return modulesPopulatedByDraggedModule.reduce((acc, currentModule) => {
    if (draggedModuleOrder > currentModule.order) {
      return [
        ...acc,
        {
          displayOrder: currentModule.displayOrder,
          name: currentModule.question || 'Untitled module',
          updateModuleId: currentModule.moduleId,
        },
      ];
    }
    if (currentModule.order > hoveredModuleOrder) return acc;

    if (
      currentModule.order > draggedModuleOrder &&
      currentModule.order !== hoveredModuleOrder
    ) {
      // case 2
      return [
        ...acc,
        {
          displayOrder: currentModule.displayOrder,
          name: currentModule.question || 'Untitled module',
          updateModuleId: currentModule.moduleId,
        },
      ];
    } else {
      // Case 2.B
      return acc;
    }
  }, []);
};

/**
 * Check if dragged module is populated
 * @param draggedModule
 * @returns {boolean|*|null}
 */
function isDraggedModulePopulated({ draggedModule }) {
  return (
    draggedModule.questionData?.populateOptions &&
    draggedModule.questionData?.populateModule
  );
}

/**
 * Check the populate module of the dragged module.
 * If the populate module order is higher than the
 * hovered module. Break the populate option of
 * the dragged module. e.g 1,2,3, 3 populate
 * from 2, if 3 is moved to 1, break 2 away from 3.
 * @param draggedModule
 * @param hoveredModule
 * @param moduleList
 * @returns {boolean}
 */
const isDraggedModuleBreaking = ({
  draggedModule,
  hoveredModule,
  moduleList,
}) => {
  const populateModule = draggedModule.questionData?.populateModule;
  if (moduleList[populateModule?.moduleId]?.order > hoveredModule.order)
    return true;
  return false;
};

/**
 * Is the hovered module the same as the populate module of the dragged module
 * @param draggedModule
 * @param hoveredModule
 * @returns {boolean}
 */
function draggedPopulatedModuleSameAsHoveredModule({
  draggedModule,
  hoveredModule,
}) {
  return (
    draggedModule.questionData?.populateModule?.moduleId ===
    hoveredModule.moduleId
  );
}

export const getBreakingAlert = ({
  draggedModule,
  hoveredModule,
  moduleList,
}) => {
  let breakingAlerts = [];
  if (
    isHoveredOverModulePopulated({ hoveredModule }) &&
    isHoveredOverModulePopulatedByDraggedModule({
      draggedModule,
      hoveredModule,
    })
  ) {
    breakingAlerts = [
      ...breakingAlerts,
      {
        displayOrder: hoveredModule.displayOrder,
        name: hoveredModule.question || 'Untitled module',
        updateModuleId: hoveredModule.moduleId,
      },
    ];
  }

  const modulesBrokenByDrag = modulesBrokenByDraggedModule({
    draggedModule,
    hoveredModule,
    moduleList,
  });
  const isDraggedModuleBreakingOtherModules = modulesBrokenByDrag.length > 0;

  if (isDraggedModuleBreakingOtherModules) {
    breakingAlerts = [...breakingAlerts, ...modulesBrokenByDrag];
  }

  if (
    isDraggedModulePopulated({ draggedModule }) &&
    (isDraggedModuleBreaking({ draggedModule, hoveredModule, moduleList }) ||
      draggedPopulatedModuleSameAsHoveredModule({
        draggedModule,
        hoveredModule,
      }))
  ) {
    breakingAlerts = [
      ...breakingAlerts,
      {
        displayOrder: draggedModule.displayOrder,
        name: draggedModule.question || 'Untitled module',
        updateModuleId: draggedModule.moduleId,
      },
    ];
  }

  return breakingAlerts;
};

export const getDeleteModuleBreakingAlert = ({ moduleId, moduleList }) => {
  const brokenModules = getModulesPopulatedByDeletedModule({
    moduleId,
    moduleList,
  });
  if (brokenModules.length > 0) {
    return brokenModules.map((mod) => ({
      displayOrder: mod.displayOrder,
      name: mod.question || 'Untitled module',
      updateModuleId: mod.moduleId,
    }));
  }
  return [];
};

/**
 * If the current module being looped is the same as the dragged module
 * update its order so that it is not overwritten constructing the new
 * modulelist.
 * @param currentModuleId
 * @param draggedModuleId
 * @param newOrder
 * @returns {{}|{order}}
 */
const getOrder = ({ currentModuleId, draggedModuleId, newOrder }) => {
  if (currentModuleId === draggedModuleId) {
    return { order: newOrder };
  }
  return {};
};

/**
 * Get the new sorted module list.
 * @param draggedModule
 * @param hoveredModule
 * @param modulePopulateAlert
 * @param moduleList
 * @returns {*}
 */
export function getNewSortedModuleList(
  draggedModule,
  hoveredModule,
  modulePopulateAlert,
  moduleList
) {
  // the card we are moving
  const dragOrder = draggedModule.order;
  const dragModuleId = draggedModule.moduleId;
  // the card we are dropping it on
  const hoverOrder = hoveredModule.order;

  const newOrder = hoverOrder < dragOrder ? hoverOrder - 0.5 : hoverOrder + 0.5;

  let modulesToUpdate = modulePopulateAlert.map(
    (alert) => alert.updateModuleId
  );

  // Construct a modules object
  modulesToUpdate = modulesToUpdate.reduce((acc, currentModuleId) => {
    return {
      ...acc,
      [currentModuleId]: {
        ...moduleList[currentModuleId],
        ...getOrder({
          currentModuleId,
          draggedModuleId: dragModuleId,
          newOrder,
        }),
        questionData: {
          ...(moduleList[currentModuleId].questionData || {}),
          populateOptions: false,
          populateModule: null,
        },
      },
    };
  }, {});

  const newModuleList = {
    ...moduleList,
    [dragModuleId]: {
      ...draggedModule,
      order: newOrder,
    },
    ...modulesToUpdate,
  };

  return sortModules(newModuleList);
}

/**
 * Remove the populate option from the modules to be updated and
 * return the new modulelist.
 * @param moduleList
 * @param modulePopulateAlert
 * @returns {*}
 */
export const removePopulateOption = ({ moduleList, modulePopulateAlert }) => {
  let modulesToUpdate = modulePopulateAlert.map(
    (alert) => alert.updateModuleId
  );

  // Construct a modules object
  modulesToUpdate = modulesToUpdate.reduce((acc, currentModuleId) => {
    return {
      ...acc,
      [currentModuleId]: {
        ...moduleList[currentModuleId],
        questionData: {
          ...(moduleList[currentModuleId].questionData || {}),
          populateOptions: false,
          populateModule: null,
        },
      },
    };
  }, {});

  return {
    ...moduleList,
    ...modulesToUpdate,
  };
};
