import React, {useRef, useState} from "react";
import styled from "styled-components";

import {Button, Button2, DragHandle, LabeledInput, LabeledTextarea, Row, SettingsOverlay,} from "../layout";
import {OPTION_PREFIX, PERSONAL_INFO} from "../../const/module-types";

import TextSlideImg from "../../img/text-slide.png";
import PlotterXYImg from "../../img/xy.png";
import Plotter22Img from "../../img/22.png";
import PointDistributionImg from "../../img/point-distribution.png";
import TextHighlightImg from "../../img/text-highlight.png";
import DefaultImg from "../../img/spectrum-distribution.png";
import StackRankImg from "../../img/sr.png";
import CloseImg from "../../img/close.png";
import SpectrumDistributionImg from "../../img/sd.png";
import MatrixImg from "../../img/mt.png";
import PlusImg from "../../img/plus3.svg";
import PlusWhiteImg from "../../img/plus-white.svg";
import BlueEyeImg from "../../img/blue-eye.svg";

import {useDrag, useDrop} from "react-dnd";
import SurveyModulesEdit from "./SurveyModulesEdit";

export const MODULE_CARD = "MODULE_CARD";

const ModulePadding = styled("div")`
  padding: 14px 40px;
  position: relative;
  @media (min-width: 1024px) {
    padding: 14px 18px;
  }
`;

const Order = styled("div")`
  cursor: pointer;
  font-family: Neue Haas Grotesk Display Std;
  font-style: normal;
  font-size: 42px;
  margin-top: 8px;
  font-weight: normal;
`;

const TypeImgs = {
  TEXT_SLIDE: TextSlideImg,
  PLOTTER_XY: PlotterXYImg,
  PLOTTER_22: Plotter22Img,
  POINT_DISTRIBUTION: PointDistributionImg,
  STACK_RANK: StackRankImg,
  TEXT_HIGHLIGHT: TextHighlightImg,
  SPECTRUM_DISTRIBUTION: SpectrumDistributionImg,
  MATRIX: MatrixImg,
  PERSONAL_INFO: DefaultImg,
  MADLIB: DefaultImg,
};

const TypeImg = styled("div")`
  cursor: pointer;
  width: 5.125rem;
  height: 3.25rem;
  background-position: left center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: ${(props) =>
    props.backgroundImage ? `url('${props.backgroundImage}')` : "none"};
  display: none;
`;

const OrderAndTypeContainer = styled("div")`
  display: flex;
  flex: auto;
  flex-direction: row;
  justify-content: space-between;
  flex: 0 0 64px;
  flex-direction: column;
`;

const DetailLong = styled(LabeledInput)`
  margin-bottom: 10px;
`;

export const DetailTextarea = styled(LabeledTextarea)`
  width: 100%;
`;

export const filterAndSortOptions = (hash, prefix = OPTION_PREFIX) => {
  return Object.entries(hash)
    .filter(([key]) => {
      return key.indexOf(prefix) === 0;
    })
    .sort(([aKey], [bKey]) => {
      const aOrder = parseInt(aKey.replace(prefix, ""), 10);
      const bOrder = parseInt(bKey.replace(prefix, ""), 10);
      return aOrder - bOrder;
    });
};

const Close = styled("div")`
  cursor: pointer;
  width: 1.375rem;
  height: 1.375rem;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('${CloseImg}');
  display: none;
`;

const MoveClosePreview = styled("div")`
  min-width: 90px;
  min-height: 66px;
  display: ${(props) => (props.isPersonalInfoModuleActive ? "none" : "flex")};
  flex-direction: column;
  margin-left: 8px;
`;

const EditDupeButton = styled(Button2)`
  border-color: #1ca4fc;
  color: #1ca4fc;
  background: white;
  display: none;
`;

const EyeImg = styled("img")`
  display: none;
  width: 22px;
  margin: 8px auto 0 auto;
`;

const DupeAndClose = ({
  moduleId,
  onDupeClick,
  onEditClick,
  isPersonalInfoModuleActive,
}) => (
  <MoveClosePreview isPersonalInfoModuleActive={isPersonalInfoModuleActive}>
    <EyeImg src={BlueEyeImg} />
    <EditDupeButton
      onClick={onEditClick}
      data-module-id={moduleId}
      margin="0 0 9px 0"
      hoverBold
    >
      Edit
    </EditDupeButton>
    <EditDupeButton onClick={onDupeClick} data-module-id={moduleId} hoverBold>
      Duplicate
    </EditDupeButton>
  </MoveClosePreview>
);

const ModuleDragHandle = styled(DragHandle)`
  height: 42px;
  display: none;
  opacity: 1;
  margin-top: 8px;
`;

const OrderAndType = ({ order, typeImg, onPresentationClick }) => (
  <OrderAndTypeContainer>
    <ModuleDragHandle />
    <Order>{`${order}. `}</Order>
    <TypeImg
      backgroundImage={typeImg}
      data-order={order}
      onClick={onPresentationClick}
    />
  </OrderAndTypeContainer>
);

const MoveModalOverlay = styled(SettingsOverlay)`
  top: 0;
  bottom: 0;
  background: rgba(15, 43, 61, 0.63);
`;

const MoveModalBody = styled("div")`
  position: absolute;
  width: 14rem;
  height: 16.875rem;
  background: #f2f2f2;
  top: 1.875rem;
  left: 6.25rem;
  padding: 0.625rem;
  box-shadow: 0.125rem 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
`;

const MoveModalClose = styled(Close)`
  position: absolute;
  right: 0.625rem;
  top: 0.625rem;
`;

const MoveModalTitle = styled("div")`
  color: #000000;
  font-family: "Neue Haas Grotesk Display Std", sans-serif;
  font-size: 2.25rem;
  font-weight: bold;
  margin-bottom: 1.125rem;
`;

const MoveModalButton = styled(Button)`
  font-weight: normal;
`;

export const CONTAINER_WIDTH = 380;

const InsertModuleContainer = styled("div")`
  display: ${(props) => (props.inEditMode ? "none" : "flex")};
  align-items: center;
  height: 28px;
  margin: -14px 18px -14px 80px;
  cursor: pointer;
  position: relative;
  z-index: 2;
`;

const InsertModuleLine = styled("div")`
  border-bottom: ${(props) =>
    props.active ? "2px solid #1CA4FC" : "1px dashed #000000"};
  flex: 1 0 auto;
`;

const InsertModulePlus = styled("div")`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  box-sizing: border-box;
  border: ${(props) => (props.active ? "none" : "1px dashed #000000")};
  flex: 0 0 28px;
  background: ${(props) =>
      props.active
        ? `#1CA4FC url('${PlusWhiteImg}')`
        : `white url('${PlusImg}')`}
    center/auto no-repeat;
`;

export const InsertModule = ({
  insertIndex,
  activeInsertIndex,
  onInsertClick,
  inEditMode,
}) => {
  const active = insertIndex === activeInsertIndex;
  return (
    <InsertModuleContainer
      inEditMode={inEditMode}
      onClick={() => onInsertClick(insertIndex)}
    >
      <InsertModuleLine active={active} />
      <InsertModulePlus active={active} />
    </InsertModuleContainer>
  );
};

const ModuleContainer = styled("div")`
  font-family: "Neue Haas Grotesk Display Std";
  font-weight: bold;
  color: black;
  line-height: 1;
  display: flex;
  flex-direction: column;
  transform: translate(0, 0);
  background: white;
  ${({ inEditMode, isEditing, isActive, editingModule, isOtherEditing }) => {
    if (inEditMode && isEditing)
      return `
      border: 2px solid #1CA4FC;
      box-shadow: 0px 0px 8px rgba(0, 175, 215, 0.5);
      position: relative;
      z-index: 110;
      ${Order} {
        color: #1CA4FC;
        font-weight: bold;
      };

    `;
    if (inEditMode && isActive)
      return `
      color: #1CA4FC;
      ${Order} {
        color: #1CA4FC;
        font-weight: bold;
      }
      ${EyeImg} {
        display: ${isActive ? "block" : "none"};
      }
      &:hover {
        ${ModulePadding} {
          background-color: #1CA4FC;
          color: white;
        }
        ${Order} {
          display: none;
        }
        ${ModuleDragHandle} {
          display: block;
        }
        ${EditDupeButton} {
          display: flex;
        }
        ${EyeImg} {
          display: none;
        }
      }
    `;
    if (inEditMode && (!editingModule || isEditing))
      return `
      cursor: pointer;
      &:hover {
        ${ModulePadding} {
          background-color: #1CA4FC;
          color: white;
        }
        ${Order} {
          display: none;
        }
        ${ModuleDragHandle} {
          display: block;
        }
        ${EditDupeButton} {
          display: flex;
        }
      }
    `;
    if (isOtherEditing) {
      return `
      opacity: 0.5;
      pointer-events: none;
      `;
    }
  }}
`;

const ModuleCard = ({
  order,
  onMoveClick,
  moduleType,
  question,
  questionData,
  moduleId,
  connectDragSource,
  onPreviewClick,
  onPresentationClick,
  onDupeClick,
  onInsertClick,
  activeInsertIndex,
  setActiveModule,
  activeModule,
  removeModule,
  inEditMode,
  moveCard,
  saveDraft,
  isSaveDraftEnabled,
  loadSurvey,
  formikProps,
  setEditingModule,
  editingModule,
  onModuleDrag,
  ...other
}) => {
  const ref = useRef(null);
  const [{ isDragging }, drag, preview] = useDrag({
    item: { id: moduleId, type: MODULE_CARD },
    end: (item, monitor) => {
      const result = monitor.getDropResult();
      if (!result) return;
      const { id: dragIndex } = item;
      const { id: hoverIndex } = result;
      if (dragIndex === hoverIndex) return;

      const { values } = formikProps;
      const moduleList = { ...values.moduleList };
      // the card we are moving
      const dragModule = moduleList[dragIndex];

      // the card we are dropping it on
      const hoverModule = moduleList[hoverIndex];

      onModuleDrag({ draggedModule: dragModule, hoveredModule: hoverModule, moduleList, dragIndex, hoverIndex })
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: MODULE_CARD,
    drop: () => ({ id: moduleId, type: MODULE_CARD }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  const isHoveringOver = canDrop && isOver;
  const [allowMove, setAllowMove] = useState(true);
  const [moveModalActive, setMoveModalActive] = useState(false);
  const [currentOrder, setCurrentOrder] = useState("");

  const showMoveModal = () => {
    setMoveModalActive(true);
    setCurrentOrder(order);
  };
  const hideMoveModal = () => {
    setMoveModalActive(false);
  };
  const moveClick = () => {
    onMoveClick && onMoveClick({ order, moduleId, currentOrder });
    hideMoveModal();
  };
  const setOrder = (e) => {
    const newOrder = e.currentTarget.value;
    setCurrentOrder(newOrder);
  };
  const onMouseEnter = () => {
    setAllowMove(false);
  };
  const onMouseLeave = () => {
    setAllowMove(true);
  };
  const typeImg = TypeImgs[moduleType];
  const isActive = activeModule === moduleId;
  const isEditing = editingModule === moduleId;
  const isOtherEditing = editingModule && editingModule !== moduleId;
  if (allowMove) {
    drag(drop(ref));
  }
  const isPersonalInfoModuleActive =
    isActive && isEditing && moduleType === PERSONAL_INFO;
  const onClickModuleContainer = () => {
    if (isOtherEditing) return null;
    const x = ref.current;
    // temporarily removing this as is not giving a desired behaviour
    // at this time. The effects would be investigated and this might
    // be reverted if required.
    // x && x.scrollIntoView({ behavior: "smooth", block: "start" });
    setActiveModule(moduleId);
  };
  const onEditClick = () => setEditingModule(moduleId);
  return (
    <>
      <ModuleContainer
        {...{
          inEditMode,
          isActive,
          isEditing,
          isDragging,
          isHoveringOver,
          editingModule,
          isOtherEditing,
        }}
        ref={preview}
        onClick={onClickModuleContainer}
      >
        <ModulePadding ref={ref}>
          <Row>
            <OrderAndType
              {...{
                order,
                moduleType,
                typeImg,
                onPresentationClick,
                onOrderClick: showMoveModal,
                removeModule,
              }}
            />
            <SurveyModulesEdit
              {...{
                ...other,
                question,
                questionData,
                moduleId,
                moduleType,
                onMouseEnter,
                onMouseLeave,
                inEditMode,
                isEditing,
                saveDraft,
                isSaveDraftEnabled,
                loadSurvey,
                formikProps,
                isPersonInfoModule: isPersonalInfoModuleActive,
                order
              }}
            />
            <DupeAndClose
              {...{
                moduleId,
                connectDragSource,
                onPreviewClick,
                order,
                onDupeClick,
                removeModule,
                onEditClick,
                isPersonalInfoModuleActive,
              }}
            />
          </Row>
          <MoveModalOverlay active={moveModalActive} onClick={hideMoveModal}>
            <MoveModalBody onClick={(e) => e.stopPropagation()}>
              <MoveModalClose onClick={hideMoveModal} />
              <MoveModalTitle>Move to Position:</MoveModalTitle>
              <DetailLong
                placeholder="Enter Number"
                value={currentOrder}
                onChange={setOrder}
                fontSize="3.75rem"
                fontWeight="bold"
              />
              <MoveModalButton onClick={moveClick}>Move Module</MoveModalButton>
            </MoveModalBody>
          </MoveModalOverlay>
        </ModulePadding>
      </ModuleContainer>
      <InsertModule
        insertIndex={order}
        {...{
          activeInsertIndex,
          onInsertClick,
          inEditMode,
        }}
      />
    </>
  );
};

export default ModuleCard;
