import React, { useState } from 'react';
import { ReactComponent as CommentIcon } from '../../../img/comment_icon_default.svg';
import { ReactComponent as CommentActiveIcon } from '../../../img/comment_icon_active.svg';
import { ButtonBase } from '../../buttons';
import Section from './Section';
import { COMPARISON_SECTIONS } from 'const/module-types';
import { useSelector } from 'react-redux';
import { HILOS } from 'const/index';
import {
  setComparisonSectionOneSelectedParticipantResponseId,
  setComparisonSectionTwoSelectedParticipantResponseId,
} from 'pages/workshopSlice';

const styles = {
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    minWidth: '1000px',
    maxWidth: '1500px',
    width: '100%',
    margin: '0 auto',
    overflowY: 'auto',
  },
  mainSectionsContainer: {
    height: '100%',
    display: 'flex',
    width: '100%',
    overflowY: 'auto',
  },
  explanationsBtn: () => ({
    margin: '1rem 30px 1rem auto',
  }),
};

export default function Comparison({
  moduleList,
  userResponses,
  questionData,
}) {
  const { module0, module1 } = questionData;
  const [showExplanations, setShowExplanations] = useState(false);
  const [expandedSection, setExpandedSection] = useState(null);

  const sectionOneActiveQuestion = useSelector(
    (state) => state.workshop.comparison.sectionOne.activeQuestion
  );
  const sectionTwoActiveQuestion = useSelector(
    (state) => state.workshop.comparison.sectionTwo.activeQuestion
  );

  return (
    <div css={styles.container}>
      <ButtonBase
        onClick={() => setShowExplanations(!showExplanations)}
        css={styles.explanationsBtn()}
      >
        {showExplanations ? <CommentActiveIcon /> : <CommentIcon />}
      </ButtonBase>
      <div css={styles.mainSectionsContainer}>
        <Section
          moduleList={moduleList}
          userResponses={userResponses}
          initialSelectedModuleId={module0}
          showExplanations={showExplanations}
          onToggleExpand={() => {
            setExpandedSection(expandedSection === 0 ? null : 0);
          }}
          singleMode={expandedSection === 0}
          visible={expandedSection !== 1}
          comparisonSection={COMPARISON_SECTIONS.ONE}
          activeQuestion={sectionOneActiveQuestion}
          mode={HILOS.WORKSHOP}
          setSelectedParticipantResponseId={
            setComparisonSectionOneSelectedParticipantResponseId
          }
        />
        <Section
          moduleList={moduleList}
          userResponses={userResponses}
          initialSelectedModuleId={module1}
          showExplanations={showExplanations}
          onToggleExpand={() => {
            setExpandedSection(expandedSection === 1 ? null : 1);
          }}
          singleMode={expandedSection === 1}
          visible={expandedSection !== 0}
          comparisonSection={COMPARISON_SECTIONS.TWO}
          activeQuestion={sectionTwoActiveQuestion}
          mode={HILOS.WORKSHOP}
          setSelectedParticipantResponseId={
            setComparisonSectionTwoSelectedParticipantResponseId
          }
        />
      </div>
    </div>
  );
}
