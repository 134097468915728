import ReactGA from "react-ga";
import {APP_IS_IN_TO_PROD, GOOGLE_ANALYTICS_TRACKING_CODE} from "../const";

export const initGoogleAnalytics = (options = { debug: false }) => {
  const isGAEnabled = !!GOOGLE_ANALYTICS_TRACKING_CODE && APP_IS_IN_TO_PROD;
  if (isGAEnabled) {
    ReactGA.initialize(GOOGLE_ANALYTICS_TRACKING_CODE, {
      ...options
    });
  }
  return isGAEnabled;
};
