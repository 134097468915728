import {
  DEFAULT_CHART_BUBBLE_RADIUS,
  OPTION_PREFIX,
} from '../../../const/module-types';
import {
  getArrayOfOptions,
  getFormattedDataWithFrequency,
  getNonPopulateQuestionOptions,
  getNumberOfParticipants,
  getQuestionOptions,
  getUserName,
  sortAscByAnotherArray,
} from '../Helper';

/**
 * Get the data per option and format it.
 * @param moduleResponse Response per respondent
 * @param name
 * @returns {function(...[*]=)}
 */
function getRespondentsFormattedData(moduleResponse, name, responseId) {
  return (collector, option) => {
    const entries = Object.entries(option);
    if (entries.length === 0) return collector;
    const [key, value] = entries[0];
    const { points, pointDistributionExplanation } = moduleResponse[key] || {};
    if (!points) return collector;
    return [
      ...collector,
      {
        option: value,
        points: points,
        explanation: pointDistributionExplanation || '',
        name,
        responseId,
        radius: DEFAULT_CHART_BUBBLE_RADIUS,
      },
    ];
  };
}

function getResponses(
  acc,
  nameLabel,
  mainExplanation,
  name,
  respondentData,
  responseId,
  commentTime
) {
  return {
    ...acc.responses,
    [responseId]: {
      explanation: mainExplanation,
      name,
      data: respondentData,
      time: commentTime,
    },
  };
}

function getParticipants(acc, name, nameLabel, respondentData) {
  return [
    ...acc.participants,
    { name, label: nameLabel, data: respondentData },
  ];
}

function getInitialValue(totalPoints) {
  return {
    totalPoints: totalPoints,
    responses: {},
    participants: [],
  };
}

/**
 * Prepare and get the user responses.
 * @param userResponses The responses from the survey participants
 * @param moduleList The list of all survey modules
 * @param activeQuestion The currently selected question data
 * @param moduleId Id of the point distribution module in the survey
 * @returns {totalpoint: int, reponses: {}}
 */
const formatPointDistributionDataFromResponses = (
  userResponses,
  moduleList,
  activeQuestion,
  moduleId
) => {
  const questionData = activeQuestion.metaData.questionData;
  const totalPoints = questionData.totalPoints;
  const addedOptions =
    activeQuestion.metaData?.questionData?.addedOptions || [];
  const options = getNonPopulateQuestionOptions(
    questionData,
    OPTION_PREFIX,
    addedOptions
  );
  const responses = Object.values(userResponses);
  const allResponses = [];
  const responsesPerRespondent = responses.reduce(
    (acc, response, currentIndex) => {
      const responseId = response.responseId;
      const answerData = response.answerData;
      const commentTime = response?.endSurveyTimestamp?.seconds;
      const moduleResponse = answerData[moduleId];
      if (!moduleResponse) return acc;
      const mainExplanation = moduleResponse.explanation || '';
      const index = currentIndex + 1;
      const name = getUserName(
        response.userName,
        answerData,
        moduleList,
        index
      );
      const nameLabel = `${name}-${currentIndex}`;
      const respondentData = options.reduce(
        getRespondentsFormattedData(moduleResponse, name, responseId),
        []
      );
      allResponses.push(...respondentData);
      return {
        ...acc,
        responses: getResponses(
          acc,
          nameLabel,
          mainExplanation,
          name,
          respondentData,
          responseId,
          commentTime
        ),
        participants: getParticipants(acc, name, nameLabel, respondentData),
      };
    },
    getInitialValue(totalPoints)
  );
  return {
    allResponses,
    options,
    ...responsesPerRespondent,
  };
};

const calculateAverages = (allResponses, respondents, options) => {
  const totals = allResponses.reduce((acc, { option, points, responseId }) => {
    acc[option] = {
      points: (acc[option]?.points || 0) + points,
      users: [...(acc[option]?.users || []), responseId],
    };
    return acc;
  }, {});
  return options.map((option) => {
    const value = Object.values(option)[0];
    const average = Math.floor((totals[value]?.points || 0) / respondents);
    return {
      option: value,
      points: average,
      users: totals[value]?.users || [],
    };
  });
};

const sortPointDistributionDataAsc = (averages, data) => {
  const avg = [...averages];
  const sortAvgAsc = avg.sort((a, b) => a.points - b.points);
  const options = getArrayOfOptions(sortAvgAsc);
  return sortAscByAnotherArray(data, options);
};

/**
 * Since we sort the average oin descending, we then sort all
 * chart data in ascending order to retain the averages
 * descending order.
 * @param averages
 * @param data
 * @returns {*}
 */
const sortPointDistributionDataDesc = (averages, data) => {
  const sortAvgDesc = [...averages].sort((a, b) => b.points - a.points);
  const options = getArrayOfOptions(sortAvgDesc);
  return sortAscByAnotherArray(data, options);
};

const formatPointDistributionComments = (data) => {
  return Object.entries(data).map(([key, obj]) => {
    return {
      ...obj,
      responseId: key,
      data: [
        {
          title: 'Overall',
          comment: obj.explanation,
          points: obj.data.reduce((acc, { points }) => acc + points, 0),
        },
        ...obj.data.map((item) => {
          return {
            title: item.option,
            comment: item.explanation,
            points: item.points,
          };
        }),
      ],
    };
  });
};

const getPointDistributionData = (
  userResponses,
  moduleList,
  activeQuestion,
  moduleId
) => {
  const {
    totalPoints,
    responses,
    allResponses,
    options,
    participants,
  } = formatPointDistributionDataFromResponses(
    userResponses,
    moduleList,
    activeQuestion,
    moduleId
  );
  const chartData = getFormattedDataWithFrequency(responses);
  const averages = calculateAverages(
    allResponses,
    getNumberOfParticipants(participants),
    options
  );
  return {
    totalPoints,
    participantsData: responses,
    chartData,
    participants,
    averages,
    comments: formatPointDistributionComments(responses),
  };
};

export {
  getPointDistributionData,
  sortPointDistributionDataAsc,
  sortPointDistributionDataDesc,
  formatPointDistributionComments,
};
