import get from 'lodash/get';
import queryString from 'query-string';

export const getSurveyCode = (props) => {
  const { inEditMode, surveyDetails, match } = props;
  if (inEditMode) {
    return surveyDetails && surveyDetails.surveyCode;
  }
  return (
    match &&
    match.params &&
    (match.params.surveyCode ||
      match.params.previewSurveyCode ||
      match.params.resultSurveyCode ||
      match.params.presentationSurveyCode ||
      match.params.facilitationSurveyCode)
  );
};

export const getBackToEdit = (props) => {
  return (get(props, 'location.search') || '').includes('backToEdit=true');
};

export const getResponseId = (search) => {
  const parsedQuery = queryString.parse(search);
  const { responseId } = parsedQuery;
  return responseId;
};
