import React, { Component } from 'react';
import queryString from 'query-string';
import styled from 'styled-components';
import { SENTENCE, HIGHLIGHT_VALUES } from '../../../const/module-types';
import { getNestedData } from '../../../const/nested-data';
import { getNumericKeys } from '../../../lib/builder-api';
import { appendUserIdAndName } from '../../../lib/survey-api';

import { DragSource, DropTarget, DragDropContext, DragLayer } from 'react-dnd';
import HTML5Backend, { getEmptyImage } from 'react-dnd-html5-backend';
import TouchBackend from 'react-dnd-touch-backend';

import {
  PresentationTableContainer,
  PresentationTable,
  PresentationRow as LayoutPresentationRow,
  PresentationCell,
  PresentationBody,
  ChartContainer,
  PresentationTitleRow,
} from '../../layout';

export const TextAndTable = styled('div')`
  height: 100%;
  width: 100%;
  overflow: auto;
`;

export const TextHighlightContainer = styled('div')`
  background: ${(props) => (props.background ? props.background : 'white')};
  width: 100%;
  padding: 1.25rem 0 3rem 0;
  margin: 0;
  cursor: pointer;
  user-select: none;
  height: 100%;
  overflow: auto;
  ${({ inMobileView, shortContainer, disableSelect }) =>
    inMobileView
      ? ``
      : `
  @media (min-width: 1200px) {
    padding: 0;
    height: ${shortContainer ? '12rem' : '100%'};
    display: flex;
    flex-direction: column;
    // background-image: linear-gradient(0deg, #ffffff 0%, #c4c4c4 100%);
    background-position: top left;
    background-size: 100% 0.5rem;
    background-repeat: no-repeat;
    user-select: ${disableSelect ? 'none' : 'auto'};
  }
  `}
`;

export const TextHighlight = styled('div')`
  font-family: 'Neue Haas Grotesk Display Std', sans-serif;
  font-size: 1.5rem;
  font-weight: ${(props) => props.fontWeight || 'lighter'};
  color: ${(props) => props.color || 'transparent'};
  max-width: 44.375rem;
  text-align: left;
  padding: 1rem;
  position: ${(props) => props.position || 'relative'};
  top: 0;
  left: 0;
  pointer-events: ${(props) => props.pointerEvents || 'auto'};
  cursor: text;
  z-index: ${(props) => props.zIndex || 1};
  user-select: none;
  line-height: 1.45;
  ::selection {
    background: #1ca4fc; /* WebKit/Blink Browsers */
  }
  overflow: visible;
  margin: auto 0;
  ${({ inMobileView, disableSelect }) =>
    inMobileView
      ? ``
      : `
  @media (min-width: 1200px) {
    user-select: ${disableSelect ? 'none' : 'auto'};
    margin: auto 0;
    font-size: 2.5rem;
    padding: 0 3.75rem;
  }
  `}
`;

export const TextHighlightSentence = styled('div')``;

export const TextHighlightSentenceOverlay = styled('div')`
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
  color: #333333;
`;

const LeftSelector = styled('div')`
  position: absolute;
  left: -0.6875rem;
  top: -0.6875rem;
  width: 1.375rem;
  height: 1.375rem;
  border-radius: 50%;
  border: 1px solid #ffffff;
  background-color: #ff9b3a;
  cursor: pointer;
  z-index: 4;
`;

const RightSelector = styled('div')`
  position: absolute;
  right: -0.6875rem;
  bottom: -0.6875rem;
  width: 1.375rem;
  height: 1.375rem;
  border-radius: 50%;
  border: 1px solid #ffffff;
  background-color: #ff9b3a;
  cursor: pointer;
  z-index: 4;
`;

// gets the text between start and end
const getHighlightText = ({ sentence, start, end }) => {
  return sentence.substring(parseInt(start, 10), parseInt(end, 10));
};

// gets the text around a single cursor position (start == end)
const getMobileHighlightText = ({ sentence, start, end }) => {
  if (start !== end) return getHighlightText({ sentence, start, end });
  const cursorPosition = start;
  // we need to get the new start and end as well as the highlighted text, and return that
  const sentenceWords = sentence.split(' ');
  const highlightedTextHash = sentenceWords.reduce(
    (collector, word, i) => {
      const wordStart = collector.currentLetterCount;
      const wordEnd = collector.currentLetterCount + word.length - 1;
      const highlightThisWord =
        cursorPosition >= wordStart && cursorPosition <= wordEnd;
      if (highlightThisWord && !collector.matchFound) {
        return {
          ...collector,
          matchFound: true,
          highlightText: word,
          start: wordStart,
          end: wordEnd,
          currentLetterCount: collector.currentLetterCount + word.length,
        };
      }
      return {
        ...collector,
        currentLetterCount: collector.currentLetterCount + word.length + 1, // +1 for the space
      };
    },
    {
      highlightText: '',
      start: parseInt(start, 10),
      end: parseInt(end, 10),
      currentLetterCount: 0,
      matchFound: false,
    }
  );
  return highlightedTextHash;
};

const PresentationRow = styled(LayoutPresentationRow)`
  &:hover {
    color: #ff9b3a;
  }
`;

const Letter = styled('span')`
  position: relative;
  opacity: ${(props) => props.opacity || '1'};
  color: ${(props) => props.color || 'transparent'};
  pointer-events: ${(props) => props.pointerEvents || 'initial'};
  background-color: ${(props) =>
    props.highlighter
      ? props.opacity
        ? `rgba(28,164,252,${props.opacity})`
        : 'rgba(28,164,252,0.5)'
      : 'transparent'};
  cursor: ${(props) => (props.highlighter ? 'pointer' : 'initial')};
  padding: 0.125rem 0;
`;

export const getHighlightValues = ({ answerData, moduleId }) => {
  const v1Data = answerData[moduleId];
  const v2Data = getNestedData(answerData, `${moduleId}.${HIGHLIGHT_VALUES}`);
  if (v2Data) return v2Data;
  // filter out non-numeric keys (eg. explanation)
  if (v1Data) {
    const dataArray = Object.entries(v1Data)
      .filter(([key, data]) => String(key).match(/^\d/))
      .reduce((collector, [key, data]) => {
        const collector2 = [...collector];
        collector2[key] = data;
        return collector2;
      }, []);
    return dataArray;
  }
  return [];
};

const LEFT = 'LEFT';
const RIGHT = 'RIGHT';

const mergeHighlights = (highlightArray = []) => {
  const rangeArray = highlightToRange(highlightArray).sort((a, b) => {
    return a[0] - b[0] || a[1] - b[1];
  });
  const mergedRanges = mergeRange(rangeArray);
  const newHighlights = rangeToHighlight(mergedRanges);
  return newHighlights;
};

const rangeToHighlight = (rangeArray = []) => {
  return rangeArray.map(([anchorOffset, focusOffset]) => {
    return { anchorOffset, focusOffset };
  });
};

const highlightToRange = (highlightArray = []) => {
  return highlightArray.map((highlightHash) => {
    return [highlightHash.anchorOffset, highlightHash.focusOffset];
  });
};

// https://stackoverflow.com/questions/26390938/merge-arrays-with-overlapping-values
const mergeRange = (ranges = []) => {
  let result = [],
    last;
  ranges.forEach((r) => {
    if (!last || r[0] > last[1]) result.push((last = r));
    else if (r[1] > last[1]) last[1] = r[1];
  });
  return result;
};

export default class TextHighlightSurvey extends Component {
  state = {
    currentHighlightIndex: null,
    currentSelectorEdge: null,
    currentLetterIndex: 0,
    clickAnchorOffset: 0,
    movedSelector: false,
  };
  // todo: ok so the single layer simplified things, but we still need to
  // allow mobile users to select using the built-in text selector since the
  // current touch experience in mobile still feels a bit off due to the lack of
  // move sensitivity in mobile
  clickSelector = (e) => {
    console.log('clickSelector');
    const { clientX, clientY } = e.touches ? e.touches[0] : e;
    const element = document.elementFromPoint(clientX, clientY);
    const { highlightIndex, selectorEdge, letterIndex } = (
      element || e.target
    ).dataset;
    if (![LEFT, RIGHT].includes(selectorEdge)) return;
    console.log('clickSelector valid');
    this.setState({
      currentHighlightIndex: parseInt(highlightIndex, 10),
      currentSelectorEdge: selectorEdge,
      currentLetterIndex: parseInt(letterIndex, 10),
    });
  };
  getShowAll() {
    const { location } = this.props;
    const parsedQuery = queryString.parse((location && location.search) || '');
    const { showAll } = parsedQuery;
    return showAll;
  }
  getHighlightValues() {
    const { values, moduleId } = this.props;
    const answerData = values.textHighlight;
    return getHighlightValues({ answerData, moduleId });
  }
  getPresentationHighlightValues() {
    const { moduleId } = this.props;
    const userResponses =
      getNestedData(this.props, 'values.userResponses') || {};
    const presentationHighlightValues = Object.entries(userResponses).reduce(
      (collector, [userId, responseData]) => {
        const { answerData, userName } = responseData;
        const highlightValues = getHighlightValues({ answerData, moduleId });
        const highlightValuesWithUserId = appendUserIdAndName({
          array: highlightValues,
          userId,
          userName,
        });
        const thCollector = [...collector, ...highlightValuesWithUserId];
        return thCollector;
      },
      []
    );
    return presentationHighlightValues;
  }
  getExtendedHighlightValues() {
    const { inPresentationMode } = this.props;
    const data = this.getHighlightValues();
    const ranges = this.getTopRanges();
    const presentationData = this.getPresentationHighlightValues();
    const presentationDataToUse = this.getShowAll() ? presentationData : ranges;
    const valuesToUse = inPresentationMode ? presentationDataToUse : data;
    return valuesToUse;
  }
  resetTextHighlight = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const { moduleId, values, setValues, inPresentationMode } = this.props;
    if (inPresentationMode) return;
    const textHighlight = {
      [moduleId]: {
        [HIGHLIGHT_VALUES]: [],
      },
    };
    setValues({
      ...values,
      textHighlight: {
        ...values.textHighlight,
        ...textHighlight,
      },
    });
    this.setState({
      currentHighlightIndex: null,
      currentSelectorEdge: null,
      currentLetterIndex: 0,
      clickAnchorOffset: 0,
      movedSelector: false,
    });
    window.getSelection().removeAllRanges();
  };
  deselectText = (e) => {
    const { setValues, values, moduleId, inPresentationMode } = this.props;
    const { selectorEdge, highlightIndex } = e.target.dataset;
    if ([LEFT, RIGHT].includes(selectorEdge)) return;
    e.stopPropagation();
    e.preventDefault();
    const removeInt = parseInt(highlightIndex, 10);
    console.log(`removing highlight ${removeInt}`);
    if (inPresentationMode) return;
    const existingHighlightValues = this.getHighlightValues();
    const highlightValues = existingHighlightValues.filter((v, i) => {
      return i !== removeInt;
    });
    const textHighlight = {
      [moduleId]: {
        [HIGHLIGHT_VALUES]: highlightValues,
      },
    };
    setValues({
      ...values,
      textHighlight: {
        ...values.textHighlight,
        ...textHighlight,
      },
    });
    this.setState({
      currentHighlightIndex: null,
      currentSelectorEdge: null,
      currentLetterIndex: 0,
      clickAnchorOffset: 0,
      movedSelector: false,
    });
  };
  getTextHighlightOpacity() {
    const { inPresentationMode } = this.props;
    if (!inPresentationMode) return 0.5;
    const highlightValues = this.getExtendedHighlightValues();
    const userHighlightCount = highlightValues.reduce(
      (collector, highlightHash) => {
        const { userId } = highlightHash;
        return {
          ...collector,
          [userId]: collector[userId] ? collector[userId] + 1 : 1,
        };
      },
      {}
    );
    const userCount = Object.keys(userHighlightCount).length;
    return 1 / userCount;
  }
  getPresentationOpacity = ({ highlightCount }) => {
    const { inPresentationMode } = this.props;
    if (!inPresentationMode) return 0.5;
    const { largestHighlightCountValues } = this.getHighlightCounts();
    const index = largestHighlightCountValues.indexOf(highlightCount) + 1;
    const opacity = (4 - index) / 4;
    return opacity;
  };
  getHighlightCounts() {
    const { inPresentationMode, questionData } = this.props;
    if (!inPresentationMode) return {};
    const highlightValues = this.getPresentationHighlightValues();
    const sentence = questionData[SENTENCE];
    let highlightCountsPerChar = new Array(sentence.length + 1).fill(0);
    let largestHighlightCountValues = [];
    highlightValues.forEach((highlightHash) => {
      const { anchorOffset, focusOffset } = highlightHash;
      for (let i = anchorOffset; i <= focusOffset; i++) {
        const oldValue = highlightCountsPerChar[i];
        const newValue = oldValue + 1;
        highlightCountsPerChar[i] = newValue;
        largestHighlightCountValues = [
          ...new Set([...largestHighlightCountValues, newValue]),
        ]
          .sort((a, b) => b - a)
          .slice(0, 4);
      }
    });
    return {
      highlightCountsPerChar,
      largestHighlightCountValues,
    };
  }
  getTopRanges() {
    const { inPresentationMode, questionData } = this.props;
    if (!inPresentationMode) return [];
    const sentence = questionData[SENTENCE];
    const {
      highlightCountsPerChar,
      largestHighlightCountValues,
    } = this.getHighlightCounts();
    let topRanges = [];
    let start = void 0;
    let end = void 0;
    largestHighlightCountValues.forEach((highestHighlightCount) => {
      highlightCountsPerChar.forEach((highlightCount, position) => {
        if (
          highlightCount === highestHighlightCount &&
          position !== highlightCountsPerChar.length - 1
        ) {
          if (start === void 0) {
            start = position;
            end = position;
            return;
          }
          end = position;
          return;
        } else {
          if (start !== void 0) {
            // ignore empty
            if (start !== position) {
              const rangeHash = {
                anchorOffset: start,
                focusOffset: position,
                highlightCount: highlightCountsPerChar[position],
                text: getHighlightText({ sentence, start, end: position }),
              };
              topRanges.push(rangeHash);
            }
            start = void 0;
            end = void 0;
            return;
          }
        }
      });
    });
    return topRanges.sort((a, b) => {
      return b.highlightCount - a.highlightCount;
    });
  }
  renderPresentationTable() {
    const { inPresentationMode } = this.props;
    if (!inPresentationMode) return null;
    const topRanges = this.getTopRanges();
    const body = topRanges.map((rangeHash, i) => {
      return (
        <PresentationRow key={`tr${i}`}>
          <PresentationCell>{rangeHash.text}</PresentationCell>
          <PresentationCell
            width="9.9375rem"
            background="#F8F8F8"
            textAlign="center"
          >
            {rangeHash.highlightCount}
          </PresentationCell>
        </PresentationRow>
      );
    });
    return (
      <PresentationTableContainer>
        <PresentationTable>
          <thead>
            <PresentationTitleRow>
              <PresentationCell background="transparent">
                Highlighted Passage
              </PresentationCell>
              <PresentationCell
                width="9.9375rem"
                background="transparent"
                textAlign="center"
              >
                Highlight Count
              </PresentationCell>
            </PresentationTitleRow>
          </thead>
          <PresentationBody>{body}</PresentationBody>
        </PresentationTable>
      </PresentationTableContainer>
    );
  }
  onPointerDown = (e) => {
    e.stopPropagation();
    e.preventDefault();
    console.log('pointerDown');
    if (e.button !== 0 && !e.touches) return;
    const { inPresentationMode } = this.props;
    if (inPresentationMode) return;
    const { clientX, clientY } = e.touches ? e.touches[0] : e;
    const element = document.elementFromPoint(clientX, clientY);
    const els = document.querySelectorAll(':hover');
    const { selectorEdge, letterIndex } = element.parentNode.dataset.letterIndex
      ? element.parentNode.dataset
      : element.dataset;
    console.log({ element, els, selectorEdge, letterIndex });
    if ([LEFT, RIGHT].includes(selectorEdge)) return this.clickSelector(e);
    this.setState({
      clickAnchorOffset: parseInt(letterIndex, 10),
    });
  };
  onPointerMove = (e) => {
    console.log('pointerMove');
    const { moduleId, values, inPresentationMode } = this.props;
    const {
      currentHighlightIndex,
      currentSelectorEdge,
      currentLetterIndex,
    } = this.state;
    if (inPresentationMode) return;
    if (currentHighlightIndex === null) return;
    const { clientX, clientY } = e.touches ? e.touches[0] : e;
    const element = document.elementFromPoint(clientX, clientY);
    const elementLetterIndex = element.parentNode.dataset.letterIndex
      ? parseInt(element.parentNode.dataset.letterIndex, 10)
      : parseInt(element.dataset.letterIndex, 10);
    if (!elementLetterIndex) return;
    const existingHighlightValues = this.getHighlightValues();
    if (!existingHighlightValues[currentHighlightIndex]) {
      debugger;
      return;
    }
    console.log('onPointerMove');
    const anchorOffset =
      currentSelectorEdge === LEFT
        ? elementLetterIndex
        : existingHighlightValues[currentHighlightIndex].anchorOffset;
    const focusOffset =
      currentSelectorEdge === LEFT
        ? existingHighlightValues[currentHighlightIndex].focusOffset
        : elementLetterIndex;

    const sentence = this.props.questionData[SENTENCE];
    let highlightRange =
      focusOffset > anchorOffset
        ? { anchorOffset, focusOffset }
        : { anchorOffset: focusOffset, focusOffset: anchorOffset };
    let highlightText = getHighlightText({
      sentence,
      start: highlightRange.anchorOffset,
      end: highlightRange.focusOffset,
    });
    console.log(
      `modifying text highlight ${currentHighlightIndex} ${highlightRange.anchorOffset} ${highlightRange.focusOffset} ${highlightText}`
    );
    let newHighlightValues = [...existingHighlightValues];
    newHighlightValues[currentHighlightIndex] = highlightRange;
    const textHighlight = {
      ...values.textHighlight,
      [moduleId]: {
        [HIGHLIGHT_VALUES]: newHighlightValues,
      },
    };
    this.props.setValues({
      ...values,
      textHighlight,
    });
    this.setState({ movedSelector: true });
  };
  onPointerUp = (e) => {
    e.stopPropagation();
    e.preventDefault();
    console.log('pointerUp');
    if (e.button !== 0 && !e.touches) return;
    const { moduleId, values, inPresentationMode } = this.props;
    const {
      clickAnchorOffset,
      currentHighlightIndex,
      currentSelectorEdge,
      movedSelector,
    } = this.state;
    const existingHighlightValues = this.getHighlightValues();
    const { clientX, clientY } = e.changedTouches ? e.changedTouches[0] : e;
    if (inPresentationMode) return;
    const element = document.elementFromPoint(clientX, clientY);
    const { highlightIndex, selectorEdge, letterIndex } = element.parentNode
      .dataset.letterIndex
      ? element.parentNode.dataset
      : element.dataset;
    if (
      highlightIndex &&
      highlightIndex !== 'false' &&
      ![LEFT, RIGHT].includes(selectorEdge)
    )
      return this.deselectText(e);
    if (!letterIndex && letterIndex !== '0') return this.resetTextHighlight(e);
    if (inPresentationMode) return;
    let anchorOffset = parseInt(clickAnchorOffset, 10);
    let focusOffset = parseInt(letterIndex, 10) || anchorOffset;
    if (currentHighlightIndex === null) {
      // desktop mode, handle selections
      const selection = window.getSelection();
      if (selection && selection.rangeCount) {
        anchorOffset = selection.anchorOffset;
        focusOffset = selection.focusOffset;
        if (
          selection.anchorNode &&
          selection.anchorNode.parentNode.dataset.letterIndex
        ) {
          anchorOffset = parseInt(
            selection.anchorNode.parentNode.dataset.letterIndex,
            10
          );
          focusOffset = parseInt(
            selection.focusNode.parentNode.dataset.letterIndex,
            10
          );
        } else if (e.target.dataset.letterIndex) {
          anchorOffset = parseInt(e.target.dataset.letterIndex, 10);
          focusOffset = parseInt(e.target.dataset.letterIndex, 10);
        }
      }
      const sentence = this.props.questionData[SENTENCE];
      let highlightRange =
        focusOffset > anchorOffset
          ? { anchorOffset, focusOffset }
          : { anchorOffset: focusOffset, focusOffset: anchorOffset };
      let highlightText = getHighlightText({
        sentence,
        start: highlightRange.anchorOffset,
        end: highlightRange.focusOffset,
      });
      if (focusOffset === anchorOffset) {
        const mobileHighlightText = getMobileHighlightText({
          sentence,
          start: anchorOffset,
          end: focusOffset,
        });
        highlightText = mobileHighlightText.highlightText;
        highlightRange = {
          anchorOffset: mobileHighlightText.start,
          focusOffset: mobileHighlightText.end,
        };
      }
      console.log(
        `adding text highlight ${highlightRange.anchorOffset} ${highlightRange.focusOffset} ${highlightText}`
      );
      const newHighlightValues = mergeHighlights([
        ...existingHighlightValues,
        highlightRange,
      ]);
      console.log(newHighlightValues);
      const textHighlight = {
        ...values.textHighlight,
        [moduleId]: {
          [HIGHLIGHT_VALUES]: newHighlightValues,
        },
      };
      this.props.setValues({
        ...values,
        textHighlight: {
          ...this.props.values.textHighlight,
          ...textHighlight,
        },
      });
    }
    if (currentHighlightIndex !== null && !movedSelector) {
      return this.resetTextHighlight(e);
    }
    this.setState({
      currentHighlightIndex: null,
      currentSelectorEdge: null,
      currentLetterIndex: 0,
      clickAnchorOffset: 0,
      movedSelector: false,
    });
    window.getSelection().removeAllRanges();
  };
  getHighlightedIndex({ letterIndex }) {
    const highlightedValues = this.getExtendedHighlightValues();
    if (!highlightedValues.length) return false;
    const highlighted = highlightedValues.reduce(
      (collector, { anchorOffset, focusOffset, highlightCount }, i) => {
        if (letterIndex >= anchorOffset && letterIndex <= focusOffset) return i;
        return collector;
      },
      null
    );
    return highlighted;
  }
  getHighlighted({ letterIndex }) {
    const highlightedValues = this.getExtendedHighlightValues();
    if (!highlightedValues.length) return false;
    const highlighted = highlightedValues.reduce(
      (collector, { anchorOffset, focusOffset, highlightCount }, i) => {
        if (letterIndex >= anchorOffset && letterIndex <= focusOffset)
          return true;
        return collector;
      },
      false
    );
    return highlighted;
  }
  getSelectorEdge({ letterIndex }) {
    const highlightedValues = this.getExtendedHighlightValues();
    if (!highlightedValues.length) return false;
    const edge = highlightedValues.reduce(
      (collector, { anchorOffset, focusOffset, highlightCount }, i) => {
        if (letterIndex === anchorOffset) return LEFT;
        if (letterIndex === focusOffset) return RIGHT;
        return collector;
      },
      null
    );
    return edge;
  }
  render() {
    console.log('render');
    const { inMobileView, questionData } = this.props;
    const { currentSelectorEdge } = this.state;
    const sentence = questionData[SENTENCE] || '';
    const highlightedValues = this.getExtendedHighlightValues();
    const sentenceArray = sentence.split('');
    const sentenceLetters = sentenceArray.map((letter, m) => {
      const index = m;
      const highlightIndex = this.getHighlightedIndex({
        letterIndex: index,
      });
      const highlighter = this.getHighlighted({ letterIndex: index });
      const selectorEdge = this.getSelectorEdge({ letterIndex: index });
      const key = `sentence${index}`;
      const userSelect = [LEFT, RIGHT].includes(currentSelectorEdge)
        ? 'none'
        : 'auto';
      return (
        <Letter
          color="#333333"
          data-highlight-index={highlightIndex}
          data-letter-index={index}
          data-selector-edge={selectorEdge}
          {...{
            key,
            highlighter,
            letterIndex: index,
            userSelect,
          }}
        >
          {this.getSelectorEdge({ letterIndex: index }) === LEFT && (
            <LeftSelector
              data-highlight-index={highlightIndex}
              data-letter-index={index}
              data-selector-edge={LEFT}
            />
          )}
          {this.getSelectorEdge({ letterIndex: index }) === RIGHT && (
            <RightSelector
              data-highlight-index={highlightIndex}
              data-letter-index={index}
              data-selector-edge={RIGHT}
            />
          )}
          {letter}
        </Letter>
      );
    });
    return (
      <TextAndTable>
        <TextHighlightContainer
          onPointerDown={this.onPointerDown}
          onPointerMove={this.onPointerMove}
          onTouchMove={this.onPointerMove}
          onPointerUp={this.onPointerUp}
          onMouseUp={this.onPointerUp}
          onMouseDown={this.onPointerDown}
          onMouseMove={this.onPointerMove}
          onTouchStart={this.onPointerDown}
          onTouchEnd={this.onPointerUp}
          {...{ inMobileView }}
        >
          <TextHighlight {...{ inMobileView }}>
            <TextHighlightSentence>{sentenceLetters}</TextHighlightSentence>
          </TextHighlight>
        </TextHighlightContainer>
        {this.state.log}
        {this.renderPresentationTable()}
      </TextAndTable>
    );
  }
}
