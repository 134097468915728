import React, { useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';

import {
  setClickedChartElementResponseIds,
  setCommentsPanelVisibility,
  setPanelComments,
  setSelectedParticipantResponseId,
} from 'pages/presentationSlice';

import CrossSVG from './cross.svg';

const Container = styled('div')`
  display: ${(props) => (props.active || props.show ? 'block' : 'none')};
  width: 415px;
  height: 220px;
  background-color: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.4);
  padding-left: 30px;
  position: absolute;
  margin-top: 8px;
  margin-left: -50px;
`;

const Cross = styled('div')`
  height: 35px;
  display: flex;
  justify-content: flex-end;
`;

const Header = styled('div')`
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  color: #3faffa;
  font-family: Roboto, sans-serif;
  font-size: 15px;
  font-weight: 700;
`;

const Option = styled('div')`
  flex-grow: 1;
`;

const Count = styled('div')`
  width: 80px;
`;

const Button = styled('button')`
  width: 32px;
  height: 21px;
  padding: 0;
  border: none;
  outline: none;
`;

const SortHighestActiveSVG = ReactDOMServer.renderToString(
  <svg
    width="32"
    height="21"
    viewBox="0 0 33 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32.5833" height="20.4444" fill="#3FAFFA" />
    <rect x="5.75" y="4.47217" width="21.0833" height="2.55556" fill="white" />
    <rect x="5.75" y="8.94434" width="14.0556" height="2.55556" fill="white" />
    <rect x="5.75" y="13.4166" width="8.94444" height="2.55556" fill="white" />
  </svg>
);

const SortHighestInActiveSVG = ReactDOMServer.renderToString(
  <svg
    width="32"
    height="21"
    viewBox="0 0 33 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.319444"
      y="0.319444"
      width="31.9444"
      height="19.8056"
      stroke="#3FAFFA"
      strokeWidth="0.638889"
    />
    <rect
      x="5.75"
      y="4.47217"
      width="21.0833"
      height="2.55556"
      fill="#3FAFFA"
    />
    <rect
      x="5.75"
      y="8.94434"
      width="14.0556"
      height="2.55556"
      fill="#3FAFFA"
    />
    <rect
      x="5.75"
      y="13.4166"
      width="8.94444"
      height="2.55556"
      fill="#3FAFFA"
    />
  </svg>
);

const SortLowestActiveSVG = ReactDOMServer.renderToString(
  <svg
    width="33"
    height="21"
    viewBox="0 0 33 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.416687" width="32.5833" height="20.4444" fill="#3FAFFA" />
    <rect
      width="21.0833"
      height="2.55556"
      transform="matrix(1 0 0 -1 8.08344 15.9722)"
      fill="white"
    />
    <rect
      width="14.0556"
      height="2.55556"
      transform="matrix(1 0 0 -1 8.08337 11.5)"
      fill="white"
    />
    <rect
      width="8.94444"
      height="2.55556"
      transform="matrix(1 0 0 -1 8.08337 7.02771)"
      fill="white"
    />
  </svg>
);

const SortLowestInActiveSVG = ReactDOMServer.renderToString(
  <svg
    width="33"
    height="21"
    viewBox="0 0 33 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.736116"
      y="0.319444"
      width="31.9444"
      height="19.8056"
      fill="white"
      stroke="#3FAFFA"
      strokeWidth="0.638889"
    />
    <rect
      width="21.0833"
      height="2.55556"
      transform="matrix(1 0 0 -1 8.08344 15.9722)"
      fill="#3FAFFA"
    />
    <rect
      width="14.0556"
      height="2.55556"
      transform="matrix(1 0 0 -1 8.08341 11.5)"
      fill="#3FAFFA"
    />
    <rect
      width="8.94444"
      height="2.55556"
      transform="matrix(1 0 0 -1 8.08341 7.02771)"
      fill="#3FAFFA"
    />
  </svg>
);

const SortHighestButtonImg = styled(Button)`
  background-image: url(data:image/svg+xml;base64,${(props) =>
    props.selected
      ? btoa(SortHighestActiveSVG)
      : btoa(SortHighestInActiveSVG)});
  background-repeat: no-repeat;
  background-color: #fff;
  background-size: 32px 21px;
  margin-right: 2px;
`;

const SortLowestButtonImg = styled(Button)`
  background-image: url(data:image/svg+xml;base64,${(props) =>
    props.selected ? btoa(SortLowestActiveSVG) : btoa(SortLowestInActiveSVG)});
  background-repeat: no-repeat;
  background-color: #fff;
  background-size: 32px 21px;
`;

const ItemContainer = styled('div')`
  overflow-y: scroll;
  height: calc(220px - 70px);
`;

const Item = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 65%;
  padding-top: 17px;
  font-family: Roboto, sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: #000000;
`;

const OptionName = styled('div')`
  text-transform: capitalize;
  &:hover {
    text-decoration: underline;
  }
  color: ${(props) => (props.selected ? '#FF9736' : '')};
`;

const MadlibOptionsView = ({
  options,
  active,
  onClick,
  onSortAsc,
  onSortDesc,
  onMouseEnter,
  onMouseLeave,
}) => {
  const dispatch = useDispatch();
  const responses = useSelector((state) => state.presentation.userResponses);
  const selectedParticipantResponseId = useSelector(
    (state) => state.presentation.selectedParticipantResponseId
  );

  const activeQuestion = useSelector(
    (state) => state.presentation.activeQuestion
  );
  const comments = useSelector((state) => state.presentation.comments);

  const moduleId = activeQuestion?.metaData?.moduleId;

  const isSelectedUserOption = (optionName) => {
    return responses?.[selectedParticipantResponseId]?.answerData[moduleId]?.[
      'dropdownOptions1'
    ] === optionName
      ? true
      : false;
  };

  const [show, setShow] = useState(false);
  const [sortLowest, setSortLowest] = useState(false);
  const [sortHighest, setSortHighest] = useState(false);
  const [clicked, setClicked] = useState('');
  const [itemClickedToggle, setItemClickedToggle] = useState(false);

  const onClickClose = (e) => {
    setShow(false);
    setSortLowest(false);
    setSortHighest(false);
    onClick(e, true);
  };

  const onClickSortLowest = (data) => {
    setSortLowest(!sortLowest);
    setSortHighest(false);
    onSortAsc(data);
  };

  const onClickSortHighest = (data) => {
    setSortHighest(!sortHighest);
    setSortLowest(false);
    onSortDesc(data);
  };

  const onClickOptionName = (name) => {
    dispatch(setSelectedParticipantResponseId(null));
    setClicked(name);

    let responseIds = [];
    Object.values(responses).forEach((user) => {
      if (
        responses?.[user.responseId]?.answerData[moduleId]?.[
          'dropdownOptions1'
        ] === name
      ) {
        responseIds.push(user.responseId);
      }
    });
    dispatch(setClickedChartElementResponseIds(responseIds));
    dispatch(setCommentsPanelVisibility(true));
    setItemClickedToggle(!itemClickedToggle);
    dispatch(setPanelComments(comments));
  };

  return (
    <Container
      active={active}
      show={show}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Cross>
        <img src={CrossSVG} alt="cross" onClick={onClickClose} />
      </Cross>

      <Header>
        <Option>Option</Option>
        <Count>Count</Count>
        <div className="sort">
          <SortHighestButtonImg
            selected={sortHighest}
            onClick={() => onClickSortHighest(options)}
          />
          <SortLowestButtonImg
            selected={sortLowest}
            onClick={() => onClickSortLowest(options)}
          />
        </div>
      </Header>

      <ItemContainer>
        {options &&
          options.map((option) => (
            <Item key={`${option.name}${option.count}`}>
              <OptionName
                onClick={() => onClickOptionName(option.name)}
                style={{ color: clicked === option.name ? '#FF9736' : '' }}
                selected={isSelectedUserOption(option.name)}
              >
                {option.name}
              </OptionName>
              <div>{option.count}</div>
            </Item>
          ))}
      </ItemContainer>
    </Container>
  );
};

export default MadlibOptionsView;
