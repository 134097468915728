import React from 'react';
import styled from 'styled-components';
import {
  VictoryChart,
  VictoryScatter,
  Selection,
  VictoryAxis,
  VictoryLabel,
  VictoryTooltip,
  Point,
  VictoryBar,
  VictoryBoxPlot,
  Box,
  LineSegment,
} from 'victory';
import {
  PLOTTER_XY,
  PLOTTER_22,
  PLOTTER_VALUES,
  Y_CURRENT,
  X,
  Y,
} from '../../../const/module-types';
import { getNestedData } from '../../../const/nested-data';
import {
  appendUserIdAndName,
  getPersonalInfoName,
} from '../../../lib/survey-api';
import {
  Delete,
  PresentationTableContainer,
  PresentationTable,
  PresentationRow,
  PresentationCell,
  PresentationBody,
  ChartContainer,
  PresentationTitleRow,
} from '../../../components/layout';
import { getMedian } from '../PointDistribution';

const axisLabel = {
  fontSize: 18,
  fontFamily: 'Neue Haas Grotesk Text Std',
  fontStyle: 'normal',
  fontWeight: 'bold',
  color: '#333333',
  textAnchor: 'start',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'block',
};

const tickLabels = {
  fontSize: 12,
  fontFamily: 'Neue Haas Grotesk Text Std',
  fontStyle: 'normal',
  fontWeight: 'normal',
  color: '#333333',
};

const ticks = {
  stroke: '#333333',
  size: 0,
};

const axis = {
  stroke: '#333333',
  strokeDasharray: '2 4',
};

export const axisStyle = {
  axis,
  axisLabel,
  tickLabels,
  ticks,
};

export const axisStyleXY = {
  axis,
  axisLabel: {
    ...axisLabel,
    textAnchor: 'start',
  },
  tickLabels,
  ticks,
};

export const axisStyleXY_X = {
  axis,
  axisLabel: {
    ...axisLabel,
    textAnchor: 'middle',
  },
  tickLabels,
  ticks,
};

export const CHART_HEIGHT = window.innerWidth < 834 ? 320 : 442;
export const CHART_WIDTH = window.innerWidth < 834 ? 320 : 710;

const moduleTypePadding = {
  PLOTTER_XY: { bottom: 45, left: 45, top: 45, right: 20 },
  PLOTTER_22: { top: 20, bottom: 20, left: 10, right: 10 },
};

const DEFAULT_DOMAINS = {
  PLOTTER_XY: [1, 8],
  PLOTTER_22: [-100, 100],
};

export const CURRENT_POINT = 'Current';
export const AVERAGE_POINT = 'Average';
export const PLACEHOLDER_BAR = 'Placeholder';

export const stringToRangeArray = (
  stringValue,
  moduleType,
  defaultStart = 0
) => {
  const defaultDomain =
    DEFAULT_DOMAINS[moduleType] || DEFAULT_DOMAINS[PLOTTER_XY];
  if (!stringValue || !moduleType) return defaultDomain;
  const split = stringValue
    .replace('[', '')
    .replace(']', '')
    .split(',');
  const intArray = split.map((intAsString) => {
    return parseInt(intAsString.trim(), 10);
  });
  if (intArray.length === 2) return intArray;
  if (intArray.length === 1 && intArray[0] > 0)
    return [defaultStart, intArray[0]];
  return defaultDomain;
};

const PlotterAndResponses = styled('div')`
  height: 100%;
`;

const PlotterContainer = styled('div')`
  background-color: #ffffff;

  padding: 0 0.5rem;
  position: static;
  top: 5.75rem;
  left: 0;
  bottom: 6rem;
  z-index: 1;
  width: 100%;
  display: flex;
  align-items: center;
  height: 100%;
  ${({ inMobileView }) =>
    inMobileView
      ? ``
      : `
  @media (min-width: 1200px) {
    height: 100%;
    flex-direction: column;
    justify-content: center;
    // background-image: linear-gradient(0deg, #ffffff 0%, #c4c4c4 100%);
    background-position: top left;
    background-size: 100% 0.5rem;
    background-repeat: no-repeat;
    padding: 0 3.75rem;

    position: static;
    top: auto;
    left: auto;
    bottom: auto;
    width: auto;
    align-items: flex-start;
  }
  `}
`;

const QUADRANT_NP = 'QUADRANT_NP'; // negative x, positive y
const QUADRANT_PP = 'QUADRANT_PP';
const QUADRANT_PN = 'QUADRANT_PN';
const QUADRANT_NN = 'QUADRANT_NN';

const quadrantLabelStyle = {
  fontSize: 18,
  fontFamily: 'Neue Haas Grotesk Display Std',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fill: '#1CA4FC',
};

export class VariablePoint extends React.Component {
  onClick = (e) => {
    const { x, y, datum, origin } = this.props;
    const { onPointClick } = this.props;
    onPointClick && onPointClick({ x, y, datum, origin });
  };
  render() {
    const { x, y, datum } = this.props;
    const { type } = datum;
    if (type === CURRENT_POINT) {
      return (
        <g role="presentation" onClick={this.onClick}>
          <Point {...this.props} style={{ fill: '#FF9B3A' }} size={16} />
          <VictoryLabel
            text="Current"
            x={x}
            y={y}
            dy={-30}
            style={{ fill: '#FF9B3A', textAnchor: 'middle' }}
          />
        </g>
      );
    }
    if (type === AVERAGE_POINT) {
      return (
        <g role="presentation">
          <svg
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            x={x - 17}
            y={y - 17}
          >
            <circle
              cx="16"
              cy="16"
              r="16"
              transform="translate(1 1)"
              stroke="#FF9B3A"
              strokeWidth="2"
            />
            <line
              y1="-1"
              x2="32"
              y2="-1"
              transform="translate(16 1) rotate(90)"
              stroke="#FF9B3A"
              strokeWidth="2"
            />
            <line
              y1="-1"
              x2="32"
              y2="-1"
              transform="translate(33 16) rotate(-180)"
              stroke="#FF9B3A"
              strokeWidth="2"
            />
          </svg>
          <VictoryLabel
            text="Average"
            x={x}
            y={y}
            dy={-30}
            style={{ fill: '#FF9B3A', textAnchor: 'middle' }}
          />
        </g>
      );
    }
    return (
      <g role="presentation" onClick={this.onClick}>
        <Point {...this.props} style={{ fill: '#1CA4FC' }} />
      </g>
    );
  }
}

export const getPlotterValues = ({ moduleId, values }) => {
  const v1Data = getNestedData(values, moduleId);
  const v2Data = getNestedData(values, `${moduleId}.${PLOTTER_VALUES}`);
  if (v2Data) return v2Data;
  // filter out non-numeric keys (eg. explanation)
  if (v1Data) {
    const dataArray = Object.entries(v1Data)
      .filter(([key, data]) => String(key).match(/^\d/))
      .reduce((collector, [key, data]) => {
        const collector2 = [...collector];
        collector2[key] = data;
        return collector2;
      }, []);
    return dataArray;
  }
  return [];
};

export const ResponsesContainer = styled('div')`
  width: 100%;
  margin-top: 0.75rem;
`;

export const ResponsesTitle = styled('div')`
  font-family: 'Neue Haas Grotesk Display Std', sans-serif;
  font-size: 1.5rem;
  font-weight: normal;
  color: #1ca4fc;
  margin-bottom: 0.75rem;
  display: none;
`;

export const ResponseMessage = styled('div')`
  flex: 1 1 auto;
  font-family: 'Neue Haas Grotesk Display Std', sans-serif;
  font-size: 1.5rem;
  font-weight: normal;
  color: #333333;
  padding: 1rem 0;
  margin-top: -1px;
  line-height: 2rem;
  background-image: linear-gradient(
    to right,
    #333 40%,
    rgba(255, 255, 255, 0) 20%
  );
  background-position: top;
  background-size: 3px 1px;
  background-repeat: repeat-x;
`;

export const ResponseRowContainer = styled('div')`
  background-color: white;
  box-shadow: inset -1px 1px 0.1875rem rgba(0, 0, 0, 0.15);
  -webkit-appearance: none;
  overflow: hidden;
  position: relative;
`;

export const ResponseRow = styled('div')`
  display: flex;
  padding: 0 2.25rem;
`;

export const ParticipantName = styled('div')`
  font-family: 'Neue Haas Grotesk Display Std', sans-serif;
  font-size: 0.75rem;
  font-weight: bold;
  flex: 0 0 6rem;
  margin-right: 1.25rem;
  color: #1ca4fc;
  padding: 1rem 0;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 2rem;
  text-align: right;
`;

export const ResponseDelete = styled(Delete)`
  position: absolute;
  right: 1rem;
  top: 1rem;
`;

export default class Plotter extends React.Component {
  state = {
    selectedResponses: [],
  };
  static defaultProps = {
    value: [],
    onChange: () => {},
    maxChoices: 1,
    enabled: true,
    round: true,
  };
  withinDomain(point) {
    const { x, y } = point;
    const xDomain = this.getXDomain();
    const yDomain = this.getYDomain();
    return (
      x >= xDomain[0] && x <= xDomain[1] && y >= yDomain[0] && y <= yDomain[1]
    );
  }
  getPlotterValues() {
    const { moduleId, values } = this.props;
    const data = getPlotterValues({
      moduleId,
      values: getNestedData(values, `plotterData`),
    });
    return data;
  }
  getPresentationPlotterValues() {
    const { moduleId } = this.props;
    const userResponses =
      getNestedData(this.props, 'values.userResponses') || {};
    const presentationPlotterValues = Object.entries(userResponses).reduce(
      (collector, [responseId, responseData]) => {
        const { userId, userName } = responseData;
        const piUserName = getPersonalInfoName({ data: responseData });
        const nameToUse =
          piUserName || (userName === 'Guest' ? userId : userName);
        const plotterValues =
          getPlotterValues({
            moduleId,
            values: getNestedData(responseData, `answerData`),
          }) || [];
        const explanation = getNestedData(
          responseData,
          `answerData.${moduleId}.explanation`
        );
        const plotterValuesWithUserId = appendUserIdAndName({
          array: plotterValues,
          userId,
          userName: nameToUse,
          responseId,
          explanation,
        });
        const xyCollector = [...collector, ...plotterValuesWithUserId];
        return xyCollector;
      },
      []
    );
    return presentationPlotterValues;
  }
  getAveragePoint() {
    const { inPresentationMode } = this.props;
    const plotterValues = this.getPresentationPlotterValues();
    const plotterTotalsX = plotterValues.reduce(
      (xcollect, pointValue) => xcollect + pointValue.x,
      0
    );
    const plotterTotalsY = plotterValues.reduce(
      (ycollect, pointValue) => ycollect + pointValue.y,
      0
    );
    const responseCount = plotterValues.length;
    const averageX = plotterTotalsX / responseCount;
    const averageY = plotterTotalsY / responseCount;
    const averagePoint = inPresentationMode
      ? {
          x: averageX,
          y: averageY,
          type: AVERAGE_POINT,
        }
      : null;
    return averagePoint;
  }
  getCurrentPoint() {
    const { questionData = {} } = this.props;
    const v2CurrentY = questionData[Y_CURRENT];
    const currentPoint = v2CurrentY
      ? {
          [X]: 'Current',
          [Y]: parseFloat(v2CurrentY, 10),
          type: CURRENT_POINT,
        }
      : null;
    return currentPoint;
  }
  getXYPlotterValues() {
    const plotterValues = this.getExtendedPlotterValues();
    const { inPresentationMode } = this.props;
    if (inPresentationMode) return plotterValues;
    // if in preview or survey or response mode, fill up the rest of the years with gray placeholder boxes
    const options = this.getXOptions();
    const yDomain = this.getYDomain();
    const [start] = yDomain;
    const y = start;
    const placeholders = options.map((xOption) => {
      return {
        x: xOption,
        y,
        type: PLACEHOLDER_BAR,
      };
    });
    const newPlotterValues = [...plotterValues, ...placeholders];
    let seen = {};
    const filteredPlotterValues = newPlotterValues.filter((p) => {
      const { [X]: x } = p;
      if (seen[x]) return false;
      seen[x] = true;
      return true;
    });
    return filteredPlotterValues;
  }
  getExtendedPlotterValues() {
    const { inPresentationMode } = this.props;
    const currentPoint = this.getCurrentPoint();
    const averagePoint = this.getAveragePoint();
    const data = this.getPlotterValues();
    const presentationData = this.getPresentationPlotterValues();
    const valuesToUse = inPresentationMode ? presentationData : data;
    const extendedData = [currentPoint, averagePoint, ...valuesToUse].filter(
      (x) => x
    );
    if (this.props.moduleType === PLOTTER_XY) {
      return extendedData.map((point) => {
        return {
          ...point,
          [X]: `${point[X]}`,
        };
      });
    }
    return extendedData;
  }
  uniq = (a) => {
    var seen = {};
    return a.filter(function(item) {
      return seen.hasOwnProperty(item) ? false : (seen[item] = true);
    });
  };
  addPointXY = ({ point }) => {
    const { moduleId, values, setValues } = this.props;
    const existingValues = this.getPlotterValues();
    const newPlotterValues = [point, ...existingValues];
    let seen = {};
    const filteredPlotterValues = newPlotterValues.filter((p) => {
      const { [X]: x } = p;
      if (seen[x]) return false;
      seen[x] = true;
      return true;
    });
    const plotterData = {
      ...values.plotterData,
      [moduleId]: {
        [PLOTTER_VALUES]: filteredPlotterValues,
      },
    };
    setValues({
      ...values,
      plotterData,
    });
  };
  addPoint = ({ point }) => {
    const {
      moduleId,
      moduleType,
      values,
      setValues,
      inPresentationMode,
    } = this.props;
    if (inPresentationMode) return;
    if (moduleType === PLOTTER_XY) return this.addPointXY({ point });
    const existingValues = this.getPlotterValues();
    const newPlotterValues = [...existingValues, point];
    if (newPlotterValues.length > 1) {
      return this.reset();
    }
    const plotterData = {
      ...values.plotterData,
      [moduleId]: {
        [PLOTTER_VALUES]: newPlotterValues,
      },
    };
    setValues({
      ...values,
      plotterData,
    });
  };
  reset = () => {
    const { moduleId, values, setValues } = this.props;
    const plotterData = {
      ...values.plotterData,
      [moduleId]: {
        [PLOTTER_VALUES]: [],
      },
    };
    setValues({
      ...values,
      plotterData,
    });
  };
  renderXYAxis() {
    if (this.props.moduleType !== PLOTTER_XY) return;
    const { yAxisLabel, xAxisLabel } = this.props.questionData;
    return [
      <VictoryAxis
        dependentAxis
        label={yAxisLabel}
        axisLabelComponent={
          <VictoryLabel key="yLabel" x={20} y={20} angle={0} />
        }
        style={axisStyleXY}
        key="yAxis"
      />,
      <VictoryAxis
        label={xAxisLabel}
        axisLabelComponent={
          <VictoryLabel
            key="xLabel"
            x={CHART_WIDTH / 2 + moduleTypePadding[PLOTTER_XY].left / 2}
            y={CHART_HEIGHT - 20}
          />
        }
        style={axisStyleXY_X}
        key="xAxis"
      />,
    ];
  }
  render22Labels() {
    if (this.props.moduleType !== PLOTTER_22) return;
    const {
      positiveXLabel,
      negativeXLabel,
      positiveYLabel,
      negativeYLabel,
    } = this.props.questionData;
    const chunkify = (label = '', CHUNKIFY_LIMIT = 15) => {
      return label.split(' ').reduce((collector, word) => {
        const [currentLine = '', ...otherLinesReversed] = [
          ...collector,
        ].reverse();
        const otherLines = otherLinesReversed.reverse();
        const newLine = currentLine ? `${currentLine} ${word}` : word;
        if (newLine.length > CHUNKIFY_LIMIT) {
          return [...otherLines, currentLine, word].filter((x) => x);
        }
        return [...otherLines, newLine];
      }, []);
    };
    const chunkyPx = chunkify(positiveXLabel);
    const chunkyNx = chunkify(negativeXLabel);
    const chunkyPy = chunkify(positiveYLabel, 30);
    const chunkyNy = chunkify(negativeYLabel, 30);
    const HALF_CHART = CHART_HEIGHT / 2;
    const pxOff = 10 * chunkyPx.length;
    const nxOff = 10 * chunkyNx.length;
    const pxy = HALF_CHART + pxOff;
    const nxy = HALF_CHART + nxOff;
    const pyy = 10 * chunkyPy.length;
    const nyOff = 10 * chunkyNy.length;
    const nyy = CHART_HEIGHT - nyOff;
    return [
      <VictoryLabel
        text={chunkyPx}
        style={{ ...axisStyle.axisLabel, textAnchor: 'end' }}
        x={CHART_WIDTH}
        y={pxy}
        key="positiveXLabel"
        name="positiveXLabel"
        standalone
        width={CHART_WIDTH / 4}
        renderInPortal
      />,
      <VictoryLabel
        text={chunkyNx}
        style={axisStyle.axisLabel}
        x={10}
        y={nxy}
        key="negativeXLabel"
        name="negativeXLabel"
        standalone
        width={CHART_WIDTH / 4}
        renderInPortal
      />,
      <VictoryLabel
        text={chunkyPy}
        style={{ ...axisStyle.axisLabel, textAnchor: 'middle' }}
        x={CHART_WIDTH / 2}
        y={pyy}
        key="positiveYLabel"
        name="positiveYLabel"
        standalone={true}
      />,
      <VictoryLabel
        text={chunkyNy}
        style={{ ...axisStyle.axisLabel, textAnchor: 'middle' }}
        x={CHART_WIDTH / 2}
        y={nyy}
        key="negativeYLabel"
        name="negativeYLabel"
        standalone={true}
      />,
    ];
  }
  render22Axis() {
    if (this.props.moduleType !== PLOTTER_22) return;
    return [
      <VictoryAxis
        dependentAxis
        style={axisStyle}
        tickFormat={() => ''}
        key="yAxis22"
      />,
      <VictoryAxis style={axisStyle} tickFormat={() => ''} key="xAxis22" />,
      ...this.render22Labels(),
    ];
  }
  getScatterLabels = (d) => {
    return `${d[X].toLocaleString()}\n ${d[Y].toLocaleString()}`;
  };
  getScatterTooltip = () => {
    if (this.props.moduleType === PLOTTER_22)
      return <VictoryTooltip active={false} />;
    return <VictoryTooltip />;
  };
  getXDomain() {
    const { questionData, moduleType } = this.props;
    const { xDomain } = questionData;
    const xDomainArray = stringToRangeArray(xDomain, moduleType, 1);
    const [start, end] = xDomainArray;
    if (start > end) return [end, start];
    return xDomainArray;
  }
  getYDomain() {
    const { questionData, moduleType } = this.props;
    const { yDomain } = questionData;
    const yDomainArray = stringToRangeArray(yDomain, moduleType);
    return yDomainArray;
  }
  getXLabel() {
    const { questionData } = this.props;
    const { xAxisLabel } = questionData;
    return xAxisLabel || '';
  }
  getYLabel() {
    const { questionData } = this.props;
    const { yAxisLabel } = questionData;
    return yAxisLabel || '';
  }
  render22PresentationLabels() {
    const { moduleType, inPresentationMode } = this.props;
    if (moduleType !== PLOTTER_22 || !inPresentationMode) return;
    const presentationData = this.getPresentationPlotterValues();
    const quadrants = presentationData.reduce(
      (collector, point) => {
        const { x, y } = point;
        const np = x > 0 || y < 0 ? 0 : x === 0 || y === 0 ? 0.5 : 1;
        const pp = x < 0 || y < 0 ? 0 : x === 0 || y === 0 ? 0.5 : 1;
        const pn = x < 0 || y > 0 ? 0 : x === 0 || y === 0 ? 0.5 : 1;
        const nn = x > 0 || y > 0 ? 0 : x === 0 || y === 0 ? 0.5 : 1;
        return {
          ...collector,
          [QUADRANT_NP]: collector[QUADRANT_NP] + np,
          [QUADRANT_PP]: collector[QUADRANT_PP] + pp,
          [QUADRANT_PN]: collector[QUADRANT_PN] + pn,
          [QUADRANT_NN]: collector[QUADRANT_NN] + nn,
        };
      },
      {
        [QUADRANT_NP]: 0,
        [QUADRANT_PP]: 0,
        [QUADRANT_PN]: 0,
        [QUADRANT_NN]: 0,
      }
    );
    const labels = Object.entries(quadrants).map(([quadrant, count]) => {
      const x = [QUADRANT_PN, QUADRANT_PP].includes(quadrant)
        ? CHART_WIDTH - 20
        : 20;
      const y = [QUADRANT_PN, QUADRANT_NN].includes(quadrant)
        ? CHART_HEIGHT - 20
        : 20;
      const textAnchor = [QUADRANT_PN, QUADRANT_PP].includes(quadrant)
        ? 'end'
        : 'start';
      return (
        <VictoryLabel
          text={count}
          style={{ ...quadrantLabelStyle, textAnchor }}
          x={x}
          y={y}
          key={quadrant}
          name={quadrant}
          standalone={true}
        />
      );
    });
    return labels;
  }
  renderClickedResponses() {
    return null;
    const { inPresentationMode } = this.props;
    if (!inPresentationMode) return;
    const presentationData = this.getPresentationPlotterValues();
    const responses = this.state.selectedResponses.map((responseId) => {
      const response = presentationData.find(
        (response) => response.responseId === responseId
      );
      const { userName, explanation = '' } = response;
      return (
        <ResponseRow key={responseId}>
          <ParticipantName title={responseId}>{userName}</ParticipantName>
          <ResponseMessage>{explanation}</ResponseMessage>
        </ResponseRow>
      );
    });
    return (
      <ResponsesContainer>
        <ResponsesTitle>Responses</ResponsesTitle>
        <ResponseRowContainer>
          <ResponseDelete onClick={this.onResetResponses} />
          {responses}
        </ResponseRowContainer>
      </ResponsesContainer>
    );
  }
  onPointClick = ({ x, y, datum, origin }) => {
    const { responseId } = datum;
    const selectedResponses = [
      ...new Set([...this.state.selectedResponses, responseId]),
    ];
    this.setState({
      selectedResponses,
    });
  };
  onResetResponses = () => {
    const selectedResponses = [];
    this.setState({
      selectedResponses,
    });
  };
  formatLabel = (d) => {
    const labelString = `${this.getXLabel()}: ${d.x.toLocaleString()}\n ${this.getYLabel()}: ${d.y.toLocaleString()}`;
    const label = [d.type, d.responseId, labelString]
      .filter((x) => x)
      .join(`\n`);
    return label;
  };
  getBarWidth() {
    const xDomainArray = this.getXDomain();
    const [start, end] = xDomainArray;
    const chunks = end - start + (this.getCurrentPoint() ? 1 : 0);
    const chunksAddition = chunks * 20;
    const DIVISION_WIDTH = window.innerWidth < 834 ? 140 : 200 + chunksAddition;
    const width = DIVISION_WIDTH / chunks;
    return width;
  }
  getXOptions() {
    const xDomainArray = this.getXDomain();
    const [start, end] = xDomainArray;
    let categories = [];
    for (let i = start; i <= end; i++) {
      categories.push(`${i}`);
    }
    return categories;
  }
  getXCategories() {
    const categories = this.getXOptions();
    if (!this.getCurrentPoint()) return categories;
    return ['Current', ...categories];
  }
  renderXYChart() {
    if (this.props.moduleType !== PLOTTER_XY) return;
    const data = this.getXYPlotterValues();
    const x = this.getXCategories();
    return (
      <VictoryBar
        data={data}
        labels={this.formatLabel}
        labelComponent={this.getScatterTooltip()}
        categories={{ x }}
        barRatio={0.9}
        style={{
          data: {
            fill: (d) => {
              return d.type === PLACEHOLDER_BAR ? '#C4C4C4' : '#1CA4FC';
            },
            //width
          },
        }}
      />
    );
  }
  render22Chart() {
    if (this.props.moduleType !== PLOTTER_22) return;
    const data = this.getExtendedPlotterValues();
    const labelComponent = this.getScatterTooltip();
    const labelSpread = false
      ? { labelComponent, labels: this.formatLabel }
      : {};
    return (
      <VictoryScatter
        size={8}
        bubbleProperty="size"
        minBubbleSize={8}
        maxBubbleSize={16}
        data={data}
        dataComponent={<VariablePoint onPointClick={this.onPointClick} />}
        {...labelSpread}
      />
    );
  }
  victoryOnClick = (evt, props) => {
    const { x, y } = Selection.getSVGEventCoordinates(evt);
    const newX = props.scale.x.invert(x);
    const newY = props.scale.y.invert(y);
    const roundX = this.props.round ? Math.round(newX) : newX;
    const roundY = this.props.round ? Math.round(newY) : newY;
    let shiftedX = roundX;
    if (this.props.moduleType === PLOTTER_XY) {
      const xDomainArray = this.getXDomain();
      const [start] = xDomainArray;
      shiftedX = this.getCurrentPoint()
        ? roundX - 2 + start
        : roundX - 1 + start;
    }
    const point = {
      [X]: shiftedX,
      [Y]: roundY,
    };
    if (this.withinDomain(point)) {
      this.addPoint({ point });
    }
  };
  getDomain(options = {}) {
    const { moduleType, inPresentationMode } = this.props;
    const { offsetCurrentPoint = true } = options;
    if (moduleType === PLOTTER_XY) {
      const xDomainArray = this.getXDomain();
      const [start, end] = xDomainArray;
      const length = end - start;
      const currentOffset =
        this.getCurrentPoint() && offsetCurrentPoint ? 1 : 0;
      const newXDomain = inPresentationMode
        ? [1, length + currentOffset + 2.5]
        : [0, length + currentOffset + 1.5];
      return {
        x: newXDomain,
        y: this.getYDomain(),
      };
    }
    return {
      x: this.getXDomain(),
      y: this.getYDomain(),
    };
  }
  victoryOnMouseMove = (evt, props) => {
    if (this.props.moduleType !== PLOTTER_XY) return;
    const mouseDown = evt.buttons;
    if (mouseDown !== 1) return;
    const { x, y } = Selection.getSVGEventCoordinates(evt);
    const newX = props.scale.x.invert(x);
    const newY = props.scale.y.invert(y);
    const roundX = this.props.round ? Math.round(newX) : newX;
    const roundY = this.props.round ? Math.round(newY) : newY;
    let shiftedX = roundX;
    if (this.props.moduleType === PLOTTER_XY) {
      const xDomainArray = this.getXDomain();
      const [start] = xDomainArray;
      shiftedX = this.getCurrentPoint()
        ? roundX - 2 + start
        : roundX - 1 + start;
    }
    const point = {
      [X]: shiftedX,
      [Y]: roundY,
    };
    if (this.withinDomain(point)) {
      this.addPointXY({ point });
    }
  };
  getPresentationData() {
    const { moduleId, questionData } = this.props;
    const userResponses =
      getNestedData(this.props, 'values.userResponses') || {};
    const xOptions = this.getXOptions(); // [ '1', '2', ... ]
    const responsesPerOption = xOptions.reduce((collector, key) => {
      const userResponsesForOption = Object.entries(userResponses).reduce(
        (collector2, [responseId, responseData]) => {
          const plotterValues =
            getPlotterValues({
              moduleId,
              values: getNestedData(responseData, `answerData`),
            }) || [];
          const point = plotterValues.find(({ x, y }) => {
            return key === `${x}`;
          });
          // don't count blank entries
          //if (!point) return collector2;
          // count blank entries as 0
          const y = (point && point.y) || 0;
          return {
            ...collector2,
            [responseId]: y,
          };
        },
        {}
      );
      return {
        ...collector,
        [key]: {
          ...collector[key],
          ...userResponsesForOption,
        },
      };
    }, {});
    const presentationData = Object.entries(responsesPerOption).reduce(
      (collector, [optionKey, userResponsesHash]) => {
        const userResponseArray = Object.values(userResponsesHash);
        const average =
          userResponseArray.reduce((av, points) => {
            return av + points;
          }, 0) / (userResponseArray.length || 1);
        const median = getMedian(userResponseArray);
        const minimum =
          userResponseArray.reduce((min, points) => {
            return min === void 0 || points < min ? points : min;
          }, void 0) || 0;
        const maximum =
          userResponseArray.reduce((min, points) => {
            return min === void 0 || points > min ? points : min;
          }, void 0) || 0;
        const range = maximum - minimum;
        // https://www.mathsisfun.com/data/standard-deviation-formulas.html
        const sdStep2 = userResponseArray.map((points) => {
          const step2 = Math.pow(points - average, 2);
          return step2;
        });
        const sdStep3 = sdStep2.reduce((sd3, step2) => {
          return sd3 + step2;
        }, 0);
        const sdStep4 = sdStep3 / (sdStep2.length || 1);
        const sd = Math.pow(sdStep4, 0.5);
        const optionText = questionData[optionKey];
        const presentationHash = {
          average,
          median,
          minimum,
          maximum,
          range,
          sd,
          optionText,
          userResponseArray,
        };
        // todo: remove
        if (userResponseArray.length === 0) return collector;
        return {
          ...collector,
          [optionKey]: presentationHash,
        };
      },
      {}
    );
    const presentationWithRank = Object.entries(presentationData).map(
      ([optionKey, presentationHash], i) => {
        const optionInt = parseInt(optionKey, 10);
        return {
          ...presentationHash,
          optionKey,
          optionInt,
          rank: i + 1,
        };
      }
    );
    return presentationWithRank;
  }
  renderRankCharts() {
    const presentationData = this.getPresentationData();
    const domain = this.getDomain();
    const current = this.getCurrentPoint() ? ['Current'] : [];
    const tickValues = ['', ...current, ...this.getXOptions()];
    const boxPlotData = presentationData.map((pHash) => {
      return { x: `${pHash.optionInt}`, y: pHash.userResponseArray, ...pHash };
    });
    const currentPoint = this.getCurrentPoint()
      ? [
          {
            x: `Current`,
            y: [this.getCurrentPoint().y || 0],
          },
        ]
      : [];
    const boxPlotDataWithCurrent = [...currentPoint, ...boxPlotData];
    if (boxPlotData.length === 0) return null;
    return (
      <ChartContainer>
        <VictoryChart domain={domain} width={CHART_WIDTH} height={CHART_HEIGHT}>
          <VictoryAxis
            dependentAxis
            label={this.getYLabel()}
            axisLabelComponent={
              <VictoryLabel key="yLabel" x={10} y={30} angle={0} />
            }
            style={axisStyleXY}
          />
          <VictoryAxis
            label={this.getXLabel()}
            axisLabelComponent={<VictoryLabel key="xLabel" x={10} y={405} />}
            style={axisStyleXY}
            tickValues={tickValues}
          />
          <VictoryBoxPlot
            data={boxPlotDataWithCurrent}
            sortKey="rank"
            q1Component={<Box style={{ fill: '#1CA4FC' }} />}
            medianComponent={
              <LineSegment style={{ stroke: '#FF9B3A', strokeWidth: 3 }} />
            }
            q3Component={<Box style={{ fill: '#1CA4FC' }} />}
          />
        </VictoryChart>
      </ChartContainer>
    );
  }
  renderXYPresentation() {
    const { inPresentationMode } = this.props;
    if (!inPresentationMode) return null;
    const presentationData = this.getPresentationData();
    const body = presentationData.map((pHash) => {
      return (
        <PresentationRow key={`row${pHash.optionKey}`}>
          <PresentationCell>{pHash.optionInt}</PresentationCell>
          <PresentationCell textAlign="center">
            {pHash.average.toFixed(2)}
          </PresentationCell>
          <PresentationCell textAlign="center">
            {pHash.median.toFixed(2)}
          </PresentationCell>
          <PresentationCell textAlign="center">
            {pHash.minimum}
          </PresentationCell>
          <PresentationCell textAlign="center">
            {pHash.maximum}
          </PresentationCell>
          <PresentationCell textAlign="center">{pHash.range}</PresentationCell>
          <PresentationCell textAlign="center">
            {pHash.sd.toFixed(2)}
          </PresentationCell>
        </PresentationRow>
      );
    });
    return (
      <PresentationTableContainer>
        <PresentationTable>
          <thead>
            <PresentationTitleRow>
              <PresentationCell background="transparent" width="5.9375rem">
                {this.getXLabel()}
              </PresentationCell>
              <PresentationCell background="transparent" textAlign="center">
                Mean
              </PresentationCell>
              <PresentationCell background="transparent" textAlign="center">
                Median
              </PresentationCell>
              <PresentationCell background="transparent" textAlign="center">
                Minimum
              </PresentationCell>
              <PresentationCell background="transparent" textAlign="center">
                Maximum
              </PresentationCell>
              <PresentationCell background="transparent" textAlign="center">
                Range
              </PresentationCell>
              <PresentationCell background="transparent" textAlign="center">
                Std. Dev
              </PresentationCell>
            </PresentationTitleRow>
          </thead>
          <PresentationBody>{body}</PresentationBody>
        </PresentationTable>
        {this.renderRankCharts()}
      </PresentationTableContainer>
    );
  }
  render() {
    const { inMobileView } = this.props;
    if (this.props.moduleType === PLOTTER_XY && this.props.inPresentationMode)
      return this.renderXYPresentation();
    const padding = moduleTypePadding[this.props.moduleType];
    return (
      <PlotterAndResponses>
        <PlotterContainer {...{ inMobileView }}>
          <div>
            <VictoryChart
              domain={this.getDomain()}
              padding={padding}
              height={CHART_HEIGHT}
              width={CHART_WIDTH}
              events={[
                {
                  target: 'parent',
                  eventHandlers: {
                    onClick: this.victoryOnClick,
                    onMouseMove: this.victoryOnMouseMove,
                  },
                },
              ]}
            >
              {this.renderXYAxis()}
              {this.render22Axis()}
              {this.renderXYChart()}
              {this.render22Chart()}
              {this.render22PresentationLabels()}
            </VictoryChart>
          </div>
        </PlotterContainer>
        {this.renderClickedResponses()}
      </PlotterAndResponses>
    );
  }
}
