import React from "react";
import ReactGA from "react-ga";

export default class GoogleAnalyticsTracker extends React.Component {
  onPageChange(path, search = "") {
    const page = `${path}${search}`;
    const { location } = window;
    ReactGA.set({
      page,
      location: `${location.origin}${page}`,
      ...this.props.options
    });
    ReactGA.pageview(page);
  }

  componentDidMount() {
    const { pathname, search } = this.props.location;
    this.onPageChange(pathname, search);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { location: prevLocation } = prevProps;
    const { pathname, search } = this.props.location;
    const isDifferentPathname = pathname !== prevLocation.pathname;
    const isDifferentSearch = search !== prevLocation.search;
    if (isDifferentPathname || isDifferentSearch) {
      this.onPageChange(pathname, search);
    }
  }

  render() {
    return null;
  }
}

