import React from "react";
import styled from "styled-components";
import { RefreshSvg, CrossSvg } from "./svg";
import {
  TEXT_SLIDE,
  COMPARISON,
  WAITING_ROOM,
} from "../../../const/module-types";

const Container = styled("div")`
  width: 600px;
  height: 600px;
  background-color: #ffffff;
  overflow-y: hidden;
  padding-right: 15px;
  margin-left: 25px;
`;

const Icons = styled("div")`
  display: flex;
  justify-content: flex-end;
  padding: 15px;
`;

const RefreshIcon = styled("div")`
  cursor: pointer;
  padding-top: 5px;
`;

const CrossIcon = styled("div")`
  cursor: pointer;
  padding-top: 1px;
`;

const HeadingContainer = styled("div")`
  margin-bottom: 2em;
`;

const ResponsesContainer = styled("div")`
  height: 420px;
  overflow-y: auto;
`;

const Bottom = styled("div")`
  margin-top: 15px;
`;

const getQuestionColor = moduleType => {
  let color = "#1CA4FC";
  if (moduleType === WAITING_ROOM) {
    color = "#FF7B3C";
  } else if (moduleType === COMPARISON) {
    color = "#6A36FF";
  }
  return color;
};

const RowElement = styled("div")`
  display: flex;
  padding-left: 50px;
  padding-right: 25px;
  justify-content: space-between;
`;

const Row = styled(RowElement)`
  color: ${props => getQuestionColor(props.moduleType)};
`;

const QuestionsRow = styled(RowElement)`
  color: #000000;
`;

const Title = styled("div")`
  font-family: Roboto, sans-serif;
  font-weight: 700;
  font-size: 20px;
`;

const Question = styled("div")`
  flex-basis: 21em;
  margin-top: ${props => (props.IsFirstQn ? "0px" : "2em")};
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 29px;
`;

const Response = styled("div")`
  margin-top: ${props => (props.IsFirstQn ? "0px" : "1.5em")};
  font-family: Roboto, sans-serif;
  font-weight: 700;
  font-size: 25px;
  line-height: 29px;
  text-align: center;
  width: 3em;
`;

const getModuleResponsesCount = ({ userResponses, moduleId }) => {
  const responses = Object.values(userResponses);
  return responses.reduce((acc, response) => {
    const answerData = response.answerData;
    const moduleResponse = answerData[moduleId];
    if (!moduleResponse) return acc;
    return acc + 1;
  }, 0);
};

const getQuestionsAndResponseCount = (moduleList = {}, userResponses = {}) => {
  if (moduleList && Object.keys(moduleList).length === 0) return [];

  return Object.values(moduleList)
    .filter(module => !module.deleted)
    .map((module, index) => {
      const type = module.moduleType;
      const id = module.moduleId;
      let responseCount;

      if (type === COMPARISON || type === WAITING_ROOM || type === TEXT_SLIDE) {
        responseCount = "N/A";
      } else {
        responseCount = getModuleResponsesCount({
          userResponses,
          moduleId: id
        });
      }

      return {
        id,
        question: ` ${index + 1}. ${module.question || "Comparison Module"}`,
        type,
        responseCount
      };
    });
};

const WaitingRoom = ({
  onClickCloseIcon,
  onClickRefreshIcon,
  moduleList,
  userResponses,
}) => {
  return (
    <Container>
      <Icons>
        <RefreshIcon onClick={onClickRefreshIcon}>
          <RefreshSvg />
        </RefreshIcon>
        <CrossIcon onClick={onClickCloseIcon}>
          <CrossSvg />
        </CrossIcon>
      </Icons>

      <HeadingContainer>
        <QuestionsRow>
          <Title>Questions</Title>
          <Title>Responses</Title>
        </QuestionsRow>
      </HeadingContainer>

      <ResponsesContainer>
        {getQuestionsAndResponseCount(moduleList, userResponses).map(
          (module, index) => {
            return (
              <Row key={module.id} moduleType={module.type}>
                <Question IsFirstQn={index === 0}>{module.question}</Question>
                <Response IsFirstQn={index === 0}>
                  {module.responseCount}
                </Response>
              </Row>
            );
          }
        )}
      </ResponsesContainer>
      <Bottom />
    </Container>
  );
};

export default WaitingRoom;
