import React, { useState, useMemo } from 'react';
import { ReactComponent as IconEdit } from '../../../img/edit-plain.svg';
import { ReactComponent as SelectedCheck } from '../../../img/selected_item_check.svg';
import { ReactComponent as IconExpand } from '../../../img/ic_expand.svg';
import { ButtonBase } from '../../buttons';
import CommentInput from './CommentInput';

import { useWindowSize } from '../../utils/useWindowSize';
import { screen, device } from '../../../utils/deviceBreakpoints';

import Tracking from 'lib/tracking/tracking';
import { surveyTakerEvents } from 'lib/tracking/events';
import { MULTI_SELECT } from 'const/module-types';

const styles = {
  container: {
    width: '180px',
    height: '180px',
    position: 'relative',
    [`@media ${device.tablet}`]: {
      width: '150px',
      height: '150px',
    },
  },
  contentContainer: ({ active, imageUrl }) => ({
    width: '100%',
    height: '100%',
    boxShadow: active
      ? '0px 0px 13.5px rgba(253, 158, 70, 0.3)'
      : '0px 0px 13.5px rgba(28, 164, 252, 0.25)',
    transform: active ? 'scale(1.1112)' : 'scale(1)',
    borderRadius: '13.5px',
    cursor: 'pointer',
    transition: 'transform 0.15s linear',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    ...(imageUrl
      ? {
          backgroundImage: `url(${imageUrl})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }
      : { background: '#FFF' }),
    [`@media ${device.tablet}`]: {
      boxShadow: '0px 0px 13.5px rgba(253, 158, 70, 0.3)',
      transform: 'scale(1)',
    },
  }),
  title: {
    fontSize: '24px',
    fontFamily: 'Roboto',
    fontWeight: 500,
    color: '#000000',
    padding: '12px 12px 6px 12px',
    width: '100%',
    display: '-webkit-box',
    '-webkit-line-clamp': '4',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    overflowWrap: 'break-word',
  },
  actionBtn: {
    stroke: '#FFF',
    background: '#1CA4FC',
    borderRadius: '99999px',
    width: '40px',
    height: '40px',
    marginRight: '8px',
    [`@media ${device.tablet}`]: {
      width: '28px',
      height: '28px',
    },
  },
  actionBtnIcon: {
    marginTop: '3px',
    [`@media ${device.tablet}`]: {
      width: '15px',
      height: '15px',
    },
  },
  actionBtnsContainer: {
    position: 'absolute',
    bottom: '8px',
    display: 'flex',
    width: '100%',
  },
  commentContainer: ({ active }) => ({
    height: '120px',
    position: 'absolute',
    bottom: active ? `${-135 * 1.1112}px` : '-130px',
    left: 0,
    right: 0,
    marginTop: '1rem',
    transform: active ? 'scale(1.1112)' : 'scale(1)',
    transition: 'transform 0.15s linear',
    zIndex: 400,
    [`@media ${device.tablet}`]: {
      display: 'none',
    },
  }),
  selectedCheck: {
    width: '55px',
    height: '55px',
    position: 'absolute',
    top: 0,
    right: 0,
  },
  contentOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(0, 0, 0, 0.5)',
    borderRadius: '13.5px',
    zIndex: -1,
  },
};

export default function OptionItem({
  questionData,
  data,
  allowComment,
  commentInputIsActive,
  onClick,
  onClickExpand,
  onClickEditComment,
  onClickSaveComment,
  onClickCancelComment,
}) {
  const windowSize = useWindowSize();
  const [desktopVpHovered, setDesktopHovered] = useState(false);

  const { selected, comment = '' } = data || {};
  const imageUrl = useMemo(
    () =>
      questionData.file
        ? URL.createObjectURL(questionData.file)
        : questionData.imageUrl,
    [questionData.file, questionData.imageUrl]
  );

  const isMobileVp = windowSize.width < screen.tablet;
  const hovered = desktopVpHovered || isMobileVp;
  const active = selected || hovered;

  const saveComment = (value) => {
    Tracking.event({
      name: surveyTakerEvents.MODULE_EXPLANATION_TEXT,
      properties: { moduleType: MULTI_SELECT, moduleExplanation: value },
    });
    onClickSaveComment(value);
  };
  return (
    <div css={styles.container}>
      <div
        css={[styles.contentContainer({ active, imageUrl })]}
        onClick={onClick}
        onMouseEnter={() => {
          if (!isMobileVp) setDesktopHovered(true);
        }}
        onMouseLeave={() => {
          if (!isMobileVp) setDesktopHovered(false);
        }}
      >
        {!!imageUrl && hovered && <div css={styles.contentOverlay} />}
        {!!imageUrl && !hovered ? null : (
          <p css={[styles.title, { color: !imageUrl ? '#000' : '#FFF' }]}>
            {questionData.title}
          </p>
        )}
        {selected && <SelectedCheck css={styles.selectedCheck} />}
        <div css={styles.actionBtnsContainer}>
          {imageUrl && active && (
            <ButtonBase
              css={[styles.actionBtn, { marginLeft: 'auto' }]}
              onClick={(e) => {
                e.stopPropagation();
                onClickExpand();
              }}
            >
              <IconExpand css={styles.actionBtnIcon} />
            </ButtonBase>
          )}
          {allowComment && (commentInputIsActive || active || comment) && (
            <ButtonBase
              onClick={(e) => {
                e.stopPropagation();
                onClickEditComment();
              }}
              css={[
                styles.actionBtn,
                {
                  background: commentInputIsActive ? '#FD9E46' : '#1CA4FC',
                  marginLeft: !(imageUrl && active) ? 'auto' : 0, // apply "auto" only if expand button is visible
                },
              ]}
            >
              <IconEdit css={styles.actionBtnIcon} />
            </ButtonBase>
          )}
        </div>
      </div>
      {allowComment && commentInputIsActive && (
        <CommentInput
          defaultValue={comment}
          className={styles.commentContainer({ active })}
          onClickCancel={() => onClickCancelComment()}
          onClickSave={(value) => saveComment(value)}
        />
      )}
    </div>
  );
}
