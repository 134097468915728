import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styles } from '../AddOptions/styles';
import { ButtonBase } from 'components/buttons';
import StackRankGroupView from './StackRankGroupView';
import StackRankChartDetail from './StackRankChartDetail';
import { setStackRankChartData } from 'pages/presentationSlice';

const StackRank = ({ moduleId, mode, comparisonSection }) => {
  const dispatch = useDispatch();

  const stackRankData = useSelector((state) => state.presentation[moduleId]);
  const [showGroup, setShowGroup] = useState(true);
  const [showIndividual, setShowIndividual] = useState(false);

  const { chartData, individualChartData, showIndividualView } = stackRankData;

  useEffect(() => {
    if (showIndividualView) {
      setShowIndividual(true);
      setShowGroup(false);
    }
  }, [showIndividualView]);

  const handleGroupClick = () => {
    setShowGroup(true);
    setShowIndividual(false);
    dispatch(setStackRankChartData({ moduleId, showIndividualView: false }));
  };

  const handleIndvCLick = () => {
    setShowGroup(false);
    setShowIndividual(true);
  };

  return (
    <>
      <div css={[styles.container]}>
        <div css={[styles.topGrpBtn]}>
          <ButtonBase
            css={styles.actionBtn}
            style={{
              padding: '6px 35px',
              color: showGroup ? '#fff' : '#1ca4fc',
              backgroundColor: showGroup ? '#1ca4fc' : '#fff',
            }}
            onClick={handleGroupClick}
          >
            Group
          </ButtonBase>
          <ButtonBase
            css={styles.actionBtn}
            style={{
              padding: '6px 35px',
              color: showIndividual ? '#fff' : '#1ca4fc',
              backgroundColor: showIndividual ? '#1ca4fc' : '#fff',
            }}
            active={showIndividual}
            onClick={handleIndvCLick}
          >
            Individual
          </ButtonBase>
        </div>
      </div>
      <div>
        {showGroup && <StackRankGroupView data={chartData} />}
        {showIndividual && (
          <StackRankChartDetail
            userData={individualChartData}
            mode={mode}
            comparisonSection={comparisonSection}
            moduleId={moduleId}
          />
        )}
      </div>
    </>
  );
};

export default StackRank;
