import styled from "styled-components";

import { Button2 } from "../../layout";
import PreviewEyeImg from "img/preview-eye.svg";

export const PopulatePreviewBanner = styled('div')`
  width: 100%;
  height: 71px;
  background: #B0E0FF;
`;

export const PopulatePreviewText = styled('div')`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: -0.01em;
  color: #1CA4FC;
  text-align: center;
  padding-top: 25px;
`;

export const PreviewContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 21px 50px;
  background: white;
  height: 90px;
`;

export const PreviewSection = styled("div")`
  min-width: 155px;
  text-align: center;
`;

export const PreviewRow = styled("div")`
  display: flex;
`;

export const SurveyCode = styled("strong")`
  user-select: all;
`;

export const PreviewTab = styled("div")`
  height: 45px;
  margin: 0 4px;
  position: relative;
  cursor: pointer;
  background: url('${({ icon }) => icon}') center 26px / auto no-repeat;
  width: ${props => props.width || "auto"};
  text-align: center;
  text-decoration: none;
  color: #262525;
  &:hover {
    ${({ hoverBold }) => (hoverBold ? `font-weight: bold;` : ``)}
    ${({ hoverIcon }) =>
      hoverIcon ? `background-image: url('${hoverIcon}');` : ``}
    ${({ hoverColor }) => (hoverColor ? `color: ${hoverColor};` : ``)}
  }
  ${({ active }) =>
    active
      ? `
  font-weight: bold;
  background: url('${PreviewEyeImg}') center 26px / auto no-repeat;
  &:hover {
    color: #262525;
  }
  &:before {
    content: ' ';
    border-bottom: 2px solid #1CA4FC;
    top: 21px;
    left: 0;
    width: 100%;
    position: absolute;
  }
  `
      : ``}
`;

export const ToggleButton = styled(Button2)`
  &:hover {
    color: white;
    background: #1ca4fc;
    border-color: #1ca4fc;
  }
`;
