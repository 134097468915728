import React from "react";

const Line = ({ x1, x2, y1, y2, isX, isY }) => (
  <g>
    <defs>
      <marker
        id="arrow"
        viewBox="0 0 15 15"
        refX="9"
        refY="5"
        markerWidth="15"
        markerHeight="15"
        fill="none"
        stroke="#000"
        strokeWidth="1"
        orient="auto-start-reverse"
      >
        <path
          d="M 0 0 L 10 5 L 0 10 "
          strokeLinejoin="round"
          strokeLinecap="round"
        />
      </marker>
    </defs>
    <line
      x1={x1}
      y1={y1}
      x2={x2}
      y2={y2}
      stroke="#000"
      strokeWidth="0.5"
      markerStart={isY ? "url(#arrow)" : "none"}
      markerEnd={isX ? "url(#arrow)" : "none"}
    />
  </g>
);

export default Line;
