import React from 'react';
import styled from "styled-components";
import SurveyIcon from 'img/surveyIcon.svg';
import WorkshopIcon from 'img/workshopIcon.svg';

const HiloButtonContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding-left: 20px;
  padding-right: ${props => props.isSurvey ? '60': '30'}px;
  width: 220px;
  height: 60px;
  background: #FFFFFF;
  box-shadow: 0 2px 8px rgba(28, 164, 252, 0.3);
  border-radius: 100px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  color: ${props => props.isSurvey ? '#1CA4FC' : '#FF7B3C' };
`;

const HiloIcon = styled("div")`
  background-image: url('${props => props.isSurvey ? SurveyIcon : WorkshopIcon}');
  background-repeat: no-repeat;
  width: ${props => props.isSurvey ? '42' : '39'}px;
  height: ${props => props.isSurvey ? '34' : '35'}px;
`;

const CreateHiloButton = ({ children, onClick, isSurvey = true }) => {
    return (
        <HiloButtonContainer
            isSurvey={isSurvey}
            onClick={onClick}
        >
            <HiloIcon isSurvey={isSurvey} />
            {children}
        </HiloButtonContainer>
    )
}

export default CreateHiloButton;