import React, { Component } from 'react';
import { Formik } from 'formik';
import get from 'lodash/get';
import Bowser from 'bowser';
import { connect } from 'react-redux';
import { setIsParticipantMode } from '../store/hiloSlice';
import {
  fetchSurveyModulesAndResponses,
  getModuleListListenerReducer,
  sendFeedback,
} from 'lib/survey-api';
import { getIsClosed, getLastSlide, sortModulesToArray } from 'lib/builder-api';
import Layout, {
  Button,
  isEmailValid,
  ModalBody,
  ModalClose,
  ModalOverlay,
  ModalTextarea,
  PageContainer,
} from 'components/layout';
import {
  ADMIN,
  COMPLETE,
  EDIT,
  LANDING,
  REVIEW,
  TEMPLATES_GALLERY,
} from 'const/routes';
import {
  ADD_OPTION,
  COMPARISON,
  MADLIB,
  MATRIX,
  MULTI_SELECT,
  OPEN_ENDED,
  PERSONAL_INFO,
  PLOTTER_22,
  PLOTTER_XY,
  POINT_DISTRIBUTION,
  SPECTRUM_DISTRIBUTION,
  STACK_RANK,
  TEXT_HIGHLIGHT,
  TEXT_SLIDE,
  WAITING_ROOM,
} from 'const/module-types';
import { getNestedData } from 'const/nested-data';
import PointDistribution from 'components/survey-components/PointDistribution';
import StackRank from 'components/survey-components/StackRank';
import Matrix from 'components/survey-components/Matrix';
import PersonalInfo from 'components/survey-components/PersonalInfo';
import SpectrumDistribution from 'components/survey-components/SpectrumDistribution';
import TextHighlightSurvey from 'components/survey-components/TextHighlight';
import Madlib from 'components/survey-components/Madlib';
import Comparison from 'components/survey-components/Comparison';
import WaitingRoom from 'components/survey-components/WaitingRoom';
import OpenEnded from 'components/survey-components/OpenEnded';
import MultiSelect from 'components/survey-components/MultiSelect';
import { AddOptions } from 'components/survey-components/AddOptions';
import Complete, {
  CompleteQuestion,
} from 'components/survey-components/Complete';
import Plotter, {
  ParticipantName,
  ResponseMessage,
  ResponseRow,
  ResponseRowContainer,
  ResponsesContainer,
} from 'components/survey-components/Plotter';
import SurveyBottom from 'components/SurveyBottom';
import SurveyTitle, { Question } from 'components/SurveyTitle';
import { Description as LandingDescription } from 'pages/Landing/styles';
import Review from '../review';
import { EmptyPopulate } from './EmptyPopulate';
import { isPopulatedOptions } from 'components/utils/helpers';
import { filterAndSortStackRank } from 'components/survey-components/StackRank/helpers';
import Tracking from 'lib/tracking/tracking';
import { surveyTakerEvents } from 'lib/tracking/events';
import { db } from '../../firebase';
import { getBackToEdit, getResponseId, getSurveyCode } from 'pages/Survey/lib';
import {
  getSlide,
  LoadingFull,
  NoSurveyFull,
  PlotterContainer,
} from 'pages/Survey/styles';
import {
  EnterEmail,
  SurveyRow,
  FeedbackRecordId,
  SurveyLeftColumn,
  SurveyRightColumn,
  ComparisonContainer,
  SurveyQuestionDataContainer,
  ComparisonSurveyBottomContaier,
} from './styles';

class Survey extends Component {
  modulesListener = null;
  state = {
    moduleList: {},
    surveyDetails: {
      name: ' ',
    },
    surveyLoadError: false,
    loading: true,
    plotterData: {},
    stackRank: {},
    pointDistribution: {},
    textHighlight: {},
    spectrumDistribution: {},
    explanation: {},
    matrix: {},
    personalInfo: {},
    openEnded: {},
    multiSelect: {},
    addOption: {},
    userResponses: {},
    madlib: {},
    startSurveyTimestamp: new Date(),
    startModuleTimestamp: new Date(),
    endModuleTimestamp: new Date(),
    showHelp: false,
    feedbackEmail: '',
    feedbackMessage: '',
    feedbackSaving: false,
    feedbackRecordId: null,
    showExplanations: false,
    sendFeedbackAttempted: false,
    titleCollapsed: {},
    backToEdit: false,
    waitingRoomResponsesPanelToggle: false,
  };
  listenForModuleListChanges = () => {
    const { match } = this.props;
    const { surveyCode, presentationSurveyCode, facilitationSurveyCode } =
      (match && match.params) || {};
    const surveyId =
      presentationSurveyCode || facilitationSurveyCode || surveyCode;

    if (this.inParticipantMode()) {
      if (!surveyId) {
        console.log('No surveyId provided for the modules realtime listener');
        return;
      }
      this.modulesListener = db
        .collection('modules')
        .where('surveyId', '==', surveyId)
        .onSnapshot((snapshot) => {
          if (snapshot.empty) return;
          const moduleList = snapshot.docs.reduce(
            getModuleListListenerReducer,
            {}
          );
          this.setState({
            moduleList,
          });
        });
    }
  };
  setBackToEdit = () => {
    const backToEdit = getBackToEdit(this.props);
    this.setState({ backToEdit });
  };
  componentDidMount() {
    this.loadSurvey();
    this.setBackToEdit();
    this.listenForModuleListChanges();
  }
  componentWillUnmount() {
    if (this.modulesListener) {
      // Unsubscribe from the modules listener
      this.modulesListener();
    }
  }

  setStartModuleTimestamp = () => {
    this.setState({ startModuleTimestamp: new Date() });
  };
  componentDidUpdate(prevProps) {
    if (this.props.inEditMode) {
      if (prevProps !== this.props) {
        this.updateStateFromProps();
      }
      return;
    }
    if (getSurveyCode(prevProps) !== getSurveyCode(this.props))
      this.loadSurvey();
    else if (
      getSlide(this.props) &&
      getSlide(prevProps) !== getSlide(this.props)
    )
      this.setStartModuleTimestamp();
    window.scrollTo(0, 0);
  }
  inParticipantMode = () => {
    const { inEditMode, match } = this.props;
    if (inEditMode) return false;
    const { surveyCode } = (match && match.params) || {};
    this.props.dispatch(setIsParticipantMode(!!surveyCode));

    return !!surveyCode;
  };
  inPreviewMode = () => {
    const { inEditMode, match } = this.props;
    if (inEditMode) return false;
    const { previewSurveyCode } = (match && match.params) || {};
    const hasPreviewCode = !!previewSurveyCode;
    return hasPreviewCode;
  };
  inPresentationMode = () => {
    const { inEditMode, match } = this.props;
    if (inEditMode) return false;
    const { presentationSurveyCode } = (match && match.params) || {};
    return !!presentationSurveyCode;
  };
  inResultMode = () => {
    const { inEditMode, match } = this.props;
    if (inEditMode) return false;
    const { resultSurveyCode } = (match && match.params) || {};
    return !!resultSurveyCode;
  };
  inFacilitationMode = () => {
    const { inEditMode, match } = this.props;
    if (inEditMode) return false;
    const { facilitationSurveyCode } = (match && match.params) || {};
    return !!facilitationSurveyCode;
  };
  restoreAnswerData = ({ answerData }) => {
    // copy to all survey state holders since we don't currently store moduleType (todo: store more info on save)
    const [
      plotterData,
      stackRank,
      pointDistribution,
      textHighlight,
      spectrumDistribution,
      explanation,
      matrix,
      personalInfo,
      madlib,
    ] = Array(9).fill(answerData);
    this.setState({
      plotterData,
      stackRank,
      pointDistribution,
      textHighlight,
      spectrumDistribution,
      explanation,
      matrix,
      personalInfo,
      madlib,
    });
  };
  updateStateFromProps = () => {
    const { surveyDetails, moduleList, loading } = this.props;
    return this.setState({
      surveyDetails,
      moduleList,
      loading,
    });
  };
  loadSurvey = () => {
    const { match, inEditMode } = this.props;
    const {
      surveyCode,
      presentationSurveyCode,
      resultSurveyCode,
      responseId,
      facilitationSurveyCode,
    } = (match && match.params) || {};
    if (inEditMode) {
      return this.updateStateFromProps();
    }
    // pull up survey in preview mode
    if (this.inPreviewMode()) {
      const { surveyDetails, moduleList } = this.props.location.state || {};
      // try to preview using temporary location data (preview)
      if (moduleList) {
        return this.setState({
          surveyDetails: surveyDetails || { name: ' ' },
          moduleList,
          loading: false,
        });
        // otherwise load the survey normally (and show the draft data)
      } else {
        this.setState({ loading: true });
        return fetchSurveyModulesAndResponses({
          surveyCode: getSurveyCode(this.props),
        }).then(({ surveyDetails, moduleList, userResponses }) => {
          const selectedSurveyDetails = surveyDetails;
          const selectedModuleList = moduleList;
          this.setState({
            surveyDetails: selectedSurveyDetails,
            moduleList: selectedModuleList,
            loading: false,
            surveyLoadError: !surveyDetails,
            userResponses,
          });
        });
      }
    }
    // pull up survey in presentation mode
    if (this.inPresentationMode() || this.inFacilitationMode()) {
      this.setState({ loading: true });
      return fetchSurveyModulesAndResponses({
        surveyCode:
          presentationSurveyCode || facilitationSurveyCode || surveyCode,
        showAllUsers: true,
      }).then(({ surveyDetails, moduleList, userResponses }) => {
        // set user response data (by default restore first response)
        const answerData = this.inFacilitationMode()
          ? {}
          : (Object.values(userResponses)[0] || {}).answerData || {};
        this.restoreAnswerData({ answerData });
        // set survey data
        return this.setState({
          surveyDetails: surveyDetails || { name: ' ' },
          moduleList,
          loading: false,
          surveyLoadError: !surveyDetails,
          userResponses,
        });
      });
    }
    // pull up survey in review mode
    if (this.isOnReviewSlide()) {
      this.setState({ loading: true });
      return fetchSurveyModulesAndResponses({
        surveyCode: presentationSurveyCode || surveyCode,
        showAllUsers: true,
      }).then(({ surveyDetails, moduleList, userResponses }) => {
        // set survey data
        return this.setState({
          surveyDetails: surveyDetails || { name: ' ' },
          moduleList,
          loading: false,
          surveyLoadError: !surveyDetails,
          userResponses,
        });
      });
    }
    // pull up survey in view response mode
    if (this.inResultMode()) {
      this.setState({ loading: true });
      return fetchSurveyModulesAndResponses({
        surveyCode: resultSurveyCode,
        responseId,
      }).then(({ surveyDetails, moduleList, userResponses }) => {
        // set user response data
        const answerData =
          userResponses && userResponses[responseId]
            ? userResponses[responseId].answerData
            : {};
        this.restoreAnswerData({ answerData });
        // set survey data
        return this.setState({
          surveyDetails: surveyDetails || { name: ' ' },
          moduleList,
          loading: false,
          surveyLoadError: !surveyDetails,
          userResponses,
        });
      });
    }

    // otherwise try to pull up a real survey
    this.setState({ loading: true });
    return fetchSurveyModulesAndResponses({
      surveyCode,
      modulesListFilterFn: (module) => {
        return module.moduleType !== COMPARISON;
      },
    }).then(({ surveyDetails, moduleList, userResponses }) => {
      // set survey data
      this.setState({
        surveyDetails: surveyDetails || { name: ' ' },
        moduleList,
        loading: false,
        surveyLoadError: !surveyDetails,
        userResponses,
      });
    });
  };

  getCurrentProjectId() {
    const currentProjectId = getNestedData(
      this.props,
      `location.state.projectId`
    );
    return currentProjectId;
  }

  onLogoClick = () => {
    const { history, inEditMode } = this.props;
    const projectId = this.getCurrentProjectId();
    if (projectId) {
      return this.props.history.push(`${ADMIN}?projectId=${projectId}`, {
        projectId,
      });
    }
    this.props.history.push(LANDING);
  };
  getCurrentModule(options = {}) {
    const { moduleList = this.state.moduleList } = options;
    const slide = getSlide(this.props);
    const moduleArray = sortModulesToArray(moduleList);
    const module = moduleArray.find((module) => module.order === slide);
    return module || {};
  }
  getModuleArray(options = {}) {
    const { moduleList = this.state.moduleList } = options;
    return sortModulesToArray(moduleList);
  }
  getQuestionData(options = {}) {
    const module = this.getCurrentModule();
    return module.questionData || {};
  }
  getInitialStackRankOptions() {
    const module = this.isModuleReady(STACK_RANK);
    if (!module) return;
    const moduleId = this.getModuleId();
    const questions = this.getQuestionData();
    const addedOptions = questions?.addedOptions || [];
    const initial = filterAndSortStackRank(questions, addedOptions).reduce(
      (collector, [stackId, stackText], i) => {
        const order = i + 1;
        return {
          ...collector,
          [stackId]: {
            stackText,
            stackId,
            order,
          },
        };
      },
      {}
    );
    return { [moduleId]: initial };
  }
  getModuleId() {
    const module = this.getCurrentModule();
    if (!module) return;
    const { moduleId } = module;
    return moduleId;
  }
  getCurrentSlide() {
    const { order } = this.getCurrentModule();
    const slide = order ? parseInt(order, 10) : getSlide(this.props);
    return slide;
  }
  getLastSlide() {
    return getLastSlide(this.state.moduleList);
  }
  getModuleType() {
    const { moduleType } = this.getCurrentModule();
    return moduleType;
  }
  getOrderWithOutTextSlides() {
    const { displayOrder } = this.getCurrentModule();
    return displayOrder;
  }
  onClickCloseWaitingRoomResponsesDetails = () => {
    this.setState({
      waitingRoomResponsesPanelToggle: false,
    });
  };
  onClickRefreshWaitingRoomIcon = () => {
    this.loadSurvey();
  };
  onToggleOpenWaitingRoomResponsesDetails = () => {
    const { waitingRoomResponsesPanelToggle } = this.state;
    this.setState({
      waitingRoomResponsesPanelToggle: !waitingRoomResponsesPanelToggle,
    });
  };
  toggleTitleCollapsed = (e) => {
    const slide = this.getCurrentSlide();
    this.setState((state) => ({
      titleCollapsed: {
        ...state.titleCollapsed,
        [slide]: !state.titleCollapsed[slide],
      },
    }));
  };
  renderSurveyTitle(options = {}) {
    const { collapsedHeader } = options;
    const { inEditMode, inMobileView } = this.props;
    const module = this.getCurrentModule();
    if (!module) return;
    const { question, description } = module;
    if (!question) return;
    const responseCount = Object.entries(this.state.userResponses).length;
    const preTitleCollapsed = this.state.titleCollapsed[this.getCurrentSlide()];
    const titleCollapsed = collapsedHeader
      ? !preTitleCollapsed
      : preTitleCollapsed;
    return (
      <SurveyTitle
        lastSlide={this.getLastSlide()}
        moduleType={this.getModuleType()}
        orderWithOutTextSlides={this.getOrderWithOutTextSlides()}
        onClickInfoIcon={this.onToggleOpenWaitingRoomResponsesDetails}
        question={question}
        description={description}
        responseCount={responseCount}
        inPresentationMode={this.inPresentationMode()}
        toggleTitleCollapsed={this.toggleTitleCollapsed}
        inFacilitationMode={this.inFacilitationMode()}
        waitingRoomResponsesPanelToggle={
          this.state.waitingRoomResponsesPanelToggle
        }
        {...{ inEditMode, inMobileView, titleCollapsed }}
      />
    );
  }
  isModuleReady(type) {
    if (this.state.loading) return false;
    if (Object.keys(this.state.moduleList).length === 0) return false;
    if (type && this.getModuleType() !== type) return false;
    return this.getCurrentModule();
  }
  renderXYPlotter({ formikProps }) {
    const { inMobileView } = this.props;
    const module = this.isModuleReady(PLOTTER_XY);
    if (!module) return;
    return (
      <PlotterContainer>
        <Plotter
          moduleType={this.getModuleType()}
          moduleId={this.getModuleId()}
          questionData={this.getQuestionData()}
          inPresentationMode={this.inPresentationMode()}
          {...formikProps}
          {...{ inMobileView }}
        />
      </PlotterContainer>
    );
  }
  render22Plotter({ formikProps }) {
    const { inMobileView } = this.props;
    const module = this.isModuleReady(PLOTTER_22);
    if (!module) return;
    return (
      <PlotterContainer>
        <Plotter
          moduleType={this.getModuleType()}
          moduleId={this.getModuleId()}
          questionData={this.getQuestionData()}
          inPresentationMode={this.inPresentationMode()}
          {...formikProps}
          {...{ inMobileView }}
        />
      </PlotterContainer>
    );
  }
  renderStackRank({ formikProps }) {
    const { inMobileView } = this.props;
    const module = this.isModuleReady(STACK_RANK);
    if (!module) return;
    return (
      <StackRank
        moduleId={this.getModuleId()}
        questionData={this.getQuestionData()}
        inPresentationMode={this.inPresentationMode()}
        {...formikProps}
        {...{ inMobileView }}
      />
    );
  }
  renderAddOptions({ formikProps }) {
    const { inMobileView } = this.props;
    const module = this.isModuleReady(ADD_OPTION);
    if (!module) return;
    return (
      <AddOptions
        moduleId={this.getModuleId()}
        questionData={this.getQuestionData()}
        inPresentationMode={this.inPresentationMode()}
        module={module}
        formikProps={formikProps}
        {...{ inMobileView }}
      />
    );
  }
  renderPointDistribution({ formikProps }) {
    const { inMobileView } = this.props;
    const module = this.isModuleReady(POINT_DISTRIBUTION);
    if (!module) return;
    return (
      <PointDistribution
        moduleId={this.getModuleId()}
        questionData={this.getQuestionData()}
        inPresentationMode={this.inPresentationMode()}
        {...formikProps}
        {...{ inMobileView }}
      />
    );
  }
  renderWaitingRoom({ formikProps }) {
    const {
      waitingRoomResponsesPanelToggle,
      moduleList,
      userResponses,
    } = this.state;
    const module = this.isModuleReady(WAITING_ROOM);
    if (!module) return;
    if (!(this.inFacilitationMode() && waitingRoomResponsesPanelToggle))
      return null;
    return (
      <WaitingRoom
        moduleList={moduleList}
        userResponses={userResponses}
        onClickCloseIcon={this.onClickCloseWaitingRoomResponsesDetails}
        onClickRefreshIcon={this.onClickRefreshWaitingRoomIcon}
      />
    );
  }

  renderTextHighlight({ formikProps }) {
    const { inMobileView } = this.props;
    const module = this.isModuleReady(TEXT_HIGHLIGHT);
    if (!module) return;
    return (
      <TextHighlightSurvey
        moduleId={this.getModuleId()}
        questionData={this.getQuestionData()}
        inPresentationMode={this.inPresentationMode()}
        location={this.props.location}
        {...formikProps}
        {...{ inMobileView }}
      />
    );
  }
  renderSpectrumDistribution({ formikProps }) {
    const { inMobileView } = this.props;
    const module = this.isModuleReady(SPECTRUM_DISTRIBUTION);
    if (!module) return;
    return (
      <SpectrumDistribution
        moduleId={this.getModuleId()}
        questionData={this.getQuestionData()}
        inPresentationMode={this.inPresentationMode()}
        {...formikProps}
        {...{ inMobileView }}
      />
    );
  }
  renderMatrix({ formikProps }) {
    const { inMobileView } = this.props;
    const module = this.isModuleReady(MATRIX);
    if (!module) return;
    return (
      <Matrix
        moduleId={this.getModuleId()}
        questionData={this.getQuestionData()}
        inPresentationMode={this.inPresentationMode()}
        {...formikProps}
        {...{ inMobileView }}
      />
    );
  }
  onClickShowExplanations = () => {
    const showExplanations = !this.state.showExplanations;
    this.setState({ showExplanations });
  };
  renderSurveyBottom(options = {}) {
    const { props, state } = this;
    const {
      match,
      history,
      location,
      currentUser,
      inEditMode,
      inMobileView,
    } = props;
    const { formikProps, className } = options;
    const { startSurveyTimestamp, startModuleTimestamp, surveyDetails } = state;
    if (this.isOnLastSlide()) return null;
    const showExplanationsActive =
      this.getExplanations({ formikProps }).length > 0;
    const resultSurveyCode = get(match, 'params.resultSurveyCode'); // userId for presentation i think
    return (
      <SurveyBottom
        moduleId={this.getModuleId()}
        moduleArray={this.getModuleArray()}
        questionData={this.getQuestionData()}
        currentSlide={this.getCurrentSlide()}
        lastSlide={this.getLastSlide()}
        surveyCode={getSurveyCode(props)}
        history={history}
        location={location}
        currentUser={currentUser}
        moduleType={this.getModuleType()}
        surveyDetails={surveyDetails}
        match={match}
        startSurveyTimestamp={startSurveyTimestamp}
        startModuleTimestamp={startModuleTimestamp}
        onClickShowExplanations={this.onClickShowExplanations}
        showExplanationsActive={showExplanationsActive}
        {...{
          inEditMode,
          inMobileView,
          inPresentationMode: this.inPresentationMode(),
          inPreviewMode: this.inPreviewMode(),
          inResultMode: this.inResultMode(),
          inFacilitationMode: this.inFacilitationMode(),
          resultSurveyCode,
        }}
        {...formikProps}
        className={className}
      />
    );
  }
  isOnLastSlide() {
    const { inEditMode, match } = this.props;
    if (inEditMode) return false;
    return match.params.slide === COMPLETE;
  }
  isOnReviewSlide() {
    const { inEditMode, match } = this.props;
    if (inEditMode) return false;
    return match.params.slide === REVIEW;
  }
  renderComplete({ formikProps }) {
    if (!this.isOnLastSlide()) return;
    return (
      <Complete
        surveyDetails={this.state.surveyDetails}
        responseId={getResponseId(this.props.location.search)}
        {...formikProps}
      />
    );
  }
  renderPersonalInfo({ formikProps }) {
    const { inMobileView } = this.props;
    const module = this.isModuleReady(PERSONAL_INFO);
    if (!module) return;
    return (
      <PersonalInfo
        moduleId={this.getModuleId()}
        questionData={this.getQuestionData()}
        inPresentationMode={this.inPresentationMode()}
        {...formikProps}
        {...{ inMobileView }}
      />
    );
  }
  backToEditor = () => {
    const { surveyDetails, moduleList, projectDetails, projectId } =
      this.props.location.state || {};
    if (
      (surveyDetails && moduleList && projectDetails) ||
      this.inPreviewMode()
    ) {
      const state = {
        surveyDetails,
        moduleList,
        projectDetails,
      };
      const url = surveyDetails.template
        ? `${EDIT}?templateId=${surveyDetails.templateId}`
        : `${EDIT}?project=${this.state.surveyDetails.projectId}&survey=${this.state.surveyDetails.surveyId}`;
      return this.props.history.push(url, state);
    }
    const currentProjectId = this.state.surveyDetails.projectId || projectId;
    if (currentProjectId) {
      const state = {
        projectId: currentProjectId,
      };
      return this.props.history.push(
        `${ADMIN}?projectId=${this.state.surveyDetails.projectId}&surveyId=${this.state.surveyDetails.surveyId}`,
        state
      );
    }
    return this.props.history.goBack();
  };
  backToAdmin = () => {
    const { template } = this.state.surveyDetails;
    const url = template
      ? TEMPLATES_GALLERY
      : `${ADMIN}?projectId=${this.state.surveyDetails.projectId}`;
    return this.props.history.push(url);
  };
  renderMadlib({ formikProps }) {
    const { inMobileView } = this.props;
    const module = this.isModuleReady(MADLIB);
    if (!module) return;
    return (
      <Madlib
        moduleId={this.getModuleId()}
        questionData={this.getQuestionData()}
        inPresentationMode={this.inPresentationMode()}
        {...formikProps}
        {...{ inMobileView }}
      />
    );
  }
  renderReview({ formikProps }) {
    if (!this.isOnReviewSlide()) return;
    return (
      <Review
        surveyDetails={this.state.surveyDetails}
        match={this.props.match}
        history={this.props.history}
        restoreAnswerData={this.restoreAnswerData}
        {...formikProps}
      />
    );
  }
  getExplanations({ formikProps }) {
    const { values } = formikProps;
    const moduleId = this.getModuleId();
    const responses = Object.entries(values.userResponses)
      .map(([responseId, responseHash]) => {
        const { userName } = responseHash;
        const explanation = getNestedData(
          responseHash,
          `answerData.${moduleId}.explanation`
        );
        if (!explanation) return null;
        return {
          responseId,
          userName,
          explanation,
        };
      })
      .filter((x) => x);
    return responses;
  }
  renderExplanations({ formikProps }) {
    if (!this.state.showExplanations) return;
    const module = this.isModuleReady();
    if (
      !module ||
      this.isOnReviewSlide() ||
      this.isOnLastSlide() ||
      !this.inPresentationMode()
    )
      return;
    const responses = this.getExplanations({ formikProps }).map(
      ({ responseId, userName, explanation }) => {
        return (
          <ResponseRow key={responseId}>
            <ParticipantName>{userName}</ParticipantName>
            <ResponseMessage>{explanation}</ResponseMessage>
          </ResponseRow>
        );
      }
    );
    return (
      <ResponsesContainer>
        <ResponseRowContainer>{responses}</ResponseRowContainer>
      </ResponsesContainer>
    );
  }
  displayHelp = () => {
    this.setState((state) => ({ showHelp: !state.showHelp }));
  };
  hideHelp = () => {
    this.setState({ showHelp: false, feedbackRecordId: null });
  };
  stopModalClick = (e) => {
    e.stopPropagation();
  };
  setFeedbackEmail = (e) => {
    const feedbackEmail = e.currentTarget.value || '';
    this.setState({ feedbackEmail });
  };
  setFeedbackMessage = (e) => {
    const feedbackMessage = e.currentTarget.value || '';
    this.setState({ feedbackMessage });
  };
  sendFeedback = () => {
    this.setState({ feedbackSaving: true, sendFeedbackAttempted: true });
    const { feedbackEmail, feedbackMessage } = this.state;
    const surveyCode = getSurveyCode(this.props);
    if (!isEmailValid(feedbackEmail)) return;
    return sendFeedback({
      email: feedbackEmail,
      feedback: feedbackMessage,
      surveyCode,
    }).then((record) => {
      const feedbackRecordId = record.getId();
      this.setState({
        feedbackSaving: false,
        feedbackEmail: '',
        feedbackMessage: '',
        feedbackRecordId,
      });
    });
  };
  detectEnter = (e) => {
    if (e.keyCode === 13) return this.sendFeedback();
  };
  renderFeedbackComplete() {
    const { feedbackRecordId } = this.state;
    return (
      <ModalBody onClick={this.stopModalClick}>
        <Question>Feedback sent</Question>
        <FeedbackRecordId>{feedbackRecordId}</FeedbackRecordId>
        <Button active={true} onClick={this.hideHelp}>
          Close
        </Button>
      </ModalBody>
    );
  }
  renderFeedback() {
    const {
      feedbackEmail,
      feedbackMessage,
      feedbackSaving,
      feedbackRecordId,
      sendFeedbackAttempted,
    } = this.state;
    const buttonText =
      sendFeedbackAttempted && !isEmailValid(feedbackEmail)
        ? 'Invalid email, please try again'
        : 'Submit';
    return (
      <ModalBody onClick={this.stopModalClick}>
        <ModalClose onClick={this.hideHelp} />
        <CompleteQuestion>Need help?</CompleteQuestion>
        <LandingDescription>
          Please enter your question or comment and an administrator will follow
          up with you. Thank you.
        </LandingDescription>
        <ModalTextarea
          onChange={this.setFeedbackMessage}
          rows={6}
          value={feedbackMessage}
        />
        <EnterEmail
          placeholder="Email Address"
          onChange={this.setFeedbackEmail}
          onKeyDown={this.detectEnter}
          value={feedbackEmail}
          width="100%"
        />
        <Button
          active={feedbackEmail.length > 0 && !feedbackSaving}
          onClick={this.sendFeedback}
        >
          {buttonText}
        </Button>
      </ModalBody>
    );
  }
  renderHelp() {
    const { feedbackRecordId, showHelp } = this.state;
    if (!showHelp) return null;
    const modalBody = feedbackRecordId
      ? this.renderFeedbackComplete()
      : this.renderFeedback();
    return (
      <ModalOverlay active={this.state.showHelp} onClick={this.hideHelp}>
        {modalBody}
      </ModalOverlay>
    );
  }
  renderForm() {
    const { inMobileView, collapsedHeader } = this.props;
    const {
      loading,
      plotterData,
      stackRank,
      pointDistribution,
      textHighlight,
      spectrumDistribution,
      matrix,
      personalInfo,
      openEnded,
      multiSelect,
      addOption,
      madlib,
      explanation,
      userResponses,
    } = this.state;
    const moduleType = this.getModuleType();
    const questionData = this.getQuestionData();
    const initialStackRank = this.getInitialStackRankOptions();
    return (
      <Formik
        initialValues={{
          plotterData,
          stackRank: initialStackRank || {},
          pointDistribution,
          textHighlight,
          spectrumDistribution,
          matrix,
          personalInfo,
          openEnded,
          multiSelect,
          addOption,
          madlib,
          explanation,
          userResponses,
          surveyEmail: '',
          responseId: void 0,
          reviewCode: void 0,
        }}
        onSubmit={() => {}}
        render={(formikProps) => {
          Tracking.event({
            name: surveyTakerEvents.MODULE_ON_VISIT,
            properties: { moduleType },
          });
          if (
            this.inParticipantMode() &&
            questionData.populateOptions &&
            isPopulatedOptions(Object.keys(questionData)) === false
          ) {
            return (
              <SurveyQuestionDataContainer
                data-module-type={moduleType}
                {...{ inMobileView }}
              >
                <EmptyPopulate />
              </SurveyQuestionDataContainer>
            );
          }
          if (this.isOnLastSlide()) {
            return (
              <SurveyQuestionDataContainer
                data-module-type={moduleType}
                {...{ inMobileView }}
              >
                {this.renderComplete({ formikProps })}
              </SurveyQuestionDataContainer>
            );
          }
          return ![COMPARISON, OPEN_ENDED, MULTI_SELECT].includes(
            moduleType
          ) ? (
            <SurveyQuestionDataContainer
              data-module-type={moduleType}
              {...{ inMobileView }}
            >
              <SurveyRow {...{ inMobileView }}>
                <SurveyLeftColumn {...{ inMobileView }}>
                  {this.renderSurveyTitle({ collapsedHeader })}
                  {this.renderSurveyBottom({ formikProps })}
                  {this.renderExplanations({ formikProps })}
                </SurveyLeftColumn>
                <SurveyRightColumn
                  data-module-type={moduleType}
                  {...{ inMobileView }}
                >
                  {this.renderXYPlotter({ formikProps })}
                  {this.render22Plotter({ formikProps })}
                  {this.renderStackRank({ formikProps })}
                  {this.renderPointDistribution({ formikProps })}
                  {this.renderWaitingRoom({ formikProps })}
                  {this.renderTextHighlight({ formikProps })}
                  {this.renderSpectrumDistribution({ formikProps })}
                  {this.renderMatrix({ formikProps })}
                  {this.renderPersonalInfo({ formikProps })}
                  {this.renderMadlib({ formikProps })}
                  {this.renderAddOptions({ formikProps })}
                  {this.renderReview({ formikProps })}
                </SurveyRightColumn>
              </SurveyRow>
            </SurveyQuestionDataContainer>
          ) : (
            <>
              {moduleType === COMPARISON && (
                <ComparisonContainer>
                  <Comparison
                    moduleList={this.state.moduleList}
                    userResponses={this.state.userResponses}
                    questionData={this.getQuestionData()}
                  />
                  <ComparisonSurveyBottomContaier>
                    {this.renderSurveyBottom({ formikProps })}
                  </ComparisonSurveyBottomContaier>
                </ComparisonContainer>
              )}
              {moduleType === OPEN_ENDED && !!this.isModuleReady(OPEN_ENDED) && (
                <OpenEnded
                  {...{
                    ...formikProps,
                    questionData: this.getQuestionData(),
                    module: this.getCurrentModule(),
                    controls: () => this.renderSurveyBottom({ formikProps }),
                    inMobileView,
                  }}
                />
              )}
              {moduleType === MULTI_SELECT &&
                !!this.isModuleReady(MULTI_SELECT) && (
                  <MultiSelect
                    {...{
                      ...formikProps,
                      inMobileView,
                      questionData: this.getQuestionData(),
                      module: this.getCurrentModule(),
                      order: this.getOrderWithOutTextSlides(),
                      controls: ({ className } = {}) =>
                        this.renderSurveyBottom({ formikProps, className }),
                    }}
                  />
                )}
            </>
          );
        }}
      />
    );
  }
  renderEditMode() {
    const {
      surveyDetails,
      loading,
      saving,
      surveyLoadError,
      moduleList,
      inEditMode,
      activeIndex,
      loadSurvey,
      setSaving,
      isClosed,
      toggleClosed,
      onPreviewClick,
      saveDraft,
      dirtyBuilder,
      saveSurveyClick,
      previewTab,
      setPreviewTab,
      inDesktopView,
      inMobileView,
      ...other
    } = this.props;
    const { name: title } = surveyDetails;
    let background = '#F0F0F0';
    if (
      (!isClosed || (isClosed && inEditMode)) &&
      (this.getModuleType() === TEXT_SLIDE || this.isOnLastSlide())
    )
      background = '#2D2D2D';
    if (this.isOnReviewSlide()) background = '#1CA4FC';
    const inPreviewMode = this.inPreviewMode();
    return (
      <Layout
        {...{
          loading,
          saving,
          inEditMode,
          title,
          background,
          hideButtons: true,
          inPreviewMode,
          surveyDetails,
          inMobileView,
          inDesktopView,
        }}
      >
        <PageContainer
          flexDirection="column"
          justifyContent="flex-start"
          {...{ background, inEditMode }}
        >
          <LoadingFull loading={loading}>Loading...</LoadingFull>
          <NoSurveyFull loading={loading} surveyLoadError={surveyLoadError}>
            Survey with code {getSurveyCode(this.props)} not found
          </NoSurveyFull>
          <LoadingFull
            loading={!loading && Object.keys(moduleList).length === 0}
          >
            Survey has no modules
          </LoadingFull>
          <LoadingFull loading={!loading && inEditMode && activeIndex === -1}>
            Select a module to edit
          </LoadingFull>
          {loading ||
          (isClosed && !inEditMode) ||
          (inEditMode && activeIndex === -1)
            ? null
            : this.renderForm()}
        </PageContainer>
        {this.renderHelp()}
      </Layout>
    );
  }
  render() {
    const {
      state,
      props,
      onLogoClick,
      backToEditor,
      displayHelp,
      backToAdmin,
    } = this;
    const {
      surveyDetails,
      loading,
      saving,
      showHelp,
      surveyLoadError,
      moduleList,
      backToEdit,
    } = state;
    const { match, inEditMode, history } = props;
    if (inEditMode) return this.renderEditMode();
    const { name, closed, closeDate } = surveyDetails;
    const isClosed = getIsClosed({ closed, closeDate });
    const inPresentationMode = this.inPresentationMode();
    const inPreviewMode = this.inPreviewMode();
    const inFacilitationMode = this.inFacilitationMode();
    const onLastSlide = this.isOnLastSlide();
    const onReviewSlide = this.isOnReviewSlide();
    const isTextSlide = this.getModuleType() === TEXT_SLIDE;
    const browser = Bowser.getParser(window.navigator.userAgent);
    const showDarkBgColor =
      isClosed && !inPresentationMode && !inPreviewMode && !inFacilitationMode
        ? false
        : isTextSlide || onLastSlide;
    let backgroundColor = showDarkBgColor ? '#2D2D2D' : '#F0F0F0';
    if (onReviewSlide) backgroundColor = '#1CA4FC';
    const onHelpClick =
      !inPresentationMode && !inPreviewMode && !inFacilitationMode
        ? displayHelp
        : false;
    const showClosed =
      !loading &&
      isClosed &&
      !inPresentationMode &&
      !inPreviewMode &&
      !inFacilitationMode;
    const surveyCode = getSurveyCode(props);
    const onClickTakeSurveyAnyway = () => {
      localStorage['ignoreInvalidBrowser'] = true;
      this.forceUpdate();
    };
    const onClose = backToEdit ? backToEditor : backToAdmin;
    return (
      <Layout
        {...{
          title: name,
          background: backgroundColor,
          onLogoClick,
          loading,
          saving,
          onClose,
          inPreviewMode,
          inPresentationMode,
          inFacilitationMode,
          onHelpClick,
          helpActive: showHelp,
        }}
      >
        <PageContainer
          background={backgroundColor}
          flexDirection="column"
          justifyContent="flex-start"
        >
          <LoadingFull loading={loading}>Loading...</LoadingFull>
          <NoSurveyFull loading={loading} surveyLoadError={surveyLoadError}>
            Survey with code {surveyCode} not found
          </NoSurveyFull>
          <LoadingFull
            loading={!loading && Object.keys(moduleList).length === 0}
          >
            Survey has no modules
          </LoadingFull>
          <LoadingFull loading={showClosed}>Survey is closed</LoadingFull>
          {loading || showClosed ? null : this.renderForm()}
        </PageContainer>
        {this.renderHelp()}
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  hilo: state.hilo,
});

const ConnectedSurvey = connect(mapStateToProps)(Survey);

export default ConnectedSurvey;
