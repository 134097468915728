import React from 'react';
import styled from 'styled-components';

export const Button2 = styled('div')`
  font-family: Neue Haas Grotesk Display Std;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  display: ${({ display }) => display || 'flex'};
  align-items: center;
  text-align: ${({ textAlign }) => textAlign || 'center'};
  color: ${({ color }) => color || '#262525'};
  border: 2px solid
    ${({ borderColor, color }) => borderColor || color || '#262525'};
  background: ${({ background }) => background || 'transparent'};
  box-sizing: border-box;
  border-radius: 4px;
  width: ${props => props.width || '90px'};
  height: 26px;
  justify-content: center;
  cursor: pointer;
  margin: ${({ margin }) => margin || '0'};
  ${props =>
    props.disabled
      ? `
  pointer-events: none;
  opacity: 0.5;
  `
      : ``}
  ${({ hoverBold }) =>
    hoverBold
      ? `
  &:hover {
    font-weight: bold;
  }
  `
      : ``}
`;

export const ButtonBase = styled.button`
  outline: 0;
  border: 0;
  background: transparent;
  padding: 0;
`
