import {
  DEFAULT_CHART_BUBBLE_RADIUS,
  OPTION_PREFIX,
  PERSONAL_INFO,
  TEXT_SLIDE,
} from '../../const/module-types';

const isThereData = (data) => data && data.length > 0;

/**
 * Get data in this format.
 * [{title, times, radius, score, members}]
 * @param data
 * @returns {*[]}
 */
const getFormattedDataWithFrequency = (data) => {
  return Object.entries(getResponsesWithSamePointValue(data)).reduce(
    (acc, currentValue) => {
      return [...acc, ...Object.values(currentValue[1]).flat()];
    },
    []
  );
};

/**
 * Get options that have the same point value and group them together
 * @param responses
 * @returns {{}}
 */
const getResponsesWithSamePointValue = (responses = {}) => {
  const finalMap = {};
  Object.entries(responses).forEach(([responseId, { data, name }]) => {
    data.forEach(({ option, points }) => {
      finalMap[option] = {
        ...(finalMap[option] || {}),
        [points]: {
          option,
          frequency: getFrequency(finalMap, option, points),
          radius: getPoint(finalMap, option, points),
          points,
          members: [
            ...getRespondentsWithSamePointValue(finalMap, option, points),
            name,
          ],
          users: [
            ...getResponseIdWithSamePointValue(finalMap, option, points),
            responseId,
          ],
        },
      };
    });
  });
  return finalMap;
};

/**
 * Get the value of times
 * @param finalMap
 * @param option
 * @param points
 * @returns {*|number}
 */
function getFrequency(finalMap, option, points) {
  return (
    (finalMap[option] &&
      finalMap[option][points] &&
      finalMap[option][points].frequency) + 1 || 1
  );
}

/**
 * Get the score
 * 10 represents one dot, to make this dynamic we shall
 * divide the total by the number of options.
 * @param finalMap
 * @param option
 * @param points
 * @returns {number}
 */
function getPoint(finalMap, option, points) {
  return getFrequency(finalMap, option, points) * DEFAULT_CHART_BUBBLE_RADIUS;
}

/**
 * Get the participants with that specific score.
 * @param finalMap
 * @param option
 * @param points
 * @returns {*|*[]}
 */
function getRespondentsWithSamePointValue(finalMap, option, points) {
  return (
    (finalMap[option] &&
      finalMap[option][points] &&
      finalMap[option][points].members) ||
    []
  );
}

/**
 * Get the participants responseIds with that specific score.
 * @param finalMap
 * @param option
 * @param points
 * @returns {*|*[]}
 */
function getResponseIdWithSamePointValue(finalMap, option, points) {
  // console.log('returnertth', finalMap)
  return (
    (finalMap[option] &&
      finalMap[option][points] &&
      finalMap[option][points].users) ||
    []
  );
}

/**
 * Get the respondent username from the personal information module if
 * it exists or just use the default Guest name.
 * @param userName
 * @param answerData
 * @param moduleList
 * @param index
 * @returns {*}
 */
const getUserName = (userName, answerData, moduleList, index) => {
  if (!moduleList) return;
  let name = '';
  const personalInfoModule = moduleList.filter(
    (module) => module.moduleType === PERSONAL_INFO
  )[0];
  if (personalInfoModule) {
    const moduleId = personalInfoModule.moduleId;
    name = answerData[moduleId] && answerData[moduleId].name;
  }
  return name || `${userName} ${index}`;
};

/**
 * Get the options the survey creator used for the point distribution
 * @param questionData
 * @param prefix
 * @returns {[string, unknown]}
 */
const getQuestionOptions = (questionData, prefix = OPTION_PREFIX) =>
  Object.entries(questionData).reduce((acc, [key, value]) => {
    if (key.indexOf(prefix) !== 0) return acc;
    return [...acc, { [key]: value }];
  }, []);

/**
 * Get the options the survey creator used for the point distribution but not the
 * ones added by the facilitator.
 * We do not want to show the facilitator added options on the module(populate) chart.
 * We only show them on the module on which they are to be populated on.
 * This should be used to Point distribution and stack rank in the presentation or
 * workshop presentation mode.
 * @param questionData
 * @param prefix
 * @param addedOptions An array containing option keys added by the facilitator in the workshop mode.
 * @returns {[string, unknown]}
 */
const getNonPopulateQuestionOptions = (
  questionData,
  prefix = OPTION_PREFIX,
  addedOptions = []
) =>
  Object.entries(questionData).reduce((acc, [key, value]) => {
    if (key.indexOf(prefix) !== 0 || addedOptions.includes(key)) return acc;
    return [...acc, { [key]: value }];
  }, []);

const getQuestionOptionsForModuleFormatting = (
  questionData,
  prefix = OPTION_PREFIX
) =>
  Object.entries(questionData).reduce((acc, [key, value]) => {
    if (key.indexOf(prefix) !== 0) return acc;
    return [...acc, value];
  }, []);

const doesModuleHaveOptions = (questionData, prefix = OPTION_PREFIX) => {
  const options = getQuestionOptionsForModuleFormatting(questionData);
  return options.length > 0;
};

const removeModuleOptions = (questionData, prefix = OPTION_PREFIX) => {
  return Object.entries(questionData).reduce((acc, [key, value]) => {
    if (key.indexOf(prefix) === 0) return acc;
    return {
      ...acc,
      [key]: value,
    };
  }, {});
};

const getNumberOfParticipants = (participants) =>
  participants.filter((part) => part.data.length > 0).length;

/**
 * Get an array of only sorted titles strings.
 * @param data
 * @returns {*}
 */
const getArrayOfOptions = (data) => data.map(({ option }) => option);

/**
 * Sort the provided data in ascending order according ot the sorting array
 *
 * NOTE: For stack rank desc, the item with the higher average appears on top that
 * is why we are considering this the asc. ave of 3, appears above 2.
 * **The function naming is intentional
 * @param data
 * @param sortingArray
 * @returns {*}
 */
const sortAscByAnotherArray = (data, sortingArray) => {
  const arrayForSort = [...data];
  return arrayForSort.sort(
    (a, b) => sortingArray.indexOf(a.option) - sortingArray.indexOf(b.option)
  );
};

/**
 * Sort the provided data in descending order according ot the sorting array
 *
 * NOTE: For stack rank asc sorting, the item with the lowest average appears on top that
 * is why we are considering this the asc. ave of 2, appears above 3.
 * **The function naming is intentional
 * @param data
 * @param sortingArray
 * @returns {*}
 */
const sortDescByAnotherArray = (data, sortingArray) => {
  const arrayForSort = [...data];
  return arrayForSort.sort(
    (a, b) => sortingArray.indexOf(b.option) - sortingArray.indexOf(a.option)
  );
};

const getQuestionDescription = (activeQuestion) => {
  if (activeQuestion && activeQuestion.metaData.hasOwnProperty('description'))
    return activeQuestion.metaData.description;
  return '';
};

const getQuestionText = (activeQuestion) => {
  if (activeQuestion && activeQuestion.metaData.hasOwnProperty('questionText'))
    return activeQuestion.metaData.questionText;
  return '';
};

const isTextSlideModule = (moduleType) => moduleType === TEXT_SLIDE;

const sortWordCloudDesc = ({ wordGroup }) => {
  return wordGroup.sort((a, b) => b.value - a.value);
};

const unwantedWordCloudWords = [
  'be',
  'to',
  'of',
  'and',
  'a',
  'in',
  'that',
  'have',
  'i',
  'it',
  'for',
  'not',
  'on',
  'with',
  'he',
  'as',
  'you',
  'do',
  'at',
  'this',
  'but',
  'his',
  'by',
  'from',
  'they',
  'we',
  'say',
  'her',
  'she',
  'or',
  'an',
  'will',
  'my',
  'one',
  'all',
  'would',
  'there',
  'their',
  'what',
  'so',
  'up',
  'out',
  'if',
  'about',
  'who',
  'get',
  'which',
  'go',
  'me',
  'when',
  'make',
  'can',
  'like',
  'time',
  'no',
  'just',
  'him',
  'know',
  'take',
  'people',
  'into',
  'year',
  'your',
  'good',
  'some',
  'could',
  'them',
  'see',
  'other',
  'than',
  'then',
  'now',
  'look',
  'only',
  'come',
  'its',
  'over',
  'think',
  'also',
  'back',
  'after',
  'use',
  'two',
  'how',
  'our',
  'work',
  'first',
  'well',
  'way',
  'even',
  'new',
  'want',
  'because',
  'any',
  'these',
  'give',
  'day',
  'most',
  'us',
  '',
  '.',
  ',',
  ';',
  'im',
  'is',
  'yet',
  'the',
  'nor',
  'are',
  'each',
  'still',
  'use',
];

const getAllowedWordGroup = ({ wordGroup, maxLength = 80 }) => {
  if (wordGroup && wordGroup.length <= maxLength) return wordGroup;
  return wordGroup.slice(0, maxLength);
};

function getMedian({ arr }) {
  if (!arr.length) {
    return 0;
  }
  const numbers = arr.slice(0).sort((a, b) => a - b);
  const middle = Math.floor(numbers.length / 2);
  const isEven = numbers.length % 2 === 0;
  return isEven ? (numbers[middle] + numbers[middle - 1]) / 2 : numbers[middle];
}

/**
 * Group words into tiers so that we can apply styling.
 * allEqual means that all words have the same frequencies.
 * Else we try and group them into 3 tiers.
 * @param wordGroup
 * @returns {{allEqual: boolean}|{maxFrequency: number, medianFrequency: (number|*), minFrequency: number}}
 */
const getWordsFrequencyTiers = ({ wordGroup }) => {
  if (!wordGroup || wordGroup.length === 0) return { allEqual: true };
  const frequencies = wordGroup.map((group) => group.value);
  const maxFrequency = Math.max(...frequencies);
  const minFrequency = Math.min(...frequencies);
  if (maxFrequency === minFrequency) return { allEqual: true };
  const medianFrequency = getMedian({ arr: frequencies });
  return { maxFrequency, minFrequency, medianFrequency };
};

const mapWordGroupStyling = ({
  maxFrequency,
  medianFrequency,
  minFrequency,
  group,
}) => {
  const frequency = group.value;

  if (frequency === maxFrequency) {
    return {
      ...group,
      variant: 'large',
      opacity: '1',
      fontWeight: '900',
    };
  }

  if (frequency === minFrequency) {
    return {
      ...group,
      variant: 'small',
      opacity: '0.7',
      fontWeight: '300',
    };
  }

  if (frequency > minFrequency && frequency < medianFrequency) {
    return {
      ...group,
      variant: 'base',
      opacity: '1',
      fontWeight: '400',
    };
  }

  if (frequency >= medianFrequency && frequency < maxFrequency) {
    return {
      ...group,
      variant: 'medium',
      opacity: '1',
      fontWeight: '400',
    };
  }
};

/**
 * Add base styling to words when they all have the same frequency
 * @param group
 * @returns {*&{variant: string, opacity: string, fontWeight: string}}
 */
const mapEqualFrequencyGroup = (group) => ({
  ...group,
  value: 2, // Hard coding the value here so that we have the same font size when all words have the same frequency
  variant: 'base',
  opacity: '1',
  fontWeight: '400',
});

/**
 * Add the styling variant base, large, medium, or small.
 * Base is one if we have all words having the same frequency
 * large: largest and rest....
 * No need to add a variant if all words have the same frequency, since
 * the default is the base variant in the word styling component.
 * @param wordGroup
 * @returns {*[]|*}
 */
const addStylingVariantToWordGroupElements = ({ wordGroup }) => {
  if (!wordGroup || wordGroup.length === 0) return [];
  const {
    allEqual,
    maxFrequency,
    minFrequency,
    medianFrequency,
  } = getWordsFrequencyTiers({ wordGroup });

  if (allEqual) {
    return wordGroup.map(mapEqualFrequencyGroup);
  }

  return wordGroup.map((group) =>
    mapWordGroupStyling({ group, maxFrequency, minFrequency, medianFrequency })
  );
};

const capitalize = (word) => word.charAt(0).toUpperCase() + word.slice(1);

const getQuestionsData = ({ modules }) =>
  modules.reduce(
    (acc, module, index) => {
      const question = `Question ${index + 1}`;
      const moduleId = module.moduleId;
      const questionMetaData = {
        ...module,
        questionText: module.question,
        question,
      };
      return {
        questions: [
          ...acc.questions,
          {
            name: question,
            label: question,
            moduleType: questionMetaData.moduleType,
          },
        ],
        questionsMetaData: {
          ...acc.questionsMetaData,
          [moduleId]: questionMetaData,
        },
      };
    },
    {
      questions: [],
      questionsMetaData: {},
    }
  );

function setModuleIdQueryParam(moduleId, history) {
  const params = new URLSearchParams();
  params.append('moduleId', moduleId);
  history.push({ search: params.toString() });
}

export {
  capitalize,
  isThereData,
  getUserName,
  getQuestionText,
  getQuestionsData,
  sortWordCloudDesc,
  isTextSlideModule,
  getArrayOfOptions,
  getQuestionOptions,
  getAllowedWordGroup,
  removeModuleOptions,
  doesModuleHaveOptions,
  sortAscByAnotherArray,
  setModuleIdQueryParam,
  unwantedWordCloudWords,
  getQuestionDescription,
  sortDescByAnotherArray,
  getNumberOfParticipants,
  getNonPopulateQuestionOptions,
  getFormattedDataWithFrequency,
  addStylingVariantToWordGroupElements,
  getQuestionOptionsForModuleFormatting,
};
