import React from 'react';
import { TemplateCreationDialog } from '../../Templates';
import { RightColumn } from '../../layout';
import styled from 'styled-components';
import SurveyCard, {
  SurveyCardContainer as SurveyCardContainerOriginal,
} from '../SurveyCard';
import { getIsClosed } from '../../../lib/builder-api';
import format from 'date-fns/format';
import {
  EDIT,
  NEW_PRESENTATION_ROOT,
  PREVIEWROOT,
} from '../../../const/routes';
import SurveySort from '../../SurveySort';

const SurveysContainer = styled('div')`
  display: grid;
  width: 100%;
  grid-template-rows: minmax(270px, auto);
  grid-gap: 22px;
  margin: 0 0 22px 0;
  grid-template-columns: 100%;
  @media (min-width: 400px) {
    grid-template-columns: repeat(auto-fill, minmax(345px, 1fr));
  }
`;

const SurveyCardContainer = styled(SurveyCardContainerOriginal)`
  height: 100%;
  flex: none;
  margin: 0;
`;

const BuildContainer = styled(SurveyCardContainer)`
  background-color: transparent;
  box-shadow: none;
`;

const BuildNewSurvey = styled(SurveyCardContainer)`
  cursor: pointer;
  padding: 0.5rem 0.75rem;
  border: 1px dashed #1ca4fc;
  font-family: 'Neue Haas Grotesk Display Std', sans-serif;
  font-size: 2.25rem;
  font-weight: bold;
  line-height: 1;
  letter-spacing: normal;
  box-sizing: border-box;
  flex: 0 0 auto;
  &:hover {
    path {
      stroke: #1ca4fc;
    }
  }
`;

const PlusContainer = styled('div')`
  width: 20px;
  height: 40px;
  cursor: pointer;
`;

const PlusSvg = () => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.88086 1V15"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.86133 8H15.9002"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const TopRow = styled('div')`
  margin: 20px 0;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
`;

const PlusIcon = () => (
  <PlusContainer>
    <PlusSvg />
  </PlusContainer>
);

const sortParams = [
  {
    id: 1,
    label: 'Last Modified',
  },
  {
    id: 2,
    label: 'Created by myself',
  },
  {
    id: 3,
    label: 'Recently created',
  },
  {
    id: 4,
    label: 'Name',
  },
];

const OrganizationSurveyList = ({
  isTemplateDialogOpen,
  onDismissTemplate,
  templateSurveyId,
  surveys,
  state,
  getAllUsers,
  onClickTemplate,
  launchSurveyClick,
  currentUser,
  fetchProjects,
  fetchProjectSurveys,
  surveyLocks,
  history,
  onSelectedSortOrder,
  fetchOrganizationSurveys,
}) => {
  const renderSurveyList = () => {
    return surveys.map((survey, i) => {
      const { surveyCode, surveyId, projectId, responseCount } = survey;
      const isClosed = getIsClosed(survey);
      const formattedTimestamp =
        survey.timestamp && survey.timestamp.toDate
          ? format(survey.timestamp.toDate(), 'm/d/yy h:mma')
          : '';
      const members = getAllUsers(state);
      const titleLocation = `${NEW_PRESENTATION_ROOT}/${surveyCode}`;
      const titleLink = {
        location: titleLocation,
        state: { projectId },
      };
      const configLink = {
        location: `${EDIT}?project=${projectId}&survey=${surveyId}`,
        state: { projectId },
      };
      const onClickPlay = () => {
        window.open(`${PREVIEWROOT}/${surveyCode}/1`);
      };
      return (
        <SurveyCard
          hideMargin
          key={`${surveyId}::${i}`}
          onClickSettings={() =>
            this.selectSettingsTab({ projectId, surveyId })
          }
          onClickPlay={() => onClickPlay({ surveyCode, isClosed })}
          onClickTemplate={() => onClickTemplate({ surveyId, surveyCode })}
          {...{
            titleLink,
            configLink,
            launchSurveyClick,
            history,
            responseCount,
            formattedTimestamp,
            members,
            survey,
            currentUser,
            fetchProjects,
            fetchProjectSurveys,
            fetchOrganizationSurveys,
            surveyLocks,
            isAllSurveys: true,
          }}
        />
      );
    });
  };

  return (
    <RightColumn>
      <TopRow>
        <SurveySort
          onSelectedItem={(item) => onSelectedSortOrder(item)}
          items={sortParams}
        />
      </TopRow>
      <TemplateCreationDialog
        showDialog={isTemplateDialogOpen}
        onDismiss={onDismissTemplate}
        surveyId={templateSurveyId}
      />
      <SurveysContainer>
        {/*Hide this until we find a way of adding surveys to projects on the homepage*/}
        {/*<BuildContainer>*/}
        {/*  <BuildNewSurvey*/}
        {/*  // onClick={addNewHilo}*/}
        {/*  // to={`${ADD}?project=${this.state.selectedProject}`}*/}
        {/*  >*/}
        {/*    Build*/}
        {/*    <br />*/}
        {/*    new survey*/}
        {/*    <PlusIcon />*/}
        {/*  </BuildNewSurvey>*/}
        {/*</BuildContainer>*/}
        {renderSurveyList()}
      </SurveysContainer>
    </RightColumn>
  );
};

export default OrganizationSurveyList;
