import {
  getArrayOfOptions,
  getNonPopulateQuestionOptions,
  getNumberOfParticipants,
  getUserName,
  sortAscByAnotherArray,
  sortDescByAnotherArray,
} from '../Helper';
import {
  DEFAULT_CHART_BUBBLE_RADIUS,
  OPTION_PREFIX,
  STACK_RANK_COLOR_PALETTE,
} from '../../../const/module-types';
import { isEmpty } from 'lodash';

function getRespondentFormattedData(moduleResponse, name) {
  return (collector, option) => {
    const entries = Object.entries(option);
    if (entries.length === 0) return collector;
    const [key, value] = entries[0];
    const { order } = moduleResponse[key] || {};
    if (!order) return collector;
    return [
      ...collector,
      {
        option: value,
        points: order,
        name,
        radius: DEFAULT_CHART_BUBBLE_RADIUS,
      },
    ];
  };
}

const getUserData = (moduleId, moduleList) => {
  return (acc, response, currentIndex) => {
    const answerData = response.answerData;
    const responseId = response.responseId;
    const moduleResponse = answerData[moduleId];
    if (!moduleResponse) return acc;
    const index = currentIndex + 1;
    const name = getUserName(response.userName, answerData, moduleList, index);
    return [...acc, { username: name, responseId, answers: moduleResponse }];
  };
};

const calculateDataRanking = (data, option) => {
  let points = 0;
  return data.reduce((acc, cv) => {
    if (cv.answers[option]) {
      points += cv.answers[option].order;
      return points;
    }
  }, 0);
};

// Derive the stack rank data from the options
// Then sort the array alphabetically before sorting it by rank
const formatStackRankDataFromOptions = (
  questionData,
  addedOptions,
  userResponses,
  moduleId,
  moduleList
) => {
  const responses = Object.values(userResponses);
  return Object.entries(questionData)
    .reduce((acc, [key, value]) => {
      if (key.indexOf(OPTION_PREFIX) !== 0 || addedOptions.includes(key))
        return acc;
      const users = responses.reduce(getUserData(moduleId, moduleList), []);
      const filteredUserAnswers = users.filter(({ answers }) => answers[key]);
      const rank = calculateDataRanking(filteredUserAnswers, key);
      return [
        ...acc,
        { key: key, option: value, rank, users: filteredUserAnswers },
      ];
    }, [])
    .sort((a, b) => {
      const aOption = a.option.toLowerCase();
      const bOption = b.option.toLowerCase();
      if (aOption < bOption) {
        return -1;
      }
      if (aOption > bOption) {
        return 1;
      }
      return 0;
    })
    .sort((a, b) => a.rank - b.rank);
};

const formatStackRankDataFromResponse = (
  userResponses,
  moduleList,
  activeQuestion,
  moduleId
) => {
  const questionData = activeQuestion.metaData.questionData;
  const addedOptions =
    activeQuestion.metaData?.questionData?.addedOptions || [];
  const options = getNonPopulateQuestionOptions(
    questionData,
    OPTION_PREFIX,
    addedOptions
  );
  const responses = Object.values(userResponses);
  const responsesPerRespondent = responses.reduce(
    (acc, response, currentIndex) => {
      const answerData = response.answerData;
      const moduleResponse = answerData[moduleId];
      if (!moduleResponse) return acc;
      const responseId = response.responseId;
      const index = currentIndex + 1;
      const name = getUserName(
        response.userName,
        answerData,
        moduleList,
        index
      );
      const nameLabel = `${name}-${currentIndex}`;
      const respondentData = options.reduce(
        getRespondentFormattedData(moduleResponse, name),
        []
      );
      return {
        ...acc,
        participantsData: {
          ...acc.participantsData,
          [responseId]: {
            name,
            nameLabel,
            responseId,
          },
        },
        participants: [
          ...acc.participants,
          {
            name,
            data: respondentData,
            responseId,
          },
        ],
      };
    },
    { participantsData: {}, participants: [] }
  );
  const { participantsData, participants } = responsesPerRespondent;
  return {
    options: formatStackRankDataFromOptions(
      questionData,
      addedOptions,
      userResponses,
      moduleId,
      moduleList
    ),
    participantsData,
    participants,
  };
};

const getComments = (participantsData) => {
  return Object.values(participantsData);
};

const getStackRankData = (
  userResponses,
  moduleList,
  activeQuestion,
  moduleId
) => {
  const {
    participantsData,
    participants,
    options,
  } = formatStackRankDataFromResponse(
    userResponses,
    moduleList,
    activeQuestion,
    moduleId
  );
  const numberOfParticipants = getNumberOfParticipants(participants);
  const chartData = options;
  const individualChartData = getIndividualStackRankData(options);
  const comments = getComments(participantsData);
  return {
    chartData,
    individualChartData,
    participants,
    participantsData,
    numberOfParticipants,
    comments,
  };
};

const getIndividualStackRankData = (groupData) => {
  if (!isEmpty(groupData)) {
    const { users } = groupData[0];

    return groupData.map((data, index) => {
      return {
        optionId: data.key,
        optionName: data.option,
        color: STACK_RANK_COLOR_PALETTE[index],
        data:
          !isEmpty(users) &&
          users?.map((user) => {
            return {
              x: user.responseId,
              y: user.answers[data.key].order,
              responseId: user.responseId,
              color: STACK_RANK_COLOR_PALETTE[index],
            };
          }),
      };
    });
  }
  return;
};

/**
 * Calculate the overall total for each option. e.g
 * [{ title: 'apples', score: 19}]
 * @param data
 * @returns {*}
 */
const calculateCumulativeOptionTotal = (data) => {
  return data.reduce((acc, cv) => {
    acc[cv.option] = {
      ...(acc[cv.option] || {}),
      option: cv.option,
      points:
        ((acc[cv.option] && acc[cv.option].points) || 0) +
        cv.points * cv.frequency,
    };
    return acc;
  }, {});
};

/**
 * Calculate the average for each option.
 * @param data
 * @param numberOfParticipants
 * @returns {*}
 */
const calculateAverage = (data, numberOfParticipants) => {
  return Object.values(data).map(({ option, points }) => ({
    option,
    average: points / numberOfParticipants,
  }));
};

const sortOptionsAscUsingAverage = (data) =>
  data.sort((a, b) => a.average - b.average);

/**
 * Do all the calculations and send back the sorted array of titles.
 * @param rankedData
 * @param numberOfParticipants
 * @returns {*}
 */
function getSortedArrayOfOptions(rankedData, numberOfParticipants) {
  const overallTotals = calculateCumulativeOptionTotal(rankedData);
  const optionsWithAverages = calculateAverage(
    overallTotals,
    numberOfParticipants
  );
  const sortedOptionsWithAverages = sortOptionsAscUsingAverage(
    optionsWithAverages
  );
  return getArrayOfOptions(sortedOptionsWithAverages);
}

/**
 * For stack rank, the item with the lowest average appears on top, that
 * is why we are considering this the asc. ave of 2, appears above 3.
 * @param rankedData
 * @param numberOfParticipants
 * @returns {*}
 */
const sortStackRankDataAsc = (rankedData, numberOfParticipants) => {
  const arrayOfOptions = getSortedArrayOfOptions(
    rankedData,
    numberOfParticipants
  );
  return sortDescByAnotherArray(rankedData, arrayOfOptions);
};

const sortStackRankDataDesc = (rankedData, numberOfParticipants) => {
  const arrayOfOptions = getSortedArrayOfOptions(
    rankedData,
    numberOfParticipants
  );
  return sortAscByAnotherArray(rankedData, arrayOfOptions);
};

export { getStackRankData, sortStackRankDataAsc, sortStackRankDataDesc };
