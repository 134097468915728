import { db, firebase } from '../firebase';
import {
  ADD_OPTION,
  MULTI_SELECT,
  POINT_DISTRIBUTION,
  STACK_RANK,
} from 'const/module-types';

const modulesCollection = db.collection('modules');

/**
 * Update a survey module with the provided data
 * This is used in the populatePanel to update
 * the populateable modules options.
 * @param moduleId
 * @param questionOptionDataKey
 * @param value
 * @param moduleType
 * @param optionKey
 * @returns {Promise<void>}
 */
export const updatePopulateModule = async ({
  moduleId,
  questionOptionDataKey,
  value,
  moduleType,
  optionKey = null,
}) => {
  let data = {
    [questionOptionDataKey]: value,
  };
  if (moduleType === STACK_RANK || moduleType === POINT_DISTRIBUTION) {
    data = {
      ...data,
      'questionData.addedOptions': firebase.firestore.FieldValue.arrayUnion(
        optionKey
      ),
    };
  }
  return modulesCollection.doc(moduleId).update(data);
};

export const updatePopulateOption = async ({
  moduleId,
  moduleType,
  questionOptionDataKey,
  optionValue,
}) => {
  if (moduleType === POINT_DISTRIBUTION || moduleType === STACK_RANK) {
    return modulesCollection.doc(moduleId).update({
      [questionOptionDataKey]: optionValue,
    });
  }

  if (moduleType === MULTI_SELECT || moduleType === ADD_OPTION) {
    return modulesCollection.doc(moduleId).update({
      [`${questionOptionDataKey}.title`]: optionValue,
    });
  }

  return null;
};

export const deletePopulateOption = async ({
  moduleId,
  moduleType,
  questionOptionDataKey,
}) => {
  return modulesCollection.doc(moduleId).update({
    [`${questionOptionDataKey}.isShown`]: false,
  });
};
