import React, { useState } from "react";
import styled from "styled-components";
import Tooltip from "@reach/tooltip";
import "@reach/tooltip/styles.css";
import { ReactComponent as PlayIcon } from "../../../img/play-2.svg";
import { ReactComponent as EditIcon } from "../../../img/edit-plain.svg";
import { ReactComponent as BoxedPlusIcon } from "../../../img/boxed-plus.svg";
import { ReactComponent as CloseIcon } from "../../../img/close-plain.svg";
import { ReactComponent as SettingsIcon } from "../../../img/settings.svg";
import { ReactComponent as TrashIcon } from "../../../img/trash-white.svg";

const Container = styled.div`
  background-color: ${({ detailsMode }) => (detailsMode ? "white" : "#6A36FF")};
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  transition: background-color 0.1s, border-color 0.1s;
  position: relative;
  overflow: visible;
  &:before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: opacity 0.1s;
    box-shadow: 0px 0px 8px rgba(28, 164, 252, 0.5);
    opacity: 0;
    pointer-events: none;
  }
  &:hover {
    text-decoration: none;
    border-color: #6a36ff;
    &:before {
      opacity: 1;
    }
  }
  padding: ${({ settingsMode }) => (settingsMode ? 0 : "1rem")};
  font-family: "Neue Haas Grotesk Display Std", sans-serif;
  height: 280px;
  flex-grow: 1;
`;

const TemplateTitle = styled.h1`
  font-size: 2.25rem;
  font-weight: bold;
  line-height: 1;
  letter-spacing: -0.06rem;
  flex: 1 0 auto;
  color: black;
  transition: color 50ms;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  margin-top: 0px;
  margin-bottom: 0px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const Button = styled.button`
  background: transparent;
  border: 0;
  padding: 0;
`;

const DetailsContainer = styled.div`
  font-size: 18px;
  margin-bottom: 0px;
`;

const DetailParagraph = styled.p`
  color: #6a36ff;
  marginbottom: 16px;
  font-size: 18px;
`;

const ReadDescriptionBtn = styled(Button)`
  color: #6a36ff;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const IconsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const DescriptionParagraph = styled.p`
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const IconButton = styled(Button)`
  stroke: black;
  &:hover {
    stroke: #6a36ff;
  }
`;

const CloseDescriptionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const DetailValue = styled.span`
  color: #000;
`;

const TitleSectionContainer = styled.div`
  height: 8.5rem;
  display: flex;
`;

const OwnerTag = styled(DetailParagraph)`
  font-size: 20px;
  font-weight: 700;
  margin-top: 15px;
`;

const SettingButton = styled(Button)`
  display: flex;
  width: 100%;
  outline: 0;
  padding: 12px 1.625rem;
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

const SettingsContainer = styled.div`
  padding: 1rem 0;
  padding-bottom: 0;
  font-size: 18px;
  color: #fff;
  font-weight: 500;
`;

export default function TemplateCard({
  isOwned,
  template,
  onClickEditBtn,
  handleTurnIntoSurveyClick,
  onClickPreviewBtn,
  onClickDeleteBtn,
  sortedByUsageCount
}) {
  const [mode, setMode] = useState("details");

  return (
    <Container
      settingsMode={mode === "settings"}
      detailsMode={mode === "details"}
    >
      {mode === "details" && (
        <>
          <TitleSectionContainer>
            <div css={{ marginRight: "1rem" }}>
              <TemplateTitle>
                {`${template.name}${
                  sortedByUsageCount ? ` (${template.usageCount || 0})` : ""
                }`}
              </TemplateTitle>
              {isOwned && <OwnerTag>(Created by myself)</OwnerTag>}
            </div>
            {isOwned && (
              <Tooltip label="Manage">
                <Button
                  onClick={() => setMode("settings")}
                  css={{ marginLeft: "auto", height: "fit-content" }}
                >
                  <SettingsIcon />
                </Button>
              </Tooltip>
            )}
          </TitleSectionContainer>
          <DetailsContainer>
            <DetailParagraph>
              Creator: <DetailValue>{template.creatorName}</DetailValue>
            </DetailParagraph>
            <DetailParagraph>
              Organization:{" "}
              <DetailValue>{template.organizationName}</DetailValue>
            </DetailParagraph>
            <ReadDescriptionBtn onClick={() => setMode("description")}>
              Read description
            </ReadDescriptionBtn>
          </DetailsContainer>
          <IconsContainer>
            {isOwned && (
              <Tooltip label="Edit">
                <IconButton onClick={onClickEditBtn}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip label="Place template">
              <IconButton
                onClick={handleTurnIntoSurveyClick}
                css={{ marginLeft: "20px" }}
              >
                <BoxedPlusIcon />
              </IconButton>
            </Tooltip>
            <Tooltip label="Preview">
              <IconButton
                onClick={onClickPreviewBtn}
                css={{ marginLeft: "20px" }}
              >
                <PlayIcon />
              </IconButton>
            </Tooltip>
          </IconsContainer>
        </>
      )}
      {mode === "description" && (
        <>
          <CloseDescriptionContainer>
            <Button onClick={() => setMode("details")}>
              <CloseIcon style={{ stroke: "#FFF" }} />
            </Button>
          </CloseDescriptionContainer>
          <DescriptionParagraph>{template.description}</DescriptionParagraph>
        </>
      )}
      {mode === "settings" && (
        <SettingsContainer>
          <SettingButton onClick={onClickDeleteBtn}>
            <span>Delete Template</span>
            <TrashIcon css={{ marginLeft: "auto", stroke: "#FFF" }} />
          </SettingButton>
          <div
            css={{ borderBottom: "1px dashed white", margin: "0 1.625rem" }}
          />
          <SettingButton onClick={onClickEditBtn}>
            <span>Edit Template</span>
            <EditIcon css={{ marginLeft: "auto", stroke: "#FFF" }} />
          </SettingButton>
          <SettingButton onClick={handleTurnIntoSurveyClick}>
            <span>Add Template</span>
            <BoxedPlusIcon css={{ marginLeft: "auto", stroke: "#FFF" }} />
          </SettingButton>
          <SettingButton onClick={onClickPreviewBtn}>
            <span>Preview Template</span>
            <PlayIcon css={{ marginLeft: "auto", stroke: "#FFF" }} />
          </SettingButton>
          <div
            css={{ borderBottom: "1px dashed white", margin: "0 1.625rem" }}
          />
          <SettingButton onClick={() => setMode("details")}>
            <span css={{ marginLeft: "auto" }}>Exit</span>
          </SettingButton>
        </SettingsContainer>
      )}
    </Container>
  );
}
