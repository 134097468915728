import React from 'react';
import ReactDOMServer from 'react-dom/server';
import styled from 'styled-components';

const ChartSortAscSelectedSVG = ReactDOMServer.renderToString(
  <svg
    width="51"
    height="32"
    viewBox="0 0 51 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="51" height="32" fill="#3FAFFA" />
    <rect x="9" y="7" width="33" height="4" fill="white" />
    <rect x="9" y="14" width="22" height="4" fill="white" />
    <rect x="9" y="21" width="14" height="4" fill="white" />
  </svg>
);

const ChartSortAscSVG = ReactDOMServer.renderToString(
  <svg
    width="51"
    height="32"
    viewBox="0 0 51 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="50" height="31" stroke="#3FAFFA" />
    <rect x="9" y="7" width="33" height="4" fill="#3FAFFA" />
    <rect x="9" y="14" width="22" height="4" fill="#3FAFFA" />
    <rect x="9" y="21" width="14" height="4" fill="#3FAFFA" />
  </svg>
);

const ChartSortDescSelectedSVG = ReactDOMServer.renderToString(
  <svg
    width="51"
    height="32"
    viewBox="0 0 51 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.5"
      width="50"
      height="31"
      fill="#3FAFFA"
      stroke="#3FAFFA"
    />
    <rect
      width="33"
      height="4"
      transform="matrix(1 0 0 -1 12 25)"
      fill="white"
    />
    <rect
      width="22"
      height="4"
      transform="matrix(1 0 0 -1 12 18)"
      fill="white"
    />
    <rect
      width="14"
      height="4"
      transform="matrix(1 0 0 -1 12 11)"
      fill="white"
    />
  </svg>
);

const ChartSortDescSVG = ReactDOMServer.renderToString(
  <svg
    width="51"
    height="32"
    viewBox="0 0 51 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="50" height="31" stroke="#3FAFFA" />
    <rect
      width="33"
      height="4"
      transform="matrix(1 0 0 -1 12 25)"
      fill="#3FAFFA"
    />
    <rect
      width="22"
      height="4"
      transform="matrix(1 0 0 -1 12 18)"
      fill="#3FAFFA"
    />
    <rect
      width="14"
      height="4"
      transform="matrix(1 0 0 -1 12 11)"
      fill="#3FAFFA"
    />
  </svg>
);

const ChartHeader = styled('div')`
  display: flex;
  width: ${(props) => (props.openEnded ? '100%' : '150%')};
  justify-content: ${(props) => (props.flexEnd ? 'flex-end' : 'space-between')};
  margin-bottom: ${(props) => (props.MarginBottom ? '4rem' : '')};
  margin-left: ${(props) =>
    props.ControlPosition ? '2rem' : props.MarginLeft ? '4em' : ''};
`;

const ChartSortAscImg = styled('button')`
  background-image: url(data:image/svg+xml;base64,${(props) =>
    props.selected ? btoa(ChartSortAscSelectedSVG) : btoa(ChartSortAscSVG)});
  background-repeat: no-repeat;
  background-color: #fff;
  width: 3.3em;
  height: 2em;
  padding: 0;
  border: none;
  outline: none;
  background-size: 3.3em 2em;
  margin-right: 0.5em;
`;

const ChartSortContainer = styled('div')`
  padding-left: 4.5em;
  @media (min-width: 1024px) {
    padding-left: 6.5em;
  }
`;

export const PresenationBtnStyle = styled('div')`
  padding-right: 4.5em;
  @media (min-width: 1024px) {
    padding-right: 6.5em;
  }
`;

const ChartSortDescImg = styled('button')`
  background-image: url(data:image/svg+xml;base64,${(props) =>
    props.selected ? btoa(ChartSortDescSelectedSVG) : btoa(ChartSortDescSVG)});
  background-repeat: no-repeat;
  background-color: #fff;
  width: 3.3em;
  height: 2em;
  padding: 0;
  border: none;
  outline: none;
  background-size: 3.3em 2em;
`;

export { ChartHeader, ChartSortAscImg, ChartSortContainer, ChartSortDescImg };
