export const styles = {
  containerOverlay: {
    display: 'inline',
    flexDirection: 'column',
    zIndex: '100',
    position: 'absolute',
    top: '132%',
    // left: '70%',
    right: '10%',
    border: '5px solid #F1F2F5',
    borderRadius: '8px',
    backgroundColor: '#F1F2F5',
    padding: '10px 10px 30px',
    width: '90%',
    flexDirection: 'column',
  },
  addForm: {
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    height: '50px',
    border: 'none',
    boxShadow: 'inset 1px 0px 9px 0px #919293a8',
    outline: 'none',
    borderRadius: '5px',
    padding: '0 15px',
    fontSize: '18px',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: '30px',

    button: {
      border: '0.125rem solid',
      padding: '5px 15px',
      width: '100px',
      borderRadius: '6px',
    },
  },
  addBtn: {
    borderColor: '#1CA4FC',
    color: '#1CA4FC',
    '&:disabled': {
      color: 'grey',
    },
  },
};
