import React, { useState } from 'react';
import styled from 'styled-components';

import {
  updateSurveyClosed,
  updateSurveyCloseDate,
  deleteSurvey,
  getIsClosed,
  launchSurvey
} from '../../lib/builder-api';
import { cloneSurvey } from '../../lib/admin-api';
import { Button2 } from '../layout';
import { ToggleClosed } from '../Builder/PreviewHeader';
import { EDIT, ADMIN } from '../../const/routes';

import {
  ManageCopySvg,
  ManageDuplicateSvg,
  ManageEditSvg,
  ManageTrashSvg
} from '../inline-svg';

const ManageCell = styled('div')`
  flex: 0 0 65px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ManageButton = styled(ManageCell)``;

export const MenuContainer = styled('div')`
  ${({ inManageMode, isSettingsVisible }) =>
    inManageMode
      ? `
  `
      : `
  background: #1ca4fc;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 3;
  transition: opacity 0.1s ease;
  opacity: ${isSettingsVisible ? 1 : 0};
  pointer-events: ${isSettingsVisible ? 'auto' : 'none'};
  color: #ffffff;
  `}
`;

const MenuHr = styled('div')`
  ${({ inManageMode }) =>
    inManageMode
      ? `
  
  border-bottom: 1px dashed #000000;
  margin: 0 80px;
  `
      : `
  border-bottom: 1px dashed #ffffff;
  margin: 0 22px;
  `}
`;

const MenuRow = styled('div')`
  display: flex;
  line-height: 25px;
  font-family: Neue Haas Grotesk Display Std;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: normal;
  padding: 0 22px;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')};
  height: 43px;
  box-sizing: border-box;
  align-items: center;
  &:hover {
    background-color: ${({ onClick }) =>
      onClick ? 'rgba(255, 255, 255, 0.2)' : 'transparent'};
  }
  ${({ inManageMode, onClick }) =>
    inManageMode
      ? `
  display: flex;
  height: 45px;
  align-items: center;
  cursor: pointer;
  padding: 0 80px;
  color: #262525;
  &:hover {
    background-color: ${onClick ? '#1ca4fc' : 'transparent'};
    color: white;
    ${Button2} {
      border-color: white;
      color: white;
    }
    ${ManageButton} {
      path,
      rect {
        stroke: white;
      }
      .fill {
        fill: white;
      }
    }
  }
  `
      : `
  ${Button2} {
    border-color: white;
    color: white;
  }
  ${ManageButton} {
    path,
    rect {
      stroke: white;
    }
    .fill {
      fill: white;
    }
  }    
  `}
`;
const DeleteRow = styled(MenuRow)`
  ${({ inManageMode }) => (inManageMode ? `` : `height: 49px`)};
`;
const ExitRow = styled(MenuRow)`
  height: 47px;
`;
const MenuLabel = styled('div')`
  flex: 1 0 auto;
`;
const MenuIcon = styled('div')`
  flex: 0 0 45px;
  cursor: pointer;
`;

const DateInput = styled('input')`
  color: black;
`;

const SurveyCardMenu = ({
  survey,
  isSettingsVisible,
  hideSettings,
  currentUser,
  reloadData,
  inManageMode,
  history,
  showSurveyLaunched,
  toggleClosedExternal
}) => {
  const { id: userId, name: userName, email: userEmail, organizationId, organizationName } = currentUser || {};
  const { surveyId, projectId } = survey;
  const codeRef = React.createRef();
  const copyText = () => {
    const copyArea = codeRef.current;
    copyArea.focus();
    window.getSelection().selectAllChildren(copyArea);
    try {
      document.execCommand('copy');
    } catch (e) {
      console.log(e);
    }
  };

  const [showCloseDate, setShowCloseDate] = useState(false);
  const [closeDate, setCloseDate] = useState(null);

  const toggleCloseDate = () => {
    if (
      closeDate === null ||
      closeDate === '' ||
      closeDate.match(/^\d+-\d+-\d+$/)
    ) {
      setShowCloseDate(!showCloseDate);
      if (closeDate !== null) {
        updateSurveyCloseDate({
          projectId,
          surveyId,
          userId,
          closeDate,
          survey
        });
        reloadData();
      }
    }
  };

  const closed = getIsClosed(survey);
  const toggleClosedInternal = () => {
    const newClosed = !closed;
    updateSurveyClosed({
      closed: newClosed,
      surveyId: surveyId,
      userId: currentUser.id,
      shouldIncludeTimestamp: false
    }).then(() => {
      reloadData();
    });
  };
  const toggleClosed =
    toggleClosedExternal && showSurveyLaunched
      ? closed
        ? showSurveyLaunched
        : toggleClosedExternal
      : toggleClosedInternal;

  const onDateChange = e => {
    const value = e.currentTarget.value;
    const digitValues = value ? value.replace(/[^\d]/g, '') : '';
    const strippedValueMatch = digitValues.match(
      /(\d{1,2})(\d{1,2})?(\d{1,2})?/
    );
    const strippedValue = strippedValueMatch
      ? `${strippedValueMatch[1] || ''}-${strippedValueMatch[2] ||
          ''}-${strippedValueMatch[3] || ''}`.replace(/-*$/, '')
      : '';
    setCloseDate(strippedValue);
  };

  const onClickDuplicate = () => {
    return cloneSurvey({
      projectId,
      surveyId,
      userId,
      userName,
      userEmail,
      organizationId,
      organizationName
    }).then(({ projectId: newProjectId, surveyId: newSurveyId }) => {
      //reloadData()
      if (inManageMode) {
        history.push(`${EDIT}?project=${newProjectId}&survey=${newSurveyId}`);
      } else {
        reloadData();
      }
    });
  };

  const onKeyDownDate = e => {
    if (e.keyCode === 13) {
      toggleCloseDate();
    }
  };

  const displayCloseDate = closeDate !== null ? closeDate : survey.closeDate;

  const onDeleteClick = () => {
    const okToDelete = window.confirm(
      `${survey.name}      
Are you sure you want to delete this survey?`
    );
    if (!okToDelete) return;
    return deleteSurvey({ projectId, surveyId }).then(() => {
      if (inManageMode) {
        history.push(`${ADMIN}?projectId=${projectId}`, { projectId });
      } else {
        reloadData();
      }
    });
  };

  const isClosed = getIsClosed(survey);

  const onLaunchClick = () => {
    const { id: userId } = currentUser;
    return launchSurvey({ surveyId, userId }).then(() => {
      reloadData();
    });
  };

  const deleteRow = (
    <DeleteRow onClick={onDeleteClick} {...{ inManageMode }}>
      <MenuLabel>Delete survey</MenuLabel>
      <ManageButton>
        <ManageTrashSvg />
      </ManageButton>
    </DeleteRow>
  );

  const duplicateRow = (
    <MenuRow onClick={onClickDuplicate} {...{ inManageMode }}>
      <MenuLabel>Duplicate survey</MenuLabel>
      <ManageButton>
        <ManageDuplicateSvg />
      </ManageButton>
    </MenuRow>
  );

  const exitRow = (
    <ExitRow onClick={hideSettings} {...{ inManageMode }}>
      <MenuLabel />
      <MenuIcon>Exit</MenuIcon>
    </ExitRow>
  );

  const scheduleCloseRow = survey.unpublished ? (
    <MenuRow {...{ inManageMode }} />
  ) : (
    <MenuRow onClick={toggleCloseDate} {...{ inManageMode }}>
      <MenuLabel>
        Schedule to close{' '}
        <span onClick={e => e.stopPropagation()}>
          {showCloseDate ? (
            <DateInput
              value={displayCloseDate}
              onChange={onDateChange}
              placeholder="mm-dd-yy"
              onKeyDown={onKeyDownDate}
            />
          ) : (
            <strong>{displayCloseDate}</strong>
          )}
        </span>
      </MenuLabel>
      <ManageButton>
        {showCloseDate && inManageMode ? null : <ManageEditSvg />}
      </ManageButton>
    </MenuRow>
  );

  const copyCodeRow = survey.unpublished ? (
    <MenuRow {...{ inManageMode }} />
  ) : (
    <MenuRow onClick={copyText} {...{ inManageMode }}>
      <MenuLabel>
        Code <strong ref={codeRef}>{survey.surveyCode}</strong>
      </MenuLabel>
      <ManageButton icon={ManageCopySvg}>
        <ManageCopySvg />
      </ManageButton>
    </MenuRow>
  );

  const toggleCloseLaunchRow = survey.unpublished ? (
    <MenuRow onClick={onLaunchClick} {...{ inManageMode }}>
      <MenuLabel>Survey not launched</MenuLabel>
      <MenuIcon>
        <Button2 color="white">Launch</Button2>
      </MenuIcon>
    </MenuRow>
  ) : (
    <MenuRow onClick={toggleClosed} {...{ inManageMode }}>
      <MenuLabel>Survey {isClosed ? 'closed' : 'launched'}</MenuLabel>
      <ManageCell>
        <ToggleClosed
          {...{
            surveyDetails: survey,
            shortButton: true
          }}
        />
      </ManageCell>
    </MenuRow>
  );
  const stopClick = e => e.stopPropagation();
  if (inManageMode) {
    return (
      <MenuContainer
        {...{ isSettingsVisible, inManageMode, onClick: stopClick }}
      >
        {toggleCloseLaunchRow}
        {copyCodeRow}
        {scheduleCloseRow}
        <MenuHr {...{ inManageMode }} />
        {duplicateRow}
        <MenuHr {...{ inManageMode }} />
        {deleteRow}
      </MenuContainer>
    );
  }

  return (
    <MenuContainer {...{ isSettingsVisible, inManageMode, onClick: stopClick }}>
      {deleteRow}
      <MenuHr />
      {toggleCloseLaunchRow}
      {copyCodeRow}
      {scheduleCloseRow}
      {duplicateRow}
      <MenuHr />
      {exitRow}
    </MenuContainer>
  );
};

export default SurveyCardMenu;
