import styled from "styled-components";

export const HiloDialogTextContainer = styled("div")`
  padding-top: ${props => props.paddingTop || "30"}px;
  padding-left: ${props => props.paddingLeft || "30"}px;
  padding-right: 30px;
  margin-bottom: ${props => props.marginBottom || "0"}px;
  display: ${props => props.display || 'block'};
`;

export const HiloDialogContentContainer = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;