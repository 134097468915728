export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const LANDING = '/';
export const ADMIN = '/admin';
export const BUILDER = '/builder';
export const ADD = '/add';
export const EDIT = '/edit';
export const MANAGE = '/manage';
export const SETTINGS = '/settings';
export const LAYOUT = '/layout';
export const SURVEYROOT = '/survey';
export const SURVEYSTART = `${SURVEYROOT}/:surveyCode`;
export const SURVEY = `${SURVEYROOT}/:surveyCode/:slide`;
export const RESULTROOT = `/result`;
export const RESULT = `${RESULTROOT}/:resultSurveyCode/:slide/:responseId`;
export const PREVIEWROOT = `/preview`;
export const PREVIEW = `${PREVIEWROOT}/:previewSurveyCode/:slide`;
export const FACILITATIONROOT = `/facilitation`;
export const FACILITATION = `${FACILITATIONROOT}/:facilitationSurveyCode/:slide`;
export const COMPLETE = 'complete';
export const REVIEW = 'review';
export const EXPORT = '/export';
export const PRESENTATIONROOT = '/presentation';
export const NEW_PRESENTATION_ROOT = '/presentation/new';
export const PRESENTATION = `${PRESENTATIONROOT}/:presentationSurveyCode/:slide`;
export const NEW_PRESENTATION = `${NEW_PRESENTATION_ROOT}/:presentationSurveyCode`;
export const FULLPRESENTATIONROOT = '/fullpresentation';
export const FULLPRESENTATION = `${FULLPRESENTATIONROOT}/:presentationSurveyCode`;
export const TEMPLATES_GALLERY = '/admin/templates';
export const WORKSHOP_ROOT = '/workshop';
export const WORKSHOP = `${WORKSHOP_ROOT}/:workshopCode/:slide`;

export const PROJECT_ID_QUERY_KEY = 'project_id';
export const PROJECT_ALL_QUERY_KEY = 'showAll';
