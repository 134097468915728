import { isPopulatedOptions } from 'components/utils/helpers';
import { cloneDeep } from 'lodash';
import { Timestamp } from '../firebase';

function isObject(val) {
  if (val === null) {
    return false;
  }
  return typeof val === 'function' || typeof val === 'object';
}

function getTimestamp(timestamp) {
  if (isObject(timestamp)) {
    return new Timestamp(timestamp.seconds, timestamp.nanoseconds);
  }
  return timestamp;
}

export const sortByLastSaved = (a, b) => {
  const timestampA =
    (getTimestamp(a.timestamp) || 0) && getTimestamp(a.timestamp).valueOf();
  const timestampB =
    (getTimestamp(b.timestamp) || 0) && getTimestamp(b.timestamp).valueOf();
  if (timestampA > timestampB) {
    return -1;
  } else if (timestampA < timestampB) {
    return 1;
  } else {
    return 0;
  }
};

export const sortByName = (a, b) => {
  return (a.name || '').localeCompare(b.name || '');
};

export const sortByRecentlyCreated = (a, b) => {
  const timestampA =
    (getTimestamp(a.createTimestamp) || 0) &&
    getTimestamp(a.createTimestamp).valueOf();
  const timestampB =
    (getTimestamp(b.createTimestamp) || 0) &&
    getTimestamp(b.createTimestamp).valueOf();
  if (timestampA > timestampB) {
    return -1;
  } else if (timestampA < timestampB) {
    return 1;
  } else {
    return 0;
  }
};

export const sortByUsageCount = (a, b) => {
  const usageCountA = a.usageCount || 0;
  const usageCountB = b.usageCount || 0;
  if (usageCountA > usageCountB) {
    return -1;
  } else if (usageCountA < usageCountB) {
    return 1;
  } else {
    return 0;
  }
};

export const canCurrentUserEditSurvey = (survey, userId) => {
  if (!userId) return false;
  if (survey.creator) {
    return survey.creator === userId;
  }
  return false;
};

export const removeOptionsFromPopulateModule = (moduleId, moduleList) => {
  let moduleListCopy = cloneDeep(moduleList);
  const isPopulate = moduleListCopy[moduleId]?.questionData?.populateOptions;
  const moduleQuestionData = moduleListCopy[moduleId]?.questionData;
  if (!isPopulate) return moduleListCopy;

  if (
    isPopulate &&
    isPopulatedOptions(Object.keys(moduleQuestionData)) === false
  ) {
    const questionData = Object.entries(moduleListCopy[moduleId]?.questionData);
    questionData.forEach(([key, value]) => {
      if (!key.indexOf('option')) {
        delete moduleListCopy[moduleId]?.questionData[key];
      }
    });
  }

  return moduleListCopy;
};

/**
 * Check if the current user/response with the responseId is among the
 * responseIds gotten when a user clicks a chart Bar.
 * @param responseIds
 * @param responseId
 */
export const isHighlighted = ({ responseIds, responseId }) =>
  responseIds?.includes(responseId);

export const isIfster = ({ email }) => {
  const anIfster = /intentionalfutures.com/;
  const isIF = anIfster.exec(email);
  return !!isIF;
};
