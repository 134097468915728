import Airtable from 'airtable';
import { db, serverTimestamp } from '../firebase';
import { NAME, POINTS } from '../const/module-types';

import { fetchSurveyByCode, generateSurveyCode } from './admin-api';
import { fetchSurveyModules } from './builder-api';

const modulesCollection = db.collection('modules');
const surveysCollection = db.collection('surveys');
const projectsCollection = db.collection('projects');

export const appendUserIdAndName = ({
  array,
  userId,
  userName,
  responseId,
  explanation,
}) => {
  return array.map((hash) => {
    return {
      ...hash,
      userId,
      userName,
      responseId,
      explanation,
    };
  });
};

export const snapshotToJson = ({ snapshot, idKey = 'id' }) => {
  if (!snapshot.exists) return null;
  const id = snapshot.id;
  const data = snapshot.data();
  const json = {
    id,
    [idKey]: id,
    ...data,
  };
  return json;
};

export const snapshotToArray = ({ snapshot, idKey = 'id' }) => {
  return snapshot.docs.map((snapshotDoc) => {
    const snapshotDetails = snapshotToJson({
      snapshot: snapshotDoc,
      idKey,
    });
    return snapshotDetails;
  });
};

export const fetchSurveyAndModulesByCode = (options = {}) => {
  const { surveyCode } = options;
  if (!surveyCode) throw new Error('please provide a surveyCode');
  return fetchSurveyByCode({ surveyCode })
    .then((surveyDocs) => {
      let survey = surveyDocs.docs[0];
      return survey;
    })
    .then((survey) => {
      if (!!survey) return survey;
      return surveysCollection
        .where('templateId', '==', surveyCode)
        .get()
        .then((res) => res.docs[0]);
    })
    .then((survey) => {
      const surveyId = survey && survey.id;
      if (!surveyId) throw new Error(`surveyId ${surveyId} not found`);
      return fetchSurveyModules({ surveyId }).then((modules) => {
        const surveyDetails = {
          surveyId: survey.id,
          ...survey.data(),
        };
        const moduleList = modules.docs.map((doc) => {
          return {
            moduleId: doc.id,
            ...doc.data(),
          };
        });
        return { surveyDetails, moduleList };
      });
    });
};

export const surveyStateToAnswerData = (options = {}) => {
  const {
    plotterData = {},
    stackRank = {},
    pointDistribution = {},
    textHighlight = {},
    spectrumDistribution = {},
    explanation = {},
    matrix = {},
    personalInfo = {},
    madlib = {},
    openEnded = {},
    multiSelect = {},
    addOption = {},
  } = options;
  let answerData = {
    ...plotterData,
    ...stackRank,
    ...pointDistribution,
    ...textHighlight,
    ...matrix,
    ...personalInfo,
    ...madlib,
    ...openEnded,
    ...multiSelect,
    ...addOption,
  };
  // below we merge answer options onto the answerData[moduleId]
  Object.entries(explanation).forEach(([moduleId, explanationHash]) => {
    answerData[moduleId] = { ...answerData[moduleId], ...explanationHash };
  });
  Object.entries(spectrumDistribution).forEach(
    ([moduleId, spectrumDistributionHash]) => {
      answerData[moduleId] = {
        ...answerData[moduleId],
        ...spectrumDistributionHash,
      };
    }
  );
  return answerData;
};

export const saveSurveyResponse = (options = {}) => {
  const {
    projectId,
    surveyId,
    userId,
    userName,
    userEmail,
    answerData,
    savedResponseId,
    selectedReviewCode,
    startSurveyTimestamp,
  } = options;
  if (!projectId) throw new Error('please provide a projectId');
  if (!surveyId) throw new Error('please provide a surveyId');
  if (!userId) throw new Error('please provide a userId');
  const survey = surveysCollection.doc(surveyId);
  const responseCollection = survey.collection('response');
  const batch = db.batch();
  const timestamp = serverTimestamp();
  return getAvailableResponseCode({ surveyId }).then(({ responseCode }) => {
    const responseId = selectedReviewCode || savedResponseId || responseCode;
    const response = responseCollection.doc(responseId);
    const responseDetails = {
      responseId,
      responseCode: responseId,
      answerData,
      userId,
      userName,
      userEmail,
      timestamp,
      isDone: false,
      startSurveyTimestamp,
      endSurveyTimestamp: new Date(),
    };
    batch.set(response, responseDetails);
    return batch.commit().then(() => {
      return {
        responseId,
      };
    });
  });
};

export const saveResponse = async (options = {}) => {
  const {
    projectId,
    surveyId,
    userId,
    isDone,
    userName,
    userEmail,
    answerData,
    responseId,
    startSurveyTimestamp,
    isFacilitator,
  } = options;
  if (!projectId) throw new Error('please provide a projectId');
  if (!surveyId) throw new Error('please provide a surveyId');
  if (!userId) throw new Error('please provide a userId');
  if (!responseId) throw new Error('please provide a responseId');
  const survey = surveysCollection.doc(surveyId);
  const responseCollection = survey.collection('response');
  const timestamp = serverTimestamp();
  const response = isFacilitator
    ? responseCollection.doc()
    : responseCollection.doc(responseId);
  const responseDetails = {
    responseId,
    responseCode: responseId,
    answerData,
    userId,
    userName,
    userEmail,
    timestamp,
    startSurveyTimestamp,
    isDone,
    endSurveyTimestamp: new Date(),
  };
  return response.set(responseDetails, { merge: true });
};

export const updateSurveyResponse = (options = {}) => {
  const { newResponse, responseId, surveyId } = options;
  const survey = surveysCollection.doc(surveyId);
  const responseCollection = survey.collection('response');
  const response = responseCollection.doc(responseId);
  const responseDetails = newResponse;
  return response.set(responseDetails, { merge: false });
};

export const getPersonalInfoName = ({ data }) => {
  const { userName: defaultName, answerData = {} } = data;
  const piUserName = Object.values(answerData).reduce(
    (collector, answerHash) => {
      const { [NAME]: name } = answerHash;
      return name || collector;
    },
    defaultName
  );
  return piUserName;
};

export const getUserResponseReducer = (showHidden) => {
  return (collector, doc) => {
    const responseId = doc.id;
    const data = doc.data();
    const { hidden, userName, userId, timestamp } = data;
    const piUserName = getPersonalInfoName({ data });
    const name = piUserName || userName;
    const nameToUse = name === 'Guest' ? userId : name;
    if (hidden && showHidden === false) return collector;
    return {
      ...collector,
      [responseId]: {
        ...data,
        userId,
        userName: nameToUse,
        timestamp,
      },
    };
  };
};

export const fetchSurveyResponses = (options = {}) => {
  const { surveyId, showHidden = false } = options;
  if (!surveyId) throw new Error('please provide a surveyId');
  const survey = surveysCollection.doc(surveyId);
  const responseCollection = survey.collection('response').orderBy('timestamp');
  return responseCollection.get().then((data) => {
    return data.docs.reduce(getUserResponseReducer(showHidden), {});
  });
};

export const fetchSurveyResponsesByResponseId = (options = {}) => {
  const { surveyId, responseId } = options;
  if (!surveyId) throw new Error('please provide a surveyId');
  if (!responseId) throw new Error('please provide a responseId');
  const survey = surveysCollection.doc(surveyId);
  const responseCollection = survey.collection('response').orderBy('timestamp');
  return responseCollection
    .doc(responseId)
    .get()
    .then((snapshot) => {
      const json = snapshotToJson({ snapshot, idKey: 'responseId' });
      if (!json) return {};
      const responseData = {
        [json.responseId]: json,
      };
      return responseData;
    });
};

const getModuleListReducer = (collector, doc) => {
  const { moduleId } = doc;
  return {
    ...collector,
    [moduleId]: doc,
  };
};

export const getModuleListListenerReducer = (collector, doc) => {
  const { moduleId } = doc.data();
  return {
    ...collector,
    [moduleId]: doc.data(),
  };
};

export const fetchSurveyModulesAndResponses = (options = {}) => {
  const {
    responseId,
    surveyCode,
    showAllUsers,
    modulesListFilterFn = () => true,
  } = options;
  const fetchSurvey = fetchSurveyAndModulesByCode({ surveyCode }).then(
    ({ surveyDetails, moduleList: modules }) => {
      const { surveyId } = surveyDetails;

      let fetchUserResponses = Promise.resolve({});
      if (responseId)
        fetchUserResponses = fetchSurveyResponsesByResponseId({
          surveyId,
          responseId,
        });
      if (showAllUsers) fetchUserResponses = fetchSurveyResponses({ surveyId });

      const moduleList = modules
        .filter((module) => modulesListFilterFn(module))
        .reduce(getModuleListReducer, {});
      return fetchUserResponses.then((userResponses) => {
        return { surveyDetails, moduleList, userResponses };
      });
    }
  );
  return fetchSurvey;
};

export const getCurrentPoints = (moduleResponse, skipKey) => {
  return Object.entries(moduleResponse).reduce((collector, [key, values]) => {
    if (key == skipKey) return collector;
    const value = values && values[POINTS] ? parseInt(values[POINTS], 10) : 0;
    return collector + value;
  }, 0);
};

export const deleteResponse = (options = {}) => {
  const { surveyId, userId } = options;
  if (!surveyId) throw new Error('please provide a surveyId');
  if (!userId) throw new Error('please provide a userId');
  const survey = surveysCollection.doc(surveyId);
  const responseCollection = survey.collection('response');
  return responseCollection.doc(userId).delete();
};

export const hideShowResponse = (options = {}) => {
  const { surveyId, userId, responseId, hidden = true } = options;
  if (!surveyId) throw new Error('please provide a surveyId');
  if (!responseId) throw new Error('please provide a responseId');
  const survey = surveysCollection.doc(surveyId);
  const responseCollection = survey.collection('response');
  return responseCollection.doc(responseId).update({
    hidden,
  });
};

export const fetchResponseByCode = (options = {}) => {
  const { surveyCode, responseCode } = options;
  if (!responseCode) throw new Error('please provide a responseCode');
  if (!surveyCode) throw new Error('please provide a surveyCode');
  return fetchSurveyByCode({ surveyCode }).then((snapshot) => {
    const survey = snapshotToJson({ snapshot, idKey: 'surveyId' });
    const { surveyId } = survey;
    const surveyDoc = snapshot;
    const responseCollection = surveyDoc.collection('response');
    const response = responseCollection
      .where('responseCode', '==', surveyCode)
      .get()
      .then((responseDocs) => {
        const responses = snapshotToArray({
          snapshot: responseDocs,
          idKey: 'responseId',
        });
        const response = responses[0];
        return {
          response,
          survey,
        };
      });
  });
};

export const getAvailableResponseCode = (options = {}) => {
  const { surveyId } = options;
  if (!surveyId) throw new Error('please provide a survey id');
  const survey = surveysCollection.doc(surveyId);
  const responseCollection = survey.collection('response');
  let responseCode = generateSurveyCode({ charCount: 8 });
  return new Promise((resolve, reject) => {
    const fetchResponseCode = () => {
      console.log(`testing code ${responseCode}`);
      return responseCollection
        .where('responseCode', '==', responseCode)
        .orderBy('timestamp', 'desc')
        .get()
        .then((responseDoc) => {
          if (responseDoc.empty) {
            console.log(`code ok ${responseCode}`);
            return resolve({ responseCode });
          }
          responseCode = generateSurveyCode({ charCount: 8 });
          return resolve({ responseCode });
        });
    };
    fetchResponseCode({ responseCode });
  });
};

export const sendSurveyEmail = (options = {}) => {
  const { surveyCode, responseCode, surveyEmail } = options;
  if (!surveyCode) throw new Error('please provide a surveyCode');
  if (!responseCode) throw new Error('please provide a responseCode');
  if (!surveyEmail) throw new Error('please provide a surveyEmail');
  return fetch(
    `/send-email?surveyCode=${surveyCode}&responseCode=${responseCode}&surveyEmail=${encodeURIComponent(
      surveyEmail
    )}`
  );
};

export const saveSurveyResponseEmail = (options = {}) => {
  const { surveyId, surveyCode, responseId, surveyEmail } = options;
  if (!surveyId) throw new Error('please provide a surveyId');
  if (!responseId) throw new Error('please provide a responseId');
  if (!surveyEmail) throw new Error('please provide a surveyEmail');
  const survey = surveysCollection.doc(surveyId);
  const responseCollection = survey.collection('response');
  const responseDoc = responseCollection.doc(responseId);
  return getAvailableResponseCode({ surveyId }).then(({ responseCode }) => {
    return responseDoc.get().then((snapshot) => {
      const response = snapshotToJson({ snapshot, idKey: 'responseId' });
      const existingOrNewResponseCode = response.responseCode || responseCode;
      const responseDetails = {
        surveyEmail,
        responseCode: existingOrNewResponseCode,
      };
      const batch = db.batch();
      const timestamp = serverTimestamp();
      console.log(JSON.stringify(responseDetails));
      batch.update(responseDoc, responseDetails);
      return batch
        .commit()
        .then(() => {
          return sendSurveyEmail({
            surveyCode,
            responseCode: existingOrNewResponseCode,
            surveyEmail,
          });
        })
        .then(() => {
          return {
            responseCode: existingOrNewResponseCode,
          };
        });
    });
  });
};

export const sendFeedback = (options = {}) => {
  const {
    apiKey = 'keyElKUtj5kxNE9C3',
    baseKey = 'appU6PPfUF5qDRLeD',
    email = '',
    feedback = '',
    surveyCode = '',
  } = options;
  Airtable.configure({
    endpointUrl: 'https://api.airtable.com',
    apiKey,
  });
  const base = Airtable.base(baseKey);
  return new Promise((resolve, reject) => {
    base('Survey Feedback').create(
      {
        Email: email,
        Feedback: feedback,
        'Survey Name': surveyCode,
        'Sent Date': new Date().toDateString(),
      },
      function(err, record) {
        if (err) {
          console.error(err);
          return reject(err);
        }
        console.log(record.getId());
        return resolve(record);
      }
    );
  });
};

export const getSurveyResponseWithResponseId = async (surveyId, responseId) => {
  try {
    const query = await surveysCollection
      .doc(surveyId)
      .collection('response')
      .orderBy('timestamp')
      .doc(responseId)
      .get();
    const data = query.data();
    return data || {};
  } catch (e) {
    throw e;
  }
};
