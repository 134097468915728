import styled, { keyframes } from 'styled-components';
import { Input } from 'components/layout';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

export const BodyContent = styled('div')`
  display: ${(props) => (props.loading ? 'none' : 'flex')};
  animation: ${fadeIn} 1.25s;
  max-width: 20.9375rem;
  width: 100%;
  padding: 4.25rem 1.25rem 1.25rem 1.25rem;
  margin: auto;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  @media (min-width: 1200px) {
    max-width: initial;
    padding: 0;
    width: 44.375rem;
  }
`;

export const Welcome = styled('div')`
  color: white;
  font-family: 'Neue Haas Grotesk Display Std', sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  @media (min-width: 1200px) {
    font-size: 3.75rem;
  }
`;

export const Description = styled('div')`
  color: white;
  font-family: 'Neue Haas Grotesk Display Std', sans-serif;
  font-size: 1.5rem;
  line-height: 1.1;
  font-weight: lighter;
  margin-top: 1.25rem;
  @media (min-width: 1200px) {
    margin-top: 2.375rem;
    font-size: 3.75rem;
  }
`;

export const EnterCode = styled(Input)`
  font-size: 1.5rem;
  font-weight: lighter;
  color: #1ca4fc;
  width: ${(props) => (props.width ? props.width : '21.5625rem')};
  max-width: 100%;
  margin-top: 1.375rem;
  margin-bottom: 0.625rem;
  @media (min-width: 1200px) {
    font-size: 3rem;
    margin-top: 2.375rem;
    margin-bottom: 1.5rem;
  }
`;
