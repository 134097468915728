import React from "react";
import Tooltip, { useTooltip, TooltipPopup } from "@reach/tooltip";
import "@reach/tooltip/styles.css";
import { Bar, VictoryPortal } from "victory";
import styled from "styled-components";

const InfoSVG = styled("svg")`
  &:hover {
    circle {
      stroke: #eb99ff;
    }
    path {
      fill: #eb99ff;
    }
  }
`;

const InfoIcon = ({ x, y, onClick }) => (
  <Tooltip
    label="Individual explanation"
    aria-label="Individual explanation"
    style={{ background: "#E5E5E5", color: "#000000", fontFamily: "Roboto" }}
  >
    <InfoSVG
      onClick={onClick}
      style={{ cursor: "pointer" }}
      width="10"
      height="10"
      viewBox="0 0 20 20"
      x={x}
      y={y}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      pointerEvents="all"
    >
      <circle
        cx="10"
        cy="10"
        r="9.42857"
        stroke="#FD9E46"
        strokeWidth="1.14286"
      />
      <path
        d="M11.139 14.8571H8.78125V7.30968H11.139V14.8571ZM8.64174 5.36353C8.64174 5.02871 8.76265 4.75435 9.00446 4.54043C9.24628 4.32652 9.56017 4.21956 9.94615 4.21956C10.3321 4.21956 10.646 4.32652 10.8878 4.54043C11.1297 4.75435 11.2506 5.02871 11.2506 5.36353C11.2506 5.69836 11.1297 5.97272 10.8878 6.18664C10.646 6.40055 10.3321 6.50751 9.94615 6.50751C9.56017 6.50751 9.24628 6.40055 9.00446 6.18664C8.76265 5.97272 8.64174 5.69836 8.64174 5.36353Z"
        fill="#FD9E46"
      />
    </InfoSVG>
  </Tooltip>
);

const Container = styled("div")`
  color: #868c97;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-size: ${props => props.fontSize || "10"}px;
  font-weight: 400;
  width: 100%;
  height: 100%;
`;

const DataContainer = styled("div")`
  border: 1px solid #868c97;
  border-radius: 5px;
  padding-bottom: 20px;
  background-color: #ffffff;
  width: 100%;
  height: 100%;
`;

const Text = styled("div")`
  margin-right: 10px;
  padding-left: 12px;
  overflow-y: auto;
  height: 100%;
`;

const Right = styled("div")`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  padding-bottom: 5px;
  margin-top: 2px;
  margin-right: 6px;
`;

const CrossContainer = styled("div")`
  width: ${props => props.width || "15px"};
  height: ${props => props.height || "10px"};
`;

const getXValue = (x, chartWidth) => {
  if (x >= chartWidth - 50) return x - 140;
  return x;
};

const getYValue = (x, y) => {
  const lowestYValueWeSupportOnChart = 240;
  if (y > lowestYValueWeSupportOnChart) {
    return y - 135;
  }
  return y;
};

const CrossIcon = () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.30322 15.91L15.9098 5.30343"
      stroke="#868C97"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.9099 15.91L5.30331 5.30343"
      stroke="#868C97"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Explanation = ({
  x,
  y,
  datum,
  dx,
  dy,
  fontSize,
  chartWidth,
  onClickCloseExplanation
}) => {
  const defaultChartWidth = chartWidth || 400;

  return (
    <g>
      <foreignObject
        x={getXValue(x, defaultChartWidth) - 20}
        y={getYValue(x, y) + 7}
        dx={dx}
        dy={dy}
        width="180"
        height="120"
        pointerEvents="visiblePainted"
      >
        <Container xmlns="http://www.w3.org/1999/xhtml" fontSize={fontSize}>
          <DataContainer>
            <Right>
              <CrossContainer onClick={onClickCloseExplanation}>
                <CrossIcon />
              </CrossContainer>
            </Right>
            <Text>{datum.explanation || "No explanation provided"}</Text>
          </DataContainer>
        </Container>
      </foreignObject>
    </g>
  );
};

const CustomBar = props => {
  const [isExplanationOpen, setIsExplanationOpen] = React.useState(false);

  const onInfoIconClick = () => {
    setIsExplanationOpen(true);
  };

  const onClickCloseExplanation = () => {
    setIsExplanationOpen(false);
  };

  const iconProps = {
    x: props.x + 5,
    y: props.y - 5,
    x0: props.x0,
    y0: props.y0
  };

  const explanationProps = {
    ...props,
    fontSize: 8,
    onClickCloseExplanation
  };

  return (
    <>
      <Bar {...props} />
      <VictoryPortal>
        <InfoIcon {...iconProps} onClick={onInfoIconClick} />
      </VictoryPortal>
      {isExplanationOpen && (
        <VictoryPortal>
          <Explanation {...explanationProps} />
        </VictoryPortal>
      )}
    </>
  );
};

export default CustomBar;
