import React from 'react';

import {
  ADD_OPTION,
  POPULATE_OPTIONS,
  OPTION_MAX,
  OPTION,
} from 'const/module-types';
import { PopulateToggle } from '../../PopulateToggle';
import { PopulateDropdown } from '../../PopulateDropdown';
import { MaxOptionInput } from '../../MaxOptionInput';
import { HILOS } from 'const/index';
import { getNestedData } from 'const/nested-data';

/**
 * Render's Open Options fields/components/whatever
 * for builder mode edit section
 */
export const AddOptions = ({
  order,
  moduleType,
  moduleId,
  modulesArray,
  formikProps,
  hilo,
  onPopulateToggle,
  isOpenOptionChecked,
}) => {
  const { values } = formikProps;

  if (moduleType !== ADD_OPTION) return;

  // const isPopulateOptionsKey = `moduleList.${moduleId}.questionData.${POPULATE_OPTIONS}`;
  // const isPopulateOptions =
  //   getNestedData(values, isPopulateOptionsKey) || false;

  // const isWorkshopMode = hilo.type === HILOS.WORKSHOP;

  // const components = [];

  // const populateOption = (
  //   <PopulateToggle
  //     key={order}
  //     displayOrder={order}
  //     checked={isPopulateOptions}
  //     onChange={(value) =>
  //       onPopulateToggle({
  //         populateOptionsKey: isPopulateOptionsKey,
  //         value,
  //       })
  //     }
  //   />
  // );

  // if (isWorkshopMode) {
  //   components.push(populateOption);
  // }

  // const populateDropdown = (
  //   <PopulateDropdown
  //     key={`dropdown_key${order}`}
  //     displayOrder={order}
  //     options={modulesArray}
  //     moduleId={moduleId}
  //     formikProps={formikProps}
  //   />
  // );

  const isMaxOptionEnabled = `moduleList.${moduleId}.questionData.${OPTION}`;
  const maxOptionkey = `moduleList.${moduleId}.questionData.${OPTION_MAX}`;
  const maxOptionCountValue = getNestedData(values, maxOptionkey);

  const MaxOption = (
    <MaxOptionInput
      key={maxOptionkey}
      name={maxOptionkey}
      isValid={isMaxOptionEnabled && !maxOptionCountValue ? false : true}
      label="Max option number"
      formikProps={formikProps}
      moduleId={moduleId}
    />
  );

  // const componentToShow =
  //   isPopulateOptions && isWorkshopMode ? [populateDropdown] : [];

  return [isOpenOptionChecked && MaxOption];
};
