import React, { Component } from "react";
import styled from "styled-components";
import { getNestedData } from "../../../const/nested-data";
import {
  SPECTRUM_TEXT,
  SPECTRUM_VALUE,
  SPECTRUM_VALUE_V1
} from "../../../const/module-types";
import {
  TextHighlight,
  TextHighlightSentence,
  TextHighlightContainer
} from "../TextHighlight";
import {
  PresentationTableContainer,
  ChartContainer,
  QuestionDataContainer
} from "../../layout";
import { CHART_WIDTH, CHART_HEIGHT, axisStyleXY } from "../Plotter";
import { VictoryChart, VictoryBar, VictoryAxis, VictoryLabel } from "victory";

export const DEFAULT_SPECTRUM_DIVISIONS = 7;

const SpectrumDistributionContainer = styled("div")`
  width: 100%;
  max-width: 44.375rem;
  margin: auto;
  background-color: white;
  background-repeat: no-repeat;
  background-position: top center;
  display: flex;
  height: 7.5rem;
  position: relative;
`;

const SpectrumBoxContainer = styled("div")`
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  ::before {
    content: "";
    width: 80%;
    height: 0;
    border-bottom: 1px dotted black;
    position: absolute;
    top: 50%;
    left: 10%;
  }
`;

const SpectrumBox = styled("div")`
  flex: 1 0 auto;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const SpectrumDot = styled("div")`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: #1ca4fc;
  background: ${props => (props.active ? "#1ca4fc" : "white")};
  transition: background 100ms;
  border: 1px dotted black;
  z-index: 2;
  ${({ inMobileView }) =>
    inMobileView
      ? ``
      : `
  @media (min-width: 1200px) {
    width: 4rem;
    height: 4rem;
  }
  `}
`;

const SpectrumLabelBase = styled("div")`
  flex: 0 0 auto;
  font-family: "Neue Haas Grotesk Text Std", "Neue Haas Grotesk Display Std",
    sans-serif;
  font-size: ${props => props.fontSize};
  margin: ${props => props.margin};
  font-weight: normal;
  position: absolute;
  top: ${props => (props.isEmoji ? "5rem" : "5rem")};
  right: 0;
  width: 100%;
  text-align: center;
  ${({ inMobileView, isEmoji }) =>
    inMobileView
      ? ``
      : `
  @media (min-width: 1200px) {
    top: ${isEmoji ? "6rem" : "6rem"};
  }
  `}
`;

const isEmoji = text => {
  const isEmoji = String(text).match(
    /^(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])$/
  );
  const isSingleLetter = String(text).length === 1;
  return isEmoji || isSingleLetter;
};

const SpectrumLabel = props => {
  const emoji = isEmoji(props.children);
  const fontSize = emoji ? "3.75rem" : "0.75rem";
  const newProps = {
    ...props,
    fontSize,
    isEmoji: emoji
  };
  return <SpectrumLabelBase {...newProps} />;
};

const LOWER_LIMIT = 1;

const SpectrumHighlight = styled(TextHighlight)`
  line-height: 1;
  padding: 1rem 1rem 0 1rem;
  overflow: initial;
  ${({ inMobileView }) =>
    inMobileView
      ? ``
      : `
  @media (min-width: 1200px) {
    padding: 0 1.75rem;
  }
  `}
`;

export default class SpectrumDistribution extends Component {
  getSpectrumDivisions() {
    const { questionData } = this.props;
    if (!questionData) return DEFAULT_SPECTRUM_DIVISIONS;
    const { numberOfDivisions } = questionData;
    const intDivisions = numberOfDivisions
      ? parseInt(numberOfDivisions, 10)
      : DEFAULT_SPECTRUM_DIVISIONS;
    return intDivisions;
  }
  handleChange = e => {
    e.stopPropagation();
    const name = e.currentTarget.getAttribute("name");
    const value = e.currentTarget.dataset.value;
    this.props.setFieldValue(name, value);
  };
  getSpectrumValue() {
    const { moduleId, values } = this.props;
    const v1key = `spectrumDistribution.${moduleId}.${SPECTRUM_VALUE_V1}`;
    const v2key = `spectrumDistribution.${moduleId}.${SPECTRUM_VALUE}`;
    const v1value = getNestedData(values, v1key);
    const v2value = getNestedData(values, v2key);
    const value = v2value || v1value;
    return value;
  }
  getSpectrumArray() {
    const arrayLength = this.getSpectrumDivisions();
    const spectrumArray = Array.from(
      { length: arrayLength },
      (x, i) => i + LOWER_LIMIT
    );
    return spectrumArray;
  }
  renderSurvey() {
    const { moduleId, questionData, inMobileView } = this.props;
    const { leftEndpoint, midpoint, rightEndpoint } = questionData;
    const value = this.getSpectrumValue();
    const spectrumArray = this.getSpectrumArray();
    const v2key = `spectrumDistribution.${moduleId}.${SPECTRUM_VALUE}`;
    const spectrumBoxes = spectrumArray.map(i => {
      const active = i === parseInt(value, 10);
      const startLabel =
        i === LOWER_LIMIT ? (
          <SpectrumLabel textAlign="left" {...{ inMobileView }}>
            {leftEndpoint}
          </SpectrumLabel>
        ) : null;
      const endLabel =
        i === spectrumArray.length ? (
          <SpectrumLabel textAlign="right" {...{ inMobileView }}>
            {rightEndpoint}
          </SpectrumLabel>
        ) : null;
      const midLabel =
        i === Math.ceil(spectrumArray.length / 2) ? (
          <SpectrumLabel {...{ inMobileView }}>
            {midpoint}
          </SpectrumLabel>
        ) : null;
      return (
        <SpectrumBox
          key={`spectrum${i}`}
          name={v2key}
          onClick={this.handleChange}
          data-value={i}
        >
          <SpectrumDot {...{ active, inMobileView }} />
          {midLabel}
          {startLabel}
          {endLabel}
        </SpectrumBox>
      );
    });
    const spectrumText = this.props.questionData[SPECTRUM_TEXT];
    const spectrumTextDiv = (
      <SpectrumHighlight
        color="#C4C4C4"
        fontWeight="bold"
        {...{ inMobileView }}
      >
        {spectrumText ? (
          <TextHighlightSentence>{spectrumText}</TextHighlightSentence>
        ) : null}
        <QuestionDataContainer>
          <SpectrumDistributionContainer>
            <SpectrumBoxContainer>{spectrumBoxes}</SpectrumBoxContainer>
          </SpectrumDistributionContainer>
        </QuestionDataContainer>
      </SpectrumHighlight>
    );
    const isEmojiContainer = [leftEndpoint, midpoint, rightEndpoint].reduce(
      (collector, endpoint) => {
        return collector && isEmoji(endpoint);
      },
      true
    );
    const inner = (
      <TextHighlightContainer disableSelect={true} {...{ inMobileView }}>
        {spectrumTextDiv}
      </TextHighlightContainer>
    );
    return inner;
  }
  getResponsesPerOption() {
    const { moduleId, questionData } = this.props;
    const userResponses =
      getNestedData(this.props, "values.userResponses") ||
      this.props.userResponses ||
      {};
    const spectrumArray = this.getSpectrumArray();
    const responsesPerOption = spectrumArray.reduce((collector, division) => {
      const spectrumAtValueCount = Object.entries(userResponses).reduce(
        (collector2, [userId, responseData]) => {
          const spectrumValue = getNestedData(
            responseData,
            `answerData.${moduleId}.${SPECTRUM_VALUE}`
          );
          const spectrumInt = parseInt(spectrumValue, 10);
          return spectrumInt === division ? collector2 + 1 : collector2;
        },
        0
      );
      return {
        ...collector,
        [division]: (collector[division] || 0) + spectrumAtValueCount
      };
    }, {});
    return responsesPerOption;
  }
  getPresentationData() {
    const responsesPerOption = this.getResponsesPerOption();
    const presentationData = Object.entries(responsesPerOption).map(
      ([optionKey, optionCount]) => {
        return { x: optionKey, y: optionCount };
      }
    );
    return presentationData;
  }
  getDomain() {
    return { x: [0, this.getSpectrumDivisions() + 1] };
  }
  renderPresentation() {
    const { questionData, shortContainer, inMobileView } = this.props;
    const { leftEndpoint, midpoint, rightEndpoint } = questionData;
    const data = this.getPresentationData();
    const spectrumText = questionData[SPECTRUM_TEXT];
    const spectrumTextDiv = spectrumText && (
      <SpectrumHighlight color="#C4C4C4" fontWeight="bold">
        <TextHighlightSentence>{spectrumText}</TextHighlightSentence>
      </SpectrumHighlight>
    );
    return (
      <TextHighlightContainer
        disableSelect={true}
        shortContainer={shortContainer}
      >
        {spectrumTextDiv}
        <QuestionDataContainer>
          <PresentationTableContainer>
            <ChartContainer>
              <VictoryChart
                width={CHART_WIDTH}
                height={CHART_HEIGHT}
                padding={{ top: 30, right: 20, bottom: 30, left: 40 }}
                domain={this.getDomain()}
              >
                <VictoryAxis
                  dependentAxis
                  label="Count"
                  axisLabelComponent={
                    <VictoryLabel key="yLabel" x={10} y={20} angle={0} />
                  }
                  style={axisStyleXY}
                  tickFormat={t => (t % 1 === 0 ? t : "")}
                />
                <VictoryAxis
                  label="Rank"
                  axisLabelComponent={
                    <VictoryLabel key="xLabel" x={10} y={CHART_HEIGHT - 15} />
                  }
                  style={axisStyleXY}
                  tickFormat={tt => {
                    const t = parseInt(tt, 10);
                    if (t === 1) return leftEndpoint;
                    if (
                      this.getSpectrumDivisions() % 2 === 1 &&
                      t === Math.floor(this.getSpectrumDivisions() / 2) + 1
                    )
                      return midpoint;
                    if (t === this.getSpectrumDivisions()) return rightEndpoint;
                    return "";
                  }}
                />
                <VictoryBar
                  data={data}
                  sortKey="rank"
                  style={{ data: { fill: "#1CA4FC" } }}
                />
              </VictoryChart>
            </ChartContainer>
          </PresentationTableContainer>
        </QuestionDataContainer>
      </TextHighlightContainer>
    );
  }
  render() {
    const { inPresentationMode } = this.props;
    return inPresentationMode ? this.renderPresentation() : this.renderSurvey();
  }
}
