import React, { useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel
} from "@reach/accordion";
import "@reach/accordion/styles.css";

import CaretDown from "../../../img/caret-down.svg";
import CaretUp from "../../../img/caret-up.svg";
import styled from "styled-components";

const ProjectsMenuContainer = styled("div")`
  display: flex;
  flex-direction: column;
`;

const HiloAccordion = styled(Accordion)``;

const AccordingHeader = styled("h3")`
  margin-top: ${props => (props.lowerHeader ? "20px" : 0)};
  margin-left: 30px;
  margin-bottom: 0;
  [data-reach-accordion-button] {
    background: none;
    outline: none;
    border: none;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #1ca4fc;
    padding-right: 0;
    padding-left: 0;
    display: flex;
  }
`;

const Caret = styled("div")`
  background-image: url('${props =>
    props.isCollapsed ? CaretDown : CaretUp}');
  background-repeat: no-repeat;
  width: 1rem;
  height: 1.5rem;
  background-size: auto;
  cursor: pointer;
  margin-right: 30px;
  margin-left: 0;
  background-position: center;
`;

const AccordionContent = styled("div")`
  [data-reach-accordion-panel] {
    border: none;
    outline: none;
  }
`;

const ProjectListItems = styled("ul")`
  list-style: none;
  margin-bottom: 0;
  padding: 0;
`;

const ProjectListItem = styled("li")`
  padding-left: 76px;
  padding-right: 20px;
  padding-top: 9px;
  padding-bottom: 9px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  cursor: pointer;
  text-transform: capitalize;
  color: ${props => (props.active ? "#FF9736" : "#000000")};
  background-color: ${props => (props.active ? "none" : "none")};
  &:hover {
    background-color: #1ca4fc;
    color: #ffffff;
  }
`;

const ProjectItem = styled("div")`
  display: flex;
  justify-content: space-between;
`;

const TrashSVG = styled("svg")`
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
`;

const Trash = ({ onClick }) => (
  <TrashSVG
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    opacity="0.4"
    onClick={onClick}
  >
    <path
      d="M1 4.06667H2.55556H15"
      stroke="white"
      strokeWidth="1.47368"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.1813 4.06667C14.1813 3.65972 13.8514 3.32983 13.4444 3.32983C13.0375 3.32983 12.7076 3.65972 12.7076 4.06667H14.1813ZM3.29238 4.06667C3.29238 3.65972 2.96249 3.32983 2.55554 3.32983C2.1486 3.32983 1.8187 3.65972 1.8187 4.06667H3.29238ZM4.15204 4.06667C4.15204 4.47361 4.48193 4.80351 4.88888 4.80351C5.29583 4.80351 5.62572 4.47361 5.62572 4.06667H4.15204ZM10.3743 4.06667C10.3743 4.47361 10.7042 4.80351 11.1111 4.80351C11.518 4.80351 11.8479 4.47361 11.8479 4.06667H10.3743ZM12.7076 4.06667V14.8H14.1813V4.06667H12.7076ZM12.7076 14.8C12.7076 15.2299 12.3511 15.5965 11.8889 15.5965V17.0702C13.1449 17.0702 14.1813 16.0637 14.1813 14.8H12.7076ZM11.8889 15.5965H4.1111V17.0702H11.8889V15.5965ZM4.1111 15.5965C3.6489 15.5965 3.29238 15.2299 3.29238 14.8H1.8187C1.8187 16.0637 2.85507 17.0702 4.1111 17.0702V15.5965ZM3.29238 14.8V4.06667H1.8187V14.8H3.29238ZM5.62572 4.06667V2.53333H4.15204V4.06667H5.62572ZM5.62572 2.53333C5.62572 2.1034 5.98224 1.73684 6.44443 1.73684V0.263158C5.18841 0.263158 4.15204 1.26959 4.15204 2.53333H5.62572ZM6.44443 1.73684H9.55554V0.263158H6.44443V1.73684ZM9.55554 1.73684C10.0177 1.73684 10.3743 2.1034 10.3743 2.53333H11.8479C11.8479 1.26959 10.8116 0.263158 9.55554 0.263158V1.73684ZM10.3743 2.53333V4.06667H11.8479V2.53333H10.3743Z"
      fill="white"
    />
  </TrashSVG>
);

const getSelectedProject = (projects, selectedProject, isAllSurveyActive) => {
  if (selectedProject) return selectedProject;
  if (isAllSurveyActive) return null;
  if (projects && projects.length !== 0) {
    return projects[0][0];
  }
};

const ProjectsList = ({
  projectsArray,
  selectProject,
  selectedProject,
  sharedProjects,
  onSelectProject,
  isAllSurveyActive,
  onDeleteProject
}) => {
  const [indices, setIndices] = useState([0, 1]);

  function toggleItem(toggledIndex) {
    if (indices.includes(toggledIndex)) {
      setIndices(indices.filter(currentIndex => currentIndex !== toggledIndex));
    } else {
      setIndices([...indices, toggledIndex].sort());
    }
  }

  function isCollapsed(headerIndex) {
    return !indices.includes(headerIndex);
  }

  return (
    <ProjectsMenuContainer>
      <HiloAccordion index={indices} onChange={toggleItem}>
        <AccordionItem>
          <AccordingHeader>
            <AccordionButton>
              <Caret isCollapsed={isCollapsed(0)} /> My Projects
            </AccordionButton>
          </AccordingHeader>
          <AccordionContent>
            <AccordionPanel>
              <ProjectListItems>
                {(projectsArray || []).map(([id, { name }]) => {
                  const active = selectedProject === id;
                  return (
                    <ProjectListItem
                      key={id}
                      data-id={id}
                      onClick={onSelectProject}
                      active={active}
                    >
                      <ProjectItem>
                        {name}
                        <Trash onClick={() => onDeleteProject({ id, name })} />
                      </ProjectItem>
                    </ProjectListItem>
                  );
                })}
              </ProjectListItems>
            </AccordionPanel>
          </AccordionContent>
        </AccordionItem>
        <AccordionItem>
          <AccordingHeader lowerHeader>
            <AccordionButton>
              <Caret isCollapsed={isCollapsed(1)} /> Projects I'm shared to
            </AccordionButton>
          </AccordingHeader>
          <AccordionContent>
            <AccordionPanel>
              <ProjectListItems>
                {sharedProjects.map(({ id, name }) => {
                  const active = selectedProject === id;
                  return (
                    <ProjectListItem
                      key={id}
                      data-id={id}
                      onClick={selectProject}
                      active={active}
                    >
                      {name}
                    </ProjectListItem>
                  );
                })}
              </ProjectListItems>
            </AccordionPanel>
          </AccordionContent>
        </AccordionItem>
      </HiloAccordion>
    </ProjectsMenuContainer>
  );
};

export default ProjectsList;
