import { createSlice } from '@reduxjs/toolkit';
import { DEFAULT_X_MAX_DOMAIN, DEFAULT_X_MIN_DOMAIN } from 'const/module-types';

// We store the modules data under a moduleId, there is no
// initial data since for every module we set the data dynamically
// look at the redux data through the devtools to see the
// module data.
export const presentationSlice = createSlice({
  name: 'presentation',
  initialState: {
    surveyDetails: {},
    modules: [],
    userResponses: {},
    activeQuestion: {
      question: '',
      metaData: {},
      moduleType: '',
      moduleId: '',
    },
    questionsData: {
      questions: [],
      questionsMetaData: {},
    },
    selectedParticipantResponseId: null,
    clickedChartElementResponseIds: [], // This an array of responseIds when a user clicks on a chart bar
    clickedChartElementOptionKey: null, // The option key for the selected chart element/bar
    isCommentsPanelVisible: false,
    isPopulatePanelVisible: false,
    initialComments: [],
    comments: [],
    panelComments: [],
    xAxisCollapsed: false,
    yAxisCollapsed: false,
  },
  reducers: {
    setDistributionToggle: (state, action) => {
      state[action.payload.moduleId] = {
        ...state[action.payload.moduleId],
        distributionToggle: action.payload.distributionToggle,
      };
    },
    setSurveyDetails: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.surveyDetails = action.payload;
    },
    setModules: (state, action) => {
      state.modules = action.payload;
    },
    setUserResponses: (state, action) => {
      state.userResponses = action.payload;
    },
    setMultiSelectComments: (state, action) => {
      state.comments = {
        ...state.comments,
        ...action.payload,
      };
    },
    setMultiSelectChartData: (state, action) => {
      state[action.payload.moduleId] = {
        ...state[action.payload.moduleId],
        ...action.payload,
      };
    },
    setActiveQuestion: (state, action) => {
      state.activeQuestion = action.payload;
    },
    setSelectedParticipantResponseId: (state, action) => {
      state.selectedParticipantResponseId = action.payload;
    },
    // Sets the responseIds when a user clicks on a chart bar so that we can
    // highlight the selected users in the left comments section.
    setClickedChartElementResponseIds: (state, action) => {
      state.clickedChartElementResponseIds = action.payload;
    },
    setCommentsPanelVisibility: (state, action) => {
      state.isCommentsPanelVisible = action.payload;
    },
    setPopulatePanelVisibility: (state, action) => {
      state.isPopulatePanelVisible = action.payload;
    },
    setClickedChartElementOptionKey: (state, action) => {
      state.clickedChartElementOptionKey = action.payload;
    },
    setComments: (state, action) => {
      state.comments = action.payload;
    },
    setInitialComments: (state, action) => {
      state.initialComments = action.payload;
    },
    setPanelComments: (state, action) => {
      state.panelComments = action.payload;
    },
    setSpectrumChartData: (state, action) => {
      state[action.payload.moduleId] = {
        ...state[action.payload.moduleId],
        chartData: {
          domain: { min: DEFAULT_X_MIN_DOMAIN, max: DEFAULT_X_MAX_DOMAIN },
          data: action.payload.chartData,
        },
      };
    },
    setSpectrumChartLabels: (state, action) => {
      state[action.payload.moduleId] = {
        ...state[action.payload.moduleId],
        chartData: {
          ...(state[action.payload.moduleId].chartData || {}),
          labels: action.payload.labels,
        },
      };
    },
    // Point selected when we click on view selection in the comments panel
    setSpectrumSelectedPoint: (state, action) => {
      state[action.payload.moduleId] = {
        ...state[action.payload.moduleId],
        selectedPoint: action.payload.selectedPoint,
      };
    },
    setQuestionsData: (state, action) => {
      state.questionsData = action.payload;
    },
    setPlotter22Data: (state, action) => {
      state[action.payload.moduleId] = {
        ...state[action.payload.moduleId],
        ...action.payload,
      };
    },
    // Collapse X axis of the plotter22
    setPlotter22XAxisCollapsed: (state, action) => {
      state.xAxisCollapsed = action.payload;
    },
    // Collapse Y axis of the plotter22
    setPlotter22YAxisCollapsed: (state, action) => {
      state.yAxisCollapsed = action.payload;
    },
    setPointDistributionData: (state, action) => {
      state[action.payload.moduleId] = action.payload;
    },
    // Selected point when we click on the chart itself.
    setSelectedPointKey: (state, action) => {
      state[action.payload.moduleId] = {
        ...state[action.payload.moduleId],
        ...action.payload,
      };
    },
    setMadlibData: (state, action) => {
      state[action.payload.moduleId] = {
        ...state[action.payload.moduleId],
        ...action.payload,
      };
    },
    setStackRankChartData: (state, action) => {
      state[action.payload.moduleId] = {
        ...state[action.payload.moduleId],
        ...action.payload,
      };
    },
    setStackRankNumberOfParticipant: (state, action) => {
      state[action.payload.moduleId] = {
        ...state[action.payload.moduleId],
        ...action.payload,
      };
    },
    setStackRankParticipantsData: (state, action) => {
      state[action.payload.moduleId] = {
        ...state[action.payload.moduleId],
        ...action.payload,
      };
    },
    // Point selected when we click on view selection in the comments panel
    setStackRankSelectedPoint: (state, action) => {
      state[action.payload.moduleId] = {
        ...state[action.payload.moduleId],
        selectedDataPoint: action.payload.selectedDataPoint,
      };
    },
    setOpenQuestionData: (state, action) => {
      state[action.payload.moduleId] = {
        ...state[action.payload.moduleId],
        ...action.payload,
      };
    },
    setIsWordCloudSelected: (state, action) => {
      state[action.payload.moduleId] = {
        ...state[action.payload.moduleId],
        ...action.payload,
      };
    },
    setOpenQuestionResponseToggle: (state, action) => {
      state[action.payload.moduleId] = {
        ...state[action.payload.moduleId],
        ...action.payload,
      };
    },
    setAddOptionsResponses: (state, action) => {
      state[action.payload.moduleId] = {
        ...state[action.payload.moduleId],
        ...action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setModules,
  setSurveyDetails,
  setQuestionsData,
  setUserResponses,
  setActiveQuestion,
  setComments,
  setInitialComments,
  setPanelComments,
  setSelectedPointKey,
  setPlotter22XAxisCollapsed,
  setPlotter22YAxisCollapsed,
  setOpenQuestionData,
  setSpectrumChartData,
  setDistributionToggle,
  setSpectrumChartLabels,
  setIsWordCloudSelected,
  setMultiSelectChartData,
  setSpectrumSelectedPoint,
  setCommentsPanelVisibility,
  setPopulatePanelVisibility,
  setOpenQuestionResponseToggle,
  setClickedChartElementOptionKey,
  setSelectedParticipantResponseId,
  setClickedChartElementResponseIds,
  setPlotter22Data,
  setPointDistributionData,
  setMadlibData,
  setStackRankChartData,
  setStackRankNumberOfParticipant,
  setStackRankParticipantsData,
  setStackRankSelectedPoint,
  setAddOptionsResponses,
} = presentationSlice.actions;

export default presentationSlice.reducer;
