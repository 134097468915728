import React, { useState, useEffect } from 'react';

import Tooltip from '@reach/tooltip';
import '@reach/tooltip/styles.css';

import { ReactComponent as InfoDefault } from 'img/InfoDefault.svg';
import { ReactComponent as InfoHover } from 'img/InfoHover.svg';

import CrossSVG from 'img/cross.svg';
import ArrowUpSVG from 'img/arrow-up.svg';
import ArrowDownSVG from 'img/arrow-down.svg';

import { POPULATABLE_MODULES, POPULATE_MODULE } from 'const/module-types';

import {
  DropDownListContainer,
  DropDownList,
  DropDownHead,
  DropDownContainer,
  HeaderContainer,
  CrossButton,
  ArrowButton,
  ListItem,
  Img,
  Label,
} from './styles';
import { getNestedData } from 'const/nested-data';

const getSelectedOptionText = ({ populatedModule, modulesArray }) => {
  if (populatedModule && modulesArray) {
    const { moduleId } = populatedModule;
    const module = modulesArray.find((module) => module.moduleId === moduleId);
    if (module) return module.question;
    return null;
  }
  return null;
};

export const PopulateDropdown = ({
  options = [],
  disabled = false,
  moduleId = '',
  displayOrder,
  formikProps,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const { setFieldValue, values } = formikProps;

  const populatedModuleKey = `moduleList.${moduleId}.questionData.${POPULATE_MODULE}`;
  const populatedModule = getNestedData(values, populatedModuleKey);

  const onOptionClicked = (value, optionEnabled) => {
    if (!optionEnabled) return;
    const { moduleId, moduleType } = value;
    setFieldValue(populatedModuleKey, { moduleId, moduleType });
    setIsOpen(false);
  };

  const onClickCross = () => {
    if (disabled) return;
    setFieldValue(populatedModuleKey, null);
  };

  const toggling = () => {
    if (disabled) return;
    setIsOpen(!isOpen);
  };

  const TooltipLabel =
    'This question is not able to \
  populate responses. Please \
  try out Point Dirstribution, \
  Stack Rank, Multiple Choice, Add Option';

  const DisabledOptionTooltip = ({ isPopulateModule, moduleAfter }) => {
    const [showHoverIcon, setShowHoverIcon] = useState(false);
    return (
      <div
        onMouseOver={() => setShowHoverIcon(true)}
        onMouseLeave={() => setShowHoverIcon(false)}
      >
        <Tooltip
          label={
            isPopulateModule && !moduleAfter
              ? 'You cannot populate from questions that come after this question.'
              : TooltipLabel
          }
          style={{ zIndex: '10000' }}
        >
          {showHoverIcon ? <InfoHover /> : <InfoDefault />}
        </Tooltip>
      </div>
    );
  };

  const LIMIT_TEXT = 20;
  const trimmedOptionText = (text) => {
    if (text.length <= LIMIT_TEXT) return text;
    return text.substring(0, LIMIT_TEXT) + '...';
  };

  return (
    <>
      <Label>Question to populate responses</Label>
      <DropDownContainer>
        <HeaderContainer disabled={disabled}>
          <DropDownHead
            onClick={toggling}
            isOpen={isOpen}
            selectedOption={populatedModule ? true : false}
            disabled={disabled}
          >
            {trimmedOptionText(
              getSelectedOptionText({
                populatedModule,
                modulesArray: options,
              }) || 'Choose a question'
            )}
          </DropDownHead>
          {populatedModule ? (
            <CrossButton onClick={onClickCross} disabled={disabled}>
              <Img
                disabled={disabled}
                src={CrossSVG}
                alt="Close name selection"
              />
            </CrossButton>
          ) : (
            <ArrowButton onClick={toggling} disabled={disabled}>
              <Img
                src={isOpen ? ArrowUpSVG : ArrowDownSVG}
                alt="drop down arrow"
                disabled={disabled}
              />
            </ArrowButton>
          )}
        </HeaderContainer>
        {isOpen && (
          <DropDownListContainer>
            <DropDownList>
              {options &&
                options.map((option) => {
                  if (option.moduleId === moduleId) return null;
                  const moduleAfter = displayOrder > option.displayOrder;
                  const isPopulateModule = POPULATABLE_MODULES.includes(
                    option.moduleType
                  );
                  const isPopulateable = isPopulateModule && moduleAfter;

                  return (
                    <ListItem
                      onClick={() => onOptionClicked(option, isPopulateable)}
                      key={option.moduleId}
                      disabled={!isPopulateable}
                    >
                      <Tooltip
                        label={option.question}
                        style={{ zIndex: '10000' }}
                      >
                        <span>
                          {`${option.displayOrder}. ${trimmedOptionText(
                            option.question
                          )}`}
                        </span>
                      </Tooltip>
                      {!isPopulateable && (
                        <span style={{ float: 'right', marginRight: '10px' }}>
                          <DisabledOptionTooltip
                            isPopulateModule={isPopulateModule}
                            moduleAfter={moduleAfter}
                          />
                        </span>
                      )}
                    </ListItem>
                  );
                })}
            </DropDownList>
          </DropDownListContainer>
        )}
      </DropDownContainer>
    </>
  );
};
