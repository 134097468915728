import { device } from "../../../utils/deviceBreakpoints";

export const styles = {
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    paddingTop: "65px",
    position: "relative",
    background: "#FFF",
    overflow: "auto",
    [`@media ${device.tablet}`]: {
      paddingBottom: "88px",
    },
  },
  subContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(4, 180px)",
    gridGap: "30px",
    height: "fit-content",
    margin: "0 auto",
    [`@media ${device.tablet}`]: {
      gridTemplateColumns: "repeat(2, 150px)",
      gridGap: "24px",
    },
  },
  questionDescriptionContainer: {
    gridColumnStart: 1,
    gridColumnEnd: 5,
    paddingLeft: "30px",
    [`@media ${device.tablet}`]: {
      display: "none",
    },
  },
  question: {
    minHeight: "auto !important",
    marginBottom: "45px",
  },
  description: {
    minHeight: "auto !important",
    margin: 0,
    whiteSpace: "pre-line",
  },
  controlsContainer: {
    gridColumnStart: 1,
    gridColumnEnd: 5,
    [`@media ${device.tablet}`]: {
      display: "none",
    },
  },
  controlsSubContainer: {
    width: "60%",
    position: "relative",
    [`@media ${device.tablet}`]: {
      marginTop: "80px",
    },
  },
  mobileBottomContainer: {
    background: "#F2F2F2",
    bottom: 0,
    display: "none",
    position: "absolute",
    width: "100%",
    zIndex: 2,
    padding: "1rem 0",
    [`@media ${device.tablet}`]: {
      display: "block",
    },
  },
  mobileControls: {
    position: "initial !important",
    padding: 0,
  },
  title: {
    display: "none",
    [`@media ${device.tablet}`]: {
      display: "block",
    },
  },
  mobileCommentInput: {
    position: "relative",
    margin: "0 1.25rem 0.75rem",
  },
};
