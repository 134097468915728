import React, { useState } from 'react';
import { HiloDialog } from 'components/Common/HiloDialog';
import {
  HiloDialogContentContainer,
  HiloDialogTextContainer,
} from 'components/Common/HiloDialog/styles';
import { HiloParagraph } from 'components/Common/Typography';
import {
  Close,
  BreakingAlertArrow,
  ModuleContainer,
  ModuleName,
  ModuleNumber,
  BreakingAlertIllustrationContainer,
  BreakingAlertText,
  IllustrationContainer,
  ButtonsContainer,
  ModuleDetails,
} from './styles';
import { HiloButton } from 'components/Common/Button';
import { ReactComponent as Caution } from 'img/Caution.svg';
import { ReactComponent as BreakingAlertLeft } from 'img/breaking-alert-left.svg';
import { ReactComponent as BreakingAlertRight } from 'img/breaking-alert-right.svg';

const BreakingAlert = ({
  onContinue,
  modulePopulateAlert = [],
  onDismiss,
  onClickBreakingText,
}) => (
  <>
    <HiloDialogTextContainer>
      <HiloParagraph>
        Looks like this update will result in some changes to your Hilo. Check
        out the alerts below and click confirm to continue.
      </HiloParagraph>
    </HiloDialogTextContainer>
    <HiloDialogTextContainer
      paddingTop="35"
      marginBottom="20"
      onClick={onClickBreakingText}
    >
      <HiloParagraph
        color="#FF7086"
        weight="500"
        size="18"
        lineHeight="17.93"
        display="flex"
      >
        <Caution style={{ marginRight: '10px' }} />
        <BreakingAlertText>
          The populate setting will be reset for the question(s) listed below
        </BreakingAlertText>
      </HiloParagraph>
    </HiloDialogTextContainer>
    <HiloDialogTextContainer paddingTop="0" paddingLeft="60">
      {modulePopulateAlert.map((alert) => (
        <ModuleContainer key={alert.displayOrder}>
          <ModuleNumber>{alert.displayOrder}.</ModuleNumber>
          <ModuleName>{alert.name}</ModuleName>
        </ModuleContainer>
      ))}
    </HiloDialogTextContainer>
    <ButtonsContainer>
      <HiloButton
        paddingRight="45px"
        paddingLeft="45px"
        marginRight="30px"
        onClick={onDismiss}
      >
        Cancel
      </HiloButton>
      <HiloButton paddingRight="45px" paddingLeft="45px" onClick={onContinue}>
        Continue
      </HiloButton>
    </ButtonsContainer>
  </>
);

const BreakingAlertIllustration = ({ onClick }) => (
  <BreakingAlertIllustrationContainer>
    <Close stroke="#1CA4FC" onClick={onClick} />
    <HiloDialogTextContainer paddingTop="0" marginBottom="20">
      <HiloParagraph weight="500" size="18" lineHeight="19.8">
        For example, question2 can’t populate from question1 since it’s ahead of
        question1 now
      </HiloParagraph>
    </HiloDialogTextContainer>
    <IllustrationContainer>
      <BreakingAlertLeft />
      <BreakingAlertArrow />
      <BreakingAlertRight />
    </IllustrationContainer>
  </BreakingAlertIllustrationContainer>
);

const ModuleDragDialog = ({
  isOpen,
  onDismiss,
  onContinue,
  modulePopulateAlert = [],
}) => {
  const [isBreakingAlertText, setIsBreakingAlertText] = useState(true);

  const onClickClose = () => setIsBreakingAlertText(true);

  const onClickBreakingText = () => setIsBreakingAlertText(false);

  return (
    <HiloDialog
      ariaLabel="Module card drag and drop"
      isOpen={isOpen}
      onDismiss={onDismiss}
      width="721px"
      height="336px"
    >
      <HiloDialogContentContainer>
        {isBreakingAlertText ? (
          <BreakingAlert
            modulePopulateAlert={modulePopulateAlert}
            onContinue={onContinue}
            onDismiss={onDismiss}
            onClickBreakingText={onClickBreakingText}
          />
        ) : (
          <BreakingAlertIllustration onClick={onClickClose} />
        )}
      </HiloDialogContentContainer>
    </HiloDialog>
  );
};

export default ModuleDragDialog;
