import React, { useEffect, useState } from 'react';
import { css } from 'emotion';
import Tooltip from '@reach/tooltip';
import '@reach/tooltip/styles.css';
import '@reach/dialog/styles.css';

import { QuestionContainer } from './QuestionContainer';
import { ChartContainer } from './ChartContainer';
import SurveyPresentation from '../SurveyPresentation';
import { CommentsPanel } from './CommentsPanel';
import { ButtonBase } from 'components/buttons';

import { ReactComponent as EnabledComment } from 'img/EnabledCommentIcon.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCommentsPanelVisibility,
  setInitialComments,
  setPanelComments,
  setPopulatePanelVisibility,
} from 'pages/presentationSlice';
import Tracking from 'lib/tracking/tracking';
import { presentationEvents } from 'lib/tracking/events';
import { Badge } from './Badge';
import { getNewCommentBadgeCount } from 'components/utils/helpers';

const styles = {
  populateBtn: {
    background: '#F1F2F5',
    borderRadius: '10px',
    width: '64px',
    height: '50px',
  },
  populateBtnContainer: {
    position: 'relative',
    marginLeft: 'auto',
  },
};

export default function Presentation({
  moduleList,
  initialSelectedModuleId,
  renderControls,
  renderWorkshopPopulateBtn,
  mode,
  currentUser,
  getDataAsync,
}) {
  const dispatch = useDispatch();
  const isCommentPanelVisible = useSelector(
    (state) => state.presentation.isCommentsPanelVisible
  );
  const isPopulatePanelVisible = useSelector(
    (state) => state.presentation.isPopulatePanelVisible
  );
  const selectedParticipantResponseId = useSelector(
    (state) => state.presentation.selectedParticipantResponseId
  );

  const activeQuestion = useSelector(
    (state) => state.presentation.activeQuestion
  );

  const data = useSelector(
    (state) => state.presentation[activeQuestion.moduleId]
  );

  const comments = useSelector((state) => state.presentation.comments);
  const initialComments = useSelector(
    (state) => state.presentation.initialComments
  );

  const [currentmoduleId, setCurrentModuleId] = useState();

  const openCommentPanel = () => {
    Tracking.event({ name: presentationEvents.COMMENTS_OPEN });
    dispatch(setPopulatePanelVisibility(false));
    dispatch(setPanelComments(comments));
    dispatch(setCommentsPanelVisibility(!isCommentPanelVisible));
  };

  /**
   * This checks if the currently active question's moduleId is different from the
   * current moduleId. If it is, it sets the current moduleId to the active question's
   * This hook runs when there is a change in the active question.
   */
  useEffect(() => {
    if (activeQuestion.moduleId !== currentmoduleId) {
      setCurrentModuleId(activeQuestion.moduleId);
      dispatch(setInitialComments(comments));
    }
  }, [activeQuestion]);

  const badgeCount = getNewCommentBadgeCount(comments, initialComments);

  const CommentBtn = (
    <Tooltip label="Comments">
      <span css={styles.populateBtnContainer}>
        {badgeCount > 0 && !isCommentPanelVisible && (
          <Badge value={badgeCount} />
        )}
        <ButtonBase css={styles.populateBtn} onClick={() => openCommentPanel()}>
          <EnabledComment />
        </ButtonBase>
      </span>
    </Tooltip>
  );

  return (
    <SurveyPresentation
      moduleList={moduleList}
      initialSelectedModuleId={initialSelectedModuleId}
      mode={mode}
      activeQuestion={activeQuestion}
      currentUser={currentUser}
      getDataAsync={getDataAsync}
    >
      {({ chart: Chart, questionsData, onQuestionSelected }) => {
        return (
          <>
            <div
              css={{
                width:
                  isCommentPanelVisible || isPopulatePanelVisible
                    ? '80%'
                    : '100%',
                height: '100%',
              }}
            >
              <QuestionContainer
                renderControls={() =>
                  renderControls({
                    onQuestionSelected,
                    questionsData,
                    activeQuestion,
                  })
                }
                renderCommentBtn={CommentBtn}
                renderWorkshopPopulateBtn={renderWorkshopPopulateBtn}
              />
              <ChartContainer
                activeQuestion={activeQuestion}
                isWordCloud={data}
              >
                <Chart
                  className={css`
                    ${isCommentPanelVisible && 'margin-right: 40%'};
                  `}
                />
              </ChartContainer>
            </div>
            {(isCommentPanelVisible || selectedParticipantResponseId) && (
              <CommentsPanel activeQuestion={activeQuestion} />
            )}
          </>
        );
      }}
    </SurveyPresentation>
  );
}
