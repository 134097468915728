import React from 'react';

import { useSelector } from 'react-redux';

import { A } from '../../layout';
import { DESKTOP, MOBILE, getIsClosed } from 'lib/builder-api';

import PreviewPlayImg from 'img/preview-play.svg';
import BluePreviewPlayImg from 'img/preview-play-blue.svg';

import {
  PopulatePreviewBanner,
  PopulatePreviewText,
  PreviewContainer,
  PreviewSection,
  PreviewRow,
  SurveyCode,
  PreviewTab,
  ToggleButton,
} from './styles';
import Tracking from 'lib/tracking/tracking';
import { surveyBuilderEvents } from 'lib/tracking/events';

export const ToggleClosed = ({
  isTemplate,
  shortButton,
  toggleClosed,
  surveyDetails,
  showSurveyLaunched,
  hoverBold,
}) => {
  if (isTemplate) return null;
  const { type } = useSelector((state) => state.hilo);
  const isClosed = getIsClosed(surveyDetails);
  return isClosed ? (
    <ToggleButton
      width="100%"
      onClick={showSurveyLaunched || toggleClosed}
      {...{ hoverBold }}
    >
      Launch
      {shortButton ? `` : ` ${type}`}
    </ToggleButton>
  ) : (
    <ToggleButton width="100%" onClick={toggleClosed} {...{ hoverBold }}>
      Close
      {shortButton ? `` : ` ${type}`}
    </ToggleButton>
  );
};

export const PreviewHeader = ({
  surveyDetails = {},
  previewTab = DESKTOP,
  setPreviewTab,
  toggleClosed,
  onPreviewClick,
  loading,
  saving,
  isClosed,
  showSurveyLaunched,
  isTemplate,
  moduleList,
  activeModule,
}) => {
  const { type } = useSelector((state) => state.hilo);
  const isPopulate = moduleList[activeModule]?.questionData?.populateOptions;
  const { surveyCode, unpublished } = surveyDetails;
  const clickDesktop = setPreviewTab
    ? () => {
        Tracking.event({ name: surveyBuilderEvents.DESKTOP_TOGGLE });
        setPreviewTab(DESKTOP);
      }
    : null;
  const clickMobile = setPreviewTab
    ? () => {
        Tracking.event({ name: surveyBuilderEvents.MOBILE_TOGGLE });
        setPreviewTab(MOBILE);
      }
    : null;
  return (
    <>
      <PreviewContainer>
        <PreviewSection>
          {loading || saving ? null : (
            <>
              {unpublished ? (
                `Draft`
              ) : isClosed ? (
                `${type} closed`
              ) : isTemplate ? null : (
                <>
                  Launched at <SurveyCode>{surveyCode}</SurveyCode>
                </>
              )}
              <ToggleClosed
                {...{
                  isTemplate,
                  surveyDetails,
                  toggleClosed,
                  showSurveyLaunched,
                  hoverBold: true,
                }}
              />
            </>
          )}
        </PreviewSection>
        <PreviewRow>
          <PreviewTab
            width="70px"
            active={previewTab === DESKTOP}
            onClick={clickDesktop}
            hoverColor="#1ca4fc"
          >
            Desktop
          </PreviewTab>
          <PreviewTab
            width="70px"
            active={previewTab === MOBILE}
            onClick={clickMobile}
            hoverColor="#1ca4fc"
          >
            Mobile
          </PreviewTab>
        </PreviewRow>
        <PreviewSection>
          <A
            href={`/preview/${
              isTemplate ? surveyDetails.templateId : surveyCode
            }/1?backToEdit=true`}
            target="_blank"
            onClick={onPreviewClick}
          >
            <PreviewTab
              width="105px"
              icon={PreviewPlayImg}
              hoverIcon={BluePreviewPlayImg}
              hoverColor="#1ca4fc"
              hoverBold
            >
              Test {type}
            </PreviewTab>
          </A>
        </PreviewSection>
      </PreviewContainer>
      {isPopulate && (
        <PopulatePreviewBanner>
          <PopulatePreviewText>
            Option list will be automatically filled up when user finished the
            previous question during workshop.
          </PopulatePreviewText>
        </PopulatePreviewBanner>
      )}
    </>
  );
};
