import React, { useRef, createRef, useEffect } from 'react';
import MessageBubble from './MessageBubble';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { NoResponse } from '../common/others';

const Container = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const MessageContainer = styled(motion.div)`
  flex: 50%;
  display: flex;
  justify-content: ${(props) => (props.right ? 'flex-end' : 'normal')};
  margin: 30px 2px 0 2px;
`;

const isOdd = ({ index }) => index % 2;

function isActive(selectedParticipantData, message) {
  return (
    selectedParticipantData &&
    selectedParticipantData.responseId === message.responseId
  );
}

const MessageBubbles = ({
  data = [],
  selectedParticipantData = {},
  animationsControls = {},
}) => {
  const containerRef = createRef();

  const selectedParticipantResponseId = (selectedParticipantData || {})
    .responseId;
  useEffect(() => {
    const indexSelectedItem = data.findIndex(
      (item) => item.responseId === selectedParticipantResponseId
    );
    if (indexSelectedItem !== -1) {
      const selectedChild = containerRef.current.children[indexSelectedItem];
      selectedChild.scrollIntoView({ behavior: 'smooth' });
    }
  }, [selectedParticipantResponseId]);

  return (
    <>
      <Container
        ref={containerRef}
        variants={{
          hidden: {},
          visible: {
            opacity: 1,
            scale: 1,
            transition: {
              delayChildren: 0.3,
              delay: 0.2,
              staggerChildren: 0.5,
              ease: 'anticipate',
            },
          },
        }}
        initial="hidden"
        animate="visible"
        onAnimationComplete={(def) => {
          animationsControls.onInitialAnimationComplete();
        }}
      >
        {data.map((message, index) => {
          const right = isOdd({ index });
          return (
            <MessageContainer
              key={message.responseId}
              right={right}
              inherit={animationsControls.shouldReAnimate}
              variants={{
                hidden: {
                  scale: 0,
                  y: 20,
                  opacity: 0,
                },
                visible: {
                  y: 0,
                  opacity: 1,
                  scale: 1,
                },
              }}
            >
              <MessageBubble
                name={message.name}
                response={message.value}
                right={right}
                isActive={isActive(selectedParticipantData, message)}
              />
            </MessageContainer>
          );
        })}
      </Container>
      <NoResponse data={data} />
    </>
  );
};

export default MessageBubbles;
