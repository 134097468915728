import React, { useState } from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { useDispatch, useSelector } from 'react-redux';
import {
  setClickedChartElementOptionKey,
  setClickedChartElementResponseIds,
  setCommentsPanelVisibility,
  setPanelComments,
  setSelectedParticipantResponseId,
} from 'pages/presentationSlice';

import { TooltipComponent } from './TooltipComponent';
import { ReactComponent as IconExpand } from '../../../img/ic_expand_sm.svg';
import ExpandedItemView from '../../survey-components/MultiSelect/ExpandedItemView';

import {
  Bar,
  Label,
  Count,
  BarContainer,
  ChartContainer,
  Container,
  Item,
  ExpandButton,
  StatsContainer,
  StatsText,
} from './styles';
import { HILOS } from 'const/index';

const BAR_WIDTH = 510;

const getBarWidth = (count, refCount) => {
  const width = (count / refCount) * BAR_WIDTH;
  if (Number.isNaN(width)) return 0;
  return width;
};

export default function MultiSelect({ moduleId, mode, comparisonSection }) {
  const dispatch = useDispatch();

  const [expandedItemData, setExpandedItemData] = useState(null);
  const [barClickedToggle, setBarClickedToggle] = useState(false);

  const chartData = useSelector(
    (state) => state.presentation[moduleId].chartData
  );
  const moduleResponsesLength = useSelector(
    (state) => state.presentation[moduleId].moduleResponsesLength
  );

  const responses = useSelector((state) => state.presentation.userResponses);
  const comments = useSelector((state) => state.presentation.comments);

  const selectedParticipantResponseId = useSelector((state) => {
    if (comparisonSection && mode === HILOS.WORKSHOP) {
      return state.workshop.comparison[comparisonSection]
        .selectedParticipantResponseId;
    } else {
      return state.presentation.selectedParticipantResponseId;
    }
  });
  const selectedBar = useSelector(
    (state) => state.presentation.clickedChartElementOptionKey
  );

  const isCommentPanelOpen = useSelector(
    (state) => state.presentation.isCommentsPanelVisible
  );

  const selectedUserOptions = (key) =>
    selectedParticipantResponseId
      ? responses[selectedParticipantResponseId].answerData[moduleId]?.[key]?.[
          'selected'
        ]
      : false;

  const avgChoices = Math.ceil(
    chartData.reduce((prev, curr) => prev + curr.count, 0) /
      moduleResponsesLength
  );

  const onClickBar = ({ users, event }) => {
    // Set this to null so that the highlight on View selection
    // In the comment section does not conflict with the bar click.
    dispatch(setSelectedParticipantResponseId(null));
    if (barClickedToggle) {
      dispatch(setClickedChartElementResponseIds([]));
      setBarClickedToggle(false);
      dispatch(setClickedChartElementOptionKey(null));
    } else {
      dispatch(setClickedChartElementOptionKey(event.target.dataset.key));
      const responseIds = users.map((user) => user.responseId);
      dispatch(setClickedChartElementResponseIds(responseIds));
      dispatch(setCommentsPanelVisibility(true));
      setBarClickedToggle(true);
      dispatch(setPanelComments(comments));
    }
  };

  const isBarSelected = ({ key }) => {
    return selectedUserOptions(key) || selectedBar === key;
  };

  const hasResponse = chartData.some((item) => item.count > 0);

  return (
    <Container isCommentPanelOpen={isCommentPanelOpen}>
      {expandedItemData && (
        <ExpandedItemView
          data={expandedItemData}
          onDismiss={() => {
            setExpandedItemData(null);
          }}
        />
      )}
      <StatsContainer isCommentPanelOpen={isCommentPanelOpen}>
        <StatsText>
          Number of people making choice: &nbsp; {moduleResponsesLength}
        </StatsText>
        <StatsText>
          Avg number of choices people make: &nbsp;{' '}
          {Number.isNaN(avgChoices) ? 0 : avgChoices}
        </StatsText>
      </StatsContainer>
      <ChartContainer hasResponse={hasResponse}>
        {chartData.map((item, index) => {
          const hasCount = item.count > 0;
          const key = `${index}${item.count}${item.title}`;

          return (
            <Item hasCount={hasCount} key={key}>
              <Count hasCount={hasCount}>
                {Number(item.count) < 10 ? (
                  <>
                    <span style={{ visibility: 'hidden' }}>0</span>
                    {item.count}
                  </>
                ) : (
                  <>{item.count}</>
                )}
              </Count>
              <BarContainer>
                <div css={{ display: 'flex', alignItems: 'center' }}>
                  <Label hasCount={hasCount}>{item.title}</Label>
                  {item.imageUrl ? (
                    <ExpandButton onClick={() => setExpandedItemData(item)}>
                      <IconExpand css={{ marginTop: '4px' }} />
                    </ExpandButton>
                  ) : null}
                </div>
                <a data-tip data-for={key}>
                  <Bar
                    onClick={(event) =>
                      onClickBar({ users: item.users, event })
                    }
                    selected={isBarSelected({ key: item.key })}
                    width={getBarWidth(item.count, chartData[0].count)}
                    data-key={item.key}
                  />
                </a>
                {hasCount && (
                  <ReactTooltip
                    id={key}
                    place="right"
                    type="light"
                    effect="solid"
                    multiline
                    backgroundColor="#FFFFFF"
                  >
                    <TooltipComponent data={item.users} />
                  </ReactTooltip>
                )}
              </BarContainer>
            </Item>
          );
        })}
      </ChartContainer>
    </Container>
  );
}
