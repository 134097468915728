import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import withAuthentication from './components/auth-context';
import Navigation from './nav';

const App = () => {
  return <Navigation />;
};

export default withAuthentication(App);
