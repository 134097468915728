import React from 'react';
import ReactDOMServer from 'react-dom/server';
import styled from 'styled-components';

const XAxisSVG = ReactDOMServer.renderToString(
  <svg
    width="12"
    height="28"
    viewBox="0 0 14 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.04865 23.5732L7.19433 17.4275L13.34 23.5732"
      stroke="#2AA6F9"
      strokeWidth="1.5"
    />
    <path
      d="M13.34 6.19055L7.19432 12.3362L1.04864 6.19055"
      stroke="#2AA6F9"
      strokeWidth="1.5"
    />
  </svg>
);

const XAxisCollapseSVG = ReactDOMServer.renderToString(
  <svg
    width="12"
    height="24"
    viewBox="0 0 14 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.850586 8.2063L7.0569 1.99998L13.2632 8.2063"
      stroke="#2AA6F9"
      strokeWidth="1.5"
    />
    <path
      d="M13.2632 16.3471L7.05686 22.5534L0.850546 16.3471"
      stroke="#2AA6F9"
      strokeWidth="1.5"
    />
  </svg>
);

const YAxisSVG = ReactDOMServer.renderToString(
  <svg
    width="29"
    height="12"
    viewBox="0 0 31 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.89867 1L13.0443 7.14568L6.89867 13.2914"
      stroke="#2AA6F9"
      strokeWidth="1.5"
    />
    <path
      d="M24.2813 13.2914L18.1356 7.1457L24.2813 1.00002"
      stroke="#2AA6F9"
      strokeWidth="1.5"
    />
  </svg>
);

const YAxisCollapseSVG = ReactDOMServer.renderToString(
  <svg
    width="33"
    height="14"
    viewBox="0 0 33 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.89893 13.2914L1.75325 7.1457L7.89893 1.00002"
      stroke="#2AA6F9"
      strokeWidth="1.5"
    />
    <path
      d="M25.2815 1L31.4272 7.14568L25.2815 13.2914"
      stroke="#2AA6F9"
      strokeWidth="1.5"
    />
  </svg>
);

const numberLabel = {
  fontSize: 45,
  fill: '#2AA6F9',
  fontFamily: 'Roboto',
  fontWeight: '700',
};

const axisLabel = {
  fontSize: 21,
  fontFamily: 'Neue Haas Grotesk Text Std',
  fontStyle: 'normal',
  fontWeight: 'bold',
  color: '#333333',
  textAnchor: 'start',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'block',
};

const Button = styled('button')`
  background-repeat: no-repeat;
  outline: none;
  border: none;
  background-color: #fff;
`;

const XCollapseButton = styled(Button)`
  background-image: url(data:image/svg+xml;base64,${(props) =>
    props.xAxisToggle ? btoa(XAxisCollapseSVG) : btoa(XAxisSVG)});
  width: 1em;
  height: 2em;
`;

const YCollapseButton = styled(Button)`
  background-image: url(data:image/svg+xml;base64,${(props) =>
    props.yAxisToggle ? btoa(YAxisCollapseSVG) : btoa(YAxisSVG)});
  width: ${(props) => (props.yAxisToggle ? '3em' : '2em')};
  height: 1em;
`;

export { numberLabel, axisLabel, XCollapseButton, YCollapseButton };
