import { useState } from "react";

export class Async {
  constructor(initialData) {
    this.data = initialData;
  }

  isSuccess = () => this instanceof Success;
  isError = () => this instanceof AsyncError;
  isLoading = () => this instanceof Loading;
}

export class Loading extends Async {
  constructor(data) {
    super();
    this.data = data;
  }
}
export class Success extends Async {
  constructor(data, message) {
    super();
    this.data = data;
    this.message = message;
  }
}
export class AsyncError extends Async {
  constructor(error, data) {
    super();
    this.error = error;
    this.data = data;
  }
}

Async.initial = (initialData = null) => new Async(initialData);
Async.success = (data, message) => new Success(data, message);
Async.loading = (data) => new Loading(data);
Async.error = (error, data) => {
  if (!error || !(error instanceof Error))
    throw new Error("An error instance must be passed");
  return new AsyncError(error, data);
};

export const useAsyncResource = (
  fetcher = async () => {},
  initialData = null
) => {
  const [state, setState] = useState(Async.initial(initialData));

  return {
    data: state.data,
    state: state,
    reset: () => {
      setState(Async.initial(initialData));
    },
    execute: async (fetcherOverride = null) => {
      setState(Async.loading(state.data));
      let data;
      const _fetcher = fetcherOverride || fetcher;
      try {
        data = await _fetcher();
      } catch (error) {
        console.log('error', error)
        setState(Async.error(error, state.data));
        return;
      }
      setState(Async.success(data));
    },
  };
};
