import '@reach/dialog/styles.css';
import React, { useState } from 'react';
import { css } from 'styled-components';
import { useSelector } from 'react-redux';
import { getNestedData } from '../../../const/nested-data';
import {
  EXPLANATION,
  IS_SINGLE_SELECT,
  MAX_SELECTION_COUNT,
} from '../../../const/module-types';
import SurveyTitle, { Question, Description } from '../../SurveyTitle';
import { filterAndSortOptions } from '../../Builder/ModuleCard';
import OptionItem from './OptionItem';
import ExpandedItemView from './ExpandedItemView';
import CommentInput from './CommentInput';

import { styles } from './styles.js';
import {
  filterMultiSelectOptions,
  isPopulatedOptions,
} from 'components/utils/helpers';

export default function MultiSelect(props) {
  const { isParticipantMode } = useSelector((state) => state.hilo);
  const [expandedItemData, setExpandedItemData] = useState(null);
  const [titleCollapsed, setMobileTitleCollapsed] = useState(true);
  const [activeCommentInputKey, setActiveCommentInputKey] = useState(null);

  const { questionData, module, values, setFieldValue, controls } = props;
  const { moduleId } = module;

  const options = filterAndSortOptions(questionData).filter(([, value]) =>
    filterMultiSelectOptions({ value, questionData })
  );
  const selectedOptions = getNestedData(values, `multiSelect.${moduleId}`) || {}; // prettier-ignore
  const countSelectedOptions = Object.values(selectedOptions)
    .filter((option) => !!option.selected).length; // prettier-ignore

  const maxSelectionCount = questionData[IS_SINGLE_SELECT]
    ? 1
    : questionData[MAX_SELECTION_COUNT] || options.length;
  const allowExplanation = questionData[EXPLANATION];
  const populatePlaceHolder = [
    ['option1', { title: 'To be populated', key: 'option1' }],
    ['option2', { title: 'To be populated', key: 'option2' }],
    ['option3', { title: 'To be populated', key: 'option3' }],
  ];
  return (
    <>
      <SurveyTitle
        question={module.question}
        description={module.description}
        titleCollapsed={titleCollapsed}
        orderWithOutTextSlides={props.order}
        toggleTitleCollapsed={() => setMobileTitleCollapsed(!titleCollapsed)}
        className={css(styles.title)}
      />
      <div css={styles.container}>
        {expandedItemData && (
          <ExpandedItemView
            data={expandedItemData}
            onDismiss={() => setExpandedItemData(null)}
          />
        )}
        <div css={styles.subContainer}>
          <div css={styles.questionDescriptionContainer}>
            <Question css={styles.question}>{module.question}</Question>
            <Description css={styles.description}>
              {module.description}
            </Description>
          </div>
          {questionData?.populateOptions &&
          !isParticipantMode &&
          isPopulatedOptions(Object.keys(questionData)) === false
            ? populatePlaceHolder.map(([key, value]) => {
                const optionKey = `multiSelect.${moduleId}.${key}`;
                const data = getNestedData(values, optionKey) || {};
                return (
                  <OptionItem
                    key={key}
                    questionData={value}
                    data={data}
                    allowComment={allowExplanation}
                    commentInputIsActive={activeCommentInputKey === optionKey}
                    onClickExpand={() => setExpandedItemData(value)}
                    onClickEditComment={() =>
                      setActiveCommentInputKey(optionKey)
                    }
                    onClickCancelComment={() => setActiveCommentInputKey(null)}
                    onClickSaveComment={(comment) => {
                      if (!allowExplanation) return;
                      setFieldValue(optionKey, { ...data, comment });
                      setActiveCommentInputKey(null);
                    }}
                  />
                );
              })
            : options.map(([key, value]) => {
                const optionKey = `multiSelect.${moduleId}.${key}`;
                const data = getNestedData(values, optionKey) || {};
                return (
                  <OptionItem
                    key={key}
                    questionData={value}
                    data={data}
                    allowComment={allowExplanation}
                    commentInputIsActive={activeCommentInputKey === optionKey}
                    onClickExpand={() => setExpandedItemData(value)}
                    onClickEditComment={() =>
                      setActiveCommentInputKey(optionKey)
                    }
                    onClickCancelComment={() => setActiveCommentInputKey(null)}
                    onClickSaveComment={(comment) => {
                      if (!allowExplanation) return;
                      setFieldValue(optionKey, { ...data, comment });
                      setActiveCommentInputKey(null);
                    }}
                    onClick={() => {
                      const selectable =
                        countSelectedOptions < maxSelectionCount;
                      if (!data.selected && !selectable) return;
                      const selected = !data.selected;
                      setFieldValue(optionKey, { ...data, selected });
                      // required multi-tenary
                      setActiveCommentInputKey(
                        selected
                          ? optionKey
                          : optionKey === activeCommentInputKey
                          ? null
                          : activeCommentInputKey
                      );
                    }}
                  />
                );
              })}
          <div css={styles.controlsContainer}>
            <div css={styles.controlsSubContainer}>{controls()}</div>
          </div>
        </div>
      </div>
      <div css={styles.mobileBottomContainer}>
        {activeCommentInputKey &&
          (() => {
            const data = getNestedData(values, activeCommentInputKey) || {};
            return (
              <CommentInput
                defaultValue={data.comment || ''}
                className={styles.mobileCommentInput}
                onClickCancel={() => setActiveCommentInputKey(null)}
                onClickSave={(value) => {
                  if (!allowExplanation) return;
                  setFieldValue(activeCommentInputKey, { ...data, comment: value }); // prettier-ignore
                  setActiveCommentInputKey(null);
                }}
              />
            );
          })()}
        {controls({ className: styles.mobileControls })}
      </div>
    </>
  );
}
