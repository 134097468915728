import React, { Component } from 'react';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import { format } from 'date-fns';
import {
  signInWithGoogle,
  doSignOut,
  signInWithMicrosoft,
} from '../lib/auth-api';
import { AuthUserContext } from '../components/auth-context';
import styled, { keyframes } from 'styled-components';

import ifLogoImgSm from '../img/iflogo.svg';
import ifLogoImgSmWhite from '../img/iflogo_white.svg';
import DropdownArrowImg from '../img/dropdown-arrow.png';
import ChevronRightImg from '../img/chevron-right.svg';
import EditImg from '../img/edit2.svg';
import BlackEditImg from '../img/editblack.svg';
import BlueEditImg from '../img/editblue.svg';
import { ReactComponent as PresentIcon } from '../img/present_icon.svg';

export const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

export const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

const NavigationBg = styled('div')`
  width: 100%;
  background: ${(props) => (props.background ? props.background : 'white')};
  z-index: 3;
  border-bottom: #e0e0e0 1px solid;
  ${(props) =>
    props.showTitleInput ? 'box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);' : ''}
  /*
  position: fixed;
  @media (min-width: 1024px) {
    position: static;
  }
  */
  flex: 0 0 auto;
`;

const Navigation = styled('div')`
  background: ${(props) => (props.background ? props.background : 'white')};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  margin: auto;
  height: 3.125rem;
  overflow-x: auto;
  box-sizing: border-box;
  ${({ inMobileView }) =>
    inMobileView
      ? ``
      : `
  @media (min-width: 1024px) {
    height: 70px;
    padding: 12px 0;
  }
  `}
`;

export const Button = styled('div')`
  cursor: pointer;
  display: ${(props) => (props.display ? props.display : 'flex')};
  align-items: center;
  justify-content: center;
  color: ${(props) => (props.color ? props.color : 'white')};
  background: ${(props) => (props.background ? props.background : '#1CA4FC')};
  font-family: 'Neue Haas Grotesk Text Std', 'Neue Haas Grotesk Display Std',
    sans-serif;
  font-size: 0.875rem;
  font-weight: normal;
  animation: ${fadeIn} 0.1s;
  pointer-events: ${(props) =>
    props.pointerEvents ? props.pointerEvents : 'auto'};
  opacity: ${(props) => (props.opacity ? props.opacity : '1')};
  transition: opacity 0.1s;
  text-align: center;
  padding: 0.125rem 0.5rem;
  line-height: 1.2;
  margin: ${(props) => (props.margin ? props.margin : 'initial')};
  min-height: 1.75rem;
  min-width: 4.5rem;
  @media (min-width: 1024px) {
    height: 2.5rem;
    width: ${(props) => (props.width ? props.width : '6.375rem')};
  }
`;

const NotAvailable = styled('div')`
  animation: ${fadeIn} 0.1s;
  color: #333333;
`;

const Loading = styled('div')`
  animation: ${fadeOut} 0.1s;
  color: #c4c4c4;
  width: 137px;
  text-align: center;
  line-height: 29px;
`;

const LoginLogoutContainer = styled('div')``;

const UserMenuContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
`;

const UserIcon = styled('div')`
  width: 2.375rem;
  height: 2.375rem;
  display: flex;
  flex: 0 0 2.375rem;
  margin-right: 2rem;
  background-color: #1ca4fc;
  font-family: 'Neue Haas Grotesk Display Std', sans-serif;
  font-weight: bold;
  font-size: 1.125rem;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  background-image: ${(props) =>
    props.backgroundImage ? `url('${props.backgroundImage}')` : 'none'};
  background-size: cover;
`;

const DropdownArrow = styled('div')`
  width: 1rem;
  height: 0.6875rem;
  background-image: url('${DropdownArrowImg}');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
`;

const UserMenu = (props) => (
  <UserMenuContainer onClick={props.onClick}>
    <UserIcon backgroundImage={props.backgroundImage}>
      {props.backgroundImage ? '' : props.initials}
    </UserIcon>
    <DropdownArrow />
  </UserMenuContainer>
);

const LogoutButton = styled(Button)`
  font-family: 'Neue Haas Grotesk Display Std', sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  height: 1.25rem;
  align-items: flex-start;
  padding: 0;
  @media (min-width: 1024px) {
    height: auto;
    font-size: 24px;
    line-height: 29px;
    width: 137px;
  }
`;
const CloseButton = styled(LogoutButton)`
  align-items: center;
  color: white;
  background: transparent;
`;
const LoginButton = styled(Button)`
  color: #c4c4c4;
  background-color: transparent;
  border: 1px solid #c4c4c4;
`;
const LoginButtons = styled('div')`
  display: flex;
`;

const LoginLogout = (props) => (
  <LoginLogoutContainer>
    <AuthUserContext.Consumer>
      {({ authUser, canEdit, loading, setAuthUser }) => {
        if (props.hideButtons) return;
        if (loading || props.loading) return <Loading>Loading...</Loading>;
        if (props.saving) return <Loading>Saving...</Loading>;
        if (authUser && !canEdit) {
          return (
            <LoginButtons>
              <NotAvailable>
                Site only available for IF Employees, please enter code.
              </NotAvailable>
              <LogoutButton
                color="#1CA4FC"
                background="transparent"
                onClick={doSignOut}
              >
                Log Out
              </LogoutButton>
            </LoginButtons>
          );
        }
        if (authUser && canEdit) {
          if (props.showUserMenu) {
            const initials = authUser.displayName
              .split(' ')
              .reduce((acc, name) => {
                const initial = name[0];
                if (acc.length >= 2) return acc;
                return `${acc}${initial}`;
              }, '');
            const userPhoto = authUser.photoURL;
            return (
              <UserMenu
                onClick={doSignOut}
                initials={initials}
                backgroundImage={userPhoto}
              />
            );
          }
          return (
            <LogoutButton
              color="#1CA4FC"
              background="transparent"
              onClick={doSignOut}
            >
              Log Out
            </LogoutButton>
          );
        }
        return (
          <LoginButtons>
            <LoginButton onClick={signInWithGoogle}>Google Login</LoginButton>
            <LoginButton onClick={signInWithMicrosoft}>iF Login</LoginButton>
          </LoginButtons>
        );
      }}
    </AuthUserContext.Consumer>
  </LoginLogoutContainer>
);

// import database & auth
// I need to support logging in grab auth solution for if-internal
// I need to pass down who the user is to AdminPage
// login component
// meeting code entry component (should be shared)

const IFLogoSm = styled('div')`
  cursor: ${(props) => (props.onClick ? 'pointer' : 'auto')};
  background-image: ${(props) =>
    props.light ? `url('${ifLogoImgSmWhite}')` : `url('${ifLogoImgSm}')`};
  width: 1.25rem;
  height: 1.25rem;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;
  flex: 0 0 1.25rem;
  margin: 0 0.5rem 0 0.75rem;
  @media (min-width: 1024px) {
    flex: 0 0 1.75rem;
    width: 27px;
    height: 27px;
    margin: 0 28px;
    display: inline-block;
    vertical-align: bottom;
  }
`;

const Title = styled('h1')`
  font-size: 0.875rem;
  font-weight: ${(props) => (props.active ? 'bold' : 'normal')};
  font-family: 'Neue Haas Grotesk Display Std', sans-serif;
  margin: ${(props) => props.margin || '0'};
  cursor: ${(props) => (props.onClick ? 'pointer' : 'normal')};
  color: ${(props) => props.color};
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 1.25rem;
  display: flex;
  align-items: center;
  margin: 0 0.5rem;
  @media (min-width: 1024px) {
    max-width: initial;
    font-size: 1.5rem;
    line-height: 22px;
    height: auto;
    display: inline;
  }
`;

const Subtitle = styled(Title)`
  @media (min-width: 1024px) {
    font-size: 18px;
  }
`;

const Breadcrumb = styled('div')`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  display: none;
  @media (min-width: 1024px) {
    display: flex;
    flex: ${(props) =>
      props.flex
        ? props.flex
        : `0 0 ${props.showTitleInput ? '405px' : '345px'}`};
    display: block;
    max-width: ${(props) => (props.showTitleInput ? '405px' : '345px')};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const SubtitleSection = styled('div')`
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  justify-content: center;
  @media (min-width: 1024px) {
    justify-content: flex-start;
  }
`;

const HelpSection = styled('div')`
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  @media (min-width: 1024px) {
    ${(props) => (props.showTitleInput ? 'align-self: flex-start;' : '')}
  }
`;

const Tab = styled('div')`
  flex: 0 0 auto;
  font-size: 0.875rem;
  font-family: 'Neue Haas Grotesk Display Std', sans-serif;
  color: #1ca4fc;
  font-weight: ${(props) => (props.active ? 'bold' : 'normal')};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 0.5rem;
  @media (min-width: 1024px) {
    font-size: 1.5rem;
    flex: 0 0 6.25rem;
  }
`;

const Chevron = styled('div')`
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 20px;
  background: url('${ChevronRightImg}') center/auto no-repeat;
`;

export const SurveyTitle = styled('input')`
  flex: 1 0 auto;
  height: 29px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Neue Haas Grotesk Display Std', sans-serif;
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  box-shadow: inset -1px 1px 0.1875rem rgba(0, 0, 0, 0.15);
  -webkit-appearance: none;
  background: white url('${EditImg}') 14px center/auto no-repeat;
  padding: 0 0.625rem 0 52px;
  border: none;
  pointer-events: auto;
  width: 100%;
  margin: 0 0 6px 0;
  &:hover {
    background-image: url('${BlueEditImg}');
  }
  &:focus {
    background-image: url('${BlackEditImg}');
  }
`;

const TitleDateContainer = styled('div')`
  flex: 1 0 auto;
`;
const DateContainer = styled('div')`
  font-family: Neue Haas Grotesk Display Std;
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 10px;
  height: 10px;
  padding: 0 0 0 52px;
`;

export default class Header extends Component {
  static defaultProps = {
    title: '',
    subtitle: '',
    tabs: {},
    loading: false,
    saving: false,
    showUserMenu: false,
  };
  state = {
    editingSubtitle: false,
  };
  constructor(props) {
    super(props);
    this.subtitleRef = React.createRef();
  }
  renderTabs() {
    return Object.entries(this.props.tabs).map(
      ([tabLabel, { tabActive, tabAction }]) => (
        <Tab key={tabLabel} onClick={tabAction} active={tabActive}>
          {tabLabel}
        </Tab>
      )
    );
  }
  editSubtitle = () => {
    if (this.state.editingSubtitle) return;
    if (!this.props.onSubtitleEdit) return;
    this.setState({ editingSubtitle: true }, () => {
      this.subtitleRef.current.focus();
    });
  };
  detectEnterEsc = (e) => {
    if ([13, 27].includes(e.keyCode)) {
      this.setState({ editingSubtitle: false });
      const subtitle =
        e.keyCode === 13
          ? this.subtitleRef.current.innerText
          : this.props.subtitle;
      this.props.onSubtitleEdit && this.props.onSubtitleEdit({ subtitle });
    }
  };
  onTitleBlur = () => {
    const { formikProps, saveDraft } = this.props;
    const { dirty } = formikProps;
    if (dirty) {
      saveDraft();
    }
  };
  debounceSave = debounce(() => {
    console.log('debounceSave');
    this.onTitleBlur();
  }, 1000);
  onTitleChange = (e) => {
    const { debounceSave, props } = this;
    const { saveDraft } = props;
    if (e.keyCode === 13) {
      saveDraft();
    }
    debounceSave();
  };
  getTitleColor = () => {
    const { inPresentationMode, inFacilitationMode } = this.props;
    if (inPresentationMode || inFacilitationMode) return '#FFF';
    return '#333333';
  };
  render() {
    const {
      props,
      state,
      subtitleRef,
      detectEnterEsc,
      editSubtitle,
      onTitleBlur,
      debounceSave,
      onTitleChange,
    } = this;
    const {
      inPreviewMode,
      inPresentationMode,
      inFacilitationMode,
      subtitle,
      loading,
      saving,
      showUserMenu,
      hideButtons,
      onClose,
      onLogoClick,
      onHelpClick,
      helpActive,
      onSubtitleClick,
      title,
      onSubtitleEdit,
      subtitleActive,
      saveDraft,
      formikProps,
      inEditMode,
      inMobileView,
      inDesktopView,
    } = props;
    const { handleChange, values, dirty } = formikProps || {};
    const { editingSubtitle } = state;
    const { surveyDetails } = values || {};
    const { timestamp: lastSaved, draft, surveyId } = surveyDetails || {};
    const titleReactKey = `surveyDetails:name::${surveyId}`;
    const titleKey = `surveyDetails.name`;
    const titleValue = get(values, titleKey);
    const titleDisplay = title.hasOwnProperty('label') ? title.label : title;
    const onClick = title.hasOwnProperty('onClick') ? title.onClick : null;
    const isSubtitleActive =
      subtitleActive !== void 0 ? subtitleActive : !!subtitle;
    const closeButton = onClose && (
      <CloseButton onClick={onClose}>Close</CloseButton>
    );
    const inSurveyMode = !!(this.props.match || { params: {} }).params
      .surveyCode;
    const loginButtons = !onClose && (
      <LoginLogout
        loading={loading}
        saving={saving}
        showUserMenu={showUserMenu}
        hideButtons={hideButtons}
        onClose={onClose}
      />
    );
    let headerBackground = 'white';
    if (inPreviewMode || inSurveyMode) headerBackground = '#1CA4FC';
    if (inPresentationMode) headerBackground = 'black';
    if (inFacilitationMode) headerBackground = '#FF7B3C';
    const ifLogo = (
      <IFLogoSm
        light={inPresentationMode || inFacilitationMode}
        onClick={onLogoClick}
      />
    );
    const previewSubtitle =
      inPreviewMode && !loading ? (
        <Title color="white" margin="0 0 0 1rem" active={true}>
          Preview
        </Title>
      ) : null;
    const presentationSubtitle =
      inPresentationMode && !loading ? (
        <Title color="#1CA4FC" margin="0 0 0 1rem" active={true}>
          Survey Results
        </Title>
      ) : null;
    const help = onHelpClick ? (
      <Button
        background={helpActive ? '#C4C4C4' : '#FF9B3A'}
        onClick={onHelpClick}
        margin="0 1rem 0 0"
      >
        Help
      </Button>
    ) : null;
    const formattedTimestamp =
      lastSaved && lastSaved.toDate
        ? `Last modified ${format(lastSaved.toDate(), 'm/d/yy h:mma')}`
        : '';
    const dirtyDisplay = dirty ? ` - Unsaved changes` : '';
    const loadingDisplay = loading ? ` - Loading...` : '';
    const savingDisplay = saving ? ` - Saving...` : '';
    const draftDisplay = draft ? ` - Unpublished changes` : '';
    const display2 = loadingDisplay || savingDisplay || dirtyDisplay; // || draftDisplay;
    const timestampDisplay =
      loading && !formattedTimestamp
        ? `Loading...`
        : `${formattedTimestamp}${display2}`;
    const showTitleInput = values && handleChange;
    const subtitleClick = onSubtitleClick || (onSubtitleEdit && editSubtitle);
    const tabs = this.renderTabs();
    //console.log({ titleReactKey, titleValue });
    return (
      <NavigationBg
        {...{
          background: headerBackground,
          showTitleInput,
          inEditMode,
          inMobileView,
          inDesktopView,
        }}
      >
        <Navigation background={headerBackground}>
          {ifLogo}
          {inFacilitationMode && (
            <PresentIcon css={{ stroke: '#FFF', marginRight: '.25rem' }} />
          )}
          <Breadcrumb showTitleInput={showTitleInput}>
            <Title
              key="title"
              active={!subtitle}
              onClick={onClick}
              color={this.getTitleColor()}
            >
              {titleDisplay}
            </Title>
            {subtitle ? <Chevron /> : null}
            <Subtitle
              key="subtitle"
              ref={subtitleRef}
              active={isSubtitleActive}
              onClick={subtitleClick}
              contentEditable={editingSubtitle}
              onKeyDown={detectEnterEsc}
              color={this.getTitleColor()}
            >
              {subtitle}
            </Subtitle>
          </Breadcrumb>
          <SubtitleSection>
            {/*{presentationSubtitle}*/}
            {previewSubtitle}
            {tabs}
            {showTitleInput ? (
              <TitleDateContainer>
                <SurveyTitle
                  key={titleReactKey}
                  id={titleKey}
                  name={titleKey}
                  onChange={handleChange}
                  value={titleValue || ''}
                  onKeyDown={debounceSave}
                  onBlur={onTitleBlur}
                />
                <DateContainer>{timestampDisplay}</DateContainer>
              </TitleDateContainer>
            ) : null}
          </SubtitleSection>
          <HelpSection showTitleInput={showTitleInput}>
            {!inSurveyMode && closeButton}
            {loginButtons}
            {help}
          </HelpSection>
        </Navigation>
      </NavigationBg>
    );
  }
}
