import React from 'react';
import { VALUE } from '../../../const/module-types';
import { getNestedData } from '../../../const/nested-data';

const styles = {
  container: {
    background: '#fff',
    height: 'vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  subContainer: ({ inMobileView }) => ({
    display: 'flex',
    flexDirection: 'column',
    margin: '40px auto 0',
    maxWidth: '700px',
    height: '100%',
    width: '100%',
    padding: '20px',
    background: '#FFF',
  }),
  question: {
    fontSize: '40px',
    fontFamily: 'Roboto',
    lineHeight: 1.172,
    fontWeight: 'bold',
    color: '#000000',
    marginBottom: '0',
  },
  description: {
    fontFamily: 'Roboto',
    fontSize: '20px',
    lineHeight: 1.25,
    color: '#1CA4FC',
    marginTop: '1.25rem',
    marginBottom: '0',
    whiteSpace: 'pre-line',
  },
  input: {
    width: '100%',
    marginTop: '1.25rem',
    boxShadow: 'inset 0px 0px 10px #C4C4C4',
    height: '300px',
    border: 0,
    '&:focus': {
      outline: '#1CA4FC solid 2px',
    },
    padding: '32px 20px',
    fontSize: '28px',
    fontFamily: 'Roboto',
    fontWeight: 400,
    lineHeight: 1.18,
    borderRadius: 0,
  },
  controlsContainer: ({ inMobileView }) => ({
    marginTop: '80px',
    width: '100%',
    position: 'relative',
    '@media(min-width: 1280px)': {
      marginTop: inMobileView ? '80px' : '20px',
      width: inMobileView ? '100%' : '66%',
    },
  }),
};

export default function OpenEnded({
  module,
  controls,
  values,
  handleChange,
  inMobileView,
}) {
  const valueKey = `openEnded.${module.moduleId}.${VALUE}`;
  const value = getNestedData(values, valueKey);

  return (
    <div css={styles.container}>
      <div css={styles.subContainer({ inMobileView })}>
        <h2 css={styles.question}>{module.question}</h2>
        <p css={styles.description}>{module.description}</p>
        <textarea
          css={styles.input}
          name={valueKey}
          value={value || ''}
          onChange={handleChange}
        />
        <div css={styles.controlsContainer({ inMobileView })}>{controls()}</div>
      </div>
    </div>
  );
}
