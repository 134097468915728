import React from 'react';
import {
  DetailShortInputContainer,
  Input,
  InputLabel,
} from 'components/layout';
import { OPTION_MAX } from 'const/module-types';
import { getNestedData } from 'const/nested-data';

export const MaxOptionInput = ({
  name,
  isValid,
  moduleId,
  formikProps,
  label = 'Max selection number',
}) => {
  const { values, handleChange } = formikProps;
  const maxOptionkey = `moduleList.${moduleId}.questionData.${OPTION_MAX}`;
  const maxOptionCountValue = getNestedData(values, maxOptionkey) || '';

  return (
    <DetailShortInputContainer>
      <InputLabel css={[!isValid && { color: 'red' }]}>{label}</InputLabel>
      <Input
        name={name}
        onChange={handleChange}
        value={maxOptionCountValue}
        type="number"
        min={1}
        color="initial"
        css={{ outline: 0, border: isValid ? 'none' : '1px solid red' }}
      />
    </DetailShortInputContainer>
  );
};
