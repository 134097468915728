const { isEqual } = require('lodash');

/**
 * Description: this function checks if the props are equal before allowing rerendering of a component
 * it should be called on a memoized component
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns boolean
 */
const isPropsEqual = (prevProps, nextProps) => {
  return isEqual(prevProps, nextProps);
};

export default isPropsEqual;
