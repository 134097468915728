import React, { Component } from 'react';
import { connect } from 'react-redux';
import withAuthorization from 'components/with-authorization';
import AddModuleCards from 'components/Builder/AddModuleCards';
import { serverTimestamp } from '../../firebase';
import {
  CompletedCell,
  CompletedByUser,
  CompletedUserContainer,
  ManageSelected,
  ManageSubtitle,
  ManageTitle,
  ManageTotal,
  DesktopView,
  EditContainer,
  ManageContainer,
  ManageLeft,
  ManageRight,
  ManageRow,
  MobilePhone,
  MobilePhoneSkin,
  ResponsesButton,
  MobileView,
  ResponsesBorder,
  ResponsesButtons,
  ResponsesCount,
  ResponsesHeader,
  ResponsesTable,
  BuilderOverlay,
  LaunchedPadding,
  LaunchedButton,
  LaunchedSubtitle,
  LaunchedTitle,
  ResponsesLargeButton,
  BuilderRightColumn,
} from './styles';
import {
  getProject,
  getShowDelete,
  getSurvey,
  getTemplateId,
  getWorkshop,
  downloadCSV,
  sortAsc,
} from './utils';
import { Formik } from 'formik';
import format from 'date-fns/format';
import Helmet from 'react-helmet';
import {
  fetchSurveyModuleAndProjectDetails,
  getNewModuleId,
  deleteSurvey,
  saveSurvey,
  sortModules,
  sortModulesToArray,
  DESKTOP,
  MOBILE,
  updateSurveyClosed,
  getIsClosed,
  createUpdateLock,
  deleteLock,
  getEditDisabled,
} from 'lib/builder-api';
import {
  fetchSurveyResponses,
  deleteResponse,
  hideShowResponse,
} from 'lib/survey-api';
import {
  TEXT_SLIDE,
  ADD_MODULE,
  EDIT_SURVEY,
  MANAGE_RESPONSES,
} from 'const/module-types';
import {
  RESULTROOT,
  PREVIEWROOT,
  BUILDER,
  NEW_PRESENTATION_ROOT,
  PRESENTATIONROOT,
  SETTINGS,
  SURVEYROOT,
  ADMIN,
  EXPORT,
  ADD,
  EDIT,
  MANAGE,
  PROJECT_ID_QUERY_KEY,
} from 'const/routes';
import Layout, {
  PageContainerBasic,
  LeftColumn2,
  RightColumn,
  Button,
} from 'components/layout';
import SurveyModules from 'components/Builder/SurveyModules2';
import { PreviewHeader } from 'components/Builder/PreviewHeader';

import { getNestedData } from 'const/nested-data';
import { getExportArray } from '../export';
import Survey from '../Survey/survey';
import CheckedImg from 'img/checkbox-checked.svg';
import UncheckedImg from 'img/checkbox-unchecked.svg';
import CSVImg from 'img/csv-download.svg';
import VisualImg from 'img/visual-results.svg';
import BarWhiteImg from 'img/bar-chart-white.svg';
import BarBlueImg from 'img/bar-chart-blue.svg';
import { authCondition } from 'lib/auth-api';
import { HILOS } from 'const/index';

import { setHiloType, setHiloActiveModule } from '../store/hiloSlice';
import { ModuleDragDialog } from 'components/Builder/ModuleDragDialog';
import {
  getBreakingAlert,
  getDeleteModuleBreakingAlert,
  getNewSortedModuleList,
  removePopulateOption,
} from 'lib/Workshop';
import Tracking from 'lib/tracking/tracking';
import { surveyBuilderEvents } from 'lib/tracking/events';

const BUILD_TAB = 'BUILD_TAB';
const SETTINGS_TAB = 'SETTINGS_TAB';

const CompletedByUsers = ({
  viewResults,
  hideShowResponse,
  deleteResponse,
  formikProps,
  currentUser,
  deleteDisplay,
  exportSingleCSV,
}) => {
  const { values } = formikProps;
  const { responses, moduleList } = values;
  const questionHashes = Object.values(moduleList).filter(
    ({ deleted, moduleType }) => !deleted && moduleType !== TEXT_SLIDE
  );
  const questionCount = questionHashes.length;
  const questionModuleIds = questionHashes.map(({ moduleId }) => moduleId);
  const rows = Object.entries(responses)
    .sort(([aId, aResp], [bId, bResp]) => {
      return aResp.timestamp.seconds - bResp.timestamp.seconds;
    })
    .map(([id, response], i) => {
      const {
        userName,
        userId,
        timestamp,
        hidden: responseHidden,
        answerData,
      } = response;
      const { responseId = id || userId } = response;
      const isSameUser = userName === currentUser.name;
      const ts = timestamp && format(new Date(timestamp.seconds * 1000), 'Pp');
      const displayName = userName || responseId;
      const showHide = responseHidden ? (
        <ResponsesButton img={UncheckedImg} />
      ) : (
        <ResponsesButton img={CheckedImg} />
      );
      const answerCount = Object.keys(answerData).filter((moduleId) =>
        questionModuleIds.includes(moduleId)
      ).length;
      const onClickCSV = () => {
        exportSingleCSV({ rowIndex: i });
      };
      return (
        <CompletedUserContainer key={i} {...{ responseHidden, isSameUser }}>
          <CompletedCell
            data-user-id={userId}
            data-response-id={responseId}
            onClick={hideShowResponse}
            data-hidden={responseHidden}
          >
            <ResponsesButtons>{showHide}</ResponsesButtons>
          </CompletedCell>
          <CompletedCell>{i + 1}.</CompletedCell>
          <CompletedByUser title={responseId}>{displayName}</CompletedByUser>
          <CompletedCell>{ts}</CompletedCell>
          <CompletedCell>
            <ResponsesButtons>
              <ResponsesCount>
                {`${answerCount} / ${questionCount}`}
              </ResponsesCount>
              <ResponsesButton
                img={VisualImg}
                data-response-id={responseId}
                onClick={viewResults}
              />
              <ResponsesButton img={CSVImg} onClick={onClickCSV} />
              <Button
                background="#C4C4C4"
                color="white"
                data-user-id={userId}
                onClick={deleteResponse}
                display={deleteDisplay}
              >
                Delete Result
              </Button>
            </ResponsesButtons>
          </CompletedCell>
        </CompletedUserContainer>
      );
    });
  return (
    <ResponsesTable>
      <thead>
        <tr>
          <td colSpan="6">
            <ResponsesBorder />
          </td>
        </tr>
        <ResponsesHeader>
          <CompletedCell width="75px">Include</CompletedCell>
          <CompletedCell width="75px">#</CompletedCell>
          <CompletedCell>Name</CompletedCell>
          <CompletedCell width="178px">Time submitted</CompletedCell>
          <CompletedCell width="160px">Individual responses</CompletedCell>
        </ResponsesHeader>
        <tr>
          <td colSpan="6">
            <ResponsesBorder />
          </td>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </ResponsesTable>
  );
};

const LaunchedOverlay = ({ title, subtitle, buttons, hideOverlay }) => (
  <BuilderOverlay>
    <PageContainerBasic collapseMobile={true} onClick={hideOverlay}>
      <LeftColumn2 background="#1CA4FC" onClick={(e) => e.stopPropagation()}>
        <LaunchedPadding>
          <LaunchedTitle>{title}</LaunchedTitle>
          <LaunchedSubtitle>{subtitle}</LaunchedSubtitle>
          {(buttons || []).map(({ text, onClick }, i) => (
            <LaunchedButton onClick={onClick} key={`button::${i}:${text}`}>
              {text}
            </LaunchedButton>
          ))}
        </LaunchedPadding>
      </LeftColumn2>
      <BuilderRightColumn background="transparent"> </BuilderRightColumn>
    </PageContainerBasic>
  </BuilderOverlay>
);

class SurveyBuilder extends Component {
  getProject = () => {
    return getProject(this.props);
  };
  getSurvey = () => {
    const templateId = this.getTemplateId();
    if (templateId) return templateId;
    const survey = getSurvey(this.props);
    if (survey) return survey;
    return getWorkshop(this.props);
  };
  getTemplateId = () => {
    return getTemplateId(this.props);
  };
  getWorkshopId = () => getWorkshop(this.props);
  getHiloLabel = () => {
    const workshop = getWorkshop(this.props);
    let hiloLabel = HILOS.SURVEY;
    if (workshop) hiloLabel = HILOS.WORKSHOP;
    this.props.dispatch(setHiloType(hiloLabel));
    return hiloLabel;
  };
  getShowDelete = () => {
    return getShowDelete(this.props);
  };
  constructor(props) {
    super(props);
    this.surveyCodeRef = React.createRef();
    this.lockRef = null;
    const activeTab =
      {
        [ADD]: ADD_MODULE,
        [EDIT]: EDIT_SURVEY,
        [MANAGE]: MANAGE_RESPONSES,
      }[window.location.pathname] || ADD_MODULE;
    this.state = {
      activeTab,
      activeModule: null,
      leftOverlayId: null,
      initialLoadComplete: false,
      surveyLoadError: false,
      loading: true,
      saving: false,
      activeInsertIndex: null,
      previewTab: DESKTOP,
      surveyLock: null,
      surveyLaunchedOverlay: false,
      editingLaunchedOverlay: false,
      editingModule: null,
      isTemplate: false,
      isWorkshop: false,
      isModuleDialogOpen: false,
      modulePopulateAlert: [],
      draggedModule: null,
      hoveredModule: null,
      moduleDeletionMode: false,
    };
  }
  onClickPlay = () => {
    const { surveyCode } = this.props;
    window.open(`${SURVEYROOT}/${surveyCode}/1`);
  };
  selectSettingsTab = () => {
    const { isTemplate, isWorkshop } = this.state;
    if (isTemplate) {
      this.props.history.push(`${MANAGE}?templateId=${this.getSurvey()}`);
    } else if (isWorkshop) {
      this.props.history.push(
        `${MANAGE}?project=${this.getProject()}&workshop=${this.getSurvey()}`
      );
    } else {
      this.props.history.push(
        `${MANAGE}?project=${this.getProject()}&survey=${this.getSurvey()}`
      );
    }
  };
  selectAddTab = () => {
    const { isTemplate, isWorkshop } = this.state;
    if (isTemplate) {
      this.props.history.push(`${ADD}?templateId=${this.getSurvey()}`);
    } else if (isWorkshop) {
      this.props.history.push(
        `${ADD}?project=${this.getProject()}&workshop=${this.getSurvey()}`
      );
    } else {
      this.props.history.push(
        `${ADD}?project=${this.getProject()}&survey=${this.getSurvey()}`
      );
    }
  };
  selectEditTab = () => {
    const { isTemplate, isWorkshop } = this.state;
    if (isTemplate) {
      this.props.history.push(`${EDIT}?templateId=${this.getSurvey()}`);
    } else if (isWorkshop) {
      this.props.history.push(
        `${EDIT}?project=${this.getProject()}&workshop=${this.getSurvey()}`
      );
    } else {
      this.props.history.push(
        `${EDIT}?project=${this.getProject()}&survey=${this.getSurvey()}`
      );
    }
  };
  selectManageTab = () => {
    const { isTemplate } = this.state;
    if (isTemplate) {
      this.props.history.push(`${EDIT}?templateId=${this.getSurvey()}`);
      return;
    }
    this.props.history.push(
      `${MANAGE}?project=${this.getProject()}&survey=${this.getSurvey()}`
    );
  };
  saveDraft = () => {
    return this.saveSurveyClick({ draft: true });
  };
  onDupeClick = (e) => {
    e.stopPropagation();
    const { saveDraft, props } = this;
    const { values, setValues } = props.formikProps;
    const { moduleList, surveyDetails } = values;
    const projectId = getProject(props);
    const surveyId = getSurvey(props);
    const existingModuleId = e.currentTarget.dataset.moduleId;
    if (!existingModuleId) throw new Error('no moduleId provided');
    const existingModule = values.moduleList[existingModuleId];
    if (!existingModule) throw new Error('no module found');
    const {
      moduleType = '',
      order = 0,
      questionData = {},
      question = '',
      description = '',
    } = existingModule;
    const timestamp = serverTimestamp();
    const moduleId = getNewModuleId();
    const questionDataClone = JSON.parse(JSON.stringify(questionData));
    const newOrder = order + 0.5;
    const newModules = {
      ...moduleList,
      [moduleId]: {
        moduleId,
        moduleType,
        order: newOrder,
        projectId,
        question,
        description,
        questionData: questionDataClone,
        surveyId,
        timestamp,
      },
    };
    const newSurveyDetails = {
      ...surveyDetails,
      [`modules.${moduleId}`]: true,
      timestamp,
    };
    const sortedModules = sortModules(newModules);
    return Promise.resolve(
      setValues({
        ...values,
        moduleList: sortedModules,
        surveyDetails: newSurveyDetails,
      })
    ).then(saveDraft);
  };
  setSaving = (saving) => this.setState({ saving });
  onInsertClick = (activeInsertIndex) => {
    this.setState({ activeInsertIndex });
  };
  addAModule = (e) => {
    const {
      setSaving,
      getProject,
      onInsertClick,
      getSurvey,
      props,
      state,
    } = this;
    const { formikProps } = props;
    const { activeInsertIndex } = state;
    const { values, setValues } = formikProps;
    const { moduleList, surveyDetails } = values;
    const moduleType = e.currentTarget.dataset.moduleType || TEXT_SLIDE;
    const properties = { hiloType: this.getHiloLabel() };
    Tracking.builderModuleAddition({ moduleType, properties });
    const order = activeInsertIndex;
    const offsetOrder = order + 0.5;
    const newActiveInsertIndex = activeInsertIndex + 1;
    const timestamp = serverTimestamp();
    const moduleId = getNewModuleId();
    const questionData = {};
    const projectId = getProject();
    const surveyId = getSurvey();
    const newModules = {
      ...moduleList,
      [moduleId]: {
        moduleId,
        moduleType,
        order: offsetOrder,
        projectId,
        question: '',
        questionData,
        surveyId,
        timestamp,
      },
    };
    const newSurveyDetails = {
      ...surveyDetails,
      [`modules.${moduleId}`]: true,
      timestamp,
    };
    const sortedModules = sortModules(newModules);
    const newValues = {
      ...values,
      moduleList: sortedModules,
      surveyDetails: newSurveyDetails,
    };
    onInsertClick(newActiveInsertIndex);
    return Promise.resolve(setValues(newValues)).then(() => {
      return this.saveDraft();
    });
  };
  deleteSurvey = () => {
    const { setSaving, props } = this;
    const { formikProps, history } = props;
    const { setFieldValue } = formikProps;
    setSaving(true);
    return deleteSurvey({
      projectId: this.getProject(),
      surveyId: this.getSurvey(),
    }).then(() => {
      setSaving(false);
      history.push(ADMIN);
    });
  };
  copySurveyCode = (e) => {
    const target = e.currentTarget;
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(target);
    selection.removeAllRanges();
    selection.addRange(range);
    document.execCommand('copy');
  };
  removeModule = ({ moduleId }) => {
    const { saveDraft, props } = this;
    const { formikProps } = props;
    const { setValues, values } = formikProps;
    const { moduleList, modulesToDelete } = values;
    const { [moduleId]: removedModule, ...remainingModules } = moduleList;
    const newModulesToDelete = {
      ...modulesToDelete,
      [moduleId]: true,
    };
    const sortedModules = sortModules(remainingModules);
    return Promise.resolve(
      setValues({
        ...values,
        moduleList: sortedModules,
        modulesToDelete: newModulesToDelete,
      })
    ).then(() => {
      saveDraft();
    });
  };
  moveCard = ({ dragIndex, hoverIndex }) => {
    const { setValues, values } = this.props.formikProps;
    const moduleList = { ...values.moduleList };
    // the card we are moving
    const dragModule = moduleList[dragIndex];
    // the card we are dropping it on
    const hoverModule = moduleList[hoverIndex];
    const dragOrder = dragModule.order;
    const hoverOrder = hoverModule.order;
    const newOrder =
      hoverOrder < dragOrder ? hoverOrder - 0.5 : hoverOrder + 0.5;
    const newModuleList = {
      ...moduleList,
      [dragIndex]: {
        ...dragModule,
        order: newOrder,
      },
    };
    const sortedModuleList = sortModules(newModuleList);
    setValues({
      ...values,
      moduleList: sortedModuleList,
    });
  };
  onMoveClick = ({ moduleId, currentOrder }) => {
    const { values } = this.props.formikProps;
    const currentOrderInt = parseInt(currentOrder, 10);
    const hoverModule = sortModulesToArray(values.moduleList).find(
      (module) => module.order === currentOrderInt
    );
    if (!hoverModule) return;
    const hoverIndex = hoverModule.moduleId;
    this.moveCard({ dragIndex: moduleId, hoverIndex });
  };
  viewResults = (e) => {
    const { history, formikProps } = this.props;
    const { values } = formikProps;
    const { responseId } = e.currentTarget.dataset;
    history.push(
      `${RESULTROOT}/${values.surveyDetails.surveyCode}/1/${responseId}`
    );
  };
  deleteResponse = (e) => {
    const { userId } = e.currentTarget.dataset;
    const surveyId = this.getSurvey();
    deleteResponse({
      userId,
      surveyId,
    }).then(() => {
      this.fetchResponses();
    });
  };
  hideShowResponse = (e) => {
    const { setSaving, props } = this;
    const { values, setValues } = props.formikProps;
    const { userId, responseId, hidden } = e.currentTarget.dataset;
    const surveyId = this.getSurvey();
    const newHidden = hidden === 'true' ? false : true;
    const newValues = {
      ...values,
      responses: {
        ...values.responses,
        [responseId]: {
          ...values.responses[responseId],
          hidden: newHidden,
        },
      },
    };
    setValues(newValues);
    setSaving(true);
    hideShowResponse({
      userId,
      responseId,
      surveyId,
      hidden: newHidden,
    })
      .then(() => {
        return this.fetchResponses();
      })
      .then(
        () => setSaving(false),
        () => setSaving(false)
      );
  };
  exportCSV = () => {
    Tracking.event({ name: surveyBuilderEvents.EXPORT_SELECTION });
    const { responsesLoaded, loading, saving } = this.state;
    const { values } = this.props.formikProps;
    if (!responsesLoaded || loading || saving) {
      return;
    }
    const exportArray = getExportArray({
      moduleList: values.moduleList,
      responses: values.responses,
    });
    downloadCSV({ data: exportArray });
  };
  exportSingleCSV = ({ rowIndex }) => {
    const { responsesLoaded, loading, saving } = this.state;
    const { values } = this.props.formikProps;
    if (!responsesLoaded || loading || saving) {
      return;
    }
    const exportArray = getExportArray({
      moduleList: values.moduleList,
      responses: values.responses,
      showHidden: true,
    });
    const [topRow, ...userArray] = exportArray;
    const exportArray2 = [topRow, userArray[rowIndex]];
    downloadCSV({ data: exportArray2 });
  };
  onPreviewClick = (e) => {
    e.preventDefault();
    const { history, formikProps } = this.props;
    const { values } = formikProps;
    const { surveyDetails, moduleList, projectDetails } = values;
    const { surveyCode = 'NEW_SURVEY' } = surveyDetails;
    Tracking.event({
      name: surveyBuilderEvents.TEST_SURVEY,
      properties: {
        id: surveyCode,
      },
    });
    const { order = 1 } = e.currentTarget.dataset;
    const state = {
      moduleList,
      surveyDetails,
      projectDetails,
    };
    if (surveyDetails.template) {
      history.replace(`${EDIT}?templateId=${surveyDetails.templateId}`, state);
      history.push(
        `${PREVIEWROOT}/${surveyDetails.templateId}/${order}?backToEdit=true`,
        state
      );
    } else {
      history.replace(
        `${EDIT}?project=${this.getProject()}&survey=${this.getSurvey()}`,
        state
      );
      history.push(
        `${PREVIEWROOT}/${surveyCode}/${order}?backToEdit=true`,
        state
      );
    }
  };
  previewCSV = () => {
    this.props.history.push(
      `${EXPORT}?project=${this.getProject()}&survey=${this.getSurvey()}`
    );
  };
  getCurrentTab() {
    return window.location.pathname === SETTINGS ? SETTINGS_TAB : BUILD_TAB;
  }
  onPresentationClick = (e) => {
    const { formikProps, history } = this.props;
    const { values } = formikProps;
    const { surveyDetails, moduleList, projectDetails } = values;
    const { surveyCode = 'NEW_SURVEY' } = surveyDetails;
    const { order = 1 } = e.currentTarget.dataset;
    history.replace(
      `${BUILDER}?project=${this.getProject()}&survey=${this.getSurvey()}`,
      {
        moduleList,
        surveyDetails,
        projectDetails,
      }
    );
    history.push(`${PRESENTATIONROOT}/${surveyCode}/${order}`, {
      moduleList,
      surveyDetails,
      projectDetails,
    });
  };
  startSurvey = () => {
    const surveyCode = getNestedData(
      this.props,
      'formikProps.values.surveyDetails.surveyCode'
    );
    window.open(`${SURVEYROOT}/${surveyCode}/1`);
  };
  stopModalClick = (e) => {
    e.stopPropagation();
  };
  renderSettings() {
    const {
      props,
      state,
      exportCSV,
      deleteSurvey,
      viewResults,
      hideShowResponse,
      deleteResponse,
      getShowDelete,
      getSurvey,
      exportSingleCSV,
    } = this;
    const { formikProps, currentUser, history } = props;
    const { values } = formikProps;
    const { responsesLoaded, loading, saving } = state;
    const { responses } = values;
    const deleteDisplay = getShowDelete() ? 'flex' : 'none';
    if (!responsesLoaded) return null;
    const responseArray = Object.values(responses);
    const selectedResponseArray = responseArray.filter(({ hidden }) => !hidden);
    const selected = selectedResponseArray.length;
    const total = responseArray.length;
    const surveyCode = getNestedData(
      this.props,
      'formikProps.values.surveyDetails.surveyCode'
    );
    const onPresentClick = () => {
      Tracking.event({ name: surveyBuilderEvents.PRESENT_SELECTION });
      const titleLocation = `${NEW_PRESENTATION_ROOT}/${surveyCode}`;
      history.push(titleLocation);
    };
    const right = (
      <ManageContainer>
        <ManageRow>
          <ManageLeft>
            <ManageTitle>Responses</ManageTitle>
            <ManageSubtitle>
              <ManageSelected>{selected}</ManageSelected>
              <ManageTotal>{` / ${total}`}</ManageTotal>
              {` selected`}
            </ManageSubtitle>
          </ManageLeft>
          <ManageRight>
            <ResponsesLargeButton
              width="14rem"
              background="#1CA4FC"
              borderColor="#1CA4FC"
              color="white"
              textAlign="left"
              onClick={onPresentClick}
              disabled={saving || loading}
              hoverBold
            >
              Present selection
              <img src={BarWhiteImg} />
            </ResponsesLargeButton>
            <ResponsesLargeButton
              width="14rem"
              background="transparent"
              borderColor="#1CA4FC"
              color="#1CA4FC"
              textAlign="left"
              onClick={exportCSV}
              disabled={saving || loading}
              hoverBold
            >
              Export selection
              <img src={BarBlueImg} />
            </ResponsesLargeButton>
            <ResponsesLargeButton
              background="#C4C4C4"
              color="white"
              textAlign="left"
              onClick={deleteSurvey}
              display={deleteDisplay}
              margin="0 0 0 1.5rem"
              disabled={saving || loading}
              hoverBold
            >
              Delete Survey
            </ResponsesLargeButton>
          </ManageRight>
        </ManageRow>
        <CompletedByUsers
          {...{
            viewResults,
            hideShowResponse,
            deleteResponse,
            formikProps,
            currentUser,
            deleteDisplay,
            exportSingleCSV,
          }}
        />
      </ManageContainer>
    );
    return right;
  }
  saveAsTemplate = () => {
    return this.saveSurveyClick({ template: true });
  };
  setActiveTab = (activeTab) => {
    //this.setState({ activeTab });
    if (activeTab === ADD_MODULE) this.selectAddTab();
    if (activeTab === EDIT_SURVEY) this.selectEditTab();
    if (activeTab === MANAGE_RESPONSES) this.selectManageTab();
  };
  onTrashClick = () => {
    const { activeModule } = this.state;
    if (this.showBreakingAlertDialogOnModuleDelete({ activeModule })) {
      this.toggleModuleDialogOpen();
      this.toggleModuleDeletionMode();
      return;
    }
    this.removeModule({ moduleId: activeModule });
  };
  setActiveModule = (activeModule) => {
    this.setState({ activeModule: activeModule });
    this.props.dispatch(setHiloActiveModule(activeModule));
  };
  clearActiveModule = () => {
    this.setState({ activeModule: null });
    this.props.dispatch(setHiloActiveModule(''));
  };
  setLeftOverlayId = (leftOverlayId) => {
    this.setState({ leftOverlayId });
  };
  clearLeftOverlayId = (leftOverlayId) => {
    this.setState({ leftOverlayId: null });
  };
  // formik data
  componentDidMount() {
    this.getHiloLabel();
    return this.loadData({ triggerEditingOverlay: true }).then(() => {
      this.startLock();
      window.addEventListener('onbeforeunload', this.endLock);
    });
  }
  componentDidUpdate(prevProps) {
    const { loadData, getSurvey, props, state } = this;
    if (getSurvey(props) !== getSurvey(prevProps)) {
      loadData({ isTemplate: state.isTemplate });
    }
  }
  setLoading = (loading) => this.setState({ loading });

  /**
   * Load the data depending on whether its a survey, template or workshop.
   * Note all these share the same data structure and stored in the same
   * place. Differentiated by `hilo` and `isTemplate` properties
   * @param options
   * @returns {Promise<Promise<void>>}
   */
  loadData = async (options = {}) => {
    const templateId = this.getTemplateId();
    if (templateId) {
      this.setState({
        isTemplate: true,
      });
      this.props.dispatch(setHiloType(HILOS.TEMPLATE));
      return this.loadSurvey({
        ...options,
        isTemplate: true,
        triggerEditingOverlay: false,
      });
    }
    const workshopId = this.getWorkshopId();
    if (workshopId) {
      this.setState({
        isWorkshop: true,
      });
      this.props.dispatch(setHiloType(HILOS.WORKSHOP));
      return this.loadSurvey(options);
    }
    return this.loadSurvey(options);
  };

  loadSurvey = (options = {}) => {
    const { props, state, setSaving, setLoading, getSurvey, getProject } = this;
    const { formikProps, setInitialValues } = props;
    const { activeInsertIndex } = state;
    const isTemplate = options.isTemplate || state.isTemplate;
    const { values } = formikProps;
    const { triggerEditingOverlay } = options;
    const surveyId = getSurvey();
    const projectId = getProject();
    if (!surveyId) return;
    setLoading(true);
    return fetchSurveyModuleAndProjectDetails({
      surveyId,
      projectId,
      isTemplate,
    }).then(({ surveyDetails, moduleList, projectDetails, surveyLock }) => {
      const isClosed = getIsClosed(surveyDetails);
      const newSurveyDetails = surveyDetails || { name: ' ' };
      const newModuleList = moduleList || {};

      const modules = sortModulesToArray(newModuleList) || [];
      const lastItem = modules.slice(-1)[0];
      const lastOrder = lastItem ? lastItem.order : 0;
      const newActiveInsertIndex =
        activeInsertIndex !== null ? activeInsertIndex : lastOrder;

      const newValues = {
        ...values,
        surveyDetails: newSurveyDetails,
        moduleList: newModuleList,
        projectDetails,
      };
      setLoading(false);
      // remove this to stop selecting the first module on open
      //const activeModule = modules[0].moduleId;
      const overlayHash = triggerEditingOverlay
        ? {
            editingLaunchedOverlay: !isClosed,
          }
        : {};
      this.setState({
        surveyLoadError: !surveyDetails,
        activeInsertIndex: newActiveInsertIndex,
        surveyLock,
        ...overlayHash,
        //activeModule
      });
      return Promise.resolve(setInitialValues(newValues)).then(() => {
        return this.fetchResponses({ newValues });
      });
    });
  };
  fetchResponses = (options = {}) => {
    const { formikProps, setInitialValues } = this.props;
    const { values } = formikProps;
    return fetchSurveyResponses({
      surveyId: this.getSurvey(),
      showHidden: true,
    }).then((responseData) => {
      const newValues3 = {
        ...values,
        responses: responseData,
      };
      setInitialValues(newValues3);
      this.setState({ responsesLoaded: true, initialLoadComplete: true });
    });
  };
  saveSurveyClick = (options = {}) => {
    const {
      props,
      state,
      setSaving,
      getSurvey,
      getProject,
      clearEditingModule,
      loadData,
    } = this;
    const { currentUser, formikProps } = props;
    const { id: userId } = currentUser;
    const { values } = formikProps;
    const { draft = false } = options;
    const { moduleList, surveyDetails, modulesToDelete } = values;
    const { surveyCode } = surveyDetails;
    const { saving, isTemplate, activeModule } = state;
    if (saving) return;
    setSaving(true);
    const projectId = isTemplate ? '' : getProject();
    return saveSurvey({
      surveyId: getSurvey(),
      moduleId: activeModule,
      projectId,
      moduleList,
      surveyDetails,
      surveyCode,
      modulesToDelete,
      draft,
      template: isTemplate,
      userId,
      isTemplate,
    }).then(() => {
      setSaving(false);
      clearEditingModule();
      // ignoreLocationState ensures that we load the real data not preview data
      loadData({ ignoreLocationState: true });
    });
  };
  setPreviewTab = (previewTab) => this.setState({ previewTab });
  startLock = () => {
    const { props, state, getSurvey, startLock } = this;
    const { currentUser, formikProps } = props;
    const { surveyLock } = state;
    const { name: userName, id: userId } = currentUser;
    const { values } = formikProps;
    const { surveyDetails } = values;
    const surveyId = getSurvey();
    const surveyLocks = surveyLock
      ? {
          [surveyLock.id]: surveyLock,
        }
      : {};
    const editDisabled = getEditDisabled({
      surveyDetails,
      surveyLocks,
      currentUser,
    });
    if (editDisabled) {
      return;
    }
    return createUpdateLock({ surveyId, userId, userName }).then((lock) => {
      this.lockRef = setTimeout(startLock, 1000 * 60 * 60 * 1);
    });
  };
  endLock = () => {
    const { props, getSurvey, lockRef } = this;
    const { currentUser } = props;
    const { name: userName, id: userId } = currentUser || {};
    const surveyId = getSurvey();
    clearTimeout(lockRef);
    return deleteLock({ surveyId, userId, userName }).then(() => {
      // lock deleted
    });
  };
  closeLock = (e) => {
    e.preventDefault();
    return this.endLock();
  };
  componentWillUnmount() {
    this.endLock();
  }
  showSurveyLaunched = () => this.setState({ surveyLaunchedOverlay: true });
  hideSurveyLaunched = () => this.setState({ surveyLaunchedOverlay: false });
  hideEditingLaunched = () => this.setState({ editingLaunchedOverlay: false });
  toggleClosed = () => {
    const { state, props, setSaving, loadSurvey, hideSurveyLaunched } = this;
    const { saving } = state;
    const { formikProps, currentUser, hilo } = props;
    const { values } = formikProps;
    const { surveyDetails } = values;
    const { surveyId } = surveyDetails;
    const isClosed = getIsClosed(surveyDetails);
    const { id: userId } = currentUser;
    if (saving) return;
    const closed = !isClosed;
    setSaving(true);
    return updateSurveyClosed({
      closed,
      surveyId,
      userId,
      hilo,
    })
      .then(() => {
        loadSurvey();
        setSaving(false);
        hideSurveyLaunched();
      })
      .catch(() => {
        setSaving(false);
        hideSurveyLaunched();
      });
  };
  setEditingModule = (editingModule) => this.setState({ editingModule });
  clearEditingModule = () => this.setState({ editingModule: null });
  /**
   * Breaking dependency module scenarios
   *
   * 1:-
   * Dragging a module that is not populated onto one that is populated(hovered module) by the
   * dragged module. This should break if the user continues since we are moving the dragged
   * module which provides the populate options to a position greater than the one it is
   * giving the options(e.g moving 1 into the position of 2, break populate option for 2).
   * This should NOT break other modules populating from 1 provided 1 is moving to
   * a position less than theirs.
   *
   * 2:-
   * Dragging a module e.g from position 2 to 4 skipping 3 yet module 2 is used to
   * populate the options of module 3.
   * We should detect this and break the dependency from 3 if the user continues.
   * E.g we have 1,2,3,4 modules, drag 2 to 4, skip 3, but 2 populates 3, break populate
   * option for 3.
   *
   * 2.B:-
   * If we have 1,2,3,4 then 3 and 4 populate(depend) from 2. If we move 2 into 1
   * 3 and 4 should NOT break
   *
   * 3:-
   * The module attached to the dragged module is moved to a position less than
   * its own position. e.g we have 1, 2, 3 modules, module 2 is attached to 3.
   * We then move module 3 to position 1. The populate option for module 3
   * should break since module 2 will now come after module 1(which is module 3)
   * after the user chooses to continue.
   *
   * 4:-
   * We have 1,2,3,4 modules, 4 depends(populates) on 3. When we move 4 into the position of 3.
   * We break the populate option of 4 if the user continues.
   * @param draggedModule
   * @param hoveredModule
   * @param moduleList
   * @returns {boolean}
   */
  showBreakingAlertDialogOnModuleDrag = ({
    draggedModule,
    hoveredModule,
    moduleList,
  }) => {
    const breakingAlerts = getBreakingAlert({
      draggedModule,
      hoveredModule,
      moduleList,
    });
    this.setState({ modulePopulateAlert: sortAsc(breakingAlerts) });
    return breakingAlerts.length > 0;
  };
  showBreakingAlertDialogOnModuleDelete = ({ activeModule }) => {
    const { values } = this.props.formikProps;
    const moduleList = { ...values.moduleList };
    const breakingAlerts = getDeleteModuleBreakingAlert({
      moduleId: activeModule,
      moduleList,
    });
    this.setState({ modulePopulateAlert: sortAsc(breakingAlerts) });
    return breakingAlerts.length > 0;
  };
  toggleModuleDialogOpen = () =>
    this.setState((prevState) => ({
      isModuleDialogOpen: !prevState.isModuleDialogOpen,
    }));
  toggleModuleDeletionMode = () =>
    this.setState((prevState) => ({
      moduleDeletionMode: !prevState.moduleDeletionMode,
    }));
  onDismissModuleDialog = () =>
    this.setState({
      isModuleDialogOpen: false,
      draggedModule: null,
      hoveredModule: null,
      moduleDeletionMode: false,
    });
  onModuleDrag = ({
    draggedModule,
    hoveredModule,
    dragIndex,
    hoverIndex,
    moduleList,
  }) => {
    if (
      this.showBreakingAlertDialogOnModuleDrag({
        draggedModule,
        hoveredModule,
        moduleList,
      })
    ) {
      this.toggleModuleDialogOpen();
      this.setState({
        draggedModule,
        hoveredModule,
      });
    } else {
      this.moveCard({ dragIndex, hoverIndex });
    }
  };
  /**
   * Move a module that has a another dependant module or depends
   * on another module.
   */
  moveModule = () => {
    const { setValues, values } = this.props.formikProps;
    const moduleList = { ...values.moduleList };
    const { draggedModule, hoveredModule, modulePopulateAlert } = this.state;
    const sortedModuleList = getNewSortedModuleList(
      draggedModule,
      hoveredModule,
      modulePopulateAlert,
      moduleList
    );
    setValues({
      ...values,
      moduleList: sortedModuleList,
    });

    // Allow for the formik state changes to take effect below saving.
    setTimeout(() => this.saveDraft(), 500);
  };
  /**
   * Prepare module deletion if the module dialog is shown and the
   * user clicks continue
   */
  moduleDeletion = () => {
    const { setValues, values } = this.props.formikProps;
    const moduleList = { ...values.moduleList };
    const { activeModule, modulePopulateAlert } = this.state;
    const updatedModuleList = removePopulateOption({
      moduleList,
      modulePopulateAlert,
    });
    return Promise.resolve(
      setValues({
        ...values,
        moduleList: updatedModuleList,
      })
    ).then(() => this.removeModule({ moduleId: activeModule }));
  };
  onModuleMoveContinue = () => {
    const { moduleDeletionMode } = this.state;
    if (moduleDeletionMode) {
      this.moduleDeletion();
    } else {
      this.moveModule();
    }
    this.onDismissModuleDialog();
    this.setState({
      draggedModule: null,
      hoveredModule: null,
      moduleDeletionMode: false,
      modulePopulateAlert: [],
    });
  };
  render() {
    const {
      saveSurveyClick,
      saveDraft,
      props,
      state,
      moveCard,
      onPreviewClick,
      onPresentationClick,
      saveAsTemplate,
      onDupeClick,
      onMoveClick,
      setActiveTab,
      onInsertClick,
      onTrashClick,
      setActiveModule,
      clearActiveModule,
      removeModule,
      setLeftOverlayId,
      clearLeftOverlayId,
      addAModule,
      setPreviewTab,
      toggleClosed,
      loadSurvey,
      showSurveyLaunched,
      hideSurveyLaunched,
      hideEditingLaunched,
      setEditingModule,
      clearEditingModule,
    } = this;
    const {
      activeTab,
      activeModule,
      activeInsertIndex,
      saving,
      loading,
      leftOverlayId,
      previewTab,
      initialLoadComplete,
      surveyLock,
      surveyLaunchedOverlay,
      editingLaunchedOverlay,
      editingModule,
      isTemplate,
      isModuleDialogOpen,
    } = state;

    const {
      history,
      project,
      survey,
      surveyLoadError,
      location,
      formikProps,
      currentUser,
      hilo,
    } = props;
    const { values, dirty } = formikProps;
    const {
      surveyDetails,
      moduleList,
      modulesToDelete,
      projectDetails,
    } = values;
    const { draft, name, surveyCode } = surveyDetails;
    const { projectId, name: projectName } = projectDetails;
    const onSubtitleClick = () => {
      if (isTemplate) {
        history.push(`${ADMIN}/templates`);
        return;
      }
      history.push(`${ADMIN}?${PROJECT_ID_QUERY_KEY}=${projectId}`);
    };
    const settingsOverlay = this.renderSettings();
    const onLogoClick = onSubtitleClick;
    const subtitle = projectName;
    const background =
      'linear-gradient(to right, white 0%, white 50%, #F0F0F0 51%, #F0F0F0 100%)';
    const modulesArray = sortModulesToArray(moduleList);
    const emptyModules = initialLoadComplete && modulesArray.length === 0;
    const inAddMode =
      initialLoadComplete && (emptyModules || window.location.pathname === ADD); // activeTab === ADD_MODULE;
    const inEditMode = !emptyModules && window.location.pathname === EDIT; // activeTab === EDIT_SURVEY;
    const inManageMode = !emptyModules && window.location.pathname === MANAGE; // activeTab === MANAGE_RESPONSES;
    const activeIndex = modulesArray.findIndex(
      ({ moduleId }) => moduleId === activeModule
    );
    const editIndex = modulesArray.findIndex(
      ({ moduleId }) => moduleId === editingModule
    );
    const isClosed = getIsClosed(surveyDetails);
    const surveyLocks = surveyLock
      ? {
          [surveyLock.id]: surveyLock,
        }
      : {};
    const editDisabled = getEditDisabled({
      surveyDetails,
      surveyLocks,
      currentUser,
    });
    const {
      userId: lockedById,
      userName: lockedByName,
      timestamp: lockedByTime,
    } = editDisabled || {};
    const dirtyBuilder = dirty;
    const inDesktopView = previewTab === DESKTOP;
    const inMobileView = previewTab === MOBILE;
    const surveyProps = {
      inEditMode,
      surveyDetails,
      moduleList,
      activeIndex,
      saving,
      loading,
      surveyLoadError,
      setPreviewTab,
      previewTab,
      toggleClosed,
      isClosed,
      onPreviewClick,
      saveDraft,
      dirtyBuilder,
      saveSurveyClick,
      inDesktopView,
      inMobileView,
    };
    if (editDisabled) {
      return `${
        hilo.type
      } locked by ${lockedByName} ${lockedById} ${currentUser &&
        currentUser.id} ${
        lockedByTime && lockedByTime.toDate ? lockedByTime.toDate() : ''
      }`;
    }
    const rightColBg = inManageMode ? `white` : null;
    const surveyLaunched = surveyLaunchedOverlay ? (
      <LaunchedOverlay
        title={`This ${hilo.type} is launched and accepting responses.`}
        subtitle={
          <>
            All your changes will be automatically published to{' '}
            <strong>{surveyCode}</strong>.
          </>
        }
        buttons={[
          { text: 'Launch', onClick: toggleClosed },
          { text: 'Cancel', onClick: hideSurveyLaunched },
        ]}
        hideOverlay={hideSurveyLaunched}
      />
    ) : null;
    const editingLaunched = editingLaunchedOverlay ? (
      <LaunchedOverlay
        title={`This ${hilo.type} is launched and accepting responses.`}
        subtitle={
          <>
            All your changes will be automatically published to{' '}
            <strong>{surveyCode}</strong>.
          </>
        }
        buttons={[
          { text: 'Ok!', onClick: hideEditingLaunched },
          {
            text: 'Close responses while I edit',
            onClick: () => {
              toggleClosed().then(hideEditingLaunched);
            },
          },
        ]}
      />
    ) : null;
    return (
      <>
        <Layout
          {...{
            subtitle,
            background,
            onLogoClick,
            onSubtitleClick,
            formikProps,
            saveDraft,
            loading,
            saving,
            hideBodyOverflow: true,
          }}
        >
          <Helmet>
            <title>{`Builder - ${name} (${surveyCode})`}</title>
          </Helmet>
          <PageContainerBasic collapseMobile={true}>
            <ModuleDragDialog
              isOpen={isModuleDialogOpen}
              onDismiss={this.onDismissModuleDialog}
              modulePopulateAlert={this.state.modulePopulateAlert}
              onContinue={this.onModuleMoveContinue}
            />
            <LeftColumn2>
              <SurveyModules
                {...{
                  onModuleDrag: this.onModuleDrag,
                  isTemplate,
                  saveSurvey: saveSurveyClick,
                  saveDraft,
                  project,
                  survey,
                  moduleList,
                  saving,
                  loading,
                  surveyLoadError,
                  moveCard,
                  modulesToDelete,
                  onPreviewClick,
                  onPresentationClick,
                  saveAsTemplate,
                  onDupeClick,
                  onMoveClick,
                  draft,
                  location,
                  onInsertClick,
                  activeInsertIndex,
                  setActiveTab,
                  activeTab,
                  setActiveModule,
                  clearActiveModule,
                  activeModule,
                  removeModule,
                  onTrashClick,
                  loadSurvey,
                  formikProps,
                  leftOverlayId,
                  inAddMode,
                  inEditMode,
                  inManageMode,
                  modulesArray,
                  previewTab,
                  setPreviewTab,
                  isClosed,
                  toggleClosed,
                  initialLoadComplete,
                  saveSurveyClick,
                  settingsOverlay,
                  emptyModules,
                  currentUser,
                  history,
                  showSurveyLaunched,
                  setEditingModule,
                  clearEditingModule,
                  editingModule,
                }}
              />
            </LeftColumn2>
            <BuilderRightColumn {...{ inEditMode, background: rightColBg }}>
              {inAddMode ? (
                <AddModuleCards
                  {...{
                    hilo: this.getHiloLabel(),
                    addAModule,
                    setLeftOverlayId,
                    clearLeftOverlayId,
                  }}
                />
              ) : null}
              {inEditMode ? (
                <EditContainer>
                  <PreviewHeader
                    {...{
                      surveyDetails,
                      isClosed,
                      toggleClosed,
                      onPreviewClick,
                      saveDraft,
                      dirtyBuilder,
                      saveSurveyClick,
                      previewTab,
                      setPreviewTab,
                      loading,
                      saving,
                      showSurveyLaunched,
                      isTemplate,
                      moduleList,
                      activeModule,
                    }}
                  />
                  {inMobileView ? (
                    <MobileView>
                      <MobilePhoneSkin>
                        <MobilePhone>
                          <Survey
                            {...{
                              ...surveyProps,
                            }}
                          />
                        </MobilePhone>
                      </MobilePhoneSkin>
                      <MobilePhoneSkin>
                        <MobilePhone>
                          <Survey
                            {...{
                              ...surveyProps,
                              collapsedHeader: true,
                            }}
                          />
                        </MobilePhone>
                      </MobilePhoneSkin>
                    </MobileView>
                  ) : (
                    <DesktopView>
                      <Survey
                        {...{
                          ...surveyProps,
                        }}
                      />
                    </DesktopView>
                  )}
                </EditContainer>
              ) : null}
              {inManageMode ? settingsOverlay : null}
            </BuilderRightColumn>
          </PageContainerBasic>
        </Layout>
        {surveyLaunched}
        {editingLaunched}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  hilo: state.hilo,
});
const ConnectedSurveyBuilder = connect(mapStateToProps)(SurveyBuilder);

class FormikSurveyBuilder extends Component {
  state = {
    moduleList: {},
    surveyDetails: {},
    modulesToDelete: {},
    responses: {},
    projectDetails: {},
  };
  setInitialValues = (state) => {
    this.setState(state);
  };
  render() {
    const { props, state, setInitialValues } = this;
    return (
      <Formik
        enableReinitialize
        initialValues={state}
        onSubmit={(values) => {}}
        render={(formikProps) => {
          return (
            <ConnectedSurveyBuilder
              formikProps={formikProps}
              setInitialValues={setInitialValues}
              {...props}
            />
          );
        }}
      />
    );
  }
}

export default withAuthorization(authCondition)(FormikSurveyBuilder);
