export const GOOGLE_ANALYTICS_TRACKING_CODE = 'UA-36372844-10';
export const APP_IS_IN_TO_PROD =
  window.location.host.indexOf('k-base') === 0 ||
  window.location.host.indexOf('intentionalstrategy.com') === 0 ||
  false;
export const APP_IS_IN_STAGING =
  window.location.host.indexOf('kbase-dev2') === 0;
export const DEVELOPMENT_ENV = 'development';
export const STAGING_DEV = 'staging';
export const PRODUCTION_ENV = 'production';
export const SENTRY_DSN =
  'https://b3a6959f43654a77bb75f2b52ceff49c@o305845.ingest.sentry.io/5245646';
export const GOOGLE_TAG_MANAGER_ID = 'GTM-N23JTB6';
export const MIXPANEL_PROJECT_TOKEN_STAGING =
  'b02ada5b97f250f572cf823a47be4ef4';
export const MIXPANEL_PROJECT_TOKEN = '370e9c7f8656047f9be06406304faea6';

export const getApplicationEnv = () => {
  if (APP_IS_IN_TO_PROD || process.env.NODE_ENV === PRODUCTION_ENV) {
    return PRODUCTION_ENV;
  }
  if (process.env.NODE_ENV === DEVELOPMENT_ENV) {
    return DEVELOPMENT_ENV;
  }
  if (process.env.NODE_ENV === STAGING_DEV || APP_IS_IN_STAGING) {
    return STAGING_DEV;
  }
  return DEVELOPMENT_ENV;
};

export const HILOS = {
  SURVEY: 'survey',
  WORKSHOP: 'workshop',
  TEMPLATE: 'template',
  PRESENTATION: 'presentation',
};

export const DIALOG = {
  CREATE_HILO: 'createHilo',
  CREATE_TEMPLATE: 'createTemplate',
};

export const getGoogleTagManagerConfig = () => {
  if (APP_IS_IN_TO_PROD || process.env.NODE_ENV === PRODUCTION_ENV) {
    return {
      gtmId: GOOGLE_TAG_MANAGER_ID,
      auth: 'cmlo7YWt8r7ZQQ3Ur86zmw',
      preview: 'env-1',
    };
  }
  return {
    gtmId: GOOGLE_TAG_MANAGER_ID,
    auth: 'rQkNBqakIwx0fCv_3U_Naw',
    preview: 'env-12',
  };
};
