import React, { Component } from 'react';
import { Formik } from 'formik';
import queryString from 'query-string';
import { format } from 'date-fns';
import withAuthorization from '../components/with-authorization';
import {
  fetchSurveyAndModules,
  sortModules,
  getOptionKeys,
} from '../lib/builder-api';
import { fetchSurveyResponses } from '../lib/survey-api';
import {
  TEXT_SLIDE,
  PLOTTER_XY,
  PLOTTER_22,
  POINT_DISTRIBUTION,
  STACK_RANK,
  TEXT_HIGHLIGHT,
  SPECTRUM_DISTRIBUTION,
  MATRIX,
  PERSONAL_INFO,
  SENTENCE,
  MADLIB,
  POINTS,
  EXPLANATION_VALUE,
  NAME,
  EMAIL,
  TEAM,
  LEFT_ENDPOINT,
  MIDPOINT,
  RIGHT_ENDPOINT,
  SPECTRUM_VALUE,
  SPECTRUM_VALUE_V1,
  X_LABEL,
  Y_LABEL,
  X_CURRENT,
  Y_CURRENT,
  X,
  Y,
  POSX_LABEL,
  NEGX_LABEL,
  POSY_LABEL,
  NEGY_LABEL,
  FIRST_COLUMN_LABEL,
  TOTAL_POINTS,
  PLOTTER_VALUES,
  MADLIB_SENTENCE,
  OPTIONS_PREFIX,
  OPEN_ENDED,
  VALUE,
  MULTI_SELECT,
  OPTION_PREFIX,
} from '../const/module-types';
import Layout, { PageContainer } from '../components/layout';
import { getNestedData } from '../const/nested-data';

export const getRowData = ({ moduleList = [], answerData = {} }) => {
  const modules = sortModules(moduleList);
  return Object.entries(answerData)
    .map(([moduleId, responseData]) => {
      const module = modules[moduleId] || {};
      const { questionData = {}, moduleType, displayOrder } = module;
      if (moduleType === TEXT_SLIDE) {
        const explanation = responseData[EXPLANATION_VALUE] && {
          [`q${displayOrder}-text-explanation`]: responseData[
            EXPLANATION_VALUE
          ],
        };
        const rowData = {
          ...explanation,
        };
        return rowData;
      }
      if (moduleType === PLOTTER_XY) {
        const {
          [X_LABEL]: xLabel,
          [Y_LABEL]: yLabel,
          [X_CURRENT]: currentX,
          [Y_CURRENT]: currentY,
        } = questionData;
        const plotterValues =
          getNestedData(responseData, `${PLOTTER_VALUES}`) || [];
        const sortedValues = plotterValues.sort((a, b) => a.x - b.x);
        const sortedObj = sortedValues.reduce((collector, { x, y }) => {
          return {
            ...collector,
            [`q${displayOrder}-xy-plotter${x}`]: y,
          };
        }, {});
        const info = {
          [`q${displayOrder}-xy-xlabel`]: xLabel,
          [`q${displayOrder}-xy-ylabel`]: yLabel,
          [`q${displayOrder}-xy-currentX`]: currentX,
          [`q${displayOrder}-xy-currentY`]: currentY,
          ...sortedObj,
        };
        const explanation = responseData[EXPLANATION_VALUE] && {
          [`q${displayOrder}-xy-explanation`]: responseData[EXPLANATION_VALUE],
        };
        const rowData = {
          ...info,
          ...explanation,
        };
        return rowData;
      }
      if (moduleType === PLOTTER_22) {
        const {
          [POSX_LABEL]: posXLabel,
          [NEGX_LABEL]: negXLabel,
          [POSY_LABEL]: posYLabel,
          [NEGY_LABEL]: negYLabel,
        } = questionData;
        const { [X]: xValue, [Y]: yValue } =
          getNestedData(responseData, `${PLOTTER_VALUES}.0`) || {};
        const info = {
          [`q${displayOrder}-2x2-xvalue`]: xValue,
          [`q${displayOrder}-2x2-yvalue`]: yValue,
          [`q${displayOrder}-2x2-posXLabel`]: posXLabel,
          [`q${displayOrder}-2x2-posYLabel`]: posYLabel,
          [`q${displayOrder}-2x2-negXLabel`]: negXLabel,
          [`q${displayOrder}-2x2-negYLabel`]: negYLabel,
        };
        const explanation = responseData[EXPLANATION_VALUE] && {
          [`q${displayOrder}-2x2-explanation`]: responseData[EXPLANATION_VALUE],
        };
        const rowData = {
          ...info,
          ...explanation,
        };
        return rowData;
      }
      // todo POINT_DISTRIBUTION_EXPLANATION
      if (moduleType === POINT_DISTRIBUTION) {
        const options = getOptionKeys(questionData);
        const { [TOTAL_POINTS]: totalPoints } = questionData;
        const optionsAndPoints = options.reduce((collector, optionKey) => {
          const val =
            responseData[optionKey] && responseData[optionKey][POINTS];
          const choice = questionData[optionKey];
          const explanation =
            responseData[optionKey] &&
            responseData[optionKey][EXPLANATION_VALUE];
          const explanationHash = explanation
            ? {
                [`q${displayOrder}-pointdist-${optionKey}-explanation`]: explanation,
              }
            : {};
          return {
            ...collector,
            [`q${displayOrder}-pointdist-${optionKey}-val`]: val || 0,
            [`q${displayOrder}-pointdist-${optionKey}-choice`]: choice,
            ...explanationHash,
          };
        }, {});
        const rowData = {
          [`q${displayOrder}-pointdist-total-points`]: totalPoints,
          ...optionsAndPoints,
        };
        return rowData;
      }
      if (moduleType === TEXT_HIGHLIGHT) {
        const sentence = questionData[SENTENCE];
        const highlights = Object.values(responseData.highlightValues).map(
          (highlightHash) => {
            const { anchorOffset, focusOffset } = highlightHash;
            const secondThird = sentence.slice(anchorOffset, focusOffset);
            return secondThird;
          }
        );
        const explanation = responseData[EXPLANATION_VALUE] && {
          [`q${displayOrder}-hl-explanation`]: responseData[EXPLANATION_VALUE],
        };
        const rowData = {
          [`q${displayOrder}-hl-full`]: sentence,
          [`q${displayOrder}-hl-highlights`]: JSON.stringify(highlights),
          ...explanation,
        };
        return rowData;
      }
      if (moduleType === STACK_RANK) {
        const optionKeys = getOptionKeys(responseData);
        const options = optionKeys.reduce((collector, optionKey) => {
          return {
            ...collector,
            [`q${displayOrder}-sr-${optionKey}-val`]: responseData[optionKey]
              .stackText,
            [`q${displayOrder}-sr-${optionKey}-pos`]: responseData[optionKey]
              .order,
          };
        }, {});
        const explanation = responseData[EXPLANATION_VALUE] && {
          [`q${displayOrder}-sr-explanation`]: responseData[EXPLANATION_VALUE],
        };
        const rowData = {
          ...options,
          ...explanation,
        };
        return rowData;
      }
      // todo TEXT HIGHLIGHT
      if (moduleType === SPECTRUM_DISTRIBUTION) {
        const {
          [LEFT_ENDPOINT]: leftEndpoint,
          [MIDPOINT]: midpoint,
          [RIGHT_ENDPOINT]: rightEndpoint,
        } = questionData;
        const {
          [SPECTRUM_VALUE]: spectrumValue,
          [SPECTRUM_VALUE_V1]: spectrumValueV1,
        } = responseData;
        const info = {
          [`q${displayOrder}-spectrum-label`]: JSON.stringify([
            leftEndpoint,
            midpoint,
            rightEndpoint,
          ]),
          [`q${displayOrder}-spectrum-value`]: spectrumValue || spectrumValueV1,
        };
        const explanation = responseData[EXPLANATION_VALUE] && {
          [`q${displayOrder}-spectrum-explanation`]: responseData[
            EXPLANATION_VALUE
          ],
        };
        const rowData = {
          ...info,
          ...explanation,
        };
        return rowData;
      }
      if (moduleType === MATRIX) {
        const { [FIRST_COLUMN_LABEL]: firstColumnLabel } = questionData;
        const info = {
          [`q${displayOrder}-matrix-label`]: firstColumnLabel,
          [`q${displayOrder}-matrix-json`]: JSON.stringify(responseData),
        };
        const explanation = responseData[EXPLANATION_VALUE] && {
          [`q${displayOrder}-matrix-explanation`]: responseData[
            EXPLANATION_VALUE
          ],
        };
        const rowData = {
          ...info,
          ...explanation,
        };
        return rowData;
      }
      if (moduleType === PERSONAL_INFO) {
        const { [TEAM]: team, [NAME]: name, [EMAIL]: email } = responseData;
        const info = {
          [`q${displayOrder}-pi-${TEAM}`]: questionData[team],
          [`q${displayOrder}-pi-${NAME}`]: name,
          [`q${displayOrder}-pi-${EMAIL}`]: email,
        };
        const explanation = responseData[EXPLANATION_VALUE] && {
          [`q${displayOrder}-pi-explanation`]: responseData[EXPLANATION_VALUE],
        };
        const rowData = {
          ...info,
          ...explanation,
        };
        return rowData;
      }
      if (moduleType === OPEN_ENDED) {
        const { [VALUE]: value } = responseData;
        return {
          [`q${displayOrder}-oe-response`]: value,
        };
      }
      if (moduleType === MADLIB) {
        const { [MADLIB_SENTENCE]: madlibSentence } = questionData;
        const selectKeys = Object.entries(responseData).reduce(
          (collector, [key, value]) => {
            if (key.includes(OPTIONS_PREFIX)) {
              return {
                ...collector,
                [`q${displayOrder}-madlib-${key}`]: value,
              };
            }
            return collector;
          },
          {}
        );
        const info = {
          [`q${displayOrder}-madlib-full`]: madlibSentence,
          ...selectKeys,
        };
        const explanation = responseData[EXPLANATION_VALUE] && {
          [`q${displayOrder}-madlib-explanation`]: responseData[
            EXPLANATION_VALUE
          ],
        };
        const rowData = {
          ...info,
          ...explanation,
        };
        return rowData;
      }
      if (moduleType === MULTI_SELECT) {
        const userOptions = Object.entries(answerData[moduleId])
          .filter(([key]) => key.startsWith(OPTION_PREFIX) && !(questionData[key]?.removed))
          .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}); // prettier-ignore
        const selectedOptionsText = Object.entries(userOptions)
          .filter(([, value]) => value.selected)
          .map(([key]) => questionData[key]?.title)
          .join(', ');
        const comments = Object.entries(userOptions)
          .filter(([, value]) => !!value.comment)
          .map(
            ([key, value]) => `${questionData[key]?.title}: ${value.comment}`
          )
          .join('\u2028')
          .replace(/\n\n/g, '\u2028');
        return {
          [`q${displayOrder}-multichoice-values`]: selectedOptionsText,
          [`q${displayOrder}-multichoice-explanations`]: comments,
        };
      }
      // default
      console.log(
        `unknown moduleType ${moduleType} ${JSON.stringify({
          questionData,
          responseData,
        })}`
      );
      return {};
    })
    .reduce((collector, hash) => {
      return {
        ...collector,
        ...hash,
      };
    }, {});
};

const isSurveyComplete = ({ isDone }) => (isDone ? 'TRUE' : 'FALSE');

export const getExportArray = ({
  moduleList = {},
  responses = {},
  showHidden,
}) => {
  const responsesAsArray = Object.entries(responses);
  const responseMap = responsesAsArray
    .map(([userId, response]) => {
      const {
        userName,
        userEmail,
        timestamp,
        answerData,
        hidden,
        isDone,
      } = response;
      if (!showHidden && hidden) return null;
      const rowData = getRowData({
        moduleList: moduleList,
        answerData,
      });
      const userHash = {
        userName,
        userEmail,
        complete: isSurveyComplete({ isDone }),
        timestamp:
          timestamp && format(new Date(timestamp.seconds * 1000), 'Pp'),
        hidden,
      };
      return {
        ...userHash,
        ...rowData,
      };
    })
    .filter((x) => x);
  const collectRowKeys = Object.keys(
    responseMap.reduce((collector, responseHash) => {
      return {
        ...collector,
        ...responseHash,
      };
    }, {})
  ).sort((a, b) => {
    const firstColumns = ['userName', 'userEmail', 'timestamp', 'hidden'];
    if (firstColumns.includes(a) && firstColumns.includes(b))
      return firstColumns.indexOf(a) - firstColumns.indexOf(b);
    if (firstColumns.includes(a)) return -1;
    if (firstColumns.includes(b)) return 1;
    const aMatch = a.match(/\d+/);
    const bMatch = b.match(/\d+/);
    const aInt = aMatch ? parseInt(aMatch[0], 10) : 0;
    const bInt = bMatch ? parseInt(bMatch[0], 10) : 0;
    return aInt - bInt;
  });
  const row = responseMap.map((responseHash) => {
    return collectRowKeys.map((rowKey) => {
      const response = responseHash[rowKey]
        ? JSON.stringify(responseHash[rowKey]).replace(/"/g, '""')
        : '(none)';
      return response;
    });
  });
  const exportArray = [Object.values(collectRowKeys), ...row];
  return exportArray;
};

export class Export extends Component {
  getProject = () => {
    const parsedQuery = queryString.parse(this.props.location.search);
    const { project } = parsedQuery;
    return project;
  };
  getSurvey = () => {
    const parsedQuery = queryString.parse(this.props.location.search);
    const { survey } = parsedQuery;
    return survey;
  };
  componentDidMount() {
    this.loadSurveyAndFetchResponses();
  }
  loadSurveyAndFetchResponses = () => {
    return this.loadSurvey().then(() => {
      return this.fetchResponses();
    });
  };
  loadSurvey = () => {
    console.log('loading survey');
    const { setValues, location, values } = this.props;
    const survey = this.getSurvey();
    if (!survey) return Promise.resolve();
    const { surveyDetails, moduleList } = (location && location.state) || {};
    // try to restore temporary location data (preview)
    if (surveyDetails && moduleList) {
      setValues({
        ...values,
        surveyDetails,
        moduleList,
        loading: false,
      });
      return Promise.resolve();
      // otherwise load the survey normally
    } else {
      setValues({
        ...values,
        loading: true,
      });
      return fetchSurveyAndModules({ surveyId: survey }).then(
        ({ surveyDetails, moduleList }) => {
          const newSurveyDetails = (surveyDetails &&
            surveyDetails.draftDetails) ||
            surveyDetails || { name: ' ' };
          const newModuleList =
            (surveyDetails && surveyDetails.draftModules) || moduleList || {};
          setValues({
            ...values,
            loading: false,
            surveyLoadError: !surveyDetails,
            surveyDetails: newSurveyDetails,
            moduleList: newModuleList,
          });
        }
      );
    }
  };
  fetchResponses = () => {
    return fetchSurveyResponses({
      surveyId: this.getSurvey(),
      showHidden: true,
    }).then((responseData) => {
      this.props.setValues({
        ...this.props.values,
        responses: responseData,
        responsesLoaded: true,
      });
    });
  };
  render() {
    if (!this.props.values.responsesLoaded || this.props.values.loading) {
      console.log('responses not loaded yet, please wait');
      return null;
    }
    const exportArray = getExportArray({
      moduleList: this.props.values.moduleList,
      responses: this.props.values.responses,
    });
    //downloadCSV({ data: exportArray });
    const trs = exportArray.map((r, i) => {
      const tds = r.map((col, j) => {
        return <td key={j}>{col}</td>;
      });
      return <tr key={i}>{tds}</tr>;
    });
    return (
      <div style={{ overflow: 'scroll' }}>
        <table>
          <tbody>{trs}</tbody>
        </table>
      </div>
    );
  }
}

export class ExportContainer extends Component {
  render() {
    return (
      <Layout
        title={{
          label: 'CSV Preview',
          onClick: () => this.props.history.goBack(),
        }}
      >
        <PageContainer>
          <Formik
            initialValues={{
              name: '',
              surveyCode: '',
              question: '',
              description: '',
              moduleList: {},
              surveyDetails: { name: ' ' },
              surveyLoadError: false,
              loading: false,
              saving: false,
              responses: {},
              responsesLoaded: false,
            }}
            onSubmit={() => {}}
            render={(formikProps) => {
              return <Export {...formikProps} {...this.props} />;
            }}
          />
        </PageContainer>
      </Layout>
    );
  }
}

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(ExportContainer);
