import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const surveyBuilderSlice = createSlice({
    name: 'surveyBuilder',
    initialState,
    reducers: {

    }
});

export const {} = surveyBuilderSlice.actions;

export default surveyBuilderSlice.reducer;