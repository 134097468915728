import styled from "styled-components";

export const Container = styled(`div`)`
  background: #FFFFFF;
  justify-content: center;
`;

export const InfoBox = styled(`div`)`
  background: #FFFFFF;
  margin: 0;
  position: absolute;
  top: 40%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  width: 610px;
  height: 304px;
  filter: drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.25));
  z-index: 9999999 !important;
  border: 2px;
  border-radius: 10px;
  padding: 76px 65px 0;
`;

export const Title = styled(`p`)`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 47px;
  color: #1CA4FC;
`;

export const InfoText = styled(`p`)`
  margin: 0;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 35px;
  color: #000000;
`;

export const SalyOneImg = styled(`div`)`
  display: flex;
  position: absolute;
  left: 50%;
  top: 0;
  width: 841px;
  height: 841px;
`;

export const SalyTwoImg = styled(`div`)`
  display: flex;
  position: absolute;
  left: 2%;
  top: 20%;
  width: 685px;
  height: 685px;
`;
