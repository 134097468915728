import queryString from 'query-string';

export const getProject = ({ location }) => {
  const parsedQuery = queryString.parse(location.search);
  const { project } = parsedQuery;
  return project;
};

export const getSurvey = ({ location }) => {
  const parsedQuery = queryString.parse(location.search);
  const { survey, workshop } = parsedQuery;
  if (!survey) {
    return workshop;
  }
  return survey;
};

export const getTemplateId = ({ location }) => {
  const parsedQuery = queryString.parse(location.search);
  const { templateId } = parsedQuery;
  return templateId;
};

export const getWorkshop = ({ location }) => {
  const parsedQuery = queryString.parse(location.search);
  const { workshop } = parsedQuery;
  return workshop;
};

export const getShowDelete = ({ location }) => {
  const parsedQuery = queryString.parse(location.search);
  const { showDelete } = parsedQuery;
  return showDelete;
};

const arrayToCSV = (args) => {
  const data = args.data || null;
  if (!data || !data.length) return;
  const rowArray = data.map((colArray, index) => {
    const filteredColArray = colArray.map((info) => {
      if (!info) return info;
      return `"${info}"`;
    });
    const line = filteredColArray.join(',');
    return index == 0 ? /*'data:text/csv;charset=utf-8,\uFEFF' + */ line : line;
  });
  const csvContent = rowArray.join('\n');
  return csvContent;
};

export const downloadCSV = (args) => {
  let csv = arrayToCSV(args);
  if (csv == null) return;
  const filename = args.filename || 'export.csv';
  /*
    if (!csv.match(/^data:text\/csv/i)) {
      csv = 'data:text/csv;charset=utf-8,\uFEFF' + csv;
    }
    */
  const data = URL.createObjectURL(new Blob([csv]));
  let link = document.createElement('a');
  link.setAttribute('href', data);
  link.setAttribute('download', filename);
  link.click();
};

export const sortAsc = (arr) =>
  arr.sort((a, b) => a.displayOrder - b.displayOrder);
