import React from 'react';
import { useSelector } from 'react-redux';
import { LineBubbleChart } from '../common';
import { PointDistributionLineChartContainer } from '../../Presentation/ChartContainer/styles';
import { isThereData } from '../../../lib/Presentation';
import NoData from '../common/NoData';
import HorizontalBarChart from './HorizontalBarChart';
import { HILOS } from 'const/index';

const PointDistribution = ({ moduleId, mode, comparisonSection }) => {
  const distributionData = useSelector((state) => state.presentation[moduleId]);

  const selectedParticipantResponseId = useSelector((state) => {
    if (comparisonSection && mode === HILOS.WORKSHOP) {
      return state.workshop.comparison[comparisonSection]
        .selectedParticipantResponseId;
    } else {
      return state.presentation.selectedParticipantResponseId;
    }
  });

  const selectedParticipantData =
    distributionData?.participantsData?.[selectedParticipantResponseId];

  const distributionToggle = useSelector(
    (state) => state.presentation[moduleId]?.distributionToggle
  );

  return (
    <>
      {isThereData(distributionData.averages) &&
        (distributionToggle ? (
          <PointDistributionLineChartContainer>
            <LineBubbleChart
              moduleId={moduleId}
              data={distributionData.chartData}
              xLabel="Points"
              flyOutLabelType="Point"
              mode={mode}
              comparisonSection={comparisonSection}
            />
          </PointDistributionLineChartContainer>
        ) : (
          <div style={{ width: '150%' }}>
            <HorizontalBarChart
              moduleId={moduleId}
              selectedPoint={distributionData.selectedPoint}
              data={distributionData.averages}
              selectedParticipantData={selectedParticipantData}
            />
          </div>
        ))}

      {!isThereData(distributionData.averages) && <NoData />}
    </>
  );
};

export default PointDistribution;
