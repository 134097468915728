import styled from 'styled-components';

export const Container = styled('div')`
  width: 100%;
  padding-left: 6.5em;
  padding-right: 6.5em;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background: #ffffff;
  overflow: auto;
  height: auto;
  clear: both;
`;

export const Row = styled('div')`
  display: flex;
  padding-bottom: 1.5rem;
`;

export const DescRow = styled('div')`
  display: flex;
`;

export const RowCol = styled('div')`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const RightAlign = styled('div')`
  display: flex;
  margin-left: auto;
`;

export const Question = styled('h2')`
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
  font-weight: bold;
  padding: 0;
  margin: 0;
  margin-left: 0.9rem;
`;

export const Description = styled('div')`
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
  font-weight: 500;
  color: #3faffa;
  font-size: 1.25rem;
  margin-top: 1.25rem;
  display: flex;
  flex: wrap;
  width: 100%;
`;

export const Explanation = styled('div')`
  background: #ffffff;
  margin-top: 2.5em;
  padding: 2em 1em;
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
  font-weight: 300;
  font-size: 1rem;
  color: ${(props) => (props.isExplanation ? '#000000' : '#c4c4c4')};
  letter-spacing: -0.01em;
  height: 14em;
  width: 25em;
  border: 2px solid #e5e5e5;
  overflow: auto;
`;

export const DescriptionToggle = styled('span')`
  background: #f1f2f5;
  border-radius: 10px;
  width: 30px;
  height: 30px;
  color: #1ca4fc;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 15px;
  letter-spacing: -0.01em;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: end;
  cursor: pointer;
`;
