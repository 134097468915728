import styled from "styled-components";

const Label =  styled("label")`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 101.83%;
  letter-spacing: -0.01em;
  color: #1CA4FC;
`;

const DropDownContainer = styled("div")`
  flex: 2 0 350px;
  background: #FFFFFF;
  font-family: Roboto;
  margin: 5px 0 40px;
`;

const DropDownHead = styled("div")`
  width: 310px;
  padding-right: 1em;
  padding-left: 0.8em;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 101.83%;
  letter-spacing: -0.01em;
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  color: #1CA4FC;
  height: 35px;
  padding-top: 8px;
`;

const DropDownListContainer = styled("div")`
  width: 350px;
  position: absolute;
  z-index: 10000000;
  overflow: auto;
  max-height: 300px;
`;

const DropDownList = styled("ul")`
  padding: 0;
  margin: 0;
  padding-left: 1em;
  background: #ffffff;
  border: 2px solid #e5e5e5;
  box-sizing: border-box;
  color: #3faffa;
  font-size: 1rem;
  font-weight: 500;
`;

const ListItem = styled("li")`
  list-style: none;
  margin: 1.5rem 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 101.83%;
  &:hover {
    color: ${props => props.disabled ? "none": "#fd9e46"};
  }
  color: ${props => props.disabled ? "#BDBDBD": "#000000"};
`;

const HeaderContainer = styled("div")`
  padding: 0.4em;
  flex: 2 0 300px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  margin-bottom: 0.8em;
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
`;

const Button = styled("button")`
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  padding: 0;
  background-color: #fff;
`;

const CrossButton = styled(Button)`
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
`;

const Img = styled("img")`
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
`;

const ArrowButton = styled(Button)`
  margin-right: 0.4em;
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
`;

const TextSlideImg = styled("img")`
  margin-left: 1.2em;
  padding-top: 1px;
`;

export {
  DropDownContainer,
  DropDownHead,
  DropDownList,
  DropDownListContainer,
  HeaderContainer,
  CrossButton,
  ArrowButton,
  ListItem,
  Img,
  TextSlideImg,
  Label,
};
