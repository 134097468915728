import React from 'react';
import { VictoryChart, VictoryScatter, VictoryTooltip } from 'victory';
import { useDispatch, useSelector } from 'react-redux';
import {
  setClickedChartElementResponseIds,
  setCommentsPanelVisibility,
  setPanelComments,
  setSelectedParticipantResponseId,
  setSelectedPointKey,
} from 'pages/presentationSlice';
import Plotter22Axis from './components/Axes/Axis';
import AveragePoint from './components/AveragePoint';
import { DefaultTooltip, InnerText } from '../common/Tooltips';
import { chartMouseEvents } from '../../../lib/Events';
import { colors } from '../../../const/theme';
import HTMLFlyOut from '../common/Tooltips/HTMLFlyOut';
import { isThereData } from '../../../lib/Presentation';
import NoData from '../common/NoData';
import { HILOS } from 'const/index';

const CHART_HEIGHT = window.innerWidth < 834 ? 320 : 450;
const CHART_WIDTH = window.innerWidth < 834 ? 320 : 710;

function isBubbleClicked(bubble, clickSelectedPoint) {
  return (
    bubble.datum.x === clickSelectedPoint?.x &&
    bubble.datum.y === clickSelectedPoint?.y
  );
}

const Plotter22 = ({
  mode,
  comparisonSection,
  moduleId,
  onCollapseXAxisPlotter22,
  onCollapseYAxisPlotter22,
}) => {
  const dispatch = useDispatch();

  const data = useSelector((state) => state.presentation[moduleId]);
  const comments = useSelector((state) => state.presentation.comments);

  const clickSelectedPoint = data.selectedPoint;

  const selectedParticipantResponseId = useSelector((state) => {
    if (comparisonSection && mode === HILOS.WORKSHOP) {
      return state.workshop.comparison[comparisonSection]
        .selectedParticipantResponseId;
    } else {
      return state.presentation.selectedParticipantResponseId;
    }
  });

  const selectedParticipantData = Object.values(
    data.participantsData || {}
  ).find((a) => a.id === selectedParticipantResponseId);

  const hasData = isThereData(data.chartData);

  const onClickBubble = (datum) => {
    const responseIds = datum.users;
    const { x, y } = datum;
    // Set this to null so that the highlight on View selection
    // In the comment section does not conflict with the bar click.
    dispatch(setSelectedParticipantResponseId(null));
    dispatch(setSelectedPointKey({ moduleId, selectedPoint: { x, y } }));
    dispatch(setClickedChartElementResponseIds(responseIds));
    dispatch(setCommentsPanelVisibility(true));
    dispatch(setPanelComments(comments));
  };

  return (
    <>
      {hasData ? (
        <VictoryChart
          domain={{ x: [-100, 100], y: [-100, 100] }}
          padding={{ top: 30, bottom: 0, left: 40, right: 40 }}
          height={CHART_HEIGHT}
          width={CHART_WIDTH}
        >
          {Plotter22Axis({
            chartWidth: CHART_WIDTH,
            chartHeight: CHART_HEIGHT,
            axisLabels: data.axisLabels,
            onCollapseXAxisPlotter22,
            onCollapseYAxisPlotter22,
            moduleId: moduleId,
            plotter22LabelValues: data.labelValues,
            participants: data.numberOfParticipantsWithResponses,
          })}
          <VictoryScatter
            events={[chartMouseEvents(false, undefined, onClickBubble)]}
            data={data.chartData}
            animate={{
              onLoad: {
                duration: 2000,
                before: () => ({ opacity: 0 }),
                after: () => ({ opacity: 1 }),
              },
            }}
            size={6}
            minBubbleSize={5}
            maxBubbleSize={10}
            bubbleProperty="frequency"
            labels={() => ''}
            labelComponent={
              <VictoryTooltip
                flyoutComponent={
                  <HTMLFlyOut
                    chartRightPadding={0}
                    isPlotter22
                    flyOutLabelType=""
                    fontSize={14}
                    chartWidth={CHART_WIDTH}
                    render={(datum, flyOutLabel, numberOfMembers, members) => (
                      <InnerText
                        datum={datum}
                        flyOutLabelType={flyOutLabel}
                        numberOfMembers={numberOfMembers}
                        members={members}
                      />
                    )}
                  />
                }
              />
            }
            style={{
              data: {
                fill: (bubble) => {
                  if (isBubbleClicked(bubble, clickSelectedPoint)) {
                    return colors.SELECTED_CHART_FILL_COLOR;
                  }
                  return colors.PRIMARY_CHART_FILL_COLOR;
                },
                opacity: ({ datum }) => datum.opacity,
                stroke: colors.STROKE_CHART_COLOR,
                strokeWidth: 1,
              },
            }}
          />

          {selectedParticipantData && (
            <VictoryScatter
              events={[
                chartMouseEvents(false, undefined, () => {
                  dispatch(setSelectedParticipantResponseId(null));
                }),
              ]}
              labels={({ datum }) => `Name: ${datum.name}`}
              labelComponent={<DefaultTooltip fontSize={14} isPlotter22 />}
              size={7}
              style={{
                data: {
                  fill: colors.SELECTED_CHART_FILL_COLOR,
                  stroke: colors.STROKE_CHART_COLOR,
                  strokeWidth: 1,
                },
              }}
              data={selectedParticipantData.data}
            />
          )}
          {data.averagePoint && (
            <VictoryScatter
              size={10}
              style={{
                data: {
                  fill: colors.SELECTED_CHART_FILL_COLOR,
                  stroke: colors.STROKE_CHART_COLOR,
                  strokeWidth: 1,
                },
              }}
              data={data.averagePoint}
              dataComponent={<AveragePoint />}
            />
          )}
        </VictoryChart>
      ) : (
        <NoData />
      )}
    </>
  );
};

export default Plotter22;
