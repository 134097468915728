import React, { useEffect, useRef, useState } from 'react';
import {
  ViewSelection,
  CollapsibleButton,
  CommentBox,
  CommentTitle,
  CommentValue as CommentValueStyle,
  Panel,
  RightAlign,
  Row,
  UserCard,
  UserName,
  CommentsContainer,
  NotSelected,
  CommentPoint,
} from './styles';

import { ReactComponent as PlusIcon } from 'img/PlusIcon.svg';
import { ReactComponent as MinusIcon } from 'img/MinusIcon.svg';
import { ReactComponent as CancelCross } from 'img/CancelCross.svg';
import { ReactComponent as CommentRefreshDisabled } from 'img/RefreshDisabled.svg';
import { ReactComponent as CommentRefreshEnabled } from 'img/RefreshEnabled.svg';
import { ReactComponent as NewComment } from 'img/NewComment.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  setClickedChartElementOptionKey,
  setClickedChartElementResponseIds,
  setSelectedParticipantResponseId,
  setSpectrumSelectedPoint,
  setStackRankSelectedPoint,
  setCommentsPanelVisibility,
  setSelectedPointKey,
  setInitialComments,
  setStackRankChartData,
} from 'pages/presentationSlice';

import { isHighlighted } from 'lib/utils';
import {
  MULTI_SELECT,
  POINT_DISTRIBUTION,
  STACK_RANK,
} from 'const/module-types';
import Tracking from 'lib/tracking/tracking';
import { presentationEvents } from 'lib/tracking/events';
import Tooltip from '@reach/tooltip';
import { Badge } from '../Badge';
import { getNewCommentBadgeCount } from 'components/utils/helpers';
import { isEmpty } from 'lodash';

const isCollapseVisible = ({ moduleType }) => {
  const collapsibleModules = [MULTI_SELECT, POINT_DISTRIBUTION];
  return collapsibleModules.includes(moduleType);
};

export const CommentsPanel = ({ activeQuestion }) => {
  const dispatch = useDispatch();
  const [commentsRefreshed, setCommentsRefreshed] = useState(false);

  useEffect(() => {
    if (commentsRefreshed) {
      sortedPanelComments();
    }
  }, [commentsRefreshed]);

  /**
   * This is the original comments array that is fetched from firebase
   * it is the central repository of comments and it holds both the new comments and
   * the old comments.
   * N.B this is not the comments array that is displayed in the panel.
   */
  const comments = useSelector((state) => state.presentation.comments);

  /**
   * This is the array that holds the comments that were available on page load
   * It does not get updated until the panel is refreshed or closed
   * It is used in conjunction with panelComments to determine which comments are new
   */
  const initialComments = useSelector(
    (state) => state.presentation.initialComments
  );

  /**
   * These are the comments that is displayed in the panel on refresh
   * THis had to be done in order to be able to identify the new comments when
   * i. the panel is opened because a new comment is added (there is a badge number).
   * ii. when the refresh button is clicked in the panel.
   */
  const panelComments = useSelector(
    (state) => state.presentation.panelComments
  );

  const selectedParticipantResponseId = useSelector(
    (state) => state.presentation.selectedParticipantResponseId
  );

  const selectedResponseIds = useSelector(
    (state) => state.presentation.clickedChartElementResponseIds
  );

  const isCommentPanelVisible = useSelector(
    (state) => state.presentation.isCommentsPanelVisible
  );

  const toggleViewSelection = (responseId) => {
    // De-highlight the user names when we click on
    // view selection since they might be selected
    // if the user clicked an item.
    const moduleId = activeQuestion.moduleId;
    Tracking.event({ name: presentationEvents.TOGGLE_COMMENT_VIEW_SELECTION });
    dispatch(setClickedChartElementResponseIds([]));
    dispatch(setClickedChartElementOptionKey(null));
    if (activeQuestion.moduleType === POINT_DISTRIBUTION) {
      dispatch(
        setSelectedPointKey({
          moduleId,
          selectedPoint: null,
        })
      );
    }
    dispatch(
      setSpectrumSelectedPoint({
        moduleId,
        selectedDataPoint: null,
      })
    );
    dispatch(
      setStackRankSelectedPoint({
        moduleId,
        selectedDataPoint: null,
      })
    );
    if (
      responseId === selectedParticipantResponseId ||
      isHighlighted({ responseId, responseIds: selectedResponseIds })
    ) {
      dispatch(setSelectedParticipantResponseId(null));
      if (activeQuestion.moduleType === STACK_RANK) {
        dispatch(
          setStackRankChartData({ moduleId, showIndividualView: false })
        );
      }
    } else {
      dispatch(setSelectedParticipantResponseId(responseId));
      if (activeQuestion.moduleType === STACK_RANK) {
        dispatch(setStackRankChartData({ moduleId, showIndividualView: true }));
      }
    }
  };

  const closePanel = () => {
    dispatch(setCommentsPanelVisibility(false));
    dispatch(setSelectedParticipantResponseId(null));
    dispatch(setInitialComments(comments));
    if (activeQuestion.moduleType === POINT_DISTRIBUTION) {
      // De-highlight the bar/bubble when the comment section is closed.
      dispatch(
        setSelectedPointKey({
          moduleId: activeQuestion.moduleId,
        })
      );
    }
    if (
      activeQuestion.moduleType === STACK_RANK ||
      activeQuestion.moduleType === POINT_DISTRIBUTION
    ) {
      // De-highlight line bubble bubble
      dispatch(
        setStackRankSelectedPoint({
          moduleId: activeQuestion.moduleId,
          selectedDataPoint: null,
        })
      );
    }
  };

  const refreshComments = () => {
    setCommentsRefreshed(true);
  };

  const sortedPanelComments = () => {
    if (!isEmpty(panelComments) && !commentsRefreshed) {
      return [...panelComments].sort((a, b) => b.time - a.time);
    } else if (!isEmpty(comments) && commentsRefreshed) {
      return [...comments].sort((a, b) => b.time - a.time);
    }
    return [];
  };

  const badgeNumber = commentsRefreshed
    ? 0
    : getNewCommentBadgeCount(comments, panelComments);

  return (
    <Panel>
      <Row>
        &nbsp;
        <RightAlign
          style={{
            position: 'relative',
            marginTop: '-15px',
            marginRight: '-10px',
            marginBottom: '5px',
            color: '#1CA4FC',
            cursor: 'pointer',
          }}
        >
          {badgeNumber > 0 && isCommentPanelVisible ? (
            <>
              <CommentRefreshEnabled
                style={{ marginRight: 15 }}
                onClick={refreshComments}
              />
              <Badge value={badgeNumber} />
            </>
          ) : (
            <Tooltip label="No new input">
              <CommentRefreshDisabled style={{ marginRight: 5 }} />
            </Tooltip>
          )}
          <CancelCross onClick={closePanel} />
        </RightAlign>
      </Row>
      {sortedPanelComments() &&
        sortedPanelComments().map((comment, index) => (
          <Comment
            key={`${comment.responseId} ${index}`}
            onClickViewSelection={() => toggleViewSelection(comment.responseId)}
            comment={comment}
            moduleType={activeQuestion?.moduleType}
            initialComments={initialComments}
            panelComments={panelComments}
            refreshClicked={commentsRefreshed}
          />
        ))}
    </Panel>
  );
};

const Comment = ({
  comment,
  onClickViewSelection,
  moduleType,
  initialComments,
  panelComments,
  refreshClicked,
}) => {
  const ref = useRef(null);

  const [toggleCollapse, setToggleCollapse] = useState(false);

  const initialResponseTimes = initialComments?.map((comment) => comment.time);

  const panelResponseTimes = panelComments?.map((comment) => comment.time);

  const isNewComment = refreshClicked
    ? !panelResponseTimes.includes(comment.time)
    : !initialResponseTimes.includes(comment.time);

  const selectedResponseIds = useSelector(
    (state) => state.presentation.clickedChartElementResponseIds
  );

  const selectedParticipantResponseId = useSelector(
    (state) => state.presentation.selectedParticipantResponseId
  );

  useEffect(() => {
    if (
      ref &&
      ref.current &&
      comment.responseId === selectedParticipantResponseId
    ) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [comment.responseId, selectedParticipantResponseId]);

  const showOverallComment =
    moduleType === POINT_DISTRIBUTION && !toggleCollapse;

  return (
    <div ref={ref}>
      <UserCard>
        <Row>
          <UserName
            highlighted={
              selectedParticipantResponseId === comment.responseId ||
              isHighlighted({
                responseId: comment.responseId,
                responseIds: selectedResponseIds,
              })
            }
          >
            {isNewComment && (
              <NewComment
                style={{ marginRight: 5, height: 8, color: '#ff7086' }}
              />
            )}
            {comment.name}
          </UserName>
          {isCollapseVisible({ moduleType }) && (
            <CollapsibleButton
              onClick={() => setToggleCollapse(!toggleCollapse)}
            >
              {toggleCollapse ? <MinusIcon /> : <PlusIcon />}
            </CollapsibleButton>
          )}
          <RightAlign>
            <ViewSelection
              onClick={onClickViewSelection}
              selected={
                selectedParticipantResponseId === comment.responseId ||
                isHighlighted({
                  responseId: comment.responseId,
                  responseIds: selectedResponseIds,
                })
              }
            >
              View Selections
            </ViewSelection>
          </RightAlign>
        </Row>
      </UserCard>
      {showOverallComment && (
        <OverallComment moduleType={moduleType} option={comment.data?.[0]} />
      )}
      <CollapsibleComment
        comment={comment}
        moduleType={moduleType}
        toggleCollapse={toggleCollapse}
      />
      <NonCollapsibleComment comment={comment} moduleType={moduleType} />
      <hr
        style={{
          marginTop: '20px',
          border: '1px',
          width: '100%',
          borderColor: '#000000',
          borderStyle: 'solid',
        }}
      />
    </div>
  );
};

const OverallComment = ({ option }) => {
  if (!option) return null;
  return (
    <div css={{ marginTop: '20px' }}>
      <CommentTitle css={{ marginBottom: '1rem' }}>
        <span>{option.title}</span>
        <CommentPoint css={{ background: '#F1F2F5' }}>
          {option.points}
        </CommentPoint>
      </CommentTitle>
      <CommentValue value={option.comment} />
    </div>
  );
};

const CommentValue = ({ value }) => {
  return (
    <CommentValueStyle css={{ fontStyle: !!value ? 'normal' : 'italic' }}>
      {!!value?.trim() ? value : 'No comment'}
    </CommentValueStyle>
  );
};

const CollapsibleComment = ({ comment, toggleCollapse, moduleType }) => {
  if (!isCollapseVisible({ moduleType })) {
    return null;
  }

  return (
    toggleCollapse && (
      <CommentsContainer>
        {comment.data &&
          comment.data.map((option, index) => (
            <div key={`${option.key}${index}`}>
              <Row style={{ marginTop: '20px' }}>
                <CommentTitle>
                  <span>{option.title}</span>
                  {option.hasOwnProperty('points') && (
                    <CommentPoint>{option.points}</CommentPoint>
                  )}
                  {option.hasOwnProperty('selected') && !option.selected && (
                    <NotSelected>(not selected)</NotSelected>
                  )}
                </CommentTitle>
              </Row>
              <Row>
                <CommentBox>
                  <CommentValue value={option.comment} />
                </CommentBox>
              </Row>
            </div>
          ))}
      </CommentsContainer>
    )
  );
};

const NonCollapsibleComment = ({ comment, moduleType }) => {
  if (isCollapseVisible({ moduleType })) return null;

  return (
    <Row>
      <CommentBox>
        <CommentValue
          value={comment ? comment.explanation || comment.comment : ''}
        />
      </CommentBox>
    </Row>
  );
};
