import React, { useState } from 'react';
import styled from 'styled-components';
import { SECTIONS_PREFIX } from '../../../const/module-types';
import {
  getMadlibOptionsHash,
  getMadlibSections,
  getOptionCount,
  getOptionsArray,
  getOptionsViewData,
} from '../../../lib/Presentation/Madlib';
import MadlibOptionsView from './MadlibOptionsView';
import { useSelector } from 'react-redux';
import { HILOS } from 'const/index';

const MadlibContainer = styled('div')`
  position: relative;
  font-size: 20px;
  font-family: Roboto, sans-serif;
  line-height: 35px;
  margin: 0 auto;
`;

const MadlibOptionText = styled('span')`
  cursor: pointer;
  color: ${(props) => (props.active ? '#FD9E46' : '#0073BF')};
  font-weight: bold;
  border-bottom: 1px solid ${(props) => (props.active ? '#FD9E46' : '#0073BF')};
  transition: color 100ms;
  &.hovered {
    color: #eb99ff;
    border-bottom: 1px solid #eb99ff;
  }
`;

const OtherText = styled('span')`
  color: #3faffa;
`;
/**
 * Check if there is a selected participant in the left panel with data.
 * If yes, change the color for the text for the selected user.
 * @param selectedParticipant
 * @returns {*}
 */
const isActiveParticipant = (selectedParticipant) =>
  selectedParticipant && selectedParticipant.data;

const getMadlibText = (
  questionData,
  moduleId,
  userResponse,
  selectedParticipant = null
) => {
  const [activeOption, setActiveOption] = useState(null);
  const [hovered, setHovered] = useState(null);
  const [modalSortedOptions, setModalSortedOptions] = useState([]);
  const [isOptionSorted, setIsOptionSorted] = useState(false);

  /**
   * Click listener for the option chosen by the survey participant.
   * @param e
   */
  const onClickOption = (e) => {
    const intKey = parseInt(e.target.dataset.key, 10);
    setActiveOption(intKey);
    setIsOptionSorted(false);
    setModalSortedOptions([]);
  };

  /**
   * Click listener for the options model for an option chosen
   * by a survey participant.
   * @param e
   * @param active Used to show which modal is active and then
   * the active state variable to false and hide the options
   * modal when the close icon is clicked on the modal.
   */
  const onClickMadlibOptionsView = (e, active) => {
    e.stopPropagation();
    if (active) setActiveOption(null);
  };

  /**
   * Used to show the hover state for the option chosen by the
   * survey participant and ensure that the modal does not
   * interfere with the hover state.
   * @param e
   */
  const onMadlibOptionTextMouseEnter = (e) => {
    e.stopPropagation();
    const intKey = parseInt(e.target.dataset.key, 10);
    setHovered(intKey);
  };

  /**
   * Remove the hover state on the option chosen by the
   * survey participant
   * @param e
   */
  const onMadlibOptionTextMouseLeave = (e) => {
    e.stopPropagation();
    setHovered(null);
  };

  /**
   * Stop propagation for the options modal
   * @param e
   */
  const onMadlibOptionsViewMouseEnter = (e) => {
    e.stopPropagation();
  };

  /**
   * Stop propagation for the options modal
   * @param e
   */
  const onMadlibOptionsViewMouseLeave = (e) => {
    e.stopPropagation();
  };

  /**
   * Sort the options data for the data in an asc order so that it
   * can be shown in the modal
   * @param options
   */
  const onSortModalOptionsAsc = (options) => {
    if (!options) return;
    const sort = (a, b) => a.count - b.count;
    setModalSortedOptions([...options.sort(sort)]);
    setIsOptionSorted(true);
  };

  /**
   * Sort the options data for the data in an desc order so that it
   * can be shown in the modal
   * @param options
   */
  const onSortModalOptionsDesc = (options) => {
    if (!options) return;
    const sort = (a, b) => b.count - a.count;
    setModalSortedOptions([...options.sort(sort)]);
    setIsOptionSorted(true);
  };

  const sections = getMadlibSections({ questionData });
  return sections.map(([key, data], i) => {
    const intKey = parseInt(key.replace(SECTIONS_PREFIX, ''), 10);
    let optionValue;
    if (selectedParticipant && selectedParticipant.data) {
      optionValue = selectedParticipant.data[intKey];
    } else {
      optionValue = getMadlibOptionsHash(questionData)[intKey];
    }
    if (!optionValue) {
      return (
        <OtherText key={key}>
          {data === '\n' ? <br key={`madlib${i}`} /> : data}
        </OtherText>
      );
    }
    let optionText;
    let madlibOptionsView = null;
    if (selectedParticipant && selectedParticipant.data) {
      optionText = optionValue;
    } else {
      const optionsArray = getOptionsArray(optionValue); //to get the dropdown
      optionText = getOptionCount(intKey, moduleId, questionData, userResponse)
        .key;
      const optionsViewData = getOptionsViewData(
        intKey,
        moduleId,
        questionData,
        userResponse,
        optionsArray
      );
      madlibOptionsView = (
        <MadlibOptionsView
          key={`madlibView${intKey}`}
          options={isOptionSorted ? modalSortedOptions : optionsViewData}
          active={activeOption === intKey}
          onClick={onClickMadlibOptionsView}
          onSortAsc={onSortModalOptionsAsc}
          onSortDesc={onSortModalOptionsDesc}
          onMouseOver={onMadlibOptionsViewMouseEnter}
          onMouseLeave={onMadlibOptionsViewMouseLeave}
        />
      );
    }
    return (
      <MadlibOptionText
        key={`madlibOption${intKey}`}
        data-key={intKey}
        active={
          activeOption === intKey || isActiveParticipant(selectedParticipant)
        }
        onClick={onClickOption}
        onMouseOver={onMadlibOptionTextMouseEnter}
        onMouseLeave={onMadlibOptionTextMouseLeave}
        className={hovered === intKey ? 'hovered' : ''}
      >
        {optionText || data}
        {madlibOptionsView}
      </MadlibOptionText>
    );
  });
};

const Madlib = ({ moduleId, mode, comparisonSection }) => {
  const { userResponses, questionData, participantsData } = useSelector(
    (state) => state.presentation[moduleId]
  );

  const selectedParticipantResponseId = useSelector((state) => {
    if (comparisonSection && mode === HILOS.WORKSHOP) {
      return state.workshop.comparison[comparisonSection]
        .selectedParticipantResponseId;
    } else {
      return state.presentation.selectedParticipantResponseId;
    }
  });

  const selectedParticipantData =
    participantsData?.[selectedParticipantResponseId];

  return (
    <MadlibContainer>
      {getMadlibText(
        questionData,
        moduleId,
        userResponses,
        selectedParticipantData
      )}
    </MadlibContainer>
  );
};

export default Madlib;
