import React, { useState } from 'react';
import { LineSegment, VictoryAxis } from 'victory';
import { useDispatch, useSelector } from 'react-redux';
import Line from '../Line';
import Plotter22Labels from '../Labels/Labels';
import { axisStyle } from './styles';
import {
  setPlotter22XAxisCollapsed,
  setPlotter22YAxisCollapsed,
} from 'pages/presentationSlice';

const getYAxis = ({ isHidden }) =>
  isHidden
    ? []
    : [
        <VictoryAxis
          dependentAxis
          style={axisStyle}
          tickFormat={() => ''}
          key="yAxis22"
          axisComponent={
            <LineSegment lineComponent={<Line isY />} type="axis" />
          }
        />,
      ];

const getXAxis = ({ isHidden }) =>
  isHidden
    ? []
    : [
        <VictoryAxis
          style={axisStyle}
          tickFormat={() => ''}
          key="xAxis22"
          axisComponent={
            <LineSegment lineComponent={<Line isX />} type="axis" />
          }
        />,
      ];

const Plotter22Axis = ({
  chartWidth,
  chartHeight,
  axisLabels,
  moduleId,
  onCollapseXAxisPlotter22,
  onCollapseYAxisPlotter22,
  plotter22LabelValues,
  participants,
}) => {
  const dispatch = useDispatch();
  const xAxisCollapsed = useSelector(
    (state) => state.presentation.xAxisCollapsed
  );
  const yAxisCollapsed = useSelector(
    (state) => state.presentation.yAxisCollapsed
  );

  const [xAxisToggle, setXAxisToggle] = useState(false);
  const [yAxisToggle, setYAxisToggle] = useState(false);

  const onClickCollapseXAxis = () => {
    dispatch(setPlotter22XAxisCollapsed(!xAxisCollapsed));
    setXAxisToggle(!xAxisToggle);
    onCollapseXAxisPlotter22(!xAxisCollapsed, moduleId);
  };

  const onClickCollapseYAxis = () => {
    dispatch(setPlotter22YAxisCollapsed(!yAxisCollapsed));
    setYAxisToggle(!yAxisToggle);
    onCollapseYAxisPlotter22(!yAxisCollapsed, moduleId);
  };

  return [
    ...getYAxis({ isHidden: xAxisToggle }),
    ...getXAxis({ isHidden: yAxisToggle }),
    ...Plotter22Labels({
      axisLabels,
      chartWidth,
      chartHeight,
      onClickCollapseXAxis,
      xAxisToggle,
      onClickCollapseYAxis,
      yAxisToggle,
      plotter22LabelValues,
      participants,
    }),
  ];
};

export default Plotter22Axis;
