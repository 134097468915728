import React from 'react';
import {
  Menu,
  MenuButton,
  MenuPopover,
  MenuItems,
  MenuItem,
} from '@reach/menu-button';
import { ReactComponent as CaretUp } from '../img/caret-up-white.svg';
import { ReactComponent as CaretDown } from '../img/caret-down-white.svg';

const styles = {
  menuBtn: {
    background: '#F1F2F5',
    borderRadius: '10px',
    width: '250px',
    textAlign: 'left',
    border: 0,
    padding: '12px 20px',
    outline: 0,
    color: '#1CA4FC',
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '20px',
    display: 'flex',
  },
  menuBtnName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: '4px',
  },
  itemsContainer: {
    marginTop: '10px',
    background: '#FFF',
    width: '250px',
    borderRadius: '10px',
    boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.10)',
    padding: '5px 0',
    overflowY: 'auto',
    border: '0px',
    outline: 0,
    maxHeight: '18.75rem',
  },
  menuItem: {
    width: '100%',
    display: 'block',
    color: '#1CA4FC',
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '20px',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&[data-selected]': {
      color: '#FF9736',
      background: 'transparent',
    },
    '&[aria-disabled]': {
      cursor: 'initial',
    },
  },
  caretContainer: {
    stroke: '#868C97',
    marginLeft: 'auto',
  },
  popover: {
    zIndex: 100,
  },
};

export default function SelectMenu({
  items,
  selectedItem,
  onSelectItem,
  itemRender,
  itemSelectionDisabled,
}) {
  return (
    <Menu>
      {({ isExpanded }) => {
        return (
          <>
            <MenuButton css={{ '&[data-reach-menu-button]': styles.menuBtn }}>
              <span css={styles.menuBtnName}>
                {itemRender({ item: selectedItem })}
              </span>
              <span css={styles.caretContainer}>
                {isExpanded ? <CaretUp /> : <CaretDown />}
              </span>
            </MenuButton>
            <MenuPopover css={{ '&[data-reach-menu-popover]': styles.popover }}>
              <MenuItems
                css={{ '&[data-reach-menu-items]': styles.itemsContainer }}
              >
                {items.map((item, index) => {
                  return (
                    <MenuItem
                      key={item.moduleId}
                      disabled={itemSelectionDisabled?.({ item, index })}
                      onSelect={() => onSelectItem(item)}
                      css={{ '&[data-reach-menu-item]': styles.menuItem }}
                    >
                      {itemRender({ item, index })}
                    </MenuItem>
                  );
                })}
              </MenuItems>
            </MenuPopover>
          </>
        );
      }}
    </Menu>
  );
}
