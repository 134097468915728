import React from "react";
import styled from "styled-components";
import { DialogOverlay } from "@reach/dialog";

export const Container = styled("div")`
  width: ${props => props.width || "20px"};
  height: ${props => props.height || "32px"};
`;

export const Error = styled("div")`
  color: #ff5a36;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
`;

export const SuccessContainer = styled("div")`
  font-family: Roboto, sans-serif;
  font-style: normal;
  color: #1ca4fc;
  margin-top: 38px;
  margin-left: 123px;
  margin-right: 88px;
`;

export const SuccessHeader = styled("div")`
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;
  letter-spacing: 0.4px;
  margin-bottom: 20px;
`;

export const SuccessMessage = styled("div")`
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.4px;
`;

export const TemplateDialog = styled(DialogOverlay)`
  overflow-y: hidden !important;
  [data-reach-dialog-overlay] {
    opacity: 0.3;
  }
  > [data-reach-dialog-content] {
    width: 550px;
    height: 350px;
    margin: 32vh 38vw;
    padding: 0;
    border: 4px solid #1ca4fc;
    border-radius: 10px;
    filter: drop-shadow(0px 0px 15.1521px rgba(0, 0, 0, 0.3));
  }
`;

export const ContentContainer = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const TemplateName = styled("input")`
  outline: none;
  border: none;
  padding-bottom: 10px;
  padding-left: 15px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  &::placeholder {
    color: #c4c4c4;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: bold;
  }
`;

export const HorizontalRule = styled("div")`
  border-bottom: ${props =>
    props.isError ? "2px solid#F98870" : "1px solid #DFE0EB"};
  margin-bottom: 20px;
`;

export const Description = styled("textarea")`
  padding-bottom: 10px;
  padding-left: 15px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  border: none;
  outline: none;
  &::placeholder {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    color: #c4c4c4;
  }
`;

export const Text = styled("p")`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #1ca4fc;
  padding-bottom: 10px;
  padding-left: 15px;
`;

export const Right = styled("div")`
  display: flex;
  margin-right: 25px;
  padding-left: 15px;
  justify-content: ${props => (props.isError ? "space-between" : "flex-end")};
  cursor: pointer;
`;

export const ConfirmButton = styled("button")`
  border: ${props => (props.isLoading ? "none" : "1px solid #1890ff")};
  box-sizing: border-box;
  border-radius: 2px;
  color: ${props => (props.isLoading ? "#FFFFFF" : "#1ca4fc")};
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  width: 68px;
  outline: none;
  background: ${props => (props.isLoading ? "#1890FF" : "transparent")};
`;
