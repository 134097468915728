import React, { useEffect, useState } from 'react';
import Checkbox from 'react-custom-checkbox';
import { useParams, useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonBase } from 'components/buttons';
import { AddOptionDialog } from '../AddOptionDialog/AddOptionDialog';
import { EditOptionDialog } from '../EditOptionDialog/EditOptionDialog';
import {
  MULTI_SELECT,
  OPTION_PREFIX,
  STACK_RANK,
  ADD_OPTION,
  POINT_DISTRIBUTION,
  POPULATABLE_MODULES,
} from 'const/module-types';
import ids from 'short-id';
import { ReactComponent as IconCheck } from 'img/ic_check.svg';
import { ReactComponent as SortAsc } from 'img/sort_asc.svg';
import { ReactComponent as SortDsc } from 'img/sort_dsc.svg';
import { styles, AddOption, AddIcon, OptionFull, EditOption } from './styles';
import Tooltip from '@reach/tooltip';

import { setPopulatePanelVisibility } from 'pages/presentationSlice';
import { updatePopulateModule, updatePopulateOption } from 'lib/modules-api';
import { updateSurveyResponse } from 'lib/survey-api';
import Tracking from 'lib/tracking/tracking';
import { workshopEvents } from 'lib/tracking/events';

const getFormattedPopulateOptions = (module, formattedUserResponses) => {
  if (!POPULATABLE_MODULES.includes(module?.moduleType)) {
    return [];
  }
  const moduleOptions = Object.entries(module.questionData ?? {})
    .filter(([key]) => key.startsWith(OPTION_PREFIX)); // prettier-ignore
  const map = {
    [MULTI_SELECT]: () =>
      moduleOptions
        .filter(([, value]) => !value.removed)
        .map(([key, value]) => ({
          id: key,
          name: value.title,
        })),
    [STACK_RANK]: () =>
      moduleOptions.map(([key, value]) => ({
        id: key,
        name: value,
      })),
    [POINT_DISTRIBUTION]: () =>
      moduleOptions.map(([key, value]) => ({
        id: key,
        name: value,
      })),
    [ADD_OPTION]: () => {
      // we use key for this module, since we can have items with same id
      // since options also come from the user
      const userOptions = formattedUserResponses?.chartData || [];
      const facilitatorOptions = moduleOptions
        .filter((option) => option[1].isShown)
        .map(([key, value]) => ({
          id: key,
          name: value.title,
          author: 'facilitator',
          key,
          isShown: value.isShown,
        }));
      return [...userOptions, ...facilitatorOptions];
    },
  };
  return map[module?.moduleType]?.();
};

export function PopulatePanel({
  onSave,
  module,
  savedSelectedOptions,
  getDataAsync,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { workshopCode, slide } = useParams();
  const search = useLocation().search;
  const sort = new URLSearchParams(search).get('sort');
  const closePanel = () => {
    Tracking.event({ name: workshopEvents.CANCEL_OPTIONS });
    dispatch(setPopulatePanelVisibility(false));
  };
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [activeOption, setActiveOption] = useState(null);
  const [optionToEdit, setOptionToEdit] = useState('');
  const [options, setOptions] = useState(getFormattedPopulateOptions(module));
  const [asc, setAsc] = useState(true);
  const [loading, setLoading] = useState(false);

  const formattedUserResponses = useSelector(
    (state) => state.presentation[module.moduleId]
  );

  const { surveyId } = module;
  const responses = useSelector((state) => state.presentation.userResponses);

  useEffect(() => {
    const allOptions = getFormattedPopulateOptions(
      module,
      formattedUserResponses
    );
    setOptions(allOptions);
    if (sort === 'dsc') {
      setAsc(false);
    }
  }, [module, formattedUserResponses]);

  useEffect(() => {
    setLoading(false);
    onCloseDialog();
    onCloseEditModal();
  }, [getDataAsync.state.isLoading()]);

  const handleAddOptionClick = () => {
    Tracking.event({ name: workshopEvents.ADD_OPTIONS_CLICKED });
    setShowDialog(true);
    setActiveOption(null);
  };

  const handleEditClick = (option, index) => {
    Tracking.event({ name: workshopEvents.EDIT_OPTION_CLICKED });
    setActiveOption(`${option.id}_${index}`);
    setOptionToEdit(option);
    setShowDialog(false);
  };

  const onCloseDialog = () => setShowDialog(false);

  const onCloseEditModal = () => setActiveOption(null);

  const handleEditOption = async (option, updatedOption) => {
    const moduleType = module?.moduleType;
    const moduleId = module?.moduleId;
    const optionId = option.id;
    const responseId = option.responseId;
    const questionOptionDataKey = `questionData.${optionId}`;

    setLoading(true);

    if (moduleType === ADD_OPTION && option.author === 'user') {
      const newResponse = JSON.parse(
        JSON.stringify(responses[`${responseId}`])
      );

      newResponse.answerData[`${moduleId}`][optionId] = {
        value: updatedOption,
        selected: true,
      };
      await updateSurveyResponse({
        newResponse,
        responseId,
        surveyId,
      });
    } else {
      await updatePopulateOption({
        moduleId,
        moduleType,
        questionOptionDataKey,
        optionValue: updatedOption,
      });
    }

    setLoading(true);

    await getDataAsync.execute();
  };

  const handleAddOption = async (text) => {
    setLoading(true);
    const moduleType = module?.moduleType;
    const moduleId = module?.moduleId;

    const optionKey = `option${ids.generate()}`;
    const questionOptionDataKey = `questionData.${optionKey}`;

    if (moduleType === MULTI_SELECT) {
      await updatePopulateModule({
        moduleId,
        questionOptionDataKey,
        value: {
          key: optionKey,
          title: text,
          addedOption: true,
        },
      });
    }

    if (moduleType === STACK_RANK) {
      await updatePopulateModule({
        moduleId,
        questionOptionDataKey,
        value: text,
        optionKey,
        moduleType,
      });
    }

    if (moduleType === POINT_DISTRIBUTION) {
      await updatePopulateModule({
        moduleId,
        moduleType,
        questionOptionDataKey,
        value: text,
        optionKey,
      });
    }

    if (moduleType === ADD_OPTION) {
      await updatePopulateModule({
        moduleId,
        moduleType,
        questionOptionDataKey,
        value: {
          title: text,
          isShown: true,
        },
        optionKey,
      });
    }

    await getDataAsync.execute();
  };

  const handleSort = async () => {
    Tracking.event({ name: workshopEvents.SORT_OPTIONS_CLICKED });
    await setAsc(!asc);
    asc
      ? history.replace(
          `/workshop/${workshopCode}/${slide}?mode=presentation&sort=dsc`
        )
      : history.replace(
          `/workshop/${workshopCode}/${slide}?mode=presentation&sort=asc`
        );
  };

  const sortedOption = asc
    ? options?.sort((a, b) => a.name?.localeCompare(b.name))
    : options?.sort((a, b) => b.name?.localeCompare(a.name));

  return (
    <div
      css={styles.subContainer}
      // onClick={(event) => event.stopPropagation()}
    >
      <div css={{ marginBottom: '30px', position: 'relative' }}>
        <AddOption
          css={[styles.actionBtn, styles.addBtn]}
          onClick={handleAddOptionClick}
        >
          <AddIcon />
          Add options
        </AddOption>
        <ButtonBase
          onClick={handleSort}
          css={[styles.actionBtn, styles.sortBtn]}
        >
          {asc ? (
            <SortAsc css={[styles.sortIcon]} />
          ) : (
            <SortDsc css={[styles.sortIcon]} />
          )}
          Sort by alpha
        </ButtonBase>
        {showDialog && (
          <AddOptionDialog
            sending={loading || getDataAsync.state.isLoading()}
            close={onCloseDialog}
            handleSubmit={(newOption) => handleAddOption(newOption)}
          />
        )}
      </div>
      {sortedOption?.map((option, index) => {
        // we use key because of the Add option module, else we use id for all other modules.
        const handleOptionCheckChange = () => {
          if (selectedOptions?.includes?.(option.key || option.id)) {
            setSelectedOptions(selectedOptions.filter(optionId => optionId !== (option?.key || option?.id))); // prettier-ignore
          } else {
            setSelectedOptions([
              ...selectedOptions,
              `${option.key || option.id}`,
            ]);
          }
        };
        return (
          <OptionFull key={option.key || option.id}>
            <div>
              <div
                css={styles.optionContainer}
                onClick={handleOptionCheckChange}
              >
                <Checkbox
                  size={15}
                  borderColor="#1CA4FC"
                  borderWidth="1px"
                  borderRadius="2px"
                  containerStyle={{ margin: '0 8px 0 0', cursor: 'pointer' }}
                  icon={
                    <div css={styles.optionCheckboxChecked}>
                      <IconCheck />
                    </div>
                  }
                  checked={selectedOptions?.includes?.(option.key || option.id)}
                  onChange={handleOptionCheckChange}
                />
                <Tooltip label={option.name}>
                  <p css={styles.optionName}>{option.name}</p>
                </Tooltip>
              </div>
            </div>
            <EditOption
              style={{
                color: activeOption === `${option.id}_${index}` && '#ff9736',
              }}
              onClick={() => handleEditClick(option, index)}
            >
              Edit
            </EditOption>
            {activeOption === `${option.id}_${index}` && (
              <EditOptionDialog
                close={onCloseEditModal}
                sending={loading || getDataAsync.state.isLoading()}
                optionName={optionToEdit.name}
                handleSubmit={(updatedOption) =>
                  handleEditOption(optionToEdit, updatedOption)
                }
              />
            )}
          </OptionFull>
        );
      })}
      <div css={styles.btnsContainer}>
        <ButtonBase onClick={closePanel} css={styles.actionBtn}>
          Cancel
        </ButtonBase>
        <ButtonBase
          css={[styles.actionBtn, { marginLeft: '20px' }]}
          onClick={() => onSave(selectedOptions)}
        >
          Save
        </ButtonBase>
      </div>
    </div>
  );
}
