import { differenceBy, filter, isEmpty } from 'lodash';

/**
 * helper function to determine if a module has option{digit} as a key
 * option1, option2 .... optionN
 * to be used to check if populate is enabled.
 * @param keys an array of object keys.
 * @returns boolean
 */
import { MULTI_SELECT } from 'const/module-types';

export const isPopulatedOptions = (keys) => {
  const regex = /option/;
  return keys.some((key) => key.match(regex));
};

/**
 * If the populated from module is a multi select and it is
 * populating into a multi select. Show the facilitator
 * added options in the next Multi select module.
 * We need this function since by default we do not
 * show the added options in the original module,
 * but since we are populating multi select from
 * multi select then we need this function.
 * @param value Multi select option value
 * @param questionData Module question data
 * @returns {boolean}
 */
export const filterMultiSelectOptions = ({ value, questionData }) => {
  const populateOptions = questionData.populateOptions;
  const populateModule = questionData.populateModule;
  const isPopulatedFromMultiSelect =
    populateOptions &&
    populateModule &&
    populateModule.moduleType === MULTI_SELECT;

  if (isPopulatedFromMultiSelect) return !value.removed;
  return !value.removed && !value.addedOption;
};

/**
 * this function checks if the comment is not null or empty, and if it is not
 * then it returns the length of the comment that passes the test, otherwise it returns 0.
 * @returns {number}
 */
export const getNewCommentBadgeCount = (comments, comparisonComment) => {
  const newComments = differenceBy(comments, comparisonComment, 'time');
  const count = filter(
    newComments,
    (comment) =>
      !isEmpty(comment.comment) ||
      !isEmpty(comment.explanation) ||
      comment?.data
        ?.map((data) => data.comment)
        .some((value) => !isEmpty(value))
  );
  return count.length;
};

/**
 * this function breaks text in spectrum module presentation mode into array
 * in order to make sure that is not too long
 * @param text, text to be broken
 * @param size, max length of the text
 * @returns {Array}
 */
export const splitString = (str = '', size) => {
  const regex = new RegExp(String.raw`\S.{1,${size - 2}}\S(?= |$)`, 'g');
  const chunks = str.match(regex);
  return chunks;
};
