import { createSlice } from '@reduxjs/toolkit';
import { HILOS } from 'const/index';

export const hiloSlice = createSlice({
  name: 'hilo',
  initialState: {
    type: HILOS.SURVEY,
    activeModule: '', // same as module Id
    isLaunched: false,
    name: '', // name/title of hilo
    isParticipantMode: false, // triggered from survey.js
  },
  reducers: {
    setHiloType: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.type = action.payload;
    },
    setHiloActiveModule: (state, action) => {
      state.activeModule = action.payload;
    },
    setHiloLaunched: (state, action) => {
      state.isLaunched = action.payload
    },
    setHiloName: (state, action) => {
      state.name = action.payload
    },
    setIsParticipantMode: (state, action) => {
      state.isParticipantMode = action.payload;
    }
  },
})

// Action creators are generated for each case reducer function
export const { setHiloType, setHiloActiveModule, setHiloLaunched, setHiloName, setIsParticipantMode, } = hiloSlice.actions

export default hiloSlice.reducer
