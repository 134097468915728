import React from 'react';
import { ReactComponent as CaretUp } from '../../../img/caret-up-white.svg';
import { ReactComponent as CaretDown } from '../../../img/caret-down-white.svg';
import { ButtonBase } from '../../buttons';
import { COMPARISON } from '../../../const/module-types';
import { getNestedData } from '../../../const/nested-data';

const styles = {
  container: {
    position: 'relative',
    width: '100%',
    marginBottom: '2rem',
  },
  header: {
    fontWeight: 'normal',
    fontSize: '12px',
    color: '#BDBDBD',
  },
  button: {
    width: '100%',
    background: '#FFF',
    boxShadow: '0px 2px 3px rgba(0,0,0,0.15)',
    outline: 0,
    border: 0,
    padding: '12px 20px',
    textAlign: 'left',
    color: '#3FAFFA',
    fontSize: '20px',
    fontWeight: 500,
    display: 'flex',
    fontFamily: 'Roboto',
  },
  caretContainer: {
    stroke: '#868C97',
    alignSelf: 'center',
    marginLeft: 'auto',
  },
  moduleSelectContainer: {
    maxHeight: '18.75rem',
    background: '#FFF',
    marginTop: '1rem',
    position: 'absolute',
    top: '64px',
    width: '100%',
    zIndex: 100,
    boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.15)',
    overflowY: 'scroll',
    padding: '20px',
    paddingBottom: '0px',
  },
  moduleSelectBtn: {
    width: '100%',
    display: 'block',
    fontSize: '20px',
    fontWeight: 500,
    textAlign: 'left',
    marginBottom: '20px',
    color: '#1CA4FC',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontFamily: 'Roboto',
    '&:hover': {
      color: '#FF9736',
    },
  },
  positionSuggestionText: {
    marginBottom: '1rem',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 500,
    color: '#FF5A36',
  },
};

const SelectComparisonItem = ({
  position,
  items = [],
  isActive,
  onClickDropDownBtn,
  selectedModule,
  onSelectItem,
}) => {
  return (
    <div css={styles.container}>
      <p css={styles.header}>Result {position + 1}</p>
      <button onClick={onClickDropDownBtn} css={styles.button}>
        <span>
          {selectedModule
            ? selectedModule.question || 'Enter module name'
            : 'Select a question'}
        </span>
        <span css={styles.caretContainer}>
          {isActive ? <CaretUp /> : <CaretDown />}
        </span>
      </button>
      {isActive && (
        <div css={styles.moduleSelectContainer}>
          {items.map((item) => {
            return (
              <ButtonBase
                css={styles.moduleSelectBtn}
                onClick={() => onSelectItem(item.moduleId)}
                title={item.question || 'Enter module name'}
              >
                {item.question || (
                  <span css={{ fontStyle: 'italic' }}>Enter module name</span>
                )}
              </ButtonBase>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default function ComparisonModuleFields({
  formikProps,
  moduleId,
  modulesArray,
  order,
}) {
  const [activeItem, setActiveItem] = React.useState(null);

  const { values: data, setFieldValue } = formikProps;
  const module1Key = `moduleList.${moduleId}.questionData.module0`;
  const module2key = `moduleList.${moduleId}.questionData.module1`;

  const onClickItemDropDownBtn = (position) => {
    if (activeItem === position) return setActiveItem(null);
    setActiveItem(position);
  };

  const onSelectModule = (position, moduleId) => {
    const field = position === 0 ? module1Key : module2key;
    setFieldValue(field, moduleId);
    setActiveItem(null);
  };

  const items = (modulesArray || []).filter(
    (item) => item.moduleType !== COMPARISON
  );
  const module1Id = getNestedData(data, module1Key);
  const module2Id = getNestedData(data, module2key);

  return (
    <>
      {order === 1 && (
        <p css={styles.positionSuggestionText}>
          Tip: Place this module after the questions you want to compare for
          easier transitions while in Facilitation mode.
        </p>
      )}
      <SelectComparisonItem
        isActive={activeItem === 0}
        position={0}
        items={items}
        onClickDropDownBtn={() => onClickItemDropDownBtn(0)}
        selectedModule={items.find((item) => item.moduleId === module1Id)}
        onSelectItem={(moduleId) => onSelectModule(0, moduleId)}
      />
      <SelectComparisonItem
        isActive={activeItem === 1}
        position={1}
        items={items}
        onClickDropDownBtn={() => onClickItemDropDownBtn(1)}
        selectedModule={items.find((item) => item.moduleId === module2Id)}
        onSelectItem={(moduleId) => onSelectModule(1, moduleId)}
      />
    </>
  );
}
