import React, { useState } from 'react';
import { ButtonBase } from 'components/buttons';
import Loader from 'react-loader-spinner';
import { styles } from './styles';
import Tracking from 'lib/tracking/tracking';
import { workshopEvents } from 'lib/tracking/events';

export const AddOptionDialog = ({
  sending,
  close,
  handleSubmit,
  isWorkshop,
}) => {
  const [text, setText] = useState('');

  const handleChange = (e) => {
    e.preventDefault();
    setText(e.target.value);
  };

  const onClose = (e) => {
    e.preventDefault();
    close();
  };

  const onSubmit = (e) => {
    Tracking.event({ name: workshopEvents.ADD_BUTTON });
    e.preventDefault();
    if (text.trim()) {
      handleSubmit(text);
    } else {
      setText('');
    }
  };

  return (
    <div
      css={[
        styles.containerOverlay,
        { width: isWorkshop && '23%' },
        { right: isWorkshop && '3%' },
      ]}
      aria-label="Dialog with an input for adding new options"
    >
      <form css={styles.addForm}>
        <input
          css={styles.input}
          type="text"
          name="option"
          value={text}
          onChange={(e) => handleChange(e)}
        />
        <div css={styles.actions}>
          <ButtonBase onClick={onClose}>Cancel</ButtonBase>
          <ButtonBase
            onClick={onSubmit}
            disabled={(!text || sending) && true}
            css={styles.addBtn}
          >
            {sending ? (
              <Loader type="Oval" color="#1CA4FC" height={18} width={20} />
            ) : (
              'Add'
            )}
          </ButtonBase>
        </div>
      </form>
    </div>
  );
};
