import React from "react";
import styled from "styled-components";

const Points = styled("div")`
  padding-bottom: 3px;
`;

const Numbers = styled("div")`
  padding-bottom: 1px;
`;

const Members = styled("div")`
  display: flex;
  line-height: 1.5;
`;

const Member = styled("span")``;

const Name = styled("div")`
  padding-right: 2px;
`;

const InnerText = ({ datum, flyOutLabelType, numberOfMembers, members }) => (
  <>
    {flyOutLabelType && <Points>{`${flyOutLabelType}: ${datum.points}`}</Points>}
    <Numbers>{`Number of people: ${numberOfMembers}`}</Numbers>
    {numberOfMembers > 0 && (
      <Members>
        <Name>Name:</Name>
        <Member>{members}</Member>
      </Members>
    )}
  </>
);

export default InnerText;
