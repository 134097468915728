import React, { Component } from 'react';
import queryString from 'query-string';
import { RouterContext } from '../nav';
import withAuthorization from '../components/with-authorization';
import { fetchSurveyModulesAndResponses } from '../lib/survey-api';
import { sortModulesToArray, getLastSlide } from '../lib/builder-api';
import Layout, { PageContainer } from '../components/layout';
import styled from 'styled-components';
import { LANDING, COMPLETE, REVIEW, ADMIN } from '../const/routes';
import Plotter from '../components/survey-components/Plotter';
import {
  PLOTTER_XY,
  PLOTTER_22,
  STACK_RANK,
  POINT_DISTRIBUTION,
  TEXT_SLIDE,
  TEXT_HIGHLIGHT,
  SPECTRUM_DISTRIBUTION,
  MATRIX,
  PERSONAL_INFO,
  MADLIB,
} from '../const/module-types';
import PointDistribution from '../components/survey-components/PointDistribution';
import StackRank from '../components/survey-components/StackRank';
import { getNestedData } from '../const/nested-data';
import Matrix from '../components/survey-components/Matrix';
import PersonalInfo from '../components/survey-components/PersonalInfo';
import SpectrumDistribution from '../components/survey-components/SpectrumDistribution';
import TextHighlightSurvey from '../components/survey-components/TextHighlight';
import Madlib from '../components/survey-components/Madlib';
import SurveyBottom from '../components/SurveyBottom';
import SurveyTitle from '../components/SurveyTitle';
import Tracking from 'lib/tracking/tracking';
import { presentationEvents } from 'lib/tracking/events';
import { getSurveyCode } from 'pages/Survey/lib';
import {
  getSlide,
  LoadingFull,
  NoSurveyFull,
  PlotterContainer,
} from 'pages/Survey/styles';

const SectionContainer = styled('div')`
  @media (min-width: 1200px) {
    max-width: 19rem;
    margin: 0.5rem 0;
  }
`;

const FormContainer = styled('div')`
  @media (min-width: 1200px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 42rem;
    margin: 0 auto;
    padding: 0 1rem;
  }
`;

class FullPresentation extends Component {
  getResponseId() {
    const parsedQuery = queryString.parse(this.props.location.search);
    const { responseId } = parsedQuery;
    return responseId;
  }
  state = {
    moduleList: {},
    surveyDetails: {
      name: ' ',
    },
    surveyLoadError: false,
    loading: true,
    plotterData: {},
    stackRank: {},
    pointDistribution: {},
    textHighlight: {},
    spectrumDistribution: {},
    explanation: {},
    matrix: {},
    personalInfo: {},
    userResponses: {},
    madlib: {},
  };
  componentDidMount() {
    this.loadSurvey();
  }
  restoreAnswerData = ({ answerData }) => {
    // copy to all survey state holders since we don't currently store moduleType (todo: store more info on save)
    const [
      plotterData,
      stackRank,
      pointDistribution,
      textHighlight,
      spectrumDistribution,
      explanation,
      matrix,
      personalInfo,
      madlib,
    ] = Array(9).fill(answerData);
    this.setState({
      plotterData,
      stackRank,
      pointDistribution,
      textHighlight,
      spectrumDistribution,
      explanation,
      matrix,
      personalInfo,
      madlib,
    });
  };
  loadSurvey = () => {
    console.log('loading survey');
    const { surveyCode, presentationSurveyCode } = this.props.match.params;
    // pull up survey in presentation mode
    this.setState({ loading: true });
    return fetchSurveyModulesAndResponses({
      surveyCode: presentationSurveyCode || surveyCode,
      showAllUsers: true,
    }).then(({ surveyDetails, moduleList, userResponses }) => {
      // set user response data (by default restore first response)
      const answerData =
        (Object.values(userResponses)[0] || {}).answerData || {};
      this.restoreAnswerData({ answerData });
      // set survey data
      return this.setState({
        surveyDetails: surveyDetails || { name: ' ' },
        moduleList,
        loading: false,
        surveyLoadError: !surveyDetails,
        userResponses,
      });
    });
  };
  getCurrentProjectId() {
    const currentProjectId = getNestedData(
      this.props,
      `location.state.projectId`
    );
    console.log(currentProjectId);
    return currentProjectId;
  }
  onLogoClick = () => {
    Tracking.event({ name: presentationEvents.IF_ICON_CLICKED });
    const projectId = this.getCurrentProjectId();
    if (projectId) {
      return this.props.history.push(`${ADMIN}`, { projectId });
    }
    this.props.history.push(LANDING);
  };
  getCurrentModule(options = {}) {
    const { moduleList = this.state.moduleList } = options;
    const slide = getSlide(this.props);
    const module = sortModulesToArray(moduleList).find(
      (module) => module.order === slide
    );
    return module || {};
  }
  getQuestionData({ module = {} }) {
    return module.questionData;
  }
  getModuleId({ module }) {
    if (!module) return;
    const { moduleId } = module;
    return moduleId;
  }
  getCurrentSlide({ module = {} }) {
    const { order } = module;
    const slide = order ? parseInt(order, 10) : getSlide(this.props);
    return slide;
  }
  getLastSlide() {
    return getLastSlide(this.state.moduleList);
  }
  getModuleType({ module = {} }) {
    const { moduleType } = module;
    return moduleType;
  }
  getOrderWithOutTextSlides({ module = {} }) {
    const { displayOrder } = module;
    return displayOrder;
  }
  renderSurveyTitle({ module }) {
    if (!module) return;
    const { question, description } = module;
    if (!question) return;
    const responseCount = Object.entries(this.state.userResponses).length;
    return (
      <SurveyTitle
        lastSlide={this.getLastSlide()}
        moduleType={this.getModuleType({ module })}
        orderWithOutTextSlides={this.getOrderWithOutTextSlides({ module })}
        question={question}
        description={description}
        responseCount={responseCount}
        inPresentationMode={true}
        notFixed={true}
        disableMinHeight={true}
      />
    );
  }
  isModuleReady(type, module) {
    if (this.state.loading) return false;
    if (Object.keys(this.state.moduleList).length === 0) return false;
    if (type && this.getModuleType({ module }) !== type) return false;
    return module;
  }
  renderXYPlotter({ formikProps, module }) {
    if (!this.isModuleReady(PLOTTER_XY, module)) return;
    return (
      <PlotterContainer>
        <Plotter
          moduleType={this.getModuleType({ module })}
          moduleId={this.getModuleId({ module })}
          questionData={this.getQuestionData({ module })}
          inPresentationMode={true}
          {...formikProps}
        />
      </PlotterContainer>
    );
  }
  render22Plotter({ formikProps, module }) {
    if (!this.isModuleReady(PLOTTER_22, module)) return;
    return (
      <PlotterContainer>
        <Plotter
          moduleType={this.getModuleType({ module })}
          moduleId={this.getModuleId({ module })}
          questionData={this.getQuestionData({ module })}
          inPresentationMode={true}
          match={this.props.match}
          {...formikProps}
        />
      </PlotterContainer>
    );
  }
  renderStackRank({ formikProps, module }) {
    if (!this.isModuleReady(STACK_RANK, module)) return;
    return (
      <StackRank
        moduleId={this.getModuleId({ module })}
        questionData={this.getQuestionData({ module })}
        inPresentationMode={true}
        {...formikProps}
      />
    );
  }
  renderPointDistribution({ formikProps, module }) {
    if (!this.isModuleReady(POINT_DISTRIBUTION, module)) return;
    return (
      <PointDistribution
        moduleId={this.getModuleId({ module })}
        questionData={this.getQuestionData({ module })}
        inPresentationMode={true}
        {...formikProps}
      />
    );
  }
  renderTextHighlight({ formikProps, module }) {
    if (!this.isModuleReady(TEXT_HIGHLIGHT, module)) return;
    return (
      <TextHighlightSurvey
        moduleId={this.getModuleId({ module })}
        questionData={this.getQuestionData({ module })}
        inPresentationMode={true}
        location={this.props.location}
        {...formikProps}
      />
    );
  }
  renderSpectrumDistribution({ formikProps, module }) {
    if (!this.isModuleReady(SPECTRUM_DISTRIBUTION, module)) return;
    console.log({
      formikProps,
      questionData: this.getQuestionData({ module }),
      moduleId: this.getModuleId({ module }),
    });
    return (
      <SpectrumDistribution
        moduleId={this.getModuleId({ module })}
        questionData={this.getQuestionData({ module })}
        inPresentationMode={true}
        shortContainer={true}
        {...formikProps}
      />
    );
  }
  renderMatrix({ formikProps, module }) {
    if (!this.isModuleReady(MATRIX, module)) return;
    return (
      <Matrix
        moduleId={this.getModuleId({ module })}
        questionData={this.getQuestionData({ module })}
        inPresentationMode={true}
        {...formikProps}
      />
    );
  }
  renderSurveyBottom(options = {}) {
    if (this.isOnLastSlide()) return null;
    const { formikProps, module } = options;
    return (
      <SurveyBottom
        moduleId={this.getModuleId({ module })}
        questionData={this.getQuestionData({ module })}
        surveyCode={getSurveyCode(this.props)}
        userId={this.props.match.params.userId}
        history={this.props.history}
        location={this.props.location}
        currentUser={this.props.currentUser}
        surveyDetails={this.state.surveyDetails}
        inPresentationMode={true}
        {...formikProps}
      />
    );
  }
  isOnLastSlide() {
    return this.props.match.params.slide === COMPLETE;
  }
  isOnReviewSlide() {
    return this.props.match.params.slide === REVIEW;
  }
  renderPersonalInfo({ formikProps, module }) {
    if (!this.isModuleReady(PERSONAL_INFO, module)) return;
    return (
      <PersonalInfo
        moduleId={this.getModuleId({ module })}
        questionData={this.getQuestionData({ module })}
        inPresentationMode={true}
        {...formikProps}
      />
    );
  }
  renderMadlib({ formikProps, module }) {
    if (!this.isModuleReady(MADLIB, module)) return;
    return (
      <Madlib
        moduleId={this.getModuleId({ module })}
        questionData={this.getQuestionData({ module })}
        inPresentationMode={true}
        {...formikProps}
      />
    );
  }
  renderChart({ module }) {
    if (this.state.loading) return;
    const formikProps = {
      plotterData: {
        ...this.state.plotterData,
      },
      stackRank: {
        ...this.state.stackRank,
      },
      pointDistribution: {
        ...this.state.pointDistribution,
      },
      textHighlight: {
        ...this.state.textHighlight,
      },
      spectrumDistribution: {
        ...this.state.spectrumDistribution,
      },
      matrix: {
        ...this.state.matrix,
      },
      personalInfo: {
        ...this.state.personalInfo,
      },
      madlib: {
        ...this.state.madlib,
      },
      explanation: {
        ...this.state.explanation,
      },
      userResponses: {
        ...this.state.userResponses,
      },
      surveyEmail: '',
      responseId: void 0,
      reviewCode: void 0,
    };
    if (this.getModuleType({ module }) === TEXT_SLIDE) return null;
    return (
      <SectionContainer>
        {this.renderSurveyTitle({ module })}
        {this.renderXYPlotter({ formikProps, module })}
        {this.render22Plotter({ formikProps, module })}
        {this.renderStackRank({ formikProps, module })}
        {this.renderPointDistribution({ formikProps, module })}
        {this.renderTextHighlight({ formikProps, module })}
        {this.renderSpectrumDistribution({ formikProps, module })}
        {this.renderMatrix({ formikProps, module })}
        {this.renderPersonalInfo({ formikProps, module })}
        {this.renderMadlib({ formikProps, module })}
      </SectionContainer>
    );
  }
  renderForm() {
    if (this.state.loading) return;
    const moduleList = this.state.moduleList;
    const modules = sortModulesToArray(moduleList).sort(
      (a, b) => a.order < b.order
    );
    return (
      <FormContainer>
        {modules.map((module) => {
          return this.renderChart({ module });
        })}
      </FormContainer>
    );
  }
  render() {
    const { name } = this.state.surveyDetails;
    let backgroundColor = '#F0F0F0';
    return (
      <Layout
        title={name}
        background={backgroundColor}
        onLogoClick={this.onLogoClick}
        onClose={this.onLogoClick}
        inPresentationMode={true}
      >
        <PageContainer
          background={backgroundColor}
          flexDirection="column"
          justifyContent="flex-start"
        >
          <LoadingFull loading={this.state.loading}>Loading...</LoadingFull>
          <NoSurveyFull
            loading={this.state.loading}
            surveyLoadError={this.state.surveyLoadError}
          >
            Survey with code {getSurveyCode(this.props)} not found
          </NoSurveyFull>
          <LoadingFull
            loading={
              !this.state.loading &&
              Object.keys(this.state.moduleList).length === 0
            }
          >
            Survey has no modules
          </LoadingFull>
          {this.renderForm()}
        </PageContainer>
      </Layout>
    );
  }
}

const FullPresentationContext = React.forwardRef((props, ref) => (
  <RouterContext.Consumer>
    {({ project, survey }) => {
      return (
        <FullPresentation
          {...props}
          project={project}
          survey={survey}
          ref={ref}
        />
      );
    }}
  </RouterContext.Consumer>
));

export default withAuthorization(() => true)(FullPresentationContext);
