import styled from 'styled-components';
import { Loading, NoSurveyFound } from 'components/Builder/SurveyModules';
import { EnterCode } from 'pages/Landing/styles';

export const PlotterContainer = styled('div')`
  width: 100%;
  margin: auto;
  max-width: 44.375rem;
  cursor: pointer;
  height: 100%;
`;

export const LoadingFull = styled(Loading)`
  flex: 1;
  height: 100%;
  border: 1px solid transparent;
  display: ${({ loading }) => (loading ? `flex` : `none`)};
  pointer-events: auto;
  padding: 1rem;
`;

export const NoSurveyFull = styled(NoSurveyFound)`
  flex: 1;
  height: 100%;
  display: ${({ loading }) => (loading ? `none` : `flex`)};
  border: 1px solid transparent;
`;

export const getSlide = (props) => {
  const { inEditMode, match, activeIndex } = props;
  if (inEditMode) return activeIndex + 1;
  const { slide } = (match && match.params) || {};
  const slideInt = parseInt(slide, 10);
  return slideInt || slide;
};

export const EnterEmail = styled(EnterCode)`
  margin: 1rem 0;
`;

export const FeedbackRecordId = styled('div')`
  font-family: 'Neue Haas Grotesk Display Std', sans-serif;
  font-size: 1.5rem;
  font-weight: normal;
  margin: 1rem 0;
  display: none;
`;

export const SurveyQuestionDataContainer = styled('div')`
  margin: 0;
  height: 100%;
  ${({ inMobileView }) =>
    inMobileView
      ? ``
      : `
  @media (min-width: 1200px) {
    margin: auto;
    width: 72.6875rem;
    display: flex;
    justify-content: center;
    height: 100%;
    display: table;
    table-layout: fixed;
  }
  `}
`;

export const SurveyLeftColumn = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto 0;
  ${({ inMobileView }) =>
    inMobileView
      ? ``
      : `
  @media (min-width: 1200px) {
    flex: 1 0 30.5rem;
    margin-right: 3.7rem;
    padding: 1rem;
    width: 30.5rem;
    margin-right: 0;
    display: table-cell;
    vertical-align: middle;
  }
  `}
`;

export const SurveyRightColumn = styled('div')`
  display: flex;
  overflow: auto;
  height: 100%;
  margin: 0 auto;
  width: 100%;
  padding: 2.5rem 0 3.25rem 0;
  ${({ inMobileView }) =>
    inMobileView
      ? ``
      : `
  @media (min-width: 1200px) {
    display: table-cell;
    vertical-align: middle;
    width: 612px;
    padding: 0;
  }
  `}
`;

export const SurveyRow = styled('div')`
  height: 100%;
  ${({ inMobileView }) =>
    inMobileView
      ? ``
      : `
  @media (min-width: 1200px) {
    display: table-row;
  }
  `}
`;

export const ComparisonContainer = styled('div')`
  background: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-top: 0px;
`;

export const ComparisonSurveyBottomContaier = styled('div')`
  width: 33.33%;
  margin: 0 auto;
`;
