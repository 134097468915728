import { signInWithRedirect } from 'firebase/auth';
import { auth, GoogleProvider, MicrosoftProvider } from '../firebase';
import Tracking from 'lib/tracking/tracking';
import { loginEvents } from 'lib/tracking/events';

// Sign out
export const doSignOut = () => auth.signOut();

// Google Sign in

export const signInWithGoogle = () => {
  // Tracking.identifyUser({ userId: Tracking.getDistinctId() });
  Tracking.event({ name: loginEvents.LOGIN_WITH_GOOGLE });
  return signInWithRedirect(auth, GoogleProvider);
};

export const signInWithMicrosoft = () => {
  // Tracking.identifyUser({ userId: Tracking.getDistinctId() });
  Tracking.event({ name: loginEvents.LOGIN_WITH_MICROSOFT });
  return signInWithRedirect(auth, MicrosoftProvider);
};

export const authCondition = (authUser) => !!authUser;
