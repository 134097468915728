import React, { useState } from "react";
import styled from "styled-components";
import "@reach/menu-button/styles.css";
import { LeftColumn, LeftTitle, Plus } from "../../layout";
import PlusImg from "../../../img/plus.svg";
import PlusBlue from "../../../img/plus-blue.svg";
import ProjectsList from "../ProjectsList/ProjectsList";
import { TemplateGalleryLink } from "../../Templates";

const AdminLeftColumnContainer = styled(LeftColumn)`
  display: flex;
  flex-direction: column;
  max-height: 50%;
  @media (min-width: 1024px) {
    max-height: none;
  }
`;

export const PlusAddProject = styled(Plus)`
  margin-right: 30px;
  margin-left: 0;
  background-image: url('${PlusBlue}');
`;

const AddNewProject = styled(LeftTitle)`
  cursor: pointer;
  font-family: Roboto,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #1CA4FC;
  padding-left: 30px;
  border-bottom: 2px solid #1CA4FC;
  &:hover {
    background-color: #1CA4FC;
    color: #FFFF;
    ${PlusAddProject} {
      background-image: url('${PlusImg}');
    }
  }
`;

const AllSurveys = styled("h3")`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 75px;
  padding-top: 9px;
  padding-bottom: 9px;
  cursor: pointer;
  color: ${props => (props.active ? "#FF9736" : "#1ca4fc")};
  background-color: ${props => (props.active ? "none" : "none")};
  &:hover {
    background-color: #1ca4fc;
    color: #fff;
  }
`;

const NavList = styled("div")`
  height: 100%;
  overflow-y: scroll;
`;

const AdminLeftColumn = ({
  projectsArray,
  addNewProject,
  selectProject,
  selectedProject,
  onClickAllSurvey,
  isAllSurveyActive,
  sharedProjects,
  onDeleteProject
}) => {
  return (
    <AdminLeftColumnContainer>
      <AddNewProject id='add-new-project' onClick={addNewProject}>
        <PlusAddProject /> Add a new project
      </AddNewProject>
      <NavList>
        <AllSurveys active={isAllSurveyActive} onClick={onClickAllSurvey}>
          All Hilos
        </AllSurveys>
        <ProjectsList
          projectsArray={projectsArray}
          selectProject={selectProject}
          selectedProject={selectedProject}
          sharedProjects={sharedProjects}
          onSelectProject={selectProject}
          isAllSurveyActive={isAllSurveyActive}
          onDeleteProject={onDeleteProject}
        />
      </NavList>
      <TemplateGalleryLink isInMenu />
    </AdminLeftColumnContainer>
  );
};

export default AdminLeftColumn;
