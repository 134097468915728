import React, { useMemo } from "react";
import { ButtonBase } from "../../buttons";
import { ReactComponent as IconClose } from "../../../img/close-plain.svg";
import { device } from "../../../utils/deviceBreakpoints";

const styles = {
  overlay: {
    zIndex: 1000,
    display: "flex",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    [`@media ${device.tablet}`]: {
      zIndex: 1,
    },
  },
  content: {
    padding: 0,
    margin: "auto",
    width: "930px",
    height: "600px",
    boxShadow: "0px 0px 8px rgba(28, 164, 252, 0.25)",
    borderRadius: "10px",
    display: "flex",
    position: "relative",
    background: "#FFF",
    [`@media ${device.tablet}`]: {
      width: "100%",
      height: "100%",
      borderRadius: 0,
    },
  },
  image: {
    width: "65%",
    height: "100%",
    objectFit: "contain",
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    background: "rgba(0, 0, 0, 0.8)",
    [`@media ${device.tablet}`]: {
      width: "100%",
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
  text: {
    padding: "24px",
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: "40px",
    color: "#000",
    width: "35%",
    overflowWrap: "break-word",
    overflow: "auto",
    [`@media ${device.tablet}`]: {
      display: "none",
    },
  },
  closeBtn: {
    position: "absolute",
    top: 8,
    right: 10,
    stroke: "#1CA4FC",
    [`@media ${device.tablet}`]: {
      top: "3rem",
      stroke: "#FFFFFF",
    },
  },
};

export default function ExpandedItemView({ onDismiss, data }) {
  const imageUrl = useMemo(
    () => (data.file ? URL.createObjectURL(data.file) : data.imageUrl),
    [data.file, data.imageUrl]
  );

  return (
    <div css={styles.overlay} onClick={() => onDismiss()}>
      <div css={styles.content} aria-label="Expanded item view">
        <ButtonBase onClick={onDismiss} css={styles.closeBtn}>
          <IconClose />
        </ButtonBase>
        <img src={imageUrl} css={styles.image} />
        <p css={styles.text}>{data.title}</p>
      </div>
    </div>
  );
}
