import { OPTION_PREFIX, PERSONAL_INFO } from 'const/module-types';
import { getUserName } from 'lib/Presentation';

const getOptions = ({ questionData }) => {
  return Object.entries(questionData)
    .filter(
      ([key, value]) =>
        key.indexOf(OPTION_PREFIX) === 0 && !value.removed && !value.addedOption
    )
    .map(([key, value]) => ({ ...value, key }));
};

const getUserComments = ({
  userResponses,
  moduleId,
  questionData,
  moduleList,
}) => {
  if (!userResponses || !moduleList || !moduleId || !questionData) return null;
  const responses = Object.values(userResponses);
  const options = getOptions({ questionData });
  return responses.reduce((acc, response, currentIndex) => {
    const answerData = response.answerData;
    const commentTime = response?.endSurveyTimestamp?.seconds;
    const moduleResponse = answerData[moduleId];
    if (!moduleResponse) return acc;
    const index = currentIndex + 1;
    const name = getUserName(
      response.userName,
      answerData,
      Object.values(moduleList),
      index
    );
    const nameLabel = `${name}-${currentIndex}`;
    const optionsData = options.reduce((accumulator, option) => {
      const data = moduleResponse[option.key];
      if (!data) return accumulator;
      const key = option.key;
      return [
        ...accumulator,
        {
          key,
          title: option.title,
          selected: data.selected || false,
          comment: data.comment || null,
        },
      ];
    }, []);
    return [
      ...acc,
      {
        name,
        label: nameLabel,
        data: optionsData,
        responseId: response.responseId,
        time: commentTime,
      },
    ];
  }, []);
};

const getModuleResponses = (responses, moduleId, userInfoModuleId) => {
  return Object.values(responses)
    .map((response = {}, index) => {
      const { userName, answerData, responseId } = response;
      return {
        username: answerData[userInfoModuleId]?.name ?? `${userName} ${index + 1}`, // prettier-ignore
        answers: answerData[moduleId],
        responseId,
      };
    })
    .filter((response) => !!response.answers);
};

const chartData = (options, moduleResponses) => {
  return options
    .map((option) => {
      const users = moduleResponses.filter(({ answers }) => {
        const answer = answers[option.key];
        return answer?.selected;
      });
      return {
        ...option,
        count: users.length,
        users,
      };
    })
    .sort((a, b) => b.count - a.count);
};

const getPersonalInfoModule = ({ modules }) => {
  const module = modules.filter(
    ({ moduleType }) => moduleType === PERSONAL_INFO
  );
  if (module && modules.length !== 0) return module[0];
  return null;
};

const getChartData = ({ userResponses, moduleId, modules, questionData }) => {
  const personalInfoModuleId = getPersonalInfoModule({ modules });
  const moduleResponses = getModuleResponses(userResponses, moduleId, personalInfoModuleId); // prettier-ignore
  const data = chartData(getOptions({ questionData }), moduleResponses);
  return {
    chartData: data,
    moduleResponsesLength: moduleResponses.length,
  };
};

export default {
  getUserComments,
  getChartData,
};
