import React, { useState, useRef, useEffect } from "react";
import { ReactComponent as CaretUp } from "../../img/caret-up-white.svg";
import { ReactComponent as CaretDown } from "../../img/caret-down-white.svg";
import styled from "styled-components";

const SortContainer = styled("div")`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  background: #1CA4FC;
  position: relative;
`;

const Button = styled("button")`
  display: flex;
  justify-content: space-between;
  border: none;
  outline: none;
  background: transparent;
  padding: 10px 10px;
  color: white;
  width: 354px;
  text-align: left;
  position: relative;
`;

const SortButton = styled(Button)`
  &:hover {
    background: #ff9736;
  }
`;

const SortList = styled("div")`
  position: absolute;
  margin-top: 4px;
  z-index: 10;
  cursor: pointer;
  background: inherit;
`;

const SelectedButton = styled(Button)``;

const Caret = styled("div")`
  width: 20px;
  height: 100%;
`;

const SurveySort = ({ onSelectedItem, items, className }) => {
  const [showList, setShowList] = useState(false);
  const [selectedItem, setSelectedItem] = useState(items[0]);
  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutsideDropDown(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)){
        setShowList(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutsideDropDown);

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideDropDown);
    }
  }, [dropdownRef]);

  const toggleListVisibility = () => setShowList(!showList);

  const onSelectItem = item => {
    setShowList(false);
    setSelectedItem(item);
    onSelectedItem(item);
  };

  const renderSelectedItem = () => {
    if (!selectedItem) return null;
    return `Sorted By: ${selectedItem.label}`;
  };

  return (
    <SortContainer ref={dropdownRef} className={className}>
      <SelectedButton onClick={toggleListVisibility}>
        {renderSelectedItem()}
        <div css={{ display: "flex", alignSelf: "center", stroke: "#FFF" }}>
          {showList ? <CaretDown /> : <CaretUp />}
        </div>
      </SelectedButton>
      {showList && (
        <SortList>
          {items
            .filter(item => item.id !== (selectedItem && selectedItem.id))
            .map(item => (
              <SortButton onClick={() => onSelectItem(item)} key={item.id}>
                {item.label}
              </SortButton>
            ))}
        </SortList>
      )}
    </SortContainer>
  );
};

export default SurveySort;
