import React from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase';
import { doSignOut } from '../lib/auth-api';
import get from 'lodash/get';
import Tracking from 'lib/tracking/tracking';
import { loginEvents } from 'lib/tracking/events';

export const AuthUserContext = React.createContext(null);
const anIfster = /intentionalfutures.com|cody.moniz@gmail.com|unyonotachi@gmail.com|john.kagga@andela.com|idris.adetunmbi@andela.com|wale.ayandiran@andela.com|ayodeji.moronkeji@andela.com/;

const withAuthentication = (Component) =>
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        authUser: null,
        token: null,
        claims: null,
        canEdit: false,
        loading: true,
      };
    }

    componentDidMount() {
      onAuthStateChanged(auth, async (authUser) => {
        const email = authUser
          ? authUser.email || get(authUser, 'providerData[0].email')
          : '';
        if (anIfster.exec(email)) {
          const tokenResult = await authUser.getIdTokenResult();
          const token = tokenResult.token;
          let claims = tokenResult.claims;
          claims = {
            ...claims,
            organizationId: 'fGK4hNrqExnniqH83QW7',
            // If we move to other orgs, validate that we have the orgId or orgName.and set it automatically
            // we are hard coding it here because some users do not have it and they were failing to use the app.
          };
          //Trigger function to add the claims
          Tracking.event({ name: loginEvents.LOGIN_SUCCESSFUL });
          return this.setState({
            authUser,
            token,
            claims,
            canEdit: true,
            loading: false,
          });
        } else {
          return this.badLoginCheck(authUser);
        }
      });
    }

    badLoginCheck(user) {
      if (user) {
        return this.setState(
          { authUser: null, canEdit: false, loading: false },
          () => {
            return doSignOut();
          }
        );
      } else {
        return this.setState({
          authUser: null,
          canEdit: false,
          loading: false,
        });
      }
    }

    render() {
      return (
        <AuthUserContext.Provider value={this.state}>
          <Component />
        </AuthUserContext.Provider>
      );
    }
  };

export default withAuthentication;
