import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useParams, useHistory } from 'react-router';
import { Masonry } from '@mui/lab';
import {
  setClickedChartElementOptionKey,
  setCommentsPanelVisibility,
  setPanelComments,
  setSelectedParticipantResponseId,
} from 'pages/presentationSlice';
import { ReactComponent as SortAsc } from 'img/sort_asc.svg';
import { ReactComponent as SortDsc } from 'img/sort_dsc.svg';
import { styles, Masons } from './styles';
import { ButtonBase } from 'components/buttons';
import { updateSurveyResponse } from 'lib/survey-api';
import { HILOS } from 'const/index';
import { ADD_OPTION, OPTION_PREFIX } from 'const/module-types';
import { deletePopulateOption, updatePopulateOption } from 'lib/modules-api';
import { HiloMasons } from 'components/PresentationModules/AddOptions/HiloMasons';

export const AddOptions = ({ moduleId, mode, comparisonSection }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { workshopCode, slide } = useParams();
  const search = useLocation().search;
  const sort = new URLSearchParams(search).get('sort');

  const [optionToEdit, setOptionToEdit] = useState(null);
  const [asc, setAsc] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (sort === 'dsc') {
      setAsc(false);
    }
  }, []);

  useEffect(() => {
    setLoading(false);
    onCloseEditDialog();
  }, []);

  const onCloseEditDialog = () => {
    setOptionToEdit(null);
  };

  const modules = useSelector((state) => state.presentation.modules);

  const module = modules.find((module) => module.moduleId === moduleId);

  const responses = useSelector((state) => state.presentation.userResponses);

  const addOptionsData = useSelector(
    (state) => state.presentation[moduleId].chartData
  );

  const moduleOptions = Object.entries(
    module.questionData ?? {}
  ).filter(([key]) => key.startsWith(OPTION_PREFIX));

  const facilitatorOptions = moduleOptions
    .filter((option) => option[1].isShown)
    .map(([key, value]) => ({
      id: key,
      name: value.title,
      author: 'facilitator',
      isShown: value.isShown,
    }));

  const addOptions = [...Object.values(addOptionsData), ...facilitatorOptions];

  const comments = useSelector((state) => state.presentation.comments);

  const handleEditOption = async (option, updatedOption, action) => {
    setLoading(true);
    const responseId = option.responseId;
    const id = option.id;
    const name = option.name;
    const { surveyId } = module;
    const questionOptionDataKey = `questionData.${id}`;

    if (option.author !== 'facilitator') {
      const Idx = addOptions.findIndex(
        (val) =>
          val.name === name && val.id === id && val.responseId === responseId
      );

      const newResponse = JSON.parse(
        JSON.stringify(responses[`${responseId}`])
      );

      newResponse.answerData[`${moduleId}`][addOptions[Idx].id] = action
        ? { ...newResponse.answerData[`${moduleId}`][Idx], selected: false }
        : { value: updatedOption, selected: true };
      await updateSurveyResponse({
        newResponse,
        responseId,
        surveyId,
      });
    } else {
      await updatePopulateOption({
        moduleId,
        moduleType: ADD_OPTION,
        questionOptionDataKey,
        optionValue: updatedOption,
      });
    }
    setLoading(false);
    onCloseEditDialog();
  };

  const handleDeleteOption = async () => {
    const optionId = optionToEdit.id;
    const deleteAction = true;
    if (optionToEdit.author === 'facilitator') {
      setLoading(true);

      const questionOptionDataKey = `questionData.${optionId}`;
      await deletePopulateOption({
        moduleId,
        questionOptionDataKey,
      });
    } else {
      await handleEditOption(optionToEdit, '', deleteAction);
    }
    setLoading(false);
  };

  const handleSort = async () => {
    const { surveyId, moduleId } = module;
    const path =
      mode === HILOS.WORKSHOP
        ? `/workshop/${workshopCode}/${slide}?mode=presentation&sort`
        : `/presentation/new/${surveyId}?moduleId=${moduleId}&sort`;
    await setAsc(!asc);
    asc ? history.replace(`${path}=dsc`) : history.replace(`${path}=asc`);
  };

  const sortedOptions = asc
    ? (addOptions || []).sort((a, b) => a.name.localeCompare(b.name))
    : (addOptions || []).sort((a, b) => b.name.localeCompare(a.name));

  const selectedParticipantResponseId = useSelector((state) => {
    if (comparisonSection && mode === HILOS.WORKSHOP) {
      return (
        state.workshop.comparison[comparisonSection]
          .selectedParticipantResponseId?.responseCode ||
        state.workshop.comparison[comparisonSection]
          .selectedParticipantResponseId
      );
    } else {
      return (
        state.presentation.selectedParticipantResponseId?.responseCode ||
        state.presentation.selectedParticipantResponseId
      );
    }
  });

  const selectedOptionKey = useSelector(
    (state) => state.presentation.clickedChartElementOptionKey
  );

  const optionClicked = (option) => {
    if (option.key === selectedOptionKey) {
      setOptionToEdit(option);
    } else {
      setOptionToEdit(null);
    }

    const user = comments.filter((comment) => {
      if (comment.responseId === option.responseId) return comment;
    });

    dispatch(setCommentsPanelVisibility(true));
    dispatch(setSelectedParticipantResponseId(user?.[0]?.responseId));
    dispatch(setClickedChartElementOptionKey(option.key));
    dispatch(setPanelComments(comments));
  };

  const isMasonSelected = (responseId, optionKey) => {
    if (selectedOptionKey) {
      return optionKey === selectedOptionKey;
    }

    return selectedParticipantResponseId === responseId;
  };

  return (
    <>
      <div css={[styles.container]}>
        <div css={[styles.topGrpBtn]}>
          <ButtonBase
            css={[styles.actionBtn, styles.sortBtn]}
            onClick={handleSort}
          >
            {asc ? (
              <SortAsc css={[styles.sortIcon]} />
            ) : (
              <SortDsc css={[styles.sortIcon]} />
            )}
            By Alpha
          </ButtonBase>
        </div>
      </div>
      <div css={[styles.options]}>
        <Masonry
          columns={{ xs: 1, sm: 1, md: 3, lg: 4 }}
          spacing={{ xs: 1, sm: 2 }}
          sx={{
            width: (sortedOptions || []).length === 2 ? '60%' : '100%',
          }}
        >
          <HiloMasons
            loading={loading}
            options={sortedOptions}
            close={onCloseEditDialog}
            optionToEdit={optionToEdit}
            optionClicked={optionClicked}
            isMasonSelected={isMasonSelected}
            handleEditOption={handleEditOption}
            handleDeleteOption={handleDeleteOption}
          />
        </Masonry>
      </div>
    </>
  );
};
