import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import {
  Bar,
  Label,
  Count,
  BarContainer,
  ChartContainer,
  Container,
  Item,
} from '../MultiSelect/styles';

const BAR_WIDTH = 510;

const getBarWidth = (rank, refCount, dataNum) => {
  const width = ((dataNum - rank / refCount) * BAR_WIDTH) / dataNum;
  if (Number.isNaN(width)) return 0;
  return Math.abs(width);
};

export default function StackRankGroupView({ data }) {
  const isCommentPanelOpen = useSelector(
    (state) => state.presentation.isCommentsPanelVisible
  );
  const numOfData = data?.length;

  return (
    <Container isCommentPanelOpen={isCommentPanelOpen}>
      <ChartContainer isStack>
        {data.map((item, index) => {
          const hasCount = item.rank > 0;
          const key = `${index}${item.rank}${item.option}`;

          return (
            <Item hasCount={hasCount} key={key}>
              <Count hasCount={hasCount} isStack>
                {/* the block of code below was introduced in order to fix the issue where the number label of the bar is not aligned when it is more than 10 */}
                {index + 1 < 10 ? (
                  <>
                    <span style={{ visibility: 'hidden' }}>0</span>
                    {index + 1}
                  </>
                ) : (
                  <>{index + 1}</>
                )}
              </Count>
              <BarContainer>
                <div css={{ display: 'flex', alignItems: 'center' }}>
                  <Label hasCount={hasCount} isStack>
                    {item.option}
                  </Label>
                </div>
                <a data-tip data-for={key}>
                  <Bar
                    width={getBarWidth(
                      item.rank,
                      data[numOfData - 1]?.rank,
                      numOfData / 2
                    )}
                    data-key={item.key}
                    isStack
                  />
                </a>
              </BarContainer>
            </Item>
          );
        })}
      </ChartContainer>
    </Container>
  );
}
