import React, { useState } from "react";
import styled from "styled-components";
import Switch from "react-switch";
import ReactTooltip from "react-tooltip";
import Tooltip from "@reach/tooltip";
import "@reach/tooltip/styles.css";

import { ReactComponent as InfoDefault } from 'img/InfoDefault.svg';
import { ReactComponent as InfoHover } from 'img/InfoHover.svg';
import { ReactComponent as Caution } from 'img/Caution.svg';

import './styles.css';

const Label = styled(`div`)`
  display: grid;
  grid-template-columns: auto auto auto auto;
  padding: 0px;
  margin-right: 5px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #1CA4FC;
  align-items: center;
  > * {
    margin: 25px 10px 15px 0;
  }
`;

const Alert = styled(`div`)`
  grid-column: 4 / span 1;
  padding: 5px;
  margin-top: -10px;
  background: #FFFFFF;
  border: 1px solid #FF708B;
  box-sizing: border-box;
  width: 130px;
  height: 90px;
`;

const CautionText = styled(`p`)`
  color: #000000;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 150%;
  letter-spacing: -0.01em;
`;

export const PopulateToggle = ({ name, checked, onChange, displayOrder }) => {

  const TooltipLabel = "Enable this option to pre-fill \
  items from earlier modules.";
  const disable = displayOrder === 1 ? true : false;
  return (
    <Label>
      <span style={{ width: "100px" }}>Populate options</span>
      <Switch
        disabled={disable}
        uncheckedIcon={false}
        checkedIcon={false}
        onHandleColor="#1CA4FC"
        offHandleColor="#BDBDBD"
        offColor="#FFFFFF"
        onColor="#FFFFFF"
        activeBoxShadow={null}
        checked={checked}
        onChange={onChange}
        name={name}
        height={17}
        width={45}
        handleDiameter={14}
        css={{ border: checked ? "1.2px solid #1CA4FC" : "1.2px solid #BDBDBD", borderRadius: "60px !important", height: "fit-content" }}
      />
      <Tooltip label={TooltipLabel} style={{ zIndex: "10000", marginLeft: "5px", }}>
        {checked ? <InfoHover /> : <InfoDefault />}
      </Tooltip>
      {disable && <>
        <a data-tip data-for="caution">
          <Caution style={{ marginTop: "10px" }} />
        </a>
        <ReactTooltip
          id="caution"
          place="bottom"
          effect="solid"
          multiline
          arrowColor="transparent"
          className="customTheme"
        >
          <Alert>
            <CautionText>Uh-oh! Looks like there’s nothing to populate from since this is the first question.</CautionText>
          </Alert>
        </ReactTooltip>
      </>}
    </Label>
  );
};
