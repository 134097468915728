import styled from 'styled-components';

export const HiloParagraph = styled('p')`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: ${(props) => props.weight || '400'};
  font-size: ${(props) => props.size || '20'}px;
  line-height: ${(props) => props.lineHeight || '23.44'}px;
  margin-bottom: 0;
  color: ${(props) => props.color || '#1ca4fc'};
  display: ${(props) => props.display || 'block'};
`;
