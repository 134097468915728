import React from 'react';

export const RefreshSvg = () => (
    <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        className="svg"
        xmlns="http://www.w3.org/2000/svg">
      <path
          d="M26.99 18.9575C26.99 25.8583 21.3958 31.4525 14.495 31.4525C7.5942 31.4525 2 25.8583 2 18.9575C2 12.0567 7.5942 6.46249 14.495 6.46249"
          stroke="#868C97"
          strokeWidth="2.6775"
          strokeLinecap="round"/>
      <path d="M9.91992 2L15.9718 6.34989L11.6219 12.4017"
            stroke="#868C97"
            strokeWidth="2.6775"
            strokeLinecap="round"
            strokeLinejoin="round"/>
    </svg>
);

export const CrossSvg = () => (
    <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        className="svg"
        xmlns="http://www.w3.org/2000/svg">
      <path d="M10.9067 33.0931L32.7204 11.2794"
            stroke="#868C97"
            strokeWidth="2.89212"
            strokeLinecap="round"
            strokeLinejoin="round"/>
      <path d="M32.7207 33.0931L10.907 11.2794"
            stroke="#868C97"
            strokeWidth="2.89212"
            strokeLinecap="round"
            strokeLinejoin="round"/>
    </svg>
)
