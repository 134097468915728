import React from "react";
import { VictoryTooltip } from "victory";

const DefaultTooltip = props => {
  /**
   * Change the position of the tooltip for negative x or y
   * datum values for the Plotter22 chart.
   * @returns {{}|{x: DefaultTooltip.props.x, y: number}}
   */
  const getXYTooltipPosition = () => {
    const { datum, x, y, isPlotter22, isSpectrum } = props;
    if (isPlotter22) {
      const positiveXNegativeY = datum.x > 0 && datum.y < 0;
      const negativeXNegativeY = datum.x < 0 && datum.y < 0;
      if (positiveXNegativeY || negativeXNegativeY) return { x, y: y - 20 };
    }
    if (isSpectrum) {
      const xOffset = 35;
      const yOffset = 10;
      return {
        x: x + xOffset,
        y: y + yOffset
      };
    }
    return {};
  };

  return (
    <VictoryTooltip
      {...props}
      constrainToVisibleArea
      pointerLength={0}
      orientation="top"
      flyoutStyle={{
        stroke: "#868C97",
        fill: "#FFFFFF"
      }}
      style={{
        fill: "#868C97",
        fontSize: props.fontSize || 8,
        fontFamily: "Roboto",
        fontWeight: 500
      }}
      {...getXYTooltipPosition()}
    />
  );
};

DefaultTooltip.defaultEvents = VictoryTooltip.defaultEvents;

export default DefaultTooltip;
