import React, { Component } from 'react';
import styled from 'styled-components';

import {
  Row,
  LabeledInput,
  LabeledTextarea,
  SettingsOverlay,
  Button,
} from '../../layout';
import {
  MODULE_TYPES_DISPLAY,
  MODULE_TYPES_FIELDS,
  TEXT_SLIDE,
  STACK_RANK,
  POINT_DISTRIBUTION,
  EXPLANATION,
  SENTENCE,
  TEXT_HIGHLIGHT,
  MATRIX,
  SPECTRUM,
  SPECTRUM_DISTRIBUTION,
  PERSONAL_INFO,
  SPECTRUM_QUESTION,
  SPECTRUM_DESCRIPTION,
  FIRST_COLUMN_LABEL,
  POINT_DISTRIBUTION_EXPLANATION,
  MADLIB,
  REQUIRED,
  MADLIB_SENTENCE,
  OPTION_PREFIX,
  DESCRIPTION,
  QUESTION,
} from '../../../const/module-types';
import { getNestedData } from '../../../const/nested-data';

import TextSlideImg from '../../../img/text-slide.png';
import PlotterXYImg from '../../../img/xy.png';
import Plotter22Img from '../../../img/22.png';
import PointDistributionImg from '../../../img/point-distribution.png';
import TextHighlightImg from '../../../img/text-highlight.png';
import DefaultImg from '../../../img/spectrum-distribution.png';
import StackRankImg from '../../../img/sr.png';
import DupeImg from '../../../img/dupe.svg';
import CloseImg from '../../../img/close.png';
import SpectrumDistributionImg from '../../../img/sd.png';
import MatrixImg from '../../../img/mt.png';

import { DragSource, DropTarget } from 'react-dnd';

const ModuleContainer = styled('div')`
  background-color: white;
  box-shadow: 0.125rem 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
  font-family: 'Neue Haas Grotesk Display Std';
  font-weight: bold;
  color: black;
  min-height: 16.875rem;
  padding: 1.25rem;
  line-height: 1;
  margin: 0 auto 1.25rem auto;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Order = styled('div')`
  cursor: pointer;
  font-family: 'Neue Haas Grotesk Display Std', sans-serif;
  font-size: 3.75rem;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 1.875rem;
`;

const Type = styled('div')`
  font-family: 'Neue Haas Grotesk Display Std', sans-serif;
  font-size: 0.75rem;
  font-weight: bold;
  margin: 1.25rem 0 0 0;
  @media (min-width: 1200px) {
    margin: auto 0 0.3125rem 0;
  }
`;

const TypeImgs = {
  TEXT_SLIDE: TextSlideImg,
  PLOTTER_XY: PlotterXYImg,
  PLOTTER_22: Plotter22Img,
  POINT_DISTRIBUTION: PointDistributionImg,
  STACK_RANK: StackRankImg,
  TEXT_HIGHLIGHT: TextHighlightImg,
  SPECTRUM_DISTRIBUTION: SpectrumDistributionImg,
  MATRIX: MatrixImg,
  PERSONAL_INFO: DefaultImg,
  MADLIB: DefaultImg,
};

const TypeImg = styled('div')`
  cursor: pointer;
  width: 5.125rem;
  height: 3.25rem;
  background-position: left center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: ${(props) =>
    props.backgroundImage ? `url('${props.backgroundImage}')` : 'none'};
`;

const OrderAndTypeContainer = styled('div')`
  display: flex;
  flex: auto;
  flex-direction: row;
  justify-content: space-between;
  @media (min-width: 1199px) {
    flex: 0 0 5.125rem;
    flex-direction: column;
  }
`;

const QuestionDetailsContainer = styled('div')`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
`;

const DetailLong = styled(LabeledInput)`
  margin-bottom: 1.25rem;
`;

const Question = styled(DetailLong)``;

const Details = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const DetailShortInputContainer = styled('div')`
  flex: 0 1 48.5%;
  margin-bottom: 1.25rem;
`;

const SpacerContainer = styled(DetailShortInputContainer)``;

const DetailShortInput = (props) => {
  return (
    <DetailShortInputContainer>
      <LabeledInput {...props} />
    </DetailShortInputContainer>
  );
};

const DetailShort = (props = {}) => {
  const { name } = props;
  const value = getNestedData(props.values, name);
  const extendProps = {
    value: value || '',
    onChange: props.handleChange,
    ...props,
  };
  return <DetailShortInput {...extendProps} />;
};

const AllowExplanationContainer = styled('div')`
  cursor: pointer;
  flex: ${(props) => (props.flex ? props.flex : '0 1 48.5%')};
  margin-bottom: 1.25rem;
  font-family: 'Neue Haas Grotesk Display Std', sans-serif;
  font-size: 1.125rem;
  font-weight: normal;
  display: flex;
  align-items: center;
`;

const AllowExplanationCheckbox = styled('input')`
  margin: 0 0.75rem !important;
  cursor: pointer;
`;

const HorizontalFieldContainer = styled('div')`
  flex: 0 1 48.5%;
  display: block;
`;

class AllowExplanation extends Component {
  toggleBoolean = () => {
    const { moduleId, field = EXPLANATION } = this.props;
    const key = `moduleList.${this.props.moduleId}.questionData.${field}`;
    const value = getNestedData(this.props.values, key) || '';
    this.props.setFieldValue(key, !value);
  };
  render() {
    const { moduleId, field = EXPLANATION } = this.props;
    const key = `moduleList.${this.props.moduleId}.questionData.${field}`;
    const value = getNestedData(this.props.values, key) || '';
    return (
      <AllowExplanationContainer onClick={this.toggleBoolean}>
        <AllowExplanationCheckbox
          id={key}
          name={key}
          key={key}
          type="checkbox"
          checked={value}
          readOnly
        />
        {this.props.placeholder}
      </AllowExplanationContainer>
    );
  }
}

export const DetailTextarea = styled(LabeledTextarea)`
  width: 100%;
`;
const Description = styled(DetailTextarea)``;

/**
 * If the option key is in the addedOptions it means that it should not be
 * shown to the user in this module since it is supposed to appear in
 * the next module.
 * That means that the optio was added by the facilitator.
 * @param hash
 * @param prefix
 * @returns {[string, unknown][]}
 */
export const filterAndSortOptions = (hash, prefix = OPTION_PREFIX) => {
  return Object.entries(hash)
    .filter(([key, hashData]) => {
      if (hash.addedOptions) {
        return key.indexOf(prefix) === 0 && !hash.addedOptions.includes(key);
      }
      return key.indexOf(prefix) === 0;
    })
    .sort(([aKey, aHash], [bKey, bHash]) => {
      const aOrder = parseInt(aKey.replace(prefix, ''), 10);
      const bOrder = parseInt(bKey.replace(prefix, ''), 10);
      return aOrder - bOrder;
    });
};

class QuestionDetails extends Component {
  renderDetailsByType(moduleType = this.props.moduleType) {
    const fields = MODULE_TYPES_FIELDS[moduleType] || {};
    return Object.entries(fields).map(
      ([fieldKey, placeholder], moduleListIndex) => {
        const key = `moduleList.${this.props.moduleId}.questionData.${fieldKey}`;
        const value = getNestedData(this.props.values, key);
        return (
          <DetailShort
            id={key}
            name={key}
            key={key}
            data-field-key={fieldKey}
            placeholder={placeholder}
            onChange={this.props.handleChange}
            value={value || ''}
          />
        );
      }
    );
  }
  renderQuestion() {
    const key = `moduleList.${this.props.moduleId}.${QUESTION}`;
    const value = getNestedData(this.props.values, key);
    const placeholder =
      this.props.moduleType === TEXT_SLIDE ? 'Header' : 'Question';
    return (
      <Question
        placeholder={placeholder}
        value={value || ''}
        id={key}
        name={key}
        key={key}
        onChange={this.props.handleChange}
      />
    );
  }
  renderDescription() {
    const key = `moduleList.${this.props.moduleId}.${DESCRIPTION}`;
    const value = getNestedData(this.props.values, key);
    return (
      <Description
        placeholder="Description"
        value={value || ''}
        id={key}
        name={key}
        key={key}
        onChange={this.props.handleChange}
      />
    );
  }
  getQuestionData() {
    const key = `moduleList.${this.props.moduleId}.questionData`;
    const questionData = getNestedData(this.props.values, key);
    return questionData;
  }
  renderStackRankInputs() {
    if (this.props.moduleType !== STACK_RANK) return;
    let lastIndex = 0;
    const currentItems = filterAndSortOptions(this.getQuestionData()).map(
      ([key, stackRankText]) => {
        const key2 = `moduleList.${this.props.moduleId}.questionData.${key}`;
        lastIndex = parseInt(key.replace('option', ''), 10);
        return (
          <DetailShort
            id={key2}
            name={key2}
            key={key2}
            placeholder={`Change Item ${lastIndex}`}
            onChange={this.props.handleChange}
            value={stackRankText || ''}
          />
        );
      }
    );
    const key3 = `moduleList.${
      this.props.moduleId
    }.questionData.option${lastIndex + 1}`;
    const additionalInput = (
      <DetailShort
        id={key3}
        name={key3}
        key={key3}
        placeholder={`Add Item ${lastIndex + 1}`}
        onChange={this.props.handleChange}
        value={''}
      />
    );
    return [...currentItems, additionalInput];
  }
  renderRequired() {
    const required = (
      <AllowExplanation
        placeholder="Answer required"
        setFieldValue={this.props.setFieldValue}
        values={this.props.values}
        moduleId={this.props.moduleId}
        field={REQUIRED}
      />
    );
    return required;
  }
  renderAllowExplanation() {
    const explanation = (
      <AllowExplanation
        placeholder="Allow explanation"
        onChange={this.props.handleChange}
        setFieldValue={this.props.setFieldValue}
        values={this.props.values}
        moduleId={this.props.moduleId}
        field={EXPLANATION}
      />
    );
    return explanation;
  }
  renderAllowPointDistributionExplanation() {
    if (this.props.moduleType !== POINT_DISTRIBUTION) return;
    const explanation = (
      <AllowExplanation
        placeholder="Allow explanation per item"
        onChange={this.props.handleChange}
        setFieldValue={this.props.setFieldValue}
        values={this.props.values}
        moduleId={this.props.moduleId}
        field={POINT_DISTRIBUTION_EXPLANATION}
      />
    );
    return explanation;
  }
  renderPointDistributionInputs() {
    if (this.props.moduleType !== POINT_DISTRIBUTION) return;
    const key = `moduleList.${this.props.moduleId}.questionData`;
    const value = getNestedData(this.props.values, key);
    let lastIndex = 0;
    const currentItems = filterAndSortOptions(value).map(
      ([key, pointDistributionText]) => {
        const key2 = `moduleList.${this.props.moduleId}.questionData.${key}`;
        lastIndex = parseInt(key.replace('option', ''), 10);
        return (
          <DetailShort
            id={key2}
            name={key2}
            key={key2}
            placeholder={`Change Item ${lastIndex}`}
            onChange={this.props.handleChange}
            value={pointDistributionText || ''}
          />
        );
      }
    );
    const key3 = `moduleList.${
      this.props.moduleId
    }.questionData.option${lastIndex + 1}`;
    const additionalInput = (
      <DetailShort
        id={key3}
        name={key3}
        key={key3}
        placeholder={`Add Item ${lastIndex + 1}`}
        onChange={this.props.handleChange}
        value={''}
      />
    );
    return [...currentItems, additionalInput];
  }
  renderTextHighlightInputs() {
    if (this.props.moduleType !== TEXT_HIGHLIGHT) return;
    const key = `moduleList.${this.props.moduleId}.questionData.${SENTENCE}`;
    const value = getNestedData(this.props.values, key);
    return (
      <DetailTextarea
        id={key}
        name={key}
        key={key}
        placeholder="Sentence"
        onChange={this.props.handleChange}
        value={value || ''}
        rows={3}
      />
    );
  }
  renderMatrixRowInputs() {
    let lastRowIndex = 0;
    const questionData = this.getQuestionData();
    const rowItems = Object.entries(questionData)
      .filter(([key, matrixText]) => {
        return key.indexOf('row') === 0;
      })
      .map(([key, matrixText]) => {
        const key2 = `moduleList.${this.props.moduleId}.questionData.${key}`;
        const intRow = parseInt(key.replace('row', ''), 10);
        lastRowIndex = intRow;
        return (
          <DetailShort
            id={key2}
            name={key2}
            key={key2}
            placeholder={`Row ${intRow}`}
            onChange={this.props.handleChange}
            value={matrixText || ''}
          />
        );
      });
    const addRowKey = `moduleList.${
      this.props.moduleId
    }.questionData.row${lastRowIndex + 1}`;
    const addRowInput = (
      <DetailShort
        id={addRowKey}
        name={addRowKey}
        key={addRowKey}
        placeholder={`Row ${lastRowIndex + 1}`}
        onChange={this.props.handleChange}
        value={''}
      />
    );
    return [...rowItems, addRowInput];
  }
  renderMatrixColumnInputs() {
    let lastColumnIndex = 0;
    const questionData = this.getQuestionData();
    const columnItems = Object.entries(questionData)
      .filter(([key, matrixText]) => {
        return key.indexOf('column') === 0;
      })
      .map(([key, matrixText]) => {
        const key2 = `moduleList.${this.props.moduleId}.questionData.${key}`;
        const intColumn = parseInt(key.replace('column', ''), 10);
        lastColumnIndex = intColumn;
        return (
          <DetailShort
            id={key2}
            name={key2}
            key={key2}
            placeholder={`Column ${intColumn}`}
            onChange={this.props.handleChange}
            value={matrixText || ''}
          />
        );
      });
    const addColumnKey = `moduleList.${
      this.props.moduleId
    }.questionData.column${lastColumnIndex + 1}`;
    const addColumnInput = (
      <DetailShort
        id={addColumnKey}
        name={addColumnKey}
        key={addColumnKey}
        placeholder={`Column ${lastColumnIndex + 1}`}
        onChange={this.props.handleChange}
        value={''}
      />
    );
    return [...columnItems, addColumnInput];
  }
  renderMatrixInputs() {
    if (this.props.moduleType !== MATRIX) return;
    const firstColumnLabelKey = `moduleList.${this.props.moduleId}.questionData.${FIRST_COLUMN_LABEL}`;
    const value = getNestedData(this.props.values, firstColumnLabelKey);
    const firstColumnLabelInput = (
      <DetailShort
        id={firstColumnLabelKey}
        name={firstColumnLabelKey}
        key={firstColumnLabelKey}
        placeholder="# Column Label"
        onChange={this.props.handleChange}
        value={value || ''}
      />
    );
    return [
      firstColumnLabelInput,
      <HorizontalFieldContainer key="matrixRows">
        {this.renderMatrixRowInputs()}
      </HorizontalFieldContainer>,
      <HorizontalFieldContainer key="matrixColumns">
        {this.renderMatrixColumnInputs()}
      </HorizontalFieldContainer>,
    ];
  }
  renderAllowSpectrum() {
    return null;
    if (
      ![STACK_RANK, POINT_DISTRIBUTION, MATRIX].includes(this.props.moduleType)
    )
      return;
    const spectrum = (
      <AllowExplanation
        placeholder="Allow spectrum"
        handleChange={this.props.handleChange}
        setFieldValue={this.props.setFieldValue}
        values={this.props.values}
        moduleId={this.props.moduleId}
        field={SPECTRUM}
      />
    );
    return spectrum;
  }
  renderPersonalInfoInputs() {
    if (this.props.moduleType !== PERSONAL_INFO) return;
    const key = `moduleList.${this.props.moduleId}.questionData`;
    const value = getNestedData(this.props.values, key);
    let lastIndex = 0;
    const currentItems = filterAndSortOptions(value).map(
      ([key, pointDistributionText]) => {
        const key2 = `moduleList.${this.props.moduleId}.questionData.${key}`;
        lastIndex = parseInt(key.replace('option', ''), 10);
        return (
          <DetailShort
            id={key2}
            name={key2}
            key={key2}
            placeholder={`Option ${lastIndex}`}
            inputPlaceholder={`Change option ${lastIndex}`}
            onChange={this.props.handleChange}
            value={pointDistributionText || ''}
          />
        );
      }
    );
    const key3 = `moduleList.${
      this.props.moduleId
    }.questionData.option${lastIndex + 1}`;
    const additionalInput = (
      <DetailShort
        id={key3}
        name={key3}
        key={key3}
        placeholder={`Option ${lastIndex + 1}`}
        inputPlaceholder={`Add option ${lastIndex + 1}`}
        onChange={this.props.handleChange}
        value={''}
      />
    );
    const key4 = `moduleList.${this.props.moduleId}.questionData.categoryDescription`;
    const value4 = getNestedData(this.props.values, key4);
    const categoryDescription = (
      <DetailShort
        id={key4}
        name={key4}
        key={key4}
        placeholder={`Category Description`}
        inputPlaceholder="Team"
        onChange={this.props.handleChange}
        value={value4 || ''}
      />
    );
    const spacerContainer = <SpacerContainer key="placeholder" />;
    return [
      categoryDescription,
      spacerContainer,
      ...currentItems,
      additionalInput,
    ];
  }
  renderAdditionalSpectrum() {
    if (
      ![STACK_RANK, POINT_DISTRIBUTION, MATRIX].includes(this.props.moduleType)
    )
      return;
    const keyPrefix = `moduleList.${this.props.moduleId}.questionData`;
    const spectrumKey = `${keyPrefix}.${SPECTRUM}`;
    const spectrumEnabled = getNestedData(this.props.values, spectrumKey);
    if (!spectrumEnabled) return;
    const specQuestionKey = `${keyPrefix}.${SPECTRUM_QUESTION}`;
    const specDescriptionKey = `${keyPrefix}.${SPECTRUM_DESCRIPTION}`;
    const specQuestion = (
      <DetailShort
        name={specQuestionKey}
        key={specQuestionKey}
        placeholder="Spectrum Question"
        {...this.props}
      />
    );
    const specDescription = (
      <DetailShort
        name={specDescriptionKey}
        key={specDescriptionKey}
        placeholder="Spectrum Description"
        {...this.props}
      />
    );
    const spectrumDistributionFields = this.renderDetailsByType(
      SPECTRUM_DISTRIBUTION
    );
    // as per @eric only spectrum description for now
    return [specDescription, ...spectrumDistributionFields];
    //return [ specQuestion, specDescription, ...spectrumDistributionFields ];
  }
  renderMadlibInput() {
    if (this.props.moduleType !== MADLIB) return;
    const key = `moduleList.${this.props.moduleId}.questionData.${MADLIB_SENTENCE}`;
    const value = getNestedData(this.props.values, key);
    return (
      <DetailTextarea
        id={key}
        name={key}
        key={key}
        placeholder="Madlib Sentence"
        inputPlaceholder="Enter madlib options [initial text, option 1, option 2] here"
        onChange={this.props.handleChange}
        value={value || ''}
        rows={3}
      />
    );
  }
  render() {
    const { onMouseEnter, onMouseLeave } = this.props;
    return (
      <QuestionDetailsContainer
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {this.renderQuestion()}
        <Details>
          {this.renderAllowExplanation()}
          {this.renderAllowPointDistributionExplanation()}
          {this.renderAllowSpectrum()}
          {this.renderRequired()}
          {this.renderAdditionalSpectrum()}
          {this.renderDescription()}
          {this.renderDetailsByType()}
          {this.renderStackRankInputs()}
          {this.renderPointDistributionInputs()}
          {this.renderTextHighlightInputs()}
          {this.renderMatrixInputs()}
          {this.renderPersonalInfoInputs()}
          {/* {this.renderMadlibInput()} */}
        </Details>
      </QuestionDetailsContainer>
    );
  }
}

const Dupe = styled('div')`
  cursor: pointer;
  width: 1.6875rem;
  height: 1.6875rem;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('${DupeImg}');
  margin: 0 1.5rem;
`;

const Close = styled('div')`
  cursor: pointer;
  width: 1.375rem;
  height: 1.375rem;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('${CloseImg}');
`;

const MoveClosePreview = styled('div')`
  display: flex;
  flex-direction: column;
`;

const DupeAndCloseContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Preview = styled('div')`
  cursor: pointer;
  color: #1ca4fc;
  font-family: 'Neue Haas Grotesk Display Std', sans-serif;
  font-size: 0.75rem;
  font-weight: normal;
  margin-top: auto;
  text-align: right;
`;

const DupeAndClose = (props) => (
  <MoveClosePreview>
    <DupeAndCloseContainer>
      <Dupe onClick={props.onDupeClick} data-module-id={props.moduleId} />
      <Close data-module-id={props.moduleId} onClick={props.onRemoveModule} />
    </DupeAndCloseContainer>
    <Preview data-order={props.order} onClick={props.onPreviewClick}>
      Preview Module
    </Preview>
  </MoveClosePreview>
);

const OrderAndType = (props) => (
  <OrderAndTypeContainer>
    <Order onClick={props.onOrderClick}>{`${props.order}. `}</Order>
    <Type>{MODULE_TYPES_DISPLAY[props.moduleType]}</Type>
    <TypeImg
      backgroundImage={props.typeImg}
      data-order={props.order}
      onClick={props.onPresentationClick}
    />
  </OrderAndTypeContainer>
);

const MoveModalOverlay = styled(SettingsOverlay)`
  background: rgba(15, 43, 61, 0.63);
`;

const MoveModalBody = styled('div')`
  position: absolute;
  width: 14rem;
  height: 16.875rem;
  background: #f2f2f2;
  top: 1.875rem;
  left: 6.25rem;
  padding: 0.625rem;
  box-shadow: 0.125rem 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
`;

const MoveModalClose = styled(Close)`
  position: absolute;
  right: 0.625rem;
  top: 0.625rem;
`;

const MoveModalTitle = styled('div')`
  color: #000000;
  font-family: 'Neue Haas Grotesk Display Std', sans-serif;
  font-size: 2.25rem;
  font-weight: bold;
  margin-bottom: 1.125rem;
`;

const MoveModalButton = styled(Button)`
  font-weight: normal;
`;

class ModuleCard extends Component {
  state = {
    moveModalActive: false,
    currentOrder: '',
  };
  showMoveModal = () => {
    const { order } = this.props;
    this.setState({ moveModalActive: true, currentOrder: order });
  };
  hideMoveModal = () => {
    this.setState({ moveModalActive: false });
  };
  onMoveClick = () => {
    const { onMoveClick, order, moduleId } = this.props;
    const { currentOrder } = this.state;
    onMoveClick && onMoveClick({ order, moduleId, currentOrder });
    this.hideMoveModal();
  };
  setCurrentOrder = (e) => {
    const currentOrder = e.currentTarget.value;
    this.setState({ currentOrder });
  };
  onMouseEnter = () => {
    this.setState({ allowMove: false });
  };
  onMouseLeave = () => {
    this.setState({ allowMove: true });
  };
  renderModuleCard() {
    const {
      order,
      moduleType,
      question,
      questionData,
      moduleId,
      onRemoveModule,
      connectDragSource,
      handleChange,
      values,
      setValues,
      setFieldValue,
      key,
      onPreviewClick,
      onPresentationClick,
      onDupeClick,
      onOrderClick,
      onMoveClick,
    } = this.props;
    const { moveModalActive, currentOrder } = this.state;
    const typeImg = TypeImgs[moduleType];
    return (
      <ModuleContainer key={key}>
        <Row>
          <OrderAndType
            order={order}
            moduleType={moduleType}
            typeImg={typeImg}
            onPresentationClick={onPresentationClick}
            onOrderClick={this.showMoveModal}
          />
          <QuestionDetails
            question={question}
            questionData={questionData}
            moduleId={moduleId}
            moduleType={moduleType}
            handleChange={handleChange}
            values={values}
            setValues={setValues}
            setFieldValue={setFieldValue}
            onMouseEnter={this.onMouseEnter}
            onMouseLeave={this.onMouseLeave}
          />
          <DupeAndClose
            onRemoveModule={onRemoveModule}
            moduleId={moduleId}
            connectDragSource={connectDragSource}
            onPreviewClick={onPreviewClick}
            order={order}
            onDupeClick={onDupeClick}
          />
        </Row>
        <MoveModalOverlay active={moveModalActive} onClick={this.hideMoveModal}>
          <MoveModalBody onClick={(e) => e.stopPropagation()}>
            <MoveModalClose onClick={this.hideMoveModal} />
            <MoveModalTitle>Move to Position:</MoveModalTitle>
            <DetailLong
              placeholder="Enter Number"
              value={currentOrder}
              onChange={this.setCurrentOrder}
              fontSize="3.75rem"
              fontWeight="bold"
            />
            <MoveModalButton onClick={this.onMoveClick}>
              Move Module
            </MoveModalButton>
          </MoveModalBody>
        </MoveModalOverlay>
      </ModuleContainer>
    );
  }
  render() {
    const { connectDragSource, connectDropTarget } = this.props;
    if (this.state.allowMove)
      return connectDragSource(
        connectDropTarget(<div>{this.renderModuleCard()}</div>)
      );
    return <div>{this.renderModuleCard()}</div>;
  }
}

export const MODULE_CARD = 'MODULE_CARD';

const dragSourceCollect = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
});

const dropTargetCollect = (connect, monitor) => ({
  highlighted: monitor.canDrop(),
  hovered: monitor.isOver(),
  connectDropTarget: connect.dropTarget(),
});

const dragSourceSpec = {
  canDrag(props) {
    return props.isReady !== void 0 ? props.isReady : true;
  },
  beginDrag(props, monitor, component) {
    return { moduleId: props.moduleId };
  },
};
const dropTargetSpec = {
  drop(props, monitor, component) {
    if (!component) return null;
    const dragIndex = monitor.getItem().moduleId;
    const hoverIndex = props.moduleId;
    if (dragIndex === hoverIndex) return;
    props.moveCard && props.moveCard({ dragIndex, hoverIndex });
  },
};

export default DropTarget(
  MODULE_CARD,
  dropTargetSpec,
  dropTargetCollect
)(DragSource(MODULE_CARD, dragSourceSpec, dragSourceCollect)(ModuleCard));
// todo: () => <Formik onSubmit={(values) => values} component={Component} />
// recieves props values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting
