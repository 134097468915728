import { getUserName } from '../Helper';

//Used so that the bubbles are 1 point away from the x-axis
const DEFAULT_CHART_Y_VALUE = 1;
// frequency is our bubble property so that the bubble size representing value 1 is always constant
const DEFAULT_FREQUENCY_VALUE = 1;

const getComments = ({ userResponses, moduleList, moduleId }) => {
  const responses = Object.values(userResponses);
  return responses.reduce((acc, response, currentIndex) => {
    const answerData = response.answerData;
    const commentTime = response?.endSurveyTimestamp?.seconds;
    const moduleResponse = answerData[moduleId];
    if (!moduleResponse) return acc;
    const index = currentIndex + 1;
    const name = getUserName(response.userName, answerData, moduleList, index);
    const nameLabel = `${name}-${currentIndex}`;
    const explanation = moduleResponse.explanation || '';
    return [
      ...acc,
      {
        name: name,
        label: nameLabel,
        comment: explanation,
        responseId: response.responseId,
        time: commentTime,
      },
    ];
  }, []);
};

const formatSpectrumDataFromResponses = (
  userResponses,
  moduleList,
  moduleId
) => {
  const responses = Object.values(userResponses);
  return responses.reduce(
    (acc, response, currentIndex) => {
      const answerData = response.answerData;
      const moduleResponse = answerData[moduleId];
      if (!moduleResponse) return acc;
      const responseId = response.responseId;
      const index = currentIndex + 1;
      const name = getUserName(
        response.userName,
        answerData,
        moduleList,
        index
      );
      const nameLabel = `${name}-${currentIndex}`;
      const explanation = moduleResponse.explanation || '';
      let value = moduleResponse.spectrumValue;
      if (!value) {
        return {
          ...acc,
          participantsData: {
            ...acc.participantsData,
            [nameLabel]: {
              data: [],
              explanation,
            },
          },
        };
      }
      value = parseInt(value, 10);
      return {
        ...acc,
        participantsData: {
          ...acc.participantsData,
          [nameLabel]: {
            data: [
              {
                name,
                value,
                y: DEFAULT_CHART_Y_VALUE,
                frequency: DEFAULT_FREQUENCY_VALUE,
                responseId,
              },
            ],
            explanation,
          },
        },
      };
    },
    {
      participantsData: {},
    }
  );
};

const getFormattedDataWithFrequency = (data) => {
  const frequencyData = Object.values(data).reduce((acc, currentValue) => {
    const { data, explanation } = currentValue;
    if (data.length === 0) return acc;
    const { name, value, responseId } = data[0];
    acc[value] = {
      ...(acc[value] || {}),
      x: value,
      y: DEFAULT_CHART_Y_VALUE,
      explanation,
      frequency: (acc[value] && acc[value].frequency + 1) || 1,
      members: [...((acc[value] && acc[value].members) || []), name],
      users: [...((acc[value] && acc[value].users) || []), responseId],
    };
    return {
      ...acc,
    };
  }, {});
  return Object.values(frequencyData);
};

const getSpectrumData = (userResponses, moduleList, moduleId) => {
  const { participantsData } = formatSpectrumDataFromResponses(
    userResponses,
    moduleList,
    moduleId
  );
  const chartData = getFormattedDataWithFrequency(participantsData);

  return {
    chartData,
  };
};

const getSelectedParticipantData = (
  responses,
  selectedParticipantResponseId,
  moduleId
) => {
  if (!selectedParticipantResponseId) return null;
  const value =
    responses[selectedParticipantResponseId]?.answerData[moduleId][
      'spectrumValue'
    ];
  if (!value) return null;

  return [
    {
      value: parseInt(value, 10),
      y: DEFAULT_CHART_Y_VALUE,
      frequency: DEFAULT_FREQUENCY_VALUE,
    },
  ];
};

const getSelectedBubbleData = (value) => {
  if (!value) return null;
  return [
    {
      value: parseInt(value, 10),
      y: DEFAULT_CHART_Y_VALUE,
      frequency: DEFAULT_FREQUENCY_VALUE,
    },
  ];
};

export { getSpectrumData, getComments };
export default { getSelectedParticipantData, getSelectedBubbleData };
