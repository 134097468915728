import React from 'react';
import '@reach/dialog/styles.css';
import styled from 'styled-components';
import { HILOS } from 'const/index';
import { HiloDialog } from 'components/Common/HiloDialog';
import { HiloParagraph } from 'components/Common/Typography';
import {
  HiloDialogContentContainer,
  HiloDialogTextContainer,
} from 'components/Common/HiloDialog/styles';
import { HiloButton } from 'components/Common/Button';

const Cross = ({ hilo = HILOS.SURVEY }) => (
  <svg
    width="24"
    height="30"
    viewBox="0 0 24 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      y1="-1"
      x2="25.0805"
      y2="-1"
      transform="matrix(0.606927 -0.606928 0.606927 0.606928 4.677 29.2734)"
      stroke={hilo === HILOS.SURVEY ? '#1CA4FC' : '#FFA536'}
      strokeWidth="2"
    />
    <line
      y1="-1"
      x2="25.0805"
      y2="-1"
      transform="matrix(-0.606928 -0.606927 0.606928 -0.606927 19.8992 28.0052)"
      stroke={hilo === HILOS.SURVEY ? '#1CA4FC' : '#FFA536'}
      strokeWidth="2"
    />
  </svg>
);

export const Right = styled('div')`
  display: flex;
  margin-right: 25px;
  padding-left: 15px;
  justify-content: flex-end;
  cursor: pointer;
`;

const Container = styled('div')`
  width: ${(props) => props.width || '20px'};
  height: ${(props) => props.height || '32px'};
`;

const ButtonContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: ${(props) => (props.hilo === HILOS.SURVEY ? '40' : '0')}px 0;
`;

const getText = ({ hilo }) => {
  if (hilo === HILOS.SURVEY) {
    return `
        Build interactive form in Hilo with the survey builder.
        Add questions, polls, images and more to your survey to
        create fun and engaging forms asynchronous data collection.
        `;
  } else {
    return `
        Build interactive workshops with the Hilo builder mode. 
        Share your workshop with an audience that uses their 
        devices respond to your prompts. 
        Then visualize their responses in real-time to create a 
        fun and interactive experience.
        `;
  }
};

/**
 * Dialog used to create a workshop or survey
 * @param hilo
 * @param isOpen
 * @param onDismiss
 * @param onClickCreate
 * @returns {JSX.Element}
 * @constructor
 */
const CreateHiloDialog = ({
  hilo,
  isOpen = false,
  onDismiss,
  onClickCreate,
}) => {
  return (
    <HiloDialog
      ariaLabel="Creating a Hilo"
      isOpen={isOpen}
      onDismiss={onDismiss}
      dialogBorder={`4px solid ${
        hilo === HILOS.SURVEY ? '#1ca4fc' : '#FFA536'
      };`}
    >
      <HiloDialogContentContainer>
        <Right>
          <Container onClick={onDismiss}>
            <Cross hilo={hilo} />
          </Container>
        </Right>
        <HiloDialogTextContainer marginBottom="60">
          <HiloParagraph color={hilo === HILOS.SURVEY ? '#1ca4fc' : '#FFA536'}>
            {getText({ hilo })}
          </HiloParagraph>
        </HiloDialogTextContainer>
        <ButtonContainer hilo={hilo} onClick={() => onClickCreate({ hilo })}>
          <HiloButton color={hilo === HILOS.SURVEY ? '#1ca4fc' : '#FFA536'}>
            Create {hilo}
          </HiloButton>
        </ButtonContainer>
      </HiloDialogContentContainer>
    </HiloDialog>
  );
};

export default CreateHiloDialog;
