import React from "react";
import styled from "styled-components";

const NoDataContainer = styled("div")`
  display: flex;
  width: 100%;
  justify-content: center;
  margin: auto;
`;

const NoData = () => <NoDataContainer>No data available</NoDataContainer>;

export default NoData;
