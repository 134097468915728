import { useEffect, useState } from 'react';
import { db } from '../firebase';
import { getUserResponseReducer } from 'lib/survey-api';

const surveysCollection = db.collection('surveys');

const useUserResponses = ({
  surveyId,
  responseId,
  showAllUsers,
  showHidden = false,
}) => {
  const [userResponses, setUserResponses] = useState({});

  let unsubscribe = null;

  useEffect(() => {
    unsubscribe = surveysCollection
      .doc(surveyId)
      .collection('response')
      .onSnapshot((snapshot) => {
        const responses = snapshot.docs.reduce(
          getUserResponseReducer(showHidden),
          {}
        );
        setUserResponses(responses);
      });
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [surveyId]);

  return userResponses;
};

export default useUserResponses;
