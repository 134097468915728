import React, { useEffect, useState } from 'react';
import {
  VictoryAxis,
  VictoryBar,
  VictoryLabel,
  VictoryChart,
  VictoryGroup,
  VictoryTooltip,
} from 'victory';
import { DefaultTooltip } from '../common/Tooltips';
import FlyOut from '../common/Tooltips/Flyout';
import { chartMouseEvents } from '../../../lib/Events';
import { colors } from '../../../const/theme';
import { LabelTextComp, LabelTspanComp } from '../common/others';
import CustomBar from './CustomBar';
import {
  setClickedChartElementOptionKey,
  setClickedChartElementResponseIds,
  setCommentsPanelVisibility,
  setPanelComments,
  setSelectedParticipantResponseId,
  setSelectedPointKey,
} from 'pages/presentationSlice';
import { useDispatch, useSelector } from 'react-redux';

const chartRightPadding = 5;

const calculateChartHeight = (data, participantData) => {
  const pLength = (participantData && participantData.data.length) || 0;
  const dLength = (data && data.length) || 0;
  const dataLength = dLength + pLength;
  if (dataLength <= 5) return 200;
  return 300;
};

const getParticipantData = (data) => {
  return data.map((datum) => {
    let points = datum.points;
    if (points === 0) points = points + 0.2;
    return {
      ...datum,
      points,
    };
  });
};

const showChart = (value) => value && value.data.length > 0;

const HorizontalBarChart = ({
  data,
  moduleId,
  selectedPoint,
  selectedParticipantData = null,
}) => {
  const dispatch = useDispatch();

  const comments = useSelector((state) => state.presentation.comments);

  const onClickBar = (datum) => {
    const responseIds = datum.users;
    // Set this to null so that the highlight on View selection
    // In the comment section does not conflict with the bar click.
    dispatch(setSelectedPointKey({ moduleId, selectedPoint: datum.option }));
    dispatch(setSelectedParticipantResponseId(null));
    dispatch(setClickedChartElementOptionKey(datum.option));
    dispatch(setClickedChartElementResponseIds(responseIds));
    dispatch(setCommentsPanelVisibility(true));
    dispatch(setPanelComments(comments));
  };

  return (
    <VictoryChart
      domainPadding={{ x: [30, 30] }}
      padding={{ top: 0, bottom: 30, left: 90, right: 5 }}
      height={250}
      width={500}
    >
      <VictoryAxis
        style={{ axis: { stroke: 'transparent' } }}
        tickLabelComponent={
          <VictoryLabel
            textComponent={<LabelTextComp />}
            tspanComponent={<LabelTspanComp />}
            renderInPortal
          />
        }
      />
      <VictoryAxis
        dependentAxis
        label="Average points"
        alignment="start"
        crossAxis={false}
        style={{
          axis: { stroke: 'black' },
          axisLabel: {
            fontSize: 8,
            fontFamily: 'Roboto',
            fontWeight: 'bold',
            padding: 20,
          },
          ticks: { stroke: 'black', size: -5, angle: 90 },
          tickLabels: { fontSize: 8, fontFamily: 'Roboto', fontWeight: 400 },
        }}
      />
      <VictoryGroup offset={7}>
        {showChart(selectedParticipantData) && (
          <VictoryBar
            events={[chartMouseEvents(true, null, onClickBar)]}
            data={getParticipantData(selectedParticipantData.data)}
            dataComponent={<CustomBar />}
            style={{ data: { fill: colors.SELECTED_CHART_FILL_COLOR } }}
            labels={() => ''}
            labelComponent={
              <VictoryTooltip
                flyoutComponent={
                  <FlyOut
                    chartRightPadding={chartRightPadding}
                    isSelected
                    isBar
                  />
                }
              />
            }
            horizontal
            barWidth={5}
            x="option"
            y="points"
          />
        )}
        <VictoryBar
          events={[chartMouseEvents(true, null, onClickBar)]}
          data={data}
          style={{
            data: {
              fill: (bar) => {
                if (bar.datum.xName === selectedPoint)
                  return colors.SELECTED_CHART_FILL_COLOR;
                return colors.PRIMARY_CHART_FILL_COLOR;
              },
            },
          }}
          labels={({ datum }) => `Avg: ${datum.points}`}
          labelComponent={<DefaultTooltip />}
          horizontal
          barWidth={5}
          x="option"
          y="points"
        />
      </VictoryGroup>
    </VictoryChart>
  );
};

export default HorizontalBarChart;
