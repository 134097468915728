import React, { useContext, useEffect, useState } from 'react';
import { AuthUserContext } from 'components/auth-context';
import { useHistory, useLocation } from 'react-router-dom';
import { ADMIN, SURVEYROOT } from 'const/routes';
import Layout, { Button } from 'components/layout';
import { fetchSurveyByCode } from 'lib/admin-api';
import { logSurveyAttempt } from 'lib/landing-api';
import Tracking from 'lib/tracking/tracking';
import { surveyTakerEvents } from 'lib/tracking/events';
import {
  BodyContent,
  Description,
  EnterCode,
  Welcome,
} from 'pages/Landing/styles';

const LandingPage = () => {
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadError, setLoadError] = useState(false);

  const authContext = useContext(AuthUserContext);

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    checkForRedirect();
    return () => checkForRedirect();
  });

  const checkForRedirect = () => {
    const stopRedirect = location.pathname === '/survey';
    if (
      !stopRedirect &&
      !authContext.loading &&
      authContext.authUser &&
      authContext.canEdit
    ) {
      console.log('auth user-landing-page:::', authContext.authUser);
      history.push(ADMIN);
    }
  };

  const updateCode = (e) => {
    const code = (e.currentTarget.value || '').toUpperCase();
    setCode(code.trim());
    Tracking.event({ name: surveyTakerEvents.ENTER_SURVEY_CODE });
  };

  const getStarted = (e) => {
    const surveyCode = code;
    setLoading(true);
    const userId = Tracking.getDistinctId();
    Tracking.identifyUser({
      userId,
      userAttributes: {
        $name: userId,
        $email: `${userId}@if.com`, // fix to get users in mix panel profile page
      },
    });
    return fetchSurveyByCode({ surveyCode }).then((survey) => {
      if (survey.empty) {
        logSurveyAttempt({ surveyCode, surveyExists: false });
        setLoading(false);
        setLoadError(true);
        return;
      }

      logSurveyAttempt({ surveyCode, surveyExists: true });

      Tracking.event({
        name: surveyTakerEvents.START_SURVEY,
        properties: {
          surveyCode,
        },
      });

      return history.push(`${SURVEYROOT}/${surveyCode.toUpperCase()}/1`);
    });
  };

  const detectEnter = (e) => {
    if (e.keyCode === 13) {
      Tracking.event({ name: surveyTakerEvents.SURVEY_CODE_ENTER_KEYBOARD });
      return getStarted();
    }
  };

  const onStartClick = () => {
    Tracking.event({ name: surveyTakerEvents.SURVEY_CODE_CLICK_START_BUTTON });
    return getStarted();
  };

  const welcome = loadError ? 'Hmmmm...' : 'Welcome.';

  if (welcome === 'Welcome.') {
    Tracking.event({
      name: surveyTakerEvents.VISIT_LANDING_PAGE,
      properties: { success: true },
    });
  } else {
    Tracking.event({
      name: surveyTakerEvents.VISIT_LANDING_PAGE,
      properties: { success: false },
    });
  }

  const description = loadError
    ? "That code doesn't seem to work. Please try again or ask the facilitator for a new code."
    : "This is an interactive survey we'll be using to gather information from you and your team.";
  const active = code.length > 0 && !loading;

  return (
    <Layout background="#1CA4FC">
      <BodyContent loading={authContext?.loading}>
        <Welcome>{welcome}</Welcome>
        <Description>{description}</Description>
        <EnterCode
          placeholder="Enter code"
          onChange={updateCode}
          onKeyDown={detectEnter}
          value={code}
        />
        <Button
          background="#FF9B3A"
          pointerEvents={active ? 'auto' : 'none'}
          onClick={onStartClick}
        >
          Start
        </Button>
      </BodyContent>
    </Layout>
  );
};

export default LandingPage;
