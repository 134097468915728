import ids from 'short-id';
import {
  MULTI_SELECT,
  OPTION_PREFIX,
  PERSONAL_INFO,
  POINT_DISTRIBUTION,
  STACK_RANK,
} from 'const/module-types';
import { getUserName } from 'lib/Presentation';

const getAddOptions = ({ userResponses, moduleId }) => {
  const responses = Object.values(userResponses);
  const responseIds = Object.keys(responses);
  let allValues = [];
  // we use key for this module, since we can have items with same id
  // since options also come from the user
  responseIds.forEach((responseId) => {
    const optionsArray = responses[responseId]?.answerData[moduleId];
    const userResponseId = responses[responseId]?.responseId;
    if (optionsArray) {
      const getModuleOptions = Object.entries(optionsArray)
        .filter(
          ([key, value]) => key.indexOf(OPTION_PREFIX) === 0 && value.selected
        )
        .map(([key, value]) => ({
          name: value.value,
          id: key,
          key: `${key}-${userResponseId}`,
          author: 'user',
          responseId: userResponseId,
        }));

      allValues = allValues.concat(getModuleOptions);
    }
  });
  return allValues;
};

const getUserComments = ({
  userResponses,
  moduleId,
  questionData,
  moduleList,
}) => {
  if (!userResponses || !moduleList || !moduleId || !questionData) return null;
  const responses = Object.values(userResponses);
  const options = getAddOptions({ userResponses, moduleId });

  return responses.reduce((acc, response, currentIndex) => {
    const answerData = response.answerData;
    const moduleResponse = answerData[moduleId];
    const moduleResponseArray = Object.values(moduleResponse || {});

    const hasTrueOption = moduleResponseArray.some(
      (resp) => resp.selected === true
    );
    if (!moduleResponse || !hasTrueOption) return acc;
    const index = currentIndex + 1;
    const name = getUserName(
      response.userName,
      answerData,
      Object.values(moduleList),
      index
    );
    const nameLabel = `${name}-${currentIndex}`;
    const optionsData = options.reduce((accumulator, option) => {
      const data = moduleResponse[option.key];
      if (!data) return accumulator;
      const key = option.key;
      return [
        ...accumulator,
        {
          key,
          title: option.title,
          selected: data.selected || false,
          comment: data.comment || null,
        },
      ];
    }, []);
    return [
      ...acc,
      {
        name,
        label: nameLabel,
        data: optionsData,
        responseId: response.responseId,
      },
    ];
  }, []);
};

const getModuleResponses = (responses, moduleId, userInfoModuleId) => {
  return Object.values(responses)
    .map((response = {}, index) => {
      const { userName, answerData, responseId } = response;
      return {
        username: answerData[userInfoModuleId]?.name ?? `${userName} ${index + 1}`, // prettier-ignore
        answers: answerData[moduleId],
        responseId,
      };
    })
    .filter((response) => !!response.answers);
};

const chartData = (options, moduleResponses) => {
  return options
    .map((option) => {
      const users = moduleResponses.filter(({ answers }) => {
        const answer = answers[option.key];
        return answer?.selected;
      });
      return {
        ...option,
        count: users.length,
        users,
      };
    })
    .sort((a, b) => b.count - a.count);
};

const getPersonalInfoModule = ({ modules }) => {
  const module = modules.filter(
    ({ moduleType }) => moduleType === PERSONAL_INFO
  );
  if (module && modules.length !== 0) return module[0];
  return null;
};

const getChartData = ({ userResponses, moduleId, modules, questionData }) => {
  const personalInfoModuleId = getPersonalInfoModule({ modules });
  const moduleResponses = getModuleResponses(userResponses, moduleId, personalInfoModuleId); // prettier-ignore
  const data = chartData(getAddOptions({ questionData }), moduleResponses);
  return {
    chartData: data,
    moduleResponsesLength: moduleResponses.length,
  };
};

/**
 * Since we have options from the users in the Add option module,
 * we should be able to get the options from there too and
 * show them in the confirmation dialog.
 * @param options
 * @param optionsIds
 * @returns {*[]}
 */
const getAddOptionUserOptionsNames = (options = [], optionsIds = []) => {
  if (!options || !optionsIds) return [];
  return options
    .filter((option) => optionsIds.includes(option.key))
    .map((option) => option.name);
};

/**
 * Format the Add option options in a way that they can be added to the next
 * module that is going to display them(populated module)
 * @param options Add option user options
 * @param populatedModuleType The module in which the options are going to be populated
 * i.e Stack rank, multi select or point distribution
 * @param selectedPopulateOptionsIds An array with the selected options by the facilitator,
 * we need to only format the options chosen by the facilitator.
 */
const getOptionsForNextModuleFromAddOption = (
  options,
  populatedModuleType,
  selectedPopulateOptionsIds
) => {
  if (
    !options ||
    ![STACK_RANK, MULTI_SELECT, POINT_DISTRIBUTION].includes(
      populatedModuleType
    )
  )
    return {};
  const formattedOptions = options
    .filter((option) => selectedPopulateOptionsIds.includes(option.key))
    .map((option) => ({
      key: `option${ids.generate()}`,
      title: option.name,
    }))
    .reduce(
      (acc, option) => ({
        ...acc,
        [option.key]: { title: option.title, key: option.key },
      }),
      {}
    );
  const moduleMap = {
    [STACK_RANK]: Object.entries(formattedOptions).reduce(
      (acc, [key, value]) => {
        return {
          ...acc,
          [key]: value.title,
        };
      },
      {}
    ),
    [MULTI_SELECT]: Object.entries(formattedOptions).reduce(
      (acc, [key, value]) => {
        return {
          ...acc,
          [key]: { key, title: value.title },
        };
      },
      {}
    ),
    [POINT_DISTRIBUTION]: Object.entries(formattedOptions).reduce(
      (acc, [key, value]) => {
        return {
          ...acc,
          [key]: value.title,
        };
      },
      {}
    ),
  };
  return moduleMap[populatedModuleType];
};

export {
  getUserComments,
  getChartData,
  getAddOptions,
  getAddOptionUserOptionsNames,
  getOptionsForNextModuleFromAddOption,
};
