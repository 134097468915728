import styled, { keyframes } from 'styled-components';
import { ButtonBase } from '../../buttons';

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  margin-top: -5px;
  margin-left: ${(props) => props.isCommentPanelOpen && '-20%'};
`;

const fadeIn = ({ value }) => keyframes`
  from {width: 0px};
  to {width: ${value}px};
`;

export const Bar = styled('div')`
  border-radius: 20px;
  :hover {
    background: ${({ isStack }) => !isStack && '#0069ad'};
  }
  height: 20px;
  width: ${(props) => props.width}px;
  background: ${(props) => (props.selected ? '#FD9E46' : '#1CA4FC')};
  margin-top: 10px;
  display: ${(props) => (props.width > 0 ? 'block' : 'none')};
  cursor: ${({ isStack }) => !isStack && 'pointer'};
  animation: ${fadeIn((props) => props.width)} 2s ease forwards;
`;

export const Label = styled('p')`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  margin: 0;
  color: ${({ hasCount, isStack }) =>
    (hasCount, isStack) ? '#000000' : '#FF7086'};
`;

export const Count = styled('p')`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  margin: 0;
  line-height: ${(props) => (props.hasCount ? '18px' : '25px')};
  color: ${({ hasCount, isStack }) =>
    (hasCount, isStack) ? '#000000' : '#FF7086'};
`;

export const BarContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-left: 32px;
`;

export const Item = styled('div')`
  position: static;
  display: flex;
  align-items: flex-end;
  margin-top: ${(props) => (props.hasCount ? '30px' : '40px')};
`;

export const ChartContainer = styled('div')`
  margin: ${({ hasResponse, isStack }) =>
    (hasResponse || isStack) && '20px auto 0px 18%'};
  padding-bottom: 50px;
  align-self: ${(props) => !props.hasResponse && 'center'};
`;

export const ExpandButton = styled(ButtonBase)`
  stroke: #fff;
  background: #1ca4fc;
  border-radius: 99999px;
  width: 22px;
  height: 22px;
  margin-left: 30px;
  :hover {
    background: #fd9e46;
  }
`;

export const StatsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 301px;
  margin-top: 20px;
  filter: drop-shadow(0px 0px 6px rgba(28, 164, 252, 0.3));
  background: #ffffff;
  border-radius: 5px;
  padding: 8px;
  align-self: end;
`;

export const StatsText = styled('div')`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: #1ca4fc;
  margin: 4px 0 10px 5px;
  text-transform: none;
`;
