import React, { useEffect, useState } from 'react';

import { ReactComponent as AddIcon } from 'img/addOpen.svg';
import { ReactComponent as RemoveIcon } from 'img/removeOpen.svg';

import { getNestedData } from '../../../const/nested-data';
import { OPTION_MAX, OPTION } from '../../../const/module-types';
import { usePrevious } from 'components/utils/usePrevious';

import {
  OpenOptionsContainer,
  Row,
  InfoText,
  Input,
  CharLeft,
  Icon,
} from './styles';

const OptionsField = ({
  option,
  optionKey,
  index,
  isNextItem,
  isLastItem,
  isFirstItem,
  onChange,
  onAdd,
  onRemove,
  disabled,
  data,
}) => {
  const MAX_LENGTH = 50;

  return (
    <div
      index={index}
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '-20px',
      }}
    >
      <Row>
        <Input
          type="text"
          placeholder="Type your option"
          maxLength={MAX_LENGTH}
          onChange={onChange}
          name={`add_option${optionKey}`}
          id={`add_option${optionKey}`}
          value={data.selected ? data.value : option}
          autoFocus
          required
          onKeyPress={(e) => e.key === 'Enter' && onAdd(index)}
        />
        {isNextItem || isFirstItem || isLastItem ? (
          <Icon onClick={() => onRemove(index)}>
            {' '}
            <RemoveIcon />{' '}
          </Icon>
        ) : (
          <Icon style={{ visibility: 'hidden' }} />
        )}
        {isLastItem ? (
          <Icon onClick={() => onAdd(index)} disabled={disabled}>
            {' '}
            <AddIcon />{' '}
          </Icon>
        ) : (
          <Icon style={{ visibility: 'hidden' }} />
        )}
      </Row>
      <CharLeft>
        {' '}
        {Number(MAX_LENGTH - option.length)} characters left{' '}
      </CharLeft>
    </div>
  );
};

export const AddOptions = ({
  moduleId,
  questionData,
  inPresentationMode,
  module,
  formikProps,
  inMobileView,
  data,
}) => {
  const { values, setFieldValue } = formikProps;
  const selectedOptions = getNestedData(values, `addOption.${moduleId}`) || {}; // prettier-ignore
  const selectedOptionsArray = Object.values(selectedOptions);
  const selectedValues = [];
  selectedOptionsArray.forEach((opt) => {
    if (opt?.selected === true && opt?.value !== undefined)
      selectedValues.push(opt.value);
  });

  const prevModuleId = usePrevious(moduleId);
  useEffect(() => {
    if (prevModuleId !== moduleId)
      setItems(selectedValues.length === 0 ? [''] : selectedValues);
  }, [moduleId, prevModuleId]);

  const [items, setItems] = useState(selectedValues);
  const [charNum, setCharNum] = useState(50);

  const maxOptionCountValue = module.questionData[OPTION_MAX]; // getNestedData(formikProps, maxOptionkey);
  const isMaxOptionEnabled = module.questionData[OPTION];

  if (!questionData || inPresentationMode) return null;

  const onChange = (e, index) => {
    const optionKey = `addOption.${moduleId}.option${index}`;
    // Option input
    const { value } = e.target;
    const newItems = items.map((item, idx) => {
      if (idx === index) {
        return value;
      }
      return item;
    });
    setItems(newItems);
    const newValue = { value, selected: true };
    setFieldValue(optionKey, newValue);
  };

  const onAdd = (index) => {
    if (isMaxOptionEnabled && maxOptionCountValue) {
      // do something for when the option is limited
      if (items.length < maxOptionCountValue) {
        const newItems = items.concat('');

        setItems(newItems);
      }
    } else {
      const newItems = items.concat('');

      setItems(newItems);
    }
  };

  const onRemove = (index) => {
    const optionKey = `addOption.${moduleId}.option${index}`;
    const data = getNestedData(values, optionKey) || {};
    if (items.length !== 1) {
      const newItems = items.filter((item, idx) => idx !== index);
      // const updatedValue = {valuedata.selected}
      setFieldValue(optionKey, { ...data, selected: false });
      setItems(newItems);
    }
  };

  return (
    <OpenOptionsContainer {...{ inMobileView }}>
      <InfoText>
        {' '}
        {isMaxOptionEnabled && maxOptionCountValue > 0
          ? `${items.length}/${maxOptionCountValue} options added`
          : `You can add as many options as you want`}{' '}
      </InfoText>
      {items.map((item, index) => {
        const optionKey = `addOption.${moduleId}.option${index}`;
        const data = getNestedData(values, optionKey) || {};
        const isLastItem = index === items.length - 1;
        const isNextItem = index > 0 && !isLastItem;
        const isFirstItem = index === 0;
        return (
          <OptionsField
            key={optionKey}
            option={item}
            optionKey={optionKey}
            index={index}
            isNextItem={isNextItem}
            isLastItem={isLastItem}
            isFirstItem={isFirstItem}
            charNum={charNum}
            onChange={(e) => onChange(e, index)}
            onAdd={onAdd}
            onRemove={onRemove}
            disabled={items.length === maxOptionCountValue}
            data={data}
          />
        );
      })}
    </OpenOptionsContainer>
  );
};

export default AddOptions;
