import styled from 'styled-components';
import { Plus } from 'components/layout';
import PlusBlue from '../../../img/add-blue.svg';
import PlusImg from '../../../img/plus-white.svg';

export const styles = {
  subContainer: {
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    background: '#FFF',
    padding: '2rem',
    width: '32%',
    height: '100%',
    maxWidth: '450px',
    boxShadow: '-6px 0px 10px rgba(28, 164, 252, 0.2)',
    borderRadius: '20px',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  actionBtn: {
    marginLeft: 'auto',
    background: '#FFF',
    boxShadow: '0px 1.24444px 4.97778px rgba(28, 164, 252, 0.3)',
    borderRadius: '62px',
    padding: '8px 32px',
    fontSize: '12px',
    color: '#1CA4FC',
    fontWeight: 500,
    fontFamily: 'Roboto',
  },
  btnsContainer: {
    marginTop: 'auto',
    display: 'flex',
  },
  optionContainer: {
    border: '1px solid #1CA4FC',
    borderRadius: '100px',
    padding: '6px 16px',
    display: 'flex',
    // marginBottom: '40px',
    alignItems: 'center',
    cursor: 'pointer',
    maxWidth: 'max-content',
  },
  optionCheckboxChecked: {
    backgroundColor: '#1CA4FC',
    display: 'flex',
    flex: 1,
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
  },
  optionName: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '20px',
    color: '#1CA4FC',
    margin: 0,
    maxWidth: '200px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  addBtn: {
    marginRight: 'auto',
    marginLeft: '0',
    fontSize: '14px',
    fontWeight: '600',
    padding: '6px 15px 6px 35px',
    '&:hover': {
      color: '#FFF',
      background: '#1CA4FC',
    },
  },
  sortBtn: {
    position: 'relative',
    marginLeft: '20px',
    fontSize: '14px',
    fontWeight: '600',
    padding: '6px 15px 6px 35px',
  },
  sortIcon: {
    position: 'absolute',
    left: '15px',
    top: '9px',
    height: '16px',
    width: '15px',
  },
};

export const EditOption = styled('p')`
  display: none;
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  &:hover {
    color: #ff9736;
  }
`;

export const OptionFull = styled('div')`
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
  &:active,
  &:hover {
    ${EditOption} {
      display: inline;
    }
  }
`;

export const AddIcon = styled(Plus)`
  position: absolute;
  margin-right: 70px;
  margin-left: 0;
  top: 10px;
  height: 12px;
  width: 13px;
  background-image: url('${PlusBlue}');
`;

export const AddOption = styled.button`
  position: relative;
  min-width: 126px;
  outline: 0;
  border: 0;
  background: transparent;
  padding: 0;
  &:hover {
    ${AddIcon} {
      background-image: url('${PlusImg}');
    }
  }
`;
