export const styles = {
  containerOverlay: {
    display: 'inline',
    flexDirection: 'column',
    zIndex: '100',
    position: 'absolute',
    top: '61%',
    // left: '40%',
    right: '-21px',
    height: 'fit-content',
    border: '5px solid #F1F2F5',
    borderRadius: '8px',
    backgroundColor: '#F1F2F5',
    padding: '2px 2px 10px',
    width: '69%',
    maxWidth: '300px',
  },
  addForm: {
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    height: '50px',
    border: 'none',
    boxShadow: 'inset 1px 0px 9px 0px #919293a8',
    outline: 'none',
    borderRadius: '5px',
    padding: '0 15px',
    fontSize: '18px',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: '30px',

    button: {
      border: '0.125rem solid',
      padding: '1px 0px',
      width: '80px',
      borderRadius: '6px',
    },
  },
  addBtn: {
    borderColor: '#1CA4FC',
    color: '#1CA4FC',
    '&:disabled': {
      color: 'grey',
    },
  },
  delBtn: {
    borderColor: '#ff7086',
    color: '#ff7086',
  },
};
