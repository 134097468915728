import React, { Component } from 'react';
import styled from 'styled-components';
import { Formik, Field } from 'formik';
import { getNestedData } from '../../../const/nested-data';
import {
  RemainingPoints,
  RemainingPointsText,
  RemainingPointsContainer
} from '../PointDistribution';
import {
  TOTAL_POINTS,
  FIRST_COLUMN_LABEL,
  POINTS
} from '../../../const/module-types';
import { TextHighlightContainer } from '../TextHighlight';
import { Points } from '../PointDistribution';

const MatrixInnerContainer = styled('div')`
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
`;

const MatrixInnerContainer2 = styled('div')`
  padding: 1rem 1rem 4rem 1rem;
  margin: auto;
  ${({ inMobileView }) =>
    inMobileView
      ? ``
      : `
  @media (min-width: 1200px) {
    padding: 0 3.75rem;
  }
  `}
`;

const ColumnHeader = styled('div')`
  text-align: center;
  font-family: 'Neue Haas Grotesk Text Std', 'Neue Haas Grotesk Display Std',
    sans-serif;
  font-size: 0.75rem;
  font-weight: bold;
  color: #333333;
  min-width: 3rem;
  ${({ inMobileView }) =>
    inMobileView
      ? ``
      : `
  @media (min-width: 1200px) {
    width: 7.0625rem;
  }
  `}
`;

const RadioInput = styled('input')`
  display: none;
`;

const RadioLabel = styled('label')`
  display: flex;
  cursor: pointer;
  margin: 0.375rem;
  justify-content: center;
  align-items: center;
`;

const RadioBox = styled('div')`
  background: white;
  box-shadow: inset -1px 1px 0.1875rem rgba(0,0,0,0.15);
  -webkit-appearance: none;
  display: flex
  justify-content: center;
  align-items: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 1.875rem;
  @media (min-width: 1200px) {
    width: 7.0625rem;
    height: 2.5rem;
  }
`;

const RadioDot = styled('div')`
  background: #1ca4fc;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  @media (min-width: 1200px) {
    width: 1.875rem;
    height: 1.875rem;
  }
`;

const RowTitle = styled('div')`
  font-family: 'Neue Haas Grotesk Text Std', 'Neue Haas Grotesk Display Std',
    sans-serif;
  font-size: 0.75rem;
  font-weight: bold;
  color: #333333;
  padding-right: 1rem;
  width: auto;
  min-width: 5rem;
  @media (min-width: 1200px) {
    width: 6.5rem;
  }
`;

const MatrixRow = styled('tr')``;

const HoverHide = styled('div')`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.05s;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    opacity: 0.25;
  }
`;

const MatrixContainer = styled(TextHighlightContainer)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
`;

export default class Matrix extends Component {
  getRemainingEqualsTotal() {
    const { questionData, moduleId, values } = this.props;
    const { pointDistribution = {} } = values;
    const totalPoints = parseInt(questionData[TOTAL_POINTS], 10) || 0;
    return this.getRemainingPoints() === totalPoints;
  }
  getRemainingPoints(options = {}) {
    const { skipKey } = options;
    const { questionData, values, moduleId } = this.props;
    const totalPoints = parseInt(questionData[TOTAL_POINTS], 10) || 0;
    const userResponses =
      (values.matrix && values.matrix[this.props.moduleId]) || {};
    const currentPoints = Object.entries(userResponses).reduce(
      (collector, [key, values]) => {
        if (key === skipKey) return collector;
        const value =
          values && values[POINTS] ? parseInt(values[POINTS], 10) : 0;
        return collector + value;
      },
      0
    );
    return totalPoints - currentPoints;
  }
  handlePointChange = e => {
    const id = e.currentTarget.id;
    const value = e.currentTarget.value || '';
    const skipKey = e.currentTarget.dataset.skipKey;
    const cleanedValue = value.replace(/[^\d]/g, '');
    const ivalue = cleanedValue ? parseInt(cleanedValue, 10) : 0;
    const toppedValue = Math.min(ivalue, this.getRemainingPoints({ skipKey }));
    this.props.setFieldValue(id, toppedValue);
  };
  render() {
    const { questionData, inPresentationMode, inMobileView } = this.props;
    if (!questionData || inPresentationMode) return null;
    const firstColumnLabel = questionData[FIRST_COLUMN_LABEL];
    const rows = Object.entries(questionData).filter(([key, data]) => {
      return key.indexOf('row') === 0;
    });
    const columns = Object.entries(questionData).filter(([key, data]) => {
      return key.indexOf('column') === 0;
    });
    const rowLength = rows.length;
    const columnLength = columns.length;
    const topRow = columns.map(([columnKey, column], c) => {
      return (
        <th key={`columnHeader${c}`}>
          <ColumnHeader data-c={c}>{column}</ColumnHeader>
        </th>
      );
    });
    const remainderRows = rows.map(([rowKey, row], r) => {
      const keyPrefix = `matrix.${this.props.moduleId}.${rowKey}`;
      const remainingInputs = columns.map(([columnKey, column], c) => {
        const key = `${keyPrefix}.column${c}`;
        const reactKey = `${key}.checked`;
        const value = getNestedData(this.props.values, reactKey);
        return (
          <td key={`row${r}col${c}`} data-r={r} data-c={c}>
            <RadioLabel htmlFor={reactKey}>
              <RadioBox>
                <RadioInput
                  key={reactKey}
                  name={reactKey}
                  id={reactKey}
                  value={columnKey}
                  checked={!!value}
                  type="checkbox"
                  onChange={this.props.handleChange}
                />
                {!!value ? <RadioDot /> : null}
                <HoverHide>
                  <RadioDot />
                </HoverHide>
              </RadioBox>
            </RadioLabel>
          </td>
        );
      });
      const pointsKey = `${keyPrefix}.${POINTS}`;
      const pointsValue = getNestedData(this.props.values, pointsKey);
      return (
        <MatrixRow key={keyPrefix} data-key={keyPrefix}>
          <th>
            <RowTitle>{row}</RowTitle>
          </th>
          <td data-r={r} data-c="points">
            <RadioLabel>
              <Points
                key={pointsKey}
                name={pointsKey}
                id={pointsKey}
                data-skip-key={rowKey}
                value={pointsValue || ''}
                onChange={this.handlePointChange}
                placeholder="#"
              />
            </RadioLabel>
          </td>
          {remainingInputs}
        </MatrixRow>
      );
    });
    return (
      <MatrixContainer {...{ inMobileView }}>
        <MatrixInnerContainer>
          <MatrixInnerContainer2 {...{ inMobileView }}>
            <RemainingPointsContainer>
              <RemainingPointsText>Remaining Points</RemainingPointsText>
              <RemainingPoints
                remainingPointsEqualsTotal={this.getRemainingEqualsTotal()}
              >
                {this.getRemainingPoints()}
              </RemainingPoints>
            </RemainingPointsContainer>
            <table>
              <thead>
                <MatrixRow>
                  <th />
                  <th>
                    <ColumnHeader {...{ inMobileView }}>
                      {firstColumnLabel}
                    </ColumnHeader>
                  </th>
                  {topRow}
                </MatrixRow>
              </thead>
              <tbody>{remainderRows}</tbody>
            </table>
          </MatrixInnerContainer2>
        </MatrixInnerContainer>
      </MatrixContainer>
    );
  }
}
