import TextSlideImg from '../img/text-slide.png';
import XYPlotterImg from '../img/xy-plotter.png';
import Plotter22Img from '../img/22-plotter.png';
import PointDistributionImg from '../img/point-distribution.png';
import StackRankImg from '../img/stack-rank.png';
import TextHighlightImg from '../img/text-highlight.png';
import SpectrumDistributionImg from '../img/spectrum-distribution.png';

import Overlay22Img from '../img/2x2.svg';

// MODULE_TYPES
export const TEXT_SLIDE = 'TEXT_SLIDE';
export const PLOTTER_XY = 'PLOTTER_XY';
export const PLOTTER_22 = 'PLOTTER_22';
export const POINT_DISTRIBUTION = 'POINT_DISTRIBUTION';
export const STACK_RANK = 'STACK_RANK';
export const TEXT_HIGHLIGHT = 'TEXT_HIGHLIGHT';
export const SPECTRUM_DISTRIBUTION = 'SPECTRUM_DISTRIBUTION';
export const MATRIX = 'MATRIX';
// TODO: matrix, userinfo, stack/point spectrum
export const PERSONAL_INFO = 'PERSONAL_INFO';
export const MADLIB = 'MADLIB';
export const COMPARISON = 'COMPARISON';
export const WAITING_ROOM = 'WAITING_ROOM';
export const OPEN_ENDED = 'OPEN_ENDED';
export const MULTI_SELECT = 'MULTI_SELECT';
export const ADD_OPTION = 'ADD_OPTION';

export const MODULE_TYPES_DISPLAY = {
  TEXT_SLIDE: 'Title slide',
  PLOTTER_XY: 'XY',
  PLOTTER_22: '2x2',
  POINT_DISTRIBUTION: 'Point distribution',
  STACK_RANK: 'Stack rank',
  TEXT_HIGHLIGHT: 'Text highlight',
  SPECTRUM_DISTRIBUTION: 'Spectrum distribution',
  MATRIX: 'Matrix',
  PERSONAL_INFO: 'Personal information',
  MADLIB: 'Madlib',
  COMPARISON: 'Comparison',
  WAITING_ROOM: 'Waiting room',
  OPEN_ENDED: 'Open ended',
  MULTI_SELECT: 'Multi Select',
  ADD_OPTION: 'Add Option',
};

/**
 * Uncomment a module for it to show up again in the module list
 * within the survey builder.
 * @type {(string)[]}
 */
export const MODULE_TYPES = [
  TEXT_SLIDE,
  // PLOTTER_XY,
  PLOTTER_22,
  POINT_DISTRIBUTION,
  STACK_RANK,
  // TEXT_HIGHLIGHT,
  SPECTRUM_DISTRIBUTION,
  //MATRIX,
  PERSONAL_INFO,
  MADLIB,
  COMPARISON,
  WAITING_ROOM,
  OPEN_ENDED,
  MULTI_SELECT,
  ADD_OPTION,
];

export const SURVEY_MODULE_TYPES = [
  TEXT_SLIDE,
  PLOTTER_22,
  POINT_DISTRIBUTION,
  STACK_RANK,
  SPECTRUM_DISTRIBUTION,
  PERSONAL_INFO,
  MADLIB,
  OPEN_ENDED,
  MULTI_SELECT,
  ADD_OPTION,
];

export const MODULE_TYPES_IMG = {
  TEXT_SLIDE: TextSlideImg,
  PLOTTER_XY: XYPlotterImg,
  PLOTTER_22: Plotter22Img,
  POINT_DISTRIBUTION: PointDistributionImg,
  STACK_RANK: StackRankImg,
  TEXT_HIGHLIGHT: TextHighlightImg,
  SPECTRUM_DISTRIBUTION: SpectrumDistributionImg,
  MATRIX: SpectrumDistributionImg,
  PERSONAL_INFO: SpectrumDistributionImg,
  MADLIB: SpectrumDistributionImg,
  COMPARISON: SpectrumDistributionImg,
  MULTI_SELECT: SpectrumDistributionImg,
  ADD_OPTION: SpectrumDistributionImg,
};

export const MODULE_TYPES_OVERLAY = {
  TEXT_SLIDE: Overlay22Img,
  PLOTTER_XY: Overlay22Img,
  PLOTTER_22: Overlay22Img,
  POINT_DISTRIBUTION: Overlay22Img,
  STACK_RANK: Overlay22Img,
  TEXT_HIGHLIGHT: Overlay22Img,
  SPECTRUM_DISTRIBUTION: Overlay22Img,
  MATRIX: Overlay22Img,
  PERSONAL_INFO: Overlay22Img,
  MADLIB: Overlay22Img,
  COMPARISON: Overlay22Img,
  WAITING_ROOM: Overlay22Img,
  OPEN_ENDED: Overlay22Img,
  MULTI_SELECT: Overlay22Img,
  ADD_OPTION: Overlay22Img,
};

// # questionData keys
// all modules
export const REQUIRED = 'required';
export const EXPLANATION = 'explanation';
export const OPTION = 'openOption';
export const OPTION_MAX = 'openOptionMax';
export const CATEGORY_DESCRIPTION = 'disableCategoryDescription';
export const DESCRIPTION = 'description';
export const QUESTION = 'question';
// PD
export const TOTAL_POINTS = 'totalPoints';
export const POINT_DISTRIBUTION_EXPLANATION = 'pointDistributionExplanation';
// TH
export const SENTENCE = 'sentence';
// SR
export const ORDER = 'order';
export const STACK_ID = 'stackId';
export const STACK_TEXT = 'stackText';
export const STACK_HEADER = 'stackHeader';
export const STACK_FOOTER = 'stackFooter';
// PD + SR + MT + SD
export const SPECTRUM = 'spectrum';
export const SPECTRUM_QUESTION = 'spectrumQuestion';
export const SPECTRUM_TEXT = 'spectrumText';
export const SPECTRUM_DESCRIPTION = 'spectrumDescription';
export const LEFT_ENDPOINT = 'leftEndpoint';
export const MIDPOINT = 'midpoint';
export const RIGHT_ENDPOINT = 'rightEndpoint';
// MT
export const FIRST_COLUMN_LABEL = 'firstColumnLabel';
// ML
export const MADLIB_SENTENCE = 'madlibSentence';
// XY
export const X_CURRENT = 'xCurrent';
export const Y_CURRENT = 'yCurrent';
export const X_LABEL = 'xAxisLabel';
export const Y_LABEL = 'yAxisLabel';
export const X_DOMAIN = 'xDomain';
export const Y_DOMAIN = 'yDomain';
// 22
export const POSX_LABEL = 'positiveXLabel';
export const NEGX_LABEL = 'negativeXLabel';
export const POSY_LABEL = 'positiveYLabel';
export const NEGY_LABEL = 'negativeYLabel';
// # answerData keys
// all (via EXPLANATION)
export const EXPLANATION_VALUE = 'explanation';
// PD + MT + AO
export const POINTS = 'points';
// PD + SR + MT + SD + AddOption
export const SPECTRUM_VALUE = 'spectrumValue';
export const SPECTRUM_VALUE_V1 = 'value';
// MT prefix
export const ROW_PREFIX = 'row'; // todo: implement these constants
export const COLUMN_PREFIX = 'column'; // todo: implement these constants
// SR? PD?
export const OPTION_PREFIX = 'option'; // a few types use this field (todo: make list)
// ML prefix
export const SECTIONS_PREFIX = 'section';
export const OPTIONS_PREFIX = 'dropdownOptions';
// TH
export const HIGHLIGHT_VALUES = 'highlightValues';
// 22, XY
export const PLOTTER_VALUES = 'plotterValues';
// XY
export const X = 'x';
export const Y = 'y';
// PI
export const NAME = 'name';
export const EMAIL = 'email';
export const TEAM = 'team';
export const DISABLE_EMAIL_INPUT = 'disableEmailInput';

// OpenEnded
export const VALUE = 'value';

// Multi-select
export const MAX_SELECTION_COUNT = 'maxSelectionCount';
export const IS_SINGLE_SELECT = 'isSingleSelect';

// these are just text field inputs
// more custom fields are possible but since they
// don't have just regular text field inputs
// todo: maybe introduce type to this data structure
// eg: shortText!Label, longText!label, boolean!label, array!options
export const MODULE_TYPES_FIELDS = {
  PLOTTER_XY: {
    // field key: placeholder string
    [X_LABEL]: 'X-axis label',
    [Y_LABEL]: 'Y-axis label',
    [X_DOMAIN]: 'X domain',
    [Y_DOMAIN]: 'Y domain',
    [Y_CURRENT]: 'Current numbers Y value',
  },
  PLOTTER_22: {
    [NEGX_LABEL]: 'Negative X label',
    [POSX_LABEL]: 'Positive X label',
    [NEGY_LABEL]: 'Negative Y label',
    [POSY_LABEL]: 'Positive Y label',
  },
  POINT_DISTRIBUTION: {
    [TOTAL_POINTS]: 'Total points',
  },
  SPECTRUM_DISTRIBUTION: {
    [LEFT_ENDPOINT]: 'Left endpoint',
    [MIDPOINT]: 'Midpoint',
    [RIGHT_ENDPOINT]: 'Right endpoint',
    numberOfDivisions: 'Number Of divisions',
    [SPECTRUM_TEXT]: 'Sentence',
  },
  MATRIX: {
    [TOTAL_POINTS]: 'Total points',
  },
};

export const typeToValueKey = {
  [PLOTTER_XY]: 'plotterData',
  [PLOTTER_22]: 'plotterData',
  [STACK_RANK]: 'stackRank',
  [POINT_DISTRIBUTION]: 'pointDistribution',
  [TEXT_HIGHLIGHT]: 'textHighlight',
  [SPECTRUM_DISTRIBUTION]: 'spectrumDistribution',
  [MATRIX]: 'matrix',
  [PERSONAL_INFO]: 'personalInfo',
  [MADLIB]: 'madlib',
  [EXPLANATION]: 'explanation',
  [OPEN_ENDED]: 'openEnded',
  [MULTI_SELECT]: 'multiSelect',
  [ADD_OPTION]: 'addOption',
};

export const restoreAnswerData = ({ surveyDetails, answerData }) => {
  const modules = surveyDetails.modules;
  return Object.entries(answerData).reduce(
    (collector, [moduleId, answerHash]) => {
      const moduleType = modules[moduleId].moduleType;
      const answerKey = typeToValueKey[moduleType];
      const { [EXPLANATION]: explanationText } = answerHash;
      const explanation = {
        ...collector['explanation'],
        [moduleId]: {
          explanation: explanationText || '',
        },
      };
      const addAnswerData = answerKey
        ? {
            [answerKey]: {
              ...(collector[answerKey] || {}),
              [moduleId]: answerHash,
            },
          }
        : {};
      return {
        ...collector,
        ...addAnswerData,
        explanation,
      };
    },
    {}
  );
};

export const MODULE_TYPES_DESCRIPTION = {
  TEXT_SLIDE:
    'Sharing introductory or explanatory text to help communicate with your user. Also useful for asking open-ended questions.',
  PLOTTER_XY:
    'Plotting an estimate or prediction of two related, quantitative values (e.g. Revenue over time).',
  PLOTTER_22:
    "Plotting an answer against two independent, qualitative dimensions. A consultant's best friend.",
  POINT_DISTRIBUTION:
    'Prioritizing a set of activities or actions with point values to indicate relative emphasis.',
  STACK_RANK:
    'Prioritizing a set of activities or actions along one dimension (e.g. Importance). Best to limit to about 7 options.',
  TEXT_HIGHLIGHT:
    'Reacting to language of a short text excerpt (e.g. draft mission statement).',
  SPECTRUM_DISTRIBUTION:
    'Answering a single question along a simple point scale. Can be used as Likert scale. (Only offers descriptions for end values and midpoint).',
  MATRIX:
    'Description of this module, going in to some detail about what it is and why it’s valuable.',
  PERSONAL_INFO:
    'Gathering name, email, and other personal information (e.g. Team).',
  MADLIB:
    'Fill in the blank questions with pre-determined set of options. Our version of multiple choice.',
  COMPARISON:
    'Display the results of two questions side-by-side to compare and constrast responses.  Use facilitation mode to show results live in a workshop.',
  WAITING_ROOM: `Introduce pauses in your workshops. Facilitators can also show the number of responses collected for each question throughout the survey.  
  <br>
  <br>
  Tip: For the best results, use this in workshops and place where you want to introduce pauses in the survey to control the tempo.  
  This module does not show in presentation mode.
  `,
  OPEN_ENDED: '',
  MULTI_SELECT: '',
  ADD_OPTION: '',
};

export const ADD_MODULE = 'ADD_MODULE';
export const EDIT_SURVEY = 'EDIT_SURVEY';
export const MANAGE_RESPONSES = 'MANAGE_RESPONSES';

export const PLOTTER22_QUADRANT_ONE = 'quadrant_1';
export const PLOTTER22_QUADRANT_TWO = 'quadrant_2';
export const PLOTTER22_QUADRANT_THREE = 'quadrant_3';
export const PLOTTER22_QUADRANT_FOUR = 'quadrant_4';

export const NO_QUESTION_TEXT = 'No questions';
export const DEFAULT_CHART_BUBBLE_RADIUS = 10; //This value is only used to determine the size of the scatter chart bubble size
//This is according to the chart in the survey
export const DEFAULT_X_MIN_DOMAIN = 1;
export const DEFAULT_X_MAX_DOMAIN = 7;

export const POPULATABLE_MODULES = [
  STACK_RANK,
  MULTI_SELECT,
  POINT_DISTRIBUTION,
  ADD_OPTION,
];

export const POPULATE_OPTIONS = 'populateOptions';
export const POPULATE_MODULE = 'populateModule';
export const SELECTED_POPULATE_OPTIONS_IDS = 'selectedPopulateOptionsIds';

export const NON_PRESENTATION_MODULES = [
  PERSONAL_INFO,
  COMPARISON,
  WAITING_ROOM,
  TEXT_SLIDE,
];

export const COMPARISON_SECTIONS = {
  ONE: 'sectionOne',
  TWO: 'sectionTwo',
};

export const STACK_RANK_COLOR_PALETTE = [
  '#FF899E',
  '#FF3F89',
  '#FFB800',
  '#FF7A00',
  '#88B50C',
  '#577600',
  '#00A2EE',
  '#4785E1',
  '#1A009D',
  '#023047',
];
