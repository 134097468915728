import React, { useRef, useEffect } from 'react';
import { Masons } from 'components/PresentationModules/AddOptions/styles';
import { EditOptionDialog } from 'components/workshop/EditOptionDialog/EditOptionDialog';
import {
  setClickedChartElementOptionKey,
  setSelectedParticipantResponseId,
} from 'pages/presentationSlice';
import { useDispatch, useSelector } from 'react-redux';

export const HiloMasons = ({
  options,
  loading,
  isMasonSelected,
  optionClicked,
  optionToEdit,
  close,
  handleEditOption,
  handleDeleteOption,
}) => {
  const refs = useRef([]);

  const dispatch = useDispatch();

  const selectedOptionKey = useSelector(
    (state) => state.presentation.clickedChartElementOptionKey
  );

  useEffect(() => {
    const handleEditDialogClose = (event) => {
      if (refs.current && refs.current[selectedOptionKey] !== event.target) {
        dispatch(setSelectedParticipantResponseId(null));
        dispatch(setClickedChartElementOptionKey(null));
      }
    };
    document.addEventListener('click', handleEditDialogClose, true);
    return () => {
      document.removeEventListener('click', handleEditDialogClose, true);
    };
  }, [selectedOptionKey]);

  return (options || []).map((option, index) => (
    <div css={{ position: 'relative' }} key={option.key}>
      <Masons
        ref={(el) => (refs.current[option.key] = el)}
        key={option.key}
        selected={isMasonSelected(option.responseId, option.key)}
        onClick={() => optionClicked(option, index)}
      >
        {option.name}
      </Masons>
      {optionToEdit && optionToEdit.key === option.key && (
        <EditOptionDialog
          sending={loading}
          close={close}
          optionName={option.name}
          handleSubmit={(updatedOption) =>
            handleEditOption(optionToEdit, updatedOption)
          }
          handleDelete={handleDeleteOption}
          isWorkshop
        />
      )}
    </div>
  ));
};
