import React, { useRef } from "react";
import { ButtonBase } from "../../buttons";

const styles = {
  commentInput: {
    boxShadow:
      "inset -1px -1px 2px rgba(0, 0, 0, 0.1), inset 1px 1px 2px rgba(0, 0, 0, 0.1)",
    border: 0,
    background: "#FFF",
    outline: 0,
    padding: "8px",
    fontSize: "12px",
    width: "100%",
    height: "100%",
  },
  commentActionBtnsContainer: {
    display: "flex",
    position: "absolute",
    bottom: 8,
    right: 8,
    fontSize: "10px",
  },
  commentCancelBtn: {
    border: "0.5px solid #000",
    padding: "2px 10px",
    borderRadius: "3px",
    background: "#FFF",
  },
  commentSaveBtn: {
    marginLeft: "8px",
    border: "0.5px solid #1CA4FC",
    padding: "2px 10px",
    borderRadius: "3px",
    color: "#1CA4FC",
    background: "#FFF",
  },
};

function CommentSaveAndCancel({ onClickCancel, onClickSave }) {
  return (
    <div css={styles.commentActionBtnsContainer}>
      <ButtonBase onClick={onClickCancel} css={styles.commentCancelBtn}>
        Cancel
      </ButtonBase>
      <ButtonBase onClick={onClickSave} css={styles.commentSaveBtn}>
        Save
      </ButtonBase>
    </div>
  );
}

export default function CommentInput({
  onClickSave,
  onClickCancel,
  className,
  defaultValue,
}) {
  const commentInputRef = useRef(null);

  return (
    <div css={className}>
      <textarea
        placeholder="Please input your explanation"
        css={styles.commentInput}
        defaultValue={defaultValue}
        ref={commentInputRef}
        rows={5}
      />
      <CommentSaveAndCancel
        onClickCancel={() => onClickCancel()}
        onClickSave={() => onClickSave(commentInputRef.current.value)}
      />
    </div>
  );
}
