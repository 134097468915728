import React, { useState } from 'react';
import {
  Container,
  Question,
  Description,
  DescriptionToggle,
  RightAlign,
  Row,
  DescRow,
} from './styles';
import {
  MADLIB,
  MULTI_SELECT,
  OPEN_ENDED,
  PLOTTER_22,
  POINT_DISTRIBUTION,
  SPECTRUM_DISTRIBUTION,
  STACK_RANK,
  ADD_OPTION,
  TEXT_SLIDE,
} from '../../../const/module-types';
import {
  getQuestionDescription,
  isTextSlideModule,
  getQuestionText,
} from '../../../lib/Presentation';
import { useSelector } from 'react-redux';
import Tracking from 'lib/tracking/tracking';
import { presentationEvents } from 'lib/tracking/events';

import { ReactComponent as PlusIcon } from 'img/PlusIcon.svg';
import { ReactComponent as MinusIcon } from 'img/MinusIcon.svg';

/**
 * Show sections of the UI where the module type is not TEXT_SLIDE
 * @param activeQuestion
 * @param isExplanationBox
 * @returns {boolean}
 */
const show = (activeQuestion, isExplanationBox = false) => {
  if (isExplanationBox && activeQuestion.metaData.moduleType === OPEN_ENDED) {
    return false;
  }

  const allowedModules = [
    POINT_DISTRIBUTION,
    STACK_RANK,
    PLOTTER_22,
    SPECTRUM_DISTRIBUTION,
    MADLIB,
    OPEN_ENDED,
    MULTI_SELECT,
    ADD_OPTION,
    TEXT_SLIDE,
  ];
  return !!(
    activeQuestion &&
    activeQuestion.metaData.hasOwnProperty('moduleType') &&
    allowedModules.includes(activeQuestion.metaData.moduleType)
  );
};

export const QuestionContainer = ({
  renderControls = () => null,
  renderCommentBtn,
  renderWorkshopPopulateBtn = () => null,
}) => {
  const [showDescription, setShowDescription] = useState(true);

  const activeQuestion = useSelector(
    (state) => state.presentation.activeQuestion
  );

  return (
    <>
      <Container>
        <Row>
          <div>{renderControls()}</div>
          <RightAlign>
            <span style={{ marginRight: '15px' }}>
              {renderWorkshopPopulateBtn?.()}
            </span>
            {renderCommentBtn}
          </RightAlign>
        </Row>
        <div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {show(activeQuestion) && getQuestionDescription(activeQuestion) && (
              <DescriptionToggle
                onClick={() => {
                  Tracking.event({
                    name: presentationEvents.TOGGLE_DESCRIPTION,
                  });
                  setShowDescription(!showDescription);
                }}
              >
                {showDescription ? <MinusIcon /> : <PlusIcon />}
              </DescriptionToggle>
            )}
            <Question
              isTextSlide={isTextSlideModule(activeQuestion.moduleType)}
            >
              {getQuestionText(activeQuestion)}
            </Question>
          </div>
          {show(activeQuestion) && getQuestionDescription(activeQuestion) && (
            <DescRow>
              {showDescription && (
                <Description>
                  {String(getQuestionDescription(activeQuestion))
                    .split('')
                    .map((char, i) =>
                      char === '\n' ? <br key={`desc${i}`} /> : char
                    )}
                </Description>
              )}
            </DescRow>
          )}
          <hr
            style={{
              clear: 'both',
              marginTop: '1.25rem',
              border: '1px',
              width: '100%',
              borderColor: '#1CA4FC',
              borderStyle: 'solid',
            }}
          />
        </div>
      </Container>
    </>
  );
};
