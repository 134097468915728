import styled from 'styled-components';

export const OpenOptionsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 100%;
  padding: 1rem 1.25rem 4rem 1.25rem;
  height: 100%;
  overflow: auto;
  text-align: left;
  ${({ inMobileView }) =>
    inMobileView
      ? ``
      : `
  @media (min-width: 1200px) {
    margin: auto;
    padding: 2rem 3.75rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    // background-image: linear-gradient(0deg, #ffffff 0%, #c4c4c4 100%);
    background-position: top left;
    background-size: 100% 0.5rem;
    background-repeat: no-repeat;
    max-width: 44.375rem;
  }
  `}
`;

export const Row = styled('div')`
  display: flex;
  flex-direction: row;
  flex: 1 1 1px;
  justify-content: space-evenly;
  margin: 20px;
`;

// You can add 1/3 more options
export const InfoText = styled('div')`
  width: 385px;
  align-items: left;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: -0.01em;
  color: #1ca4fc;
  text-align: left;
  margin-bottom: -20px;
`;

// Text input
export const Input = styled('input')`
  width: 300px;
  height: 53px;
  left: 0px;
  top: 0px;
  background: #ffffff;
  box-shadow: inset 0px 0px 10px #c4c4c4;
  border-radius: 5px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 10px 0px;
  -webkit-appearance: none;
  border: none;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 101.83%;
  letter-spacing: -0.01em;
  color: #333;
  padding: 0 10px;
`;

// 50 characters left
export const CharLeft = styled('p')`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 101.83%;
  letter-spacing: -0.01em;
  color: #1ca4fc;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: -25px 0 0 57%;
  align-item: right;
`;

// Box
export const Icon = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  background: ${(props) => (props.disabled ? '#C4C4C4' : '#1ca4fc')};
  border-radius: 4.33333px;
  margin: 25px 8px;
  cursor: ${(props) => (props.disabled ? 'none' : 'pointer')};
  position: relative;
`;
