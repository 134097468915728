import React from 'react';
import styled from 'styled-components';
import {
  MODULE_TYPES,
  MODULE_TYPES_DISPLAY,
  SURVEY_MODULE_TYPES,
} from '../../../const/module-types';
import BlueEyeImg from '../../../img/blue-eye.svg';
import PreviewEyeImg from '../../../img/preview-eye.svg';
import { HILOS } from '../../../const';

const ModuleType = styled('div')`
  height: 192px;
  padding: 11px 16px;
  box-sizing: border-box;
  background: white;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ModuleTypeTitle = styled('div')`
  flex: 1 0 auto;
  font-family: Neue Haas Grotesk Display Std;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 37px;
  letter-spacing: -0.01em;
  cursor: pointer;
  &:hover {
    color: #1ca4fc;
  }
`;

const AddBar = styled('div')`
  position: absolute;
  top: 0;
  height: 2px;
  width: 130px;
  left: 0;
  background: #1ca4fc;
  display: none;
`;

const AddModuleEye = styled('div')`
  background: url('${PreviewEyeImg}') center / auto no-repeat;
  width: 24px;
  height: 24px;
`;

const AddContainer = styled('div')`
  width: 100%;
  height: 37px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  &:hover {
    ${AddBar} {
      display: block;
    }
    ${AddModuleEye} {
      background-image: url('${BlueEyeImg}');
    }
  }
`;

const AddModuleText = styled('div')`
  color: #1ca4fc;
  font-family: Neue Haas Grotesk Display Std;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.01em;
`;

const ModuleTypes = styled('div')`
  display: grid;
  width: 100%;
  grid-gap: 22px;
  margin: 20px 0;
  grid-template-columns: 100%;
  @media (min-width: 400px) {
    grid-template-columns: repeat(auto-fill, minmax(288px, 1fr));
  }
`;

const getModulesTypes = ({ hilo }) => {
  if (hilo === HILOS.SURVEY) return SURVEY_MODULE_TYPES;
  return MODULE_TYPES;
};

const AddModuleCards = ({
  addAModule,
  setLeftOverlayId,
  clearLeftOverlayId,
  hilo = HILOS.SURVEY,
}) => {
  const addModuleCards = getModulesTypes({ hilo }).map((key) => {
    const label = MODULE_TYPES_DISPLAY[key];
    const onMouseEnter = () => {
      setLeftOverlayId(key);
    };
    return (
      <ModuleType key={key}>
        <ModuleTypeTitle
          onClick={addAModule}
          data-module-type={key}
          onMouseEnter={clearLeftOverlayId}
        >
          {label}
        </ModuleTypeTitle>
        <AddContainer
          onMouseEnter={onMouseEnter}
          onMouseLeave={clearLeftOverlayId}
        >
          <AddBar />
          <AddModuleText>
            <AddModuleEye />
          </AddModuleText>
        </AddContainer>
      </ModuleType>
    );
  });
  return <ModuleTypes>{addModuleCards}</ModuleTypes>;
};

export default AddModuleCards;
