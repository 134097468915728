import React, { Component } from 'react';
import styled from 'styled-components';
import { DragDropContext, DragLayer, DragSource, DropTarget } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import TouchBackend from 'react-dnd-touch-backend';
import { connect } from 'react-redux';
import { VictoryAxis, VictoryBar, VictoryChart, VictoryLabel } from 'victory';
import {
  OPTION_PREFIX,
  ORDER,
  STACK_FOOTER,
  STACK_HEADER,
  STACK_RANK,
  STACK_TEXT,
} from '../../../const/module-types';
import { getNestedData } from '../../../const/nested-data';
import { filterAndSortOptions } from '../../Builder/ModuleCard';
import {
  axisStyleXY,
  CHART_HEIGHT,
  CHART_WIDTH,
} from '../../../components/survey-components/Plotter';
import {
  ChartContainer,
  PresentationBody,
  PresentationCell,
  PresentationHalfWidth,
  PresentationRow,
  PresentationTable as LayoutPresentationTable,
  PresentationTableContainerTwoColumn as PresentationTableContainer,
  PresentationTitleRow,
} from '../../../components/layout';
import { getMedian } from '../../../components/survey-components/PointDistribution';
import { isPopulatedOptions } from 'components/utils/helpers';
import { filterAndSortStackRank } from 'components/survey-components/StackRank/helpers';

export const StackRankContainer = styled('div')`
  background-color: white;
  width: 100%;
  padding: 1rem 1.25rem 4rem 1.25rem;
  height: 100%;
  overflow: auto;
  ${({ inMobileView }) =>
    inMobileView
      ? ``
      : `
  @media (min-width: 1200px) {
    margin: auto;
    padding: 2rem 3.75rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    // background-image: linear-gradient(0deg, #ffffff 0%, #c4c4c4 100%);
    background-position: top left;
    background-size: 100% 0.5rem;
    background-repeat: no-repeat;
    max-width: 44.375rem;
  }
  `}
`;

export const MostLeastImportant = styled('div')`
  color: #333333;
  font-family: 'Neue Haas Grotesk Display Std', sans-serif;
  font-size: 0.75rem;
  margin: 0.25rem 0;
  ${({ inMobileView }) =>
    inMobileView
      ? ``
      : `
  @media (min-width: 1200px) {
    font-weight: bold;
    margin: 0.75rem 0;
  }
  `}
`;

export const StackRankItems = styled('div')``;
export const StackItem = styled('div')`
  cursor: pointer;
  color: white;
  max-width: 39.75rem;
  display: flex;
  align-items: center;
  width: 100%;
  font-family: 'Neue Haas Grotesk Display Std', sans-serif;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.75rem;
  background: ${(props) => (props.background ? props.background : '#1CA4FC')};
  border: ${(props) => (props.border ? props.border : 'none')};
  color: ${(props) => (props.color ? props.color : 'white')};
  position: ${(props) => (props.position ? props.position : 'relative')};
  min-height: 2.5rem;
  line-height: 1;
  &:hover {
    background: #0d87d6;
  }
  ${({ inMobileView }) =>
    inMobileView
      ? ``
      : `
  @media (min-width: 1200px) {
    font-size: 1.5rem;
    min-height: 3.25rem;
  }
  `}
`;
const StackRankBottom = styled('div')`
  padding-bottom: 0.625rem;
`;

export const sortStackRank = (stackRank) => {
  const sorted = Object.entries(stackRank)
    .filter(([stackId, stackText]) => {
      return stackId.indexOf('option') === 0;
    })
    .sort(([aKey, aData], [bKey, bData]) => {
      const aOrder = aData.order;
      const bOrder = bData.order;
      return aOrder - bOrder;
    });
  const result = sorted.reduce((collector, [stackId, stackData], i) => {
    const order = i + 1;
    return {
      ...collector,
      [stackId]: {
        ...stackData,
        stackId,
        order,
      },
    };
  }, {});
  return result;
};
export const sortStackRankToArray = (stackRank) => {
  const sorted = Object.values(sortStackRank(stackRank)).sort(
    (aData, bData) => {
      const aOrder = aData.order;
      const bOrder = bData.order;
      return aOrder - bOrder;
    }
  );
  return sorted;
};

class StackRankLayer extends Component {
  render() {
    const { isDragging, stackId, layerStackId } = this.props;
    if (!isDragging) return null;
    const style = getItemStyles(this.props);
    //console.log(JSON.stringify({ isDragging, stackId, layerStackId, style }));
    return (
      <div>
        <StackItem
          {...this.props}
          style={style}
          background="#FF9B3A!important"
        />
      </div>
    );
  }
}
const getItemStyles = (props) => {
  const { initialOffset, currentOffset, stackId, layerStackId } = props;
  if (!initialOffset || !currentOffset || stackId !== layerStackId) {
    return {
      display: 'none',
    };
  }
  const { x: xC, y: yC } = currentOffset;
  const { x: xI, y: yI } = initialOffset;
  const transform = `translate(${xC - xI}px, ${yC - yI}px)`;
  return {
    transform,
    WebkitTransform: transform,
    position: 'absolute',
    zIndex: '10',
    pointerEvents: 'none',
    color: 'white',
    left: 0,
    top: 0,
  };
};
const dragLayerCollect = (monitor) => {
  return {
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    clientOffset: monitor.getClientOffset(),
    isDragging: monitor.isDragging(),
    stackId: monitor.getItem() && monitor.getItem().stackId,
  };
};
const StackRankLayerItem = DragLayer(dragLayerCollect)(StackRankLayer);
const dragSourceCollect = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging(),
});
const dropTargetCollect = (connect, monitor) => ({
  highlighted: monitor.canDrop(),
  hovered: monitor.isOver(),
  connectDropTarget: connect.dropTarget(),
});
const dragSourceSpec = {
  beginDrag(props, monitor, component) {
    return { stackId: props.stackId };
  },
};
const dropTargetSpec = {
  drop(props, monitor, component) {
    if (!component) return null;
    const dragIndex = monitor.getItem().stackId;
    const hoverIndex = props.stackId;
    if (dragIndex === hoverIndex) return;
    props.moveCard && props.moveCard({ dragIndex, hoverIndex });
  },
};

const StackRankScroll = styled('div')`
  margin: auto 0;
`;
class StackRankItem extends Component {
  componentDidMount() {
    const { connectDragPreview } = this.props;
    const emptyImage = getEmptyImage();
    connectDragPreview(emptyImage);
  }
  render() {
    const {
      connectDragSource,
      connectDropTarget,
      highlighted,
      hovered,
      isDragging,
      inMobileView,
    } = this.props;
    let background, color, border;
    if (highlighted && (isDragging || hovered)) {
      border = '1px dotted #828282';
      background = 'transparent!important';
      color = 'transparent';
    }
    const styleProps = {
      background,
      color,
      border,
    };
    return connectDragSource(
      connectDropTarget(
        <div>
          <StackRankBottom>
            <StackItem {...styleProps} {...{ inMobileView }} {...this.props} />
          </StackRankBottom>
        </div>
      )
    );
  }
}
export const DraggableStackRankItem = DropTarget(
  STACK_RANK,
  dropTargetSpec,
  dropTargetCollect
)(DragSource(STACK_RANK, dragSourceSpec, dragSourceCollect)(StackRankItem));

export const DraggableStackContainer = DragDropContext(
  TouchBackend({ enableMouseEvents: true, enableKeyboardEvents: true })
)(StackRankItems);

const PresentationTable = styled(LayoutPresentationTable)`
  margin-bottom: 1rem;
  table-layout: fixed;
`;

const ChartTitle = styled('div')``;

class StackRank extends Component {
  getStackRankOptions() {
    const questionData = this.props.questionData;
    const addedOptions = questionData?.addedOptions || [];
    return filterAndSortStackRank(questionData, addedOptions).reduce(
      (collector, [stackId, stackText], i) => {
        const order = i + 1;
        return {
          ...collector,
          [stackId]: {
            stackText,
            stackId,
            order,
          },
        };
      },
      {}
    );
  }
  getStackRankValues() {
    const { moduleId, questionData } = this.props;
    const addedOptions = questionData?.addedOptions || [];
    if (this.props.values.stackRank && this.props.values.stackRank[moduleId]) {
      const options = Object.entries(
        this.props.values.stackRank[moduleId]
      ).filter(([stackId, stackText]) => {
        return (
          stackId.indexOf('option') === 0 && !addedOptions.includes(stackId)
        );
      });
      const hasOptions = options.length;
      if (hasOptions) {
        // Show only options added during survey building not the facilitator
        // added options. Added options should only appear in the populated
        // or next module.
        const data = options.reduce((acc, value) => {
          return {
            ...acc,
            [value[0]]: {
              ...value[1],
            },
          };
        }, {});
        return sortStackRank(data);
      }
    }
    return this.getStackRankOptions();
  }
  moveStackRankCard = ({ dragIndex, hoverIndex }) => {
    const { moduleId, values } = this.props;
    const stackRankValues = this.getStackRankValues();
    const dragRank = stackRankValues[dragIndex];
    // the card we are dropping it on
    const hoverRank = stackRankValues[hoverIndex];
    const dragOrder = dragRank.order;
    const hoverOrder = hoverRank.order;
    const newOrder =
      hoverOrder < dragOrder ? hoverOrder - 0.5 : hoverOrder + 0.5;
    const newStackRank = {
      ...stackRankValues,
      [dragIndex]: {
        ...dragRank,
        order: newOrder,
      },
    };
    const sortedStackRank = sortStackRank(newStackRank);
    const stackRank = {
      ...values.stackRank,
      [moduleId]: {
        ...sortedStackRank,
      },
    };
    this.props.setValues({
      ...values,
      stackRank,
    });
  };
  renderSurvey() {
    const {
      inMobileView,
      questionData = {},
      hilo: { isParticipantMode },
    } = this.props;
    const populatePlaceHolder = [
      { stackId: 'option1', stackText: 'To be populated', order: 1 },
      { stackId: 'option2', stackText: 'To be populated', order: 2 },
      { stackId: 'option3', stackText: 'To be populated', order: 3 },
    ];
    const itemValues = this.getStackRankValues();
    const sortedItems = sortStackRankToArray(itemValues);
    const items =
      questionData?.populateOptions &&
      !isParticipantMode &&
      isPopulatedOptions(Object.keys(questionData)) === false
        ? populatePlaceHolder.map((itemData) => {
            const { stackId, stackText } = itemData;
            return (
              <DraggableStackRankItem stackId={stackId} key={stackId}>
                {stackText}
                <StackRankLayerItem layerStackId={stackId}>
                  {stackText}
                </StackRankLayerItem>
              </DraggableStackRankItem>
            );
          })
        : sortedItems.map((itemData) => {
            const { stackId, stackText } = itemData;
            return (
              <DraggableStackRankItem
                stackId={stackId}
                key={stackId}
                moveCard={this.moveStackRankCard}
              >
                {stackText}
                <StackRankLayerItem layerStackId={stackId}>
                  {stackText}
                </StackRankLayerItem>
              </DraggableStackRankItem>
            );
          });
    return (
      <StackRankContainer {...{ inMobileView }}>
        <StackRankScroll>
          <MostLeastImportant {...{ inMobileView }}>
            {questionData[STACK_HEADER] || 'Most Important'}
          </MostLeastImportant>
          <DraggableStackContainer>{items}</DraggableStackContainer>
          <MostLeastImportant {...{ inMobileView }}>
            {questionData[STACK_FOOTER] || 'Least Important'}
          </MostLeastImportant>
        </StackRankScroll>
      </StackRankContainer>
    );
  }
  getResponsesPerOption() {
    const { moduleId, questionData } = this.props;
    const userResponses =
      getNestedData(this.props, 'values.userResponses') || {};
    const responsesPerOption = filterAndSortOptions(questionData).reduce(
      (collector, [key, pointDistributionText]) => {
        const userResponsesForOption = Object.entries(userResponses).reduce(
          (collector2, [userId, responseData]) => {
            const order = getNestedData(
              responseData,
              `answerData.${moduleId}.${key}.${ORDER}`
            );
            const stackText = getNestedData(
              responseData,
              `answerData.${moduleId}.${key}.${STACK_TEXT}`
            );
            return {
              ...collector2,
              [userId]: {
                [ORDER]: order,
                [STACK_TEXT]: stackText,
              },
            };
          },
          {}
        );
        return {
          ...collector,
          [key]: {
            ...collector[key],
            ...userResponsesForOption,
          },
        };
      },
      {}
    );
    return responsesPerOption;
  }
  getPresentationData() {
    const { questionData } = this.props;
    const responsesPerOption = this.getResponsesPerOption();
    const presentationData = Object.entries(responsesPerOption).reduce(
      (collector, [optionKey, userResponses]) => {
        const userResponseArray = Object.values(userResponses).map(
          (infoHash) => infoHash[ORDER] || 0
        );
        const average =
          userResponseArray.reduce((av, points) => {
            return av + points;
          }, 0) / userResponseArray.length;
        const median = getMedian(userResponseArray);
        const minimum = userResponseArray.reduce((min, points) => {
          return min === void 0 || points < min ? points : min;
        }, void 0);
        const maximum = userResponseArray.reduce((min, points) => {
          return min === void 0 || points > min ? points : min;
        }, void 0);
        const range = maximum - minimum;
        // https://www.mathsisfun.com/data/standard-deviation-formulas.html
        const sdStep2 = userResponseArray.map((points) => {
          const step2 = Math.pow(points - average, 2);
          return step2;
        });
        const sdStep3 = sdStep2.reduce((sd3, step2) => {
          return sd3 + step2;
        }, 0);
        const sdStep4 = sdStep3 / sdStep2.length;
        const sd = Math.pow(sdStep4, 0.5);
        const optionText = questionData[optionKey];
        const presentationHash = {
          average,
          median,
          minimum,
          maximum,
          range,
          sd,
          optionText,
        };
        return {
          ...collector,
          [optionKey]: presentationHash,
        };
      },
      {}
    );
    return Object.entries(presentationData)
      .sort(([aKey, a], [bKey, b]) => {
        return a.average - b.average;
      })
      .map(([optionKey, presentationHash], i) => {
        const optionInt = parseInt(optionKey.replace(OPTION_PREFIX, ''), 10);
        return {
          ...presentationHash,
          optionKey,
          optionInt,
          [ORDER]: i + 1,
          x: presentationHash.optionText,
          y: presentationHash.average,
        };
      });
  }
  getHighestLowestRank() {
    const data = this.getPresentationData();
    const lowestRank = data[0][ORDER];
    const highestRank = data.slice(-1)[0][ORDER];
    return { lowestRank, highestRank };
  }
  getRankTableData() {
    const { moduleId, questionData } = this.props;
    const data = this.getPresentationData();
    const { lowestRank, highestRank } = this.getHighestLowestRank();
    const responsesPerOption = this.getResponsesPerOption();
    const optionValues = Object.keys(responsesPerOption);
    const tableData = optionValues.reduce((collector, optionKey) => {
      const average = data.find((x) => x.optionKey === optionKey).average;
      let optionCollector = [];
      for (let i = lowestRank; i <= highestRank; i++) {
        const rankArray = Object.values(responsesPerOption[optionKey]).filter(
          (responseData) => {
            return responseData[ORDER] === i;
          }
        );
        const rankCount = rankArray.length;
        const optionText = questionData[optionKey];
        optionCollector.push({
          x: i,
          y: rankCount,
          optionText,
          average,
          rankCount,
        });
      }
      return {
        ...collector,
        [optionKey]: optionCollector,
      };
    }, {});
    return Object.entries(tableData).sort(
      ([aK, aD], [bK, bD]) => aD[0].average - bD[0].average
    );
  }
  getHighestCount() {
    const data = this.getRankTableData().reduce(
      (collector, [optionKey, optionCollector]) => {
        const highestCount = optionCollector.reduce(
          (collector2, optionHash) => {
            const highestCount2 = optionHash.rankCount;
            return highestCount2 > collector2 ? highestCount2 : collector2;
          },
          0
        );
        return highestCount > collector ? highestCount : collector;
      },
      0
    );
    return data;
  }
  getRankDomain() {
    const { lowestRank, highestRank } = this.getHighestLowestRank();
    const highestCount = this.getHighestCount();
    return { x: [lowestRank - 1, highestRank], y: [0, highestCount] };
  }
  renderRankTable() {
    const data = this.getRankTableData();
    return data.map(([optionKey, dataArray]) => {
      return (
        <PresentationHalfWidth key={`rank${optionKey}`}>
          <ChartTitle>{dataArray[0].optionText}</ChartTitle>
          <ChartContainer>
            <VictoryChart
              width={CHART_WIDTH / 2}
              height={CHART_HEIGHT / 2}
              padding={{ top: 30, right: 20, bottom: 30, left: 40 }}
              domain={this.getRankDomain()}
            >
              <VictoryAxis
                dependentAxis
                label="Count"
                axisLabelComponent={
                  <VictoryLabel key="yLabel" x={10} y={20} angle={0} />
                }
                style={axisStyleXY}
                tickFormat={(t) => (t % 1 === 0 ? t : '')}
              />
              <VictoryAxis
                label="Rank"
                axisLabelComponent={
                  <VictoryLabel key="xLabel" x={10} y={CHART_HEIGHT / 2 - 15} />
                }
                style={axisStyleXY}
                tickFormat={(t) => (t % 1 === 0 ? t : '')}
              />
              <VictoryBar
                data={dataArray}
                sortKey="rank"
                style={{
                  data: {
                    fill: (d) => {
                      // todo
                      return '#1CA4FC';
                    },
                  },
                }}
              />
            </VictoryChart>
          </ChartContainer>
        </PresentationHalfWidth>
      );
    });
  }
  renderPresentation() {
    const data = this.getPresentationData();
    const body = data.map((pHash) => {
      return (
        <PresentationRow key={`row${pHash.optionKey}`}>
          <PresentationCell>{pHash.optionText}</PresentationCell>
          <PresentationCell textAlign="center">
            {pHash.average.toFixed(2)}
          </PresentationCell>
          <PresentationCell textAlign="center">
            {pHash.median.toFixed(2)}
          </PresentationCell>
          <PresentationCell textAlign="center">
            {pHash.minimum}
          </PresentationCell>
          <PresentationCell textAlign="center">
            {pHash.maximum}
          </PresentationCell>
          <PresentationCell textAlign="center">{pHash.range}</PresentationCell>
          <PresentationCell textAlign="center">
            {pHash.sd.toFixed(2)}
          </PresentationCell>
        </PresentationRow>
      );
    });
    return (
      <PresentationTableContainer>
        <PresentationTable>
          <thead>
            <PresentationTitleRow>
              <PresentationCell background="transparent">
                Option
              </PresentationCell>
              <PresentationCell background="transparent" textAlign="center">
                Mean
              </PresentationCell>
              <PresentationCell background="transparent" textAlign="center">
                Median
              </PresentationCell>
              <PresentationCell background="transparent" textAlign="center">
                Minimum
              </PresentationCell>
              <PresentationCell background="transparent" textAlign="center">
                Maximum
              </PresentationCell>
              <PresentationCell background="transparent" textAlign="center">
                Range
              </PresentationCell>
              <PresentationCell background="transparent" textAlign="center">
                Std. Dev
              </PresentationCell>
            </PresentationTitleRow>
          </thead>
          <PresentationBody>{body}</PresentationBody>
        </PresentationTable>
        {this.renderRankTable()}
      </PresentationTableContainer>
    );
  }
  render() {
    return this.props.inPresentationMode
      ? this.renderPresentation()
      : this.renderSurvey();
  }
}

const mapStateToProps = (state) => ({
  hilo: state.hilo,
});

const ConnectedStackRank = connect(mapStateToProps)(StackRank);

export default ConnectedStackRank;
