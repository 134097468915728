import React from 'react';

import { Container, InfoBox, InfoText, SalyOneImg, SalyTwoImg, Title } from './styles';
import { ReactComponent as SalyShip } from 'img/saly-1.svg';
import { ReactComponent as SalySchool } from 'img/saly-read.svg';


export const EmptyPopulate = () => (
  <Container>
    <SalyOneImg> <SalyShip /> </SalyOneImg>
    <InfoBox>
      <Title>Next question is not ready </Title>
      <InfoText>Please wait for the facilitator<br /> while they ready the next question</InfoText>
    </InfoBox>
    <SalyTwoImg> <SalySchool /> </SalyTwoImg>
  </Container>
)
