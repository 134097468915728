import React, { useEffect, useReducer } from 'react';
import MessageBubbles from './MessageBubbles';
import WordCloud from './WordCloud';
import { useSelector } from 'react-redux';
import { HILOS } from 'const/index';

const OpenQuestion = ({
  mode,
  moduleId,
  comparisonSection,
  bubblesAnimationsControls,
}) => {
  const data = useSelector((state) => state.presentation[moduleId]);

  const selectedParticipantResponseId = useSelector((state) => {
    if (comparisonSection && mode === HILOS.WORKSHOP) {
      return state.workshop.comparison[comparisonSection]
        .selectedParticipantResponseId;
    } else {
      return state.presentation.selectedParticipantResponseId;
    }
  });

  const selectedParticipantData =
    data?.participantsData?.[selectedParticipantResponseId] || {};

  return data.isWordCloudSelected ? (
    <WordCloud data={data.wordCloudData} moduleId={moduleId} />
  ) : (
    <MessageBubbles
      data={data.userResponses}
      selectedParticipantData={selectedParticipantData}
      animationsControls={bubblesAnimationsControls}
    />
  );
};

export default OpenQuestion;
