import React from "react";
import styled from "styled-components";

const Button = styled("button")`
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  outline: none;
  color: ${props => (props.toggle ? "#FFFFFF" : "#3faffa")};
  border: 1px solid #3faffa;
  box-sizing: border-box;
  height: 2em;
  margin-right: ${props => (props.marginRight ? props.marginRight : "0px")};
  background-color: ${props => (props.toggle ? "#3FAFFA" : "#fff")};
`;

const PresentationButton = ({ onClick, toggle, marginRight, children }) => (
  <Button onClick={onClick} toggle={toggle} marginRight={marginRight}>
    {children}
  </Button>
);

export default PresentationButton;
