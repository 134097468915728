import styled from 'styled-components';

export const Panel = styled('div')`
  font-family: Roboto, sans-serif;
  width: 32%;
  height: vh;
  overflow-y: auto;
  background: #ffffff;
  box-shadow: -6px 0px 10px rgba(28, 164, 252, 0.25);
  border-radius: 20px 0px 0px 20px;
  padding: 20px;
  position: fixed;
  top: 70px;
  bottom: 0;
  right: 0;
  display: block;
  max-width: 450px;
`;

export const Row = styled('div')`
  display: flex;
`;

export const RightAlign = styled('div')`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

export const UserCommentContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  height: inherit;
`;

export const UserCard = styled('div')`
  margin-top: 10px;
`;

export const CardHeader = styled('div')`
  width: 468px;
  height: 25px;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 20px 0px;
`;

export const UserName = styled('div')`
  display: flex;
  align-items: center;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 101.83%;
  letter-spacing: -0.01em;
  color: ${(props) => (props.highlighted ? '#FF9736' : '#000000')};
  margin-right: 15px;
`;

export const CollapsibleButton = styled('div')`
  width: 26px;
  height: 26px;
  background: #f1f2f5;
  border-radius: 5px;
  color: #1ca4fc;
  cursor: pointer;
  text-align: center;
  justify-content: center;
  font-size: 15px;
  margin-right: 15px;
`;

export const ViewSelection = styled('div')`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 101.83%;
  text-align: right;
  letter-spacing: -0.01em;
  color: ${(props) => (props.selected ? '#FF9736' : '#1CA4FC')};
  margin-left: auto;
  cursor: pointer;
`;

export const CommentBox = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  width: 468px;
  left: 0;
  top: 45px;
  order: 1;
  flex-grow: 0;
  margin: 20px 0;
`;

export const CommentTitle = styled('div')`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 101.83%;
  letter-spacing: -0.01em;
  color: #000000;
  margin-right: 15px;
  display: flex;
  align-items: center;
`;

export const CommentPoint = styled('span')`
  background: #fff;
  padding: 0.125rem 0.5rem;
  font-size: 1rem;
  color: #1ca4fc;
  border-radius: 6px;
  margin-left: 1rem;
`;

export const CommentNumber = styled('span')`
  width: 40px;
  height: 20px;
  background: #f1f2f5;
  border-radius: 5px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 101.83%;
  letter-spacing: -0.01em;
  color: #1ca4fc;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
`;

export const CommentValue = styled('p')`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 101.83%;
  letter-spacing: -0.01em;
  color: #000000;
  word-break: break-all;
`;

export const OpenedCommentBox = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 500px;
  height: 420px;
  order: 1;
  flex-grow: 0;
  margin: 20px 0px;
  background: #f1f2f5;
  border-radius: 15px;
`;

export const NotSelected = styled('span')`
  color: #ff7086;
  font-weight: 500;
  margin-left: 5px;
  font-size: 20px;
`;

export const CommentsContainer = styled('div')`
  background-color: #f1f2f5;
  padding: 10px;
  border-radius: 15px;
  margin-top: 15px;
`;
