import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

const BadgeContainer = styled('div')`
  position: absolute;
  top: ${(props) => (props.panelVisible ? '-3px' : '-10px')};
  right: ${(props) => (props.panelVisible ? '46px' : '-14px')};
  background: #ff7086;
  color: #fff;
  padding: ${(props) => (props.value < 10 ? '1px 7px' : '3px 5px')};
  border-radius: 100px;
  font-weight: bold;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
`;

export const Badge = ({ value }) => {
  const isCommentPanelVisible = useSelector(
    (state) => state.presentation.isCommentsPanelVisible
  );

  return (
    <BadgeContainer panelVisible={isCommentPanelVisible} value={value}>
      {value}
    </BadgeContainer>
  );
};
