import React from 'react';

import { RightColumnContainer } from './styles';
import {
  OPEN_ENDED,
  PLOTTER_22,
  ADD_OPTION,
  POINT_DISTRIBUTION,
  SPECTRUM_DISTRIBUTION,
} from 'const/module-types';
import isPropsEqual from 'src/utils/isPropsEqual';

const isOpenEndedModule = ({ moduleType }) => moduleType === OPEN_ENDED;
const is2By2Module = ({ moduleType }) => moduleType === PLOTTER_22;
const addOptionModule = ({ moduleType }) => moduleType === ADD_OPTION;

const isPointDistroModule = ({ moduleType }) =>
  moduleType === POINT_DISTRIBUTION;
const isSpectrumModule = ({ moduleType }) =>
  moduleType === SPECTRUM_DISTRIBUTION;

const shouldRender = (prevProps, nextProps) => {
  if (
    nextProps.activeQuestion.moduleType === OPEN_ENDED &&
    nextProps.isWordCloud?.isWordCloudSelected
  )
    return;
  return isPropsEqual(prevProps.activeQuestion, nextProps.activeQuestion);
};

export const ChartContainer = React.memo(({ children, activeQuestion }) => {
  return (
    <RightColumnContainer
      isOpenEndedModule={isOpenEndedModule({
        moduleType: activeQuestion.moduleType || '',
      })}
      is2By2Module={is2By2Module({
        moduleType: activeQuestion.moduleType || '',
      })}
      isAddModule={addOptionModule({
        moduleType: activeQuestion.moduleType || '',
      })}
      isPointDistroModule={isPointDistroModule({
        moduleType: activeQuestion.moduleType || '',
      })}
      isSpectrumModule={isSpectrumModule({
        moduleType: activeQuestion.moduleType || '',
      })}
    >
      {children}
    </RightColumnContainer>
  );
}, shouldRender);
