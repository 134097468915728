import styled from 'styled-components';
import TrashImg from 'img/trash.svg';
import EditImg from 'img/edit-3.svg';

export const TrashPadding = styled('div')`
  padding: 0 20px;
  cursor: pointer;
  width: 100%;
`;

export const Trash = styled('div')`
  border-top: 1px dashed #000000;
  height: 88px;
  background: url('${TrashImg}') center/auto no-repeat;
`;

export const SurveysContainer = styled('div')`
  display: grid;
  width: 100%;
  grid-template-rows: minmax(270px, auto);
  grid-gap: 22px;
  margin: 0 0 22px 0;
  grid-template-columns: 100%;
  @media (min-width: 400px) {
    grid-template-columns: repeat(auto-fill, minmax(345px, 1fr));
  }
`;

export const MarginBottom = styled('div')`
  margin-bottom: ${(props) => (props.margin ? props.margin : '30')}px;
`;

export const TopRow = styled('div')`
  margin: 20px 0;
  display: grid;
  width: 100%;
  grid-gap: 22px;
  @media (min-width: 1200px) {
    grid-template-columns: 1fr 1fr minmax(345px, 1fr);
  }
`;

export const ProjectNameEditInput = styled('input')`
  font-family: Neue Haas Grotesk Display Std;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  background-color: white;
  outline: 0;
  padding: 0 10px;
  box-shadow: inset -1px 1px 3px rgba(0, 0, 0, 0.15);
  border: 0;
  width: 100%;
  height: 40px;
`;

export const ProjectNameEditIcon = styled('div')`
  position: absolute;
  top: 8px;
  right: 12px;
  background: url('${EditImg}') center / auto no-repeat;
  z-index: 2;
  width: 24px;
  height: 24px;
`;

export const ProjectNameContainer = styled('div')`
  position: relative;
  @media (min-width: 1200px) {
    grid-column-end: span 2;
  }
`;
