import React from "react";
import { VictoryLabel } from "victory";
import {
  XCollapseButton,
  YCollapseButton,
  axisLabel,
  numberLabel
} from "./styles";
import {
  PLOTTER22_QUADRANT_FOUR,
  PLOTTER22_QUADRANT_ONE,
  PLOTTER22_QUADRANT_THREE,
  PLOTTER22_QUADRANT_TWO
} from "../../../../../const/module-types";

const CustomXPositiveLabel = ({
  chunkyPx,
  pxy,
  chartWidth: CHART_WIDTH,
  onClick,
  xAxisToggle
}) => {
  return (
    <g>
      <foreignObject x={CHART_WIDTH - 30} y={pxy + 2} width="20" height="30">
        <XCollapseButton onClick={onClick} xAxisToggle={xAxisToggle}/>
      </foreignObject>
      <VictoryLabel
        text={chunkyPx}
        style={{ ...axisLabel, textAnchor: "end" }}
        angle={270}
        x={CHART_WIDTH + 10}
        y={pxy - 13}
        name="positiveXLabel"
        standalone
        width={CHART_WIDTH / 4}
        renderInPortal
      />
    </g>
  );
};

const CustomYPositiveLabel = ({
  chunkyPy,
  pyy,
  chartWidth: CHART_WIDTH,
  onClick,
  yAxisToggle
}) => (
  <g>
    <foreignObject
      x={CHART_WIDTH - (yAxisToggle ? 371 : 369)}
      y={pyy - 10}
      width="40"
      height="30"
    >
      <YCollapseButton onClick={onClick} yAxisToggle={yAxisToggle} />
    </foreignObject>
    <VictoryLabel
      text={chunkyPy}
      style={{ ...axisLabel, textAnchor: "middle" }}
      x={CHART_WIDTH / 2}
      y={pyy - 40}
      key="positiveYLabel"
      name="positiveYLabel"
      standalone={true}
      renderInPortal
    />
  </g>
);

const getXAxisLabels = ({
  chunkyPx,
  chunkyNx,
  pxy,
  nxy,
  chartWidth,
  onClick,
  isHidden,
  xAxisToggle
}) =>
  isHidden
    ? []
    : [
        <CustomXPositiveLabel
          chunkyPx={chunkyPx}
          pxy={pxy}
          key="positiveXLabel"
          chartWidth={chartWidth}
          onClick={onClick}
          xAxisToggle={xAxisToggle}
        />,
        <VictoryLabel
          text={chunkyNx}
          angle={270}
          style={axisLabel}
          x={5}
          y={nxy + 32}
          key="negativeXLabel"
          name="negativeXLabel"
          standalone
          width={chartWidth / 4}
          renderInPortal
        />
      ];

const getYAxisLabels = ({
  chunkyPy,
  chunkyNy,
  pyy,
  nyy,
  chartWidth,
  isHidden,
  onClick,
  yAxisToggle
}) =>
  isHidden
    ? []
    : [
        <CustomYPositiveLabel
          chunkyPy={chunkyPy}
          pyy={pyy}
          key="positiveYLabel"
          chartWidth={chartWidth}
          onClick={onClick}
          yAxisToggle={yAxisToggle}
        />,
        <VictoryLabel
          text={chunkyNy}
          style={{ ...axisLabel, textAnchor: "middle" }}
          x={chartWidth / 2}
          y={nyy + 40}
          key="negativeYLabel"
          name="negativeYLabel"
          standalone={true}
          renderInPortal
        />
      ];

const getCountQuadrantLabels = ({
  chartWidth,
  pyy,
  nyy,
  isHidden,
  plotter22LabelValues,
  participants
}) =>
  isHidden
    ? [
        <VictoryLabel
          text={participants}
          style={{ ...numberLabel, textAnchor: "middle" }}
          x={chartWidth - 50}
          y={pyy - 10}
          key="quadrant1Label"
          name="quadrant1Label"
          standalone={true}
          renderInPortal
        />
      ]
    : [
        <VictoryLabel
          text={plotter22LabelValues[PLOTTER22_QUADRANT_ONE]}
          style={{ ...numberLabel, textAnchor: "middle" }}
          x={chartWidth - 50}
          y={pyy - 10}
          key="quadrant1Label"
          name="quadrant1Label"
          standalone={true}
          renderInPortal
        />,
        <VictoryLabel
          text={plotter22LabelValues[PLOTTER22_QUADRANT_TWO]}
          style={{ ...numberLabel, textAnchor: "middle" }}
          x={50}
          y={pyy - 10}
          key="quadrant2Label"
          name="quadrant2Label"
          standalone={true}
          renderInPortal
        />,
        <VictoryLabel
          text={plotter22LabelValues[PLOTTER22_QUADRANT_THREE]}
          style={{ ...numberLabel, textAnchor: "middle" }}
          x={50}
          y={nyy}
          key="quadrant3Label"
          name="quadrant3Label"
          standalone={true}
          renderInPortal
        />,
        <VictoryLabel
          text={plotter22LabelValues[PLOTTER22_QUADRANT_FOUR]}
          style={{ ...numberLabel, textAnchor: "middle" }}
          x={chartWidth - 50}
          y={nyy}
          key="quadrant4Label"
          name="quadrant4Label"
          standalone={true}
          renderInPortal
        />
      ];

const chunkify = (label = "", CHUNKIFY_LIMIT = 15) => {
  return label.split(" ").reduce((collector, word) => {
    const [currentLine = "", ...otherLinesReversed] = [...collector].reverse();
    const otherLines = otherLinesReversed.reverse();
    const newLine = currentLine ? `${currentLine} ${word}` : word;
    if (newLine.length > CHUNKIFY_LIMIT) {
      return [...otherLines, currentLine, word].filter(x => x);
    }
    return [...otherLines, newLine];
  }, []);
};

const Plotter22Labels = ({
  axisLabels,
  chartWidth: CHART_WIDTH,
  chartHeight: CHART_HEIGHT,
  onClickCollapseXAxis,
  xAxisToggle,
  onClickCollapseYAxis,
  yAxisToggle,
  plotter22LabelValues,
  participants
}) => {
  const {
    positiveXLabel,
    negativeXLabel,
    positiveYLabel,
    negativeYLabel
  } = axisLabels;

  const chunkyPx = chunkify(positiveXLabel);
  const chunkyNx = chunkify(negativeXLabel);
  const chunkyPy = chunkify(positiveYLabel, 30);
  const chunkyNy = chunkify(negativeYLabel, 30);
  const HALF_CHART = CHART_HEIGHT / 2;
  const pxOff = 10 * chunkyPx.length;
  const nxOff = 10 * chunkyNx.length;
  const pxy = HALF_CHART + pxOff - 10;
  const nxy = HALF_CHART + nxOff;
  const pyy = 10 * chunkyPy.length;
  const nyOff = 10 * chunkyNy.length;
  const nyy = CHART_HEIGHT - nyOff;
  return [
    ...getXAxisLabels({
      chunkyPx,
      chunkyNx,
      pxy,
      nxy,
      chartWidth: CHART_WIDTH,
      onClick: onClickCollapseXAxis,
      isHidden: yAxisToggle,
      xAxisToggle
    }),
    ...getYAxisLabels({
      chunkyPy,
      chunkyNy,
      pyy,
      nyy,
      chartWidth: CHART_WIDTH,
      isHidden: xAxisToggle,
      onClick: onClickCollapseYAxis,
      yAxisToggle
    }),
    ...getCountQuadrantLabels({
      chartWidth: CHART_WIDTH,
      pyy,
      nyy,
      isHidden: xAxisToggle || yAxisToggle,
      plotter22LabelValues,
      participants
    })
  ];
};

export default Plotter22Labels;
