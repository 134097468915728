import {
  doesModuleHaveOptions,
  removeModuleOptions,
} from 'lib/Presentation/Helper';

/**
 * When the user has filled in options in to a populate module i.e stank rank, point distribution or Multi-select
 * and then they choose to populate from another module by set it the populate option toggle to true.
 * We then show them a dialog so that they confirm if they want to lose their options.
 * If they click okay we remove the options, if they click cancel we abort.
 * The options are finally deleted when they click save on the module.
 * If they click cancel on the module we reset the form and so not save the user's data.
 * @param values formik props values
 * @param moduleId module Id
 * @param value the populate toggle value i.e true/false
 * @param setFieldValue formik props setFieldValue Method
 * @param populateOptionsKey The key to update the populate toggle value in the formik values.
 */
export const handlePopulateToggle = (
  values,
  moduleId,
  value,
  setFieldValue,
  populateOptionsKey
) => {
  const { moduleList } = values;
  const module = moduleList[moduleId];
  const questionData = module.questionData;
  if (value && doesModuleHaveOptions(questionData)) {
    const confirmed = confirm(
      'This change will result in a lose of any hard-coded options already added to this module. Click OK to continue or Cancel to abort.'
    );
    if (confirmed) {
      const questionDataKey = `moduleList.${moduleId}.questionData`;
      const qnData = removeModuleOptions(questionData);
      setFieldValue(questionDataKey, qnData);
      setFieldValue(populateOptionsKey, value);
    }
  } else {
    setFieldValue(populateOptionsKey, value);
  }
};
