import React, { Component } from 'react';
import { saveSurveyResponseEmail } from '../../../lib/survey-api';
import { QuestionDataContainer, Button, isEmailValid } from '../../layout';
import styled from 'styled-components';
import { Question } from '../../SurveyTitle';
import { EnterCode } from 'pages/Landing/styles';
import { Description as LandingDescription } from 'pages/Landing/styles';
import { getNestedData } from '../../../const/nested-data';
import Tracking from 'lib/tracking/tracking';
import { surveyTakerEvents } from 'lib/tracking/events';

export const COMPLETE_PLACEHOLDER = `If you'd like to review your answers, enter your email and we'll send you a link.`;

export const CompleteQuestion = styled(Question)`
  @media (max-width: 1199px) {
    padding: 0;
  }
`;

const CompleteQuestionContainer = styled('div')`
  color: #ffffff;
  width: 100%;
`;

export default class Complete extends Component {
  state = {
    responseCode: '',
  };
  submitEmail = () => {
    const { surveyId, surveyCode } = this.props.surveyDetails;
    const { values, setValues, responseId } = this.props;
    const { surveyEmail, saving } = values;
    if (saving) return;
    setValues({
      ...values,
      saveAttempted: true,
    });
    if (!isEmailValid(surveyEmail)) return;
    setValues({
      ...values,
      saving: true,
    });
    return saveSurveyResponseEmail({
      surveyId,
      surveyCode,
      responseId,
      surveyEmail,
    }).then(({ responseCode }) => {
      Tracking.event({
        name: surveyTakerEvents.END_SURVEY_REVIEW_EMAIL_INPUT,
        properties: { savedEmail: surveyEmail },
      });
      setValues({
        ...values,
        saving: false,
      });
      this.setState({ responseCode });
    });
  };
  onSubmit = () => {
    Tracking.event({
      name: surveyTakerEvents.END_SURVEY_REVIEW_EMAIL_INPUT,
      properties: { submitedEmailWithButton: true },
    });
    return this.submitEmail();
  };
  detectEnter = (e) => {
    if (e.keyCode === 13) {
      Tracking.event({
        name: surveyTakerEvents.END_SURVEY_REVIEW_EMAIL_INPUT,
        properties: { submitedEmailWithEnterKey: true },
      });
      return this.submitEmail();
    }
  };
  renderHeader({ message }) {
    const { responseCode } = this.state;
    if (responseCode) {
      return <CompleteQuestion>Email Sent</CompleteQuestion>;
    }
    return (
      <>
        <CompleteQuestion>Thank you for taking the survey!</CompleteQuestion>
        <LandingDescription>{message}</LandingDescription>
      </>
    );
  }
  render() {
    const { responseCode } = this.state;
    const { values = {}, handleChange, surveyDetails = {} } = this.props;
    const emailKey = 'surveyEmail';
    const emailValue = getNestedData(values, emailKey) || '';
    const { thankYouMessage } = surveyDetails;
    const message = thankYouMessage ? thankYouMessage : COMPLETE_PLACEHOLDER;
    const { saving, saveAttempted } = values;
    let buttonText = 'Submit';
    if (saving) buttonText = 'Saving...';
    if (saveAttempted && !isEmailValid(emailValue))
      buttonText = 'Invalid email address, please try again';
    if (responseCode) buttonText = 'Email sent';
    const active = emailValue.length > 0 && !saving;
    return (
      <QuestionDataContainer>
        <CompleteQuestionContainer>
          {this.renderHeader({ message })}
          <EnterCode
            placeholder="Email Address"
            name={emailKey}
            onChange={handleChange}
            onKeyDown={this.detectEnter}
            value={emailValue}
            width="100%"
          />
          <Button
            background={active ? '#1CA4FC' : '#828282'}
            opacity={active ? 1 : 0.5}
            pointerEvents={active ? 'auto' : 'none'}
            margin="1.5rem 0 0 0"
            onClick={this.onSubmit}
          >
            {buttonText}
          </Button>
        </CompleteQuestionContainer>
      </QuestionDataContainer>
    );
  }
}
