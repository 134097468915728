import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import Tooltip from '@reach/tooltip';
import '@reach/tooltip/styles.css';
import '@reach/dialog/styles.css';
import { authCondition } from 'lib/auth-api';
import { fetchSurveyModulesAndResponses } from 'lib/survey-api';
import { sortModulesToArray, saveSurvey } from 'lib/builder-api';

import { setUserResponses } from 'pages/presentationSlice';

import Layout, { PageContainer } from 'components/layout';
import withAuthorization from 'components/with-authorization';
import Switcher from 'components/Switcher';
import Presentation from 'components/Presentation';
import WorkshopSurvey from 'components/workshop/survey';
import { PopulatePanel } from 'components/workshop/PopulatePanel/PopulatePanel';
import { ConfirmPopulateSelectionsDialog } from 'components/workshop/ConfirmPopulateSelectionsDialog';
import { ButtonBase } from 'components/buttons';

import {
  setCommentsPanelVisibility,
  setPopulatePanelVisibility,
} from 'pages/presentationSlice';

import {
  TEXT_SLIDE,
  POPULATE_MODULE,
  POPULATE_OPTIONS,
  SELECTED_POPULATE_OPTIONS_IDS,
  NON_PRESENTATION_MODULES,
} from 'const/module-types';

import { ADMIN } from 'const/routes';
import { getNestedData, setNestedData } from 'const/nested-data';
import { ReactComponent as IconPopulate } from 'img/ic_populate.svg';
import { ReactComponent as IconPopulateDisabled } from 'img/ic_populate_disabled.svg';

import { useAsyncResource } from '../utils/async';
import { cloneDeep } from 'lodash';
import {
  filterModuleOptionsByKeys,
  getModuleOptionsFromAnotherModule,
} from 'lib/Workshop';
import { HILOS } from 'const/index';
import { getOptionsForNextModuleFromAddOption } from 'lib/Presentation/AddOptions';
import Tracking from 'lib/tracking/tracking';
import { workshopEvents } from 'lib/tracking/events';
import useUserResponses from '../hooks/useUserResponses';
import useModuleList from '../hooks/useModuleList';

const SURVEY_MODE = 'survey';
const PRESENTATION_MODE = 'presentation';

const styles = {
  populateBtn: {
    background: '#F1F2F5',
    borderRadius: '10px',
    padding: '7px 15px 3px',
  },
  populateBtnContainer: {
    marginBottom: '1rem',
    marginLeft: 'auto',
  },
};

function Workshop({ builderFormikProps, currentUser }) {
  const { workshopCode, slide: _slide } = useParams();
  const history = useHistory();

  const dispatch = useDispatch();

  const isPopulatePanelVisible = useSelector(
    (state) => state.presentation.isPopulatePanelVisible
  );

  const userResponses = useSelector(
    (state) => state.presentation.userResponses
  );

  const responses = useUserResponses({
    surveyId: workshopCode,
  });

  const mList = useModuleList({ surveyId: workshopCode });

  const openPopulatePanel = () => {
    Tracking.event({ name: workshopEvents.POPULATE_PANEL_OPEN });
    dispatch(setCommentsPanelVisibility(false));
    dispatch(setPopulatePanelVisibility(!isPopulatePanelVisible));
  };

  const query = new URLSearchParams(history.location.search);

  const [
    showSavePopulateOptionsConfirmationDialog,
    setShowSavePopulateConfirmationDialog,
  ] = useState(false);
  const [selectedPopulateOptionsIds, setSelectedPopulateOptionsIds] = useState(
    null
  );

  const savePopulateDataAsync = useAsyncResource();

  const getDataAsync = useAsyncResource(async () => {
    const data = await fetchSurveyModulesAndResponses({
      surveyCode: workshopCode,
      showAllUsers: true,
    });
    // should probably set the builder data here from the same data source
    // serving the main builder, but the data from this source has the
    // same value structure which is not expected to change, and
    // therefore will be used to simplify logic.
    builderFormikProps.setValues(data);
    dispatch(setUserResponses(data.userResponses));
    return data;
  });

  const { surveyDetails = {}, moduleList = {} } =
    builderFormikProps.values || {};

  const mode = query.get('mode');
  const modules = sortModulesToArray(moduleList);
  const slide = parseInt(_slide, 10);
  const currentModuleIndex = slide - 1;
  const currentModule = modules.find(({ order }) => order === slide) || {};

  // we are currently using this for Add option user added options
  const formattedUserResponsesOptions = useSelector(
    (state) => state.presentation[currentModule.moduleId]
  );

  // current module properties
  const isNonPresentationModule = NON_PRESENTATION_MODULES.includes(currentModule?.moduleType); // prettier-ignore
  // Modules that can get options from other modules.
  const dependantPopulateModules = modules.filter(
    (module) =>
      module.questionData?.[POPULATE_MODULE]?.moduleId ===
        currentModule?.moduleId && module.questionData?.[POPULATE_OPTIONS]
  );
  const hasPopulateDependantModules = dependantPopulateModules.length > 0 && mode === PRESENTATION_MODE; // prettier-ignore
  const savedSelectedPopulateOptionsIdsKey = `moduleList.${currentModule?.moduleId}.questionData.${SELECTED_POPULATE_OPTIONS_IDS}`;
  const savedSelectedPopulateOptionsIds = getNestedData(builderFormikProps.values, savedSelectedPopulateOptionsIdsKey); // prettier-ignore
  const hasNotpopulatedDependantModules = hasPopulateDependantModules && !savedSelectedPopulateOptionsIds?.length; // prettier-ignore

  useEffect(() => {
    if (!mode || ![SURVEY_MODE, PRESENTATION_MODE].includes(mode)) {
      history.replace(`${history.location.pathname}?mode=${SURVEY_MODE}`);
    }
  }, [mode]);

  useEffect(() => {
    getDataAsync.execute();
  }, []);

  useEffect(() => {
    if (isNonPresentationModule && mode === PRESENTATION_MODE) {
      history.replace(`/workshop/${workshopCode}/${slide}?mode=${SURVEY_MODE}`);
    }
  }, [mode, isNonPresentationModule]);

  useEffect(() => {
    dispatch(setUserResponses(responses));
  }, [responses]);

  useEffect(() => {
    const values = builderFormikProps.values;
    builderFormikProps.setValues({ ...values, moduleList: mList });
  }, [mList]);

  useEffect(() => {
    const savedSelectedPopulateOptionsIds = getNestedData(
      builderFormikProps.values,
      savedSelectedPopulateOptionsIdsKey
    );
    setSelectedPopulateOptionsIds(savedSelectedPopulateOptionsIds);
  }, [savedSelectedPopulateOptionsIdsKey]);

  const goToPreviousModule = () => {
    const previousModule = modules.find(({ order }) => order === slide - 1);
    if (mode === SURVEY_MODE && NON_PRESENTATION_MODULES.includes(previousModule?.moduleType)) {
      // go to the survey mode of the previous module instead of its presentation
      history.push(`/workshop/${workshopCode}/${slide - 1}?mode=${SURVEY_MODE}`); // prettier-ignore
      return;
    } // prettier-ignore
    const newUrl =
      mode === SURVEY_MODE
        ? `/workshop/${workshopCode}/${slide - 1}?mode=${PRESENTATION_MODE}`
        : `/workshop/${workshopCode}/${slide}?mode=${SURVEY_MODE}`;
    history.push(newUrl);
  };

  const goToNextModule = () => {
    if (mode === SURVEY_MODE && isNonPresentationModule) {
      // if it is the last slide, do nothing
      if (slide === modules.length) return;
      history.push(`/workshop/${workshopCode}/${slide + 1}?mode=${SURVEY_MODE}`); // prettier-ignore
      return;
    }
    const newUrl =
      mode === SURVEY_MODE
        ? `/workshop/${workshopCode}/${slide}?mode=${PRESENTATION_MODE}`
        : `/workshop/${workshopCode}/${slide + 1}?mode=${SURVEY_MODE}`;
    history.push(newUrl);
  };

  const populateDependantModules = (data) => {
    let newData = cloneDeep(data);
    // Modules that can get options from other modules.
    dependantPopulateModules.forEach((module) => {
      const questionDataKey = `moduleList.${module.moduleId}.questionData`;
      const optionsDataFromMasterModule = getModuleOptionsFromAnotherModule(module, currentModule); // prettier-ignore
      const optionsFromMasterModule = filterModuleOptionsByKeys(
        optionsDataFromMasterModule,
        selectedPopulateOptionsIds
      );
      const addOptionUserOptions = getOptionsForNextModuleFromAddOption(
        formattedUserResponsesOptions?.chartData || [],
        module.moduleType,
        selectedPopulateOptionsIds
      );
      const updatedQuestionData = {
        ...getNestedData(newData, questionDataKey),
        ...optionsFromMasterModule, // prettier-ignore
        ...addOptionUserOptions,
      };
      setNestedData(newData, questionDataKey, updatedQuestionData);
      newData = cloneDeep(newData);
    });
    return newData;
  };

  /**
   * Save the selected options in to the next module that is being populated.
   * @returns {Promise<void>}
   */
  const savePopulateData = async () => {
    const { values: _values } = builderFormikProps;
    let values = populateDependantModules(_values);
    setNestedData(values, savedSelectedPopulateOptionsIdsKey, selectedPopulateOptionsIds); // prettier-ignore
    values = cloneDeep(values);
    const { moduleList, surveyDetails } = values;
    await savePopulateDataAsync.execute(async () => {
      await saveSurvey({
        surveyId: workshopCode,
        surveyCode: workshopCode,
        moduleList,
        surveyDetails,
        userId: currentUser?.id,
        template: false,
        moduleId: currentModule.moduleId,
      });
      await getDataAsync.execute();
      setShowSavePopulateConfirmationDialog(false);
      goToNextModule();
    });
  };

  const onLogoClick = () => {
    Tracking.event({ name: workshopEvents.IF_ICON_CLICKED });
    history.push(ADMIN);
  };

  const onCloseClick = () => {
    Tracking.event({ name: workshopEvents.CLOSE_WORKSHOP });
    history.push(ADMIN);
  };

  const renderControls = () => {
    // this is dependent on current module's nav position/mode and
    // if it is filled if it has module dependants
    const rightSwitchIsDisabled =
      (currentModuleIndex === modules.length - 1 &&
        (isNonPresentationModule
          ? mode === SURVEY_MODE
          : mode === PRESENTATION_MODE)) ||
      (hasPopulateDependantModules && !selectedPopulateOptionsIds?.length);

    const leftSwitchIsDisabled = currentModuleIndex === 0 && mode === SURVEY_MODE; // prettier-ignore

    const handleLeftSwitchClick = () => {
      Tracking.event({ name: workshopEvents.QN_SWITCHER_LEFT });
      if (leftSwitchIsDisabled) return;
      goToPreviousModule();
    };

    const handleRightSwitchClick = () => {
      Tracking.event({ name: workshopEvents.QN_SWITCHER_RIGHT });
      if (rightSwitchIsDisabled) return;
      if (hasNotpopulatedDependantModules) {
        setShowSavePopulateConfirmationDialog(true);
        return;
      } // prettier-ignore
      goToNextModule();
    };

    const handleItemSelect = (item) => {
      Tracking.event({ name: workshopEvents.QN_SWITCHER_DROPDOWN });
      if (hasNotpopulatedDependantModules && item.order > slide) {
        return;
      }
      history.push(`/workshop/${workshopCode}/${item.order}?mode=survey`);
    };

    return (
      <Switcher
        items={modules}
        selectedItem={currentModule}
        leftSwitchIsDisabled={leftSwitchIsDisabled}
        rightSwitchIsDisabled={rightSwitchIsDisabled} // prettier-ignore
        onSelectItem={handleItemSelect}
        itemRender={({ item, index }) =>
          hasNotpopulatedDependantModules &&
          index &&
          index > currentModuleIndex ? (
            <Tooltip
              style={{ zIndex: 101 }}
              label="Please populate and save options before continuing"
            >
              <span
                css={{ color: '#c4c4c4' }}
              >{`${item.order}. ${item.question}`}</span>
            </Tooltip>
          ) : (
            <span>{`${item.order}. ${item.question}`}</span>
          )
        }
        itemSelectionDisabled={({ item }) =>
          hasNotpopulatedDependantModules && item.order > slide
        }
        onClickLeftSwitch={handleLeftSwitchClick}
        onClickRightSwitch={handleRightSwitchClick}
        renderLeftSwitchBtn={({ defaultIconRender, defaultStyle }) => (
          <Tooltip label="Previous question">
            <ButtonBase
              disabled={leftSwitchIsDisabled}
              css={defaultStyle}
              onClick={handleLeftSwitchClick}
            >
              {defaultIconRender()}
            </ButtonBase>
          </Tooltip>
        )}
        renderRightSwitchBtn={({ defaultIconRender, defaultStyle }) => (
          <Tooltip
            label={
              hasPopulateDependantModules && !selectedPopulateOptionsIds?.length
                ? 'Please populate options before continuing'
                : 'Next question'
            }
          >
            <div>
              <ButtonBase
                disabled={rightSwitchIsDisabled}
                css={defaultStyle}
                onClick={handleRightSwitchClick}
              >
                {defaultIconRender()}
              </ButtonBase>
            </div>
          </Tooltip>
        )}
      />
    );
  };

  return (
    <>
      <Layout
        inFacilitationMode
        onClose={onCloseClick}
        onLogoClick={onLogoClick}
        title={surveyDetails.name}
        background={currentModule?.moduleType === TEXT_SLIDE && '#262525'}
      >
        <PageContainer>
          {getDataAsync.state.isLoading() && !getDataAsync.data ? (
            <p css={{ margin: 'auto' }}>Loading...</p>
          ) : (
            <div css={{ width: '100%', height: '100%', position: 'relative' }}>
              {mode === SURVEY_MODE ? (
                <WorkshopSurvey
                  module={currentModule}
                  moduleList={moduleList}
                  userResponses={userResponses}
                  renderControls={renderControls}
                  onClickWaitingRoomRefresh={() => getDataAsync.execute()}
                />
              ) : (
                <Presentation
                  currentUser={currentUser}
                  getDataAsync={getDataAsync}
                  moduleList={moduleList}
                  userResponses={userResponses}
                  renderControls={renderControls}
                  mode={HILOS.WORKSHOP}
                  initialSelectedModuleId={currentModule.moduleId}
                  renderWorkshopPopulateBtn={() => (
                    <Tooltip
                      label={
                        hasNotpopulatedDependantModules
                          ? 'Populate options'
                          : 'Options have already been selected for population'
                      }
                    >
                      <span css={styles.populateBtnContainer}>
                        <ButtonBase
                          css={styles.populateBtn}
                          disabled={!hasNotpopulatedDependantModules}
                          onClick={() => {
                            if (!hasNotpopulatedDependantModules) return;
                            openPopulatePanel();
                          }}
                        >
                          {hasNotpopulatedDependantModules ? (
                            <IconPopulate />
                          ) : (
                            <IconPopulateDisabled />
                          )}
                        </ButtonBase>
                      </span>
                    </Tooltip>
                  )}
                />
              )}
              {isPopulatePanelVisible && (
                <PopulatePanel
                  module={currentModule}
                  getDataAsync={getDataAsync}
                  savedSelectedOptions={selectedPopulateOptionsIds}
                  onSave={(selectedOptions) => {
                    Tracking.event({ name: workshopEvents.SAVE_OPTIONS });
                    setSelectedPopulateOptionsIds(selectedOptions);
                    dispatch(setPopulatePanelVisibility(false));
                  }}
                />
              )}
            </div>
          )}
        </PageContainer>
      </Layout>
      {showSavePopulateOptionsConfirmationDialog && (
        <ConfirmPopulateSelectionsDialog
          module={currentModule}
          selectedOptionsIds={selectedPopulateOptionsIds}
          isOpen={showSavePopulateOptionsConfirmationDialog}
          onDismiss={() => setShowSavePopulateConfirmationDialog(false)}
          userAddedOptions={formattedUserResponsesOptions}
          onSave={() => savePopulateData()}
        />
      )}
    </>
  );
}

function FormikWrappedWorkshop(props) {
  return (
    <Formik
      enableReinitialize
      initialValues={{}}
      onSubmit={() => {}}
      render={(formikProps) => {
        return <Workshop {...props} builderFormikProps={formikProps} />;
      }}
    />
  );
}

export default withAuthorization(authCondition)(FormikWrappedWorkshop);
