import React from 'react';
import styled from "styled-components";
import {CreateHiloButton} from "../index";
import { HILOS } from 'const/index';

const CreateHiloContainer = styled('div')`
  display: flex;
  width: fit-content;
`;

const CreateHeading = styled('h1')`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 36px;
  letter-spacing: -0.01em;
  color: #1CA4FC;
  margin: 0;
  margin-right: 20px;
  display: flex;
  align-items: center;
`;

const Spacing = styled('div')`
  margin-right: 20px;
`;

/**
 * Home options to create either a workshop or survey
 * @param onClick
 * @returns {JSX.Element}
 * @constructor
 */
const CreateHilo = ({ onClick }) => {
    return (
        <CreateHiloContainer>
            <CreateHeading>
                Create
            </CreateHeading>
            <CreateHiloButton onClick={() => onClick({ hilo : HILOS.SURVEY })}>
                Survey
            </CreateHiloButton>
            <Spacing />
            <CreateHiloButton isSurvey={false} onClick={() => onClick({ hilo: HILOS.WORKSHOP })}>
                Workshop
            </CreateHiloButton>
        </CreateHiloContainer>
    )
}

export default CreateHilo;