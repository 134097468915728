import React from 'react';
import Tooltip from '@reach/tooltip';
import styled from 'styled-components';

const NoResponses = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const formatLongAxisLabel = (label, specialCase = false) => {
  if (label.length <= 20) return label;
  if (specialCase) {
    return `${label.slice(0, 15)} ...`;
  }
  return `${label.slice(0, 15)} ...`;
};

export const LabelTextComp = (props) => {
  const { children, ...others } = props;
  return (
    <foreignObject
      width="100"
      height="100"
      {...others}
      x="-35"
      y={others.y - 6}
    >
      {children}
    </foreignObject>
  );
};

export const LabelTspanComp = (props) => {
  return (
    <Tooltip label={props.children}>
      <p
        css={{
          pointerEvents: 'all',
          fontSize: '8px',
          fontFamily: 'Roboto',
          fontWeight: 'bold',
          margin: props.children.length > 15 ? 0 : 'auto',
          cursor: 'pointer',
          width: '100%',
          textAlign: 'end',
        }}
      >
        {formatLongAxisLabel(props.children, true)}
      </p>
    </Tooltip>
  );
};

export const NoResponse = ({ data }) => {
  if (!data || data.length === 0) {
    return <NoResponses>No one has responded to your question yet</NoResponses>;
  }
  return null;
};
