import React from 'react';
import { DialogContent, DialogOverlay } from '@reach/dialog';
import '@reach/dialog/styles.css';
import styled from 'styled-components';

const CreateDialog = styled(DialogOverlay)`
  overflow-y: hidden !important;
  z-index: 100;
  display: flex;

  [data-reach-dialog-overlay] {
    opacity: 0.3;
  }

  > [data-reach-dialog-content] {
    width: ${(props) => (props.width ? props.width : '550px')};
    height: ${(props) => (props.height ? props.height : '350px')};
    margin: auto;
    padding: 0;
    border: ${(props) => (props.border ? props.border : '4px solid #1ca4fc')};
    border-radius: 10px;
    filter: drop-shadow(0px 0px 15.1521px rgba(0, 0, 0, 0.3));
  }
`;

/**
 * Hilo Dialog used to create Dialogs in this application
 * @param hilo
 * @param isOpen
 * @param onDismiss
 * @returns {JSX.Element}
 */
export const HiloDialog = ({
  children,
  ariaLabel,
  dialogBorder,
  isOpen = false,
  onDismiss,
  width,
  height,
}) => {
  return (
    <CreateDialog
      border={dialogBorder}
      isOpen={isOpen}
      onDismiss={onDismiss}
      width={width}
      height={height}
    >
      <DialogContent aria-label={ariaLabel}>{children}</DialogContent>
    </CreateDialog>
  );
};
