import get from 'lodash/get';
import { cloneDeep } from 'lodash';
import { db, storage, rdb, serverTimestamp } from '../firebase';
import {
  MODULE_TYPES,
  ROW_PREFIX,
  COLUMN_PREFIX,
  OPTION_PREFIX,
  TEXT_SLIDE,
  MULTI_SELECT,
} from '../const/module-types';
import {
  canCurrentUserEditSurvey,
  removeOptionsFromPopulateModule,
} from './utils';
import Tracking from './tracking/tracking';

const modulesCollection = db.collection('modules');
const surveysCollection = db.collection('surveys');
const projectsCollection = db.collection('projects');

export const fetchSurvey = (options = {}) => {
  const { surveyId } = options;
  if (!surveyId) throw new Error('please provide a surveyId');
  return surveysCollection.doc(surveyId).get();
};

// get modules
export const fetchSurveyModules = (options = {}) => {
  const { surveyId } = options;
  if (!surveyId) throw new Error('please provide a surveyId');
  return modulesCollection
    .where('surveyId', '==', surveyId)
    .orderBy('order')
    .get();
};

// create module
export const createSurveyModule = (options = {}) => {
  const {
    projectId,
    surveyId,
    moduleType,
    question = '',
    order = 0,
    questionData = {},
  } = options;
  if (!projectId) throw new Error('please provide a projectId');
  if (!surveyId) throw new Error('please provide a surveyId');
  if (!moduleType) throw new Error('please provide a module type');
  if (!MODULE_TYPES.includes(moduleType))
    throw new Error('unknown module type');

  const module = modulesCollection.doc();
  const survey = surveysCollection.doc(surveyId);
  const timestamp = serverTimestamp();

  const moduleDetails = {
    // include projectId so we can look up user permissions a bit faster
    projectId,
    surveyId,
    moduleType,
    order,
    question,
    questionData,
    timestamp,
  };
  const surveyDetails = {
    [`modules.${module.id}`]: true,
    timestamp,
  };

  const batch = db.batch();
  batch.set(module, moduleDetails);
  batch.update(survey, surveyDetails);
  return batch.commit();
};

export const fetchSurveyAndModules = (options = {}) => {
  console.log('fetching survey');
  return fetchSurvey(options).then((survey) => {
    console.log('fetching modules');
    return fetchSurveyModules(options).then((modules) => {
      console.log('load complete');
      const surveyId = survey.id;
      const surveyData = survey.data();
      const surveyDetails = {
        id: surveyId,
        surveyId,
        ...surveyData,
      };
      const moduleList = modules.docs.reduce((collector, doc) => {
        const moduleId = doc.id;
        return {
          ...collector,
          [moduleId]: {
            moduleId,
            ...doc.data(),
          },
        };
      }, {});
      return { surveyDetails, moduleList };
    });
  });
};

export const deleteModule = (options = {}) => {
  const { moduleId, surveyId } = options;
  if (!moduleId) throw new Error('please provide a moduleId');
  if (!surveyId) throw new Error('please provide a surveyId');
  const batch = db.batch();
  const timestamp = serverTimestamp();
  const module = modulesCollection.doc(moduleId);
  const survey = surveysCollection.doc(surveyId);
  const surveyDetails = {
    [`modules.${moduleId}`]: false,
    timestamp,
  };
  batch.update(survey, surveyDetails);
  batch.delete(module);
  return batch.commit();
};

const deleteUnwantedFieldsFromModuleData = ({ moduleData }) => {
  if (moduleData.file) delete moduleData.file;
  if (moduleData.deleteImageUrl) delete moduleData.deleteImageUrl;
  return moduleData;
};

const updateEmptyMultiSelectTitle = (moduleType, moduleData) => {
  if (
    moduleType === MULTI_SELECT &&
    !moduleData.removed &&
    moduleData.title === ''
  ) {
    if (
      (moduleData.file && moduleData.file !== null) ||
      (moduleData.imageUrl && moduleData.imageUrl !== null)
    ) {
      moduleData.title = 'Option';
    } else {
      moduleData.removed = true;
    }
  }
  return moduleData;
};

const updateMultiSelectField = (key, value) => {
  if (!key.startsWith('option')) {
    return value;
  }
  let valueCopy = cloneDeep(value);
  valueCopy = deleteUnwantedFieldsFromModuleData({ moduleData: valueCopy });
  valueCopy = updateEmptyMultiSelectTitle(MULTI_SELECT, valueCopy);
  return valueCopy;
};

export const filterEmptyOptions = (module) => {
  const { questionData, moduleType } = module;
  if (!questionData) return module;
  const filteredModuleQuestionData = Object.entries(questionData).reduce(
    (collector, [key, value]) => {
      const hasEmptyValue = (value === "" || value === void 0 || value === null); // prettier-ignore
      if (key.indexOf('option') === 0 && hasEmptyValue) return collector;
      let finalValue = value;
      if (moduleType === MULTI_SELECT)
        finalValue = updateMultiSelectField(key, value);
      return {
        ...collector,
        [key]: finalValue,
      };
    },
    {}
  );
  const filteredModule = {
    ...module,
    questionData: filteredModuleQuestionData,
  };
  return filteredModule;
};

/**
 * Create a path in Firebase cloud storage for where to save the option image file.
 * Then update the option within the module question data with the image url.
 * @param moduleId
 * @param filePath
 * @param moduleOptionKey
 * @param file
 * @returns {Promise<void>}
 */
const uploadImageToFirebaseStorage = async ({
  moduleId,
  filePath,
  moduleOptionKey,
  file,
}) => {
  const timestamp = new Date().getTime();
  const rootRef = storage.ref();
  const imageRef = rootRef.child(
    `surveys/images/${moduleId}/${timestamp}-${filePath}`
  );
  const imageUrlUpdateField = `questionData.${moduleOptionKey}.imageUrl`;
  const metadata = {
    customMetadata: {
      filePath,
      moduleId,
      moduleOptionKey,
    },
  };
  return imageRef
    .put(file, metadata)
    .then((snapshot) => imageRef.getDownloadURL())
    .then((imageUrl) =>
      modulesCollection
        .doc(moduleId)
        .update({ [imageUrlUpdateField]: imageUrl })
    );
};

/**
 * Upload a file attached to the module option if any in the module questionData.
 * We are only handling the multi-select module for image uploads
 * @param moduleList
 * @returns {Promise<void>}
 */
const handleModuleImageUpload = ({ moduleList = [] }) => {
  const modulesImagesUpload = moduleList
    .filter(({ moduleType, questionData }) => {
      return moduleType === MULTI_SELECT && !!questionData;
    })
    .reduce((collector, { moduleId, questionData }) => {
      const uploads = Object.values(questionData)
        .filter(Boolean)
        .filter((option) => !!option.file)
        .map(({ file, key }) =>
          uploadImageToFirebaseStorage({
            moduleId: moduleId,
            filePath: file?.path,
            moduleOptionKey: key,
            file: file,
          })
        );
      return [...collector, ...uploads];
    }, []);
  return Promise.all(modulesImagesUpload);
};

const handleModuleImageDeletion = async ({ moduleList }) => {
  if (!moduleList || moduleList.length === 0) return;

  const multiSelectModules = moduleList.filter(
    (module) => module.moduleType === MULTI_SELECT && module.questionData
  );

  if (multiSelectModules.length === 0) return;

  try {
    let promises = [];

    for (let module of multiSelectModules) {
      const { questionData } = module;

      if (!questionData) continue;

      const options = Object.values(questionData);

      const optionPromises = options.map((option) => {
        if (!option?.deleteImageUrl) return Promise.resolve();
        const httpsFileReference = storage.refFromURL(option?.deleteImageUrl);
        return httpsFileReference.delete();
      });
      promises = [...promises, ...optionPromises];
    }

    await Promise.all(promises);
  } catch (e) {
    console.error('Error deleting file...', e);
  }
};

export const saveSurvey = async (options = {}) => {
  const {
    projectId,
    surveyId,
    moduleId,
    moduleList,
    surveyDetails,
    surveyCode,
    modulesToDelete,
    draft = false,
    template,
    userId = '',
    isTemplate,
    showPublishedOverlay = false,
  } = options;
  if (!surveyId) throw new Error('please provide a surveyId');
  if (!moduleList) throw new Error('please provide a moduleList');
  if (!isTemplate && !surveyCode)
    throw new Error('please provide a surveyCode');
  const survey = surveysCollection.doc(surveyId);
  const batch = db.batch();
  const timestamp = serverTimestamp();
  let surveyDescription = '';
  const optionsModuleList = removeOptionsFromPopulateModule(
    moduleId,
    moduleList
  );
  const sortedModules = sortModulesToEntriesArray(optionsModuleList);
  const moduleListCopy = cloneDeep(Object.values(optionsModuleList));
  const moduleChanges = sortedModules.reduce((collector, [key, module]) => {
    const { order, moduleType, description } = module;
    surveyDescription = surveyDescription || description || '';
    return {
      ...collector,
      [`modules.${module.moduleId}`]: { order, moduleType },
    };
  }, {});
  const { modules, ...otherSurveyDetails } = surveyDetails;
  let unpublished = draft === false ? false : surveyDetails.unpublished;

  if (options.isTemplate) {
    unpublished = false;
  }

  let saveSurveyDetails = {
    ...otherSurveyDetails,
    ...moduleChanges,
    draftDetails: false,
    draftModules: false,
    timestamp,
    userId,
    template,
    surveyDescription,
    draft,
    unpublished,
  };
  if (showPublishedOverlay) {
    saveSurveyDetails.publishOverlayDisplayed = true;
  }
  const draftOrRealDetails = saveSurveyDetails; // draft ? draftDetails : saveSurveyDetails;
  batch.update(survey, draftOrRealDetails);
  if (true /*!draft*/) {
    sortedModules.forEach(([key, module]) => {
      const moduleDoc = modulesCollection.doc(module.moduleId);
      const filteredModule = filterEmptyOptions(module);
      const moduleDetails = {
        ...filteredModule,
        timestamp,
      };
      if (isTemplate) {
        delete moduleDetails.projectId;
      }
      batch.set(moduleDoc, moduleDetails);
    });
  }
  Object.keys(modulesToDelete || {}).forEach((moduleId) => {
    const moduleToDelete = modulesCollection.doc(moduleId);
    batch.update(moduleToDelete, { deleted: true });
  });
  await batch.commit();
  await handleModuleImageUpload({ moduleList: moduleListCopy });
  handleModuleImageDeletion({ moduleList: moduleListCopy });
  return Promise.resolve();
};

export const launchSurvey = (options = {}) => {
  const { surveyId, userId } = options;
  if (!surveyId) throw new Error('please provide a surveyId');
  if (!userId) throw new Error('please provide a userId');
  const survey = surveysCollection.doc(surveyId);
  const batch = db.batch();
  const timestamp = serverTimestamp();
  batch.update(survey, {
    draft: false,
    unpublished: false,
    userId,
    timestamp,
  });
  return batch.commit();
};

export const updateSurveyClosed = (options = {}) => {
  const {
    surveyId,
    userId,
    closed,
    shouldIncludeTimestamp = true,
    hilo,
  } = options;
  if (!surveyId) throw new Error('please provide a surveyId');
  const survey = surveysCollection.doc(surveyId);
  const batch = db.batch();
  const timestamp = serverTimestamp();
  let saveSurveyDetails = {
    ...((shouldIncludeTimestamp && { timestamp }) || {}),
    userId,
    closed,
  };
  if (closed === false) {
    saveSurveyDetails.closeDate = '';
    saveSurveyDetails.unpublished = false;
  }
  batch.update(survey, saveSurveyDetails);
  Tracking.launchOrCloseSurvey({ hilo, properties: { surveyId, closed } });
  return batch.commit();
};

export const updateSurveyCloseDate = (options = {}) => {
  const {
    projectId,
    surveyId,
    userId,
    closeDate,
    survey: propsSurvey,
  } = options;
  if (!projectId) throw new Error('please provide a projectId');
  if (!surveyId) throw new Error('please provide a surveyId');
  if (!propsSurvey) throw new Error('please provide a survey');
  const {
    name: surveyName,
    surveyCode,
    draft,
    unpublished,
    closed,
  } = propsSurvey;
  const survey = surveysCollection.doc(surveyId);
  const batch = db.batch();
  const timestamp = serverTimestamp();
  const saveSurveyDetails = {
    timestamp,
    userId,
    draft,
    unpublished,
    closed,
    closeDate,
  };
  batch.update(survey, saveSurveyDetails);
  const project = projectsCollection.doc(projectId);
  const projectDetails = {
    [`surveys.${surveyId}`]: {
      timestamp,
      userId,
      name: surveyName,
      surveyCode,
      draft,
      unpublished,
      closed,
      closeDate,
    },
    timestamp,
  };
  batch.update(project, projectDetails);
  return batch.commit();
};

export const getNewModuleId = (options = {}) => {
  const module = modulesCollection.doc();
  return module.id;
};

export const deleteSurvey = (options = {}) => {
  const { projectId, surveyId } = options;
  if (!surveyId) throw new Error('please provide a surveyId');
  if (!projectId) throw new Error('please provide a projectId');
  const batch = db.batch();
  const survey = surveysCollection.doc(surveyId);
  batch.delete(survey);
  return batch.commit();
};

export const sortModules = (moduleList = {}, options = {}) => {
  const { includeDeleted = false } = options;
  let currentOrder = 1;
  return Object.entries(moduleList)
    .filter(([key, data]) => {
      if (includeDeleted) return true;
      return !data.deleted;
    })
    .sort(([aKey, aData], [bKey, bData]) => {
      const aOrder = aData.order;
      const bOrder = bData.order;
      if (aOrder !== bOrder) return aOrder - bOrder;
      const aTimestamp = get(aData, 'timestamp.seconds');
      const bTimestamp = get(bData, 'timestamp.seconds');
      return aTimestamp - bTimestamp;
    })
    .reduce((collector, [moduleId, moduleData], i) => {
      const order = i + 1;
      const displayOrder = currentOrder++;
      return {
        ...collector,
        [moduleId]: {
          ...moduleData,
          moduleId,
          order,
          displayOrder,
        },
      };
    }, {});
};

export const sortModulesToArray = (moduleList, options) => {
  return Object.values(sortModules(moduleList, options)).sort(
    (aData, bData) => {
      const aOrder = aData.order;
      const bOrder = bData.order;
      return aOrder - bOrder;
    }
  );
};

export const sortModulesToEntriesArray = (moduleList) => {
  return Object.entries(sortModules(moduleList)).sort((aData, bData) => {
    const aOrder = aData.order;
    const bOrder = bData.order;
    return aOrder - bOrder;
  });
};

export const getLastSlide = (moduleList) => {
  const lastModule = sortModulesToArray(moduleList).slice(-1)[0];
  const { order: lastSlide } = lastModule || {};
  return lastModule ? parseInt(lastSlide, 10) : moduleList.length;
};

export const getNumericKeys = (moduleResponse) => {
  const numericKeys = Object.keys(moduleResponse).filter((key) =>
    key.match(/^\d/)
  );
  return numericKeys;
};

export const getRowKeys = (questionData) => {
  const rowKeys = Object.keys(questionData).filter(
    (key) => key.indexOf(ROW_PREFIX) === 0
  );
  return rowKeys;
};

export const getColumnKeys = (questionData) => {
  const columnKeys = Object.keys(questionData).filter(
    (key) => key.indexOf(COLUMN_PREFIX) === 0
  );
  return columnKeys;
};

export const getOptionKeys = (questionData) => {
  const optionKeys = Object.keys(questionData).filter(
    (key) => key.indexOf(OPTION_PREFIX) === 0
  );
  return optionKeys;
};

export const fetchProject = (options = {}) => {
  const { projectId } = options;
  if (!projectId) throw new Error('please provide a projectId');
  return projectsCollection.doc(projectId).get();
};

export const LOCK_THRESHOLD = 1000 * 60 * 1;

export const fetchLock = ({ surveyId }) => {
  if (!surveyId) throw new Error('please provide a surveyId');
  //const lock = locksCollection.doc(surveyId);
  const lock = rdb
    .ref('/locks/' + surveyId)
    .once('value')
    .then((snapshot) => {
      return snapshot.val();
    });
  return lock;
};

export const createUpdateLock = (options = {}) => {
  const { surveyId, userId, userName } = options;
  if (!surveyId) throw new Error('please provide a surveyId');
  if (!userId) throw new Error('please provide a userId');
  if (!userName) throw new Error('please provide a userName');
  //const lock = locksCollection.doc(surveyId);
  const timestamp = new Date().getTime();

  const lockDetails = {
    surveyId,
    userId,
    userName,
    timestamp,
  };

  const lock = rdb.ref('/locks/' + surveyId);
  return lock.set(lockDetails);
};

export const deleteLock = (options = {}) => {
  const { surveyId, userId, userName } = options;
  if (!surveyId) throw new Error('please provide a surveyId');
  if (!userId) throw new Error('please provide a userId');
  if (!userName) throw new Error('please provide a userName');
  /*
  const lock = locksCollection.doc(surveyId);
  const batch = db.batch();
  batch.delete(lock);
  return batch.commit();
  */
  const lock = rdb.ref('/locks/' + surveyId);
  return lock.set(null);
};

export const fetchSurveyModuleAndProjectDetails = (options = {}) => {
  const sfetch = fetchSurvey(options);
  const mfetch = fetchSurveyModules(options);
  const lfetch = fetchLock(options);
  return sfetch
    .then((survey) => {
      const { projectId } = survey.data() || {};
      let pfetch;
      if (options.isTemplate) {
        pfetch = Promise.resolve(true);
      } else {
        pfetch = fetchProject({ ...options, projectId });
      }
      return Promise.all([Promise.resolve(survey), mfetch, pfetch, lfetch]);
    })
    .then(([survey, modules, project, surveyLock]) => {
      const surveyId = survey.id;
      const surveyData = survey.data();
      const surveyDetails = {
        surveyId,
        ...surveyData,
      };
      const moduleList = modules.docs.reduce((collector, doc) => {
        const moduleId = doc.id;
        return {
          ...collector,
          [moduleId]: {
            moduleId,
            ...doc.data(),
          },
        };
      }, {});
      let projectDetails;
      if (options.isTemplate) {
        projectDetails = {};
      } else {
        const projectData = project.data();
        const projectId = project.id;
        projectDetails = {
          projectId,
          ...projectData,
        };
      }
      return { surveyDetails, moduleList, projectDetails, surveyLock };
    });
};

export const getIsPastCloseDate = ({ closeDate }) => {
  if (!closeDate) return false;
  const date = new Date(closeDate);
  const now = new Date();
  return now > date;
};

export const getIsClosed = ({ closed, closeDate, unpublished }) => {
  if (closed === true) return true;
  if (unpublished === true) return true;
  if (closeDate) {
    return getIsPastCloseDate({ closeDate });
  }
  return false;
};

export const getSurveyTitle = (survey) => {
  const draft = get(survey, 'draft');
  const surveyName = get(survey, 'name');
  const draftName = get(survey, 'draftDetails.name');
  return draft ? draftName || surveyName : surveyName;
};

// preview tabs
export const DESKTOP = 'DESKTOP';
export const MOBILE = 'MOBILE';

export const getIsBeforeExpireDate = ({ timestamp }) => {
  const timestampDate =
    timestamp && timestamp.toDate ? timestamp.toDate() : new Date(timestamp);
  const now = new Date();
  const expireDate = timestampDate
    ? new Date(timestampDate.getTime() + LOCK_THRESHOLD + 15000)
    : 0; // 15 seconds to account for network delay
  const isBeforeExpire = now < expireDate;
  return isBeforeExpire;
};

export const getEditDisabled = ({
  currentUser,
  surveyDetails,
  surveyLocks,
  isAllSurveys,
}) => {
  if (isAllSurveys) {
    return !canCurrentUserEditSurvey(surveyDetails, currentUser.id);
  }
  const surveyId = get(surveyDetails, 'surveyId') || get(surveyDetails, 'id');
  const surveyLock = get(surveyLocks, surveyId);
  const lockedBy = get(surveyLock, 'userId');
  const userId = get(currentUser, 'id');
  const timestamp = get(surveyLock, 'timestamp');
  const isBeforeExpire = getIsBeforeExpireDate({ timestamp });
  const locked = surveyLock;
  if (!locked) return false;
  const notLockedByMe = userId !== lockedBy;
  if (notLockedByMe && isBeforeExpire) {
    return { ...surveyLock, notLockedByMe, isBeforeExpire };
  }
  return false;
};
