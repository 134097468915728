import React, { useState, useEffect, useRef } from 'react';
import { ButtonBase } from 'components/buttons';
import Loader from 'react-loader-spinner';
import { styles } from './styles';
import Tracking from 'lib/tracking/tracking';
import { workshopEvents } from 'lib/tracking/events';

export const EditOptionDialog = ({
  close,
  sending,
  optionName,
  handleSubmit,
  isWorkshop,
  handleDelete,
}) => {
  const ref = useRef(null);

  const [text, setText] = useState(optionName);

  useEffect(() => {
    const handleEditDialogClose = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        close();
      }
    };
    document.addEventListener('click', handleEditDialogClose, true);
    return () => {
      document.removeEventListener('click', handleEditDialogClose, true);
    };
  }, []);

  const handleChange = (e) => {
    e.preventDefault();
    setText(e.target.value);
  };

  const onClose = (e) => {
    e.preventDefault();
    close();
  };

  const onSubmit = (e) => {
    Tracking.event({ name: workshopEvents.EDIT_BUTTON });
    e.preventDefault();
    if (text.trim()) {
      handleSubmit(text);
    } else {
      setText(optionName);
    }
  };

  const onDelete = (e) => {
    e.preventDefault();
    handleDelete();
  };

  return (
    <div
      ref={ref}
      css={[
        styles.containerOverlay,
        { width: isWorkshop && '150%' },
        { right: isWorkshop && '-2px' },
        { top: isWorkshop && '52px' },
      ]}
      aria-label="Dialog with an input for editing options"
    >
      <form css={styles.addForm}>
        <input
          css={styles.input}
          type="text"
          name="option"
          value={text}
          onChange={(e) => handleChange(e)}
        />
        <div css={styles.actions}>
          <ButtonBase onClick={onClose}>Cancel</ButtonBase>
          <ButtonBase onClick={onSubmit} disabled={sending} css={styles.addBtn}>
            {sending ? (
              <Loader type="Oval" color="#1CA4FC" height={18} width={20} />
            ) : (
              'Save'
            )}
          </ButtonBase>
          {isWorkshop && (
            <ButtonBase
              onClick={onDelete}
              disabled={sending}
              css={[styles.addBtn, styles.delBtn]}
            >
              Delete
            </ButtonBase>
          )}
        </div>
      </form>
    </div>
  );
};
