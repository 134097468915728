import React, { Component } from 'react';
import { QuestionDataContainer, Button } from '../components/layout';
import { Question, QuestionContainer } from '../components/SurveyTitle';
import { EnterCode } from 'pages/Landing/styles';
import { Description as LandingDescription } from 'pages/Landing/styles';
import { getNestedData } from '../const/nested-data';
import { SURVEYROOT } from '../const/routes';
import { restoreAnswerData } from '../const/module-types';

export default class Review extends Component {
  loadResponse = () => {
    const { surveyDetails, values, setValues, history } = this.props;
    const { surveyCode } = surveyDetails;
    const { userResponses, reviewCode } = values;
    const userResponse = Object.values(userResponses).find((userResponse) => {
      return userResponse.responseCode === reviewCode;
    });
    if (!userResponse) {
      return setValues({
        ...values,
        loadError: true,
      });
    }
    const answerData = (userResponse && userResponse.answerData) || {};
    const restoredAnswerData = restoreAnswerData({ answerData, surveyDetails });
    setValues({
      ...values,
      ...restoredAnswerData,
      selectedReviewCode: reviewCode,
    });
    history.push(`${SURVEYROOT}/${surveyCode}/1`);
  };
  detectEnter = (e) => {
    if (e.keyCode === 13) return this.loadResponse();
  };
  render() {
    const { values = {}, handleChange } = this.props;
    const reviewCodeKey = 'reviewCode';
    const reviewCodeValue = getNestedData(values, reviewCodeKey) || '';
    const { saving, loading, loadError } = values;
    const title = loadError ? 'Hmmmm...' : 'Welcome Back!';
    const description = loadError
      ? "That code doesn't seem to work. Please check your email and try again."
      : 'Enter your code to review your completed survey.';
    const active = reviewCodeValue.length > 0 && !saving && !loading;
    return (
      <QuestionDataContainer>
        <QuestionContainer color="white">
          <Question>{title}</Question>
          <LandingDescription>{description}</LandingDescription>
          <EnterCode
            placeholder="Enter code"
            name={reviewCodeKey}
            onChange={handleChange}
            onKeyDown={this.detectEnter}
            value={reviewCodeValue}
          />
          <Button
            background={active ? '#FF9B3A' : '#828282'}
            opacity={active ? 1 : 0.5}
            pointerEvents={active ? 'auto' : 'none'}
            margin="1.5rem 0 0 0"
            onClick={this.loadResponse}
          >
            Get Started
          </Button>
        </QuestionContainer>
      </QuestionDataContainer>
    );
  }
}
