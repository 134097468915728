/**
 * Convention:- hilo:type:event
 * @type {{CREATE_SURVEY: string}}
 */

export const generalEvents = {
  CREATE_SURVEY: 'hilo:general:create-survey',
  CREATE_WORKSHOP: 'hilo:general:create-workshop',
  CREATE_TEMPLATE: 'hilo:general:create-template',
  CANCEL_WORKSHOP_CREATION: 'hilo:general:cancel-workshop-creation',
  CANCEL_SURVEY_CREATION: 'hilo:general:cancel-survey-creation',
  CANCEL_TEMPLATE_CREATION: 'hilo:general:cancel-template-creation',
  TEMPLATE_GALLERY_ACCESS: 'hilo:general:template-gallery-access',
  NEW_PROJECT_CREATION: 'hilo:general:new-project-creation',
  ALL_HILOS_ACCESS: 'hilo:general:all-hilos-access',
};

export const surveyBuilderEvents = {
  MADLIB: 'hilo:survey-builder:madlib',
  TEXT_SLIDE: 'hilo:survey-builder:text-slide',
  PLOTTER_22: 'hilo:survey-builder:plotter-22',
  STACK_RANK: 'hilo:survey-builder:stack-rank',
  COMPARISON: 'hilo:survey-builder:comparison',
  OPEN_ENDED: 'hilo:survey-builder:open-ended',
  ADD_OPTION: 'hilo:survey-builder:add-option',
  TEST_SURVEY: 'hilo:survey-builder:test-survey',
  MULTI_SELECT: 'hilo:survey-builder:multi-select',
  WAITING_ROOM: 'hilo:survey-builder:waiting-room',
  MOBILE_TOGGLE: 'hilo:survey-builder:mobile-toggle',
  DESKTOP_TOGGLE: 'hilo:survey-builder:desktop-toggle',
  EXPORT_SELECTION: 'hilo:survey-builder:export-selection',
  PERSONAL_INFO: 'hilo:survey-builder:personal-information',
  PRESENT_SELECTION: 'hilo:survey-builder:present-selection',
  POINT_DISTRIBUTION: 'hilo:survey-builder:point-distribution',
  SPECTRUM_DISTRIBUTION: 'hilo:survey-builder:spectrum-distribution',
  LAUNCHED_SURVEY: 'hilo:survey-builder:launched-survey',
  LAUNCHED_WORKSHOP: 'hilo:survey-builder:launched-workshop',
  CLOSED_SURVEY: 'hilo:survey-builder:closed-survey',
  CLOSED_WORKSHOP: 'hilo:survey-builder:closed-workshop',
};

export const presentationEvents = {
  COMMENTS_OPEN: 'hilo:presentation-mode:comments-open',
  QN_SWITCHER_LEFT: 'hilo:presentation-mode:qn-switcher-left',
  QN_SWITCHER_RIGHT: 'hilo:presentation-mode:qn-switcher-right',
  TOGGLE_DESCRIPTION: 'hilo:presentation-mode:toggle-description',
  QN_SWITCHER_DROPDOWN: 'hilo:presentation-mode:qn-switcher-dropdown',
  TOGGLE_COMMENT_VIEW_SELECTION:
    'hilo:presentation-mode:toggle-comment-view-selection',
  IF_ICON_CLICKED: 'hilo:presentation-mode:return-to-hilo-home',
};

export const workshopEvents = {
  QN_SWITCHER_LEFT: 'hilo:workshop-mode:qn-switcher-left',
  QN_SWITCHER_RIGHT: 'hilo:workshop-mode:qn-switcher-right',
  QN_SWITCHER_DROPDOWN: 'hilo:workshop-mode:qn-switcher-dropdown',
  POPULATE_PANEL_OPEN: 'hilo:workshop-mode:populate-panel-open',
  ADD_OPTIONS_CLICKED: 'hilo:workshop-mode:populate-panel:add-option-button',
  EDIT_OPTION_CLICKED: 'hilo:workshop-mode:populate-panel:edit-option',
  SORT_OPTIONS_CLICKED: 'hilo:workshop-mode:populate-panel:sort-button',
  SAVE_OPTIONS: 'hilo:workshop-mode:populate-panel:save-option-button',
  CANCEL_OPTIONS: 'hilo:workshop-mode:populate-panel:cancel-button',
  CLOSE_WORKSHOP: 'hilo:workshop-mode:close-workshop-mode',
  IF_ICON_CLICKED: 'hilo:workshop-mode:return-to-hilo-home',
  ADD_BUTTON: 'hilo:workshop-mode-populate-panel:add-modal:add-button',
  EDIT_BUTTON: 'hilo:workshop-mode-populate-panel:edit-modal:edit-button',
};

export const surveyTakerEvents = {
  VISIT_LANDING_PAGE: 'hilo:survey-taker:visit-landing-page',
  ENTER_SURVEY_CODE: 'hilo:survey-taker:enter-survey-code',
  START_SURVEY: 'hilo:survey-taker:start-survey',
  SURVEY_CODE_ENTER_KEYBOARD: 'hilo:survey-taker:survey-code-enter-keyboard',
  SURVEY_CODE_CLICK_START_BUTTON: 'hilo:survey-taker:survey-code-start_button',
  FINISH_SURVEY: 'hilo:survey-taker:finish-survey',
  END_SURVEY_REVIEW_EMAIL_INPUT:
    'hilo:survey-taker:end-survey-review-email-input',

  MODULE_ON_VISIT: 'hilo:survey-taker:module-on-visit',
  MODULE_NEXT_BUTTON: 'hilo:survey-taker:module-next-button',
  MODULE_PREV_BUTTON: 'hilo:survey-taker:module-prev-button',
  MODULE_EXPLANATION_TEXT: 'hilo:survey-taker:module-explanation-text',
};

export const projectEvents = {
  ADD_NEW_TEAM_MEMBER: 'hilo:project:add-new-team-member',
  REMOVE_TEAM_MEMBER: 'hilo:project:remove-team-member',
  SET_PROJECT_NAME: 'hilo:project:set-project-name',
};

export const loginEvents = {
  LOGIN_WITH_GOOGLE: 'hilo:login:google',
  LOGIN_WITH_MICROSOFT: 'hilo:login:microsoft',
  LOGIN_SUCCESSFUL: 'hilo:login:successful',
};
