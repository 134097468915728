import React, { useState } from "react";
import "@reach/accordion/styles.css";
import { DialogContent } from "@reach/dialog";

import { ButtonBase } from "../../buttons";
import { TemplateDialog } from "../Dialog/styles";

import CloseIcon from "../../../img/close-icon.svg";

const styles = {
  placeTemplateDialogContainer: {
    "> [data-reach-dialog-content]": {
      borderColor: "#6A36FF",
      height: "450px",
    },
  },
  projectsContainer: {
    overflowY: "auto",
    marginBottom: "2rem",
    marginLeft: "20px",
    height: "calc(100% - 80px)",
    display: "flex",
    flexDirection: "column",
  },
};

export default function PlaceTemplateDialog({ isOpen, onDismiss, children }) {
  return (
    <TemplateDialog
      css={styles.placeTemplateDialogContainer}
      isOpen={isOpen}
      onDismiss={onDismiss}
    >
      <DialogContent aria-label="template dialog">
        <div css={{ width: "100%", height: "100%" }}>
          <div css={{ display: "flex", height: "50px" }}>
            <ButtonBase css={{ marginLeft: "auto" }} onClick={onDismiss}>
              <img src={CloseIcon} alt="Close dialog button" />
            </ButtonBase>
          </div>
          <div css={styles.projectsContainer}>{children}</div>
        </div>
      </DialogContent>
    </TemplateDialog>
  );
}
