import { useEffect, useState } from 'react';
import { db } from '../firebase';
import { getModuleListListenerReducer } from 'lib/survey-api';

const useModuleList = ({ surveyId }) => {
  const [moduleList, setModuleList] = useState({});

  let unsubscribe = null;

  useEffect(() => {
    unsubscribe = db
      .collection('modules')
      .where('surveyId', '==', surveyId)
      .onSnapshot((snapshot) => {
        if (snapshot.empty) return;
        const list = snapshot.docs.reduce(getModuleListListenerReducer, {});
        setModuleList(list);
      });
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [surveyId]);

  return moduleList;
};

export default useModuleList;
