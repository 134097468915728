import styled from "styled-components";
import {ReactComponent as AlertArrow} from "img/breaking-alert-arrow.svg";
import {ReactComponent as Cross} from "img/cross-dynamic.svg";

export const ButtonsContainer = styled('div')`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  position: absolute;
  bottom: 0;
  width: 100%;
`;

export const ModuleContainer = styled('div')`
  display: flex;
`;

export const ModuleDetails = styled('div')`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: ${props => props.weight || "400"};
  font-size: ${props => props.size || "18"}px;
  line-height: ${props => props.lineHeight || "21.09"}px;
`;

export const ModuleNumber = styled(ModuleDetails)`
  margin-right: 5px;
`;

export const ModuleName = styled(ModuleDetails)``;

export const BreakingAlertText = styled('span')`
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`

export const BreakingAlertIllustrationContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const IllustrationContainer = styled('div')`
  display: flex;
  justify-content: center;
`;

export const BreakingAlertArrow = styled(AlertArrow)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0px 20px;
`;

export const Close = styled(Cross)`
  display: flex;
  align-self: flex-end;
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
`;