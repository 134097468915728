import { colors } from 'const/theme';
import { VictoryLabel } from 'victory';
import React from 'react';

/**
 * This is used to highlight the x-axis labels when we have a selected user/column.
 * @param onClickLabel click
 * @param participantsData Stack rank participants data with name has key.
 * @param selectedParticipantResponseId
 * @param victoryProps Props provided by victory chart to custom components
 * @returns {JSX.Element}
 * @constructor
 */
const AxisTickLabel = ({
  onClickLabel,
  participantsData,
  selectedParticipantResponseId,
  ...victoryProps
}) => {
  const responseId = participantsData[victoryProps.text]?.responseId;
  const text = participantsData[victoryProps.text]?.name;
  const style = {
    ...victoryProps.style,
    fill:
      responseId && responseId === selectedParticipantResponseId
        ? colors.SELECTED_CHART_FILL_COLOR
        : 'black',
  };
  return (
    <VictoryLabel
      dy={-2}
      dx={-15}
      {...{ ...victoryProps, text }}
      style={style}
      events={{ onClick: () => onClickLabel(responseId) }}
    />
  );
};

export default AxisTickLabel;
