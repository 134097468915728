import React from 'react';
import styled from 'styled-components';

const Container = styled('div')`
  background-color: #ffffff;
  min-height: 96px;
  min-width: 200px;
  max-width: 464px;
  border-radius: 10px;
  position: relative;
  box-shadow: ${(props) =>
    props.isActive
      ? '0px 0px 10px rgba(255, 151, 54, 0.3)'
      : '0 0 10px rgba(28, 164, 252, 0.3)'};

  &::after {
    content: '';
    position: absolute;
    border: 25px solid transparent;
    border-top-color: #ffffff;
    border-bottom: 0;
    bottom: -20px;
    left: ${(props) => (props.right ? 'auto' : '5%')};
    right: ${(props) => (props.right ? '5%' : 'auto')};
    filter: ${(props) =>
      props.isActive
        ? 'drop-shadow(0px 0px 10px rgba(255, 151, 54, 0.3))'
        : 'drop-shadow(0 0 10px rgba(28, 164, 252, 0.3))'};
  }

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 5%;
    right: 5%;
    height: 19px;
    background: white;
    z-index: 10;
  }
`;

const Response = styled('div')`
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 23.44px;
  padding: 20px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
`;

const Name = styled('div')`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 20px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 20px;
  line-height: 23.44px;
  color: ${(props) => (props.isActive ? '#FF9736' : '#1ca4fc')};
`;

const MessageBubble = ({ right = false, response, name, isActive }) => {
  return (
    <Container right={right} isActive={isActive}>
      <Response>{response}</Response>
      <Name isActive={isActive}>{name}</Name>
    </Container>
  );
};

export default MessageBubble;
