import firebase from 'firebase/compat/app';
import { getAuth, GoogleAuthProvider, OAuthProvider } from 'firebase/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/database';
import 'firebase/compat/functions';
import 'firebase/compat/storage';
import { APP_IS_IN_TO_PROD } from './const';

const config = {
  apiKey: 'AIzaSyDWHGD3bOtFS79O4PV4agulv9A2Dm2O5Bk',
  authDomain: 'k-base-d7031.firebaseapp.com',
  databaseURL: 'https://k-base-d7031.firebaseio.com',
  projectId: 'k-base-d7031',
  storageBucket: 'k-base-d7031.appspot.com',
  messagingSenderId: '371191084003',
};

const dev2Config = {
  apiKey: 'AIzaSyDYLCaFK77-zQYchTchYKG7uOkcP0182d0',
  authDomain: 'kbase-dev2.firebaseapp.com',
  databaseURL: 'https://kbase-dev2.firebaseio.com',
  projectId: 'kbase-dev2',
  storageBucket: 'kbase-dev2.appspot.com',
  appId: '1:21706859780:web:911f01dbf22e3955df9c51',
  messagingSenderId: '21706859780',
  measurementId: 'G-D4BY7QQBGL',
};

let app;

if (APP_IS_IN_TO_PROD) {
  console.log('prod', config);
  app = firebase.initializeApp(config);
} else {
  console.log('dev', dev2Config);
  app = firebase.initializeApp(dev2Config);
}

const db = firebase.firestore();
db.settings({ ignoreUndefinedProperties: true });

const storage = firebase.storage();

//local firebase emulators
// https://firebase.google.com/docs/emulator-suite/install_and_configure
// eslint-disable-next-line no-restricted-globals
if (location.hostname === 'localhost') {
  db.useEmulator('localhost', 8081);
  storage.useEmulator('localhost', 9199);
  firebase.functions().useEmulator('localhost', 5001);
}

const auth = getAuth(app);
const GoogleProvider = new GoogleAuthProvider();
const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp;
const Timestamp = firebase.firestore.Timestamp;
const MicrosoftProvider = new OAuthProvider('microsoft.com');

const rdb = firebase.database();

export {
  db,
  rdb,
  auth,
  storage,
  GoogleProvider,
  serverTimestamp,
  MicrosoftProvider,
  firebase,
  Timestamp,
};
