import React, { useState, useRef } from 'react';
import Switch from 'react-switch';
import { useSelector } from 'react-redux';
import { DetailShortInputContainer, InputLabel, Input } from '../../layout';
import { getNestedData } from '../../../const/nested-data';
import { ReactComponent as IconAddImage } from '../../../img/ic_add_image.svg';
import { ReactComponent as IconRemoveImage } from '../../../img/ic_remove_image.svg';
import { ReactComponent as IconClose } from '../../../img/ic_close.svg';
import { ButtonBase } from '../../buttons';
import {
  IS_SINGLE_SELECT,
  MAX_SELECTION_COUNT,
  POPULATE_OPTIONS,
} from '../../../const/module-types';
import FileUpload from '../../FileUpload/FileUpload';
import { PopulateToggle } from './components/PopulateToggle';
import { PopulateDropdown } from './components/PopulateDropdown';
import { HILOS } from 'const/index';
import {
  doesModuleHaveOptions,
  removeModuleOptions,
} from 'lib/Presentation/Helper';
import { handlePopulateToggle } from 'components/Builder/SurveyModulesEdit/Helper';

const styles = {
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    position: 'relative',
    width: '100%',
    paddingTop: '20px',
  },
  typeSelectionContainer: {
    display: 'flex',
    fontSize: '10px',
    color: '#1CA4FC',
    alignItems: 'center',
    marginBottom: '24px',
  },
  typeSelectionSwitch: {
    border: '1.2px solid #1CA4FC',
    height: 'fit-content',
    borderRadius: '60px !important',
  },
};

function MultiSelectField({
  label,
  data,
  imageUploadIsActive,
  isLastItem,
  onClickAddImage,
  onClickRemoveImage,
  onChangeTitleField,
  onClickRemoveItem,
}) {
  const title = data.title || '';
  const imageFile = data.file || data.imageUrl || '';

  return (
    <DetailShortInputContainer css={{ opacity: imageUploadIsActive ? 0.5 : 1 }}>
      <div css={{ display: 'flex', marginBottom: isLastItem ? '6px' : '2px' }}>
        <InputLabel css={{ marginBottom: 0 }}>{label}</InputLabel>
        {imageFile ? (
          <ButtonBase
            onClick={onClickRemoveImage}
            css={{
              marginLeft: 'auto',
              cursor: imageUploadIsActive ? 'auto' : 'pointer',
            }}
          >
            <IconRemoveImage />
          </ButtonBase>
        ) : (
          <ButtonBase
            onClick={onClickAddImage}
            css={{
              marginLeft: 'auto',
              cursor: imageUploadIsActive ? 'auto' : 'pointer',
            }}
          >
            <IconAddImage />
          </ButtonBase>
        )}
        {!isLastItem && (
          <ButtonBase
            css={{
              stroke: '#1CA4FC',
              marginLeft: '12px',
              cursor: imageUploadIsActive ? 'auto' : 'pointer',
            }}
            onClick={onClickRemoveItem}
          >
            <IconClose />
          </ButtonBase>
        )}
      </div>
      <Input
        color="initial"
        value={title}
        onChange={(event) => onChangeTitleField(event.target.value)}
      />
    </DetailShortInputContainer>
  );
}

export function TypeSelection({ formikProps, moduleId }) {
  const key = `moduleList.${moduleId}.questionData.${IS_SINGLE_SELECT}`;
  const { values, setFieldValue } = formikProps;

  const isSingleSelect = getNestedData(values, key) || false;

  return (
    <div css={styles.typeSelectionContainer}>
      <span css={{ marginRight: '10px', opacity: isSingleSelect ? 0.3 : 1.0 }}>
        Multi-selection
      </span>
      <Switch
        uncheckedIcon={false}
        checkedIcon={false}
        height={18}
        width={40}
        handleDiameter={14}
        onHandleColor="#1CA4FC"
        offHandleColor="#1CA4FC"
        css={styles.typeSelectionSwitch}
        offColor="#FFFFFF"
        onColor="#FFFFFF"
        activeBoxShadow={null}
        onChange={() => {
          setFieldValue(key, !isSingleSelect);
        }}
        checked={isSingleSelect}
      />
      <span css={{ marginLeft: '10px', opacity: isSingleSelect ? 1.0 : 0.3 }}>
        Single-selection
      </span>
    </div>
  );
}

function MaxSelectionCountField({ name, onChange, value, isValid }) {
  return (
    <DetailShortInputContainer css={{ marginBottom: 0 }}>
      <InputLabel css={[!isValid && { color: 'red' }]}>
        Max selection number
      </InputLabel>
      <Input
        name={name}
        onChange={onChange}
        value={value}
        type="number"
        min={0}
        color="initial"
        css={{ outline: 0, border: isValid ? 'none' : '1px solid red' }}
      />
    </DetailShortInputContainer>
  );
}

export default function({
  displayOrder,
  options,
  formikProps,
  moduleId,
  modulesArray,
}) {
  const [currentItemImageKey, setCurrentImageItemKey] = useState(null);
  const [currentOptionKey, setCurrentOptionKey] = useState(null);
  const itemsContainerRef = useRef(null);

  const hiloType = useSelector((state) => state.hilo.type);

  const { setFieldValue, values, handleChange } = formikProps;

  const existingItems = options.filter(([, value]) => !value.removed);
  const items = [...existingItems, [`option${options.length}`, {}]];

  const maxSelectionCountKey = `moduleList.${moduleId}.questionData.${MAX_SELECTION_COUNT}`;
  const isSingleSelectKey = `moduleList.${moduleId}.questionData.${IS_SINGLE_SELECT}`;
  const isPopulateOptionsKey = `moduleList.${moduleId}.questionData.${POPULATE_OPTIONS}`;

  const maxSelectionCountValue = getNestedData(values, maxSelectionCountKey); // prettier-ignore
  const isSingleSelect = getNestedData(values, isSingleSelectKey);
  const isPopulateOptions =
    getNestedData(values, isPopulateOptionsKey) || false;

  const onImageAdded = ({ file }) => {
    const data = getNestedData(values, currentItemImageKey) || {};
    let title = data.title || '';
    if (!title && file) {
      title = 'Option';
    }
    setFieldValue(currentItemImageKey, {
      ...data,
      title,
      file,
      key: currentOptionKey,
    });
    //Close the image dropzone area
    setCurrentImageItemKey(null);
    setCurrentOptionKey(null);
  };

  const onRemoveImage = ({ moduleKey }) => {
    // if image Upload Is Active
    if (currentItemImageKey) return;
    const data = getNestedData(values, moduleKey) || {};
    const imageFile = data.file;
    if (imageFile) {
      setFieldValue(moduleKey, { ...data, file: null });
      return;
    }
    const imageUrl = data.imageUrl || null;
    if (!imageUrl) return;
    setFieldValue(moduleKey, {
      ...data,
      imageUrl: null,
      deleteImageUrl: imageUrl,
    });
  };

  const onPopulateToggle = ({ populateOptionsKey, value, moduleId }) => {
    handlePopulateToggle(
      values,
      moduleId,
      value,
      setFieldValue,
      populateOptionsKey
    );
  };

  return (
    <>
      {isSingleSelect ? null : (
        <MaxSelectionCountField
          name={maxSelectionCountKey}
          value={maxSelectionCountValue || ''}
          onChange={handleChange}
          isValid={
            isPopulateOptions
              ? true
              : existingItems.length >= (maxSelectionCountValue || 0)
          }
        />
      )}
      {hiloType === HILOS.WORKSHOP && (
        <PopulateToggle
          key={displayOrder}
          displayOrder={displayOrder}
          checked={isPopulateOptions}
          onChange={(value) =>
            onPopulateToggle({
              populateOptionsKey: isPopulateOptionsKey,
              value,
              moduleId,
            })
          }
        />
      )}
      <div ref={itemsContainerRef} css={styles.container}>
        {isPopulateOptions ? (
          <PopulateDropdown
            key={`dropdown_key${displayOrder}`}
            displayOrder={displayOrder}
            options={modulesArray}
            moduleId={moduleId}
            formikProps={formikProps}
          />
        ) : (
          items.map(([key], index) => {
            const moduleKey = `moduleList.${moduleId}.questionData.${key}`;
            const data = getNestedData(values, moduleKey) || {};

            const imageUploadIsActive = !!currentItemImageKey;
            const isLastItem = index === items.length - 1;

            return (
              <MultiSelectField
                key={moduleKey}
                label={`Add Item ${index + 1}`}
                data={data}
                imageUploadIsActive={imageUploadIsActive}
                isLastItem={isLastItem}
                onClickAddImage={() => {
                  if (imageUploadIsActive) return;
                  itemsContainerRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                  });
                  setCurrentImageItemKey(moduleKey);
                  setCurrentOptionKey(key);
                }}
                onChangeTitleField={(value) => {
                  setFieldValue(moduleKey, { ...data, title: value, key });
                }}
                onClickRemoveItem={() => {
                  if (isLastItem || imageUploadIsActive) return;
                  setFieldValue(moduleKey, { ...data, removed: true });
                }}
                onClickRemoveImage={() => onRemoveImage({ moduleKey })}
              />
            );
          })
        )}
        {currentItemImageKey && (
          <FileUpload
            onClickClose={() => setCurrentImageItemKey(null)}
            onImageAdded={onImageAdded}
          />
        )}
      </div>
    </>
  );
}
