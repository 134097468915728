import { getUserName } from '../Helper';
import {
  MADLIB_SENTENCE,
  OPTIONS_PREFIX,
  SECTIONS_PREFIX,
} from '../../../const/module-types';
import { getNestedData } from '../../../const/nested-data';

const getMadlibSectionOptions = ({ madlibSentence }) => {
  // v2 handling
  const re = /\[(.*?)\]/g;
  let options = [];
  let sections = [];
  let match;
  // get all the options matches
  while ((match = re.exec(madlibSentence)) != null) {
    const matchedText = match[0];
    const optionsArray = match[1].split(',').map((option) => option.trim());
    const matchIndex = match.index;
    const matchLength = matchedText.length;
    const start = matchIndex;
    const end = start + matchLength;
    const displayText = optionsArray[0];
    options.push({
      sortId: start,
      start,
      end,
      displayText,
      optionsArray,
      matchIndex,
      matchedText,
    });
  }
  // if we have no options, return the whole SENTENCE
  const firstMatch = options[0];
  if (!firstMatch) {
    const sentence = {
      sortId: 0,
      start: 0,
      end: madlibSentence.length,
      sectionText: madlibSentence,
      displayText: madlibSentence,
    };
    return [sentence];
  }
  // otherwise, generate the sections
  let previousStart = 0;
  let previousEnd = 0;
  options.forEach((optionsHash, i) => {
    const { start, end } = optionsHash;
    const sectionText = madlibSentence.slice(previousEnd, start);
    sections.push({
      sortId: previousEnd,
      start: previousEnd,
      end: start,
      sectionText,
      displayText: sectionText,
    });
    previousStart = start;
    previousEnd = end;
    // if last option
    if (i === options.length - 1) {
      const sentenceLength = madlibSentence.length;
      const sectionText = madlibSentence.slice(previousEnd, sentenceLength);
      sections.push({
        sortId: previousEnd,
        start: previousEnd,
        end: sentenceLength,
        sectionText,
        displayText: sectionText,
      });
    }
  });
  const merged = [...sections, ...options].sort((a, b) => {
    return a.sortId - b.sortId;
  });
  return merged;
};

export const getMadlibSections = ({ questionData }) => {
  const { [MADLIB_SENTENCE]: madlibSentence } = questionData || {};
  if (madlibSentence) {
    // v2 handling
    const sectionOptions = getMadlibSectionOptions({ madlibSentence });
    return sectionOptions.map((mergedObj, i) => {
      return [`${SECTIONS_PREFIX}${i}`, mergedObj.displayText];
    });
  } else {
    // v1 handling
    return Object.entries(questionData || {})
      .filter(([key, data]) => {
        return key.indexOf(SECTIONS_PREFIX) === 0;
      })
      .sort(([aKey, aData], [bKey, bData]) => {
        const aInt = parseInt(aKey.replace(SECTIONS_PREFIX, ''), 10);
        const bInt = parseInt(bKey.replace(SECTIONS_PREFIX, ''), 10);
        return aInt - bInt;
      });
  }
};

const getMadlibOptions = ({ questionData }) => {
  const { [MADLIB_SENTENCE]: madlibSentence } = questionData || {};
  if (madlibSentence) {
    // v2 handling
    const sectionOptions = getMadlibSectionOptions({ madlibSentence });
    return sectionOptions.reduce((collector, mergedObj, i) => {
      const { optionsArray } = mergedObj;
      if (!optionsArray) return collector;
      const optionsWithoutFirst = optionsArray.slice(1).join(',');
      return {
        ...collector,
        [i]: optionsWithoutFirst,
      };
    }, {});
  } else {
    // v1 handling
    return Object.entries(questionData || {})
      .filter(([key, data]) => {
        return key.indexOf(OPTIONS_PREFIX) === 0;
      })
      .reduce((collector, [key, data]) => {
        const intKey = parseInt(key.replace(OPTIONS_PREFIX, ''), 10);
        return {
          ...collector,
          [intKey]: data,
        };
      }, {});
  }
};

export const getMadlibOptionsHash = (questionData) => {
  const madlibSections = getMadlibOptions({ questionData });
  return madlibSections;
};

export const getOptionsArray = (optionsValue = '') => {
  return optionsValue
    .split(',')
    .map((x) => x.trim())
    .filter((x) => x);
};

const getPresentationData = (moduleId, questionData, userResponses) => {
  return Object.entries(getMadlibOptionsHash(questionData)).reduce(
    (collector, [key, listWithoutFirst]) => {
      const userResponsesForOption = Object.entries(userResponses).reduce(
        (collector2, [userId, responseData]) => {
          const optionChoice = getNestedData(
            responseData,
            `answerData.${moduleId}.${OPTIONS_PREFIX}${key}`
          );
          if (!optionChoice) return collector2;
          return {
            ...collector2,
            [optionChoice]: (collector2[optionChoice] || 0) + 1,
          };
        },
        {}
      );
      return {
        ...collector,
        [key]: {
          ...collector[key],
          ...userResponsesForOption,
        },
      };
    },
    {}
  );
};

export const getOptionsViewData = (
  intKey,
  moduleId,
  questionData,
  userResponse,
  optionsArray
) => {
  const data = getPresentationData(moduleId, questionData, userResponse);
  const options = data[intKey] || {};
  return optionsArray.map((option) => {
    const name = option;
    const count = options[option] || 0;
    return {
      name,
      count,
    };
  });
};

export const getOptionCount = (
  intKey,
  moduleId,
  questionData,
  userResponse
) => {
  const data = getPresentationData(moduleId, questionData, userResponse);
  const options = data[intKey] || {};
  const sortedOptions = Object.entries(options).sort(([aK, aD], [bK, bD]) => {
    return bD - aD;
  });
  const [key, count] = sortedOptions[0] || [];
  return { key, count };
};

const getParticipantsChosenOptions = (moduleResponse) => {
  return Object.entries(moduleResponse).reduce((acc, [key, data]) => {
    const intKey = parseInt(key.replace(OPTIONS_PREFIX, ''), 10);
    return {
      ...acc,
      [intKey]: data,
    };
  }, {});
};

const getMadlibDataFromResponses = (userResponses, moduleList, moduleId) => {
  const responses = Object.values(userResponses);
  const res = responses.reduce(
    (acc, response, currentIndex) => {
      const answerData = response.answerData;
      const moduleResponse = answerData[moduleId];
      if (!moduleResponse) return acc;
      const data = getParticipantsChosenOptions(moduleResponse);
      const explanation = moduleResponse.explanation || '';
      const index = currentIndex + 1;
      const name = getUserName(
        response.userName,
        answerData,
        moduleList,
        index
      );
      const nameLabel = `${name}-${currentIndex}`;
      const responseId = response.responseId || nameLabel;
      return {
        ...acc,
        participantsData: {
          ...acc.participantsData,
          [responseId]: {
            explanation,
            name,
            data,
            responseId,
          },
        },
        participants: [
          ...acc.participants,
          {
            name,
            label: nameLabel,
            responseId: nameLabel,
          },
        ],
      };
    },
    {
      participantsData: {},
      participants: [],
    }
  );
  return {
    participantsData: res.participantsData,
    participants: res.participants,
  };
};

export const getMadlibData = (userResponses, moduleList, moduleId) => {
  const { participantsData, participants } = getMadlibDataFromResponses(
    userResponses,
    moduleList,
    moduleId
  );

  return {
    participantsData,
    participants,
  };
};
