import React, { Component } from "react";
import styled from "styled-components";
import {
  MADLIB_SENTENCE,
  OPTIONS_PREFIX,
  SECTIONS_PREFIX
} from "../../../const/module-types";
import { getNestedData } from "../../../const/nested-data";
import {
  TextAndTable,
  TextHighlight,
  TextHighlightContainer
} from "../../../components/survey-components/TextHighlight";
import {
  TeamRadio,
  TeamRadioContainer,
  TeamRadioInput
} from "../../../components/survey-components/PersonalInfo";
import {
  PresentationBody,
  PresentationCell,
  PresentationHalfWidth,
  PresentationRow,
  PresentationTable,
  PresentationTableContainerTwoColumn as PresentationTableContainer,
  PresentationTitleRow
} from "../../../components/layout";

const MadlibContainer = styled(TextHighlightContainer)`
  background-color: ${props =>
    props.inPresentationMode ? "white" : "#1CA4FC"};
  color: ${props => (props.inPresentationMode ? "#1CA4FC" : "white")};
  ${({ inMobileView, inPresentationMode }) =>
    inMobileView
      ? ``
      : `
  @media (min-width: 1200px) {
    background-image: ${
      inPresentationMode
        ? "none"
        : "linear-gradient(0deg, #1ca4fc 0%, #0f79bd 100%)"
    };
    background-size: 100% 0.5rem;
    background-repeat: no-repeat;
    background-position: top left;
    background-color: ${inPresentationMode ? "white" : "#1CA4FC"};
    color: ${inPresentationMode ? "#1CA4FC" : "white"};
    overflow: auto;
  }
  `}
`;

const MadlibText = styled(TextHighlight)`
  overflow: visible;
  color: ${props => (props.inPresentationMode ? "#1CA4FC" : "white")};
`;

const MadlibOptionText = styled("span")`
  cursor: pointer;
  color: ${props =>
    props.inPresentationMode || props.active ? "#FF9B3A" : "#0F79BD"};
  font-weight: bold;
  border-bottom: 1px dotted #0f79bd;
  transition: color 100ms;
`;

const MadlibOptionDropdown = styled(TeamRadioContainer)`
  width: 20.9375rem;
  right: auto;
  bottom: auto;
  top: auto;
  left: auto;
  display: ${props => (props.active ? "block" : "none")};
  z-index: 2;
`;

const MadlibOption = styled(TeamRadio)`
  line-height: 1;
  min-height: 2.5rem;
  z-index: 2;
  word-break: break-all;
  display: flex;
  align-items: center;
`;

const MadlibOptionInput = styled(TeamRadioInput)``;

export const getMadlibSectionOptions = ({ madlibSentence }) => {
  // v2 handling
  const re = /\[(.*?)\]/g;
  let options = [];
  let sections = [];
  let match;
  // get all the options matches
  while ((match = re.exec(madlibSentence)) != null) {
    const matchedText = match[0];
    const optionsArray = match[1].split(",").map(option => option.trim());
    const matchIndex = match.index;
    const matchLength = matchedText.length;
    const start = matchIndex;
    const end = start + matchLength;
    const displayText = optionsArray[0];
    options.push({
      sortId: start,
      start,
      end,
      displayText,
      optionsArray,
      matchIndex,
      matchedText
    });
  }
  // if we have no options, return the whole SENTENCE
  const firstMatch = options[0];
  if (!firstMatch) {
    const sentence = {
      sortId: 0,
      start: 0,
      end: madlibSentence.length,
      sectionText: madlibSentence,
      displayText: madlibSentence
    };
    return [sentence];
  }
  // otherwise, generate the sections
  let previousStart = 0;
  let previousEnd = 0;
  options.forEach((optionsHash, i) => {
    const { start, end } = optionsHash;
    const sectionText = madlibSentence.slice(previousEnd, start);
    sections.push({
      sortId: previousEnd,
      start: previousEnd,
      end: start,
      sectionText,
      displayText: sectionText
    });
    previousStart = start;
    previousEnd = end;
    // if last option
    if (i === options.length - 1) {
      const sentenceLength = madlibSentence.length;
      const sectionText = madlibSentence.slice(previousEnd, sentenceLength);
      sections.push({
        sortId: previousEnd,
        start: previousEnd,
        end: sentenceLength,
        sectionText,
        displayText: sectionText
      });
    }
  });
  const merged = [...sections, ...options].sort((a, b) => {
    return a.sortId - b.sortId;
  });
  return merged;
};

export const getMadlibSections = ({ questionData }) => {
  const { [MADLIB_SENTENCE]: madlibSentence } = questionData;
  if (madlibSentence) {
    // v2 handling
    const sectionOptions = getMadlibSectionOptions({ madlibSentence });
    return sectionOptions.map((mergedObj, i) => {
      return [`${SECTIONS_PREFIX}${i}`, mergedObj.displayText];
    });
  } else {
    // v1 handling
    return Object.entries(questionData)
      .filter(([key, data]) => {
        return key.indexOf(SECTIONS_PREFIX) === 0;
      })
      .sort(([aKey, aData], [bKey, bData]) => {
        const aInt = parseInt(aKey.replace(SECTIONS_PREFIX, ""), 10);
        const bInt = parseInt(bKey.replace(SECTIONS_PREFIX, ""), 10);
        return aInt - bInt;
      });
  }
};

export const getMadlibOptions = ({ questionData }) => {
  const { [MADLIB_SENTENCE]: madlibSentence } = questionData;
  if (madlibSentence) {
    // v2 handling
    const sectionOptions = getMadlibSectionOptions({ madlibSentence });
    return sectionOptions.reduce((collector, mergedObj, i) => {
      const { optionsArray } = mergedObj;
      if (!optionsArray) return collector;
      const optionsWithoutFirst = optionsArray.slice(1).join(",");
      return {
        ...collector,
        [i]: optionsWithoutFirst
      };
    }, {});
  } else {
    // v1 handling
    return Object.entries(questionData)
      .filter(([key, data]) => {
        return key.indexOf(OPTIONS_PREFIX) === 0;
      })
      .reduce((collector, [key, data]) => {
        const intKey = parseInt(key.replace(OPTIONS_PREFIX, ""), 10);
        return {
          ...collector,
          [intKey]: data
        };
      }, {});
  }
};

export const getOptionsArray = (optionsValue = "") => {
  return optionsValue
    .split(",")
    .map(x => x.trim())
    .filter(x => x);
};

export default class Madlib extends Component {
  state = {
    madlibDropdown: false
  };
  showMadlibDropdown = e => {
    e.stopPropagation();
    if (this.props.inPresentationMode) return;
    const madlibDropdown = parseInt(e.target.dataset.key, 10);
    this.setState({ madlibDropdown });
  };
  hideMadlibDropdown = e => {
    e.stopPropagation();
    this.setState({ madlibDropdown: false });
  };
  getMadlibSections() {
    const { questionData } = this.props;
    const madlibSections = getMadlibSections({ questionData });
    return madlibSections;
  }
  getMadlibOptionsHash() {
    const { questionData } = this.props;
    const madlibSections = getMadlibOptions({ questionData });
    return madlibSections;
  }
  getMadlibOptionsArray({ intKey }) {
    const optionsValue = this.getMadlibOptionsHash()[intKey];
    if (!optionsValue) return [];
    const optionsArray = getOptionsArray(optionsValue);
  }
  getPresentationData() {
    const { moduleId, questionData } = this.props;
    const userResponses =
      getNestedData(this.props, "values.userResponses") || {};
    return Object.entries(this.getMadlibOptionsHash()).reduce(
      (collector, [key, listWithoutFirst]) => {
        const userResponsesForOption = Object.entries(userResponses).reduce(
          (collector2, [userId, responseData]) => {
            const optionChoice = getNestedData(
              responseData,
              `answerData.${moduleId}.${OPTIONS_PREFIX}${key}`
            );
            if (!optionChoice) return collector2;
            return {
              ...collector2,
              [optionChoice]: (collector2[optionChoice] || 0) + 1
            };
          },
          {}
        );
        return {
          ...collector,
          [key]: {
            ...collector[key],
            ...userResponsesForOption
          }
        };
      },
      {}
    );
  }
  renderPresentationTable() {
    if (!this.props.inPresentationMode) return;
    const data = this.getPresentationData();
    const tables = Object.entries(this.getMadlibOptionsHash()).map(
      ([key, madlibOptions]) => {
        const madlibArray = getOptionsArray(madlibOptions);
        const rows = madlibArray.map((madlibOption, i) => {
          const count = (data[key] && data[key][madlibOption]) || 0;
          const optionCount = this.getOptionCount({ intKey: key });
          const isHighest = optionCount.key === madlibOption;
          const color = isHighest ? "#FF9B3A" : "#333333";
          return (
            <PresentationRow key={`row${i}`}>
              <PresentationCell color={color}>{madlibOption}</PresentationCell>
              <PresentationCell color={color}>{count}</PresentationCell>
            </PresentationRow>
          );
        });
        return (
          <PresentationHalfWidth key={`table${key}`}>
            <PresentationTable>
              <thead>
                <PresentationTitleRow>
                  <PresentationCell background="transparent">
                    Option
                  </PresentationCell>
                  <PresentationCell background="transparent">
                    Count
                  </PresentationCell>
                </PresentationTitleRow>
              </thead>
              <PresentationBody>{rows}</PresentationBody>
            </PresentationTable>
          </PresentationHalfWidth>
        );
      }
    );
    return <PresentationTableContainer>{tables}</PresentationTableContainer>;
  }
  getOptionCount({ intKey }) {
    const data = this.getPresentationData();
    const options = data[intKey] || {};
    const sortedOptions = Object.entries(options).sort(([aK, aD], [bK, bD]) => {
      return bD - aD;
    });
    const [key, count] = sortedOptions[0] || [];
    return { key, count };
  }
  render() {
    const {
      moduleId,
      values,
      handleChange,
      inPresentationMode,
      inMobileView
    } = this.props;
    const sections = this.getMadlibSections();
    const options = {};
    const sentence = sections.map(([key, data], i) => {
      const intKey = parseInt(key.replace(SECTIONS_PREFIX, ""), 10);
      const optionsValue = this.getMadlibOptionsHash()[intKey];
      if (!optionsValue)
        return (
          <span key={key}>
            {data === "\n" ? <br key={`madlib${i}`} /> : data}
          </span>
        );
      const optionsArray = getOptionsArray(optionsValue);
      options[intKey] = optionsArray;
      const optionKey = `madlib.${moduleId}.${OPTIONS_PREFIX}${intKey}`;
      const optionVal = inPresentationMode
        ? this.getOptionCount({ intKey }).key
        : getNestedData(values, optionKey);
      const optionEls = optionsArray.map((option, i) => {
        const active = option === optionVal;
        return (
          <MadlibOption key={`${optionKey}.${i}`} active={active}>
            <MadlibOptionInput
              name={optionKey}
              value={option}
              type="radio"
              onChange={handleChange}
              checked={active}
              onClick={this.hideMadlibDropdown}
            />
            {option}
          </MadlibOption>
        );
      });
      const dropdown = (
        <MadlibOptionDropdown
          key={optionKey}
          active={this.state.madlibDropdown === intKey}
        >
          {optionEls}
        </MadlibOptionDropdown>
      );
      return (
        <MadlibOptionText
          key={`madlibOption${intKey}`}
          data-key={intKey}
          onClick={this.showMadlibDropdown}
          inPresentationMode={inPresentationMode}
          active={this.state.madlibDropdown === intKey}
        >
          {optionVal || data}
          {dropdown}
        </MadlibOptionText>
      );
    });
    return (
      <TextAndTable>
        <MadlibContainer
          inPresentationMode={inPresentationMode}
          {...{ inMobileView }}
        >
          <MadlibText
            {...{ inMobileView }}
            inPresentationMode={inPresentationMode}
          >
            {sentence}
          </MadlibText>
        </MadlibContainer>
        {this.renderPresentationTable()}
      </TextAndTable>
    );
  }
}
