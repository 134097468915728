import firebase from 'firebase/compat/app';
import { db, rdb, serverTimestamp } from '../firebase';
import { HILOS } from '../const';
import {
  OPTION_PREFIX,
  POPULATE_MODULE,
  SELECTED_POPULATE_OPTIONS_IDS,
} from 'const/module-types';
import Tracking from 'lib/tracking/tracking';
import { generalEvents } from 'lib/tracking/events';

const usersCollection = db.collection('users');
const projectsCollection = db.collection('projects');
const surveysCollection = db.collection('surveys');
const modulesCollection = db.collection('modules');

export const docToObj = (doc) => {
  if (!doc.exists) return null;
  return { id: doc.id, ...doc.data() };
};

export const docsToObj = (snapshot) => {
  let result = {};
  snapshot.docs.forEach((doc) => {
    const obj = docToObj(doc);
    const { id } = obj;
    result[id] = obj;
  });
  return result;
};

// Create user
export const createNewUser = (user) => {
  const userId = user.uid;
  const userDoc = usersCollection.doc(userId);
  const timestamp = serverTimestamp();
  const organizationId = user.organizationId || null;
  const organizationName = user.organizationName || null;
  return userDoc
    .set(
      {
        name: user.displayName,
        email: user.email || user.providerData[0].email,
        providerUid: user.providerData[0].uid,
        provider: user.providerData[0].providerId || '',
        updated: timestamp,
        organizationId,
        organizationName,
      },
      { merge: true }
    )
    .then((res) => {
      return userDoc.get();
    })
    .then((res) => {
      return res;
    });
};

// Get User, which returns false if it doesn't exist and the object other wise
export const getUser = (user) => {
  const userID = user.uid;
  return usersCollection.doc(userID).get();
};

export const updateUser = (user) => {
  const userId = user.id || user.uid;
  const userDoc = usersCollection.doc(userId);
  return userDoc
    .set(user, { merge: true })
    .then((res) => {
      return userDoc.get();
    })
    .then((res) => {
      return docToObj(res);
    });
};

export const fetchProjects = (organizationId, creatorId) => {
  if (!organizationId) throw new Error('please provide an organizationId');
  return projectsCollection
    .where('organizationId', '==', organizationId)
    .where('creator.id', '==', creatorId)
    .where('deleted', '==', false)
    .orderBy('timestamp', 'desc')
    .get()
    .then((querySnapshot) => {
      let projects = {};
      // firebase does not have .map() support
      querySnapshot.forEach((doc) => {
        projects[doc.id] = { id: doc.id, projectId: doc.id, ...doc.data() };
      });
      return projects;
    });
};

export const fetchProjectsAUserIsSharedWith = (organizationId, userId) => {
  if (!organizationId) throw new Error('please provide an organizationId');
  return projectsCollection
    .where('organizationId', '==', organizationId)
    .where('teamIds', 'array-contains', userId)
    .where('deleted', '==', false)
    .orderBy('timestamp', 'desc')
    .get()
    .then((querySnapshot) => {
      let projects = {};
      // firebase does not have .map() support
      querySnapshot.forEach((doc) => {
        projects[doc.id] = { id: doc.id, projectId: doc.id, ...doc.data() };
      });
      return projects;
    });
};

export const fetchProjectSurveys = (options) => {
  const { projectId, organizationId } = options;
  if (!projectId) throw new Error('please provide a surveyCode');
  if (!organizationId) throw new Error('please provide an organizationId');
  return surveysCollection
    .where('projectId', '==', projectId)
    .where('organizationId', '==', organizationId)
    .where('template', '==', false)
    .where('deleted', '==', false)
    .get()
    .then((querySnapshot) => {
      let surveys = [];
      // firebase does not have .map() support
      querySnapshot.forEach((doc) => {
        surveys.push({ id: doc.id, surveyId: doc.id, ...doc.data() });
      });
      return surveys;
    });
};

export const fetchOrganizationSurveys = (options) => {
  const { organizationId } = options;
  if (!organizationId) throw new Error('please provide an organizationId');
  return surveysCollection
    .where('organizationId', '==', organizationId)
    .where('hilo', '==', HILOS.SURVEY)
    .where('template', '==', false)
    .where('deleted', '==', false)
    .get()
    .then((querySnapshot) => {
      let surveys = [];
      // firebase does not have .map() support
      querySnapshot.forEach((doc) => {
        surveys.push({ id: doc.id, surveyId: doc.id, ...doc.data() });
      });
      return surveys;
    });
};

export const fetchLocks = () => {
  console.log('Fetch locks ...');
  const lock = rdb
    .ref('/locks')
    .once('value')
    .then((snapshot) => {
      return snapshot.val();
    });
  return lock;
  /*
  return locksCollection.get().then(snapshot => {
    return docsToObj(snapshot);
  });
  */
};

export const watchLocks = (callback) => {
  const lock = rdb.ref('/locks').on('value', (snapshot) => {
    return callback(snapshot.val());
  });
  return lock;
  /*
  return locksCollection.get().then(snapshot => {
    return docsToObj(snapshot);
  });
  */
};

export const fetchProjectsArray = () => {
  return projectsCollection
    .where('deleted', '==', false)
    .orderBy('timestamp', 'desc')
    .get()
    .then((querySnapshot) => {
      let projects = [];
      // firebase does not have .map() support
      querySnapshot.forEach((doc) => {
        projects.push({ id: doc.id, projectId: doc.id, ...doc.data() });
      });
      return projects;
    });
};

export const createProject = (options = {}) => {
  const {
    projectName,
    userId,
    userName,
    userEmail,
    organizationId,
    organizationName,
  } = options;
  if (!userId) throw new Error('New projects need at least 1 new user');
  if (!projectName) throw new Error('Please provide a name for the project');
  const project = projectsCollection.doc();
  const timestamp = serverTimestamp();
  const projectDetails = {
    name: projectName,
    id: project.id,
    organizationName,
    organizationId,
    creator: {
      id: userId,
      name: userName,
      email: userEmail,
    },
    timestamp,
    unpublished: true,
    deleted: false,
  };
  Tracking.event({
    name: generalEvents.NEW_PROJECT_CREATION,
    properties: {
      id: project.id,
    },
  });
  const batch = db.batch();
  batch.set(project, projectDetails);
  return batch.commit().then(() => {
    return project;
  });
};

export const updateProject = (options = {}) => {
  const { projectId, name } = options;
  if (!projectId) throw new Error('Please provide a project ID for the survey');
  const timestamp = serverTimestamp();
  const project = projectsCollection.doc(projectId);
  const projectDetails = {
    name,
    timestamp,
  };

  const batch = db.batch();
  batch.update(project, projectDetails);
  return batch.commit().then(() => {
    return project;
  });
};

export const markProjectAsDeleted = async (projectId) => {
  if (!projectId) throw new Error('Please provide a project ID for the survey');
  return projectsCollection.doc(projectId).update({ deleted: true });
};

export const generateSurveyCode = (options = {}) => {
  // exclude 0 1 O I Q D 8 B 5 S 2 Z U V due to similar shapes
  // 22 possible options = (22 * 21 * 20 * 19 * 18 * 17) = 53,721,360 unique options w/ 6 chars
  const { charCount = 6 } = options;
  const alphabet = '34679ACEFGHJKLMNPRTWXY';
  let surveyCode = '';
  for (let i = 0; i < charCount; i++) {
    const randomInt = Math.floor(Math.random() * 22);
    const newCodeLetter = alphabet[randomInt];
    surveyCode += newCodeLetter;
  }
  return surveyCode;
};

export const fetchSurveyByCode = (options = {}) => {
  const { surveyCode } = options;
  if (!surveyCode) throw new Error('please provide a surveyCode');
  return surveysCollection
    .where('surveyCode', '==', surveyCode)
    .orderBy('timestamp', 'desc')
    .get();
};

export const getAvailableSurveyCode = (options = {}) => {
  let surveyCode = generateSurveyCode();
  return new Promise((resolve, reject) => {
    const fetchSurvey = () => {
      fetchSurveyByCode({ surveyCode }).then((survey) => {
        if (survey.empty) {
          return resolve({ surveyCode });
        }
        surveyCode = generateSurveyCode();
        return fetchSurvey({ surveyCode });
      });
    };
    fetchSurvey({ surveyCode });
  });
};

export const createHilo = (options = {}) => {
  const {
    projectId,
    name,
    hilo,
    userId,
    results = [],
    modules = {},
    cloneSurveyCode,
    userName,
    userEmail,
    draft = true,
    unpublished = true,
    closed = false,
    closeDate = null,
    organizationId,
    organizationName,
    templateId,
    template = false,
  } = options;
  if (!projectId) throw new Error('Please provide a project ID for the survey');
  if (!name) throw new Error('Please provide a name for the survey');
  const codePromise = cloneSurveyCode
    ? Promise.resolve({ surveyCode: cloneSurveyCode })
    : getAvailableSurveyCode();
  return codePromise.then(({ surveyCode }) => {
    const survey = surveysCollection.doc(surveyCode);
    const timestamp = serverTimestamp();
    const surveyId = survey.id;
    const surveyDetails = {
      name,
      hilo,
      projectId,
      surveyCode,
      surveyId,
      ...(hilo === HILOS.WORKSHOP && {
        workshopCode: surveyCode,
        workshopId: surveyId,
      }),
      results,
      timestamp,
      draft,
      creator: userId,
      creatorName: userName,
      creatorEmail: userEmail,
      createTimestamp: timestamp,
      modules,
      unpublished,
      closed,
      closeDate,
      userId,
      organizationName,
      organizationId,
      responseCount: 0,
      template,
      deleted: false,
      ...(templateId && { templateId }),
    };
    const batch = db.batch();
    batch.set(survey, surveyDetails);
    const properties = { id: surveyCode };
    Tracking.hiloCreation({ hilo, properties });
    return batch.commit().then(() => {
      return surveyId;
    });
  });
};

export const addTeamMember = (options = {}) => {
  const { projectId, userId, userName = '' } = options;
  if (!projectId)
    throw new Error('Please provide a project ID for the team member');
  if (!userId) throw new Error('Please provide a user ID for the team member');
  const timestamp = serverTimestamp();
  const project = projectsCollection.doc(projectId);
  // https://firebase.google.com/docs/database/admin/save-data#section-update
  // https://firebase.google.com/docs/reference/js/firebase.firestore.Transaction#update
  //  use dots to update the path string (not slashes)
  const projectDetails = {
    [`team.${userId}`]: { name: userName, userId, timestamp },
    teamIds: firebase.firestore.FieldValue.arrayUnion(userId),
    timestamp,
  };
  const batch = db.batch();
  batch.update(project, projectDetails);
  return batch.commit();
};

export const deleteProject = (options = {}) => {
  const { projectId } = options;
  if (!projectId) throw new Error('Please provide a project ID');
  const batch = db.batch();
  return surveysCollection
    .where('projectId', '==', projectId)
    .get()
    .then((surveys) => {
      const moduleDeletes = surveys.docs.map((survey) => {
        const data = survey.data();
        const { modules } = data;
        const moduleKeys = Object.keys(modules);
        const surveyModuleDeletes = moduleKeys.map((moduleKey) => {
          const moduleDoc = modulesCollection.doc(moduleKey);
          return batch.delete(moduleDoc);
        });
        const surveyDoc = surveysCollection.doc(survey.id);
        const surveyDelete = batch.delete(surveyDoc);
        return [...surveyModuleDeletes, surveyDelete];
      });
      const project = projectsCollection.doc(projectId);
      // todo: delete user responses?
      const projectDelete = batch.delete(project);
      return batch.commit().then(() => {
        return { projectDelete, moduleDeletes };
      });
    });
};

export const removeTeamMember = (options = {}) => {
  const { userId, projectId } = options;
  if (!userId) throw new Error('Please provide a user ID');
  if (!projectId) throw new Error('Please provide a project ID');
  const batch = db.batch();
  const timestamp = serverTimestamp();
  const project = projectsCollection.doc(projectId);
  const projectDetails = {
    [`team.${userId}`]: false,
    teamIds: firebase.firestore.FieldValue.arrayRemove(userId),
    timestamp,
  };
  batch.update(project, projectDetails);
  return batch.commit();
};

export const fetchTeamMembers = (options = {}) => {
  const { organizationId } = options;
  if (!organizationId) throw new Error('Please provide an organization ID');
  return usersCollection
    .where('organizationId', '==', organizationId)
    .get()
    .then((data) => {
      return data.docs.reduce((collector, doc) => {
        return {
          ...collector,
          [doc.id]: {
            userId: doc.id,
            ...doc.data(),
          },
        };
      }, {});
    });
};

export const surveyToDetails = (survey) => {
  const surveyId = survey.id;
  const data = survey.data();
  /*
  debugger;
  const moduleKeys = Object.keys(data).filter(
    key => key.indexOf('modules.') === 0
  );
  const modules = moduleKeys.reduce((collector, moduleKey) => {
    const moduleId = moduleKey.replace('modules.', '');
    const moduleData = data[moduleKey];
    return {
      ...collector,
      [moduleId]: moduleData
    };
  }, {});
  */
  const surveyDetails = {
    surveyId,
    ...data,
    //modules
  };
  return surveyDetails;
};

export const moduleToDetails = (moduleDoc) => {
  const moduleId = moduleDoc.id;
  const data = moduleDoc.data();
  const moduleDetails = {
    moduleId,
    ...data,
  };
  return moduleDetails;
};

function getCloneQuestionData(currentQuestionData, surveyModuleClones) {
  const {
    [POPULATE_MODULE]: populateModule,
    [SELECTED_POPULATE_OPTIONS_IDS]: selectedPopulateOptionsIds,
  } = currentQuestionData;
  const hasPopulateRelatedData = populateModule || selectedPopulateOptionsIds;
  if (!hasPopulateRelatedData) return currentQuestionData;

  let {
    [SELECTED_POPULATE_OPTIONS_IDS]: _,
    ...updatedQuestionData
  } = currentQuestionData;
  if (selectedPopulateOptionsIds && !populateModule) {
    return updatedQuestionData;
  }
  updatedQuestionData = Object.entries(updatedQuestionData)
    .filter(([key]) => !key.startsWith(OPTION_PREFIX))
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
  const clonePopulateModuleId = surveyModuleClones.find(
    ({ moduleKey }) => moduleKey === populateModule.moduleId
  ).moduleClone.id;
  return {
    ...updatedQuestionData,
    [POPULATE_MODULE]: {
      ...populateModule,
      moduleId: clonePopulateModuleId,
    },
  };
}

export const fetchTemplateSurveys = (options = {}) => {
  return surveysCollection
    .where('template', '==', true)
    .get()
    .then((surveys) => {
      const data = surveys.docs.reduce((collector, survey) => {
        const surveyId = survey.id;
        const surveyDetails = surveyToDetails(survey);
        return {
          ...collector,
          [surveyId]: surveyDetails,
        };
      }, {});
      return data;
    });
};

export const cloneSurvey = (options = {}) => {
  const {
    surveyId,
    projectId,
    userId,
    userName,
    userEmail,
    organizationId,
    organizationName,
  } = options;
  if (!surveyId) throw new Error('please provide a surveyId to clone');
  if (!projectId) throw new Error('Please provide a project ID for the survey');
  const fetchSurveyDetails = surveysCollection
    .doc(surveyId)
    .get()
    .then((survey) => {
      return surveyToDetails(survey);
    });
  return Promise.all([fetchSurveyDetails, getAvailableSurveyCode()]).then(
    ([surveyDetails, { surveyCode }]) => {
      const { modules, name: surveyName, hilo } = surveyDetails;
      const moduleKeys = Object.keys(modules);
      const surveyModuleClones = moduleKeys.map((moduleKey) => {
        const moduleClone = modulesCollection.doc();
        return { moduleKey, moduleClone };
      });
      const batch = db.batch();
      const cloneFetches = surveyModuleClones.map(
        ({ moduleKey, moduleClone }) => {
          const moduleId = moduleClone.id;
          const moduleDoc = modulesCollection.doc(moduleKey);
          return moduleDoc.get().then((module) => {
            const moduleDetails = moduleToDetails(module);
            const newModuleDetails = {
              ...moduleDetails,
              questionData: getCloneQuestionData(
                moduleDetails.questionData,
                surveyModuleClones
              ),
              organizationId,
              organizationName,
              moduleId,
              surveyId: surveyCode,
            };
            batch.set(moduleClone, newModuleDetails);
          });
        }
      );
      return Promise.all(cloneFetches)
        .then(() => {
          return batch.commit();
        })
        .then(() => {
          const moduleArray = surveyModuleClones.map(
            ({ moduleClone }) => moduleClone.id
          );
          const moduleHash = moduleArray.reduce((collector, moduleId) => {
            return {
              ...collector,
              [moduleId]: true,
            };
          }, {});
          return createHilo({
            projectId,
            name: `${surveyName} - copy`,
            userId,
            modules: moduleHash,
            cloneSurveyCode: surveyCode,
            userName,
            userEmail,
            unpublished: true,
            closed: false,
            closeDate: '',
            organizationId,
            organizationName,
            hilo,
          }).then((surveyId) => {
            return { projectId, surveyId };
          });
        });
    }
  );
};

export const saveTemplate = async (organizationId, data) => {
  const templateRef = surveysCollection.doc();
  const id = templateRef.id;
  const timestamp = serverTimestamp();
  Tracking.hiloCreation({
    hilo: HILOS.TEMPLATE,
    properties: {
      id,
    },
  });
  return templateRef.set(
    {
      id,
      templateId: id,
      ...data,
      timestamp,
      createTimestamp: timestamp,
    },
    { merge: true }
  );
};

export const fetchTemplates = async (organizationId) => {
  const data = await surveysCollection
    .where('organizationId', '==', organizationId)
    .where('template', '==', true)
    .get();
  return data.docs.map((d) => d.data());
};

export const deleteTemplate = async (templateId) => {
  return surveysCollection.doc(templateId).delete();
};
