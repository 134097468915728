import React, { Component } from 'react';
import styled from 'styled-components';
import { TEAM, NAME, EMAIL } from '../../../const/module-types';
import { getNestedData } from '../../../const/nested-data';
import { filterAndSortOptions } from '../../Builder/ModuleCard';

import { Input } from '../../../components/layout';

const PointDistributionContainer = styled('div')`
  width: 100%;
  max-width: 44.375rem;
  margin: auto;
  padding: 1rem;
  ${({ inMobileView }) =>
    inMobileView
      ? ``
      : `
  @media (min-width: 1200px) {
    padding: 0;
  }
  `}
`;

const PersonalInfoInput = styled(Input)`
  background-color: white;
  margin-bottom: 1rem;
  position: relative;
  &::after {
    content: ' ';
    display: block;
    background: red;
    bottom: 0.25rem;
    left: 0rem;
    width: 12.5rem;
    border-bottom: 1px dotted rgba(0, 0, 0, 0.15);
  }
`;

export const TeamContainer = styled('div')`
  position: relative;
`;

export const TeamRadioContainer = styled('div')`
  position: absolute;
  width: 100%;
  max-height: 20.9375rem;
  overflow: scroll;
  background: #ff9b3a;
  right: 0;
  bottom: 50%;
  box-shadow: 0 0 0.375rem rgba(0, 0, 0, 0.4);
`;

export const TeamRadio = styled('label')`
  cursor: pointer;
  display: block;
  color: white;
  font-family: 'Neue Haas Grotesk Display Std', sans-serif;
  font-size: 1.125rem;
  line-height: 2.5rem;
  padding: 0 0.625rem;
  margin: 0;
  color: white;
  font-weight: ${(props) => (props.active ? 'bold' : 'normal')};
  &:hover {
    background: #d47f34;
  }
`;

export const TeamRadioInput = styled('input')`
  display: none;
`;

export default class PersonalInfo extends Component {
  state = {
    showTeamDropdown: false,
  };
  showTeamDropdown = (e) => {
    e.stopPropagation();
    this.setState({ showTeamDropdown: !this.state.showTeamDropdown });
  };
  hideTeamDropdown = (e) => {
    e.stopPropagation();
    this.setState({ showTeamDropdown: false });
  };
  render() {
    const { questionData, inPresentationMode, inMobileView } = this.props;
    if (!questionData || inPresentationMode) return null;
    const teamOptions = filterAndSortOptions(questionData);
    const nameKey = `personalInfo.${this.props.moduleId}.${NAME}`;
    const emailKey = `personalInfo.${this.props.moduleId}.${EMAIL}`;
    const teamKey = `personalInfo.${this.props.moduleId}.${TEAM}`;
    const nameVal = getNestedData(this.props.values, nameKey);
    const emailVal = getNestedData(this.props.values, emailKey);
    const teamVal = questionData[getNestedData(this.props.values, teamKey)];
    const disableTeam = questionData.disableCategoryDescription || false;
    const disableEmailInput = questionData.disableEmailInput || false;
    const teamRadios = teamOptions.map(([optionKey, teamName], i) => {
      return (
        <TeamRadio key={optionKey} active={optionKey === teamVal}>
          <TeamRadioInput
            name={teamKey}
            value={optionKey}
            type="radio"
            onChange={this.props.handleChange}
            checked={optionKey === teamVal}
            onClick={this.hideTeamDropdown}
          />
          {teamName}
        </TeamRadio>
      );
    });
    const teamRadioContainer = this.state.showTeamDropdown && (
      <TeamRadioContainer>{teamRadios}</TeamRadioContainer>
    );
    const teamPlaceholder = questionData.categoryDescription || 'Team';
    return (
      <PointDistributionContainer {...{ inMobileView }}>
        <div>
          <PersonalInfoInput
            name={nameKey}
            value={nameVal || ''}
            placeholder="Name"
            onChange={this.props.handleChange}
          />
        </div>
        {!disableEmailInput && (
          <div>
            <PersonalInfoInput
              name={emailKey}
              value={emailVal || ''}
              placeholder="Email"
              onChange={this.props.handleChange}
            />
          </div>
        )}
        {disableTeam ? null : (
          <TeamContainer>
            <PersonalInfoInput
              name={teamKey}
              value={teamVal || ''}
              placeholder={teamPlaceholder}
              readOnly
              onClick={this.showTeamDropdown}
            />
            {teamRadioContainer}
          </TeamContainer>
        )}
      </PointDistributionContainer>
    );
  }
}
