import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryScatter,
  VictoryTooltip,
} from 'victory';
import HTMLFlyOut from '../Tooltips/HTMLFlyOut';
import { InnerText } from '../Tooltips';
import { chartMouseEvents } from '../../../../lib/Events';
import { colors } from '../../../../const/theme';
import { LabelTextComp, LabelTspanComp } from '../others';
import {
  setClickedChartElementResponseIds,
  setCommentsPanelVisibility,
  setClickedChartElementOptionKey,
  setSelectedParticipantResponseId,
  setStackRankSelectedPoint,
  setSelectedPointKey,
  setPanelComments,
} from 'pages/presentationSlice';
import { HILOS } from 'const/index';

const chartRightPadding = 20;

function isPointHighlighted(
  datum,
  selectedPoint,
  optionKey,
  selectedParticipantResponseId
) {
  return (
    (datum.points === selectedPoint && datum.option === optionKey) ||
    datum.users.includes(selectedParticipantResponseId)
  );
}

const ScatterPoint = ({
  x,
  y,
  size,
  datum,
  moduleId,
  onClickBubble,
  selectedParticipantResponseId,
}) => {
  const [hovered, setHovered] = React.useState(false);

  // Selected point when a user clicks view selection in the comments section
  const selectedPoint = useSelector(
    (state) => state.presentation[moduleId].selectedDataPoint
  );

  const optionKey = useSelector(
    (state) => state.presentation.clickedChartElementOptionKey
  );

  const onClick = () => {
    onClickBubble(datum);
  };

  return (
    <circle
      cx={x}
      cy={y}
      r={size}
      stroke={hovered ? colors.STROKE_CHART_COLOR : colors.STROKE_CHART_COLOR}
      strokeWidth={1}
      fill={
        isPointHighlighted(
          datum,
          selectedPoint,
          optionKey,
          selectedParticipantResponseId
        )
          ? colors.SELECTED_CHART_FILL_COLOR
          : colors.PRIMARY_CHART_FILL_COLOR
      }
      onClick={onClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    />
  );
};

const LineBubbleChart = ({
  data,
  xLabel,
  moduleId,
  flyOutLabelType,
  mode,
  comparisonSection,
}) => {
  const dispatch = useDispatch();

  const isCommentPanelVisible = useSelector(
    (state) => state.presentation.isCommentsPanelVisible
  );
  const comments = useSelector((state) => state.presentation.comments);

  const selectedParticipantResponseId = useSelector((state) => {
    if (comparisonSection && mode === HILOS.WORKSHOP) {
      return state.workshop.comparison[comparisonSection]
        .selectedParticipantResponseId;
    } else {
      return state.presentation.selectedParticipantResponseId;
    }
  });

  const onClickedBubble = (datum) => {
    const responseIds = datum.users;
    // Set this to null so that the highlight on View selection
    // In the comment section does not conflict with the bar click.
    dispatch(setSelectedParticipantResponseId(null));
    // Key set when a bubble is clicked same similar/same to setClickedChartElementOptionKey
    dispatch(setSelectedPointKey({ moduleId, selectedPoint: datum.option }));
    dispatch(
      setStackRankSelectedPoint({
        moduleId,
        selectedDataPoint: datum.points,
      })
    );
    dispatch(setClickedChartElementOptionKey(datum.option));
    dispatch(setClickedChartElementResponseIds(responseIds));
    dispatch(setCommentsPanelVisibility(true));
    dispatch(setPanelComments(comments));
  };

  return (
    <>
      <VictoryChart
        domainPadding={{ x: [30, chartRightPadding] }}
        padding={{ top: 0, bottom: 70, left: 80, right: 50 }}
        style={{
          parent: {
            overflow: 'visible',
            margin: '0 auto',
            marginTop: isCommentPanelVisible ? -5 : -2,
            width: isCommentPanelVisible ? '100%' : '80%',
          },
        }}
      >
        <VictoryAxis
          style={{
            axis: { stroke: 'transparent' },
            grid: { stroke: '#3FAFFA' },
          }}
          tickLabelComponent={
            <VictoryLabel
              textComponent={<LabelTextComp />}
              tspanComponent={<LabelTspanComp />}
              renderInPortal
            />
          }
        />
        <VictoryAxis
          dependentAxis
          label={xLabel}
          crossAxis={false}
          style={{
            axis: { stroke: 'black' },
            axisLabel: {
              fontSize: 8,
              fontFamily: 'Roboto',
              fontWeight: 'bold',
              padding: 20,
            },
            ticks: { stroke: 'black', size: -5, angle: 90 },
            tickLabels: { fontSize: 8, fontFamily: 'Roboto', fontWeight: 400 },
          }}
        />

        <VictoryScatter
          events={[chartMouseEvents(false, null, onClickedBubble)]}
          data={data}
          style={{
            data: {
              fill: colors.PRIMARY_CHART_FILL_COLOR,
              stroke: colors.STROKE_CHART_COLOR,
              strokeWidth: 1,
            },
          }}
          dataComponent={
            <ScatterPoint
              moduleId={moduleId}
              onClickBubble={onClickedBubble}
              selectedParticipantResponseId={selectedParticipantResponseId}
            />
          }
          labels={() => ''}
          labelComponent={
            <VictoryTooltip
              flyoutComponent={
                <HTMLFlyOut
                  chartRightPadding={chartRightPadding}
                  isStackRank
                  flyOutLabelType={flyOutLabelType}
                  render={(datum, flyOutLabel, numberOfMembers, members) => (
                    <InnerText
                      datum={datum}
                      flyOutLabelType={flyOutLabel}
                      numberOfMembers={numberOfMembers}
                    />
                  )}
                />
              }
            />
          }
          horizontal
          bubbleProperty="radius"
          maxBubbleSize={10}
          minBubbleSize={4}
          alignment="middle"
          x="option"
          y="points"
        />
      </VictoryChart>
    </>
  );
};

export default LineBubbleChart;
