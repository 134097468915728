export const filterAndSortStackRank = (questionData, addedOptions) => {
  return Object.entries(questionData)
    .filter(([stackId, stackText]) => {
      return stackId.indexOf('option') === 0 && !addedOptions.includes(stackId);
    })
    .sort(([aId, aText], [bId, bText]) => {
      const aInt = parseInt(aId.replace('option', ''), 10);
      const bInt = parseInt(bId.replace('option', ''), 10);
      return aInt - bInt;
    });
};
