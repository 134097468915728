import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import mixpanel from 'mixpanel-browser';
import * as Sentry from '@sentry/react';
import { Provider } from 'react-redux';

import App from './application';
import { unregister as unregisterServiceWorker } from './register-service-worker';
import {
  getApplicationEnv,
  getGoogleTagManagerConfig,
  SENTRY_DSN,
  MIXPANEL_PROJECT_TOKEN,
  PRODUCTION_ENV,
  MIXPANEL_PROJECT_TOKEN_STAGING,
  STAGING_DEV,
} from './const';
import store from './redux/store';

const environment = getApplicationEnv();

Sentry.init({ dsn: SENTRY_DSN, environment });

if (environment === PRODUCTION_ENV) {
  mixpanel.init(MIXPANEL_PROJECT_TOKEN);
} else if (environment === STAGING_DEV) {
  mixpanel.init(MIXPANEL_PROJECT_TOKEN_STAGING, { debug: true });
} else {
  // Init mix panel in dev mode with staging token if needed
  mixpanel.init(MIXPANEL_PROJECT_TOKEN_STAGING, { debug: true });
}

TagManager.initialize(getGoogleTagManagerConfig());

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
unregisterServiceWorker();
