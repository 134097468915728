import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useQuery } from '../../hooks/useQuery';
import {
  setActiveQuestion,
  setMultiSelectChartData,
  setSpectrumChartData,
  setSpectrumChartLabels,
  setQuestionsData as setQuestionData,
  setPlotter22Data as setPlotter22DataGlobal,
  setIsWordCloudSelected as setIsWordCloudSelectedGlobal,
  setPointDistributionData as setPointDistributionDataGlobal,
  setMadlibData as setMadlibDataGlobal,
  setOpenQuestionData as setOpenQuestionDataGlobal,
  setStackRankChartData as setStackRankDataGlobal,
  setStackRankNumberOfParticipant as setStackRankDataParticipants,
  setOpenQuestionResponseToggle as setOpenQuestionResponseToggleGlobal,
  setModules,
  setAddOptionsResponses as setAddOptionsResponsesGlobal,
  setComments,
  setStackRankParticipantsData,
} from 'pages/presentationSlice';
import {
  setComparisonSectionOneActiveQuestion,
  setComparisonSectionOneComments,
  setComparisonSectionTwoActiveQuestion,
  setComparisonSectionTwoComments,
} from 'pages/workshopSlice';
import {
  ADD_OPTION,
  COMPARISON_SECTIONS,
  MADLIB,
  MULTI_SELECT,
  OPEN_ENDED,
  PERSONAL_INFO,
  PLOTTER_22,
  POINT_DISTRIBUTION,
  SPECTRUM_DISTRIBUTION,
  STACK_RANK,
  TEXT_SLIDE,
} from '../../const/module-types';
import {
  getMadlibData,
  getPlotter22Data,
  getPlotterValuesOnXAxis,
  getPlotterValuesOnYAxis,
  getPointDistributionData,
  getSpectrumData,
  getStackRankData,
  isThereData,
  sortPointDistributionDataAsc,
  sortPointDistributionDataDesc,
  sortStackRankDataAsc,
  sortStackRankDataDesc,
  getAddOptions,
} from '../../lib/Presentation';
import { sortModulesToArray } from '../../lib/builder-api';
import Chart from './Chart';
import { getOpenQuestionData } from '../../lib/Presentation/OpenQuestion';
import MultiSelectUtils from 'lib/Presentation/MultiSelect';
import { getComments } from 'lib/Presentation/SpectrumDistribution';
import {
  getQuestionsData,
  setModuleIdQueryParam,
} from 'lib/Presentation/Helper';
import { HILOS } from 'const/index';
import { isEqual } from 'lodash';

const Presentation = ({
  moduleList,
  initialSelectedModuleId,
  children,
  mode,
  activeQuestion,
  currentUser,
  getDataAsync,
}) => {
  const [
    openQuestionBubblesShouldReanimate,
    setOpenQuestionsBubbleShouldReanimate,
  ] = useState(true);

  const dispatch = useDispatch();

  const history = useHistory();

  const query = useQuery();

  const presentationData = useSelector((state) => state.presentation);

  const userResponses = useSelector(
    (state) => state.presentation.userResponses
  );

  const questionsData = useSelector(
    (state) => state.presentation.questionsData
  );

  const modules = useSelector((state) => state.presentation.modules);

  const currentActiveQuestion = useSelector(
    (state) => state.presentation.activeQuestion
  );

  useEffect(() => {
    const moduleIdQueryParam = query.get('moduleId');
    loadSurvey({ moduleIdQueryParam });
  }, [userResponses, mode]);

  useEffect(() => {
    if (activeQuestion.moduleType === POINT_DISTRIBUTION) {
      (async () => await onSortAsc({ asc: true, desc: false }))();
    }
  }, [activeQuestion]);

  const loadSurvey = async ({ moduleIdQueryParam }) => {
    const arrayModules = sortModulesToArray(moduleList);
    dispatch(setModules(arrayModules));
    setChartDataAndSurveyQuestions(
      arrayModules,
      userResponses,
      moduleIdQueryParam
    );
  };

  const setChartDataAndSurveyQuestions = (
    moduleList,
    responses,
    moduleIdQueryParam
  ) => {
    if (!moduleList) return;
    const availableModules = [
      POINT_DISTRIBUTION,
      STACK_RANK,
      PLOTTER_22,
      SPECTRUM_DISTRIBUTION,
      MADLIB,
      OPEN_ENDED,
      MULTI_SELECT,
      TEXT_SLIDE,
      ADD_OPTION,
    ];
    const arrayModules = moduleList.filter((module) =>
      availableModules.includes(module.moduleType)
    );
    const qnsData = getQuestionsData({ modules: arrayModules });
    setInitialChartDataAndQuestion(
      qnsData,
      responses,
      moduleList,
      moduleIdQueryParam
    );
    dispatch(setQuestionData(qnsData));
  };

  const setInitialChartDataAndQuestion = (
    questionsData,
    responses,
    moduleList,
    moduleIdQueryParam
  ) => {
    const questions = questionsData.questions;
    if (questions.length <= 0) return;
    const defaultQuestion = moduleIdQueryParam
      ? moduleIdQueryParam
      : getDefaultQuestion(questionsData);
    const questionMetaData = questionsData.questionsMetaData[defaultQuestion];
    const moduleType =
      questionsData?.questionsMetaData[defaultQuestion]?.moduleType;
    const moduleId =
      questionsData?.questionsMetaData[defaultQuestion]?.moduleId;
    let currentActiveQuestion = {
      question: defaultQuestion,
      metaData: questionMetaData,
      moduleType,
      moduleId,
    };
    dispatch(setActiveQuestion(currentActiveQuestion));
    if (mode === HILOS.PRESENTATION) {
      setModuleIdQueryParam(moduleId, history);
    }
    setCurrentChartData(
      moduleType,
      currentActiveQuestion,
      moduleId,
      questionMetaData.questionData,
      responses,
      moduleList
    );
  };

  const getDefaultQuestion = (questionsData) => {
    const initialSelectedModule = initialSelectedModuleId
      ? Object.values(questionsData.questionsMetaData).find(
          (q) => q.moduleId === initialSelectedModuleId
        )
      : null;
    const firstQn = Object.values(questionsData.questionsMetaData)[0];
    return initialSelectedModule
      ? initialSelectedModule?.moduleId
      : firstQn?.moduleId;
  };

  const setComparisonSectionComments = (comparisonSection, comments) => {
    if (!comparisonSection) return;

    if (comparisonSection === COMPARISON_SECTIONS.ONE) {
      dispatch(setComparisonSectionOneComments(comments));
    }

    if (comparisonSection === COMPARISON_SECTIONS.TWO) {
      dispatch(setComparisonSectionTwoComments(comments));
    }
  };

  function setCurrentChartData(
    moduleType,
    selectedActiveQuestion,
    moduleId,
    questionData,
    responses,
    moduleList,
    comparisonSection = null
  ) {
    if (moduleType === POINT_DISTRIBUTION) {
      const data = getPointDistributionData(
        responses,
        moduleList,
        selectedActiveQuestion,
        moduleId
      );

      dispatch(
        setPointDistributionDataGlobal({
          ...data,
          moduleId,
          showChartControls: isThereData(data.chartData),
          shouldShowDistributionButton: true,
          sortChartControls: {
            sortAscSelected: true,
            sortDescSelected: false,
          },
        })
      );

      dispatch(setComments(data.comments));
      setComparisonSectionComments(comparisonSection, data.comments);

      return;
    }
    if (moduleType === STACK_RANK) {
      const {
        chartData,
        numberOfParticipants,
        comments,
        individualChartData,
        participantsData,
      } = getStackRankData(
        responses,
        moduleList,
        selectedActiveQuestion,
        moduleId
      );
      dispatch(
        setStackRankDataGlobal({
          chartData,
          moduleId,
          individualChartData,
        })
      );
      dispatch(
        setStackRankDataParticipants({ numberOfParticipants, moduleId })
      );
      dispatch(setStackRankParticipantsData({ moduleId, participantsData }));
      dispatch(setComments(comments));
      setComparisonSectionComments(comparisonSection, comments);
      return;
    }
    if (moduleType === PLOTTER_22) {
      const plotter22Data = getPlotter22Data(
        responses,
        questionData,
        moduleList,
        moduleId
      );
      const comments = getComments({
        userResponses: responses,
        moduleList,
        moduleId,
      });
      dispatch(
        setPlotter22DataGlobal({
          moduleId,
          ...plotter22Data,
          showChartControls: true,
        })
      );
      dispatch(setComments(comments));
      setComparisonSectionComments(comparisonSection, comments);
      return;
    }
    if (moduleType === SPECTRUM_DISTRIBUTION) {
      const { chartData } = getSpectrumData(responses, moduleList, moduleId);
      const comments = getComments({
        userResponses: responses,
        moduleList,
        moduleId,
      });

      dispatch(
        setSpectrumChartData({ moduleId, chartData, showChartControls: false })
      );
      dispatch(setSpectrumChartLabels({ moduleId, labels: questionData }));
      dispatch(setComments(comments));
      setComparisonSectionComments(comparisonSection, comments);
      return;
    }
    if (moduleType === TEXT_SLIDE) {
      setComparisonSectionComments(comparisonSection, []);
      return;
    }
    if (moduleType === MADLIB) {
      const comments = getComments({
        userResponses: responses,
        moduleList,
        moduleId,
      });

      const { participantsData } = getMadlibData(
        responses,
        moduleList,
        moduleId
      );

      dispatch(
        setMadlibDataGlobal({
          moduleId,
          participantsData,
          userResponses: responses,
          questionData: selectedActiveQuestion.metaData.questionData,
          showChartControls: false,
        })
      );

      dispatch(setComments(comments));
      setComparisonSectionComments(comparisonSection, comments);
      return;
    }
    if (moduleType === OPEN_ENDED) {
      const {
        allResponses,
        wordCloudData,
        participantsData,
      } = getOpenQuestionData({
        userResponses: responses,
        moduleList,
        moduleId,
      });
      dispatch(
        setOpenQuestionDataGlobal({
          moduleId,
          userResponses: allResponses,
          wordCloudData,
          participantsData,
          showChartControls: true,
        })
      );

      dispatch(
        setOpenQuestionResponseToggleGlobal({
          moduleId,
        })
      );
      dispatch(
        setIsWordCloudSelectedGlobal({
          moduleId,
        })
      );
      setComparisonSectionComments(comparisonSection, []);
    }
    if (moduleType === MULTI_SELECT) {
      const comments = MultiSelectUtils.getUserComments({
        userResponses: responses,
        moduleList,
        questionData,
        moduleId,
      });

      dispatch(
        setMultiSelectChartData({
          ...MultiSelectUtils.getChartData({
            userResponses: responses,
            moduleId,
            modules,
            questionData,
          }),
          moduleId,
          showChartControls: false,
        })
      );

      dispatch(setComments(comments));
      setComparisonSectionComments(comparisonSection, comments);
    }
    if (moduleType === ADD_OPTION) {
      const data = getAddOptions({ userResponses: responses, moduleId });
      const comments = getComments({
        userResponses: responses,
        moduleList,
        moduleId,
      });
      dispatch(
        setAddOptionsResponsesGlobal({
          chartData: data,
          moduleId,
        })
      );

      dispatch(setComments(comments));
      setComparisonSectionComments(comparisonSection, comments);
    }
  }

  /**
   * this compares the currently active question with the selected question
   * @returns Boolean
   */
  const compareQuestion = (selectedActiveQuestion) => {
    return isEqual(currentActiveQuestion, selectedActiveQuestion);
  };

  const onQuestionSelected = (
    moduleId,
    setModuleQueryParam = true,
    comparisonSection = null
  ) => {
    const moduleType = questionsData?.questionsMetaData[moduleId]?.moduleType;
    const metaData = questionsData?.questionsMetaData[moduleId];
    const selectedActiveQuestion = {
      question: moduleId,
      metaData,
      moduleType,
      moduleId,
    };
    if (setModuleQueryParam) {
      setModuleIdQueryParam(moduleId, history);
    }
    if (comparisonSection && comparisonSection === COMPARISON_SECTIONS.ONE) {
      dispatch(setComparisonSectionOneActiveQuestion(selectedActiveQuestion));
    } else if (
      comparisonSection &&
      comparisonSection === COMPARISON_SECTIONS.TWO
    ) {
      dispatch(setComparisonSectionTwoActiveQuestion(selectedActiveQuestion));
    } else if (compareQuestion(selectedActiveQuestion)) {
      return;
    } else {
      dispatch(setActiveQuestion(selectedActiveQuestion));
    }
    setCurrentChartData(
      moduleType,
      selectedActiveQuestion,
      moduleId,
      metaData.questionData,
      userResponses,
      modules,
      comparisonSection
    );
  };

  const onSortAsc = ({ asc, desc }) => {
    const moduleId = activeQuestion.moduleId;
    if (activeQuestion.moduleType === POINT_DISTRIBUTION) {
      const distributionData = presentationData[moduleId];
      const sortFn = (a, b) => a.points - b.points;
      const spreadData = [...distributionData.averages];
      const averagesAscData = spreadData.sort(sortFn);
      const chartAscData = sortPointDistributionDataAsc(
        distributionData.averages,
        distributionData.chartData
      );
      return dispatch(
        setPointDistributionDataGlobal({
          ...distributionData,
          averages: averagesAscData,
          chartData: chartAscData,
          moduleId,
          sortChartControls: {
            sortAscSelected: asc,
            sortDescSelected: desc,
          },
        })
      );
    }
  };

  const onSortDesc = ({ asc, desc }) => {
    const moduleId = activeQuestion.moduleId;
    if (activeQuestion.moduleType === POINT_DISTRIBUTION) {
      const distributionData = presentationData[moduleId];
      const sortFn = (a, b) => b.points - a.points;
      const spreadData = [...distributionData.averages];
      const averagesDescData = spreadData.sort(sortFn);
      const chartDescData = sortPointDistributionDataDesc(
        distributionData.averages,
        distributionData.chartData
      );
      return dispatch(
        setPointDistributionDataGlobal({
          ...distributionData,
          averages: averagesDescData,
          chartData: chartDescData,
          moduleId,
          sortChartControls: {
            sortAscSelected: asc,
            sortDescSelected: desc,
          },
        })
      );
    }
  };

  /**
   * Y is 9.5 so that the average shows up in the middle of the axis.
   */
  function getPlotter22AvgOntoXAxis(plotter22Data, moduleId) {
    let plotterAvg = plotter22Data.average;
    const xAvgValue = plotterAvg[0].x;
    plotterAvg = [{ x: xAvgValue, y: 9.5 }];
    return dispatch(
      setPlotter22DataGlobal({
        moduleId,
        averagePoint: plotterAvg,
      })
    );
  }

  /**
   * X is -5.5 so that the average shows up in the middle of the axis.
   */
  function getPlotter22AvgOntoYAxis(plotter22Data, moduleId) {
    let plotterAvg = plotter22Data.average;
    const yAvgValue = plotterAvg[0].y;
    plotterAvg = [{ x: -5.5, y: yAvgValue }];
    return dispatch(
      setPlotter22DataGlobal({
        moduleId,
        averagePoint: plotterAvg,
      })
    );
  }

  const onClickPlotterAverage = (moduleId) => {
    const plotter22Data = presentationData[moduleId];
    const toggle = !plotter22Data.plotter22AvgToggle;

    if (toggle) {
      dispatch(
        setPlotter22DataGlobal({
          moduleId,
          plotter22AvgToggle: toggle,
        })
      );
      if (plotter22Data.plotter22XAxisToggle) {
        return getPlotter22AvgOntoXAxis(plotter22Data, moduleId);
      }
      if (plotter22Data.plotter22YAxisToggle) {
        return getPlotter22AvgOntoYAxis(plotter22Data, moduleId);
      }
      return dispatch(
        setPlotter22DataGlobal({
          averagePoint: plotter22Data.average,
          moduleId,
        })
      );
    }

    dispatch(
      setPlotter22DataGlobal({
        moduleId,
        plotter22AvgToggle: false,
      })
    );

    return dispatch(
      setPlotter22DataGlobal({
        averagePoint: null,
        moduleId,
      })
    );
  };

  /**
   * Collapse all the points onto the x-axis, even the average point
   * if it has already been clicked.
   * Only remove the average point if it was not earlier selected.
   * @param xAxisToggle
   * @param moduleId
   */
  const onCollapseXAxisPlotter22 = (xAxisToggle, moduleId) => {
    const plotter22Data = presentationData[moduleId];
    if (xAxisToggle) {
      dispatch(
        setPlotter22DataGlobal({
          moduleId,
          plotter22XAxisToggle: true,
        })
      );
      if (plotter22Data.plotter22AvgToggle) {
        getPlotter22AvgOntoXAxis(plotter22Data, moduleId);
      }
      return dispatch(
        setPlotter22DataGlobal({
          moduleId,
          chartData: getPlotterValuesOnXAxis(plotter22Data.chartDataCopy),
        })
      );
    }

    dispatch(
      setPlotter22DataGlobal({
        moduleId,
        plotter22XAxisToggle: false,
      })
    );

    if (plotter22Data.plotter22AvgToggle) {
      dispatch(
        setPlotter22DataGlobal({
          moduleId,
          averagePoint: plotter22Data.average,
        })
      );
    } else {
      dispatch(
        setPlotter22DataGlobal({
          moduleId,
          averagePoint: null,
        })
      );
    }
    dispatch(
      setPlotter22DataGlobal({
        moduleId,
        chartData: plotter22Data.chartDataCopy,
      })
    );
  };

  /**
   * Collapse all the points onto the y-axis, even the average point
   * if it has already been clicked.
   * Only remove the average point if it was not earlier selected.
   * @param yAxisToggle
   * @param moduleId
   */
  const onCollapseYAxisPlotter22 = (yAxisToggle, moduleId) => {
    const plotter22Data = presentationData[moduleId];

    if (yAxisToggle) {
      dispatch(
        setPlotter22DataGlobal({
          moduleId,
          plotter22YAxisToggle: true,
        })
      );
      if (plotter22Data.plotter22AvgToggle) {
        getPlotter22AvgOntoYAxis(plotter22Data, moduleId);
      }
      return dispatch(
        setPlotter22DataGlobal({
          moduleId,
          chartData: getPlotterValuesOnYAxis(plotter22Data.chartDataCopy),
        })
      );
    }

    dispatch(
      setPlotter22DataGlobal({
        moduleId,
        plotter22YAxisToggle: false,
      })
    );

    if (plotter22Data.plotter22AvgToggle) {
      dispatch(
        setPlotter22DataGlobal({
          moduleId,
          averagePoint: plotter22Data.average,
        })
      );
    } else {
      dispatch(
        setPlotter22DataGlobal({
          moduleId,
          averagePoint: null,
        })
      );
    }
    dispatch(
      setPlotter22DataGlobal({
        moduleId,
        chartData: plotter22Data.chartDataCopy,
      })
    );
  };

  const onClickOpenQuestionResponse = (moduleId) => {
    dispatch(
      setOpenQuestionResponseToggleGlobal({
        moduleId,
        openQuestionResponseToggle: true,
      })
    );
    dispatch(
      setIsWordCloudSelectedGlobal({
        moduleId,
        isWordCloudSelected: false,
      })
    );
  };

  const onClickOpenQuestionWordCloud = (moduleId) => {
    dispatch(
      setIsWordCloudSelectedGlobal({
        moduleId,
        isWordCloudSelected: true,
      })
    );
    dispatch(
      setOpenQuestionResponseToggleGlobal({
        moduleId,
        openQuestionResponseToggle: false,
      })
    );
  };

  return children({
    questionsData,
    activeQuestion,
    onQuestionSelected,
    chart: ({ className, showTextSlideQuestion, comparisonSection = null }) => {
      return (
        <Chart
          mode={mode}
          currentUser={currentUser}
          comparisonSection={comparisonSection}
          activeQuestion={activeQuestion}
          onClickSortAsc={onSortAsc}
          onClickSortDesc={onSortDesc}
          onClickPlotterAverage={onClickPlotterAverage}
          onCollapseXAxisPlotter22={onCollapseXAxisPlotter22}
          onCollapseYAxisPlotter22={onCollapseYAxisPlotter22}
          className={className}
          showTextSlideQuestion={showTextSlideQuestion}
          onClickOpenQuestionResponse={onClickOpenQuestionResponse}
          onClickOpenQuestionWordCloud={onClickOpenQuestionWordCloud}
          openQuestionBubblesAnimationControls={{
            shouldReAnimate: openQuestionBubblesShouldReanimate,
            onInitialAnimationComplete: () =>
              setOpenQuestionsBubbleShouldReanimate(false),
          }}
        />
      );
    },
  });
};

export default Presentation;
