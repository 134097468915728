import styled, { keyframes } from 'styled-components';

const circlePulse = keyframes`
  0% {
    stroke-width:1px;
  }
  25% {
    stroke-width:10px;
    stroke: #2AA6F9;
  }
  50% {
    stroke-width:15px;
    stroke: #2AA6F9;
  }
  100%{
    stroke-width:20px;
    stroke: #2AA6F9;
  }
`;

const StyledCircle = styled.svg`
  margin: auto;
`;

const StyledInnerCircle = styled.circle`
  animation: ${circlePulse} 2s ease-in-out;
  cursor: pointer;
`;

export const Circle = ({ cx, cy, r, stroke, fill, onClick }) => {
  return (
    <StyledCircle>
      <StyledInnerCircle
        cx={cx}
        cy={cy}
        r={r}
        stroke={stroke}
        stroke-width={1}
        fill={fill}
        onClick={onClick}
      />
    </StyledCircle>
  );
};
