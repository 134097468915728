import React from 'react';
import '@reach/tooltip/styles.css';
import '@reach/dialog/styles.css';
import { DialogContent, DialogOverlay } from '@reach/dialog';
import { ButtonBase } from 'components/buttons';
import {
  MULTI_SELECT,
  STACK_RANK,
  POINT_DISTRIBUTION,
  ADD_OPTION,
} from 'const/module-types';
import { styles } from './styles';
import { getAddOptionUserOptionsNames } from 'lib/Presentation/AddOptions';

const getOptionsTitlesForModule = (module, optionsIds = []) => {
  const moduleOptions = Object.entries(module?.questionData ?? {});
  const map = {
    [MULTI_SELECT]: () =>
      moduleOptions
        .filter(([key]) => optionsIds.includes(key))
        .map(([, value]) => value.title),
    [STACK_RANK]: () =>
      moduleOptions
        .filter(([key]) => optionsIds.includes(key))
        .map(([, value]) => value),
    [POINT_DISTRIBUTION]: () =>
      moduleOptions
        .filter(([key]) => optionsIds.includes(key))
        .map(([, value]) => value),
    [ADD_OPTION]: () =>
      moduleOptions
        .filter(([key]) => optionsIds.includes(key))
        .map(([, value]) => value.title),
  };
  return map[module?.moduleType]?.() ?? [];
};

function getSelectedOptionNames(
  module,
  selectedOptionsIds,
  formattedUserResponses
) {
  return [
    ...getOptionsTitlesForModule(module, selectedOptionsIds),
    ...getAddOptionUserOptionsNames(
      formattedUserResponses?.chartData || [],
      selectedOptionsIds
    ),
  ];
}

export const ConfirmPopulateSelectionsDialog = ({
  module,
  selectedOptionsIds,
  userAddedOptions,
  onDismiss,
  isOpen,
  onSave,
}) => {
  return (
    <DialogOverlay
      onDismiss={onDismiss}
      isOpen={isOpen}
      css={styles.containerOverlay}
    >
      <DialogContent aria-label="Confirm populate selections">
        <p css={styles.instructionText}>
          Double check your option list before moving on. You may not able to
          change it again once confirmed.
        </p>
        <ul css={styles.optionsTitlesText}>
          {getSelectedOptionNames(
            module,
            selectedOptionsIds,
            userAddedOptions
          ).map((options, idx) => (
            <li key={`${options}-${idx}`}>{options}</li>
          ))}
        </ul>
        <div css={styles.actionBtnsContainer}>
          <ButtonBase onClick={onDismiss} css={styles.cancelBtn}>
            Cancel
          </ButtonBase>
          <ButtonBase onClick={onSave} css={styles.saveBtn}>
            Continue
          </ButtonBase>
        </div>
      </DialogContent>
    </DialogOverlay>
  );
};
