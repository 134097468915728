import React, { useEffect } from 'react';
import { css } from 'emotion';
import Tooltip from '@reach/tooltip';
import SurveyPresentation from '../../SurveyPresentation';
import SelectMenu from '../../SelectMenu';
import { ButtonBase } from '../../buttons';
import { ReactComponent as ExpandIcon } from 'img/magnifying-expand.svg';
import { ReactComponent as CollapseIcon } from '../../../img/magnifying-collapse.svg';
import { useDispatch, useSelector } from 'react-redux';

const styles = {
  container: ({ singleMode, visible }) => ({
    height: '100%',
    width: '100%',
    display: visible ? 'flex' : 'none',
    margin: '0 auto',
    ...(singleMode && {
      minWidth: '710px',
      maxWidth: '1050px',
    }),
    flexDirection: 'column',
    '& .explanations': {
      marginLeft: singleMode ? '0px' : '10px',
    },
    '&:first-child': {
      '& .explanations': {
        marginLeft: '0px',
        marginRight: singleMode ? '0px' : '10px',
      },
    },
  }),
  chartContainer: (showExplanations) => ({
    height: showExplanations ? '65%' : '100%',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    padding: '20px',
    border: '1px solid #1CA4FC',
  }),
  chartSubContainer: {
    height: '100%',
    overflowY: 'auto',
    display: 'flex',
    padding: '10px',
    flexDirection: 'column',
  },
  chart: {
    marginTop: '40px',
    overflowY: 'auto',
    padding: '10px',
    paddingTop: 0,
  },
  explanationsContainer: {
    height: '32.5%',
    background: 'white',
    zIndex: 100,
    fontFamily: 'Roboto',
    display: 'flex',
    boxShadow:
      'inset -1px -1px 4px rgba(0, 0, 0, 0.25), inset 1px 1px 4px rgba(0, 0, 0, 0.2)',
    marginTop: '2.5%',
  },
  explanationsSubContainer: {
    margin: '20px',
    overflowY: 'auto',
    width: '100%',
  },
  noExplanationsText: {
    margin: 'auto',
    fontStyle: 'italic',
  },
};

const explanationStyles = {
  container: {
    paddingRight: '20px',
    paddingBottom: '20px',
    '&:last-child': { '#border': { display: 'none' } },
  },
  subContainer: (active) => ({
    display: 'flex',
    alignItems: 'baseline',
    color: active ? '#FF9736' : '#1CA4FC',
  }),
  name: {
    fontWeight: 900,
    fontSize: '24px',
  },
  viewDataBtn: {
    marginLeft: 'auto',
    fontSize: '20px',
    fontWeight: 400,
  },
  border: {
    borderTop: '1px solid #1CA4FC',
    marginTop: '20px',
  },
  explanationTxt: {
    fontSize: '20px',
  },
  noExplanationText: {
    fontStyle: 'italic',
    fontSize: '16px',
  },
};

const Explanation = ({ data, onClickViewData, active }) => {
  return (
    <div css={explanationStyles.container}>
      <div css={explanationStyles.subContainer(active)}>
        <p css={explanationStyles.name}>{data.name || 'No Name'}</p>
        <ButtonBase
          onClick={onClickViewData}
          css={explanationStyles.viewDataBtn}
        >
          View Data
        </ButtonBase>
      </div>
      <p css={explanationStyles.explanationTxt}>
        {data.explanation || (
          <span css={explanationStyles.noExplanationText}>
            No explanation from this user.
          </span>
        )}
      </p>
      <div id="border" css={explanationStyles.border} />
    </div>
  );
};

const setModuleQueryParam = false;

export default function Section({
  moduleList,
  userResponses,
  initialSelectedModuleId,
  showExplanations,
  singleMode,
  onToggleExpand,
  visible,
  comparisonSection,
  activeQuestion,
  mode,
  setSelectedParticipantResponseId,
}) {
  return (
    <SurveyPresentation
      moduleList={moduleList}
      userResponses={userResponses}
      initialSelectedModuleId={initialSelectedModuleId}
      activeQuestion={activeQuestion}
      mode={mode}
    >
      {({ chart: Chart, questionsData, onQuestionSelected }) => {
        const dispatch = useDispatch();

        const explanations = useSelector(
          (state) => state.workshop.comparison[comparisonSection].comments
        );

        const selectedParticipantResponseId = useSelector(
          (state) =>
            state.workshop.comparison[comparisonSection]
              .selectedParticipantResponseId
        );

        const items = Object.values(questionsData.questionsMetaData).filter(
          (data) =>
            data.moduleId !==
            (activeQuestion ? activeQuestion.metaData.moduleId : null)
        );

        useEffect(() => {
          const newSelectedPropItem = Object.values(
            questionsData.questionsMetaData
          ).filter((item) => item.moduleId === initialSelectedModuleId)[0];
          if (activeQuestion && newSelectedPropItem) {
            onQuestionSelected(
              newSelectedPropItem.moduleId,
              setModuleQueryParam,
              comparisonSection
            );
          }
        }, [initialSelectedModuleId]);

        const handleViewDataClick = (data) => {
          if (data.responseId === selectedParticipantResponseId) {
            dispatch(setSelectedParticipantResponseId(null));
            return;
          }
          dispatch(setSelectedParticipantResponseId(data.responseId));
        };

        return (
          <div css={styles.container({ singleMode, visible })}>
            <div css={styles.chartContainer(showExplanations)}>
              <div css={{ display: 'flex', paddingBottom: '30px' }}>
                <SelectMenu
                  items={items}
                  itemRender={({ item = {} }) => {
                    return (
                      <Tooltip
                        style={{ zIndex: 100 }}
                        label={item.questionText}
                      >
                        <span>{item.questionText}</span>
                      </Tooltip>
                    );
                  }}
                  selectedItem={activeQuestion.metaData}
                  onSelectItem={(item) => {
                    onQuestionSelected(
                      item.moduleId,
                      setModuleQueryParam,
                      comparisonSection
                    );
                  }}
                />
                <ButtonBase
                  css={{ marginLeft: 'auto' }}
                  onClick={onToggleExpand}
                >
                  {singleMode ? <CollapseIcon /> : <ExpandIcon />}
                </ButtonBase>
              </div>
              <Chart
                showTextSlideQuestion
                className={css(styles.chart)}
                comparisonSection={comparisonSection}
              />
            </div>
            {showExplanations && (
              <>
                <div
                  css={styles.explanationsContainer}
                  className="explanations"
                >
                  {explanations.length > 0 ? (
                    <div css={styles.explanationsSubContainer}>
                      {explanations.map((data) => (
                        <Explanation
                          data={data}
                          onClickViewData={() => handleViewDataClick(data)}
                          active={Boolean(
                            selectedParticipantResponseId &&
                              data.responseId === selectedParticipantResponseId
                          )}
                        />
                      ))}
                    </div>
                  ) : (
                    <p css={styles.noExplanationsText}>
                      No explanations from any user for this question.
                    </p>
                  )}
                </div>
              </>
            )}
          </div>
        );
      }}
    </SurveyPresentation>
  );
}
