import { db, serverTimestamp } from '../firebase';

const surveyAttempCollection = db.collection('surveyAttempt');

export const logSurveyAttempt = ({ surveyCode, surveyExists }) => {
  const surveyAttempt = surveyAttempCollection.doc();
  return surveyAttempt.set({
    surveyCode,
    surveyExists,
    timestamp: serverTimestamp()
  });
};
