import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-right: ${(props) => props.paddingRight || '15px'};
  padding-left: ${(props) => props.paddingLeft || '15px'};
  margin-right: ${(props) => props.marginRight || '0px'};
  background: #ffffff;
  box-shadow: 0 1.24444px 4.97778px rgba(28, 164, 252, 0.3);
  border-radius: 62.2222px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: ${(props) => props.textWeight || 500};
  font-size: 20px;
  width: fit-content;
  color: ${(props) => props.color || '#1ca4fc'};
  cursor: pointer;
  text-transform: capitalize;
`;

export const HiloButton = ({
  children,
  onClick,
  color = '#1ca4fc',
  textWeight = 500,
  paddingRight,
  paddingLeft,
  marginRight,
}) => {
  return (
    <Container
      color={color}
      textWeight={textWeight}
      paddingRight={paddingRight}
      paddingLeft={paddingLeft}
      marginRight={marginRight}
      onClick={onClick}
    >
      {children}
    </Container>
  );
};
