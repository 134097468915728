import React from "react";
import Lottie from "react-lottie";
import { css } from "emotion";

import animationData from "../assets/loader.json";

export default function AnimLoader({ styles }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <Lottie
      options={defaultOptions}
      style={{ width: "400px", height: "400px", margin: "auto", ...styles }}
    />
  );
}
