const axisLabel = {
  fontSize: 15,
  fontFamily: "Neue Haas Grotesk Text Std",
  fontStyle: "normal",
  fontWeight: "bold",
  color: "#333333",
  textAnchor: "start",
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "block"
};

const tickLabels = {
  fontSize: 12,
  fontFamily: "Neue Haas Grotesk Text Std",
  fontStyle: "normal",
  fontWeight: "normal",
  color: "#333333"
};

const ticks = {
  stroke: "#333333",
  size: 0
};

const axis = {
  stroke: "#333333"
};

const axisStyle = {
  axis,
  axisLabel,
  tickLabels,
  ticks
};

export { axisStyle, axis, ticks, tickLabels, axisLabel };
