import React from "react";
import styled from "styled-components";

const Container = styled("div")`
  background: #ffffff;
  border: 1px solid #868c97;
  border-radius: 5px;
  padding: 5px 15px 5px 5px;
`;

const Title = styled("p")`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #868c97;
`;

const Names = styled("div")`
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 10px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #868c97;
`;

export const TooltipComponent = ({ data = [] }) => {
  return (
    <Container>
      <Title>Name:</Title>
      <Names>
        {data.map((user, index) => (
          <div key={`${index}${user.username}`}>{user.username}</div>
        ))}
      </Names>
    </Container>
  );
};
